<template>
	<div
		:class="[!isCreation && !isSelection ? 'qp-selectable' : '', isSelected ? 'qp-selected' : '', 'qp-wrap-content']"
		@click="!isCreation && !isSelection ? $emit('selectQuestion', question) : null"
		@mouseenter="showSelectionControl = true"
	>
		<div
			v-if="isSelection && showSelectionControl"
			@mouseover="showSelectionControl = true"
			@mouseleave="showSelectionControl = false"
			class="qp-wrap-selection-options"
		>
			<div class="qp-selection-options">
				<fai icon="fas fa-angle-up" class="qp-up" @click="$emit('moveQuestionUp', question)"></fai>
				<fai icon="fas fa-angle-down" class="qp-down" @click="$emit('moveQuestionDown', question)"></fai>
				<fai icon="fas fa-times" class="qp-delete" @click="$emit('removeQuestion', question)"></fai>
			</div>
		</div>
		<div v-if="isCreation" class="qp-wrap-icons">
			<fai icon="fas fa-clone" class="qp-clone" @click="cloneQuestion"></fai>
			<fai v-if="!question.isPrivate && !question.isPublic" icon="fas fa-pen" class="qp-edit" @click="editQuestion"></fai>
			<fai v-if="!question.isPrivate && !question.isPublic" icon="fas fa-trash" class="qp-delete" @click="deleteQuestion"></fai>
		</div>
		<div class="qp-wrap-left">
			<div>
				<fai icon="fas fa-question-circle" />
				<p v-if="['oh', 'dpin', 'lz', 'smf', 'sq', 'wbm', 'wlp', 'xfz', 'zgw'].includes(show)" class="qp-question">
					<span>{{ question.question }}</span>
					<span v-if="['smf', 'xfz'].includes(show)">
						<fai v-if="question.type == 'Freetext'" icon="fas fa-keyboard" :title="$t('qpFreetext')"></fai>
						<fai
							v-if="['Buzzer', 'BuzzerSingleChoice'].includes(question.type)"
							icon="fas fa-concierge-bell"
							:title="$t('qpBuzzer')"
						></fai>
						<fai
							v-if="['SingleChoice', 'BuzzerSingleChoice'].includes(question.type)"
							icon="fas fa-list-ul"
							:title="$t('qpSingleChoice')"
						></fai>
					</span>
				</p>

				<span v-if="['kq'].includes(show)">{{ question.category }} ({{ question.questions.length }} {{ $t('qpAmountQuestions') }})</span>
				<span v-if="['lz'].includes(show)">
					(<fai v-if="['lz'].includes(show)" icon="fas fa-tags" :title="$t('qpCategories')" />
					<span v-if="['lz'].includes(show)">{{ [...question.categories.map((cat) => cat.category)].join(', ') }}</span>
					)
				</span>
			</div>

			<div
				v-if="
					!['kq', 'zgw'].includes(show) &&
					!(show == 'smf' && (question.imageIsAnswer || (question.hasTwoImages && question.answerPos.length == 0)))
				"
				class="qp-answer"
			>
				<fai icon="fas fa-circle-check" :title="$t('qpAnswer')" />
				<span v-if="['xfz'].includes(show)">
					<span v-if="!['SingleChoice', 'BuzzerSingleChoice'].includes(question.type)">{{ question.answerPos[0].answer }}</span>
					<span v-else>
						{{ question.answerPos.filter((ans) => ans.isAnswer)[0].answer }} ({{ question.answerPos.length }}
						{{ $t('qpAmountOfAnswers') }})
					</span>
				</span>
				<span v-if="['smf'].includes(show)">
					<span v-if="question.answerPos.length == 1">{{ question.answerPos[0].answer }}</span>
					<span v-else-if="question.answerPos.length > 1">
						{{ question.answerPos.filter((ans) => ans.isAnswer)[0].answer }} ({{ question.answerPos.length }}
						{{ $t('qpAmountOfAnswers') }})
					</span>
				</span>
				<span v-if="['oh', 'wbm'].includes(show)">
					{{
						question.answerPos
							.slice(0, 3)
							.map((ans) => ans.answer)
							.join(', ')
					}}
					... ({{ question.answerPos.length }} {{ $t('qpAmountOfAnswers') }})
				</span>
				<span v-if="['lz'].includes(show)">
					{{ question.answerPos.filter((ans) => ans.category != 'BAIT').length }} {{ $t('qpAmountRightAnswers') }},
					{{ question.answerPos.filter((ans) => ans.category == 'BAIT').length }} {{ $t('qpAmountWrongAnswers') }}
				</span>
				<span v-if="['dpin', 'sq', 'wlp'].includes(show)">{{ question.answer }} </span>
				<fai v-if="show == 'wlp'" icon="fas fa-coins" class="qp-money" />
			</div>

			<div v-if="['oh', 'dpin', 'smf', 'sq', 'wbm', 'wlp', 'xfz'].includes(show)" class="qp-link">
				<fai icon="fas fa-link" :title="$t('qpSolutionLink')" />
				<span v-if="!question.link">{{ this.$t('qpNoSolutionLink') }}</span>
				<span v-else>
					<a v-if="question.link" :href="question.link" target="_blank">
						{{ getHostNameFromLink(question.link) }}<fai icon="fas fa-external-link-alt"></fai>
					</a>
				</span>
			</div>
			<div class="qp-public-created">
				<div>{{ $global.parseDate(question.created, true, false) }}</div>
				<fai v-if="question.isPublic" icon="fas fa-globe-europe" :title="this.$t('qpPublic')" />
				<fai v-else-if="question.isPrivate" icon="fas fa-lock" :title="this.$t('qpPrivate')" />
				<fai v-else icon="fas fa-edit" :title="this.$t('qpLocal')" />
			</div>
		</div>
		<div v-if="['dpin', 'smf', 'wlp'].includes(show)" class="qp-wrap-right">
			<div class="qp-images">
				<img
					:src="question.image"
					:alt="question.imageIsAnswer ? $t('qpFirstImageIsAnswer') : $t('qpFirstImageIsQuestion')"
					:title="question.imageIsAnswer ? $t('qpFirstImageIsAnswer') : $t('qpFirstImageIsQuestion')"
					:class="question.imageIsAnswer ? 'qp-image-is-answer' : 'qp-image-is-question'"
				/>
				<img
					v-if="['smf'].includes(show) && question.hasTwoImages"
					:src="question.image2"
					:alt="question.answerPos.length == 0 ? $t('qpSecondImageIsAnswer') : $t('qpSecondImageIsQuestion')"
					:title="question.answerPos.length == 0 ? $t('qpSecondImageIsAnswer') : $t('qpSecondImageIsQuestion')"
					:class="question.answerPos.length == 0 ? 'qp-image-is-answer' : 'qp-image-is-question'"
				/>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'QuestionPreview',
	props: {
		question: {
			type: Object,
			required: true,
		},
		show: {
			type: String,
			required: true,
		},
		isCreation: {
			type: Boolean,
			required: true,
		},
		isSelection: {
			type: Boolean,
			required: false,
		},
		isSelected: {
			type: Boolean,
			required: false,
		},
	},
	data() {
		return {
			showSelectionControl: false,
		};
	},
	methods: {
		getHostNameFromLink(link) {
			try {
				return new URL(link).hostname.replace('www.', '');
			} catch (error) {
				console.log(error);
				return link;
			}
		},
		dragQuestion(e) {
			this.$emit('dragQuestion', e, this.question);
		},
		cloneQuestion(e) {
			this.$emit('cloneQuestion', this.question);
			this.colorBackground(e.target.closest('.qp-wrap-content'));
		},
		editQuestion(e) {
			this.$emit('editQuestion', this.question);
			this.colorBackground(e.target.closest('.qp-wrap-content'));
		},
		deleteQuestion() {
			this.$emit('deleteQuestion', this.question.qID);
		},
		colorBackground(target) {
			[...document.querySelectorAll('.qp-wrap-content')].forEach((elem) => {
				elem.classList.remove('qp-highlighted');
			});
			target.classList.add('qp-highlighted');
			document.getElementsByClassName('qc-wrap-content')[0].scrollTo({ top: 0, behavior: 'smooth' });
		},
	},
};
</script>

<style scoped>
.qp-wrap-content {
	width: 100%;
	margin: 5px 0px 10px 0px;
	padding: 10px;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	position: relative;
	box-sizing: border-box;
	text-align: start;
	border: 1px solid var(--main-color-border-dark);
	/* box-shadow: 2px 2px 4px 4px var(--main-color-border-dark); */
	background-color: var(--main-color-5);
}

.qp-selectable {
	cursor: pointer;
}

.qp-selectable:hover {
	background-color: var(--main-color-6);
}

.qp-selectable:hover p {
	color: var(--main-color-text-dark);
}

.qp-selectable:hover a {
	color: var(--main-color-text-light);
}

.qp-selected {
	background-color: var(--main-color-6);
}

.qp-selected p {
	color: var(--main-color-text-dark);
}

.qp-selected a {
	color: var(--main-color-text-light) !important;
}

.qp-wrap-selection-options {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0px;
	left: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 15;
	background-color: var(--main-color-dark-transparent-80);
}

.qp-selection-options svg {
	margin: 10px;
	font-size: 40px;
	cursor: pointer;
}

.qp-wrap-icons {
	width: 70px;
	position: absolute;
	top: 7px;
	right: 5px;
	text-align: end;
}

.qp-wrap-icons svg {
	margin: 0px 2px;
	cursor: pointer;
	-webkit-filter: drop-shadow(2px 2px 0px var(--main-color-text-dark));
	filter: drop-shadow(2px 2px 0px var(--main-color-text-dark));
}

.qp-wrap-left {
	flex: 1 1 100%;
	max-width: calc(100% - 50px);
	height: fit-content;
	min-height: 50px;
	display: flex;
	flex-flow: column;
	justify-content: center;
}

.qp-wrap-right {
	flex: 1 1 220px;
	height: fit-content;
	margin: 20px 0px;
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
}

.qp-wrap-left svg {
	margin: 5px;
	font-size: 22px;
}

.qp-wrap-left div,
.qp-wrap-left p,
.qp-wrap-left a,
.qp-wrap-left span {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	color: var(--main-color-text-light);
}

.qp-wrap-left div {
	margin: 2px 0px;
}

.qp-wrap-left a svg {
	font-size: 17px;
}

.qp-question {
	margin-right: 5px;
}

.qp-public-created {
	position: absolute;
	bottom: 2px;
	right: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.qp-public-created div {
	margin: 5px 5px 0px 0px;
}

.qp-up {
	font-size: 30px;
	color: var(--main-color-text-light);
}

.qp-up:hover {
	color: var(--main-color-3);
}

.qp-down {
	font-size: 30px;
	color: var(--main-color-text-light);
}

.qp-down:hover {
	color: var(--main-color-3);
}

.qp-clone {
	font-size: 20px;
	color: var(--main-color-text-light);
}

.qp-clone:hover {
	color: var(--main-color-3);
}

.qp-edit {
	font-size: 20px;
	color: var(--main-color-warn);
}

.qp-edit:hover {
	color: var(--secondary-color-warn);
}

.qp-delete {
	font-size: 20px;
	color: var(--main-color-error);
}

.qp-delete:hover {
	color: var(--secondary-color-error);
}

.qp-wrap-content a {
	color: var(--main-color-6);
}

.qp-highlighted {
	background-color: var(--main-color-6);
}

.qp-highlighted p {
	color: var(--main-color-text-dark);
}

.qp-highlighted a {
	color: var(--main-color-text-light);
}

.qp-highlighted span {
	color: var(--main-color-text-light);
}

.qp-images {
	display: flex;
	justify-content: center;
	align-items: center;
}

.qp-images img {
	max-width: 100px;
	max-height: 100px;
	margin: 5px;
	border-radius: 10px;
	box-shadow: 1px 1px 1px 1px var(--main-color-border-dark);
	display: inline-block;
	box-sizing: border-box;
	transition: transform 0.5s;
	z-index: 2;
}

/* Behavior of first and last element is done in QuestionCreation */
.qp-images img:hover {
	transform: translateX(-100px) scale(3);
	z-index: 99;
}

.qp-image-is-question {
	border: 5px solid var(--main-color-info-cc);
}

.qp-image-is-answer {
	border: 5px solid var(--main-color-success-cc);
}

.qp-money {
	font-size: 17px !important;
	color: var(--main-color-gold);
}
</style>
