<template>
	<div
		:class="[
			user.deleted ? 'up-deleted' : '',
			!showPasswordReset && !showUserRestore && user.uID != $global.getUser().uID ? 'up-hover-effect' : '',
			user.uID == $global.getUser().uID ? 'up-admin-user' : '',
			'up-wrap-content',
		]"
		:title="user.uID == $global.getUser().uID ? $t('upYou') : user.name"
	>
		<div v-show="!showPasswordReset && !showUserRestore" class="up-hover-view">
			<p v-if="!user.deleted" @click="confirmAction(user.verified ? 'removeVerification' : 'verifyUser')" class="up-verification-user">
				<fai
					:icon="['fas', !user.verified ? 'fa-check-circle' : 'fa-times-circle']"
					:class="!user.verified ? 'up-verified' : 'up-not-verified'"
				/>
				{{ $t(user.verified ? 'upRemoveVerification' : 'upVerifyUser') }}
			</p>
			<p v-if="!user.deleted" @click="confirmAction('resetPassword')" class="up-password-user">
				<fai icon="fas fa-key" class="up-password" /> {{ $t('upResetPassword') }}
			</p>
			<p v-if="!user.deleted" @click="confirmAction('deleteUser')" class="up-delete-user">
				<fai icon="fas fa-trash" class="up-trash" /> {{ $t('upDeleteUser') }}
			</p>
			<p v-if="user.deleted" @click="confirmAction('restoreUser')" class="up-restore-user">
				<fai icon="fas fa-redo" class="up-restore" /> {{ $t('upRestoreUser') }}
			</p>
		</div>
		<div v-if="showPasswordReset || showUserRestore" :class="['up-change-settings', user.deleted ? 'up-small-input' : 'up-large-input']">
			<div v-if="showUserRestore" class="up-wrap-input">
				<p>{{ $t('upUserName') }}</p>
				<input type="text" v-model="userName" :placeholder="$t('upUserName')" autocomplete="off" />
			</div>
			<div v-if="showUserRestore" class="up-wrap-input">
				<p>{{ $t('upEmail') }}</p>
				<input type="email" v-model="email" :placeholder="$t('upEmail')" autocomplete="off" />
			</div>
			<div class="up-wrap-input">
				<p>{{ $t('upPassword') }}</p>
				<input :type="showPassword ? 'text' : 'password'" v-model="password" :placeholder="$t('upPassword')" autocomplete="new-password" />
				<fai
					:icon="['fas', showPassword ? 'fa-eye-slash' : 'fa-eye']"
					:class="[showPassword ? 'up-smaller-eye' : '']"
					@mouseenter="showPassword = true"
					@mouseleave="showPassword = false"
				></fai>
			</div>
			<div class="up-wrap-input">
				<p>{{ $t('upPasswordRepeat') }}</p>
				<input
					:type="showPasswordRepeat ? 'text' : 'password'"
					v-model="passwordRepeat"
					:placeholder="$t('upPasswordRepeat')"
					autocomplete="new-password"
				/>
				<fai
					:icon="['fas', showPasswordRepeat ? 'fa-eye-slash' : 'fa-eye']"
					:class="[showPasswordRepeat ? 'up-smaller-eye' : '']"
					@mouseenter="showPasswordRepeat = true"
					@mouseleave="showPasswordRepeat = false"
				></fai>
			</div>
			<div class="up-wrap-buttons">
				<button v-if="showPasswordReset" class="app-default-btn" @click="cancelReset">{{ $t('upCancelAction') }}</button>
				<button v-if="showPasswordReset" class="app-success-btn" @click="resetPassword">{{ $t('upSaveNewPassword') }}</button>
				<button v-if="showUserRestore" class="app-default-btn" @click="cancelRestore">{{ $t('upCancelRestore') }}</button>
				<button v-if="showUserRestore" class="app-success-btn" @click="restoreUser">{{ $t('upRestoreUser') }}</button>
			</div>
		</div>

		<div class="up-wrap-profile-picture">
			<div class="up-image-deleted">
				<fai icon="fas fa-times" />
			</div>
			<img :src="require(`@/assets/images/profilePictures/${user.profilePic}`)" alt="" :class="user.active ? 'up-active' : ''" />
		</div>
		<div class="up-wrap-user-information">
			<p><fai icon="fas fa-id-card" class="up-id" /> {{ user.uID }}</p>
			<p><fai icon="fas fa-user" class="up-name" /> {{ user.name }} ({{ user.login }})</p>
			<p><fai icon="fas fa-envelope" class="up-email" /> {{ user.email ? user.email : '-' }}</p>
			<p><fai icon="fas fa-user-tag" class="up-role" /> {{ user.role }}</p>
			<p>
				<fai icon="fas fa-check-circle" :class="user.verified ? 'up-verified' : 'up-not-verified'" />
				{{ $t(user.verified ? 'upVerified' : 'upNotVerified') }}
			</p>
			<p class="up-creation">{{ $global.parseDate(user.creation, true, false) }}</p>
		</div>
	</div>
</template>

<script>
/**
 * @group Admin
 * UserPreview
 */
export default {
	name: 'UserPreview',
	props: {
		user: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			showTools: false,
			showPasswordReset: false,
			showUserRestore: false,
			userName: null,
			email: null,
			password: null,
			showPassword: false,
			passwordRepeat: null,
			showPasswordRepeat: false,
		};
	},
	methods: {
		confirmAction(action) {
			let title = '';
			let text = '';
			let cancel = '';
			let confirm = '';

			if (action == 'verifyUser') {
				title = 'upVerifyUser';
				text = 'upVerifyUserText';
				cancel = 'upVerifyUserCancel';
				confirm = 'upVerifyUserConfirm';
			} else if (action == 'removeVerification') {
				title = 'upRemoveVerification';
				text = 'upRemoveVerificationText';
				cancel = 'upRemoveVerificationCancel';
				confirm = 'upRemoveVerificationConfirm';
			} else if (action == 'resetPassword') {
				title = 'upResetPassword';
				text = 'upResetPasswordText';
				cancel = 'upResetPasswordCancel';
				confirm = 'upResetPasswordConfirm';
			} else if (action == 'deleteUser') {
				title = 'upDeleteUser';
				text = 'upDeleteUserText';
				cancel = 'upDeleteUserCancel';
				confirm = 'upDeleteUserConfirm';
			} else if (action == 'restoreUser') {
				title = 'upRestoreUser';
				text = 'upRestoreUserText';
				cancel = 'upRestoreUserCancel';
				confirm = 'upRestoreUserConfirm';
			}

			let that = this;
			this.$global.showPopup(
				this.$t(title),
				this.$t(text),
				'warning',
				{ text: this.$t(cancel), color: 'var(--main-color-info)' },
				{ text: this.$t(confirm), color: 'var(--main-color-warn)' },
				null,
				function (result) {
					if (result.isConfirmed) {
						if (['verifyUser', 'removeVerification'].includes(action)) that.changeVerification();
						else if (action == 'resetPassword') that.showPasswordReset = true;
						else if (action == 'deleteUser') that.deleteUser();
						else if (action == 'restoreUser') that.showUserRestore = true;
					}
				}
			);
		},
		cancelReset() {
			this.showPasswordReset = false;
			this.password = null;
			this.passwordRepeat = null;
		},
		resetPassword() {
			if (this.password == null || this.passwordRepeat == null) this.$global.showToast('warn', this.$t('upNoPasswordEntered'));
			else if (this.password.length < 9) this.$global.showToast('warn', this.$t('upPasswordLengthToShort'));
			else if (this.password != this.passwordRepeat) this.$global.showToast('warn', this.$t('upPasswordDoesntMatch'));
			else {
				let that = this;
				this.$global.patchData(
					'admin',
					'/updateUserPassword',
					{ password: this.password, user: this.user },
					{
						headers: {
							pageauthheader: this.$global.getAccessCode(),
							adminauthheader: this.$global.getAdminCode(),
							adminid: this.$global.getUser().uID,
						},
						auth: this.$global.getCredentials(),
					},
					(err, data) => {
						if (err) that.$global.showToast('error', that.$t(err.response ? err.response.data.msg : 'upPasswordResetError'));
						else {
							that.$global.showToast('success', that.$t('upPasswordReset'));
							that.cancelReset();
						}
					}
				);
			}
		},
		changeVerification() {
			let that = this;
			this.$global.patchData(
				'admin',
				'/changeVerification',
				{ user: this.user },
				{
					headers: {
						pageauthheader: this.$global.getAccessCode(),
						adminauthheader: this.$global.getAdminCode(),
						adminid: this.$global.getUser().uID,
					},
					auth: this.$global.getCredentials(),
				},
				(err, data) => {
					if (err)
						that.$global.showToast(
							'error',
							that.$t(err.response && err.response.data.msg ? err.response.data.msg : 'upChangeVerificationError')
						);
					else {
						that.$global.showToast('success', that.$t('upChangedVerification'));
						that.user.verified = !that.user.verified;
					}
				}
			);
		},
		deleteUser() {
			let that = this;
			this.$global.deleteData(
				'admin',
				'/deleteUser',
				{
					headers: {
						pageauthheader: this.$global.getAccessCode(),
						adminauthheader: this.$global.getAdminCode(),
						adminid: this.$global.getUser().uID,
					},
					auth: this.$global.getCredentials(),
					params: { user: this.user },
				},
				null,
				function (err, data) {
					if (err)
						that.$global.showToast('error', that.$t(err.response && err.response.data.msg ? err.response.data.msg : 'upDeleteUserError'));
					else {
						that.$global.showToast('success', that.$t('upDeletedUser'));
						that.user.deleted = !that.user.deleted;
						that.$emit('refreshUsers');
					}
				}
			);
		},
		cancelRestore() {
			this.showUserRestore = false;
			this.userName = null;
			this.email = null;
			this.password = null;
			this.passwordRepeat = null;
		},
		restoreUser() {
			this.email = this.email ? this.email.toLowerCase() : this.email;
			if (!this.userName || this.userName.length < 2 || this.userName.length > 25)
				this.$global.showToast('warn', this.$t('upInvalidNameError'));
			else if (!this.$global.validEmail(this.email)) this.$global.showToast('warn', this.$t('upValidEmailError'));
			else if (this.password == null || this.passwordRepeat == null) this.$global.showToast('warn', this.$t('upNoPasswordEntered'));
			else if (this.password.length < 9) this.$global.showToast('warn', this.$t('upPasswordLengthToShort'));
			else if (this.password != this.passwordRepeat) this.$global.showToast('warn', this.$t('upPasswordDoesntMatch'));
			else {
				let that = this;
				this.$global.postData(
					'admin',
					'/restoreUser',
					{
						email: this.email,
						userName: this.userName,
						password: this.password,
						uID: this.user.uID,
					},
					{
						headers: {
							pageauthheader: this.$global.getAccessCode(),
							adminauthheader: this.$global.getAdminCode(),
							adminid: this.$global.getUser().uID,
						},
						auth: this.$global.getCredentials(),
					},
					function (err, result) {
						if (err)
							that.$global.showToast(
								'error',
								that.$t(err.response && err.response.data.msg ? err.response.data.msg : 'upRestoreUserError')
							);
						else {
							that.$global.showToast('success', that.$t('brRestoredUser'));
							that.cancelRestore();
							that.$emit('refreshUsers');
						}
					}
				);
			}
		},
	},
};
</script>

<style scoped>
.up-wrap-content {
	width: 100%;
	height: 170px;
	margin: 2px 0px 8px 0px;
	padding: 5px;
	box-sizing: border-box;
	position: relative;
	background-color: var(--main-color-5);
	border: 2px solid var(--main-color-border-dark);
	transition: height 0.2s linear;
	overflow: hidden;
}

.up-hover-effect:hover {
	height: 220px;
}

.up-hover-view {
	width: 100%;
	height: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow: wrap;
	transition: height 0.2s linear;
	z-index: 2;
	overflow: hidden;
}

.up-hover-view p {
	margin: 0px 15px;
	font-size: 20px;
	cursor: pointer;
}
.up-hover-view p svg {
	filter: drop-shadow(2px 2px 2px var(--main-color-border-dark));
}

.up-hover-view p:hover svg {
	opacity: 0.5;
}

.up-hover-effect:hover .up-hover-view {
	height: 50px;
	overflow: auto;
}

.up-change-settings {
	height: 100%;
	position: absolute;
	top: 0px;
	left: 0px;
	background-color: var(--main-color-5-cc);
	z-index: 3;
}

.up-small-input {
	width: 100%;
	padding: 0px calc(50% - 250px);
	box-sizing: border-box;
	display: block;
	text-align: center;
}

.up-large-input {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.up-small-input .up-wrap-input {
	width: calc(50% - 10px);
	max-width: 240px;
	margin: 5px;
	position: relative;
	display: inline-block;
	text-align: start;
}

.up-large-input .up-wrap-input {
	width: 100%;
	max-width: 500px;
	margin: 5px 0px;
	position: relative;
	text-align: start;
}

.up-user-restore {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0px;
	left: 0px;
	display: block;
	text-align: center;
	background-color: var(--main-color-5-cc);
	z-index: 3;
}

.up-user-restore .up-wrap-input {
	width: 50%;
	max-width: 250px;
	margin: 5px;
	position: relative;
	text-align: start;
	display: inline-block;
}

.up-wrap-input input {
	width: 100%;
	box-sizing: border-box;
}

.up-wrap-input svg {
	font-size: 20px;
	color: var(--main-color-text-dark);
	position: absolute;
	bottom: 5px;
	right: 10px;
}

.up-smaller-eye {
	right: 8px !important;
}

.up-wrap-buttons button {
	width: 240px;
	margin: 5px;
	font-size: 16px;
}

.up-admin-user {
	border: 4px solid var(--main-color-gold) !important;
}

.up-wrap-profile-picture {
	width: 140px;
	height: 140px;
	padding: 5px;
	display: inline-block;
	box-sizing: border-box;
	position: relative;
}

.up-image-deleted {
	width: 130px;
	height: 130px;
	position: absolute;
	top: 5px;
	left: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	visibility: hidden;
	border-radius: 5px;
	border: 2px solid var(--main-color-border-dark);
	background-color: var(--main-color-disabled-cc);
	z-index: 2;
}

.up-image-deleted svg {
	font-size: 60px;
	color: var(--main-color-error);
	filter: drop-shadow(2px 2px 2px var(--main-color-border-dark));
}

.up-wrap-profile-picture img {
	max-width: 130px;
	max-height: 130px;
	border: 2px solid var(--main-color-border-dark);
	border-radius: 5px;
}

.up-active {
	border-color: var(--main-color-success) !important;
}

.up-deleted {
	background-color: var(--main-color-error-80);
}

.up-deleted .up-image-deleted {
	visibility: visible;
}

.up-wrap-user-information {
	width: calc(100% - 150px);
	margin-left: 10px;
	padding: 5px;
	display: inline-block;
	box-sizing: border-box;
	text-align: start;
	vertical-align: top;
}

.up-wrap-user-information p {
	font-size: 17px;
	margin: 5px 0px;
}

.up-wrap-user-information svg {
	width: 30px;
	filter: drop-shadow(2px 2px 2px var(--main-color-border-dark));
}

.up-id {
	color: var(--main-color-warn);
}
.up-name {
	color: var(--main-color-text-light);
}
.up-email {
	color: var(--main-color-gold);
}
.up-verified {
	color: var(--main-color-success);
}
.up-not-verified {
	color: var(--main-color-error);
}
.up-role {
	color: var(--main-color-info);
}
.up-trash {
	color: var(--main-color-error);
}
.up-password {
	color: var(--main-color-info);
}
.up-restore {
	color: var(--main-color-warn);
}

.up-creation {
	width: 100%;
	text-align: end;
}
</style>
