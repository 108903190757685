<template>
	<div class="po-wrap-content">
		<h1>{{ session.name }} {{ $t('poResults') }}</h1>

		<div class="po-wrap-podium">
			<div class="po-podium-area">
				<div class="po-podium-silver-fill">
					<div class="po-podium-players">
						<div
							v-for="player in silverPlayers"
							:key="player.uID"
							:class="`po-player-${silverPlayers.length}`"
							:title="player.name"
							@mouseenter="showInfo = player.uID"
							@mouseleave="showInfo = null"
						>
							<div v-if="showInfo === player.uID" class="po-information">
								<h2>{{ player.name }}</h2>
								<p>{{ player.points }} {{ $t('poPointsEarned') }}</p>
								<p><fai icon="fas fa-question" /> {{ $t('poAnsweredQuestions') }}: {{ getCorrectAnswers(player.uID) }}</p>
								<p><fai icon="fas fa-trophy" /> {{ $t('poWonGameshows') }}: {{ getWonGames(player.uID) }}</p>
							</div>
							<img :src="require(`@/assets/images/profilePictures/${player.profilePic}`)" />
						</div>
					</div>
				</div>
				<div class="po-podium-silver"><fai icon="fas fa-trophy" /></div>
			</div>
			<div class="po-podium-area">
				<div class="po-podium-gold-fill">
					<div class="po-podium-players">
						<div
							v-for="player in goldPlayers"
							:key="player.uID"
							:class="`po-player-${goldPlayers.length}`"
							:title="player.name"
							@mouseenter="showInfo = player.uID"
							@mouseleave="showInfo = null"
						>
							<div v-if="showInfo === player.uID" class="po-information">
								<h2>{{ player.name }}</h2>
								<p>{{ player.points }} {{ $t('poPointsEarned') }}</p>
								<p><fai icon="fas fa-question" /> {{ $t('poAnsweredQuestions') }}: {{ getCorrectAnswers(player.uID) }}</p>
								<p><fai icon="fas fa-trophy" /> {{ $t('poWonGameshows') }}: {{ getWonGames(player.uID) }}</p>
							</div>
							<img :src="require(`@/assets/images/profilePictures/${player.profilePic}`)" />
						</div>
					</div>
				</div>
				<div class="po-podium-gold">
					<fai icon="fas fa-trophy" />
				</div>
			</div>
			<div class="po-podium-area">
				<div class="po-podium-bronze-fill">
					<div class="po-podium-players">
						<div
							v-for="player in bronzePlayers"
							:key="player.uID"
							:class="`po-player-${bronzePlayers.length}`"
							:title="player.name"
							@mouseenter="showInfo = player.uID"
							@mouseleave="showInfo = null"
						>
							<div v-if="showInfo === player.uID" class="po-information">
								<h2>{{ player.name }}</h2>
								<p>{{ player.points }} {{ $t('poPointsEarned') }}</p>
								<p><fai icon="fas fa-question" /> {{ $t('poAnsweredQuestions') }}: {{ getCorrectAnswers(player.uID) }}</p>
								<p><fai icon="fas fa-trophy" /> {{ $t('poWonGameshows') }}: {{ getWonGames(player.uID) }}</p>
							</div>
							<img :src="require(`@/assets/images/profilePictures/${player.profilePic}`)" />
						</div>
					</div>
				</div>
				<div class="po-podium-bronze"><fai icon="fas fa-trophy" /></div>
			</div>
		</div>
		<div class="po-wrap-leaderboard">
			<Leaderboard :leaderboard="leaderboard" :players="players" />
		</div>
		<div v-if="quizNight" class="po-wrap-star-rating">
			<StarRating :qID="quizNight.qID" />
		</div>
		<div class="po-wrap-gameshow-list">
			<h2>{{ quizNight.name }}</h2>
			<GameShowConfigList
				:registeredShows="shows"
				:configuredShows="configuredShows"
				:state="{ lobby: true, showDescription: true, isPrivate: true, isPublic: true }"
			/>
		</div>
		<div class="po-wrap-points-graph">
			<h2>{{ $t('poGameCourse') }}</h2>
			<PointsGraph :shows="configuredShows" :history="history" :players="players" />
		</div>
		<div class="po-wrap-history">
			<History :history="history" :players="players" :shows="shows" :configuredShows="configuredShows" />
		</div>
	</div>
</template>

<script>
import Leaderboard from './Leaderboard.vue';
import History from './History.vue';
import StarRating from './StarRating.vue';
import PointsGraph from './PointsGraph.vue';
import GameShowConfigList from '../quizNightCreation/GameShowConfigList.vue';
export default {
	name: 'Podium',
	components: {
		Leaderboard,
		History,
		StarRating,
		GameShowConfigList,
		PointsGraph,
	},
	props: {
		players: {
			type: Array,
			required: true,
		},
		shows: {
			type: Array,
			required: true,
		},
		leaderboard: {
			type: Array,
			required: true,
		},
		history: {
			type: Array,
			required: true,
		},
		session: {
			type: Object,
			required: true,
		},
		quizNight: {
			type: Object,
			required: true,
		},
		configuredShows: {
			type: Array,
			required: true,
		},
	},
	watch: {
		showInfo: {
			handler: function () {
				this.$nextTick(() => {
					window.dispatchEvent(new Event('resize'));
				});
			},
		},
	},
	data() {
		return {
			goldPlayers: [],
			// goldPlayers: [...this.players, ...this.players].slice(0, 6),
			silverPlayers: [],
			bronzePlayers: [],
			showInfo: null,
		};
	},
	created() {
		this.dividePlayers();
		sessionStorage.setItem('isPodium', true);
	},
	mounted() {
		window.addEventListener('resize', this.resizeListener);
		window.dispatchEvent(new Event('resize'));
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.resizeListener);
		sessionStorage.removeItem('isPodium');
	},
	methods: {
		resizeListener() {
			let element = document.querySelector('.po-information');
			if (element) {
				element.style.position = `absolute`;
				if (window.innerWidth > 1120) element.style.left = `-100px`;
				else element.style.left = `-50px`;
				element.style.bottom = `-${element.clientHeight + 4}px`;
			}
		},
		dividePlayers() {
			let entrys = {};
			this.leaderboard.forEach((entry) => {
				let player = this.players.filter((p) => p.uID == entry.uID)[0];
				if (entrys[entry.points]) entrys[entry.points].push({ ...player, points: entry.points });
				else entrys[entry.points] = [{ ...player, points: entry.points }];
			});
			let groups = Object.keys(entrys).sort((a, b) => Number(b) - Number(a));
			this.goldPlayers = entrys[groups[0]];
			if (this.goldPlayers.length == 1) {
				this.silverPlayers = entrys[groups[1]];
				if (this.silverPlayers.length == 1) this.bronzePlayers = entrys[groups[2]];
			} else if (this.goldPlayers.length == 2) this.bronzePlayers = entrys[groups[1]];
		},
		getCorrectAnswers(uID) {
			let zgwID = this.configuredShows.filter((show) => show.show == 'zgw')[0];
			if (zgwID) zgwID = zgwID.gID;
			let correctAnswers = this.history.filter((his) => his.action == 'correctAnswer' && his.info == uID && his.gID != zgwID);
			correctAnswers = [...new Set(correctAnswers.map((entry) => entry.qID))].length;
			if (zgwID) {
				let zgwAnswers = this.history.filter((his) => his.action == 'correctAnswer' && his.info == uID && his.gID == zgwID);
				correctAnswers += zgwAnswers.length;
			}
			let correctKQAnswers = this.history.filter((his) => his.action == 'correctAnswerKQ' && his.info == uID);
			correctAnswers += correctKQAnswers.length;

			let correctBuzzer = this.history.filter((his) => his.action == 'rightBuzzer' && his.info == uID && his.gID != zgwID);
			correctBuzzer = [...new Set(correctBuzzer.map((entry) => entry.qID))].length;

			let correctBids = this.history.filter(
				(his) => his.action == 'revealItem' && his.info == uID && Number(his.result >= 0) && his.gID != zgwID
			);
			correctBids = [...new Set(correctBids.map((entry) => entry.qID))].length;

			let allQuestions = this.history.filter((his) => his.action == 'setQuestion' && his.gID != zgwID).length;

			if (zgwID) {
				let zgwTopics = this.history.filter((his) => his.action == 'assignTopic' && his.info != uID && his.gID == zgwID);
				allQuestions += zgwTopics.length * 10;
			}
			let kqCategoryEvents = this.history.filter((his) => ['selectCategory', 'removeCategory', 'assignCategory'].includes(his.action));
			allQuestions += kqCategoryEvents.filter((his) => his.action == 'selectCategory' && his.uID == uID).length;
			allQuestions -= kqCategoryEvents.filter((his) => his.action == 'removeCategory' && his.info2 == uID).length;
			allQuestions += kqCategoryEvents.filter((his) => his.action == 'assignCategory' && his.info == uID).length;

			return `${correctAnswers + correctBuzzer + correctBids} ${this.$t('poOf')} ${allQuestions}`;
		},
		getWonGames(uID) {
			let allPoints = this.history.filter((his) =>
				[
					'correctAnswer',
					'correctAnswerKQ',
					'rightBuzzer',
					'wrongBuzzer',
					'addPoints',
					'removePoints',
					'pointsWin',
					'creatorPoints',
				].includes(his.action)
			);
			let games = {};
			allPoints.forEach((points) => {
				if (games[points.gID]) games[points.gID].push(points);
				else games[points.gID] = [points];
			});

			let wonGamesAmount = 0;
			let wonGames = [];
			let gIDs = Object.keys(games);
			gIDs.forEach((gID) => {
				let game = games[gID];
				let playerPoints = {};

				game.forEach((entry) => {
					if (entry.action == 'wrongBuzzer') {
						this.leaderboard.forEach((user) => {
							if (entry.info !== user.uID) {
								if (playerPoints[entry.info]) playerPoints[entry.info] += Number(entry.result);
								else playerPoints[entry.info] = Number(entry.result);
							}
						});
					} else if (entry.action == 'correctAnswerKQ') {
						let show = this.configuredShows.filter((it) => it.gID == entry.gID)[0];
						let points = show.pointsPerLevel[entry.result].points;
						if (playerPoints[entry.info]) playerPoints[entry.info] += Number(points);
						else playerPoints[entry.info] = Number(points);
					} else {
						if (playerPoints[entry.info]) playerPoints[entry.info] += Number(entry.result);
						else playerPoints[entry.info] = Number(entry.result);
					}
				});

				let maxPoints = Object.values(playerPoints).sort((a, b) => b - a)[0];
				if (playerPoints[uID] == maxPoints) {
					wonGamesAmount += 1;
					wonGames.push(gID);
				}
			});

			wonGames = wonGames.map((game) => {
				return this.$t(`${this.configuredShows.filter((show) => show.gID == game)[0].show}Name`);
			});

			wonGames = wonGames.length > 0 ? `(${wonGames.join(', ')})` : '';
			return `${wonGamesAmount} ${wonGames}`;
		},
	},
};
</script>

<style scoped>
.po-wrap-content {
	width: 100vw;
	height: 100vh;
	overflow: auto;
}

.po-wrap-podium {
	width: 900px;
	height: fit-content;
	margin: auto;
}

.po-podium-area {
	width: 300px;
	height: 500px;
	display: inline-block;
	vertical-align: top;
}

.po-podium-gold {
	height: 300px;
	color: var(--main-color-gold);
}

.po-podium-gold-fill {
	width: 300px;
	height: 200px;
	padding-top: 0px;
}

.po-podium-silver {
	height: 200px;
	color: var(--main-color-silver);
}

.po-podium-silver-fill {
	width: 300px;
	height: 300px;
	padding-top: 100px;
	box-sizing: border-box;
}

.po-podium-bronze {
	height: 100px;
	color: var(--main-color-bronze);
}

.po-podium-bronze-fill {
	width: 300px;
	height: 400px;
	padding-top: 200px;
	box-sizing: border-box;
}

.po-podium-gold,
.po-podium-silver,
.po-podium-bronze {
	width: 300px;
	border: 2px solid var(--main-color-border-dark);
	box-sizing: border-box;
	background-color: var(--main-color-light-transparent-80);
	display: flex;
	justify-content: center;
	align-items: center;
}

.po-podium-gold {
	font-size: 130px;
}
.po-podium-silver {
	font-size: 100px;
}
.po-podium-bronze {
	font-size: 70px;
}

.po-podium-silver {
	border-right: none;
}

.po-podium-bronze {
	border-left: none;
}

.po-podium-gold svg,
.po-podium-silver svg,
.po-podium-bronze svg {
	filter: drop-shadow(6px 6px 4px var(--main-color-border-dark));
}

.po-podium-players {
	width: 300px;
	height: 200px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow: wrap;
	text-align: center;
	position: relative;
}

.po-podium-players img {
	border: 5px solid var(--main-color-border-dark);
	border-radius: 50%;
	box-sizing: border-box;
	cursor: pointer;
}

.po-player-1 img {
	width: 150px;
	height: 150px;
}

.po-player-2 img {
	width: 130px;
	height: 130px;
	margin: 10px;
}

.po-player-3 img {
	width: 95px;
	height: 95px;
	margin: 0px 10px;
}

/* .po-player-4 {
	flex: 1 1 95px;
} */

.po-player-4 img {
	width: 95px;
	height: 95px;
	margin: 0px 10px;
}

.po-player-5 img {
	width: 90px;
	height: 90px;
	margin: 0px 5px;
}

.po-player-6 img {
	width: 90px;
	height: 90px;
	margin: 0px 5px;
}

.po-information {
	width: 500px;
	height: fit-content;
	background-color: var(--main-color-dark-transparent-cc);
	border: 2px solid var(--main-color-border-dark);
	border-radius: 20px;
	padding: 5px 10px;
	box-sizing: border-box;
	z-index: 10;
	text-align: start;
	overflow-y: auto;
	overflow-x: hidden;
}

.po-information h2 {
	width: fit-content;
	font-size: 30px;
	margin: auto;
	margin-bottom: 10px;
}

.po-information p {
	width: 100%;
	margin: 5px;
	font-size: 25px;
}

.po-wrap-leaderboard {
	margin-top: 20px;
}

.po-wrap-points-graph {
	width: 90%;
	margin: 20px auto;
	padding: 10px;
}

.po-wrap-history {
	width: 90%;
	height: 500px;
	margin: 20px auto;
	padding: 10px;
	border-radius: 20px 20px 20px 20px;
	background-color: var(--main-color-2);
	border: 2px solid var(--main-color-border-dark);
	overflow: auto;
}

.po-wrap-gameshow-list {
	width: 90%;
	margin: 10px auto 20px auto;
	padding: 10px;
	overflow: auto;
}

.po-wrap-gameshow-list h2,
.po-wrap-points-graph h2 {
	font-size: 40px;
	margin: 0px auto;
	padding: 0px 0px;
	text-shadow: 3px 3px var(--main-color-6);
	-webkit-text-stroke: 2px var(--main-color-text-dark);
	text-align: center;
}

@media screen and (max-width: 1120px) {
	.po-wrap-podium {
		width: 600px;
		height: fit-content;
		margin: auto;
	}

	.po-podium-area {
		width: 200px;
		height: 300px;
		display: inline-block;
		vertical-align: top;
	}

	.po-podium-gold {
		height: 150px;
		color: var(--main-color-gold);
	}

	.po-podium-gold-fill {
		width: 200px;
		height: 150px;
		padding-top: 0px;
	}

	.po-podium-silver {
		height: 100px;
		color: var(--main-color-silver);
	}

	.po-podium-silver-fill {
		width: 200px;
		height: 200px;
		padding-top: 50px;
		box-sizing: border-box;
	}

	.po-podium-bronze {
		height: 50px;
		color: var(--main-color-bronze);
	}

	.po-podium-bronze-fill {
		width: 200px;
		height: 250px;
		padding-top: 100px;
		box-sizing: border-box;
	}

	.po-podium-gold,
	.po-podium-silver,
	.po-podium-bronze {
		width: 200px;
		border: 2px solid var(--main-color-border-dark);
		box-sizing: border-box;
		background-color: var(--main-color-light-transparent-80);
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.po-podium-gold {
		font-size: 90px;
	}
	.po-podium-silver {
		font-size: 60px;
	}
	.po-podium-bronze {
		font-size: 30px;
	}

	.po-podium-silver {
		border-right: none;
	}

	.po-podium-bronze {
		border-left: none;
	}

	.po-podium-gold svg,
	.po-podium-silver svg,
	.po-podium-bronze svg {
		filter: drop-shadow(3px 3px 3px var(--main-color-border-dark));
	}

	.po-podium-players {
		width: 200px;
		height: 150px;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-flow: wrap;
		text-align: center;
		position: relative;
	}

	.po-podium-players img {
		border: 5px solid var(--main-color-border-dark);
		border-radius: 50%;
		box-sizing: border-box;
		cursor: pointer;
	}

	.po-player-1 img {
		width: 130px;
		height: 130px;
	}

	.po-player-2 img {
		width: 95px;
		height: 95px;
		margin: 2px;
	}

	.po-player-3 img {
		width: 70px;
		height: 70px;
		margin: 0px 2px;
	}

	.po-player-4 img {
		width: 70px;
		height: 70px;
		margin: 0px 2px;
	}

	.po-player-5 img {
		width: 60px;
		height: 60px;
		margin: 0px 2px;
	}

	.po-player-6 img {
		width: 60px;
		height: 60px;
		margin: 0px 2px;
	}

	.po-information {
		width: 300px;
		height: fit-content;
		background-color: var(--main-color-dark-transparent-cc);
		border: 2px solid var(--main-color-border-dark);
		border-radius: 20px;
		padding: 5px 10px;
		box-sizing: border-box;
		z-index: 10;
		text-align: start;
		overflow-y: auto;
		overflow-x: hidden;
	}

	.po-information h2 {
		width: fit-content;
		font-size: 20px;
		margin: auto;
		margin-bottom: 10px;
	}

	.po-information p {
		width: 100%;
		margin: 5px;
		font-size: 17px;
	}
}
</style>
