<template>
	<div class="ut-wrap-content">
		<div class="ut-wrap-user-top-bar">
			<div class="ut-wrap-user-filter">
				<FilterBar :type="'user'" :isLoading="isLoading" :amountElements="userAmount" @refresh="refreshBrowser" />
			</div>
		</div>
		<div class="ut-blur-ref">
			<div v-show="!firstItemVisible" class="ut-blur-before"></div>
			<div class="ut-wrap-users">
				<UserPreview
					v-for="(user, idx) in users"
					:id="`user-${idx}`"
					:key="idx"
					:user="user"
					@refreshUsers="userFilter ? refreshBrowser(userFilter) : null"
				/>
				<div v-if="users.length == 0 && !isLoading" class="ut-wrap-no-users">
					<div class="ut-wrap-refresh">
						<fai icon="fas fa-sad-tear" />
						<p class="ut-no-users">{{ $t('utNoUsersFound') }}</p>
					</div>
				</div>
			</div>
			<div v-show="!lastItemVisible" class="ut-blur-after"></div>
		</div>
	</div>
</template>

<script>
/**
 * @group Admin
 * UserTable
 */
import FilterBar from '../lobby/filter/FilterBar.vue';
import UserPreview from './UserPreview.vue';
export default {
	name: 'UserTable',
	components: {
		UserPreview,
		FilterBar,
	},
	data() {
		return {
			users: [],
			userAmount: 0,
			isLoading: false,
			refreshClicked: false,
			userFilter: null,
			firstItemVisible: true,
			lastItemVisible: true,
		};
	},
	mounted() {
		document.querySelector('.ut-wrap-users').addEventListener('scroll', this.scrollListener);
	},
	beforeDestroy() {
		document.querySelector('.ut-wrap-users').removeEventListener('scroll', this.scrollListener);
	},
	methods: {
		scrollListener(e) {
			this.firstItemVisible = this.itemVisible(true);
			this.lastItemVisible = this.itemVisible(false);
		},
		itemVisible(first) {
			const item = document.getElementById(`user-${first ? 0 : this.users.length - 1}`);
			const container = document.querySelector('.ut-wrap-users');
			if (item && container) {
				const containerRect = container.getBoundingClientRect();
				const itemRect = item.getBoundingClientRect();

				// Check if the item's top and bottom are within the container's visible area
				const isTopVisible = itemRect.top >= containerRect.top && itemRect.top <= containerRect.bottom;
				const isBottomVisible = itemRect.bottom >= containerRect.top && itemRect.bottom <= containerRect.bottom;

				return isTopVisible || isBottomVisible;
			} else if (this.users.length == 0) return true;
			else return false;
		},
		getUsers() {
			let that = this;
			this.$global.getData(
				'admin',
				`/users`,
				{
					headers: {
						pageauthheader: this.$global.getAccessCode(),
						adminauthheader: this.$global.getAdminCode(),
						adminid: this.$global.getUser().uID,
					},
					auth: this.$global.getCredentials(),
					params: { userFilter: this.userFilter != null ? JSON.stringify(this.userFilter) : null },
				},
				null,
				function (err, result) {
					if (err) {
						that.$global.showToast('error', that.$t('adQueryUserError'));
						if (err.response.status == 401) that.$router.push({ name: 'Home' });
					} else {
						that.users = result.users;
						that.userAmount = result.userAmount;
						that.$nextTick(() => {
							that.firstItemVisible = that.itemVisible(true);
							that.lastItemVisible = that.itemVisible(false);
						});
					}
					that.isLoading = false;
					that.refreshClicked = false;
				}
			);
		},
		refreshBrowser(userFilter) {
			if (!this.refreshClicked) {
				this.refreshClicked = true;
				this.isLoading = true;
				this.userFilter = userFilter;
				this.users = [];
				this.getUsers();
			}
		},
	},
};
</script>

<style scoped>
.ut-wrap-content {
	width: calc(100% - 10px);
	height: 80vh;
	min-height: 300px;
	max-height: 80vh;
	flex: 1 1 100%;
	margin: 5px;
	padding: 10px;
	box-sizing: border-box;
	border: 2px solid var(--main-color-border-dark);
	background-color: var(--main-color-3-cc);
}

.ut-wrap-user-top-bar {
	width: 100%;
	height: 120px;
	text-align: center;
}

.ut-wrap-user-top-bar h2 {
	height: 40px;
	margin: 0px auto;
	padding: 0px 0px 10px 0px;
	font-size: 35px;
	text-shadow: 2px 2px var(--main-color-6);
	-webkit-text-stroke: 2px var(--main-color-text-dark);
}

.ut-wrap-user-top-bar h2 svg {
	margin: 5px;
	font-size: 45px;
	color: var(--main-color-text-light);
	filter: drop-shadow(2px 2px 2px var(--main-color-border-dark));
}

.ut-wrap-user-filter {
	width: 100%;
	height: 110px;
	margin-right: 15px;
	box-sizing: border-box;
	padding: 0px 5px 0px 0px;
	overflow-y: auto;
	overflow-x: hidden;
	border-bottom: 1px solid var(--main-color-border-light);
}

.ut-blur-ref {
	width: 100%;
	height: calc(100% - 110px);
	position: relative;
}

.ut-wrap-users {
	width: 100%;
	height: 100%;
	padding: 0px 10px;
	box-sizing: border-box;
	overflow: auto;
}

.ut-blur-before,
.ut-blur-after {
	position: absolute;
	left: 8px;
	width: calc(100% - 30px);
	margin: auto;
	height: 60px;
	backdrop-filter: blur(1px);
	z-index: 10;
	pointer-events: none;
}

.ut-blur-before {
	top: 0px;
	background: linear-gradient(to bottom, #00000033, #00000000);
}

.ut-blur-after {
	bottom: 0;
	background: linear-gradient(to top, #00000033, #00000000);
}

.ut-wrap-no-users {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ut-wrap-refresh {
	text-align: center;
	font-size: 30px;
}

.ut-wrap-refresh svg {
	margin: 10px;
	font-size: 60px;
	filter: drop-shadow(2px 2px 2px var(--main-color-border-dark));
	color: var(--main-color-6);
}
</style>
