<template>
	<div class="br-wrap-content">
		<p>{{ $t('brFeedbackBugReport') }}</p>
		<div class="br-wrap-selects">
			<div class="br-wrap-categories">
				<p>{{ $t('brCategory') }}</p>
				<select v-model="category">
					<option :value="null" disabled selected hidden>{{ $t('brSelectCategory') }}</option>
					<option value="func">{{ $t('brFunctionalityBug') }}</option>
					<option value="vis">{{ $t('brVisualBug') }}</option>
					<option value="other">{{ $t('brOtherBug') }}</option>
					<option value="feedback">{{ $t('brFeedback') }}</option>
				</select>
			</div>
			<div class="br-wrap-shows">
				<p>{{ $t('brShow') }}</p>
				<select v-model="show" :disabled="selectedShow">
					<option :value="null" disabled selected hidden>{{ $t('brSelectShow') }}</option>
					<option value="global">{{ $t('brGlobal') }}</option>
					<option v-for="show in shows" :value="show.abbreviation" :key="show.abbreviation">{{ $t(show.name) }}</option>
				</select>
			</div>
		</div>
		<div class="br-wrap-description">
			<p v-if="category !== 'feedback'">{{ $t('brBugDescription') }}</p>
			<p v-else>{{ $t('brFeedbackDescription') }}</p>
			<textarea
				v-model="description"
				:placeholder="category !== 'feedback' ? $t('brBugDescriptionPlaceholder') : $t('brFeedbackDescriptionPlaceholder')"
			></textarea>
		</div>
		<div class="br-additional-files">
			<p>{{ $t('brUploadAdditionalFiles') }}</p>
			<input id="bugFileUpload" type="file" accept="image/*" multiple @change="handleUpload" />
			<div class="br-assets-list">
				<div v-for="asset in assets" :key="asset.id" class="br-wrap-asset">
					<div class="br-asset">
						<img :src="asset.src" />
						<fai icon="fas fa-times" class="br-remove-asset" @click="removeAsset(asset.id)"></fai>
					</div>
					<p>{{ getAssetSize(asset) }}</p>
				</div>
			</div>
		</div>
		<div class="br-report-btn">
			<button class="app-success-btn" @click="addBugReport">{{ $t('brAddBugReport') }} <fai icon="fas fa-paper-plane"></fai></button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'BugReportForm',
	props: {
		shows: {
			type: Array,
			required: true,
		},
		selectedShow: {
			type: String,
			required: false,
		},
	},
	data() {
		return {
			sID: this.$route.params.sID ? this.$route.params.sID : null,
			uID: this.$global.getUser().uID,
			category: null,
			show: this.selectedShow ? this.selectedShow : null,
			description: null,
			assets: [],
		};
	},
	methods: {
		async handleUpload(e) {
			if (e.target.files.length > 0) {
				this.assets = [];
				if (e.target.files.length > 5) this.$global.showToast('warn', this.$t('brMaxNumberAssets'));

				let files = Array.from(e.target.files);
				for (let i = 0; i < files.length; i++) {
					if (this.assets.length < 5) {
						this.assets.push({
							id: i,
							src: await this.toBase64(files[i]),
							size: files[i].size,
						});
					}
				}
			}
		},
		getAssetSize(asset) {
			let kbSize = asset.size / 1024;
			let mbSize, gbSize;
			if (kbSize >= 1000) mbSize = asset.size / (1024 * 1024);
			if (mbSize >= 1000) gbSize = asset.size / (1024 * 1024 * 1024);
			if (gbSize) return `${Math.round(gbSize * 100) / 100} GB`;
			else if (mbSize) return `${Math.round(mbSize * 100) / 100} MB`;
			else return `${Math.round(kbSize * 100) / 100} KB`;
		},
		toBase64(file) {
			return new Promise((resolve, reject) => {
				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = () => resolve(reader.result);
				reader.onerror = (error) => reject(error);
			});
		},
		removeAsset(assetID) {
			this.assets = this.assets.filter((asset) => asset.id !== assetID);
		},
		addBugReport() {
			let size = this.assets.reduce((a, b) => {
				return a + b.size;
			}, 0);
			let mbSize = size / 1024 / 1024;
			if (!this.category) this.$global.showToast('warn', this.$t('brNoCategory'));
			else if (!this.show) this.$global.showToast('warn', this.$t('brNoShow'));
			else if (!this.description) this.$global.showToast('warn', this.$t('brNoDescription'));
			else if (this.assets.length > 5) this.$global.showToast('warn', this.$t('brToManyFiles'));
			else if (mbSize > 10) this.$global.showToast('warn', this.$t('brToLargeUpload'));
			else {
				let that = this;
				this.$global.postData(
					'system',
					'/bugReport',
					{
						sID: this.sID,
						uID: this.uID,
						assets: this.assets,
						category: this.category,
						show: this.show,
						description: this.description,
					},
					{
						headers: { pageauthheader: this.$global.getAccessCode() },
						auth: this.$global.getCredentials(),
					},
					function (err, result) {
						if (err) that.$global.showToast('error', that.$t(err.response ? err.response.data.msg : 'brBugReportError'));
						else {
							that.$global.showToast('success', that.$t('brBugReportSent'));
							that.resetVars();
							that.$emit('closeBugReport');
						}
					}
				);
			}
		},

		resetVars() {
			this.category = null;
			this.show = this.selectedShow ? this.selectedShow : null;
			this.description = null;
			this.assets = [];
			document.getElementById('bugFileUpload').value = null;
		},
	},
};
</script>

<style scoped>
.br-wrap-content {
	width: 100%;
	height: fit-content;
	text-align: start;
	box-sizing: border-box;
}

.br-wrap-content p {
	width: fit-content;
	margin: auto;
	text-align: center;
	font-size: 19px;
}

.br-wrap-selects {
	width: 100%;
	height: fit-content;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-flow: wrap;
}

.br-wrap-categories,
.br-wrap-shows {
	width: fit-content;
	margin-right: 10px;
}

.br-wrap-categories p,
.br-wrap-shows p,
.br-wrap-description p,
.br-additional-files p {
	font-size: 17px;
	margin: 10px 0px 5px 0px;
	text-decoration: underline;
}

.br-wrap-categories select,
.br-wrap-shows p {
	width: fit-content;
}

.br-wrap-description textarea {
	width: 100%;
	height: 100px;
	box-sizing: border-box;
	font-size: 17px;
}

.br-additional-files {
	margin-bottom: 20px;
}

.br-assets-list {
	width: 100%;
	height: fit-content;
}

.br-wrap-asset {
	display: inline-block;
	text-align: center;
}

.br-wrap-asset p {
	margin: auto;
	text-decoration: none;
	text-align: center;
}

.br-asset {
	width: 90px;
	height: 90px;
	margin: 10px;
	padding: 10px;
	vertical-align: top;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	position: relative;
	border-radius: 10px;
	background-color: var(--main-color-dark-transparent-80);
}

.br-asset img {
	max-width: 70px;
	max-height: 70px;
}

.br-asset:hover .br-remove-asset {
	display: block;
}

.br-remove-asset {
	display: none;
	position: absolute;
	top: 20px;
	left: 30px;
	font-size: 50px;
	color: var(--main-color-error-80);
}

.br-remove-asset:hover {
	cursor: pointer;
	color: var(--main-color-error);
}

.br-report-btn {
	width: 100%;
	height: fit-content;
	text-align: center;
}

.br-report-btn button {
	width: 200px;
	height: 40px;

	font-size: 20px;
}
</style>
