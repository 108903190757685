<template>
	<div class="pr-wrap-content">
		<h1>{{ $t('prProfile') }} {{ $global.getUser().name }}</h1>
		<h2>{{ $t('prProfileStats') }}</h2>
		<div class="pr-wrap-stats">
			<div class="pr-wrap-stat">
				<p>{{ gameHistory.filter((game) => game.place !== -1).length }}</p>
				{{ $t('prQuizNightsPlayed') }}
			</div>
			<div class="pr-wrap-stat">
				<p>{{ getAveragePlace() }}</p>
				{{ $t('prAveragePlace') }}
			</div>
			<div class="pr-wrap-stat">
				<p>{{ quizNights.length }}</p>
				{{ $t('prQuizNightsCreated') }}
			</div>
			<div class="pr-wrap-stat">
				<p>{{ gameHistory.filter((game) => game.place == -1).length }}</p>
				{{ $t('prQuizNightsHosted') }}
			</div>
			<div class="pr-wrap-stat">
				<p>{{ getAverageRating() }}</p>
				{{ $t('prQuizNightRatings') }}
			</div>
		</div>
		<div class="pr-quiz-night-history">
			<h2>{{ $t('prQuizNightHistory') }}</h2>
			<div class="pr-rounded-corners">
				<div class="pr-wrap-last-games">
					<router-link
						v-for="game in gameHistory"
						:key="game.sID"
						:to="{ name: 'Lobby', params: { sID: game.sID } }"
						class="pr-game-played"
					>
						<div :class="[getPlaceClass(game), 'pr-bar']" :title="getPlaceTitle(game)"></div>
						<div class="pr-content">
							<p class="pr-date">{{ $global.parseDate(game.created).split(' ')[0] }}</p>
							<p class="pr-name">
								{{ game.name }}
							</p>
							<div class="pr-wrap-shows">
								<img
									v-for="show in game.shows"
									:key="show.abbreviation"
									:src="require(`@/assets/images/logos/${show.abbreviation.toUpperCase()}Logo.png`)"
									:alt="$t(`${show.name}`)"
									:title="$t(`${show.name}`)"
									class="pr-small-show-logo"
								/>
							</div>
							<p>{{ game.amountPlayers }} {{ $t('prPlayers') }}</p>
							<p>{{ game.uID == $global.getUser().uID ? $t('prYouHosted') : getPlaceAndPoints(game) }}</p>
						</div>
					</router-link>
					<p v-if="gameHistory.length == 0" class="pr-no-games-played">
						{{ $t('prNoQuizNightsPlayed') }}
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Profile',
	data() {
		return {
			gameHistory: [],
			quizNights: [],
		};
	},
	created() {
		this.$global.checkAccess(this.$router, true);
		this.setupComponent();
	},
	methods: {
		setupComponent() {
			let that = this;
			this.$global.getData(
				'user',
				'/getGameHistory',
				{
					headers: { pageauthheader: this.$global.getAccessCode() },
					auth: this.$global.getCredentials(),
					params: { uID: this.$global.getUser().uID },
				},
				null,
				function (err, result) {
					if (err) that.$global.showToast('error', that.$t(err.response ? err.response.data.msg : 'prQuizNightHistoryError'));
					else that.gameHistory = result;
				}
			);

			this.$global.getData(
				'quiz',
				'/getOwnQuizNights',
				{
					headers: { pageauthheader: this.$global.getAccessCode() },
					auth: this.$global.getCredentials(),
					params: { uID: this.$global.getUser().uID },
				},
				null,
				function (err, result) {
					if (err) that.$global.showToast('error', that.$t(err.response ? err.response.data.msg : 'prOwnQuizNightsError'));
					else that.quizNights = result;
				}
			);
		},
		getPlaceAndPoints(game) {
			return `${game.place}. ${this.$t('prPlace')} (${game.points} ${this.$t('prPoints')})`;
		},
		getPlaceClass(game) {
			return game.place == 1 ? 'pr-gold' : game.place == 2 ? 'pr-silver' : game.place == 3 ? 'pr-bronze' : game.place == -1 ? 'pr-host' : '';
		},
		getPlaceTitle(game) {
			return game.place == 1
				? this.$t('prFirstPlace')
				: game.place == 2
				? this.$t('prSecondPlace')
				: game.place == 3
				? this.$t('prThirdPlace')
				: game.place == -1
				? this.$t('prHost')
				: this.$t('prOtherPlace');
		},
		getAveragePlace() {
			let place = 0;
			let playedQuizNights = this.gameHistory.filter((game) => game.place !== -1);
			playedQuizNights.forEach((qn) => {
				place += qn.place;
			});
			if (place !== 0) return Math.round((place / playedQuizNights.length) * 10) / 10;
			else return 0;
		},
		getAverageRating() {
			let rating = 0;
			let amount = 0;
			let ratedQuizNights = this.quizNights.filter((qn) => qn.rating.amount > 0);
			ratedQuizNights.forEach((qn) => {
				rating += qn.rating.stars;
				amount += qn.rating.amount;
			});
			if (rating !== 0) return `${Math.round((rating / ratedQuizNights.length) * 10) / 10} (${amount})`;
			else return `0 (0)`;
		},
	},
};
</script>

<style scoped>
.pr-wrap-content {
	max-width: 100vw;
	height: 100%;
	padding: 0px 10px 20px 10px;
	box-sizing: border-box;
	text-align: center;
	position: relative;
	margin: auto;
	overflow-y: auto;
	overflow-x: hidden;
}

h1 {
	font-size: 50px;
	margin: 0px auto;
	padding: 20px 0px;
	text-shadow: 3px 3px var(--main-color-6);
	-webkit-text-stroke: 2px var(--main-color-text-dark);
	text-align: center;
}

h2 {
	font-size: 40px;
	margin: 0px auto;
	padding: 20px 0px;
	text-shadow: 3px 3px var(--main-color-6);
	-webkit-text-stroke: 2px var(--main-color-text-dark);
	text-align: center;
}

.pr-wrap-stats {
	width: 100%;
	height: fit-content;
	margin: 10px auto;
	display: flex;
	justify-content: center;
	flex-flow: wrap;
}

.pr-wrap-stat {
	flex: 1 1 100px;
	margin: 10px 30px;
	padding: 20px;
	font-size: 20px;
	border-radius: 50px;
	background-color: var(--main-color-6-cc);
	color: var(--main-color-text-light);
}

.pr-wrap-stat p {
	font-size: 40px;
	color: var(--main-color-text-light);
}

.pr-quiz-night-history {
	width: 90%;
	height: fit-content;
	margin: auto;
}

.pr-rounded-corners {
	width: 100%;
	height: fit-content;
	background-color: var(--main-color-4-cc);
	border: 2px solid var(--main-color-border-dark);
	border-radius: 20px;
	box-sizing: border-box;
	overflow: hidden;
}

.pr-wrap-last-games {
	max-height: 400px;
	padding: 20px;
	overflow: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow: wrap;
}

.pr-game-played {
	min-height: 80px;
	flex: 1 1 90%;
	padding: 0px;
	border-bottom: 2px solid var(--main-color-border-light);
	text-decoration: none;
	text-align: start;
}

.pr-game-played:hover {
	border-bottom: 2px solid var(--main-color-6);
}

.pr-bar {
	min-height: 120px;
	height: 100%;
	width: 13px;
	margin-right: 10px;
	border: 2px solid var(--main-color-border-dark);
	box-sizing: border-box;
	vertical-align: top;
	float: left;
	background-color: var(--main-color-3);
}

.pr-gold {
	background-color: var(--main-color-gold);
}

.pr-silver {
	background-color: var(--main-color-silver);
}

.pr-bronze {
	background-color: var(--main-color-bronze);
}

.pr-host {
	background-color: var(--main-color-success);
}

.pr-content {
	padding: 10px 0px;
	vertical-align: top;
}

.pr-game-played p {
	vertical-align: top;
	font-size: 17px;
}

.pr-name {
	font-size: 20px;
	margin-bottom: 5px;
}

.pr-small-show-logo {
	width: 30px;
	height: 30px;
	margin-right: 10px;
	display: inline-block;
	border-radius: 10px;
}

.pr-date {
	float: right;
	vertical-align: top;
}
</style>
