<template>
	<div class="qnp-wrap-content" @click="$emit('configureQuizNight', night)">
		<p class="qnp-name">{{ night.name }}</p>
		<div class="qnp-top-right">
			<p class="qnp-stars">
				<fai icon="fas fa-star"></fai>
				{{ night.rating.stars }} ({{ night.rating.amount }})
			</p>
			<p class="qnp-play-amount"><fai icon="fas fa-play-circle"></fai> {{ night.amountPlayed }}</p>
		</div>
		<p class="qnp-creator" :title="$t('qnpCreator')">
			<fai icon="fas fa-user"></fai>
			{{ night.user.uID == this.$global.getUser().uID ? this.$t('qnpYou') : night.user.name }}
			({{ night.isPublic ? this.$t('qpPublic') : night.isPrivate ? this.$t('qpPrivate') : this.$t('qpNot') }} {{ $t('qnpIsPublic') }})
		</p>
		<p class="qnp-playable"></p>
		<p class="qnp-duration">
			<fai icon="fas fa-clock"></fai> {{ $t('spEstimated') }} {{ night.estimatedDuration }} {{ $t('spMin') }} ({{ $t('qnpFor4Players') }})
		</p>
		<div class="qnp-wrap-shows">
			<img
				v-for="show in night.shows"
				:key="show.abbreviation"
				:src="require(`@/assets/images/logos/${show.abbreviation.toUpperCase()}Logo.png`)"
				:alt="$t(`${show.name}`)"
				:title="$t(`${show.name}`)"
				class="qnp-small-show-logo"
			/>
		</div>
		<p class="qnp-last-updated" :title="$t('qnpLastUpdated')">{{ this.$global.parseDate(night.lastUpdated, true, false) }}</p>
	</div>
</template>

<script>
export default {
	name: 'QuizNightPreview',
	props: {
		night: {
			type: Object,
			required: true,
		},
	},
};
</script>

<style scoped>
.qnp-wrap-content {
	width: 100%;
	margin: 10px 0px;
	padding: 5px 10px;
	box-sizing: border-box;
	display: inline-block;
	position: relative;
	background-color: var(--main-color-5);
	box-shadow: 2px 2px 4px 4px var(--main-color-border-dark);
	color: var(--main-color-text-light);
}

.qnp-wrap-content:hover {
	cursor: pointer;
	background-color: var(--main-color-6-cc);
	color: var(--main-color-text-light);
}

.qnp-wrap-content:hover .qnp-name {
	text-decoration: underline var(--main-color-text-light);
}

.qnp-wrap-content:hover .qnp-stars svg {
	color: var(--main-color-text-light);
}

.qnp-top-right {
	position: absolute;
	top: 5px;
	right: 5px;
}

.qnp-top-right p {
	width: fit-content;
	margin: 0px 5px 5px 0px;
	display: inline-block;
}

.qnp-top-right svg {
	filter: drop-shadow(1px 1px 1px var(--main-color-border-dark));
}

.qnp-stars svg {
	color: var(--main-color-warn);
}

.qnp-last-updated {
	width: 100%;
	display: block;
	text-align: end;
}

.qnp-creator {
	display: inline-block;
	margin: 0px 0px 5px 0px;
}

.qnp-name {
	margin-bottom: 10px;
	display: block;
	font-size: 20px;
	text-decoration: underline var(--main-color-6);
}

.qnp-wrap-shows {
	width: 100%;
	height: fit-content;
	margin-top: 5px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.qnp-small-show-logo {
	width: 30px;
	height: 30px;
	margin-right: 10px;
	display: inline-block;
	border-radius: 10px;
}
</style>
