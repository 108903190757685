<template>
	<div class="pps-wrap-content">
		<div class="pps-selected-image">
			<img :src="require(`@/assets/images/profilePictures/${selectedImage.src}`)" :alt="selectedImage.alt" />
		</div>
		<div v-for="(image, idx) in images" :key="image.src" :id="`img${idx}`" class="pps-wrap-image">
			{{ assignGrid(idx) }}
			<img
				v-if="!image.selected"
				:src="require(`@/assets/images/profilePictures/${image.src}`)"
				:alt="image.alt"
				@click="selectImage(idx, image)"
			/>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ProfilePictureSelection',
	props: {
		profilePic: {
			type: String,
			required: false,
		},
	},
	data() {
		return {
			images: [
				{ src: 'Bumblebee.png', alt: this.$t('ppsBumblebee') },
				{ src: 'Cat.png', alt: this.$t('ppsCat') },
				{ src: 'Chinchilla.png', alt: this.$t('ppsChinchilla') },
				{ src: 'Degu.png', alt: this.$t('ppsDegu') },
				{ src: 'Dog.png', alt: this.$t('ppsDog') },
				{ src: 'Hummingbird.png', alt: this.$t('ppsHummingbird') },
				{ src: 'Koala.png', alt: this.$t('ppsKoala') },
				{ src: 'Puffer.png', alt: this.$t('ppsPuffer') },
				{ src: 'RedPanda.png', alt: this.$t('ppsRedPanda') },
				{ src: 'Unicorn.png', alt: this.$t('ppsUnicorn') },
				{ src: 'Squirrel.png', alt: this.$t('ppsSquirrel') },
			],
			basicImage: { src: 'ProfilePicture.png', alt: this.$t('ppsProfilePicture') },
			selectedImage: { src: 'ProfilePicture.png', alt: this.$t('ppsProfilePicture') },
		};
	},
	created() {
		this.$emit('selectedImage', this.selectedImage);
	},
	mounted() {
		if (this.profilePic) {
			let idx = this.images.findIndex((img) => img.src === this.profilePic);
			this.selectImage(idx, this.images[idx]);
		}
	},
	methods: {
		assignGrid(idx) {
			this.$nextTick(() => {
				document.getElementById(`img${idx}`).setAttribute('grid-area', `img${idx}`);
				document.getElementById(`img${idx}`).style = `grid-area: img${idx}`;
			});
		},
		selectImage(idx, image) {
			if (this.selectedImage.src == image.src) {
				this.selectedImage = this.basicImage;
				document.getElementById(`img${idx}`).classList.remove('pps-greyed-out');
			} else {
				this.selectedImage = image;
				document.querySelectorAll('.pps-wrap-image').forEach((element) => {
					element.classList.remove('pps-greyed-out');
				});
				document.getElementById(`img${idx}`).classList.add('pps-greyed-out');
			}

			this.$emit('selectedImage', this.selectedImage);
		},
	},
};
</script>

<style scoped>
.pps-wrap-content {
	width: 100%;
	height: fit-content;
	text-align: start;
	display: inline-grid;
	grid-template-columns: 10% 10% 10% 10% 10% 10% 10% 10% 10% 10%;
	grid-template-rows: 10%;
	grid-template-areas:
		'sel sel sel sel img0 img0 img1 img1 img2 img2'
		'sel sel sel sel img0 img0 img1 img1 img2 img2'
		'sel sel sel sel img3 img3 img4 img4 img5 img5'
		'sel sel sel sel img3 img3 img4 img4 img5 img5'
		'img6 img6 img7 img7 img8 img8 img9 img9 img10 img10'
		'img6 img6 img7 img7 img8 img8 img9 img9 img10 img10';
}

.pps-selected-image {
	vertical-align: top;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	margin: 10px 5px 5px 10px;
	border: 5px solid var(--main-color-4);
	background-color: var(--main-color-1);
	grid-area: sel;
	border-radius: 20px;
}

.pps-selected-image img {
	width: 100%;
	height: 100%;
	border-radius: 20px;
	border: 5px solid var(--main-color-4);
}

.pps-selected-image svg {
	font-size: 150px;
	color: var(--main-color-4);
}

.pps-wrap-image {
	margin: 10px;
	display: inline-block;
	vertical-align: top;
	cursor: pointer;
}

.pps-wrap-image img {
	width: 100%;
	height: 100%;
	border: 5px solid var(--main-color-border-dark);
	border-radius: 20px;
}

.pps-greyed-out {
	border-radius: 20px;
}

.pps-greyed-out img {
	border: 5px solid var(--main-color-6);
	-webkit-filter: grayscale(75%);
	-moz-filter: grayscale(75%);
	-o-filter: grayscale(75%);
	-ms-filter: grayscale(75%);
	filter: grayscale(75%);
}
</style>
