<template>
	<div :id="`preview-${config.show}`" class="gscp-wrap-content">
		<!-- @click="state.viewMode ? $emit('showQuestionPreview') : null" :class="['gscp-wrap-content', state.viewMode ? 'gscp-clickable' : '']" -->
		<div class="gscp-wrap-top">
			<div v-if="!state.isPrivate && !state.isPublic" class="gscp-wrap-icons">
				<fai icon="fas fa-angle-up" class="gscp-up" @click="$emit('moveConfigUp', config)"></fai>
				<fai icon="fas fa-angle-down" class="gscp-down" @click="$emit('moveConfigDown', config)"></fai>
				<fai icon="fas fa-pen" class="gscp-edit" @click="editConfig"></fai>
				<fai icon="fas fa-trash" class="gscp-delete" @click="$emit('deleteConfig', config)"></fai>
			</div>
			<h3 class="gscp-show-topic" :title="$t('gscpTopic')">
				<img
					:src="require(`@/assets/images/logos/${config.show.toUpperCase()}Logo.png`)"
					:alt="getShowName(config.show)"
					:title="getShowName(config.show)"
					class="gscp-show-logo"
				/>
				<span v-if="['xfz'].includes(config.show)">
					{{ config.position + 1 }}. {{ getShowName(config.show).replace('X', config.questions.length) }}
					<span v-if="['xfz'].includes(config.show)">{{ `"${config.topic}"` }}</span>
				</span>
				<span v-else> {{ config.position + 1 }}. {{ getShowName(config.show) }} </span>
			</h3>
		</div>
		<div class="gscp-wrap-bottom">
			<p v-if="['wlp'].includes(config.show)" :title="$t('gscpMoney')">{{ config.money }}<fai icon="fas fa-coins" class="gscp-money"></fai></p>
			<p v-if="['oh', 'wbm'].includes(config.show)" :title="$t('gscpLives')">
				{{ config.lives }}<fai icon="fas fa-heart" class="gscp-lives"></fai>
			</p>
			<p v-if="['oh', 'dpin', 'smf', 'sq', 'xfz', 'lz', 'zgw'].includes(config.show)" :title="$t('gscpPointsPos')">
				{{ config.pointsPos }} {{ $t('gscpPointsAbbr') }}<fai icon="fas fa-check" class="gscp-pos"></fai>
			</p>
			<p v-if="['smf', 'xfz', 'lz'].includes(config.show)" :title="$t('gscpPointsNeg')">
				{{ config.pointsNeg }} {{ $t('gscpPointsAbbr') }}<fai icon="fas fa-times" class="gscp-neg"></fai>
			</p>
			<p v-if="['wlp'].includes(config.show)" :title="$t('gscpPointsWin')">
				{{ config.pointsWin }} {{ $t('gscpPointsAbbr') }}<fai icon="fas fa-trophy" class="gscp-win"></fai>
			</p>
			<p v-if="['wbm', 'zgw', 'sq'].includes(config.show)" :title="$t('gscpTimer')">
				{{ config.timer }}s<fai icon="as fa-stopwatch" class="f gscp-timer"></fai>
			</p>
			<p v-if="['kq'].includes(config.show)" class="gscp-categories" :title="$t('gscpCategories')">
				{{ config.questions.length }}<span class="material-icons">category</span>
			</p>
			<p v-if="['lz'].includes(config.show)" class="gscp-categories" :title="$t('gscpCategories')">
				{{ config.questions[0].categories.length }}<span class="material-icons">category</span>
			</p>
			<p v-if="['kq'].includes(config.show)" :title="$t('gscpJoker')">{{ config.amountJoker }}<span class="gscp-joker">&#x1F0CF;</span></p>
			<p v-if="['kq'].includes(config.show)" :title="$t('gscpQuestions')">
				{{ config.questions[0].questions.length }}<fai icon="fas fa-question" class="gscp-questions"></fai>
			</p>
			<p v-if="['kq'].includes(config.show)" class="gscp-mult-ans" :title="$t('gscpMultipleAnswersPerCat')">
				<fai icon="fas fa-tasks" class="gscp-multi-list"></fai>
				{{ config.multipleAnswersPerCat ? $t('gscpYes') : $t('gscpNo') }}
			</p>
			<p v-if="['kq'].includes(config.show)" class="gscp-point-level" :title="$t('gscpPointsPerLevel')">
				<fai icon="fas fa-stairs" class="gscp-stairs"></fai>
				{{ getPointsPerLevel(config.pointsPerLevel) }} {{ $t('gscpPointsAbbrv') }}
			</p>
			<p v-if="['lz'].includes(config.show)" :title="$t('gscpQuestions')">
				{{ config.questions[0].answerPos.length }}<fai icon="fas fa-question" class="gscp-questions"></fai>
			</p>
			<p v-if="['dpin', 'oh', 'smf', 'sq', 'wbm', 'wlp', 'xfz', 'zgw'].includes(config.show)" :title="$t('gscpQuestions')">
				{{ config.questions.length }}<fai icon="fas fa-question" class="gscp-questions"></fai>
			</p>
			<div class="gscp-wrap-description" v-if="state.showDescription">
				<p>
					{{ getShowDescription(config.show) }}
				</p>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'GameShowConfigPreview',
	props: {
		config: {
			type: Object,
			required: true,
		},
		shows: {
			type: Array,
			required: true,
		},
		state: {
			type: Object,
			required: true,
		},
		editedConfig: {
			type: Object,
			required: false,
		},
	},
	watch: {
		config: {
			handler: function () {
				let elements = [...document.querySelectorAll('.gscp-wrap-content')];
				elements.forEach((element) => {
					element.classList.remove('gscp-edited');
				});
			},
			deep: true,
		},
		editedConfig: {
			handler: function () {
				if (this.editedConfig && this.editedConfig.show == this.config.show) this.colorPreview();
			},
			deep: true,
		},
	},
	mounted() {
		if (this.state.showDescription) this.adjustLayout();
		if (this.editedConfig && this.editedConfig.show == this.config.show) this.colorPreview();
	},
	methods: {
		adjustLayout() {
			let elements = [...document.querySelectorAll('.gscp-wrap-content')];
			elements.forEach((element) => {
				element.classList.add('gscp-description');
			});
		},
		getShowName(show) {
			return this.$t(this.shows.filter((s) => s.abbreviation == show)[0].name);
		},
		getShowDescription(show) {
			return this.$t(this.shows.filter((s) => s.abbreviation == show)[0].description);
		},
		getPointsPerLevel(points) {
			points = points.map((p) => {
				return p.points;
			});
			return points.join(', ');
		},
		colorPreview() {
			let elements = [...document.querySelectorAll('.gscp-wrap-content')];
			elements.forEach((element) => {
				element.classList.remove('gscp-edited');
			});

			document.getElementById(`preview-${this.config.show}`).classList.add('gscp-edited');
		},
		editConfig(e) {
			this.$emit('editConfig', this.config);
		},
	},
};
</script>

<style scoped>
.gscp-wrap-content {
	width: fit-content;
	margin: 5px 15px 5px 0px;
	padding: 7px;
	display: inline-block;
	flex: 1 1 350px;
	vertical-align: top;
	border: 1px solid var(--main-color-border-dark);
	background-color: var(--main-color-4-cc);
	box-shadow: 2px 2px 4px 4px var(--main-color-border-dark);
	box-sizing: border-box;
	text-align: start;
}

.gscp-wrap-content p {
	width: fit-content;
	display: inline-block;
	margin: 0px 10px 0px 0px;
	font-size: 25px;
}

.gscp-description {
	flex: 1 1 9999px;
	text-align: center;
}

.gscp-wrap-bottom {
	margin: 5px 10px;
	text-align: start;
}

.gscp-wrap-description {
	margin-top: 10px;
	padding-top: 10px;
	border-top: 1px solid var(--main-color-1);
	text-align: center;
}

.gscp-wrap-description p {
	font-size: 20px;
}

.gscp-wrap-icons {
	width: 110px;
	float: right;
	text-align: end;
	display: flex;
	justify-content: center;
	align-items: center;
	border-left: 2px solid var(--main-color-border-light);
}

.gscp-wrap-icons svg {
	margin: 0px 2px;
	cursor: pointer;
	vertical-align: top;
	-webkit-filter: drop-shadow(3px 3px 0px var(--main-color-text-dark));
	filter: drop-shadow(3px 3px 0px var(--main-color-text-dark));
}

.gscp-up {
	font-size: 30px;
	color: var(--main-color-text-light);
}

.gscp-up:hover {
	color: var(--main-color-6);
}

.gscp-down {
	font-size: 30px;
	color: var(--main-color-text-light);
}

.gscp-down:hover {
	color: var(--main-color-6);
}

.gscp-edit {
	font-size: 20px;
	color: var(--main-color-warn);
}

.gscp-edit:hover {
	color: var(--secondary-color-warn);
}

.gscp-delete {
	font-size: 20px;
	color: var(--main-color-error);
}

.gscp-delete:hover {
	color: var(--secondary-color-error);
}

.gscp-wrap-top {
	margin-bottom: 10px;
}

.gscp-show-topic {
	font-size: 25px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

p svg {
	-webkit-filter: drop-shadow(3px 3px 0px var(--main-color-text-dark));
	filter: drop-shadow(3px 3px 0px var(--main-color-text-dark));
	margin-left: 3px;
	vertical-align: top;
}

.gscp-money {
	color: #ffd700;
}

.gscp-lives {
	color: #ff0000;
}

.gscp-pos {
	color: #00ff00;
}

.gscp-neg {
	color: #ff0000;
	font-size: 30px;
}

.gscp-win {
	color: #ffd700;
}

.gscp-timer {
	color: #ffffff;
}

.gscp-categories span {
	color: #800080;
	font-size: 30px;
	vertical-align: bottom;
	-webkit-text-stroke: 1px var(--main-color-text-dark);
	text-shadow: 2px 2px var(--main-color-text-dark);
}

.gscp-questions {
	color: #00a0a0;
}

.gscp-edited {
	border: 1px solid var(--main-color-border-dark);
	background-color: var(--main-color-6-cc);
}

.gscp-show-logo {
	width: 50px;
	height: 50px;
	margin: 0px 10px 0px 5px;
	display: inline-block;
	border-radius: 10px;
}

.gscp-clickable:hover {
	cursor: pointer !important;
	background-color: var(--main-color-6-cc);
}

.gscp-multi-list {
	color: var(--main-color-6);
}

.gscp-stairs {
	color: var(--main-color-success);
}
</style>
