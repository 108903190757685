<template>
	<div class="iu-wrap-content" @dragenter="checkDragEnter">
		<div v-show="src && src.length > 0" class="iu-wrap-image-preview">
			<img v-show="imageIsValid" :id="imgID" :src="src" :alt="$t('iuImagePreview')" />
			<fai v-if="!imageIsValid" icon="fas fa-times"></fai>
			<p v-if="!imageIsValid" class="iu-broken-image">{{ $t('iuBrokenImage') }}</p>
		</div>
		<div v-if="!src || src.length == 0" class="iu-wrap-image-preview">
			<fai icon="fas fa-image"></fai>
			<p>{{ $t('iuNoImage') }}</p>
		</div>
		<div v-show="showUpload" class="iu-wrap-upload">
			<div class="iu-hover-div" @dragover="checkDragOver" @dragleave="checkDragLeave" @drop="handleUpload"></div>
			<div class="iu-dnd-background">
				<div>
					<fai icon="fas fa-file-image"></fai>
					<p>{{ $t('iuUploadFileDND') }}</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ImageUpload',
	props: {
		imgID: {
			type: String,
			required: true,
		},
		link: {
			type: String,
			required: false,
		},
		src: {
			type: String,
			required: false,
		},
	},
	watch: {
		link: {
			handler: function (newVal, oldVal) {
				if (newVal.length > 0) this.checkLink();
			},
		},
		src: {
			handler: function (newVal, oldVal) {
				if (newVal.length > 0) this.imageIsValid = true;
			},
		},
	},
	data() {
		return {
			allowedMimeTypes: ['image/jpeg', 'image/png', 'image/webp', 'image/avif', 'image/bmp'],
			showUpload: false,
			imageIsValid: false,
		};
	},
	mounted() {
		if (this.src && this.src.length > 0) this.imageIsValid = true;
	},
	methods: {
		// @vuese
		// Catches the drag enter event and displays the upload div
		// @arg e[Object] - The event that occurred
		checkDragEnter(e) {
			this.showUpload = true;
		},
		// @vuese
		// Catches the drag over event and displays the upload div
		// @arg e[Object] - The event that occurred
		checkDragOver(e) {
			e.preventDefault();
			e.dataTransfer.dropEffect = 'copy';
			this.showUpload = true;
		},
		// @vuese
		// Catches the drag leave event and hides the upload div
		// @arg e[Object] - The event that occurred
		checkDragLeave(e) {
			e.preventDefault();
			this.showUpload = false;
		},
		// @vuese
		// Handles the upload. Catches events from the drag and drop div
		// @arg e[Object] - The event that occurred
		handleUpload(e) {
			e.preventDefault();
			this.showUpload = false;

			let file = e.dataTransfer.files.length > 0 ? e.dataTransfer.files[0] : null;
			if (file) {
				if (this.allowedMimeTypes.includes(file.type.toLowerCase())) {
					this.$emit('imageIsValid', true);
					this.imageIsValid = true;

					const reader = new FileReader();

					reader.onload = (load) => {
						this.$emit('imageParsed', load.target.result);
					};
					reader.onerror = (error) => {
						console.log(error);

						this.$global.showToast('error', this.$t('iuUploadUnsuccessfull'));
						this.imageIsValid = false;
						this.$emit('imageIsValid', false);
						this.$emit('imageParsed', null);
					};

					// Read the file as a data URL
					reader.readAsDataURL(file);
				} else this.$global.showToast('warn', this.$t('iuTypeNotSupported'));
			} else this.$global.showToast('error', this.$t('iuUploadUnsuccessfull'));
		},
		checkLink() {
			let that = this;

			let img = new Image();
			img.onload = function (load) {
				that.$emit('imageIsValid', true);
				that.imageIsValid = true;

				that.parseImage(that.link)
					.then((dataUrl) => {
						that.$emit('imageParsed', dataUrl);
					})
					.catch((error) => {
						console.log(error);

						that.imageIsValid = false;
						that.$emit('imageIsValid', false);
						that.$emit('imageParsed', null);
						that.$global.showToast('error', that.$t('iuUploadUnsuccessfull'));
					});
			};
			img.onerror = function (error) {
				console.log(error);

				that.$emit('imageIsValid', false);
				that.imageIsValid = false;
			};
			img.src = this.link;
		},
		async parseImage(url) {
			return fetch(url)
				.then((response) => response.blob())
				.then((blob) => {
					if (this.allowedMimeTypes.includes(blob.type.toLowerCase())) {
						return new Promise((resolve, reject) => {
							const reader = new FileReader();
							reader.onloadend = () => resolve(reader.result);
							reader.onerror = reject;
							reader.readAsDataURL(blob);
						});
					} else {
						this.$emit('imageIsValid', true);
						this.imageIsValid = true;
						this.$global.showToast('warn', this.$t('iuTypeNotSupported'));
					}
				});
		},
	},
};
</script>

<style scoped>
.iu-wrap-content {
	width: 100%;
	min-width: 350px;
	height: 100%;
	text-align: center;
	color: var(--main-color-text-light);
	margin-bottom: 20px;
	position: relative;
}

.iu-wrap-image-preview {
	width: 300px;
	height: 300px;
	display: inline-flex;
	vertical-align: top;
	margin: auto;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	position: relative;
}

.iu-wrap-image-preview svg {
	font-size: 200px;
	color: var(--main-color-6);
	filter: drop-shadow(2px 2px 2px var(--main-color-border-dark));
}

.iu-wrap-image-preview p {
	width: 100%;
	font-size: 30px;
	margin: 0px;
}

.iu-wrap-image-preview img {
	max-width: 100%;
	max-height: 100%;
	border: 1px solid var(--main-color-border-dark);
	border-radius: 10px;
	box-shadow: 4px 4px 4px 4px var(--main-color-border-dark);
}

.iu-wrap-upload {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0px;
	left: 0px;
	display: block;
	box-sizing: content-box;
	border: 3px solid var(--main-color-border-dark);
	border-radius: 10px;
	/* background-color: var(--main-color-3); */
}

.iu-dnd-background {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1;
	border-radius: 10px;
	background-color: var(--main-color-5-cc);
}

.iu-dnd-background div {
	width: fit-content;
	max-width: 90%;
	height: fit-content;
	text-align: center;
	overflow: hidden;
}

.iu-dnd-background div svg {
	margin-bottom: 10px;
	font-size: 50px;
	color: var(--main-color-text-light);
}

.iu-dnd-background div p {
	display: block;
	font-size: 30px;
	color: var(--main-color-text-light);
}

.iu-hover-div {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0px;
	left: 0px;
	box-sizing: border-box;
	z-index: 4;
}
</style>
