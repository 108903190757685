<template>
	<div class="wr-wrap-content">
		<h1>{{ $t('wrWaitingRoom') }}</h1>
		<div class="wr-wrap-users">
			<UserElement
				v-if="host"
				:user="host"
				:isHost="true"
				:sessionReady="sessionReady"
				:sessionRoom="sessionRoom"
				:sessionState="sessionState"
				:configuredShows="configuredShows"
				@startShow="$emit('startShow', $event)"
			/>
			<UserElement
				v-for="player in players"
				:key="player.uID"
				:user="player"
				:sessionRoom="sessionRoom"
				@removeUser="$emit('removeUserFromSession', $event)"
				@playerReady="playerReady"
				@playerNotReady="playerNotReady"
			/>
		</div>
		<div class="wr-wrap-leaderboard">
			<Leaderboard :leaderboard="leaderboard" :players="players" />
		</div>
		<div v-if="hasEnoughData" class="wr-wrap-points-graph">
			<h2>{{ $t('poGameCourse') }}</h2>
			<PointsGraph :shows="configuredShows" :history="history" :players="players" />
		</div>
		<div class="wr-wrap-next-show">
			<GameShowConfigList
				v-if="sessionState"
				:registeredShows="shows"
				:configuredShows="
					configuredShows.filter(
						(conf) => conf.show == (sessionState.currentState !== 'lobby' ? sessionState.currentState : sessionState.nextState)
					)
				"
				:state="{ lobby: true, showDescription: true, isPrivate: true, isPublic: true }"
			/>
		</div>
		<!-- <div v-if="$global.isHost()" class="wr-wrap-example-question">
			<p>{{ $t('wrPlayExample') }}</p>
			<input type="checkbox" v-model="playExample" />
		</div> -->
	</div>
</template>

<script>
import UserElement from './UserElement.vue';
import Leaderboard from './Leaderboard.vue';
import GameShowConfigList from '../quizNightCreation/GameShowConfigList.vue';
import PointsGraph from './PointsGraph.vue';
export default {
	name: 'WaitingRoom',
	components: {
		UserElement,
		Leaderboard,
		GameShowConfigList,
		PointsGraph,
	},
	props: {
		players: {
			type: Array,
			required: true,
		},
		configuredShows: {
			type: Array,
			required: true,
		},
		shows: {
			type: Array,
			required: true,
		},
		leaderboard: {
			type: Array,
			required: true,
		},
		history: {
			type: Array,
			required: true,
		},
		sessionState: {
			type: Object,
			required: false,
		},
		sessionRoom: {
			type: Object,
			required: true,
		},
		host: {
			type: Object,
			required: false,
		},
		sessionReady: {
			type: Boolean,
			required: true,
		},
	},
	data() {
		return {
			socket: null,
			playExample: false,
		};
	},
	computed: {
		hasEnoughData: function () {
			return this.history.find((his) => his.action == 'setState' && his.result != 'lobby');
		},
	},
	created() {
		this.initiateSocket();
	},
	methods: {
		initiateSocket() {
			let that = this;
			this.$socket.getSocket(function (socket) {
				that.socket = socket;
			});
		},
		playerReady(uID) {
			this.$socket.emitEvent('player_ready', { sID: this.$route.params.sID, uID: uID });
		},
		playerNotReady(uID) {
			this.$socket.emitEvent('player_not_ready', { sID: this.$route.params.sID, uID: uID });
		},
	},
};
</script>

<style scoped>
.wr-wrap-users {
	width: 100%;
	height: fit-content;
	display: flex;
	flex-flow: wrap;
	margin-bottom: 20px;
}

.wr-wrap-leaderboard,
.wr-wrap-next-show {
	width: 100%;
	height: fit-content;
	padding: 20px 5vw;
	box-sizing: border-box;
}

.wr-wrap-points-graph {
	width: 90%;
	margin: 20px auto;
	padding: 10px;
}

.wr-wrap-points-graph h2 {
	font-size: 40px;
	margin: 0px auto;
	padding: 0px 0px;
	text-shadow: 3px 3px var(--main-color-6);
	-webkit-text-stroke: 2px var(--main-color-text-dark);
	text-align: center;
}
</style>
