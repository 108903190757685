<template>
	<div class="hp-wrap-content">
		<a id="Top" name="Top"></a>
		<h1>{{ $t('hpHelp') }}</h1>
		<div class="hp-wrap-cards">
			<div v-if="$global.getUser()" class="hp-card hp-bug-card" @click="scrollTo('#FeedbackBugReport')">
				<div>
					<fai icon="fas fa-bug" />
					<p>{{ $t('hpFeedbackBugReport') }}</p>
				</div>
			</div>
			<div class="hp-card hp-question-card" @click="scrollTo('#QuestionCreation')">
				<div>
					<fai icon="fas fa-question" />
					<p>{{ $t('hpQuestionCreation') }}</p>
				</div>
			</div>
			<div class="hp-card hp-quiz-night-card" @click="scrollTo('#QuizNightCreation')">
				<div>
					<fai icon="fas fa-cogs" />
					<p>{{ $t('hpQuizNightCreation') }}</p>
				</div>
			</div>
			<div class="hp-card hp-lobby-card" @click="scrollTo('#LobbyHosting')">
				<div>
					<fai icon="fas fa-couch" />
					<p>{{ $t('hpLobbyHosting') }}</p>
				</div>
			</div>
		</div>
		<a name="FeedbackBugReport"></a>
		<div v-if="$global.getUser()" class="hp-content-block hp-dark-block">
			<h2>{{ $t('hpFeedbackBugReport') }}</h2>
			<BugReportForm v-if="shows" :shows="shows" />
		</div>
		<a name="QuestionCreation"></a>
		<div class="hp-content-block hp-light-block">
			<h2>{{ $t('hpQuestionCreation') }}</h2>
			<QuestionCreationHelp v-if="shows" :shows="shows" />
		</div>
		<a name="QuizNightCreation"></a>
		<div class="hp-content-block hp-dark-block">
			<h2>{{ $t('hpQuizNightCreation') }}</h2>
			<QuizNightCreationHelp v-if="shows" :shows="shows" />
		</div>
		<a name="LobbyHosting"></a>
		<div class="hp-content-block hp-light-block">
			<h2>{{ $t('hpLobbyHosting') }}</h2>
			<LobbyHostingHelp v-if="shows" :shows="shows" />
		</div>
		<div id="scrollUp" class="hp-scroll-up" @click="scrollToTop">
			<fai icon="fas fa-arrow-up"></fai>
			<p class="hp-up-text">{{ $t('hpBackUp') }}</p>
		</div>
	</div>
</template>

<script>
import BugReportForm from '../components/help/BugReportForm.vue';
import QuestionCreationHelp from '../components/help/QuestionCreationHelp.vue';
import QuizNightCreationHelp from '../components/help/QuizNightCreationHelp.vue';
import LobbyHostingHelp from '../components/help/LobbyHostingHelp.vue';
export default {
	name: 'Help',
	components: {
		BugReportForm,
		QuestionCreationHelp,
		QuizNightCreationHelp,
		LobbyHostingHelp,
	},
	data() {
		return {
			shows: null,
		};
	},
	created() {
		this.$global.checkAccess(this.$router, false);
		this.getRegisteredShows();
	},
	methods: {
		getRegisteredShows() {
			let that = this;
			this.$global.getData(
				'quiz',
				'/getRegisteredShows',
				{
					headers: { pageauthheader: this.$global.getAccessCode() },
					auth: this.$global.getCredentials(),
				},
				null,
				function (err, result) {
					if (err) that.$global.showToast('error', that.$t(err.response ? err.response.data.msg : 'qcQueryError'));
					else {
						that.shows = result;
					}
				}
			);
		},
		// @vuese
		// Scrolls the view to a certain destination
		// @arg dest[String] - The location hash
		scrollTo(dest) {
			location.hash = dest;
		},
		scrollToTop() {
			location.hash = '';
			document.querySelector('#Top').scrollIntoView({ behavior: 'smooth' });
		},
	},
};
</script>

<style scoped>
.hp-wrap-content {
	max-width: 100vw;
	height: 100%;
	text-align: center;
	position: relative;
	margin: auto;
	overflow-y: auto;
	overflow-x: hidden;
}

.hp-wrap-content h2 {
	font-size: 40px;
	margin: 0px auto;
	padding: 20px 0px;
	text-shadow: 3px 3px var(--main-color-6);
	-webkit-text-stroke: 2px var(--main-color-text-dark);
	text-align: center;
}

.hp-wrap-cards {
	width: 100%;
	max-width: 90vw;
	margin: auto;
	display: flex;
	justify-content: center;
	flex-flow: wrap;
	align-items: center;
}

.hp-card {
	flex: 1 1 calc(50% - 10px);
	height: 10vh;
	min-height: 100px;
	margin: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	transition: transform 0.5s;
	border: 2px solid var(--main-color-border-dark);
}

.hp-card:hover {
	cursor: pointer;
	transform: scale(1.1);
	z-index: 5;
}

.hp-card div {
	text-align: center;
}

.hp-card svg {
	margin: 5px;
	font-size: 45px;
	color: var(--main-color-text-light);
	filter: drop-shadow(2px 2px 2px var(--main-color-border-dark));
}

.hp-card p {
	font-size: 20px;
	text-shadow: 1px 1px var(--main-color-text-dark);
}

.hp-bug-card {
	background-color: var(--main-color-error-cc);
}

.hp-question-card {
	background-color: var(--main-color-info-cc);
}

.hp-quiz-night-card {
	background-color: var(--main-color-success-cc);
}

.hp-lobby-card {
	background-color: var(--main-color-purple-cc);
}

.hp-scroll-up {
	display: block;
	position: fixed;
	bottom: 0px;
	right: 10px;
	text-align: center;
	padding: 10px;
}

.hp-scroll-up svg {
	color: var(--main-color-text-light);
	font-size: 30px;
}

.hp-scroll-up:hover svg,
.hp-scroll-up:hover .hp-up-text {
	cursor: pointer;
	color: var(--main-color-6);
	filter: drop-shadow(1px 1px 1px var(--main-color-border-dark));
}

.hp-up-text {
	color: var(--main-color-text-light);
	font-size: 15px !important;
	width: 80px;
	cursor: pointer;
}

.hp-content-block {
	width: 100%;
	margin: auto;
	padding: 20px 5vw;
	box-sizing: border-box;
}

.hp-dark-block {
	background-color: var(--main-color-dark-transparent-80);
	box-shadow: 0px 0px 10px 10px var(--main-color-border-dark);
}

.hp-light-block {
	background-color: #ffffff3a;
}
</style>
