<template>
	<div class="gs-wrap-content">
		<div class="gs-wrap-top">
			<h1 v-if="show.show == 'xfz'">{{ $t(`${show.show}Name`).replace('X', show.questions.length) }} {{ `"${show.topic}"` }}</h1>
			<h1 v-else>
				{{ $t(`${show.show}Name`) }}
			</h1>
			<div v-if="showQuestionCondition()" class="gs-question-panel">
				<!-- Question -->
				<h2 v-if="['dpin', 'xfz', 'smf', 'wlp'].includes(show.show)" :class="[isHost ? '' : 'gs-no-select', 'gs-wrap-question']">
					{{ animatedQuestion }}
				</h2>
				<!-- Answer possibilities -->
				<div v-if="['xfz', 'smf'].includes(show.show) && currentQuestion.type.includes('SingleChoice')" class="gs-wrap-answer-pos">
					<div
						v-for="answer in currentQuestion.answerPos"
						:key="answer.pos"
						:class="[answer.isAnswer && $global.isHost() ? 'gs-is-answer' : '', 'gs-answer-pos']"
					>
						<fai v-if="answer.isAnswer && $global.isHost()" icon="fas fa-check-circle" class="gs-question-panel-icon" />{{
							String.fromCharCode(97 + answer.pos)
						}}) {{ answer.answer }}
					</div>
				</div>
				<!-- Single answer -->
				<div v-if="isHost && ['dpin', 'wlp'].includes(show.show)" class="gs-wrap-answer">
					<p>
						<fai icon="fas fa-check-circle" class="gs-question-panel-icon" />{{ currentQuestion.answer }}
						<fai v-if="show.show == 'wlp'" icon="fas fa-coins" class="gs-money"></fai>
					</p>
				</div>
				<div v-if="isHost && ['xfz'].includes(show.show) && ['Freetext', 'Buzzer'].includes(currentQuestion.type)" class="gs-wrap-answer">
					<p class=".gs-answer-center">
						<fai icon="fas fa-check-circle" class="gs-question-panel-icon" />{{ currentQuestion.answerPos[0].answer }}
					</p>
				</div>
				<div
					v-if="isHost && ['smf'].includes(show.show) && !currentQuestion.imageIsAnswer && currentQuestion.answerPos.length == 1"
					class="gs-wrap-answer"
				>
					<p class=".gs-answer-center">
						<fai icon="fas fa-check-circle" class="gs-question-panel-icon" />{{ currentQuestion.answerPos[0].answer }}
					</p>
				</div>
				<div v-if="isHost && ['dpin', 'xfz', 'smf', 'wlp'].includes(show.show)" class="gs-wrap-notes">
					<textarea readonly :style="{ textAlign: currentQuestion.notes ? 'start' : 'center' }">
						{{ currentQuestion.notes ? currentQuestion.notes : $t('gspNoNotes') }}
					</textarea
					>
				</div>
				<!-- Solution link -->
				<div v-if="isHost && ['dpin', 'xfz', 'smf', 'wlp'].includes(show.show)" class="gs-wrap-solution-link">
					<a :href="currentQuestion.link" target="_blank"
						><fai icon="fas fa-external-link-alt" class="gs-question-panel-icon" /> {{ $t('gspSolutionLink') }}
					</a>
				</div>
				<div v-if="kqState.selectedQuestion" class="gs-wrap-kq-question">
					<h2 :class="[isHost ? '' : 'gs-no-select', 'gs-wrap-question']">{{ animatedQuestion }}</h2>
					<div v-if="['showHint', 'revealQuestion'].includes(kqState.state) || isHost" class="gs-wrap-hint">
						<p :class="[isHost ? '' : 'gs-no-select', '.gs-answer-center']">
							<fai icon="fas fa-info-circle" class="gs-question-panel-icon" />
							{{ getSelectedQuestion(kqState.selectedQuestion).hint }}
						</p>
					</div>
					<div v-if="isHost || kqState.state == 'revealQuestion'" class="gs-wrap-answer">
						<p class=".gs-answer-center">
							<fai icon="fas fa-check-circle" class="gs-question-panel-icon" />
							{{ getSelectedQuestion(kqState.selectedQuestion).answer }}
						</p>
					</div>
					<div v-if="isHost || kqState.state == 'revealQuestion'" class="gs-wrap-solution-link">
						<a :href="getCurrentKQLink()" target="_blank">
							<fai v-if="getCurrentKQLink()" icon="fas fa-external-link-alt" class="gs-question-panel-icon" />
							<p>{{ shortenLink(getCurrentKQLink()) }}</p>
						</a>
					</div>
				</div>
			</div>
			<div v-if="showQuestionCondition()" class="gs-image-panel">
				<ImageQuestion
					v-if="firstImageCondition()"
					:image="currentQuestion.image"
					:imageClass="$global.isHost() && !showSolutionImage && currentQuestion.imageIsAnswer ? 'iq-gray-img' : ''"
					:class="[
						(currentQuestion.hasTwoImages && $global.isHost()) || (currentQuestion.hasTwoImages && showSolutionImage)
							? ''
							: 'gs-single-image',
						'gs-wrap-image',
					]"
				/>
				<ImageQuestion
					v-if="secondImageCondition()"
					:image="currentQuestion.image2"
					:imageClass="$global.isHost() && !showSolutionImage ? 'iq-gray-img' : ''"
					:class="[
						(currentQuestion.hasTwoImages && $global.isHost()) || (currentQuestion.hasTwoImages && showSolutionImage)
							? ''
							: 'gs-single-image',
						'gs-wrap-image',
					]"
				/>
			</div>
		</div>
		<div v-if="wlpRevealCondition()" class="gs-wrap-wlp-reveal">
			<div class="gs-wlp-reveal">
				<div class="gs-wlp-header">
					<div class="gs-item-img">{{ $t('gsItem') }}</div>
					<div class="gs-item-name">{{ $t('gsItemName') }}</div>
					<div class="gs-user-name">{{ $t('gsBidder') }}</div>
					<div class="gs-user-bid">{{ $t('gsBid') }}</div>
					<div class="gs-item-price">{{ $t('gsPrice') }}</div>
					<div class="gs-price-bid-diff">{{ $t('gsMargin') }}</div>
				</div>
				<div v-for="item in getQuestionsAndMoneyEvents()" :key="`${item.type}_${item.position}`">
					<div v-if="item.type == 'ITEM'" class="gs-wlp-item">
						<div class="gs-item-img"><img :src="item.image" /></div>
						<div class="gs-item-name">{{ item.question }}</div>
						<div :class="[$global.getUser().uID == getUserForBid(item.qID).uID ? 'gs-highlight-user' : '', 'gs-user-name']">
							{{ getUserForBid(item.qID).name }}
						</div>
						<div class="gs-user-bid">
							{{ getUserForBid(item.qID).bid }}
							<fai v-if="getUserForBid(item.qID).uID" icon="fas fa-coins" class="gs-money"></fai>
						</div>
						<div class="gs-item-price">
							<p v-if="wlpState.revealState.filter((rev) => rev.position == item.position).length > 0">
								{{ item.answer }} <fai icon="fas fa-coins" class="gs-money"></fai>
							</p>
							<p v-else class="gs-blur-content">{{ $t('gsHidden') }} <fai icon="fas fa-coins" class="gs-money"></fai></p>
						</div>
						<div class="gs-price-bid-diff">
							<p
								v-if="wlpState.revealState.filter((rev) => rev.position == item.position).length > 0"
								:class="[getItemPriceDiff(wlpState.revealState.filter((rev) => rev.position == item.position)[0].diff)]"
							>
								{{
									typeof getUserForBid(item.qID).bid == 'number'
										? Number(item.answer) - getUserForBid(item.qID).bid
										: getUserForBid(item.qID).bid
								}}
								<fai v-if="getUserForBid(item.qID).uID" icon="fas fa-coins" class="gs-money"></fai>
							</p>
							<p v-else class="gs-blur-content">{{ $t('gsHidden') }} <fai icon="fas fa-coins" class="gs-money"></fai></p>
						</div>
					</div>
					<div v-else-if="item.type == 'MONEY'" class="gs-wlp-item">
						<div class="gs-item-img">-</div>
						<div class="gs-item-name">{{ $t('gsSetMoney') }}</div>
						<div :class="[$global.getUser().uID == item.uID ? 'gs-highlight-user' : '', 'gs-user-name']">
							{{ players.filter((player) => player.uID == item.uID)[0].name }}
						</div>
						<div class="gs-user-bid">-</div>
						<div class="gs-item-price">-</div>
						<div class="gs-price-bid-diff">
							<p
								v-if="wlpState.revealState.filter((rev) => rev.position == item.position).length > 0"
								:class="[getItemPriceDiff(wlpState.revealState.filter((rev) => rev.position == item.position)[0].diff)]"
							>
								{{ wlpState.revealState.filter((rev) => rev.position == item.position)[0].diff }}
								<fai icon="fas fa-coins" class="gs-money"></fai>
							</p>
							<p v-else class="gs-blur-content">{{ $t('gsHidden') }} <fai icon="fas fa-coins" class="gs-money"></fai></p>
						</div>
					</div>
					<div v-else-if="item.type == 'SKIP'" class="gs-wlp-item">
						<div class="gs-item-img"><img :src="item.image" /></div>
						<div class="gs-item-name">{{ $t('gsSkippedItem') }}</div>
						<div class="gs-user-name">-</div>
						<div class="gs-user-bid">-</div>
						<div class="gs-item-price">
							<p v-if="wlpState.revealState.filter((rev) => rev.position == item.position).length > 0">
								{{ item.answer }} <fai icon="fas fa-coins" class="gs-money"></fai>
							</p>
							<p v-else class="gs-blur-content">{{ $t('gsHidden') }} <fai icon="fas fa-coins" class="gs-money"></fai></p>
						</div>
						<div class="gs-price-bid-diff">-</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="show.show == 'zgw'">
			<div v-if="zgwState.phase == 'assignTopic'">
				<div class="gs-image-text-wrapper">
					<div>
						<img :src="require(`@/assets/images/logos/ZGWLogo.png`)" :alt="$t('tsLogo')" />
						<p>{{ $t('gsHostAssignsPlayerToCreateTopic') }}</p>
					</div>
				</div>
			</div>
			<div v-else-if="zgwState.phase == 'lockTopicTerms'">
				<div class="gs-image-text-wrapper">
					<div>
						<img :src="require(`@/assets/images/profilePictures/${zgwState.selectedCreator.profilePic}`)" />
						<p>{{ zgwState.selectedCreator.name }} {{ $t('gsIsThinkingOfTopicTerms') }}</p>
					</div>
				</div>
			</div>
			<div v-else-if="zgwState.phase == 'assignTopicGuessing'">
				<div class="gs-image-text-wrapper">
					<div v-if="!zgwState.selectedGuesser">
						<img :src="require(`@/assets/images/logos/ZGWLogo.png`)" :alt="$t('tsLogo')" />
						<p>{{ $t('gsHostAssignsPlayerToGuessTopic') }}</p>
					</div>
					<div v-else>
						<img :src="require(`@/assets/images/profilePictures/${zgwState.selectedGuesser.profilePic}`)" />
						<p>{{ zgwState.selectedGuesser.name }} {{ $t('gsGotSelectedForGuessing') }}</p>
					</div>
				</div>
			</div>
			<div v-else-if="zgwState.phase == 'guessingTime'">
				<div class="gs-image-text-wrapper">
					<div>
						<img :src="require(`@/assets/images/profilePictures/${zgwState.selectedGuesser.profilePic}`)" />
						<p>{{ zgwState.selectedGuesser.name }} {{ $t('gsIsGuessingTopicTerms') }}</p>
					</div>
				</div>
			</div>
			<div v-else-if="zgwState.phase == 'topicSummary'" class="gs-zgw-topic-summary">
				<div class="gs-wrap-zgw-header">
					<div class="gs-creator-col">
						<img :src="require(`@/assets/images/profilePictures/${zgwState.selectedCreator.profilePic}`)" />
						<p>{{ zgwState.selectedCreator.name }}</p>
					</div>
					<div v-for="player in playersWithoutCreator" :key="player.uID" class="gs-guesser-col">
						<img :src="require(`@/assets/images/profilePictures/${player.profilePic}`)" />
						<p>{{ player.name }}</p>
					</div>
				</div>
				<div class="gs-wrap-zgw-body">
					<div v-for="term in zgwState.revealedTopicTerms" :key="term" class="gs-zgw-row">
						<div class="gs-creator-col">
							<div class="gs-correct-term">
								{{ term }}
							</div>
						</div>
						<div v-for="player in playersWithoutCreator" :key="player.uID" class="gs-guesser-col">
							<div v-if="playerGuessedTerm(player, term)" class="gs-guessed-correct">
								<fai icon="fas fa-check-circle"></fai>
							</div>
							<div v-else class="gs-guessed-wrong">
								<fai icon="fas fa-times-circle"></fai>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="show.show == 'kq' && kqState" class="gs-wrap-kq">
			<CategoryQuizLayout
				:show="show"
				:players="players"
				:kqState="kqState"
				@selectCategory="$emit('selectCategory', $event)"
				@removeCategory="$emit('removeCategory', $event)"
				@assignCategory="$emit('assignCategory', $event)"
				@showCategory="$emit('showCategory', $event)"
			/>
		</div>
	</div>
</template>

<script>
import ImageQuestion from './gameshowElements/ImageQuestion.vue';
import CategoryQuizLayout from './gameshowElements/CategoryQuizLayout.vue';
export default {
	name: 'GameShow',
	components: {
		ImageQuestion,
		CategoryQuizLayout,
	},
	props: {
		show: {
			type: Object,
			required: true,
		},
		history: {
			type: Array,
			required: true,
		},
		showSolutionImage: {
			type: Boolean,
			required: false,
		},
		wlpState: {
			type: Object,
			required: false,
		},
		zgwState: {
			type: Object,
			required: false,
		},
		kqState: {
			type: Object,
			required: false,
		},
		players: {
			type: Array,
			required: false,
		},
	},
	watch: {
		kqState: {
			handler: function (newVal, oldVal) {
				if (newVal && newVal.selectedQuestion && newVal.state == 'showQuestion') {
					let question = this.getSelectedQuestion(newVal.selectedQuestion);
					if (question) this.getQuestionAnimation(question.question);
				} else if (newVal && newVal.selectedQuestion) {
					let question = this.getSelectedQuestion(newVal.selectedQuestion);
					this.animatedQuestion = question.question;
				} else this.animatedQuestion = '';
			},
			deep: true,
		},
	},
	data() {
		return {
			currentQuestion: null,
			sortedQuestions: [],
			isHost: this.$global.isHost(),
			animatedQuestion: '',
			animationInProgress: false,
		};
	},
	computed: {
		playersWithoutCreator() {
			return this.players.filter((player) => player.uID !== this.zgwState.selectedCreator.uID);
		},
	},
	created() {
		this.getCurrentQuestion();
		this.sortQuestions();
	},
	mounted() {
		window.addEventListener('resize', this.resizeListener);
		window.dispatchEvent(new Event('resize'));

		if (!['kq', 'zgw'].includes(this.show.show)) {
			if (['Buzzer', 'BuzzerSingleChoice'].includes(this.currentQuestion.type)) this.getQuestionAnimation(this.currentQuestion.question);
			else this.animatedQuestion = this.currentQuestion.question;
		} else {
			if (this.kqState && this.kqState.selectedQuestion && this.kqState.state == 'showQuestion') {
				let question = this.currentQuestion.questions[Number(this.kqState.selectedQuestion.level)];
				if (question) this.getQuestionAnimation(question.question);
			}
		}
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.resizeListener);
	},
	methods: {
		resizeListener() {
			this.$nextTick(() => {
				let element = document.querySelector('.gs-wrap-wlp-reveal');

				if (element) {
					if (element.clientWidth < 600) {
						element.classList.add('gs-list-style');
						element.classList.remove('gs-table-style');
					} else if (element.clientWidth < 900) {
						element.classList.add('gs-table-style');
						element.classList.remove('gs-list-style');
					} else if (element.clientWidth >= 900) {
						element.classList.remove('gs-table-style');
						element.classList.remove('gs-list-style');
					}
				}
			});
		},
		getCurrentQuestion() {
			let lastQuestions = this.history.filter((his) => his.action == 'setQuestion');
			if (lastQuestions.length > 0) {
				this.currentQuestion = this.show.questions.filter((quest) => quest.qID == lastQuestions[lastQuestions.length - 1].result)[0];
			} else {
				this.currentQuestion = this.show.questions.filter((quest) => quest.position == 0)[0];
			}
		},
		getQuestionAnimation(question) {
			if (!this.animationInProgress) {
				this.animationInProgress = true;
				this.animatedQuestion = '';
				let parts = question.split('');
				let i = 0;
				let interval = window.setInterval(() => {
					this.animatedQuestion += parts[i];
					i += 1;
					if (i == parts.length) {
						clearInterval(interval);
						this.animationInProgress = false;
						this.animatedQuestion = question;
						window.dispatchEvent(new Event('resize'));
					}
				}, 80);
			}
		},
		firstImageCondition() {
			if (this.currentQuestion) {
				let firstCond = !this.currentQuestion.imageIsAnswer;

				return this.currentQuestion.image && (firstCond || this.$global.isHost() || this.showSolutionImage);
			} else return false;
		},
		secondImageCondition() {
			if (this.currentQuestion) {
				let firstCond = this.currentQuestion.hasTwoImages;
				return this.currentQuestion.image2 && ((firstCond && this.$global.isHost()) || this.showSolutionImage);
			} else return false;
		},
		showQuestionCondition() {
			if (this.wlpState) {
				if (this.wlpState.revealState) return false;
				else return true;
			} else if (this.show.show == 'zgw') return false;
			else if (this.show.show == 'kq' && !(this.kqState && this.kqState.selectedQuestion) && this.kqState.state != 'showQuestion') return false;
			else return true;
		},
		wlpRevealCondition() {
			if (this.wlpState) {
				if (this.wlpState.revealState) return true;
				else return false;
			} else return false;
		},
		getUserForBid(qID) {
			let item = this.wlpState.items.filter((item) => item.qID == qID)[0];
			if (!item) {
				return {
					bid: this.$t('gsNoBid'),
					name: this.$t('gsNoBidder'),
					uID: null,
				};
			} else {
				let user = this.players.filter((player) => player.uID == item.uID)[0];
				return {
					bid: item.bid,
					name: user.name,
					uID: user.uID,
				};
			}
		},
		getItemPriceDiff(diff) {
			if (diff < 0) return 'gs-red';
			else if (diff > 0) return 'gs-green';
			else return 'gs-white';
		},
		sortQuestions() {
			let sortedQuestions = this.show.questions.sort((a, b) => {
				return a.position - b.position;
			});

			this.sortedQuestions = sortedQuestions;
			this.$forceUpdate();
		},
		getQuestionsAndMoneyEvents() {
			let moneyAndItemEvents = this.history.filter(
				(his) => his.gID == this.show.gID && ['setMoney', 'highestBid', 'skipItem'].includes(his.action)
			);
			let revealItems = [];

			moneyAndItemEvents.forEach((it) => {
				if (it.action == 'highestBid') {
					revealItems.push({
						type: 'ITEM',
						...this.show.questions.filter((quest) => quest.qID == it.qID)[0],
					});
				} else if (it.action == 'skipItem') {
					revealItems.push({
						type: 'SKIP',
						...this.show.questions.filter((quest) => quest.qID == it.qID)[0],
					});
				} else if (it.action == 'setMoney') {
					revealItems.push({
						type: 'MONEY',
						uID: it.info,
						qID: it.qID,
						money: it.result,
						position: 0,
						timestamp: it.timepoint,
					});
				}
			});
			revealItems.map((it, idx) => {
				it.position = idx;
				return it;
			});

			return revealItems;
		},
		playerGuessedTerm(player, term) {
			let his = this.history.filter(
				(his) => his.action == 'setAnswers' && his.info == player.uID && his.qID == this.zgwState.selectedTopic.qID
			)[0];
			if (his && his.result.split(';TERM;').includes(term)) return true;
			else return false;
		},
		getSelectedQuestion(selectedQuestion) {
			return this.show.questions.filter((it) => it.qID == selectedQuestion.qID)[0].questions[Number(selectedQuestion.level)];
		},
		getCurrentKQLink() {
			try {
				if (this.kqState && this.kqState.selectedQuestion) {
					let question = this.show.questions.filter((quest) => quest.qID == this.kqState.selectedQuestion.qID)[0];
					if (question) {
						return new URL('https://www.google.de/?hl=de');
						return new URL(question.questions[this.kqState.selectedQuestion.level].link);
					} else return null;
				} else return null;
			} catch (error) {
				return null;
			}
		},
		shortenLink(link) {
			try {
				return new URL(link).hostname.replace('www.', '');
			} catch (error) {
				return this.$t('gsNoSolutionLink');
			}
		},
	},
};
</script>

<style scoped>
.gs-wrap-content {
	width: 100vw;
	height: 100vh;
	overflow-x: hidden;
	overflow-y: auto;
}

.gs-wrap-content h1 {
	font-size: 40px;
	padding: 10px 0px;
	margin: 0px auto;
	text-shadow: 3px 3px var(--main-color-6);
}

.gs-question-panel {
	width: 90%;
	margin: 0px auto 20px auto;
	padding: 10px;
	background-color: var(--main-color-dark-transparent-80);
	border-radius: 20px;
	box-sizing: border-box;
	text-align: center;
}

.gs-question-panel-icon {
	font-size: 25px;
	color: var(--main-color-info);
}

.gs-wrap-question {
	font-size: 20px;
	margin: 0px auto;
	text-align: center;
}

.gs-no-select {
	user-select: none;
}

.gs-image-panel {
	display: flex;
	justify-content: center;
	align-items: center;
}

.gs-wrap-image {
	width: 500px;
	height: 500px;
	max-width: 44%;
	max-height: 40vh;
	margin: 0px 3%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.gs-single-image {
	width: fit-content;
	max-width: 94%;
}

.gs-wrap-solution-link {
	margin-top: 10px;
}

.gs-wrap-solution-link a {
	margin-left: 10px;
	font-size: 20px;
	vertical-align: top;
	text-decoration: underline;
	display: flex;
	justify-content: center;
	align-items: center;
	color: var(--main-color-text-light);
}

.gs-wrap-solution-link a:hover .gs-question-panel-icon,
.gs-wrap-solution-link a:hover {
	color: var(--main-color-6);
	cursor: pointer;
}

.gs-wrap-solution-link a svg {
	margin-right: 5px;
	font-size: 20px;
}

.gs-wrap-answer {
	width: fit-content;
	margin: 5px auto;
	padding: 5px 20px;
	font-size: 20px;
	background-color: var(--main-color-5-cc);
	color: var(--main-color-text-light);
}

.gs-wrap-answer svg {
	margin-right: 10px;
	color: var(--main-color-success);
	filter: drop-shadow(2px 2px 2px var(--main-color-border-dark));
}

.gs-wrap-answer-pos {
	width: 100%;
	height: fit-content;
	margin: 10px 0px;
	display: flex;
	justify-content: center;
	/* align-items: center; */
	flex-flow: wrap;
}

.gs-answer-center {
	display: flex;
	justify-content: center;
	align-items: center;
}

.gs-answer-pos {
	width: fit-content;
	margin: 5px 10px;
	padding: 5px;
	flex: 1 1 150px;
	font-size: 20px;
	border-bottom: 2px solid var(--main-color-border-light);
	background-color: var(--main-color-5);
	color: var(--main-color-text-light);
}

.gs-is-answer {
	background-color: var(--main-color-3);
}

.gs-is-answer svg {
	font-size: 20px;
	color: var(--main-color-success) !important;
	margin-right: 10px;
	filter: drop-shadow(2px 2px 2px var(--main-color-border-dark));
}

.gs-money {
	color: var(--main-color-gold) !important;
}

.gs-wrap-wlp-reveal {
	width: 100%;
	height: fit-content;
	/* max-height: calc(100% - 200px); */
	overflow-y: auto;
	overflow-y: auto;
}

.gs-wlp-reveal {
	width: fit-content;
	margin: 10px auto;
	padding: 10px;
	border-radius: 20px;
	overflow: auto;
	background-color: var(--main-color-dark-transparent-80);
}

.gs-wlp-header div {
	margin: 0px 5px;
	font-weight: normal !important;
	font-size: 20px;
}
.gs-wlp-header,
.gs-wlp-item {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-flow: wrap;
	border-bottom: 1px solid var(--main-color-border-light);
}

.gs-wlp-item {
	padding: 5px 0px;
}

.gs-wlp-item div {
	margin: 0px 5px;
}

.gs-item-img {
	width: 75px;
	height: 50px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-size: 17px;
	color: var(--main-color-text-light);
	text-align: center;
}

.gs-item-img img {
	max-width: 65px;
	max-height: 40px;
}

.gs-item-name {
	width: 200px;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	font-size: 17px;
	color: var(--main-color-text-light);
	text-align: center;
}

.gs-user-name {
	width: 170px;
	font-size: 17px;
	color: var(--main-color-text-light);
	text-align: center;
}

.gs-user-bid {
	width: 70px;
	font-size: 17px;
	color: var(--main-color-text-light);
	text-align: center;
}

.gs-item-price {
	width: 100px;
	font-size: 17px;
	color: var(--main-color-text-light);
	text-align: center;
}

.gs-price-bid-diff {
	width: 100px;
	font-size: 17px;
	font-weight: bold;
	color: var(--main-color-text-light);
	text-align: center;
}

.gs-blur-content {
	filter: blur(4px);
	-webkit-filter: blur(4px);
	color: var(--main-color-text-light) !important;
}

.gs-red {
	color: var(--main-color-error);
}

.gs-white {
	color: var(--main-color-text-light);
}

.gs-green {
	color: var(--main-color-success);
}

.gs-highlight-user {
	color: var(--main-color-6) !important;
}

.gs-wrap-notes {
	width: 100%;
	height: fit-content;
	text-align: start;
	margin: auto;
	position: relative;
	display: inline-block;
	vertical-align: top;
}

.gs-wrap-notes textarea {
	width: 100%;
	min-height: 40px;
	height: 40px;
	max-height: 300px;
	display: block;
	margin: 10px 0px;
	font-size: 20px;
	box-sizing: border-box;
	resize: vertical;
}

.gs-image-text-wrapper {
	width: 100%;
	height: fit-content;
	margin-top: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.gs-image-text-wrapper div {
	text-align: center;
}

.gs-image-text-wrapper img {
	width: 400px;
	height: 400px;
	border: 5px solid var(--main-color-border-dark);
	border-radius: 50%;
}

.gs-image-text-wrapper p {
	margin-top: 10px;
	font-size: 20px;
}

.gs-zgw-topic-summary {
	width: 100%;
	max-width: 1200px;
	height: fit-content;
	margin: auto;
	padding: 10px 20px;
	box-sizing: border-box;
	overflow: auto;
}

.gs-wrap-zgw-header,
.gs-wrap-zgw-body {
	width: 100%;
	min-width: 550px;
	height: fit-content;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow: wrap;
	padding: 5px 30px;
	box-sizing: border-box;
}

.gs-wrap-zgw-header {
	border-bottom: 1px solid var(--main-color-border-light);
}

.gs-creator-col {
	flex: 1 1 300px;
	display: inline-block;
	text-align: center;
}

.gs-guesser-col {
	flex: 1 1 50px;
	display: inline-block;
	text-align: center;
}

.gs-wrap-zgw-header img {
	width: 50px;
	height: 50px;
	display: block;
	margin: auto;
	border-radius: 50%;
	border: 2px solid transparent;
}

.gs-wrap-zgw-header p {
	display: block;
}

.gs-zgw-row {
	width: 100%;
	height: fit-content;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow: wrap;
	animation: fadeIn 1s ease-out;
	-webkit-animation: fadeIn 1s ease-out;
}

.gs-correct-term,
.gs-guessed-wrong,
.gs-guessed-correct {
	margin: 10px;
	padding: 5px 10px;
	font-size: 20px;
	border: 1px solid var(--main-color-border-dark);
	box-shadow: 1px 1px 1px 1px var(--main-color-border-dark);
	color: var(--main-color-text-light);
}

.gs-correct-term {
	background-color: var(--main-color-info);
}

.gs-guessed-correct {
	background-color: var(--main-color-success);
}

.gs-guessed-wrong {
	background-color: var(--main-color-error);
}

.gs-wrap-kq {
	width: 100%;
	padding: 0px 20px 20px 20px;
	margin: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
}

.gs-wrap-hint {
	margin: 5px auto;
	padding: 5px 20px;
	font-size: 20px;
	background-color: var(--main-color-5-cc);
	color: var(--main-color-text-light);
}

.gs-wrap-hint svg {
	margin-right: 10px;
	color: var(--main-color-info);
	filter: drop-shadow(2px 2px 2px var(--main-color-border-dark));
}

@keyframes fadeIn {
	0% {
		transform: scale(0);
		opacity: 0;
	}
	60% {
		transform: scale(1.1);
	}
	80% {
		transform: scale(0.9);
		opacity: 1;
	}
	100% {
		transform: scale(1);
		opacity: 1;
	}
}

@-webkit-keyframes fadeIn {
	0% {
		-webkit-transform: scale(0);
		opacity: 0;
	}
	60% {
		-webkit-transform: scale(1.1);
	}
	80% {
		-webkit-transform: scale(0.9);
		opacity: 1;
	}
	100% {
		-webkit-transform: scale(1);
		opacity: 1;
	}
}

@media screen and (max-width: 1150px) {
	.gs-table-style .gs-wlp-header div {
		font-size: 15px;
	}

	.gs-table-style .gs-item-img {
		width: 50px;
		height: 40px;
		font-size: 15px;
	}

	.gs-table-style .gs-item-img img {
		max-width: 40px;
		max-height: 30px;
	}

	.gs-table-style .gs-item-name {
		width: 100px;
		font-size: 12px;
	}

	.gs-table-style .gs-user-name {
		width: 100px;
		font-size: 12px;
	}

	.gs-table-style .gs-user-bid {
		width: 50px;
		font-size: 12px;
	}

	.gs-table-style .gs-item-price {
		width: 70px;
		font-size: 12px;
	}

	.gs-table-style .gs-price-bid-diff {
		width: 70px;
		font-size: 12px;
		font-weight: bold;
		color: var(--main-color-text-light);
		text-align: center;
	}

	.gs-table-style .gs-blur-content {
		filter: blur(4px);
		-webkit-filter: blur(4px);
		color: var(--main-color-text-light) !important;
	}

	.gs-list-style {
		width: 100%;
		height: 100%;
	}

	.gs-list-style .gs-wlp-reveal {
		width: 90%;
		height: 100%;
		overflow: auto;
	}

	.gs-list-style .gs-wlp-header {
		display: none;
	}

	.gs-list-style .gs-wlp-item {
		width: 100%;
		display: block;
		margin-bottom: 10px;
	}

	.gs-list-style .gs-wlp-item div {
		display: inline-block;
		vertical-align: top;
	}

	.gs-list-style .gs-item-img {
		width: 100px;
		height: 75px;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		font-size: 17px;
		color: var(--main-color-text-light);
		text-align: center;
	}

	.gs-list-style .gs-item-img img {
		max-width: 90px;
		max-height: 65px;
	}

	.gs-list-style .gs-item-name {
		width: calc(100% - 120px);
		font-size: 17px;
		color: var(--main-color-text-light);
		text-align: center;
	}

	.gs-list-style .gs-user-name {
		width: calc(100% - 310px);
		min-width: 100px;
		font-size: 17px;
		color: var(--main-color-text-light);
		text-align: center;
	}

	.gs-list-style .gs-user-bid {
		width: 70px;
		font-size: 17px;
		color: var(--main-color-text-light);
		text-align: center;
	}

	.gs-list-style .gs-item-price {
		width: 100px;
		font-size: 17px;
		color: var(--main-color-text-light);
		text-align: center;
	}

	.gs-list-style .gs-price-bid-diff {
		width: 100px;
		font-size: 17px;
		font-weight: bold;
		color: var(--main-color-text-light);
		text-align: center;
	}

	.gs-list-style .gs-blur-content {
		filter: blur(4px);
		-webkit-filter: blur(4px);
		color: var(--main-color-text-light) !important;
	}
}
</style>
