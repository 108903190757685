<template>
	<div class="ap-wrap-content">
		<div class="ap-toast-wrapper">
			<div class="ap-toast-animation" @click="$global.abortAnimation()">
				<div class="ap-toast ap-info">
					<fai icon="fas fa-info-circle" class="ap-toast-icon" />
					<p class="ap-info-text"></p>
				</div>
				<div class="ap-toast ap-success">
					<fai icon="fas fa-check-circle" class="ap-toast-icon" />
					<p class="ap-success-text"></p>
				</div>
				<div class="ap-toast ap-warn">
					<fai icon="fas fa-exclamation-circle" class="ap-toast-icon" />
					<p class="ap-warn-text"></p>
				</div>
				<div class="ap-toast ap-error">
					<fai icon="fas fa-times-circle" class="ap-toast-icon" />
					<p class="ap-error-text"></p>
				</div>
				<div class="ap-toast-progress">
					<span style="width: 100%"><span class="ap-progress"></span></span>
				</div>
			</div>
		</div>
		<Header />
		<transition name="fade" mode="out-in">
			<router-view @setTheme="setTheme">
				<!-- <router-view :key="$route.fullPath" @setTheme="setTheme"> -->
				<PageAccess />
			</router-view>
		</transition>
		<div class="ap-wrap-cookie-law">
			<CookieLaw v-show="showCookieLaw" @closeCookieLaw="showCookieLaw = false" />
		</div>
		<div v-show="infoText != ''" id="infoText" class="ap-info-message">
			<h4>{{ $t(infoTitle) }}</h4>
			<p v-html="$t(infoText)"></p>
			<fai icon="fas fa-times" @click="closeInfo"></fai>
		</div>
	</div>
</template>

<script>
import PageAccess from './views/PageAccess.vue';
import Header from './components/layouts/Header.vue';
import CookieLaw from './components/layouts/CookieLaw.vue';
import bowser from 'bowser';
/**
 * @group App
 * Wraps all components inside itself
 */
export default {
	name: 'App',
	components: {
		PageAccess,
		Header,
		CookieLaw,
	},
	watch: {
		$route: {
			handler: function (newVal) {
				window.setTimeout(() => {
					this.$socket.handleRouteChange(this.$route.name);
				}, 100);
			},
		},
	},
	data() {
		return {
			showCookieLaw: false,
			infoText: '',
			infoTitle: '',
		};
	},
	created() {
		if (!sessionStorage.getItem('lastVisited')) {
			sessionStorage.setItem('lastVisited', new Date());
			this.increasePageViewCount();
		}
		this.initialTheme();
		this.$socket.getConnectionInfo();
		this.checkCookieLaw();
		this.setInitialVars();
	},
	mounted() {
		this.checkBrowserAndMobile();
	},
	beforeDestroy() {
		this.$socket.closeSocketConnection();
	},
	methods: {
		increasePageViewCount() {
			this.$global.patchData('system', '/pageViewCount', null, null, (err, data) => {
				if (err) console.log(err);
			});
		},
		initialTheme() {
			this.theme = 'dark';
			localStorage.setItem('theme', 'dark');
			sessionStorage.setItem('theme', 'dark');
			// this.theme = localStorage.getItem('theme')
			// 	? localStorage.getItem('theme')
			// 	: sessionStorage.getItem('theme')
			// 	? sessionStorage.getItem('theme')
			// 	: 'dark';
			let htmlElement = document.documentElement;
			htmlElement.setAttribute('theme', this.theme);
		},
		checkCookieLaw() {
			if (!sessionStorage.getItem('askedForCookieLaw') && !localStorage.getItem('cookiesAllowed')) this.showCookieLaw = true;
			else this.showCookieLaw = false;
		},
		setInitialVars() {
			let user = localStorage.getItem('user') ? localStorage.getItem('user') : sessionStorage.getItem('user');
			this.$socket.setVars(this.$route, this.$router, this);
		},
		setTheme(theme) {
			this.theme = theme;
			let htmlElement = document.documentElement;
			htmlElement.setAttribute('theme', this.theme);
			if (localStorage.getItem('cookiesAllowed')) localStorage.setItem('theme', this.theme);
			else sessionStorage.setItem('theme', this.theme);
		},
		checkBrowserAndMobile() {
			let warningAlreadyShown = sessionStorage.getItem('showedBrowserMobileWarning');
			if (!warningAlreadyShown) {
				let result = bowser.getParser(window.navigator.userAgent);
				let browser = result.parsedResult.browser.name;
				let isMobile = result.parsedResult.platform.type !== 'desktop';

				if (isMobile) this.showInfo({ title: 'apWarnMobile', text: 'apWarnMobileInfo' });
				else if (browser !== 'Chrome') this.showInfo({ title: 'apWarnBrowser', text: 'apWarnBrowserInfo' });

				if (isMobile || browser !== 'Chrome') sessionStorage.setItem('showedBrowserMobileWarning', true);
			}
		},
		// @vuese
		// Shows the information field at the bottom of the page
		// @arg content[Object] - The content that gets displayed inside the information div with title and text
		showInfo(content) {
			this.infoTitle = content.title;
			this.infoText = content.text;

			let info = document.getElementById('infoText');
			info.style.height = 'fit-content';

			this.$nextTick(() => {
				let height = info.offsetHeight;
				info.style.height = '0px';

				window.setTimeout(function () {
					info.style.height = height + 'px';
				}, 100);
			});
		},
		// @vuese
		// Hides the information field
		closeInfo() {
			document.getElementById('infoText').style.height = null;
			let that = this;
			window.setTimeout(function () {
				that.infoTitle = '';
				that.infoText = '';
			}, 1000);
		},
	},
};
</script>

<style scoped>
.ap-wrap-content {
	width: 100%;
	height: 100%;
	overflow: hidden;
	position: relative;
}

.ap-toast-wrapper {
	width: 100vw;
	height: fit-content;
	position: absolute;
	top: 0px;
	left: 0px;
	z-index: 16;
}

.ap-toast-wrapper:hover .ap-toast-animation,
.ap-toast-wrapper:hover .ap-toast-animation .ap-toast-progress .ap-progress {
	cursor: pointer;
	-webkit-animation-play-state: paused;
	-moz-animation-play-state: paused;
	-ms-animation-play-state: paused;
	-o-animation-play-state: paused;
	animation-play-state: paused;
}

.ap-toast-wrapper .ap-toast {
	width: 100%;
	height: fit-content;
	padding: 10px 40px;
	display: none;
	justify-content: center;
	align-items: center;
	font-size: 30px;
	color: var(--main-color-text-light);
	box-sizing: border-box;
}

.ap-toast-animation {
	display: none;
	-webkit-animation: fadeout 3s forwards; /* Safari, Chrome and Opera > 12.1 */
	-moz-animation: fadeout 3s forwards; /* Firefox < 16 */
	-ms-animation: fadeout 3s forwards; /* Internet Explorer */
	-o-animation: fadeout 3s forwards; /* Opera < 12.1 */
	animation: fadeout 3s forwards;
}

.ap-toast-progress {
	width: 100%;
	display: none;
	height: 5px;
	position: relative;
	background: var(--main-color-1);
	overflow: hidden;
}

.ap-toast-progress span {
	display: block;
	height: 100%;
}

.ap-progress {
	-webkit-animation: progressBar 3s ease-in-out; /* Safari, Chrome and Opera > 12.1 */
	-webkit-animation-fill-mode: both;
	-moz-animation: progressBar 3s ease-in-out; /* Firefox < 16 */
	-moz-animation-fill-mode: both;
	-ms-animation: progressBar 3s ease-in-out; /* Internet Explorer */
	-ms-animation-fill-mode: both;
	-o-animation: progressBar 3s ease-in-out; /* Opera < 12.1 */
	-o-animation-fill-mode: both;
	animation: progressBar 3s ease-in-out;
	animation-fill-mode: both;
}

.show-info .ap-toast-animation .ap-toast-progress .ap-progress {
	background-color: var(--secondary-color-info);
}

.show-success .ap-toast-animation .ap-toast-progress .ap-progress {
	background-color: var(--secondary-color-success);
}

.show-warn .ap-toast-animation .ap-toast-progress .ap-progress {
	background-color: var(--secondary-color-warn);
}

.show-error .ap-toast-animation .ap-toast-progress .ap-progress {
	background-color: var(--secondary-color-error);
}

.show-info .ap-toast-animation {
	display: block !important;
}
.show-info .ap-toast-animation .ap-info,
.show-info .ap-toast-animation .ap-toast-progress {
	display: flex !important;
}

.show-success .ap-toast-animation {
	display: block !important;
}
.show-success .ap-toast-animation .ap-success,
.show-success .ap-toast-animation .ap-toast-progress {
	display: flex !important;
}

.show-warn .ap-toast-animation {
	display: block !important;
}
.show-warn .ap-toast-animation .ap-warn,
.show-warn .ap-toast-animation .ap-toast-progress {
	display: flex !important;
}

.show-error .ap-toast-animation {
	display: block !important;
}
.show-error .ap-toast-animation .ap-error,
.show-error .ap-toast-animation .ap-toast-progress {
	display: flex !important;
}

.ap-toast-wrapper .ap-info {
	background-color: var(--main-color-info);
}

.ap-toast-wrapper .ap-success {
	background-color: var(--main-color-success);
}

.ap-toast-wrapper .ap-warn {
	background-color: var(--main-color-warn);
}

.ap-toast-wrapper .ap-error {
	background-color: var(--main-color-error);
}

.ap-toast-icon {
	font-size: 30px;
	margin-right: 10px;
}

.ap-wrap-cookie-law {
	position: absolute;
	left: 2vw;
	bottom: 2vh;
	transition: 1s ease-out;
}

.ap-info-message {
	width: 100%;
	height: 0px;
	max-height: 50vh;
	position: absolute;
	left: 0px;
	bottom: 0px;
	background-color: var(--main-color-5-cc);
	border-top: 1px solid var(--main-color-5);
	color: var(--main-color-text-light);
	-webkit-transition: height 1s ease-out;
	-moz-transition: height 1s ease-out;
	transition: height 1s ease-out;
	overflow-x: hidden;
	overflow-y: auto;
	z-index: 2;
}

.ap-info-message p {
	padding: 10px;
	font-size: 25px;
}

.ap-info-message h4 {
	margin: 10px 0px 0px 10px;
	font-size: 30px;
	text-decoration: underline;
	color: var(--main-color-text-light);
}

.ap-info-message svg {
	font-size: 45px;
	position: absolute;
	top: 4px;
	right: 5px;
	color: var(--secondary-color-error);
}

.ap-info-message svg:hover {
	cursor: pointer;
	color: var(--main-color-error);
}

@keyframes progressBar {
	0% {
		width: 0;
	}
	100% {
		width: 100%;
	}
}

/* Firefox < 16 */
@-moz-keyframes progressBar {
	0% {
		width: 0;
	}
	100% {
		width: 100%;
	}
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes progressBar {
	0% {
		width: 0;
	}
	100% {
		width: 100%;
	}
}

/* Internet Explorer */
@-ms-keyframes progressBar {
	0% {
		width: 0;
	}
	100% {
		width: 100%;
	}
}

/* Opera < 12.1 */
@-o-keyframes progressBar {
	0% {
		width: 0;
	}
	100% {
		width: 100%;
	}
}

@-moz-keyframes progressBar {
	0% {
		width: 0;
	}
	100% {
		width: 100%;
	}
}

@keyframes fadeout {
	0% {
		opacity: 1;
	}
	80% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

/* Firefox < 16 */
@-moz-keyframes fadeout {
	0% {
		opacity: 1;
	}
	80% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeout {
	0% {
		opacity: 1;
	}
	80% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

/* Internet Explorer */
@-ms-keyframes fadeout {
	0% {
		opacity: 1;
	}
	80% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

/* Opera < 12.1 */
@-o-keyframes fadeout {
	0% {
		opacity: 1;
	}
	80% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
</style>

<style>
html {
	background-color: #000000;
}
body::before {
	width: 100vw;
	height: 100vh;
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-image: var(--background-img);
	background-repeat: repeat;
	overflow-x: hidden;
	opacity: 0.4;
	z-index: -1;
}

body {
	width: 100% !important;
	height: 100% !important;
	position: relative;
}

* {
	margin: 0;
	padding: 0;
}

*:not(svg) {
	font-family: Arial, Helvetica, sans-serif;
}

/* Global color styles */
[theme='dark'] {
	--background-img: url('./assets/images/background/background_dark.png');

	--main-color-1: #fefcfb;
	--main-color-1-cc: #fefcfbcc;
	--main-color-2: #d9d7d6;
	--main-color-2-cc: #d9d7d6cc;
	--main-color-3: #0a5a83;
	--main-color-3-cc: #0a5a83cc;
	--main-color-4: #1c3650;
	--main-color-4-cc: #1c3650cc;
	--main-color-5: #0c2f52;
	--main-color-5-cc: #0c2f52cc;
	--main-color-6: #f17c0d;
	--main-color-6-cc: #f17c0dcc;
	--secondary-color-6: #e6a05f;

	--main-color-text-light: #ffffff;
	--main-color-text-dark: #000000;
	--main-color-light: #ffffff;
	--main-color-dark: #000000;
	--main-color-border-light: #ffffff;
	--main-color-border-dark: #000000;
	--main-color-dark-transparent-40: #00000040;
	--main-color-dark-transparent-80: #00000080;
	--main-color-dark-transparent-cc: #000000cc;
	--main-color-light-transparent-40: #ffffff40;
	--main-color-light-transparent-80: #ffffff80;
	--main-color-light-transparent-cc: #ffffffcc;

	--main-color-info: #0e84be;
	--main-color-info-cc: #0e84becc;
	--main-color-info-transparent-80: #0e84be80;
	--secondary-color-info: #0c6896;
	--main-color-success: #26c52e;
	--main-color-success-cc: #26c52ecc;
	--main-color-success-transparent-80: #26c52e80;
	--secondary-color-success: #0d9213;
	--main-color-warn: #fa7901;
	--main-color-warn-cc: #fa7901cc;
	--main-color-warn-transparent-80: #fa790180;
	--secondary-color-warn: #cc6816;
	--main-color-error: #c53232;
	--main-color-error-cc: #c53232cc;
	--main-color-error-80: #c5323280;
	--secondary-color-error: #cc0808;
	--main-color-purple: #c918ba;
	--main-color-purple-cc: #c918bacc;
	--main-color-disabled: var(--main-color-2);
	--main-color-disabled-cc: var(--main-color-2-cc);
	--main-color-disabled-text: #3b3737;

	--main-color-gold: gold;
	--main-color-silver: silver;
	--main-color-bronze: #cd7f32;
}

[theme='light'] {
	--background-img: url('./assets/images/background/background_light.png');

	--main-color-1: #010304;
	--main-color-1-cc: #010304cc;
	--main-color-2: #262829;
	--main-color-2-cc: #262829cc;
	--main-color-3: #f17b41;
	--main-color-3-cc: #f17b41cc;
	--main-color-4: #f2a252;
	--main-color-4-cc: #f2a252cc;
	--main-color-5: #f3d0ad;
	--main-color-5-cc: #f3d0adcc;
	--main-color-6: #0e83f2;
	--main-color-6-cc: #0e83f2cc;

	--main-color-text-light: #000000;
	--main-color-text-dark: #ffffff;
	--main-color-border-light: #000000;
	--main-color-border-dark: #ffffff;
	--main-color-light-transparent-40: #00000040;
	--main-color-light-transparent-80: #00000080;
	--main-color-light-transparent-cc: #000000cc;
	--main-color-dark-transparent-40: #ffffff40;
	--main-color-dark-transparent-80: #ffffff80;
	--main-color-dark-transparent-cc: #ffffffcc;

	--main-color-info: #0e84be;
	--main-color-info-cc: #0e84becc;
	--main-color-info-transparent-80: #0e84be80;
	--secondary-color-info: #0c6896;
	--main-color-success: #26c52e;
	--main-color-success-cc: #26c52ecc;
	--main-color-success-transparent-80: #26c52e80;
	--secondary-color-success: #0d9213;
	--main-color-warn: #fa7901;
	--main-color-warn-transparent-80: #fa790180;
	--secondary-color-warn: #e77b22;
	--main-color-error: #c53232;
	--main-color-error-cc: #c53232cc;
	--main-color-error-80: #c5323280;
	--secondary-color-error: #cc0808;
	--main-color-purple: #c918ba;
	--main-color-purple-cc: #c918bacc;
	--main-color-disabled: var(--main-color-2);
	--main-color-disabled-text: #c4c8c8;

	--main-color-gold: gold;
	--main-color-silver: silver;
	--main-color-bronze: #cd7f32;
}

/* Global scrollbar style */
::-webkit-scrollbar {
	width: 17px;
	box-sizing: border-box;
}

/* Global scrollbar track style */
::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px black;
	/* border-radius: 10px; */
	background-color: var(--main-color-4);
}

/* Global scrollbar thumb style */
::-webkit-scrollbar-thumb {
	background: var(--main-color-2);
	/* border-radius: 10px; */
	border: 1px solid black;
}

/* Global scrollbar corner style */
::-webkit-scrollbar-corner {
	background-color: var(--main-color-2);
}

button {
	padding: 5px 10px;
	border-radius: 10px;
	outline: none;
	cursor: pointer;
	box-sizing: border-box;
}

button:hover {
	cursor: pointer;
}

button:disabled {
	background-color: var(--main-color-disabled);
	color: var(--main-color-disabled-text);
	pointer-events: none;
	cursor: default;
}

.app-default-btn {
	border: 1px solid var(--main-color-2);
	filter: drop-shadow(0px 0.2em 0px var(--main-color-2));
	color: var(--main-color-text-light);
	background-color: var(--main-color-3);
}

.app-default-btn:hover {
	background-color: var(--main-color-4);
	color: var(--main-color-text-light);
}

.app-default-btn:active {
	background-color: var(--main-color-3);
	filter: drop-shadow(0px 0.1em 0px var(--main-color-2));
	transform: translateY(0.1em);
}

.app-success-btn {
	border: 1px solid var(--main-color-2);
	filter: drop-shadow(0px 0.2em 0px var(--main-color-2));
	color: var(--main-color-text-light);
	background-color: var(--secondary-color-success);
}

.app-success-btn:hover {
	background-color: var(--main-color-success);
	color: var(--main-color-text-light);
}

.app-success-btn:active {
	background-color: var(--main-color-success);
	filter: drop-shadow(0px 0.1em 0px var(--main-color-2));
	transform: translateY(0.1em);
}

.app-error-btn {
	border: 1px solid var(--main-color-2);
	filter: drop-shadow(0px 0.2em 0px var(--main-color-2));
	color: var(--main-color-text-light);
	background-color: var(--secondary-color-error);
}

.app-error-btn:hover {
	background-color: var(--main-color-error);
	color: var(--main-color-text-light);
}

.app-error-btn:active {
	background-color: var(--main-color-error);
	filter: drop-shadow(0px 0.1em 0px var(--main-color-2));
	transform: translateY(0.1em);
}

.app-warn-btn {
	border: 1px solid var(--main-color-2);
	filter: drop-shadow(0px 0.2em 0px var(--main-color-2));
	color: var(--main-color-text-light);
	background-color: var(--secondary-color-warn);
}

.app-warn-btn:hover {
	background-color: var(--main-color-warn);
	color: var(--main-color-text-light);
}

.app-warn-btn:active {
	background-color: var(--main-color-warn);
	filter: drop-shadow(0px 0.1em 0px var(--main-color-2));
	transform: translateY(0.1em);
}

.app-disabled-btn {
	background-color: var(--main-color-disabled);
	color: var(--main-color-text-dark);
	pointer-events: none;
	cursor: default;
}

p,
label {
	color: var(--main-color-text-light);
}

/* Global input/textarea/select style */
input,
textarea,
select {
	padding: 5px 10px;
	border: 2px solid var(--main-color-border-dark);
	border-radius: 10px;
	color: var(--main-color-text-dark);
	background-color: var(--main-color-2);
}

input {
	padding-left: 20px !important;
}

/* Global input/textarea/select disabled style */
input[disabled],
textarea[disabled],
select:disabled {
	color: var(--main-color-text-dark);
	background-color: var(--main-color-2);
	border: 1px solid var(--main-color-border-dark);
}

/* Global input/textarea/select focus style */
input:focus,
textarea:focus,
select:focus {
	outline: none;
	border: 2px solid var(--main-color-border-dark);
	box-shadow: 0 0 0 2pt var(--main-color-5);
}

/* Global textarea style */
textarea {
	resize: none;
}

/* Global select style */
.select {
	min-height: 40px;
}

/* Global option style */
option {
	padding: 5px;
	font-size: 17px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

/* Heading style */
h1,
h2,
h3,
h4,
h5,
h6 {
	color: var(--main-color-text-light);
	cursor: default;
}

h1 {
	max-width: 80vw;
	font-size: 60px;
	/* font-size: 80px; */
	margin: 0px auto;
	padding: 20px 0px;
	/* padding: 5vh 0px 20px 0px; */
	text-shadow: 4px 4px var(--main-color-6);
	-webkit-text-stroke: 2px var(--main-color-text-dark);
	text-align: center;
}

h2 {
	font-size: 60px;
}

h3 {
	font-size: 40px;
}

.swal2-title,
.swal2-html-container {
	filter: drop-shadow(2px 2px 2px var(--main-color-border-dark));
}

.swal2-container h2 {
	text-decoration: none;
}

.swal2-input {
	background-color: var(--main-color-1) !important;
}

.swal2-cancel {
	background-color: var(--main-color-info) !important;
}

.swal2-loader {
	border-color: var(--main-color-6) rgba(0, 0, 0, 0) var(--main-color-6) rgba(0, 0, 0, 0) !important;
	filter: drop-shadow(2px 2px 2px var(--main-color-border-dark));
}

.ap-card {
	flex: 1 1 50%;
	max-width: 90%;
	height: 10vh;
	min-height: 100px;
	margin: 5px auto;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: transform 0.5s;
	border: 2px solid var(--main-color-border-dark);
}

.ap-card:hover {
	cursor: pointer;
	transform: scale(1.1);
	z-index: 5;
}

.ap-card div {
	text-align: center;
}

.ap-card svg {
	margin: 5px;
	font-size: 45px;
	color: var(--main-color-text-light);
	filter: drop-shadow(2px 2px 2px var(--main-color-border-dark));
}

.ap-card p {
	font-size: 20px;
	text-shadow: 1px 1px var(--main-color-text-dark);
}
</style>
