<template>
	<div class="cl-wrap-content">
		<h3>{{ $t('clCookieWarning') }}</h3>
		<p>{{ $t('clCookieWarningText') }}</p>
		<router-link :to="{ name: 'LegalNotice' }">{{ $t('clLegalNotice') }}</router-link>
		<div class="cl-wrap-buttons">
			<button class="app-error-btn" @click="changeCookieLaw(false)">{{ $t('clDecline') }}</button>
			<button class="app-success-btn" @click="changeCookieLaw(true)">{{ $t('clAccept') }}</button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'CookieLaw',
	methods: {
		changeCookieLaw(accepted) {
			sessionStorage.setItem('askedForCookieLaw', 'true');
			if (accepted) {
				localStorage.setItem('cookiesAllowed', 'true');
				if (sessionStorage.getItem('accessCode')) localStorage.setItem('accessCode', sessionStorage.getItem('accessCode'));
				if (sessionStorage.getItem('theme')) localStorage.setItem('theme', sessionStorage.getItem('theme'));
			} else localStorage.clear();
			this.$emit('closeCookieLaw');
		},
	},
};
</script>

<style scoped>
.cl-wrap-content {
	width: fit-content;
	max-width: 40vw;
	min-width: 350px;
	height: fit-content;
	background-color: var(--main-color-4);
	padding: 10px;
	border-radius: 20px;
	box-shadow: 5px 5px 5px 5px var(--main-color-border-dark);
}

h3 {
	font-size: 25px;
	color: var(--main-color-text-light);
	margin: 0px auto 10px auto;
}

p {
	font-size: 20px;
	margin: 10px auto;
}

a {
	display: block;
	font-size: 20px;
	color: var(--main-color-text-light);
	margin: 10px auto;
}

.cl-wrap-buttons {
	width: 100%;
	height: fit-content;
	text-align: end;
}

.cl-wrap-buttons button {
	width: 140px;
	height: 35px;
	font-size: 20px;
	margin: 10px;
}
</style>
