import swal from 'sweetalert2';
import axios from 'axios';
import { socket } from './socket.js';

let queue = [];
let blockQueue = false;
let session = null;
let toastWrapper = null;
let toastType = null;
let timeoutInit = false;

export const global = {
	// Ends the loading screen on the current page
	// @arg type[String] - The type of the toast
	// @arg msg[String] - The displayed message
	showToast: function (type, msg) {
		if (!blockQueue) {
			blockQueue = true;
			window.setTimeout(() => {
				toastType = type;
				toastWrapper = document.querySelector('.ap-toast-wrapper');
				if (type.toLowerCase() == 'info') {
					toastWrapper.classList.add('show-info');
					document.querySelector('.ap-info-text').innerText = msg;
				} else if (type.toLowerCase() == 'success') {
					toastWrapper.classList.add('show-success');
					document.querySelector('.ap-success-text').innerText = msg;
				} else if (type.toLowerCase() == 'warn') {
					toastWrapper.classList.add('show-warn');
					document.querySelector('.ap-warn-text').innerText = msg;
				} else if (type.toLowerCase() == 'error') {
					toastWrapper.classList.add('show-error');
					document.querySelector('.ap-error-text').innerText = msg;
				}

				toastWrapper.addEventListener('animationend', this.animationListener);
			}, 100);
		} else {
			let msgExists = queue.filter((pair) => pair[0] == type && pair[1] == msg)[0];
			if (!msgExists) queue.push([type, msg]);

			if (!timeoutInit) {
				timeoutInit = true;

				window.setInterval(() => {
					if (!blockQueue && queue.length > 0) {
						let toast = queue.shift();
						if (toast) this.showToast(toast[0], toast[1]);
					}
				}, 100);
			}
		}
	},
	animationListener() {
		if (toastType.toLowerCase() == 'info') toastWrapper.classList.remove('show-info');
		else if (toastType.toLowerCase() == 'success') toastWrapper.classList.remove('show-success');
		else if (toastType.toLowerCase() == 'warn') toastWrapper.classList.remove('show-warn');
		else if (toastType.toLowerCase() == 'error') toastWrapper.classList.remove('show-error');

		blockQueue = false;
		toastWrapper.removeEventListener('animationend', this.animationListener);
	},
	abortAnimation() {
		if (toastType.toLowerCase() == 'info') toastWrapper.classList.remove('show-info');
		else if (toastType.toLowerCase() == 'success') toastWrapper.classList.remove('show-success');
		else if (toastType.toLowerCase() == 'warn') toastWrapper.classList.remove('show-warn');
		else if (toastType.toLowerCase() == 'error') toastWrapper.classList.remove('show-error');

		blockQueue = false;
	},
	showPopup(title, html, icon, cancleButton, confirmButton, input, cb) {
		swal.fire({
			title: `<p style='color: var(--main-color-text-light)'>${title}</p>`,
			html: `<p style='color: var(--main-color-text-light)'>${html}</p>`,
			icon: icon,
			background: 'var(--main-color-5)',
			showCancelButton: cancleButton,
			cancelButtonColor: cancleButton ? cancleButton.color : '',
			cancelButtonText: cancleButton ? cancleButton.text : '',
			showConfirmButton: confirmButton,
			confirmButtonText: confirmButton ? confirmButton.text : '',
			confirmButtonColor: confirmButton ? confirmButton.color : '',
			input: input ? input.type : '',
			inputPlaceholder: input ? input.placeholder : '',
			heightAuto: false,
		}).then((result) => {
			if (cb) cb(result);
		});
	},
	showTimer: function (timer, translations, cb) {
		let timerInterval;
		let backgroundImage = require(`@/assets/images/background/background_light_dimmed.png`);
		swal.fire({
			title: translations.title,
			html: `${translations.message} <b></b> s`,
			timer: timer,
			color: '#ffffff',
			timerProgressBar: true,
			background: `url(${backgroundImage}) no-repeat center center / cover`,
			didOpen: () => {
				swal.showLoading();
				const b = swal.getHtmlContainer().querySelector('b');
				timerInterval = setInterval(() => {
					b.textContent = (swal.getTimerLeft() / 1000).toFixed(0);
				}, 100);
			},
			willClose: () => {
				clearInterval(timerInterval);
			},
		}).then((result) => {
			cb(result);
		});
	},
	getData: async function (endpoint, route, data, options, cb) {
		axios
			.get(`/api/${endpoint}${route}`, data, options)
			.then((res) => {
				cb(null, res.data);
			})
			.catch((err) => {
				console.log(err);
				cb(err, null);
			});
	},
	postData: async function (endpoint, route, data, options, cb) {
		axios
			.post(`/api/${endpoint}${route}`, data, options)
			.then((res) => {
				cb(null, res.data);
			})
			.catch((err) => {
				cb(err, null);
			});
	},
	putData: async function (endpoint, route, data, options, cb) {
		axios
			.put(`/api/${endpoint}${route}`, data, options)
			.then((res) => {
				cb(null, res.data);
			})
			.catch((err) => {
				cb(err, null);
			});
	},
	patchData: async function (endpoint, route, data, options, cb) {
		axios
			.patch(`/api/${endpoint}${route}`, data, options)
			.then((res) => {
				cb(null, res.data);
			})
			.catch((err) => {
				cb(err, null);
			});
	},
	deleteData: async function (endpoint, route, data, options, cb) {
		axios
			.delete(`/api/${endpoint}${route}`, data, options)
			.then((res) => {
				cb(null, res.data);
			})
			.catch((err) => {
				cb(err, null);
			});
	},
	checkAccess: function (router, checkCredentials) {
		let accessCode = this.getAccessCode();
		if (checkCredentials) {
			this.postData(
				'access',
				'/checkCredentials',
				{ credentials: this.getCredentials() },
				{ headers: { pageauthheader: accessCode } },
				async (err, data) => {
					if (err) {
						if (err.response.status == 401) this.logoutUser();

						if (router.history.current.name != 'Home') {
							await router.push({ name: 'Home' });
							location.reload();
						} else location.reload();
					}
				}
			);
		} else {
			this.postData('access', '/checkAccess', { accessCode: accessCode }, null, async (err, data) => {
				if (err) {
					if (err.response.status == 401) this.handleUnauthorizedAccess();

					if (router.history.current.name != 'PageAccess') {
						await router.push({ name: '/' });
						location.reload();
					}
				} else if (router.history.current.name == 'PageAccess') router.push({ name: 'Home' });
			});
		}
	},
	logoutUser() {
		sessionStorage.removeItem('user');
		localStorage.removeItem('user');
		sessionStorage.removeItem('credentials');
		localStorage.removeItem('credentials');
	},
	handleUnauthorizedAccess() {
		this.logoutUser();
		sessionStorage.removeItem('accessCode');
		localStorage.removeItem('accessCode');
	},
	getAccessCode() {
		return sessionStorage.getItem('accessCode') ? sessionStorage.getItem('accessCode') : localStorage.getItem('accessCode');
	},
	getAdminCode() {
		return sessionStorage.getItem('adminCode') ? sessionStorage.getItem('adminCode') : localStorage.getItem('adminCode');
	},
	getCredentials() {
		let credentials = sessionStorage.getItem('credentials') ? sessionStorage.getItem('credentials') : localStorage.getItem('credentials');
		if (credentials) return JSON.parse(credentials);
		else return null;
	},
	// https://stackoverflow.com/a/201378
	validEmail(email) {
		const re =
			/^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
		return re.test(email);
	},
	getUser() {
		let user = sessionStorage.getItem('user') ? sessionStorage.getItem('user') : localStorage.getItem('user');
		if (user) return JSON.parse(user);
		else return null;
	},
	setSession(newSession) {
		session = newSession;
	},
	getSession() {
		return session;
	},
	isAdmin() {
		let admin = this.getUser();
		if (admin && admin.role == 'ADMIN') return true;
		else return false;
	},
	isHost() {
		if (this.getUser() && session) {
			if (this.getUser().uID == session.session.uID) return true;
			else return false;
		} else return false;
	},
	parseDate(date, reduced = false, includeSeconds = true) {
		let d = new Date(date);

		let month = d.getMonth() + 1;
		let day = d.getDate();
		let hours = d.getHours();
		let minutes = d.getMinutes();
		let seconds = d.getSeconds();

		month = month < 10 ? '0' + month : month;
		day = day < 10 ? '0' + day : day;
		hours = hours < 10 ? '0' + hours : hours;
		minutes = minutes < 10 ? '0' + minutes : minutes;
		seconds = seconds < 10 ? '0' + seconds : seconds;

		let timestamp = '';
		if (reduced) {
			let now = new Date();
			if (now.getDate() == d.getDate() && now.getMonth() == d.getMonth() && now.getFullYear() == d.getFullYear()) {
				if (includeSeconds) timestamp = `${hours}:${minutes}:${seconds}`;
				else timestamp = `${hours}:${minutes}`;
			} else {
				if (includeSeconds) timestamp = `${day}.${month}.${d.getFullYear()} ${hours}:${minutes}:${seconds}`;
				else timestamp = `${day}.${month}.${d.getFullYear()} ${hours}:${minutes}`;
			}
		} else {
			if (includeSeconds) timestamp = `${day}.${month}.${d.getFullYear()} ${hours}:${minutes}:${seconds}`;
			else timestamp = `${day}.${month}.${d.getFullYear()} ${hours}:${minutes}`;
		}
		return timestamp;
	},
	// Sets the colors for a specific element
	// @arg color[String] - Identifier for global color style
	// @return [String] - Return the parsed global color style
	setColor: function (color) {
		// .trim() is required, otherwise the color would have a space before it
		return `${getComputedStyle(document.getElementsByTagName('body')[0]).getPropertyValue(color)}`.trim();
	},
	arrayMove(array, oldIndex, newIndex) {
		if (newIndex >= array.length) {
			let k = newIndex - array.length + 1;
			while (k--) array.push(undefined);
		}
		array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);
		return array;
	},
	setSessionState: function (sID, uID, gID, state, cb) {
		this.postData(
			'session',
			'/setSessionState',
			{
				sID: sID,
				uID: uID,
				gID: gID,
				state: state,
			},
			{
				headers: { pageauthheader: this.getAccessCode() },
				auth: this.getCredentials(),
			},
			(err, data) => {
				if (cb) {
					if (err) cb(err);
					else cb(null);
				} else {
					console.log(err);
				}
			}
		);
	},
	/**
	 * @description Adds a new history entry
	 * @param {String} sID - The ID of the session
	 * @param {String} uID - The ID of the user that created the history entry
	 * @param {String} gID - The ID of the game show
	 * @param {String} qID - The ID of the question
	 * @param {String} action - The action of the entry
	 * @param {String} info - A optional info
	 * @param {String} info2 - A optional info
	 * @param {String} result - The result of the entry
	 * @param {CallableFunction} cb - Callback after the request finished
	 */
	addHistoryEntry: function (sID, uID, gID, qID, action, info, info2, result, cb) {
		this.postData(
			'session',
			'/addHistoryEntry',
			{
				sID: sID,
				uID: uID,
				gID: gID,
				qID: qID,
				action: action,
				info: info,
				info2: info2,
				result: result,
			},
			{
				headers: { pageauthheader: this.getAccessCode(), uid: this.getUser().uID },
				auth: this.getCredentials(),
			},
			(err, data) => {
				if (cb) {
					if (err) cb(err);
					else {
						socket.getSocket(function (socket) {
							socket.emit('refresh_history', sID);
							cb(null);
						});
					}
				} else {
					console.log(err);
				}
			}
		);
	},
	deleteHistoryEntry: function (hID, cb) {
		let that = this;
		this.deleteData(
			'session',
			'/deleteHistoryEntry',
			{
				headers: { pageauthheader: this.getAccessCode() },
				auth: this.getCredentials(),
				params: { uID: this.getUser().uID, hID: hID },
			},
			null,
			(err, data) => {
				if (cb) {
					if (err) cb(err);
					else {
						socket.getSocket(function (socket) {
							socket.emit('refresh_history', that.getSession().session.sID);
							cb(null);
						});
					}
				} else {
					console.log(err);
				}
			}
		);
	},
	hasErrorResponseMsg(err) {
		if (err) if (err.response) if (err.response.data) if (err.response.data.msg) return true;
		return false;
	},
	// Computes the amount of pages for the table pagination
	// @arg allValues[Number] - The values of the table
	// @arg pageSize[Number] - The size of the page
	// @arg currentPage[Number] - The current page
	// @returns [Array] The pages with or without added dividers
	getPages(allValues, pageSize, currentPage) {
		let pages = [];
		let maxPages = Math.ceil(allValues / pageSize);
		// First page is selected
		if (currentPage == 1) {
			pages.push(currentPage);
			for (let i = 2; i < 4; i++) if (i <= maxPages) pages.push(i);

			// Add upper divider and last page if more than 4 pages are available
			if (pages.length == 3 && maxPages > 4) {
				pages.push('pageUpperDivider');
				pages.push(maxPages);
			} else if (pages.length == 3 && maxPages == 4) pages.push(maxPages);
		}
		// Last page is selected
		else if (currentPage == maxPages) {
			// Add first page and lower divider if more than 4 pages are available
			if (maxPages > 4) {
				pages.push(1);
				pages.push('pageLowerDivider');
				for (let i = maxPages - 2; i < maxPages; i++) pages.push(i);
				pages.push(maxPages);
			} else for (let i = 1; i < maxPages + 1; i++) pages.push(i);
		}
		// A page between first and last is selected
		else {
			// The page is to close to the first page to insert a lower divider
			if (currentPage - 2 <= 1) {
				for (let i = 1; i < currentPage; i++) pages.push(i);
				pages.push(currentPage);

				// The page is to close to the last page to insert a upper divider
				if (currentPage + 2 > maxPages) for (let i = currentPage + 1; i < maxPages + 1; i++) pages.push(i);
				else {
					for (let i = currentPage + 1; i < currentPage + 3; i++) pages.push(i);
					if (maxPages - 1 == pages[pages.length - 1]) pages.push(maxPages);
					else if (maxPages !== pages[pages.length - 1]) {
						pages.push('pageUpperDivider');
						pages.push(maxPages);
					}
				}
			}
			// The page is to close to the last page to insert a upper divider
			else if (currentPage + 2 >= maxPages) {
				pages.push(1);
				pages.push('pageLowerDivider');
				for (let i = currentPage - 2; i < currentPage; i++) pages.push(i);
				pages.push(currentPage);
				for (let i = currentPage + 1; i < maxPages + 1; i++) pages.push(i);
			}
			// The page is somewhere in the middle and has both dividers
			else {
				pages.push(1);
				if (currentPage - 2 !== 1) pages.push('pageLowerDivider');
				for (let i = currentPage - 2; i < currentPage + 3; i++) pages.push(i);
				if (maxPages - 1 !== pages[pages.length - 1]) pages.push('pageUpperDivider');
				pages.push(maxPages);
			}
		}

		return pages;
	},
};
