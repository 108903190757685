<template>
	<div class="qch-wrap-content">
		<p>
			{{ $t('qchQuestionCreationIntro1') }}
			<span v-if="$global.getUser()">
				(<router-link :to="{ name: 'QuestionCreation' }">{{ $t('qchQuestionCreation') }}</router-link
				>)
			</span>
		</p>
		<p>{{ $t('qchSelectionOfShows') }}</p>
		<div class="qch-wrap-shows">
			<ShowSelection :shows="shows" :selectedShow="shows[0].abbreviation" :noSelect="true" @selectShow="selectShow" />
		</div>
		<p>{{ $t('qchQuestionCreationText') }}</p>
		<div class="qch-wrap-slideshow">
			<div v-if="selectedSlide" class="qch-selected-show">
				<h3>{{ $t(selectedSlide.alt) }}</h3>
				<p v-html="$t(selectedSlide.desc)"></p>
			</div>
			<div class="qch-slideshow">
				<div class="qch-slide" v-for="(slide, idx) in slides" :key="idx" :class="`slide--${idx}`">
					<img v-if="selectedSlide.src == slide.src" :src="require(`@/assets/images/help/${slide.src}`)" :alt="$t(slide.alt)" />
				</div>
			</div>
		</div>
		<p>{{ $t('qchQuestionManagement') }}</p>
		<img :src="require(`@/assets/images/help/question_creation_management.png`)" :alt="$t('qchQuestionManagement')" class="qch-single-img" />
	</div>
</template>

<script>
import ShowSelection from '../quizNightCreation/ShowSelection.vue';
export default {
	name: 'QuestionCreationHelp',
	components: {
		ShowSelection,
	},
	props: {
		shows: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			selectedSlide: {},
			slides: [
				{ show: 'DPIN', src: 'dpin_question_creation.png', alt: 'qchDPINAlt', desc: 'qchDPINDesc' },
				{ show: 'XFZ', src: 'xfz_question_creation.png', alt: 'qchXFZAlt', desc: 'qchXFZDesc' },
				{ show: 'WLP', src: 'wlp_question_creation.png', alt: 'qchWLPAlt', desc: 'qchWLPDesc' },
				{ show: 'SMF', src: 'smf_question_creation.png', alt: 'qchSMFAlt', desc: 'qchSMFDesc' },
				{ show: 'KQ', src: 'kq_question_creation.png', alt: 'qchKQAlt', desc: 'qchKQDesc' },
				{ show: 'ZGW', src: 'zgw_question_creation.png', alt: 'qchZGWAlt', desc: 'qchZGWDesc' },
			],
		};
	},
	mounted() {
		this.selectedSlide = this.slides.filter((slide) => slide.show == this.shows[0].abbreviation.toUpperCase())[0];
	},
	methods: {
		selectSlide(slide) {
			this.selectedSlide = slide;
		},
		selectShow(show) {
			let slide = this.slides.filter((slide) => slide.show == show.toUpperCase())[0];
			if (slide) this.selectedSlide = slide;
		},
	},
};
</script>

<style scoped>
.qch-wrap-content p {
	width: 100%;
	margin: 2px 0px;
	font-size: 25px;
	text-align: start;
	color: var(--main-color-text-light);
	filter: drop-shadow(5px 5px 5px var(--main-color-text-dark));
}

.qch-wrap-shows {
	margin: 10px 0px;
}

.qch-single-img {
	max-width: 90vw;
	max-height: 650px;
	margin: 20px auto;
	box-sizing: border-box;
	border: 2px solid var(--main-color-border-dark);
	box-shadow: 0px 0px 20px 2px var(--main-color-border-dark);
}

.qch-wrap-content a {
	text-decoration: underline;
	text-decoration-color: var(--main-color-text-light);
	color: var(--main-color-text-light);
}

.qch-wrap-content a:hover {
	color: var(--main-color-text-light);
	text-decoration-color: var(--main-color-text-light);
}

.qch-wrap-slideshow {
	margin: 20px auto 40px auto;
}

.qch-selected-show {
	width: 100%;
	margin: 10px 0px 20px 0px;
	text-align: start;
}

.qch-selected-show h3 {
	font-size: 35px;
	padding: 20px 0px;
	text-shadow: 2px 2px var(--main-color-6);
	-webkit-text-stroke: 2px var(--main-color-text-dark);
	text-align: center;
}

.qch-selected-show p {
	font-size: 25px;
}

.qch-slideshow {
	margin-bottom: 0px;
	padding: 10px;
	box-sizing: border-box;
	border-radius: 50px;
}

.qch-slide {
	height: fit-content;
	max-height: 700px;
	display: flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	opacity: 1 !important;
}

.qch-slide img {
	max-height: 650px;
	max-width: calc(90vw - 40px);
	border: 2px solid var(--main-color-border-dark);
	margin: 20px;
	box-sizing: border-box;
	border: 2px solid var(--main-color-border-dark);
	box-shadow: 0px 0px 20px 2px var(--main-color-border-dark);
}

.qch-slide-thumbnail {
	height: 110px;
	padding: 5px;
	transition: opacity 0.3s;
	cursor: pointer;
	border: none;
}

.qch-slide-thumbnail img {
	max-height: 100%;
	max-width: 100%;
	margin: 0px;
	border: 1px solid var(--main-color-border-dark);
	box-shadow: none;
}

.qch-slide-thumbnail:hover {
	opacity: 0.75;
}

.qch-thumbnails {
	margin: 0 -5px;
}

.qch-selected-img img {
	box-sizing: border-box;
	border: 2px solid var(--main-color-6);
}
</style>
