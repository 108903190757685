<template>
	<div v-if="showConnectionStatus" class="cc-wrap-content">
		<button class="app-default-btn cc-host-btn" @click="forceReload">{{ $t('sbForceReload') }}</button>
		<button class="app-default-btn cc-host-btn" @click="checkUserConnections">
			{{ $t('sbCheckUserConnection') }}
		</button>
		<div class="cc-wrap-row">
			<div class="cc-user">{{ $t('sbUser') }}</div>
			<div class="cc-socket-status">{{ $t('sbSocketStatus') }}</div>
			<div class="cc-session-status">{{ $t('sbSessionStatus') }}</div>
			<div class="cc-force-rejoin"></div>
			<div class="cc-kick-user"></div>
		</div>
		<div v-for="user in users" :key="user.uID" class="cc-wrap-row">
			<div class="cc-user">
				<img :src="require(`@/assets/images/profilePictures/${user.profilePic}`)" />
				<p>{{ user.name }}</p>
			</div>
			<div class="cc-socket-status">
				<p v-if="socketConnected(user)" class="cc-connected">
					{{ $t('sbConnected') }}
				</p>
				<p v-else class="cc-disconnected">{{ $t('sbDisconnected') }}</p>
			</div>
			<div class="cc-session-status">
				<p v-if="sessionConnected(user)" class="cc-connected">
					{{ $t('sbConnected') }}
				</p>
				<p v-else class="cc-disconnected">{{ $t('sbDisconnected') }}</p>
			</div>
			<div class="cc-force-rejoin">
				<fai icon="fas fa-redo" :disabled="!(socketConnected(user) && !sessionConnected(user))" @click="forceRejoin(user)" />
			</div>
			<div class="cc-kick-user">
				<fai v-if="$global.getUser().uID !== user.uID" icon="fas fa-times-circle" @click="kickPlayer(user)" />
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ConnectionCheck',
	props: {
		users: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			userStatus: [],
			showConnectionStatus: false,
		};
	},
	created() {
		this.checkUserConnections();
	},
	methods: {
		checkUserConnections() {
			let that = this;
			this.$emit('checkUserConnections', function (data) {
				that.userStatus = data;
				that.showConnectionStatus = true;
			});
		},
		socketConnected(user) {
			return this.userStatus.socketStatus.filter((ps) => ps == user.uID)[0];
		},
		sessionConnected(user) {
			return this.userStatus.sessionStatus.filter((ps) => ps == user.uID)[0];
		},
		forceReload() {
			let that = this;
			this.$emit('forceReload', function (success) {
				if (!success) that.$global.showToast('error', that.$t('sbForceReloadError'));
				else that.$global.showToast('success', that.$t('sbForceReloadSuccess'));
				that.checkUserConnections();
			});
		},
		forceRejoin(user) {
			let that = this;
			this.$emit('forceRejoin', {
				uID: user.uID,
				cb: function () {
					that.checkUserConnections();
				},
			});
		},
		kickPlayer(user) {
			let that = this;
			this.$global.showPopup(
				this.$t('ueKickPlayer'),
				this.$t('ueKickPlayerText'),
				'warning',
				{ text: this.$t('ueCancel'), color: 'var(--main-color-4)' },
				{ text: this.$t('ueKick'), color: 'var(--main-color-error)' },
				null,
				function (result) {
					if (result.isConfirmed) {
						that.$emit('kickPlayer', user.uID);
					}
				}
			);
		},
	},
};
</script>

<style scoped>
.cc-wrap-content {
	width: 100%;
	height: fit-content;
}

.cc-host-btn {
	width: 230px;
	height: fit-content;
	display: block;
	margin: 20px auto 15px auto;
	font-size: 17px;
}

.cc-wrap-row {
	width: 100%;
	padding: 5px 10px;
	box-sizing: border-box;
	font-size: 17px;
	border-bottom: 1px solid var(--main-color-border-light);
	background-color: var(--main-color-dark-transparent-80);
	color: var(--main-color-text-light);
}

.cc-wrap-row:nth-child(2) {
	border-radius: 20px 20px 0px 0px;
}

.cc-user,
.cc-socket-status,
.cc-session-status,
.cc-force-rejoin,
.cc-kick-user {
	display: inline-flex;
	align-items: center;
	vertical-align: top;
}

.cc-user {
	width: calc(100% - 280px);
	justify-content: start;
	margin: 0px;
	line-height: 30px;
}

.cc-user img {
	width: 30px;
	height: 30px;
	display: inline-block;
	margin-right: 10px;
	border-radius: 50%;
	border: 2px solid var(--main-color-border-dark);
}

.cc-user p {
	margin: 0px;
}

.cc-socket-status,
.cc-session-status {
	width: 100px;
	justify-content: center;
	line-height: 30px;
}

.cc-kick-user {
	color: var(--main-color-error);
	-webkit-filter: drop-shadow(4px 4px 2px var(--main-color-text-dark));
	filter: drop-shadow(4px 4px 2px var(--main-color-text-dark));
}

.cc-kick-user,
.cc-force-rejoin {
	height: 32px;
	width: 40px;
	justify-content: center;
}

.cc-kick-user svg,
.cc-force-rejoin svg {
	font-size: 25px;
	margin-top: 2px;
	cursor: pointer;
}

.cc-kick-user svg:hover {
	color: var(--secondary-color-error);
}

.cc-force-rejoin svg:hover {
	color: var(--main-color-6);
}

.cc-force-rejoin svg[disabled] {
	cursor: default;
	pointer-events: none;
	color: var(--main-color-light-transparent-80);
}

.cc-connected {
	color: var(--main-color-success);
}

.cc-disconnected {
	color: var(--main-color-error);
}
</style>
