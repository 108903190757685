var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"le-wrap-content"},[_c('div',{staticClass:"le-wrap-header"},[_c('div',{staticClass:"le-place le-header"},[_vm._v(_vm._s(_vm.$t('lePlace')))]),_c('div',{staticClass:"le-name le-header"},[_vm._v(_vm._s(_vm.$t('leName')))]),_c('div',{staticClass:"le-points le-header"},[_vm._v(_vm._s(_vm.$t('lePoints')))])]),_vm._l((_vm.places),function(place,idx){return _c('div',{key:idx,staticClass:"le-wrap-place"},_vm._l((place),function(player){return _c('div',{key:player.uID,staticClass:"le-wrap-entry"},[_c('div',{class:[
					_vm.getPlace(player.uID) == 1
						? 'le-gold'
						: _vm.getPlace(player.uID) == 2
						? 'le-silver'
						: _vm.getPlace(player.uID) == 3
						? 'le-bronze'
						: '',
					'le-place',
					'le-cell' ]},[_vm._v(" "+_vm._s(_vm.getPlace(player.uID))+". ")]),_c('div',{staticClass:"le-name le-cell"},[_c('img',{attrs:{"src":require(("@/assets/images/profilePictures/" + (player.profilePic)))}}),_c('p',[_vm._v(_vm._s(player.name))])]),_c('div',{staticClass:"le-points le-cell"},[_vm._v(_vm._s(_vm.getPoints(player.uID))+" "+_vm._s(_vm.$t('lePointsAbbrv')))])])}),0)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }