<template>
	<div class="qc-wrap-content">
		<h1>{{ $t('qcQuestionCreation') }}</h1>
		<ShowSelection :shows="shows" @selectShow="selectShow" />
		<div class="qc-wrap-top">
			<QuestionConfig
				v-if="selectedShow"
				:show="selectedShow"
				:editedQuestion="editedQuestion"
				:getsEdited="getsEdited"
				@questionsAdded="questionsAdded"
				@discardChanges="discardChanges"
			/>
		</div>
		<div v-if="selectedShow" class="qc-wrap-bottom">
			<div class="qc-wrap-question-top-bar">
				<h2>{{ $t('qcQuestionBrowser') }}</h2>
				<div class="qc-wrap-question-filter">
					<FilterBar :type="'question'" :isLoading="isLoading" :amountElements="questionAmount" @refresh="refreshBrowser" />
				</div>
			</div>
			<div class="qc-blur-ref">
				<div v-show="!firstItemVisible" class="qc-blur-before"></div>
				<div class="qc-wrap-questions">
					<QuestionPreview
						v-for="(question, idx) in questions"
						:id="`questions-${idx}`"
						:key="idx"
						:question="question"
						:show="selectedShow"
						:isCreation="true"
						:class="{ 'qc-first-child': idx == 0, 'qc-last-child': idx == questions.length - 1 }"
						@cloneQuestion="cloneQuestion"
						@editQuestion="editQuestion"
						@deleteQuestion="deleteQuestion"
					/>
					<div v-if="questions.length == 0 && !isLoading" class="qc-wrap-no-questions">
						<div class="qc-wrap-refresh">
							<fai icon="fas fa-sad-tear" />
							<p class="qc-no-questions">{{ $t('hoNoQuestionsFound') }}</p>
						</div>
					</div>
				</div>
				<div v-show="!lastItemVisible" class="qc-blur-after"></div>
			</div>
		</div>

		<!-- <QuestionList
				v-if="selectedShow"
				:key="refreshToggle"
				:show="selectedShow"
				:isCreation="true"
				@cloneQuestion="cloneQuestion"
				@editQuestion="editQuestion"
				@deleteQuestion="deleteQuestion"
			/> -->
	</div>
</template>

<script>
import FilterBar from '../components/lobby/filter/FilterBar.vue';
import QuestionPreview from '../components/questionCreation/QuestionPreview.vue';
import QuestionConfig from '../components/questionCreation/QuestionConfig.vue';
import QuestionList from '../components/questionCreation/QuestionList.vue';
import ShowSelection from '../components/quizNightCreation/ShowSelection.vue';
export default {
	name: 'QuestionCreation',
	components: {
		FilterBar,
		QuestionPreview,
		QuestionConfig,
		QuestionList,
		ShowSelection,
	},
	data() {
		return {
			isLoading: false,
			shows: [],
			selectedShow: null,
			editedQuestion: null,
			getsEdited: false,
			user: null,
			refreshClicked: false,
			questionFilter: null,
			questions: [],
			questionAmount: 0,
			firstItemVisible: true,
			lastItemVisible: true,
		};
	},
	created() {
		this.$global.checkAccess(this.$router, true);
		this.getRegisteredShows();
	},
	mounted() {
		document.querySelector('.qc-wrap-content').addEventListener('scroll', this.parentScrollListener);
	},
	beforeDestroy() {
		document.querySelector('.qc-wrap-content').removeEventListener('scroll', this.parentScrollListener);
		try {
			document.querySelector('.qc-wrap-questions').removeEventListener('scroll', this.scrollListener);
		} catch (error) {}
	},
	methods: {
		parentScrollListener(e) {
			window.dispatchEvent(new Event('customScroll'));
		},
		scrollListener(e) {
			this.firstItemVisible = this.itemVisible(true);
			this.lastItemVisible = this.itemVisible(false);
		},
		itemVisible(first) {
			const item = document.getElementById(`questions-${first ? 0 : this.questions.length - 1}`);
			const container = document.querySelector('.qc-wrap-questions');
			if (item && container) {
				const containerRect = container.getBoundingClientRect();
				const itemRect = item.getBoundingClientRect();

				// Check if the item's top and bottom are within the container's visible area
				const isTopVisible = itemRect.top >= containerRect.top && itemRect.top <= containerRect.bottom;
				const isBottomVisible = itemRect.bottom >= containerRect.top && itemRect.bottom <= containerRect.bottom;

				return isTopVisible || isBottomVisible;
			} else if (this.questions.length == 0) return true;
			else return false;
		},
		getRegisteredShows() {
			let that = this;
			this.$global.getData(
				'quiz',
				'/getRegisteredShows',
				{
					headers: { pageauthheader: this.$global.getAccessCode() },
					auth: this.$global.getCredentials(),
				},
				null,
				function (err, result) {
					if (err) that.$global.showToast('error', that.$t(err.response ? err.response.data.msg : 'qcQueryError'));
					else {
						that.shows = result;
					}
				}
			);
		},
		queryQuestions() {
			let that = this;
			this.isLoading = true;
			this.$global.getData(
				'quiz',
				'/getQuestionsOfShow',
				{
					headers: { pageauthheader: this.$global.getAccessCode() },
					auth: this.$global.getCredentials(),
					params: {
						uID: this.$global.getUser().uID,
						show: this.selectedShow,
						questionFilter: this.questionFilter != null ? JSON.stringify(this.questionFilter) : null,
					},
				},
				null,
				function (err, result) {
					if (err) that.$global.showToast('error', that.$t(err.response ? err.response.data.msg : 'qlQueryError'));
					else {
						that.questions = result.questions;
						that.questionAmount = result.questionAmount;
					}
					that.isLoading = false;
					that.refreshClicked = false;
				}
			);
		},
		refreshBrowser(questionFilter) {
			if (!this.refreshClicked) {
				this.refreshClicked = true;
				this.questionFilter = questionFilter;
				this.questions = [];
				this.queryQuestions();
			}
		},
		selectShow(show) {
			this.selectedShow = null;
			this.$nextTick(() => {
				this.selectedShow = show;
				this.editedQuestion = null;
				this.getsEdited = false;
				this.questions = [];
				this.questionAmount = 0;

				this.$nextTick(() => {
					document.querySelector('.qc-wrap-questions').removeEventListener('scroll', this.scrollListener);
					this.$nextTick(() => document.querySelector('.qc-wrap-questions').addEventListener('scroll', this.scrollListener));
				});
			});
		},
		questionsAdded() {
			this.editedQuestion = null;
			this.getsEdited = false;
			[...document.querySelectorAll('.qp-wrap-content')].forEach((elem) => {
				elem.classList.remove('qp-highlighted');
			});
			this.queryQuestions();
		},
		discardChanges() {
			this.editedQuestion = null;
			this.getsEdited = false;
			[...document.querySelectorAll('.qp-wrap-content')].forEach((elem) => {
				elem.classList.remove('qp-highlighted');
			});
		},
		cloneQuestion(question) {
			this.editedQuestion = null;
			this.$nextTick(() => {
				this.editedQuestion = question;
				this.getsEdited = false;
			});
		},
		editQuestion(question) {
			this.editedQuestion = null;
			this.$nextTick(() => {
				this.editedQuestion = question;
				this.getsEdited = true;
			});
		},
		deleteQuestion(qID) {
			let that = this;
			this.$global.showPopup(
				this.$t('qcDeleteQuestion'),
				this.$t('qcDeleteQuestionText'),
				'warning',
				{ text: this.$t('qcCancel'), color: 'var(--main-color-4)' },
				{ text: this.$t('qcDelete'), color: 'var(--main-color-error)' },
				null,
				function (result) {
					if (result.isConfirmed) {
						that.$global.deleteData(
							'quiz',
							'/deleteQuestion',
							{
								headers: { pageauthheader: that.$global.getAccessCode() },
								auth: that.$global.getCredentials(),
								params: { uID: that.$global.getUser().uID, show: that.selectedShow, qID: qID },
							},
							null,
							function (err, result) {
								if (err) that.$global.showToast('error', that.$t(err.response ? err.response.data.msg : 'qcDeleteError'));
								else {
									that.$global.showToast('success', that.$t('qcDeleteSuccess'));
									that.queryQuestions();
								}
							}
						);
					}
				}
			);
		},
	},
};
</script>

<style scoped>
.qc-wrap-content {
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	overflow-x: hidden;
	overflow-y: auto;
}

.qc-wrap-top {
	width: 100%;
	display: inline-block;
	box-sizing: border-box;
	vertical-align: top;
	overflow: hidden;
}

.qc-wrap-bottom {
	width: calc(100% - 80px);
	height: 80vh;
	min-height: 300px;
	flex: 1 1 100%;
	margin: 10px 40px;
	padding: 10px;
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	border: 2px solid var(--main-color-border-dark);
	background-color: var(--main-color-3-cc);
}

.qc-wrap-question-top-bar {
	width: 100%;
	height: 160px;
	text-align: center;
}

.qc-wrap-question-top-bar h2 {
	height: 40px;
	margin: 0px auto;
	padding: 0px 0px 10px 0px;
	font-size: 35px;
	text-shadow: 2px 2px var(--main-color-6);
	-webkit-text-stroke: 2px var(--main-color-text-dark);
}

.qc-wrap-question-top-bar h2 svg {
	margin: 5px;
	font-size: 45px;
	color: var(--main-color-text-light);
	filter: drop-shadow(2px 2px 2px var(--main-color-border-dark));
}

.qc-wrap-question-filter {
	width: 100%;
	height: 100px;
	margin-right: 15px;
	box-sizing: border-box;
	padding: 0px 5px 0px 0px;
	overflow-y: auto;
	overflow-x: hidden;
	border-bottom: 1px solid var(--main-color-border-light);
}

.qc-blur-ref {
	width: 100%;
	height: calc(100% - 150px);
	position: relative;
}

.qc-wrap-questions {
	width: 100%;
	height: 100%;
	padding: 00px 10px;
	box-sizing: border-box;
	overflow: auto;
}

.qc-blur-before,
.qc-blur-after {
	position: absolute;
	left: 8px;
	width: calc(100% - 30px);
	margin: auto;
	height: 60px;
	backdrop-filter: blur(1px);
	z-index: 10;
	pointer-events: none;
}

.qc-blur-before {
	top: 0px;
	background: linear-gradient(to bottom, #00000033, #00000000);
}

.qc-blur-after {
	bottom: 0;
	background: linear-gradient(to top, #00000033, #00000000);
}

.qc-wrap-no-questions {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.qc-wrap-no-questions p {
	text-shadow: 3px 3px var(--main-color-border-dark);
}

.qc-wrap-refresh {
	text-align: center;
	font-size: 30px;
}

.qc-wrap-refresh svg {
	margin: 10px;
	font-size: 60px;
	filter: drop-shadow(2px 2px 2px var(--main-color-border-dark));
	color: var(--main-color-6);
}
</style>

<style>
.qc-first-child img,
.qc-last-child img {
	z-index: 3 !important;
}

.qc-first-child img:hover {
	transform: translateX(-100px) translateY(100px) scale(3) !important;
}
.qc-last-child img:hover {
	transform: translateX(-100px) translateY(-100px) scale(3) !important;
}
</style>
