<template>
	<div class="iq-wrap-content">
		<div v-if="largeImage" class="iq-large-image" @click="largeImage = null">
			<div class="iq-wrap-large-image">
				<!-- <div class="iq-close-hover" @click="largeImage = null">
					<div>
						<fai icon="fas fa-minus" />
					</div>
				</div> -->
				<img :src="largeImage" alt="" @contextmenu="handleContextMenu" />
			</div>
		</div>
		<img
			:src="image"
			alt=""
			:class="['iq-image', imageClass]"
			@click="largeImage = image"
			@contextmenu="handleContextMenu"
			:title="$global.isHost() ? $t('iqGreyedOut') : ''"
		/>
	</div>
</template>

<script>
export default {
	name: 'ImageQuestion',
	props: {
		image: {
			type: String,
			required: true,
		},
		imageClass: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			largeImage: null,
		};
	},
	methods: {
		handleContextMenu(e) {
			return false;
		},
	},
};
</script>

<style scoped>
.iq-wrap-content {
	text-align: center;
}

.iq-large-image {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0px;
	left: 0px;
	background-color: var(--main-color-dark-transparent-cc);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 30;
}

.iq-large-image .iq-wrap-large-image img {
	max-width: calc(100vw - 40px);
	max-height: calc(100vh - 40px);
	border-radius: 20px;
	/* margin-bottom: 10%; */
}

.iq-wrap-large-image {
	position: relative;
}

.iq-close-hover {
	width: 100%;
	height: 20px;
	position: absolute;
	top: 0px;
	left: 0px;
	display: none;
	text-align: center;
	color: var(--main-color-text-light);
	border-bottom: 2px solid var(--main-color-border-dark);
	border-radius: 20px 20px 0px 0px;
	background-color: var(--main-color-light-transparent-80);
}

.iq-close-hover div {
	width: 100%;
	height: inherit;
	border-radius: 20px 20px 0px 0px;
	background-color: var(--main-color-dark-transparent-80);
}

.iq-close-hover div svg {
	font-size: 60px;
	margin-top: -20px;
	vertical-align: bottom;
	pointer-events: none;
}

.iq-close-hover:hover div {
	background-color: var(--main-color-error-80);
	cursor: pointer;
}

.iq-close-hover:hover div svg {
	color: var(--main-color-text-dark);
	/* color: var(--main-color-error); */
	/* stroke-width: 10px;
	stroke: var(--main-color-border-dark); */
}

.iq-wrap-large-image:hover .iq-close-hover {
	display: block;
}

.iq-image {
	box-shadow: 0px 0px 10px 10px var(--main-color-border-dark);
	max-width: 100%;
	max-height: 100%;
	margin: auto;
}

.iq-image:hover {
	cursor: pointer;
	box-shadow: 0px 0px 10px 10px var(--main-color-border-light);
}

.iq-gray-img {
	filter: grayscale(100%);
}

.iq-gray-img:hover {
	filter: grayscale(0%);
}
</style>
