<template>
	<div class="re-wrap-content">
		<div class="re-top-bar">
			<fai icon="fas fa-arrow-left" class="re-to-login" @click="$emit('goToLogin')"></fai>
			<h1>{{ $t('reRegistration') }}</h1>
			<fai icon="fas fa-times-circle" class="re-close-registration" @click="$emit('closeRegistration')"></fai>
		</div>
		<div class="re-wrap-left">
			<div class="re-wrap-input">
				<label>{{ $t('reUsername') }}</label>
				<input type="text" autocomplete="on" :placeholder="$t('reUsername')" v-model.trim="user.username" />
			</div>
			<div class="re-wrap-input">
				<label>{{ $t('reEmail') }}</label>
				<input type="text" autocomplete="on" :placeholder="$t('reEmail')" v-model.trim="user.email" />
			</div>
			<div class="re-wrap-input">
				<label>{{ $t('rePassword') }}</label>
				<input :type="[showPassword ? 'text' : 'password']" autocomplete="on" :placeholder="$t('rePassword')" v-model="user.password" />
				<fai
					:icon="['fas', showPassword ? 'fa-eye-slash' : 'fa-eye']"
					:class="[showPassword ? 're-smaller-eye' : '']"
					@mouseenter="showPassword = true"
					@mouseleave="showPassword = false"
				></fai>
			</div>
			<div class="re-wrap-input">
				<label>{{ $t('rePasswordControl') }}</label>
				<input
					:type="[showPasswordRepeat ? 'text' : 'password']"
					autocomplete="on"
					:placeholder="$t('rePasswordControl')"
					v-model="user.passwordControl"
				/>
				<fai
					:icon="['fas', showPasswordRepeat ? 'fa-eye-slash' : 'fa-eye']"
					:class="[showPasswordRepeat ? 're-smaller-eye' : '']"
					@mouseenter="showPasswordRepeat = true"
					@mouseleave="showPasswordRepeat = false"
				></fai>
			</div>
		</div>
		<div class="re-wrap-right">
			<ProfilePictureSelection @selectedImage="user.profilePicture = $event" />
		</div>
		<div class="re-wrap-bottom">
			<button class="app-default-btn" @click="showRequestMail = true">{{ $t('reRequestCode') }}</button>
			<button class="app-success-btn" @click="register">{{ $t('reRegister') }}</button>
		</div>
		<div v-if="showRequestMail" class="re-wrap-code-reset">
			<div class="re-code-reset">
				<h2>{{ $t('reRequestNewCode') }}</h2>
				<div class="re-wrap-input">
					<label>{{ $t('reEmail') }}</label>
					<input type="text" autocomplete="on" :placeholder="$t('reEmail')" v-model.trim="resetEmail" />
				</div>
				<button class="app-success-btn" @click="requestNewCode">{{ $t('reRequest') }}</button>
				<fai icon="fas fa-times-circle" @click="showRequestMail = false"></fai>
			</div>
		</div>
	</div>
</template>

<script>
import ProfilePictureSelection from './ProfilePictureSelection.vue';
export default {
	name: 'Registration',
	components: {
		ProfilePictureSelection,
	},
	data() {
		return {
			showPassword: false,
			showPasswordRepeat: false,
			showRequestMail: false,
			user: {
				username: '',
				email: '',
				password: '',
				passwordControl: '',
				profilePicture: null,
			},
			resetEmail: '',
		};
	},
	created() {
		this.$global.checkAccess(this.$router, false);
	},
	methods: {
		register() {
			this.user.email = this.user.email.toLowerCase();
			if (this.user.username.length < 2 || this.user.username.length > 25) this.$global.showToast('warn', this.$t('reUsernameLengthError'));
			else if (!this.$global.validEmail(this.user.email)) this.$global.showToast('warn', this.$t('reValidEmailError'));
			else if (this.user.password !== this.user.passwordControl) this.$global.showToast('warn', this.$t('reNoMatchingPasswords'));
			else if (this.user.password.length < 9) this.$global.showToast('warn', this.$t('rePasswordLengthError'));
			else {
				this.$global.postData(
					'access',
					'/registration',
					{ user: this.user },
					{ headers: { pageauthheader: this.$global.getAccessCode() } },
					(err, data) => {
						if (err) this.$global.showToast('error', this.$t(err.response ? err.response.data.msg : 'reRegistrationError'));
						else {
							this.$global.showToast('success', this.$t('reRegistrationSuccess'));
							this.$emit('closeRegistration');
						}
					}
				);
			}
		},
		requestNewCode() {
			this.$global.postData(
				'access',
				'/resetVerification',
				{ email: this.resetEmail },
				{ headers: { pageauthheader: this.$global.getAccessCode() } },
				(err, data) => {
					if (err) this.$global.showToast('error', this.$t(err.response ? err.response.data.msg : 'reMailError'));
					else {
						this.$global.showToast('success', this.$t('reNewCodeMailSend'));
						this.resetEmail = '';
						this.showRequestMail = false;
					}
				}
			);
		},
	},
};
</script>

<style scoped>
.re-wrap-content {
	height: 100%;
	padding: 20px;
	text-align: center;
	position: relative;
	margin: auto;
	overflow-y: auto;
	overflow-x: hidden;
}

.re-wrap-content h1 {
	max-width: 80vw;
	font-size: 40px;
	margin: 0px auto;
	padding: 0px 0px 20px 0px;
	text-shadow: 3px 3px var(--main-color-6);
	-webkit-text-stroke: 2px var(--main-color-text-dark);
}

.re-wrap-left {
	vertical-align: top;
	padding: 20px;
	box-sizing: border-box;
	display: inline-block;
}
.re-wrap-right {
	max-width: 500px;
	vertical-align: top;
	padding: 20px;
	box-sizing: border-box;
	display: inline-block;
}

.re-wrap-bottom {
	width: 100%;
}

.re-wrap-input {
	width: fit-content;
	height: fit-content;
	margin: auto;
	text-align: start;
	position: relative;
}

.re-wrap-input label {
	margin: 10px auto;
	font-size: 20px;
}

.re-wrap-input input {
	min-width: 400px;
	width: 100%;
	height: 40px;
	margin: 10px auto;
	padding: 5px;
	display: block;
	box-sizing: border-box;
	font-size: 17px;
}

.re-wrap-input svg {
	font-size: 25px;
	color: var(--main-color-text-dark);
	position: absolute;
	bottom: 8px;
	right: 10px;
}

.re-smaller-eye {
	right: 8px !important;
}

button {
	width: 300px;
	height: 40px;
	margin: 10px;
	font-size: 20px;
}

.re-wrap-code-reset {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0px;
	left: 0px;
	padding: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	background-color: var(--main-color-5);
	overflow: auto;
}

.re-wrap-code-reset h2 {
	font-size: 30px;
	margin: 10px auto 30px auto;
}

.re-top-bar svg,
.re-wrap-code-reset svg {
	font-size: 30px;
	position: absolute;
	top: 15px;
	right: 15px;
	-webkit-filter: drop-shadow(3px 3px 0px var(--main-color-text-dark));
	filter: drop-shadow(3px 3px 0px var(--main-color-text-dark));
	color: var(--main-color-error);
}

.re-top-bar .re-to-login {
	position: absolute;
	top: 15px;
	left: 15px;
	color: var(--main-color-text-light);
}

.re-top-bar .re-close-registration {
	position: absolute;
	top: 15px;
	right: 15px;
	color: var(--main-color-error);
}

.re-wrap-code-reset svg {
	position: absolute;
	top: 15px;
	right: 15px;
	color: var(--main-color-error);
}

.re-top-bar svg,
.re-wrap-code-reset svg:hover {
	cursor: pointer;
	color: var(--secondary-color-error);
}
</style>
