<template>
	<div class="ql-wrap-content">
		<h2 v-if="isCreation">{{ $t('qlQuestionList') }}</h2>
		<input type="text" :placeholder="$t('qlSearchQuestions')" v-model="searchTerm" @input="searchQuestions" />
		<div class="ql-wrap-list vld-parent" ref="loadingContainer" @dragover="allowDrop" @drop="drop">
			<Loading
				:active.sync="isLoading"
				:is-full-page="false"
				:loader="'spinner'"
				:width="100"
				:height="100"
				:color="$global.setColor('--main-color-6')"
				:background-color="$global.setColor('--main-color-1')"
				:opacity="0.8"
				:container="$refs.loadingContainer"
				:zIndex="9999"
			></Loading>
			<QuestionPreview
				v-for="question in searchedQuestions"
				:key="question.qID"
				:question="question"
				:show="currentShow"
				:isCreation="isCreation"
				@dragQuestion="dragQuestion"
				@cloneQuestion="$emit('cloneQuestion', $event)"
				@editQuestion="$emit('editQuestion', $event)"
				@deleteQuestion="$emit('deleteQuestion', $event)"
			/>
			<p v-if="searchedQuestions.length == 0 && isCreation">{{ $t('qlNoQuestionsFound') }}</p>
			<p v-else-if="searchedQuestions.length == 0 && !isCreation">{{ $t('qlRemoveQuestionsDD') }}</p>
		</div>
		<div v-if="showHover" class="ql-leave-hover" @dragenter="dragLeave"></div>
		<div v-if="showHover" class="ql-remove-question ql-list-hover ql-drag-ignore" @dragover="allowDrop" @drop="drop">
			<div class="ql-drag-ignore">
				<fai icon="fas fa-times" class="ql-drag-ignore"></fai>
				<p class="ql-drag-ignore">{{ $t('qlRemoveQuestion') }}</p>
			</div>
		</div>
	</div>
</template>

<script>
import QuestionPreview from './QuestionPreview.vue';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
	name: 'QuestionList',
	components: {
		QuestionPreview,
		Loading,
	},
	props: {
		show: {
			type: String,
			required: true,
		},
		isCreation: {
			type: Boolean,
			required: true,
		},
		removeQuestion: {
			type: String,
			required: false,
		},
		excludedQuestions: {
			type: Array,
			required: false,
		},
	},
	watch: {
		show: {
			handler: function () {
				this.getQuestions();
			},
		},
		removeQuestion: {
			handler: function (newVal) {
				this.questions = this.questions.filter((quest) => quest.qID !== newVal);
				this.searchedQuestions = JSON.parse(JSON.stringify(this.questions));
			},
		},
	},
	emits: ['cloneQuestion', 'editQuestion', 'deleteQuestion'],
	data() {
		return {
			currentShow: '',
			isLoading: false,
			questions: [],
			searchedQuestions: [],
			searchTerm: '',
			showHover: false,
		};
	},
	created() {
		this.getQuestions();
	},
	mounted() {
		if (!this.isCreation) {
			document.querySelector('.ql-wrap-list').style.minHeight = '400px';
			document.querySelector('.ql-wrap-list').style.maxHeight = '400px';
		}
	},
	methods: {
		getQuestions() {
			let that = this;
			this.isLoading = true;
			this.questions = [];
			this.searchedQuestions = [];
			this.$global.getData(
				'quiz',
				'/getQuestionsOfShow',
				{
					headers: { pageauthheader: this.$global.getAccessCode() },
					auth: this.$global.getCredentials(),
					params: { uID: this.$global.getUser().uID, show: this.show },
				},
				null,
				function (err, result) {
					if (err) that.$global.showToast('error', that.$t(err.response ? err.response.data.msg : 'qlQueryError'));
					else {
						if (that.excludedQuestions) {
							result = result.filter((res) => !that.excludedQuestions.includes(res.qID));
						}
						that.currentShow = that.show;
						that.questions = result;
						that.searchedQuestions = result;
					}
					that.isLoading = false;
				}
			);
		},
		searchQuestions() {
			this.searchedQuestions = [];
			this.questions.forEach((question) => {
				// prettier-ignore
				let searchString = `${question.question ? question.question : ''} ${question.answer ? question.answer : ''} ${question.category ? question.category : ''} ${this.resolveType(question.type)}`;
				if (searchString.includes(this.searchTerm)) this.searchedQuestions.push(question);
			});
		},
		resolveType(type) {
			if (type == 'Freetext') return this.$t('qpFreetext');
			else if (type == 'Buzzer') return this.$t('qpBuzzer');
			else if (type == 'SingleChoice') return this.$t('qpSingleChoice');
			else if (type == 'BuzzerSingleChoice') return this.$t('qcBuzzerSingleChoice');
			else return '';
		},
		dragQuestion(e, question) {
			e.dataTransfer.setData('question', JSON.stringify(question));
			e.dataTransfer.setData('from', 'available');
			sessionStorage.setItem('from', 'available');
		},
		allowDrop(e) {
			e.preventDefault();
			let from = sessionStorage.getItem('from');
			if (from == 'available') this.showHover = false;
			else this.showHover = true;
		},
		dragLeave(e) {
			if (!e.target.classList.contains(`gsc-drag-ignore`)) this.showHover = false;
			else this.showHover = true;
		},
		drop(e) {
			let from = e.dataTransfer.getData('from');
			try {
				let question = JSON.parse(e.dataTransfer.getData('question'));
				if (from != 'available') {
					this.questions.push(question);
					this.searchedQuestions = JSON.parse(JSON.stringify(this.questions));
					this.searchTerm = '';
					this.$emit('removeQuestion', question.qID);
				}
				this.showHover = false;
			} catch (error) {
				this.showHover = false;
			}
		},
	},
};
</script>

<style scoped>
.ql-wrap-content {
	width: 100%;
	height: fit-content;
	text-align: center;
	position: relative;
}

.ql-wrap-content input {
	width: 80%;
	max-width: 300px;
	height: 30px;
	padding: 5px !important;
	display: block;
	margin: 10px auto;
	font-size: 20px;
	text-align: center;
	/* box-sizing: border-box; */
}

h2 {
	max-width: 90vw;
	font-size: 60px;
	margin: 0px auto;
	padding: 10pxh 0px 20px 0px;
	text-shadow: 4px 4px var(--main-color-6);
	-webkit-text-stroke: 2px var(--main-color-text-dark);
	text-align: center;
}

.ql-wrap-list {
	width: 100%;
	min-height: 100%;
	max-height: 600px;
	margin-top: 20px;
	padding: 10px;
	vertical-align: top;
	display: flex;
	flex-flow: wrap;
	box-sizing: border-box;
	overflow: auto;
}

.ql-wrap-list p {
	margin: auto;
	text-align: center;
	font-size: 22px;
}

.ql-leave-hover {
	width: 200vw;
	height: 180vh;
	position: absolute;
	top: -100vh;
	left: -100vw;
	z-index: 1;
}

.ql-list-hover {
	width: calc(100% - 30px);
	height: 400px;
	position: absolute;
	bottom: 0px;
	left: 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 2;
}

.ql-remove-question {
	border: 5px solid #ff0000aa;
	border-radius: 10px;
	background-color: #ff000080;
}

.ql-remove-question svg {
	font-size: 70px;
	color: #ff0000;
	-webkit-filter: drop-shadow(3px 3px 0px var(--main-color-text-dark));
	filter: drop-shadow(3px 3px 0px var(--main-color-text-dark));
}

.ql-list-hover p {
	font-size: 50px;
	color: var(--main-color-text-light);
	padding: 5px;
}

.ql-list-hover * {
	user-select: none;
}
</style>
