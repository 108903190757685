import { render, staticRenderFns } from "./HistoryEntry.vue?vue&type=template&id=48daa3ee&scoped=true"
import script from "./HistoryEntry.vue?vue&type=script&lang=js"
export * from "./HistoryEntry.vue?vue&type=script&lang=js"
import style0 from "./HistoryEntry.vue?vue&type=style&index=0&id=48daa3ee&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48daa3ee",
  null
  
)

export default component.exports