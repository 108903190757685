<template>
	<div class="nf-wrap-content">
		<h1>{{ $t('nfPageNotFound') }}</h1>
		<h2>{{ $t('nfPageNotFoundText') }}</h2>
		<button class="app-default-btn" @click="backToPage">{{ $t('nfBackToPage') }}</button>
	</div>
</template>

<script>
export default {
	name: 'NotFound',
	created() {
		this.$global.checkAccess(this.$router, false);
	},
	methods: {
		backToPage() {
			this.$router.push({ name: 'Home' });
		},
	},
};
</script>

<style scoped>
.nf-wrap-content {
	max-width: 100vw;
	height: 100%;
	text-align: center;
	position: relative;
	margin: auto;
	overflow-y: auto;
	overflow-x: hidden;
}

.nf-wrap-content h1 {
	max-width: 90vw;
	font-size: 80px;
	margin: 0px auto;
	padding: 5vh 0px 20px 0px;
	text-shadow: 5px 5px var(--main-color-6);
	-webkit-text-stroke: 2px var(--main-color-text-dark);
}

.nf-wrap-content h2 {
	max-width: 90vw;
	font-size: 35px;
	margin: 0px auto 40px auto;
}

button {
	font-size: 30px;
	width: 400px;
	height: 50px;
}

@media screen and (max-width: 800px) {
	.nf-wrap-content {
		max-width: 100vw;
	}

	.nf-wrap-content h1 {
		max-width: 100vw;
		font-size: 60px;
	}

	.nf-wrap-content h2 {
		font-size: 20px;
		max-width: 90%;
	}

	button {
		font-size: 20px;
		width: 300px;
		height: 40px;
	}
}
</style>
