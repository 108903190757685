<template>
	<div id="timer-wrapper" class="ti-wrap-content">
		<p>{{ Math.round(timeLeftDisplay) }}s</p>
		<div id="timer-background" class="ti-timer-background" :style="{ width: `${(timeLeft / time) * 100}%` }" :class="[timerBackgroundClass]">
			<div id="timer-drag-bar" :class="[state != 'STARTED' && $global.isHost() ? 'ti-timer-drag-bar' : '']" @mousedown="startDrag"></div>
			<div id="timer-ghost-bar" class="ti-timer-ghost-bar"></div>
		</div>
	</div>
</template>

<script>
/**
 * @group Timer
 * Timer component
 */
export default {
	name: 'Timer',
	props: {
		state: {
			type: String,
			required: true,
		},
		time: {
			type: Number,
			required: true,
		},
		timerUpdate: {
			type: Object,
			required: false,
		},
	},
	watch: {
		state: {
			handler: function (newVal) {
				this.handleNewState(newVal);
			},
			deep: true,
		},
		timerUpdate: {
			handler: function (newVal) {
				if (newVal.newState) this.handleNewState(newVal.newState);
				if (newVal.newTime) {
					this.timeLeft = newVal.newTime;
					this.timeLeftDisplay = newVal.newTime;
					this.$emit('currentTime', this.timeLeft);
				}
			},
			deep: true,
		},
	},
	data() {
		return {
			timeLeft: this.time,
			timeLeftDisplay: this.time,
			timerInterval: null,
			initialWidth: null,
			isDragging: false,
			initialDrag: null,
		};
	},
	computed: {
		timerBackgroundClass: function () {
			let percentageLeft = (this.timeLeft / this.time) * 100;
			if (percentageLeft >= 33) return 'ti-background-green';
			else if (percentageLeft < 33 && percentageLeft >= 10) return 'ti-background-orange';
			else return 'ti-background-red';
		},
	},
	mounted() {
		window.addEventListener('resize', this.resizeListener);
		this.$nextTick(() => {
			window.dispatchEvent(new Event('resize'));
		});
	},
	beforeDestroy() {
		if (this.timerInterval) clearInterval(this.timerInterval);
		this.timerInterval = null;
		window.removeEventListener('resize', this.resizeListener);
	},
	methods: {
		resizeListener(e) {
			this.initialWidth = document.getElementById('timer-wrapper').offsetWidth;
		},
		handleNewState(newState) {
			if (newState == 'STARTED' && !this.timerInterval) {
				this.timerInterval = window.setInterval(() => {
					this.timeLeft = this.timeLeft - 0.01;
					this.timeLeftDisplay = this.timeLeft;
					this.$emit('currentTime', this.timeLeft);
					if (this.timeLeft <= 0) {
						if (this.timerInterval) clearInterval(this.timerInterval);
						this.timerInterval = null;
						this.$emit('timerFinished');
					}
				}, 10);
			} else if (newState == 'STOPED') {
				if (this.timerInterval) clearInterval(this.timerInterval);
				this.timerInterval = null;
			} else if (newState == 'INITIAL') {
				if (this.timerInterval) clearInterval(this.timerInterval);
				this.timerInterval = null;
				this.timeLeft = this.time;
				this.timeLeftDisplay = this.time;
			}
		},
		startDrag(e) {
			e.preventDefault();
			let ghostBar = document.getElementById('timer-ghost-bar');

			this.isDragging = true;
			this.initialDrag = e.pageX;
			ghostBar.style.display = `block`;

			document.addEventListener('mousemove', this.mouseMoveListener);
			document.addEventListener('mouseup', this.stopDragListener);
		},
		mouseMoveListener(e) {
			let background = document.getElementById('timer-background');
			let ghostBar = document.getElementById('timer-ghost-bar');

			let computedOffset = this.initialDrag - e.pageX;
			let newWidth = background.offsetWidth - computedOffset;
			if (newWidth < 1) computedOffset = background.offsetWidth - 1;
			else if (newWidth > this.initialWidth) computedOffset = background.offsetWidth - this.initialWidth;

			this.timeLeftDisplay = this.computeNewTimeLeft(background, ghostBar);
			ghostBar.style.right = `${computedOffset}px`;
		},
		stopDragListener(e) {
			e.preventDefault();
			if (this.isDragging) {
				this.isDragging = false;
				let ghostBar = document.getElementById('timer-ghost-bar');
				let background = document.getElementById('timer-background');

				this.timeLeft = this.computeNewTimeLeft(background, ghostBar);
				this.timeLeftDisplay = this.timeLeft;

				sessionStorage.setItem('timeLeft', this.timeLeft);
				this.$emit('currentTime', this.timeLeft);

				ghostBar.style.display = `none`;
				ghostBar.style.right = `0px`;

				document.removeEventListener('mousemove', this.mouseMoveListener);
				document.removeEventListener('mouseup', this.stopDragListener);
			}
		},
		computeNewTimeLeft(background, ghostBar) {
			let initialWidth = background.offsetWidth;
			let newWidth = initialWidth - Number(ghostBar.style.right.replace('px', ''));
			let newTime = this.timeLeft * (newWidth / initialWidth);

			if (!isNaN(newTime)) return newTime;
			else if (newWidth <= 0) return 0;
			else if (newWidth >= this.initialWidth) return this.time;
		},
	},
};
</script>

<style scoped>
.ti-wrap-content {
	width: 100%;
	height: 100%;
	min-height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	overflow: hidden;
	border: 2px solid var(--main-color-border-dark);
	border-radius: 20px;
	background-color: var(--main-color-1);
}

.ti-wrap-content p {
	pointer-events: none;
}

.ti-timer-background {
	height: 100%;
	position: absolute;
	top: 0px;
	left: 0px;
	z-index: 1;
	border-radius: 20px;
	background-color: var(--main-color-success);
}
.ti-timer-drag-bar {
	width: 3px;
	height: 100%;
	position: absolute;
	top: 0px;
	right: 0px;
	z-index: 999;
}

.ti-timer-drag-bar:hover {
	background-color: var(--main-color-border-light);
	cursor: col-resize;
}

.ti-timer-ghost-bar {
	width: 3px;
	height: 100%;
	position: absolute;
	top: 0px;
	right: 0px;
	display: none;
	opacity: 0.5;
	z-index: 999;
	cursor: col-resize;
	background-color: var(--main-color-border-dark);
}

.ti-background-green {
	background-color: var(--main-color-success);
	-webkit-transition: background-color 0.5s linear;
	-ms-transition: background-color 0.5s linear;
	transition: background-color 0.5s linear;
}

.ti-background-orange {
	background-color: var(--main-color-warn);
	-webkit-transition: background-color 0.5s linear;
	-ms-transition: background-color 0.5s linear;
	transition: background-color 0.5s linear;
}

.ti-background-red {
	background-color: var(--main-color-error);
	-webkit-transition: background-color 0.5s linear;
	-ms-transition: background-color 0.5s linear;
	transition: background-color 0.5s linear;
	animation: backgroundBlink 0.5s infinite;
}

.ti-wrap-content p {
	z-index: 2;
	font-weight: bold;
	color: var(--main-color-text-dark);
}

@keyframes backgroundBlink {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0.5;
	}
	100% {
		opacity: 1;
	}
}
</style>
