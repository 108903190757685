<template>
	<div class="ts-wrap-content">
		<div class="ts-wrap-logo">
			<img :src="require(`@/assets/images/logos/${config.show.toUpperCase()}Logo.png`)" :alt="$t('tsLogo')" class="ts-animated-logo" />
		</div>
	</div>
</template>

<script>
/**
 * @group Component
 * Transition screen
 */
export default {
	name: 'TransitionScreen',
	props: {
		config: {
			type: Object,
			required: true,
		},
	},
	created() {
		let elem = document.getElementsByClassName('gsp-wrap-content')[0];
		elem.scrollTo(0, 0);
		elem.style.overflow = 'hidden';
	},
	beforeDestroy() {
		let elem = document.getElementsByClassName('gsp-wrap-content')[0];
		elem.style.overflow = 'auto';
	},
};
</script>

<style scoped>
.ts-wrap-content {
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 0px;
	left: 0px;
	z-index: 20;
	background-color: var(--main-color-dark-transparent-80);
}

.ts-wrap-logo {
	margin: auto;
}

.ts-animated-logo {
	max-width: 90vw;
	max-height: 90vh;
	border-radius: 150px;
	animation: bounceIn 2s ease-in-out 1;
}

@keyframes bounceIn {
	0% {
		opacity: 0;
		transform: scale(0.1);
	}
	50% {
		opacity: 1;
		transform: scale(1.05);
	}
	70% {
		transform: scale(0.9);
	}
	100% {
		transform: scale(1);
	}
}
</style>
