<template>
	<div class="gsc-wrap-content">
		<!-- Topic -->
		<div v-if="['xfz'].includes(show)" class="gsc-wrap-input">
			<label>{{ $t('gscTopic') }}</label>
			<input type="text" :placeholder="$t('gscTopic')" v-model="topic" />
		</div>
		<!-- Money -->
		<div v-if="['wlp'].includes(show)" class="gsc-wrap-input">
			<label>{{ $t('gscMoney') }}</label>
			<input type="number" min="0" :placeholder="$t('gscMoney')" @input="checkInput($event, 'money')" v-model.number="money" />
		</div>
		<!-- Lives -->
		<div v-if="['oh', 'wbm'].includes(show)" class="gsc-wrap-input">
			<label>{{ $t('gscLives') }}</label>
			<input type="number" min="0" :placeholder="$t('gscLives')" @input="checkInput($event, 'lives')" v-model.number="lives" />
		</div>
		<!-- Points for correct answer -->
		<div v-if="['oh', 'dpin', 'smf', 'sq', 'xfz', 'lz', 'zgw'].includes(show)" class="gsc-wrap-input">
			<label>{{ $t('gscPointsPos') }}</label>
			<input type="number" min="0" :placeholder="$t('gscPointsPos')" @input="checkInput($event, 'pointsPos')" v-model.number="pointsPos" />
		</div>
		<!-- Points for wrong answer -->
		<div v-if="['smf', 'xfz', 'lz'].includes(show)" class="gsc-wrap-input">
			<label>{{ $t('gscPointsNeg') }}</label>
			<input type="number" min="0" :placeholder="$t('gscPointsNeg')" @input="checkInput($event, 'pointsNeg')" v-model.number="pointsNeg" />
		</div>
		<!-- Points per level -->
		<div v-if="['kq'].includes(show)" class="gsc-wrap-levels">
			<div class="gsc-wrap-left">
				<div class="gsc-wrap-input">
					<label>{{ $t('gscPoints') }}</label>
					<input type="number" min="0" :placeholder="$t('gscPoints')" @input="checkInput($event, newPoints)" v-model.number="newPoints" />
				</div>
				<button class="app-default-btn" @click="addPointsPerLevel">{{ $t('gscAddPointsPerLevel') }}</button>
			</div>
			<div class="gsc-wrap-right">
				<p v-if="pointsPerLevel.length !== 0">{{ $t('gscEasyQuestion') }}</p>
				<div class="gsc-wrap-ppl">
					<div v-for="element in pointsPerLevel" :key="element.level" class="gsc-list-element">
						<p>
							<strong>{{ $t('gscLevel') }} {{ element.level + 1 }}: </strong>{{ element.points }} {{ $t('gscPoints') }}
						</p>
						<div class="gsc-wrap-controls">
							<fai icon="fas fa-pen" class="gsc-edit" @click="editPointsPerLevel(element)"></fai>
							<fai
								icon="fas fa-angle-up"
								class="gsc-up"
								:class="[element.level == 0 ? 'gsc-disable' : '']"
								:disabled="element.level == 0"
								@click="movePointsPerLevelUp(element)"
							></fai>
							<fai
								icon="fas fa-angle-down"
								class="gsc-down"
								:class="[element.level == pointsPerLevel.length - 1 ? 'gsc-disable' : '']"
								:disabled="element.level == pointsPerLevel.length - 1"
								@click="movePointsPerLevelDown(element)"
							></fai>
							<fai icon="fas fa-times" class="gsc-close" @click="removePointsPerLevel(element)"></fai>
						</div>
					</div>
					<p v-if="pointsPerLevel.length == 0">{{ $t('gscNoElements') }}</p>
				</div>
				<p v-if="pointsPerLevel.length !== 0">{{ $t('gscHardQuestion') }}</p>
			</div>
		</div>
		<!-- Points for the win -->
		<div v-if="['wlp'].includes(show)" class="gsc-wrap-input">
			<label>{{ $t('gscPointsWin') }}</label>
			<input type="number" min="0" :placeholder="$t('gscPointsWin')" @input="checkInput($event, 'pointsWin')" v-model.number="pointsWin" />
		</div>
		<!-- Timer -->
		<div v-if="['wbm', 'zgw', 'sq'].includes(show)" class="gsc-wrap-input">
			<label>{{ $t('gscTimer') }}</label>
			<input type="number" min="0" :placeholder="$t('gscTimer')" @input="checkInput($event, 'timer')" v-model.number="timer" />
		</div>
		<!-- Amount of joker -->
		<div v-if="['kq'].includes(show)" class="gsc-wrap-input">
			<label>{{ $t('gscJoker') }}</label>
			<input type="number" min="0" :placeholder="$t('gscJoker')" @input="checkInput($event, 'amountJoker')" v-model.number="amountJoker" />
		</div>
		<!-- Multiple answers per category are allowed -->
		<div v-if="['kq'].includes(show)" class="gsc-wrap-input">
			<label>{{ $t('gscMultipleAnswersPerCat') }}</label>
			<input type="checkbox" :placeholder="$t('gscMultipleAnswersPerCat')" v-model="multipleAnswersPerCat" />
		</div>
		<br />
		<div v-if="show" class="gsc-wrap-bottom">
			<div class="gsc-wrap-question-top-bar">
				<h2>{{ $t('gscQuestionBrowser') }}</h2>
				<div class="gsc-wrap-question-filter">
					<FilterBar :type="'question'" :isLoading="isLoading" :amountElements="questionAmount" @refresh="refreshBrowser" />
				</div>
			</div>
			<div class="gsc-blur-ref">
				<div v-show="!firstItemVisible" class="gsc-blur-before"></div>
				<div class="gsc-wrap-questions">
					<QuestionPreview
						v-for="(question, idx) in questions"
						:id="`questions-${idx}`"
						:key="idx"
						:question="question"
						:show="show"
						:isCreation="false"
						:isSelected="isQuestionSelected(question)"
						:class="{ 'gsc-first-child': idx == 0, 'gsc-last-child': idx == questions.length - 1 }"
						@selectQuestion="questionClicked"
					/>
					<div v-if="questions.length == 0 && !isLoading" class="gsc-wrap-no-questions">
						<div class="gsc-wrap-refresh">
							<fai icon="fas fa-sad-tear" />
							<p class="gsc-no-questions">{{ $t('hoNoQuestionsFound') }}</p>
						</div>
					</div>
				</div>
				<div v-show="!lastItemVisible" class="gsc-blur-after"></div>
			</div>
		</div>
		<div class="gsc-wrap-selected-questions">
			<div class="gsc-wrap-selected-question-top-bar">
				<h2>{{ $t('gscSelectedQuestions') }}</h2>
			</div>

			<div class="gsc-selected-questions">
				<QuestionPreview
					v-for="(question, idx) in selectedQuestions"
					:key="idx"
					:id="`selected-questions-${idx}`"
					:question="question"
					:show="show"
					:isCreation="false"
					:isSelection="true"
					:class="{ 'gsc-first-child': idx == 0, 'gsc-last-child': idx == selectedQuestions.length - 1 }"
					@moveQuestionUp="moveQuestionUp"
					@moveQuestionDown="moveQuestionDown"
					@removeQuestion="questionClicked"
				/>
				<div v-if="selectedQuestions.length == 0" class="gsc-no-questions">
					<fai icon="fas fa-question-circle"></fai>
					<p>{{ $t('gscNoQuestionsSelected') }}</p>
				</div>
			</div>
		</div>
		<div class="gsc-wrap-cards">
			<div class="ap-card gsc-add-show" @click="addConfig">
				<div>
					<fai icon="fas fa-plus-circle" />
					<p>{{ $t('gscAddConfig') }}</p>
				</div>
			</div>
			<div v-if="editedConfig" class="ap-card gsc-revert-changes" @click="$emit('revertChanges')">
				<div>
					<fai icon="fas fa-redo" />
					<p>{{ $t('gscRevertChanges') }}</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import FilterBar from '../lobby/filter/FilterBar.vue';
import QuestionList from '../questionCreation/QuestionList.vue';
import QuestionPreview from '../questionCreation/QuestionPreview.vue';
export default {
	name: 'GameShowConfig',
	components: {
		FilterBar,
		QuestionList,
		QuestionPreview,
	},
	props: {
		show: {
			type: String,
			required: true,
		},
		editedConfig: {
			type: Object,
			required: false,
		},
	},
	watch: {
		editedConfig: {
			handler: function (newVal) {
				if (newVal) {
					let conf = JSON.parse(JSON.stringify(newVal));
					this.selectedQuestions = conf.questions;
					this.excludedQuestions = [...conf.questions.map((quest) => quest.qID)];

					if (['xfz'].includes(this.show)) this.topic = conf.topic;
					if (['wlp'].includes(this.show)) {
						this.money = conf.money;
						this.pointsWin = conf.pointsWin;
					}
					if (['oh', 'wbm'].includes(this.show)) this.lives = conf.lives;
					if (['oh', 'dpin', 'smf', 'sq', 'xfz', 'lz', 'zgw'].includes(this.show)) this.pointsPos = conf.pointsPos;
					if (['smf', 'xfz', 'lz'].includes(this.show)) this.pointsNeg = conf.pointsNeg;
					if (['kq'].includes(this.show)) {
						this.pointsPerLevel = conf.pointsPerLevel;
						this.amountJoker = conf.amountJoker;
						this.multipleAnswersPerCat = conf.multipleAnswersPerCat;
					}
					if (['wbm', 'zgw', 'sq'].includes(this.show)) this.timer = conf.timer;

					this.currentShow = this.show;
					this.refreshToggle = !this.refreshToggle;
				}
			},
			deep: true,
		},
	},
	data() {
		return {
			isLoading: false,
			refreshToggle: false,
			refreshClicked: false,
			questionFilter: null,
			questions: [],
			questionAmount: 0,
			selectedQuestions: [],
			firstItemVisible: true,
			lastItemVisible: true,
			currentShow: '',
			topic: '',
			money: 0,
			lives: 0,
			pointsPos: 0,
			pointsNeg: 0,
			newPoints: 0,
			pointsPerLevel: [],
			pointsWin: 0,
			timer: 0,
			amountJoker: 0,
			multipleAnswersPerCat: false,
		};
	},
	created() {
		if (!this.editedConfig) this.currentShow = this.show;
	},
	methods: {
		queryQuestions() {
			let that = this;
			this.isLoading = true;
			this.$global.getData(
				'quiz',
				'/getQuestionsOfShow',
				{
					headers: { pageauthheader: this.$global.getAccessCode() },
					auth: this.$global.getCredentials(),
					params: {
						uID: this.$global.getUser().uID,
						show: this.show,
						questionFilter: this.questionFilter != null ? JSON.stringify(this.questionFilter) : null,
					},
				},
				null,
				function (err, result) {
					if (err) that.$global.showToast('error', that.$t(err.response ? err.response.data.msg : 'qlQueryError'));
					else {
						that.questions = result.questions;
						that.questionAmount = result.questionAmount;
					}
					that.isLoading = false;
					that.refreshClicked = false;
				}
			);
		},
		refreshBrowser(questionFilter) {
			if (!this.refreshClicked) {
				this.refreshClicked = true;
				this.questionFilter = questionFilter;
				this.questions = [];
				this.queryQuestions();
			}
		},
		checkInput(e, attr) {
			if (Number(e.target.value) < 0) this[attr] = 0;
		},
		addPointsPerLevel() {
			this.pointsPerLevel.push({ level: this.pointsPerLevel.length, points: this.newPoints });
			this.newPoints = 0;
		},
		editPointsPerLevel(element) {
			this.newPoints = element.points;
			this.pointsPerLevel = this.pointsPerLevel.filter((ppl) => ppl.level !== element.level);
			this.renumberPointsPerLevel();
		},
		movePointsPerLevelUp(element) {
			if (element.level !== 0) {
				this.pointsPerLevel = this.$global.arrayMove(this.pointsPerLevel, element.level, element.level - 1);
				this.renumberPointsPerLevel();
			}
		},
		movePointsPerLevelDown(element) {
			if (element.level !== this.pointsPerLevel.length - 1) {
				this.pointsPerLevel = this.$global.arrayMove(this.pointsPerLevel, element.level, element.level + 1);
				this.renumberPointsPerLevel();
			}
		},
		removePointsPerLevel(element) {
			this.pointsPerLevel = this.pointsPerLevel.filter((ppl) => ppl.level !== element.level);
			this.renumberPointsPerLevel();
		},
		renumberPointsPerLevel() {
			this.pointsPerLevel.map((ppl, idx) => {
				ppl.level = idx;
			});
		},
		resolveType(type) {
			if (type == 'Freetext') return this.$t('gscFreetext');
			else if (type == 'Buzzer') return this.$t('gscBuzzer');
			else if (type == 'SingleChoice') return this.$t('gscSingleChoice');
			else if (type == 'BuzzerSingleChoice') return this.$t('gscBuzzerSingleChoice');
			else return '';
		},
		isQuestionSelected(question) {
			return this.selectedQuestions.map((it) => it.qID).includes(question.qID);
		},
		questionClicked(question) {
			let questionAlreadySelected = this.isQuestionSelected(question);
			if (questionAlreadySelected) this.selectedQuestions = this.selectedQuestions.filter((it) => it.qID != question.qID);
			else {
				if (this.show == 'kq') {
					if (this.selectedQuestions.length >= 6) this.$global.showToast('warn', this.$t('gscMaxNumberCategoriesReached'));
					else {
						let elements = [...this.selectedQuestions, question];
						const result = elements.every((element) => {
							if (element.questions.length === elements[0].questions.length) return true;
						});

						if (result) this.selectedQuestions.push({ ...question, position: this.selectedQuestions.length });
						else this.$global.showToast('warn', this.$t('gscIncompatibleQuestions'));
					}
				} else this.selectedQuestions.push({ ...question, position: this.selectedQuestions.length });
			}
			this.renumberPositions();
		},
		moveQuestionUp(question) {
			if (this.selectedQuestions.length > 1) {
				let index = this.selectedQuestions.findIndex((quest) => quest.qID == question.qID);
				if (index !== 0) {
					this.selectedQuestions = this.$global.arrayMove(this.selectedQuestions, index, index - 1);
					this.renumberPositions();
				}
			}
		},
		moveQuestionDown(question) {
			if (this.selectedQuestions.length > 1) {
				let index = this.selectedQuestions.findIndex((quest) => quest.qID == question.qID);
				if (index !== this.selectedQuestions.length - 1) {
					this.selectedQuestions = this.$global.arrayMove(this.selectedQuestions, index, index + 1);
					this.renumberPositions();
				}
			}
		},
		addConfig() {
			try {
				if (['xfz'].includes(this.show) && this.topic.length < 1) throw 'gscNoTopic';
				if (['wlp'].includes(this.show) && this.money <= 0) throw 'gscNoMoney';
				if (['oh', 'wbm'].includes(this.show) && this.lives <= 0) throw 'gscNoLives';
				if (['oh', 'dpin', 'smf', 'sq', 'xfz', 'lz', 'zgw'].includes(this.show) && this.pointsPos <= 0) throw 'gscNoPointsPos';
				if (['smf', 'xfz', 'lz'].includes(this.show) && this.pointsNeg < 0) throw 'gscNoPointsNeg';
				if (['kq'].includes(this.show)) {
					if (this.pointsPerLevel.length < 3) throw 'gscKQLevelMin';
					if (this.pointsPerLevel.length > 6) throw 'gscKQLevelMax';
					if (this.selectedQuestions.length < 3) throw 'gscKQQuestionsMin';
					if (this.selectedQuestions.length > 6) throw 'gscKQQuestionsMax';
					if (this.selectedQuestions[0].questions.length !== this.pointsPerLevel.length) throw 'gscKQIncompatiblePointsPerLevel';
					if (this.amountJoker < 0) throw 'gscNoJoker';
				}
				if (['wlp'].includes(this.show) && this.pointsWin <= 0) throw 'gscNoPointsWin';
				if (['wbm', 'zgw', 'sq'].includes(this.show) && this.timer <= 0) throw 'gscNoTimer';
				if (['zgw'].includes(this.show) && this.selectedQuestions.length < 2) throw 'gscZGWQuestionsMin';
				if (['zgw'].includes(this.show) && this.selectedQuestions.length > 6) throw 'gscZGWQuestionsMax';
				if (this.selectedQuestions.length <= 0) throw 'gscNoQuestions';

				let configElement = {
					questions: this.selectedQuestions,
					show: this.show,
				};

				if (['xfz'].includes(this.show)) configElement.topic = this.topic;
				if (['wlp'].includes(this.show)) {
					configElement.money = this.money;
					configElement.pointsWin = this.pointsWin;
				}
				if (['oh', 'wbm'].includes(this.show)) configElement.lives = this.lives;
				if (['oh', 'dpin', 'smf', 'sq', 'xfz', 'lz', 'zgw'].includes(this.show)) configElement.pointsPos = this.pointsPos;
				if (['smf', 'xfz', 'lz'].includes(this.show)) configElement.pointsNeg = this.pointsNeg;
				if (['kq'].includes(this.show)) {
					configElement.pointsPerLevel = this.pointsPerLevel;
					configElement.amountJoker = this.amountJoker;
					configElement.multipleAnswersPerCat = this.multipleAnswersPerCat;
				}
				if (['wbm', 'zgw', 'sq'].includes(this.show)) configElement.timer = this.timer;

				configElement = this.correctQuestionPositions(configElement);

				this.$emit('addConfigElement', configElement);
			} catch (error) {
				this.$global.showToast('warn', this.$t(error));
			}
		},
		renumberPositions() {
			for (let i = 0; i < this.selectedQuestions.length; i++) {
				this.selectedQuestions[i].position = i;
			}
		},
		correctQuestionPositions(configElement) {
			configElement.questions = configElement.questions.sort((a, b) => {
				return a.position - b.position;
			});
			for (let i = 0; i < configElement.questions.length; i++) {
				let elementIdx = configElement.questions.findIndex((quest) => quest.qID == configElement.questions[i].qID);
				configElement.questions[elementIdx].position = i;
			}
			return configElement;
		},
	},
};
</script>

<style scoped>
.gsc-wrap-content {
	width: 100%;
	height: fit-content;
	overflow: hidden;
}

.gsc-wrap-input {
	width: 50%;
	height: fit-content;
	text-align: start;
	margin: auto;
	position: relative;
	display: inline-block;
	vertical-align: top;
}

.gsc-wrap-input label {
	font-size: 20px;
	margin: 10px auto;
}

.gsc-wrap-input input[type='text'],
.gsc-wrap-input input[type='number'],
.gsc-wrap-input select {
	width: 90%;
	height: 30px;
	padding: 5px !important;
	display: block;
	margin: 10px 0px;
	font-size: 20px;
}

.gsc-center {
	display: block;
	margin: auto;
}

.gsc-wrap-input select {
	box-sizing: content-box;
	padding: 5px !important;
}

.gsc-wrap-input input[type='checkbox'] {
	width: 30px;
	height: 30px;
	padding: 5px !important;
	display: block;
	margin: 10px 0px;
	font-size: 20px;
	cursor: pointer;
}

button {
	font-size: 20px;
	width: 300px;
	height: 40px;
	margin: 10px auto;
	display: block;
}

.gsc-wrap-left,
.gsc-wrap-right {
	width: 50%;
	display: inline-block;
	vertical-align: top;
}

.gsc-wrap-left .gsc-wrap-input {
	width: 100%;
}

.gsc-wrap-ppl {
	max-height: 250px;
	overflow: auto;
}

.gsc-wrap-right p {
	width: 100%;
	margin: 10px 0px;
	text-align: center;
}

.gsc-list-element {
	width: calc(100% - 20px);
	height: fit-content;
	min-height: 50px;
	margin: 10px;
	padding: 5px 10px;
	padding-right: 90px;
	box-sizing: border-box;
	display: block;
	background-color: var(--main-color-3);
	box-shadow: 2px 2px 4px 4px var(--main-color-border-dark);
	color: var(--main-color-text-light);
	font-size: 20px;
	position: relative;
}

.gsc-list-element p {
	width: fit-content;
	margin: 5px 0px;
	text-align: start;
}

.gsc-wrap-controls {
	position: absolute;
	right: 0px;
	top: 0px;
	width: 90px;
	height: 60px;
}

.gsc-wrap-controls svg {
	position: absolute;
	font-size: 30px;
	-webkit-filter: drop-shadow(3px 3px 0px var(--main-color-text-dark));
	filter: drop-shadow(3px 3px 0px var(--main-color-text-dark));
}

.gsc-wrap-controls svg:hover {
	cursor: pointer;
}

.gsc-close {
	top: 10px;
	right: 5px;
	color: var(--main-color-error);
}

.gsc-edit {
	top: 12px;
	right: 32px;
	color: var(--main-color-warn);
	font-size: 25px !important;
}

.gsc-up {
	top: 0px;
	right: 65px;
}

.gsc-down {
	top: 20px;
	right: 65px;
}

.gsc-close:hover {
	color: var(--secondary-color-error);
}

.gsc-up:hover,
.gsc-down:hover {
	color: var(--main-color-6);
}

.gsc-edit:hover {
	color: var(--secondary-color-warn);
}

.gsc-disable {
	color: var(--main-color-disabled) !important;
}

.gsc-disable:hover {
	cursor: default !important;
}

.gsc-wrap-available-questions,
.gsc-wrap-used-questions {
	width: 50%;
	display: inline-block;
	vertical-align: top;
}

.gsc-wrap-used-questions {
	height: fit-content;
	text-align: center;
	position: relative;
}

.gsc-wrap-used-questions input {
	width: 80%;
	max-width: 300px;
	height: 30px;
	padding: 5px !important;
	display: block;
	margin: 10px auto;
	font-size: 20px;
	text-align: center;
}

.gsc-wrap-list {
	width: 100%;
	min-height: 400px;
	max-height: 400px;
	margin-top: 20px;
	padding: 10px;
	vertical-align: top;
	display: flex;
	flex-flow: wrap;
	box-sizing: border-box;
	overflow: auto;
}

.gsc-wrap-list p {
	margin: auto;
	text-align: center;
	font-size: 22px;
}

.gsc-leave-hover {
	width: 200vw;
	height: 180vh;
	position: absolute;
	top: -100vh;
	left: -100vw;
	z-index: 1;
}

.gsc-list-hover {
	width: calc(100% - 30px);
	height: 400px;
	position: absolute;
	bottom: 0px;
	left: 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 2;
}

.gsc-add-question {
	border: 5px solid #00ff00aa;
	border-radius: 10px;
	background-color: #00ff0080;
}

.gsc-add-question svg {
	font-size: 70px;
	color: #00ff00;
	-webkit-filter: drop-shadow(3px 3px 0px var(--main-color-text-dark));
	filter: drop-shadow(3px 3px 0px var(--main-color-text-dark));
}

.gsc-list-hover p {
	font-size: 50px;
	color: var(--main-color-text-light);
	padding: 5px;
}

.gsc-list-hover * {
	user-select: none;
}

.gsc-wrap-bottom {
	width: 100%;
	height: 80vh;
	min-height: 300px;
	flex: 1 1 100%;
	margin: 20px 0px;
	padding: 10px;
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	border: 2px solid var(--main-color-border-dark);
	background-color: var(--main-color-3-cc);
}

.gsc-wrap-question-top-bar {
	width: 100%;
	height: 160px;
	text-align: center;
}

.gsc-wrap-question-top-bar h2 {
	height: 40px;
	margin: 0px auto;
	padding: 0px 0px 10px 0px;
	font-size: 35px;
	text-shadow: 2px 2px var(--main-color-6);
	-webkit-text-stroke: 2px var(--main-color-text-dark);
}

.gsc-wrap-question-top-bar h2 svg {
	margin: 5px;
	font-size: 45px;
	color: var(--main-color-text-light);
	filter: drop-shadow(2px 2px 2px var(--main-color-border-dark));
}

.gsc-wrap-question-filter {
	width: 100%;
	height: 100px;
	margin-right: 15px;
	box-sizing: border-box;
	padding: 0px 5px 0px 0px;
	overflow-y: auto;
	overflow-x: hidden;
	border-bottom: 1px solid var(--main-color-border-light);
}

.gsc-blur-ref {
	width: 100%;
	height: calc(100% - 150px);
	position: relative;
}

.gsc-wrap-questions {
	width: 100%;
	height: 100%;
	padding: 00px 10px;
	box-sizing: border-box;
	overflow: auto;
}

.gsc-blur-before,
.gsc-blur-after {
	position: absolute;
	left: 8px;
	width: calc(100% - 30px);
	margin: auto;
	height: 60px;
	backdrop-filter: blur(1px);
	z-index: 10;
	pointer-events: none;
}

.gsc-blur-before {
	top: 0px;
	background: linear-gradient(to bottom, #00000033, #00000000);
}

.gsc-blur-after {
	bottom: 0;
	background: linear-gradient(to top, #00000033, #00000000);
}

.gsc-wrap-no-questions {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.gsc-wrap-no-questions p {
	text-shadow: 3px 3px var(--main-color-border-dark);
}

.gsc-wrap-refresh {
	text-align: center;
	font-size: 30px;
}

.gsc-wrap-refresh svg {
	margin: 10px;
	font-size: 60px;
	filter: drop-shadow(2px 2px 2px var(--main-color-border-dark));
	color: var(--main-color-6);
}

.gsc-wrap-selected-questions {
	width: 100%;
	height: 550px;
	margin-bottom: 30px;
	padding: 10px;
	box-sizing: border-box;
	border: 2px solid var(--main-color-border-dark);
	background-color: var(--main-color-3-cc);
}

.gsc-wrap-selected-question-top-bar {
	width: 100%;
	height: 50px;
	text-align: center;
}

.gsc-wrap-selected-question-top-bar h2 {
	height: 40px;
	margin: 0px auto;
	padding: 0px 0px 10px 0px;
	font-size: 35px;
	text-shadow: 2px 2px var(--main-color-6);
	-webkit-text-stroke: 2px var(--main-color-text-dark);
}

.gsc-selected-questions {
	width: 100%;
	height: calc(100% - 50px);
	padding: 10px;
	box-sizing: border-box;
	overflow: auto;
}

.gsc-no-questions {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow: column;
}

.gsc-no-questions svg {
	margin-bottom: 20px;
	font-size: 100px;
	filter: drop-shadow(2px 2px 3px var(--main-color-border-dark));
	color: var(--main-color-6);
}

.gsc-no-questions p {
	font-size: 20px;
	color: var(--main-color-text-light);
}

.gsc-wrap-cards {
	width: 100%;
	padding: 1% 5%;
	box-sizing: border-box;
	display: flex;
	flex-flow: row;
	justify-content: center;
	align-items: center;
}

.gsc-wrap-cards div {
	margin: 0px 5px;
}

.gsc-add-show {
	background-color: var(--main-color-success-cc);
}

.gsc-revert-changes {
	background-color: var(--main-color-6-cc);
}
</style>

<style>
.gsc-first-child img,
.gsc-last-child img {
	z-index: 3 !important;
}

.gsc-first-child img:hover {
	transform: translateX(-100px) translateY(100px) scale(3) !important;
}
.gsc-last-child img:hover {
	transform: translateX(-100px) translateY(-100px) scale(3) !important;
}
</style>
