<template>
	<div class="bu-wrap-content">
		<div v-if="showBuzzer" :class="[clickDisabled ? 'bu-disabled' : 'bu-enabled', 'bu-buzzer']" @click="buzzerPressed">
			<fai icon="fas fa-hand-pointer" />
			{{ $t('buBuzzerText') }}
		</div>
		<div
			v-else-if="!showBuzzer && !buzzerAnimation && buzzerUser"
			:style="{
				'background-image': 'url(' + require(`@/assets/images/profilePictures/${buzzerUser.profilePic}`) + ')',
			}"
			class="bu-buzzer-img"
		>
			<p>{{ buzzerUser.name }}</p>
		</div>
		<div v-else-if="!showBuzzer && buzzerAnimation" class="bu-animation">
			<fai v-if="buzzerAnimation === 'unlock'" icon="fas fa-lock-open" class="bu-unlock"></fai>
			<fai v-if="buzzerAnimation === 'right'" icon="fas fa-check" class="bu-right"></fai>
			<fai v-if="buzzerAnimation === 'wrong'" icon="fas fa-times" class="bu-wrong"></fai>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Buzzer',
	props: {
		buzzerUser: {
			type: Object,
			required: false,
		},
		buzzerAnimation: {
			type: String,
			required: false,
		},
	},
	watch: {
		buzzerUser: {
			handler: function (newVal) {
				if (newVal) this.showBuzzer = false;
			},
		},
		buzzerAnimation: {
			handler: function (newVal) {
				if (newVal == 'unlock') {
					window.setTimeout(() => {
						this.showBuzzer = true;
						this.clickDisabled = false;
					}, 2000);
				} else if (newVal == 'right') {
				} else if (newVal == 'wrong') {
					window.setTimeout(() => {
						this.showBuzzer = true;
						this.clickDisabled = false;
					}, 2000);
				}
			},
		},
	},
	data() {
		return {
			clickDisabled: false,
			showBuzzer: true,
		};
	},
	created() {
		if (this.buzzerUser) this.showBuzzer = false;
	},
	mounted() {
		document.addEventListener('keydown', this.keyListener);
	},
	beforeDestroy() {
		document.removeEventListener('keydown', this.keyListener);
	},
	methods: {
		buzzerPressed() {
			if (!this.clickDisabled) {
				this.clickDisabled = true;
				this.$emit('buzzerPressed');
			}
		},
		keyListener(e) {
			if (!this.clickDisabled) {
				if (e.keyCode == 32) this.buzzerPressed();
			}
		},
	},
};
</script>

<style scoped>
.bu-wrap-content {
	width: 100%;
	height: fit-content;
	padding: 10px 0px;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: center;
}

.bu-buzzer {
	width: 200px;
	height: 200px;
	padding: 10px;
	box-sizing: border-box;
	border: 4px solid var(--main-color-border-light);
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow: column;
	color: var(--main-color-text-light);
	font-size: 30px;
}

.bu-buzzer svg {
	font-size: 50px;
	margin-bottom: 10px;
}

.bu-disabled {
	background-color: var(--main-color-disabled);
	color: var(--main-color-text-dark);
}

.bu-enabled {
	background-color: var(--main-color-3);
}

.bu-enabled:hover {
	background-color: var(--main-color-6);
	cursor: pointer;
	color: var(--main-color-text-light);
}

.bu-buzzer-img {
	width: 200px;
	height: 200px;
	box-sizing: border-box;
	border: 4px solid var(--main-color-border-light);
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-size: 200px 200px;
	overflow: hidden;
}

.bu-buzzer-img p {
	width: 200px;
	height: 192px;
	display: flex;
	font-weight: bold;
	justify-content: center;
	align-items: center;
	color: var(--main-color-1);
	background-color: var(--main-color-dark-transparent-80);
	text-shadow: 1px 1px 2px black;
	border-radius: 50%;
}

.bu-animation {
	width: 200px;
	height: 200px;
	box-sizing: border-box;
	border: 4px solid var(--main-color-border-light);
	border-radius: 50%;
	background-color: var(--main-color-light-transparent-40);
	display: flex;
	justify-content: center;
	align-items: center;
	background-size: 200px 200px;
	overflow: hidden;
}

.bu-animation svg {
	font-size: 100px;
	filter: drop-shadow(2px 2px 4px var(--main-color-border-dark));
}

.bu-unlock {
	color: var(--main-color-info);
}

.bu-right {
	color: var(--main-color-success);
}

.bu-wrong {
	color: var(--main-color-error);
}
</style>
