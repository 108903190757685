<template>
	<div class="ad-wrap-content">
		<div v-if="!accessGranted">
			<h1>{{ $t('adPageTitle') }}</h1>
			<div class="ad-wrap-input">
				<label>{{ $t('adInputTitle') }}</label>
				<input type="text" autocomplete="on" :placeholder="$t('adInputTitle')" v-model="adminCode" @keyup.enter="checkAdminAccess" />
				<button class="app-default-btn" @click="checkAdminAccess">{{ $t('adGetAccess') }}</button>
			</div>
		</div>
		<div v-else class="ad-wrap-admin-view">
			<CollapsableComponent :title="$t('adSystemStatistics')">
				<SystemStatistics />
			</CollapsableComponent>

			<CollapsableComponent :title="$t('adBugFeedbackView')">
				<BugFeedbackView />
			</CollapsableComponent>

			<CollapsableComponent :title="$t('adUsers')">
				<UserTable />
			</CollapsableComponent>
		</div>
	</div>
</template>

<script>
/**
 * @group Admin
 * Admin page for viewing bugs and feedback
 */
import SystemStatistics from '../components/admin/SystemStatistics.vue';
import UserTable from '../components/admin/UserTable.vue';
import BugFeedbackView from '../components/admin/BugFeedbackView.vue';
import CollapsableComponent from '../components/layouts/CollapsableComponent.vue';
export default {
	name: 'Admin',
	components: {
		SystemStatistics,
		UserTable,
		BugFeedbackView,
		CollapsableComponent,
	},
	data() {
		return {
			accessToken: null,
			adminCode: null,
			admin: null,
			accessGranted: false,
		};
	},
	created() {
		this.checkCodes();
	},
	mounted() {
		document.querySelector('.ad-wrap-content').addEventListener('scroll', this.parentScrollListener);
	},
	beforeDestroy() {
		// document.querySelector('.ad-wrap-content').removeEventListener('scroll', this.parentScrollListener);
	},
	methods: {
		checkCodes() {
			if (this.$route.query.token) this.accessToken = this.$route.query.token;
			else this.accessToken = this.$global.getAccessCode();

			if (this.$route.query.adminCode) this.accessToken = this.$route.query.adminCode;
			else this.adminCode = this.$global.getAdminCode();

			this.admin = this.$global.getUser();

			this.checkAdminAccess(this.$router, true);
		},
		checkAdminAccess() {
			if (this.accessToken.length == 0 || !this.admin) {
				this.$global.showToast('error', this.$t('adAccessDenied'));
				this.$router.push({ name: 'Home' });
			} else if (!this.adminCode) this.$global.checkAccess(this.$router, true);
			else {
				this.$global.postData(
					'access',
					'/checkAdminAccess',
					{ accessCode: this.accessToken, adminCode: this.adminCode, adminID: this.admin.uID },
					null,
					(err, data) => {
						if (err) {
							console.log(err);
							this.$global.showToast('error', this.$t('adAccessDenied'));
							this.adminCode = null;
						} else {
							if (localStorage.getItem('cookiesAllowed')) {
								localStorage.setItem('accessCode', this.accessToken);
								localStorage.setItem('adminCode', this.adminCode);
							} else {
								sessionStorage.setItem('accessCode', this.accessToken);
								sessionStorage.setItem('adminCode', this.adminCode);
							}
							this.accessGranted = true;
						}
					}
				);
			}
		},
		parentScrollListener(e) {
			window.dispatchEvent(new Event('customScroll'));
		},
	},
};
</script>

<style scoped>
.ad-wrap-content {
	width: 100%;
	height: 100%;
	padding-top: 5vh;
	box-sizing: border-box;
	text-align: center;
	overflow: auto;
}

.ad-wrap-content h1 {
	font-size: 100px;
	margin: 0px auto 5vh auto;
	text-shadow: 5px 5px var(--main-color-6);
	-webkit-text-stroke: 2px var(--main-color-text-dark);
}

.ad-wrap-input {
	width: fit-content;
	height: fit-content;
	text-align: start;
	margin: auto;
}

.ad-wrap-input label {
	font-size: 50px;
	margin: 20px auto;
}

.ad-wrap-input input {
	width: 700px;
	height: 50px;
	padding: 5px;
	display: block;
	margin: 20px auto;
	font-size: 30px;
}

.ad-wrap-input button {
	font-size: 30px;
	width: 400px;
	height: 50px;
}

.ad-wrap-admin-view {
	width: 100%;
	padding: 20px;
	box-sizing: border-box;
}
</style>
