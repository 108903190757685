<template>
	<div class="cql-wrap-content">
		<div v-if="quizGrid" :key="resetToggle" class="cql-wrap-grid">
			<div
				v-for="(cell, idx) in quizGrid.flat()"
				:key="`cell-${idx}`"
				:class="getCellClasses(cell, idx)"
				:style="{ backgroundImage: getBackgroundImage(cell, idx) }"
				:title="getCellTitle(cell, idx)"
				@click="clickCell($event, cell, idx)"
			>
				<div class="cql-blur-background">
					<p class="cql-category">{{ cell[1].category }}</p>
					<p class="cql-points">{{ cell[0].points }} {{ $t('cqlPointsAbbrv') }}</p>
					<div v-if="showControls == `cell-${idx}` && !isHost" class="cql-controls">
						<fai icon="fas fa-check-circle" class="cql-confirm" @click="selectCategory($event, cell)" :title="$t('cqlSelectCategory')" />
						<fai
							icon="fas fa-times-circle"
							class="cql-cancel"
							@click="setShowControls($event, `cell-${idx}`)"
							:title="$t('cqlCloseView')"
						/>
					</div>
					<div v-else-if="showControls == `cell-${idx}` && isHost" class="cql-controls">
						<fai
							v-if="
								kqState.selectedQuestion &&
								kqState.selectedQuestion.qID == cell[1].qID &&
								kqState.selectedQuestion.level == cell[0].level &&
								kqState.state != 'revealQuestion'
							"
							icon="fas fa-undo"
							class="cql-undo"
							@click="removeCategory($event, cell)"
							:title="$t('cqlRemoveQuestion')"
						/>
						<fai
							v-else-if="!allQuestionsPlayed()"
							icon="fas fa-chalkboard-teacher"
							class="cql-assign"
							@click="assignCategory($event, cell)"
							:title="$t('cqlAssignCategory')"
						/>
						<fai v-else icon="fas fa-eye" class="cql-show" @click="showCategory($event, cell)" :title="$t('cqlShowCategory')" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
/**
 * @group CategoryQuizLayout
 * Category quiz layout
 */
export default {
	name: 'CategoryQuizLayout',
	props: {
		show: {
			type: Object,
			required: true,
		},
		players: {
			type: Array,
			required: true,
		},
		kqState: {
			type: Object,
			required: true,
		},
	},
	watch: {
		kqState: {
			handler: function (newVal, oldVal) {
				this.preventDoubleClick = false;
				this.showControls = null;
			},
			deep: true,
		},
	},
	data() {
		return {
			quizGrid: null,
			resetToggle: false,
			showControls: null,
			isHost: this.$global.isHost(),
			user: this.$global.getUser(),
		};
	},
	mounted() {
		window.addEventListener('resize', this.resizeListener);
		this.generateGridStructure();
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.resizeListener);
	},
	methods: {
		resizeListener(e) {
			this.generateGridStructure();
		},
		generateGridStructure() {
			let grid = [];
			this.show.pointsPerLevel.forEach((points, idx) => {
				let row = [];
				this.show.questions.forEach((question) => {
					row.push([points, question]);
				});
				grid.push(row);
			});
			this.quizGrid = grid;

			this.$nextTick(() => {
				let rows = grid.length;
				let cols = grid[0].length;

				let container = document.querySelector('.cql-wrap-grid');
				container.style.gridTemplateColumns = `repeat(${cols}, 1fr)`;
				container.style.gridTemplateRows = `repeat(${rows}, 1fr`;
			});
		},
		getCellClasses(cell, idx) {
			let classes = ['cql-cell'];
			let selQ = this.kqState.selectedQuestion;
			if (this.cellDisabled(cell)) classes.push('cql-disabled');
			else if (selQ && selQ.qID == cell[1].qID && selQ.level == cell[0].level) {
				if (this.isHost) classes.push('cql-selected-host');
				else classes.push('cql-selected-user');
			} else if (!selQ && this.showControls !== `cell-${idx}` && (this.kqState.currentPlayer == this.user.uID || this.isHost))
				classes.push('cql-hover');

			if (this.addHighlight(classes, selQ, cell)) classes.push('cql-highlight');
			else if (classes.includes('cql-disabled')) {
				let quest = this.kqState.usedQuestions.filter((it) => it.qID == cell[1].qID && it.level == cell[0].level)[0];
				if (quest && quest.answered == 'correct') classes.push('cql-highlight-correct');
				else if (quest && quest.answered == 'wrong') classes.push('cql-highlight-wrong');
			}
			return classes;
		},
		cellDisabled(cell) {
			// Question was already used
			let questionInUsedQuestions = this.kqState.usedQuestions.filter((uq) => uq.qID == cell[1].qID && uq.level == cell[0].level)[0];

			// Question is already answered
			let questionAlreadyAnswered = questionInUsedQuestions ? (questionInUsedQuestions.answered ? true : false) : false;

			// A question from the category was already answered by this user and multipleAnswersPerCat is false
			let questionsFromSameCategory = this.kqState.usedQuestions.filter((it) => it.qID == cell[1].qID).map((it) => it.uID);
			let questionLockedBySingleAnswer =
				!this.show.multipleAnswersPerCat && questionsFromSameCategory.length > 0 && questionsFromSameCategory.includes(this.user.uID);

			// return (questionInUsedQuestions && questionIsSelected) || questionLockedBySingleAnswer;
			return (questionInUsedQuestions && questionAlreadyAnswered) || (questionInUsedQuestions && !this.isHost) || questionLockedBySingleAnswer;
		},
		addHighlight(classes, selQ, cell) {
			let isSelectedQuestion = selQ && selQ.qID == cell[1].qID && selQ.level == cell[0].level;

			return (classes.includes('cql-disabled') && isSelectedQuestion) || (this.isHost && isSelectedQuestion);
		},
		getBackgroundImage(cell, idx) {
			let questionUsed = this.kqState.usedQuestions.filter((it) => it.qID == cell[1].qID && it.level == cell[0].level)[0];
			if (questionUsed) {
				let player = this.players.filter((it) => it.uID == questionUsed.uID)[0];
				if (player) return `url(${require(`@/assets/images/profilePictures/${player.profilePic}`)})`;
				else return null;
			} else return null;
		},
		getCellTitle(cell, idx) {
			let questionUsed = this.kqState.usedQuestions.filter((it) => it.qID == cell[1].qID && it.level == cell[0].level)[0];
			if (questionUsed) {
				let player = this.players.filter((it) => it.uID == questionUsed.uID)[0];
				if (player) return player.name;
				else return null;
			} else return null;
		},
		clickCell(e, cell, idx) {
			// The cell isn't disabled
			let cellNotDisabled = !this.cellDisabled(cell);

			// The user that is clicking is the current user
			let userCanInteract = this.kqState.currentPlayer == this.user.uID && !this.kqState.selectedQuestion;

			// The clicked question is already clicked
			let questionAlreadySelected =
				this.kqState.selectedQuestion &&
				this.kqState.selectedQuestion.qID == cell[1].qID &&
				this.kqState.selectedQuestion.level == cell[0].level;

			// The host can interact with the question
			let hostCanInteract = this.isHost && (!this.kqState.selectedQuestion || questionAlreadySelected);

			// Host override for question reveal after all questions are played
			let hostOverride = this.isHost && this.allQuestionsPlayed();

			if ((cellNotDisabled && (userCanInteract || hostCanInteract)) || hostOverride) this.setShowControls(e, `cell-${idx}`);
		},
		formatGridCellIndex(idx) {
			return Math.ceil((idx + 1) / 2);
		},
		selectCategory(e, cell) {
			e.stopPropagation();
			this.showControls = null;
			this.preventDoubleClick = true;
			this.$emit('selectCategory', cell);
		},
		removeCategory(e, cell) {
			e.stopPropagation();
			this.showControls = null;
			this.preventDoubleClick = true;
			this.$emit('removeCategory', cell);
		},
		assignCategory(e, cell) {
			e.stopPropagation();
			this.showControls = null;
			this.preventDoubleClick = true;
			this.$emit('assignCategory', cell);
		},
		setShowControls(e, name) {
			e.stopPropagation();
			if (!this.preventDoubleClick) {
				if (this.showControls == name) this.showControls = null;
				else this.showControls = name;
			}
		},
		allQuestionsPlayed() {
			let maxQuestionsPerPlayer = this.kqState.multipleAnswers
				? Math.floor((this.show.questions.length * this.show.pointsPerLevel.length) / this.players.length)
				: this.show.questions.length;

			let availablePlayers = [];

			this.players.forEach((player) => {
				if (this.kqState.usedQuestions.filter((it) => it.uID == player.uID && it.answered).length < maxQuestionsPerPlayer)
					availablePlayers.push(player);
			});

			return availablePlayers.length == 0;
		},
		showCategory(e, cell) {
			e.stopPropagation();
			this.showControls = null;
			this.$emit('showCategory', cell);
		},
	},
};
</script>

<style scoped>
.cql-wrap-content {
	width: 100%;
	height: 100%;
	overflow: auto;
}

.cql-wrap-grid {
	width: 100%;
	height: 100%;
	display: grid;
	overflow: auto;
}

.cql-cell {
	margin: 5px;
	position: relative;
	border: 2px solid var(--main-color-border-light);
	border-radius: 10px;
	background-color: var(--main-color-5-cc);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	overflow: hidden;
}

.cql-cell p {
	user-select: none;
}

.cql-hover:hover {
	cursor: pointer;
	background-color: var(--main-color-6-cc);
}

.cql-disabled {
	cursor: default;
	user-select: none;
	background-color: var(--main-color-disabled);
	opacity: 0.8;
}

.cql-disabled p {
	color: var(--main-color-text-light);
	-webkit-text-stroke: 1px var(--main-color-text-dark);
}

.cql-blur-background {
	width: 100%;
	height: 100%;
	padding: 5px;
	display: flex;
	box-sizing: border-box;
	justify-content: center;
	align-items: center;
	flex-flow: column;
}

.cql-disabled .cql-blur-background,
.cql-selected-user .cql-blur-background {
	background-color: #ffffff40;
}

.cql-selected-host {
	cursor: pointer;
}

.cql-selected-host,
.cql-selected-user {
	background-color: var(--main-color-6-cc);
}
.cql-selected-user {
	cursor: default;
	user-select: none;
	pointer-events: none;
}

.cql-highlight {
	border: 4px solid var(--main-color-6);
	opacity: 1;
}

.cql-highlight-correct {
	border: 4px solid var(--main-color-success);
	opacity: 1;
}
.cql-highlight-wrong {
	border: 4px solid var(--main-color-error);
	opacity: 1;
}

.cql-category {
	font-size: 18px;
	font-weight: bold;
}

.cql-points {
	font-size: 17px;
	font-weight: bold;
}

.cql-controls {
	width: 100%;
	height: 100%;
	flex: 1 1 100%;
	padding: 5px;
	box-sizing: border-box;
	position: absolute;
	top: 0px;
	left: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 5px;
	background-color: var(--main-color-dark-transparent-80);
}

.cql-controls svg {
	font-size: 30px;
	margin: 5px;
	cursor: pointer;
}

.cql-confirm {
	filter: drop-shadow(2px 2px 2px var(--main-color-border-dark));
	color: var(--main-color-success);
}
.cql-confirm:hover {
	color: var(--main-color-success-cc);
}

.cql-cancel {
	filter: drop-shadow(2px 2px 2px var(--main-color-border-dark));
	color: var(--main-color-error);
}
.cql-cancel:hover {
	color: var(--main-color-error-cc);
}

.cql-undo {
	filter: drop-shadow(2px 2px 2px var(--main-color-border-dark));
	color: var(--main-color-warn);
}
.cql-undo:hover {
	color: var(--main-color-warn-cc);
}

.cql-assign {
	filter: drop-shadow(2px 2px 2px var(--main-color-border-dark));
	color: var(--main-color-info);
}
.cql-assign:hover {
	color: var(--main-color-info-cc);
}

.cql-show {
	filter: drop-shadow(2px 2px 2px var(--main-color-border-dark));
	color: var(--main-color-info);
}

.cql-show:hover {
	color: var(--main-color-info-cc);
}
</style>
