import Vue from 'vue';
import App from './App.vue';
import router from './router';
import { global } from './global.js';
import { socket } from './socket.js';
import i18n from '@/translations/i18n';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
	faBars,
	faHome,
	faTimesCircle,
	faEye,
	faEyeSlash,
	faAngleUp,
	faAngleDown,
	faPen,
	faTrash,
	faCoins,
	faHeart,
	faCheck,
	faTimes,
	faTrophy,
	faStopwatch,
	faQuestion,
	faPlusCircle,
	faClone,
	faExternalLinkAlt,
	faUnlink,
	faImage,
	faCheckCircle,
	faInfoCircle,
	faStar,
	faQuestionCircle,
	faUser,
	faCalendarAlt,
	faSyncAlt,
	faLock,
	faLockOpen,
	faSignInAlt,
	faListAlt,
	faTools,
	faRedo,
	faUserPlus,
	faUserMinus,
	faChevronCircleLeft,
	faChevronCircleRight,
	faChevronDown,
	faMinus,
	faExclamationCircle,
	faPaperPlane,
	faBan,
	faChevronCircleUp,
	faPlus,
	faUserCog,
	faSignOutAlt,
	faBalanceScale,
	faCouch,
	faFileSignature,
	faCogs,
	faArrowUp,
	faArrowLeft,
	faBug,
	faHandPointUp,
	faDoorOpen,
	faChevronRight,
	faChevronLeft,
	faUndo,
	faHandPaper,
	faPlay,
	faPause,
	faUserLock,
	faSearch,
	faClock,
	faDoorClosed,
	faThList,
	faSadTear,
	faUsers,
	faObjectGroup,
	faPlayCircle,
	faGlobe,
	faGlobeEurope,
	faEdit,
	faFileImage,
	faTags,
	faConciergeBell,
	faListUl,
	faKeyboard,
	faCircleCheck,
	faLink,
	faUpload,
	faSave,
	faChalkboardTeacher,
	faStairs,
	faTasks,
	faUserTie,
	faChevronUp,
	faUserXmark,
	faIdCard,
	faUserTag,
	faEnvelope,
	faKey,
	faFaceLaughBeam,
	faComment,
	faTag,
	faImages,
	faFileArrowDown,
	faSquareCheck,
	faSquareXmark,
	faHandPointer,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

Vue.prototype.$global = global;
Vue.prototype.$socket = socket;
Vue.config.productionTip = false;

library.add(
	faUserTie,
	faCircleCheck,
	faLink,
	faTasks,
	faStairs,
	faUpload,
	faSave,
	faChalkboardTeacher,
	faBars,
	faTags,
	faKeyboard,
	faConciergeBell,
	faListUl,
	faFileImage,
	faEdit,
	faPlayCircle,
	faGlobeEurope,
	faHome,
	faCogs,
	faBug,
	faArrowUp,
	faArrowLeft,
	faSearch,
	faClock,
	faDoorClosed,
	faFileSignature,
	faCouch,
	faSignOutAlt,
	faBalanceScale,
	faUserCog,
	faTimesCircle,
	faEye,
	faEyeSlash,
	faAngleUp,
	faAngleDown,
	faPen,
	faTrash,
	faCoins,
	faHeart,
	faCheck,
	faThList,
	faSadTear,
	faTimes,
	faTrophy,
	faStopwatch,
	faQuestion,
	faPlusCircle,
	faInfoCircle,
	faClone,
	faExternalLinkAlt,
	faUnlink,
	faImage,
	faCheckCircle,
	faStar,
	faQuestionCircle,
	faUser,
	faUsers,
	faUserTag,
	faEnvelope,
	faKey,
	faSquareCheck,
	faSquareXmark,
	faImages,
	faFileArrowDown,
	faFaceLaughBeam,
	faComment,
	faTag,
	faObjectGroup,
	faCalendarAlt,
	faSyncAlt,
	faLock,
	faLockOpen,
	faSignInAlt,
	faListAlt,
	faTools,
	faRedo,
	faUserPlus,
	faUserMinus,
	faChevronCircleLeft,
	faChevronCircleRight,
	faChevronDown,
	faChevronUp,
	faUserXmark,
	faIdCard,
	faMinus,
	faExclamationCircle,
	faPaperPlane,
	faBan,
	faChevronCircleUp,
	faPlus,
	faHandPointUp,
	faDoorOpen,
	faChevronRight,
	faChevronLeft,
	faUndo,
	faHandPaper,
	faPause,
	faPlay,
	faUserLock,
	faHandPointer
);
Vue.component('fai', FontAwesomeIcon);

new Vue({
	router,
	i18n,
	render: function (h) {
		return h(App);
	},
}).$mount('#app');
