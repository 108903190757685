<template>
	<div class="hi-wrap-content">
		<h3>{{ $t('hiSessionHistory') }}</h3>
		<div class="hi-wrap-entrys">
			<HistoryEntry
				v-for="entry in historyParsed"
				:key="entry.hID"
				:entry="entry"
				:players="players"
				:shows="shows"
				:configuredShows="configuredShows"
			/>
		</div>
	</div>
</template>

<script>
import HistoryEntry from './HistoryEntry.vue';
export default {
	name: 'History',
	components: {
		HistoryEntry,
	},
	props: {
		history: {
			type: Array,
			required: true,
		},
		players: {
			type: Array,
			required: true,
		},
		shows: {
			type: Array,
			required: true,
		},
		configuredShows: {
			type: Array,
			required: true,
		},
	},
	watch: {
		history: {
			handler: function (newVal) {
				this.parseHistory();
				this.$nextTick(() => {
					let element = document.querySelector('.hi-wrap-entrys');
					element.scrollTo({ top: element.scrollHeight, behavior: 'smooth' });
				});
			},
			deep: true,
		},
	},
	data() {
		return {
			historyParsed: [],
		};
	},
	created() {
		this.parseHistory();
	},
	mounted() {
		this.$nextTick(() => {
			let element = document.querySelector('.hi-wrap-entrys');
			element.scrollTo({ top: element.scrollHeight, behavior: 'smooth' });
		});
	},
	methods: {
		parseHistory() {
			let parsedHistory = [];
			let lastTimestamp = null;
			this.history.forEach((his) => {
				let currentTimestamp = new Date(his.timepoint);
				if (!lastTimestamp) parsedHistory.push({ action: 'dayChange', timepoint: currentTimestamp });
				else if (lastTimestamp && lastTimestamp.getDate() != currentTimestamp.getDate())
					parsedHistory.push({ action: 'dayChange', timepoint: currentTimestamp });
				parsedHistory.push(his);
				lastTimestamp = currentTimestamp;
			});

			this.historyParsed = parsedHistory;
		},
	},
};
</script>

<style scoped>
.hi-wrap-content {
	height: 100%;
	background-color: transparent;
}

h3 {
	text-align: center;
	font-size: 20px;
	color: var(--main-color-text-dark);
}

.hi-wrap-entrys {
	height: calc(100% - 33px);
	margin-top: 10px;
	padding-right: 4px;
	border-radius: 10px 0px 0px 10px;
	background-color: var(--main-color-5);
	overflow: auto;
	overflow-x: hidden;
}
</style>
