<template>
	<div class="gsp-wrap-content">
		<WaitingRoom
			v-if="lobby.session.state == 'lobby'"
			:players="players"
			:configuredShows="configuredShows"
			:shows="shows"
			:leaderboard="lobby.leaderboard"
			:history="lobby.history"
			:sessionState="sessionState"
			:sessionRoom="sessionRoom"
			:host="host"
			:sessionReady="sessionReady"
			@removeUserFromSession="$emit('removeUserFromSession', $event)"
			@startShow="$emit('startShow', $event)"
		/>
		<Podium
			v-else-if="lobby.session.state == 'finished'"
			:players="players"
			:shows="shows"
			:leaderboard="lobby.leaderboard"
			:history="lobby.history"
			:session="lobby.session"
			:quizNight="quizNight"
			:configuredShows="configuredShows"
		/>
		<GameShow
			v-else
			:show="configuredShows.filter((show) => show.show == lobby.session.state)[0]"
			:history="lobby.history"
			:showSolutionImage="showSolutionImage"
			:players="players"
			:wlpState="wlpState"
			:zgwState="zgwState"
			:kqState="kqState"
			@selectCategory="selectCategory"
			@removeCategory="removeCategory"
			@assignCategory="setAssignCategory"
			@showCategory="showCategory"
			:key="questionToggle"
		/>
		<TransitionScreen v-if="transitionScreenConfig" :config="transitionScreenConfig" />
		<div v-if="!['lobby', 'finished'].includes(lobby.session.state) && !$global.isHost()" class="gsp-wrap-user-controls">
			<UserControls
				:show="configuredShows.filter((show) => show.show == lobby.session.state)[0]"
				:players="players"
				:ownAnswer="ownAnswer"
				:history="lobby.history"
				:buzzerUser="buzzerUser"
				:buzzerAnimation="buzzerAnimation"
				:wlpState="wlpState"
				:zgwState="zgwState"
				:kqState="kqState"
				:timerUpdate="timerUpdate"
				:key="questionToggle"
				@giveTextAnswer="giveTextAnswer"
				@buzzerPressed="buzzerPressed"
				@sendBid="sendBid"
				@lockBid="lockBid"
				@addTopicTerm="addTopicTerm"
				@removeTopicTerm="removeTopicTerm"
				@lockTerms="lockTerms"
				@useJoker="useJoker"
			/>
		</div>
		<div v-else-if="!['lobby', 'finished'].includes(lobby.session.state) && $global.isHost()" class="gsp-wrap-host-controls">
			<HostControls
				:show="configuredShows.filter((show) => show.show == lobby.session.state)[0]"
				:players="players"
				:givenAnswers="givenAnswers"
				:history="lobby.history"
				:pressedBuzzer="pressedBuzzer"
				:wlpState="wlpState"
				:zgwState="zgwState"
				:kqState="kqState"
				:timerUpdate="timerUpdate"
				:key="questionToggle"
				@unlockAnswer="unlockAnswer"
				@unlockBuzzer="unlockBuzzer"
				@rightBuzzer="rightBuzzer"
				@wrongBuzzer="wrongBuzzer"
				@toggleSolution="toggleSolution"
				@setCurrentBidder="setCurrentBidder"
				@unlockBid="unlockBid"
				@assignItem="assignItem"
				@assignCurrentItem="assignCurrentItem"
				@setMoney="setMoney"
				@setPoints="setPoints"
				@revealItem="revealItem"
				@revealAllItems="revealAllItems"
				@awardWLPPoints="awardWLPPoints"
				@correctAnswer="correctAnswer"
				@updateZGWState="updateZGWState"
				@assignTopic="assignTopic"
				@assignTopicGuessing="assignTopicGuessing"
				@updateAcceptedTerm="updateAcceptedTerm"
				@assignNewPlayer="assignNewPlayer"
				@revealTopicTerm="revealTopicTerm"
				@revealAllTopicTerms="revealAllTopicTerms"
				@assignNewCreator="assignNewCreator"
				@startTimer="startTimer"
				@resetTimer="resetTimer"
				@resumeTimer="resumeTimer"
				@stopTimer="stopTimer"
				@updateTimer="updateTimer"
				@nextQuestion="nextQuestion"
				@nextShow="nextShow"
				@assignCategory="assignCategory"
				@restoreJoker="restoreJoker"
				@revertClick="revertClick"
				@correctAnswerKQ="correctAnswerKQ"
				@wrongAnswerKQ="wrongAnswerKQ"
			/>
		</div>
	</div>
</template>

<script>
import SideBar from './SideBar.vue';
import WaitingRoom from './WaitingRoom.vue';
import GameShow from './GameShow.vue';
import Podium from './Podium.vue';
import UserControls from './UserControls.vue';
import HostControls from './HostControls.vue';
import TransitionScreen from './TransitionScreen.vue';
import historyModel from '@/assets/data/historyModel.json';

export default {
	name: 'GameShowPanel',
	components: {
		SideBar,
		WaitingRoom,
		GameShow,
		Podium,
		UserControls,
		HostControls,
		TransitionScreen,
	},
	props: {
		lobby: {
			type: Object,
			required: true,
		},
		players: {
			type: Array,
			required: true,
		},
		configuredShows: {
			type: Array,
			required: true,
		},
		shows: {
			type: Array,
			required: true,
		},
		sessionRoom: {
			type: Object,
			required: true,
		},
		host: {
			type: Object,
			required: true,
		},
		sessionReady: {
			type: Boolean,
			required: true,
		},
		sessionState: {
			type: Object,
			required: true,
		},
		quizNight: {
			type: Object,
			required: true,
		},
	},
	watch: {
		sessionState: {
			handler: function (newVal) {
				if (newVal.currentState == 'wlp') {
					let lastQuestions = this.lobby.history.filter((his) => his.action == 'setQuestion');
					let show = this.configuredShows.filter((show) => show.show == 'wlp')[0];
					if (show) {
						let quest = show.questions.filter((quest) => quest.qID == lastQuestions[lastQuestions.length - 1].result)[0];
						this.reconstructWLP(show, quest);
					}
				} else if (newVal.currentState == 'kq') {
					let show = this.configuredShows.filter((show) => show.show == 'kq')[0];
					if (show) this.reconstructKQ(show);
				}
				this.$nextTick(() => {
					window.dispatchEvent(new Event('resize'));
				});
			},
			deep: true,
		},
	},
	data() {
		return {
			historyModel: historyModel,
			questionToggle: false,
			socket: null,
			ownAnswer: {},
			givenAnswers: {},
			pressedBuzzer: null,
			buzzerUser: null,
			buzzerAnimation: null,
			buzzerSound: new Audio(require('@/assets/sounds/buzz.wav')),
			rightSound: new Audio(require('@/assets/sounds/right.wav')),
			wrongSound: new Audio(require('@/assets/sounds/wrong.wav')),
			unlockSound: new Audio(require('@/assets/sounds/unlock.wav')),
			showSolutionImage: false,
			wlpState: null,
			zgwState: {
				phase: 'assignTopic',
				selectedCreator: null,
				selectedTopic: null,
				selectedGuesser: null,
				proposedTopicTerms: [],
				acceptedTopicTerms: [],
				revealedTopicTerms: [],
			},
			kqState: {
				state: null,
				multipleAnswers: true,
				players: [],
				currentPlayer: null,
				selectedQuestion: null,
				usedQuestions: [],
			},
			allowReveal: true,
			timerUpdate: {
				newTime: null,
				newState: null,
			},
			transitionScreenConfig: null,
		};
	},
	created() {
		this.initiateSocket();
	},
	mounted() {
		this.$nextTick(() => {
			window.dispatchEvent(new Event('resize'));
		});
		// this.$global.showTimer(
		// 	1000,
		// 	{
		// 		title: 'Title of popup',
		// 		message: 'msg',
		// 	},
		// 	function (result) {}
		// );
		// window.setTimeout(() => {
		// 	this.transitionScreenConfig = {
		// 		show: this.sessionState.nextState,
		// 		timer: 3000,
		// 	};
		// }, 3000);
	},
	beforeDestroy() {
		this.socket.off('get_buzzer_state');
		this.socket.off('set_buzzer_state');
		this.socket.off('text_answer');
		this.socket.off('buzzer_pressed');
		this.socket.off('buzzer_user');
		this.socket.off('unlock_answer');
		this.socket.off('unlock_buzzer');
		this.socket.off('right_buzzer');
		this.socket.off('wrong_buzzer');
		this.socket.off('toggle_solution');
		this.socket.off('set_bid');
		this.socket.off('lock_bid');
		this.socket.off('unlock_bid');
		this.socket.off('assign_item');
		this.socket.off('set_current_bidder');
		this.socket.off('set_money');
		this.socket.off('highest_bid');
		this.socket.off('reveal_item');
		this.socket.off('points_win');
		this.socket.off('set_points');
		this.socket.off('correct_answer');
		this.socket.off('show_timer');
		this.socket.off('assign_topic');
		this.socket.off('update_topic_terms');
		this.socket.off('lock_topic_terms');
		this.socket.off('assign_topic_guessing');
		this.socket.off('update_accepted_terms');
		this.socket.off('topic_summary');
		this.socket.off('reveal_topic_term');
		this.socket.off('assign_new_creator');
		this.socket.off('start_timer');
		this.socket.off('reset_timer');
		this.socket.off('resume_timer');
		this.socket.off('stop_timer');
		this.socket.off('update_timer');
		this.socket.off('select_category');
		this.socket.off('remove_category');
		this.socket.off('use_joker');
		this.socket.off('restore_joker');
		this.socket.off('correct_answer_kq');
		this.socket.off('wrong_answer_kq');
		this.socket.off('show_category');
	},
	methods: {
		initiateSocket() {
			let that = this;
			this.$socket.getSocket(function (socket) {
				that.socket = socket;

				let interval = window.setInterval(() => {
					if (that.$socket.isSocketReady()) {
						clearInterval(interval);
						that.reconstructState();
					}
				}, 100);

				socket.on('get_buzzer_state', function () {
					if (that.pressedBuzzer) that.socket.emit('set_buzzer_state', { uID: that.pressedBuzzer.uID, sID: that.$route.params.sID });
				});

				socket.on('set_buzzer_state', function (data) {
					if (!that.buzzerUser) that.buzzerUser = that.players.filter((player) => player.uID == data)[0];
				});

				socket.on('text_answer', function (data) {
					that.$set(that.givenAnswers, data.uID, data);
					that.$nextTick(() => window.dispatchEvent(new Event('resize')));
				});

				socket.on('buzzer_pressed', function (data) {
					if (!that.pressedBuzzer) {
						that.buzzerSound.volume = 0.5;
						that.buzzerSound.play();
						that.pressedBuzzer = that.players.filter((player) => player.uID == data.uID)[0];
						socket.emit('buzzer_user', { uID: data.uID, sID: that.$route.params.sID });
					}
				});

				socket.on('buzzer_user', function (data) {
					that.buzzerUser = that.players.filter((player) => player.uID == data)[0];
					that.buzzerSound.volume = 0.5;
					that.buzzerSound.play();
				});

				socket.on('unlock_answer', function () {
					that.ownAnswer.lockAnswer = false;
				});

				socket.on('unlock_buzzer', function () {
					that.pressedBuzzer = null;
					that.buzzerUser = null;
					that.buzzerAnimation = 'unlock';
					that.unlockSound.volume = 0.5;
					that.unlockSound.play();

					that.resetBuzzerAnimation();
				});

				socket.on('right_buzzer', function (data) {
					that.rightSound.volume = 0.5;
					that.rightSound.play();
					that.buzzerAnimation = 'right';
					let player = that.players.filter((player) => player.uID == data.uID)[0];
					// that.showSolutionImage = true;
					if (that.$global.getUser().uID == data.uID)
						that.$global.showToast('success', `${that.$t('gspRightBuzzer')}: ${data.points} ${that.$t('gspPointsAbbrv')}`);
					else if (!that.$global.isHost())
						that.$global.showToast(
							'info',
							`${player.name} ${that.$t('gspBuzzeredCorrectlyAndGot')} ${data.points} ${that.$t('gspPointsAbbrv')}`
						);

					that.resetBuzzerAnimation();
				});

				socket.on('wrong_buzzer', function (data) {
					that.wrongSound.volume = 0.5;
					that.wrongSound.play();
					that.buzzerAnimation = 'wrong';
					if (that.$global.getUser().uID == data.uID) {
						that.$global.showToast('error', `${that.$t('gspWrongBuzzer')}`);
					} else if (!that.$global.isHost()) {
						that.$global.showToast('success', `${that.$t('gspOtherWrongBuzzer')}: ${data.points} ${that.$t('gspPointsAbbrv')}`);
					}

					that.pressedBuzzer = null;
					that.buzzerUser = null;

					that.resetBuzzerAnimation();
				});

				socket.on('toggle_solution', function (data) {
					that.showSolutionImage = data.toggle;
				});

				socket.on('set_bid', function (data) {
					let bidIdx = that.wlpState.bids.findIndex((bid) => bid.uID == data.uID);
					that.wlpState.bids[bidIdx].bid = data.bid;

					that.setNextBidder(data.uID, { bid: data.bid, uID: data.uID }, data.qID);
				});

				socket.on('lock_bid', function (data) {
					that.wlpState.users.map((user) => {
						if (user.uID == data.uID) user.lockBid = true;
						return user;
					});

					let highestBidder = null;
					that.wlpState.bids.forEach((bidder) => {
						if (that.wlpState.users.filter((user) => user.uID == bidder.uID && user.lockBid).length == 0) {
							if (!highestBidder && bidder.uID !== data.uID) highestBidder = bidder;
							else if (highestBidder) {
								if (highestBidder.bid < bidder.bid) highestBidder = bidder;
							}
						}
					});
					// If the highest bis is 0 then nobody has bid yet
					if (highestBidder && highestBidder.bid == 0) highestBidder = null;

					if (that.wlpState.users.filter((user) => user.lockBid).length >= that.wlpState.users.length - 1) {
						that.wlpState.users.map((user) => {
							user.disableBid = true;
							return user;
						});

						if (!highestBidder) {
							let lastUser = that.wlpState.users.filter((user) => !user.lockBid)[0];
							let highestBid = that.wlpState.bids.filter((bid) => bid.uID == lastUser.uID && bid.qID == data.qID)[0];
							highestBidder = {
								bid: highestBid ? (highestBid.bid == 0 ? 1 : highestBid.bid) : 1,
								uID: lastUser.uID,
							};

							that.wlpState.bids.map((bid) => {
								if (bid.uID == highestBidder.uID && bid.qID == data.qID) bid.bid = highestBidder.bid;
								return bid;
							});
						}

						if (that.$global.isHost()) {
							let sID = that.$route.params.sID;
							let show = that.configuredShows.filter((show) => show.show == that.lobby.session.state)[0];

							// prettier-ignore
							that.$global.addHistoryEntry(sID, that.$global.getUser().uID, show.gID, data.qID, 'highestBid', highestBidder.bid, null, highestBidder.uID, function (err) {
								if (err) that.$global.showToast('error', that.$t('gspHighestBidError'));
								else {
									that.socket.emit('highest_bid', { sID: sID, uID: highestBidder.uID,	qID: data.qID, host: that.host.uID });
								}
							});
						}
					} else that.setNextBidder(data.uID, highestBidder, data.qID);
				});

				socket.on('unlock_bid', function (data) {
					that.wlpState.users.map((user) => {
						if (user.uID == data.uID) user.lockBid = false;
						return user;
					});
				});

				socket.on('assign_item', function (data) {
					let lastUser = null;
					that.wlpState.items.map((item) => {
						if (item.qID == data.qID) {
							lastUser = item.uID;
							item.uID = data.uID;
						}
						return item;
					});
					that.wlpState.users.map((user) => {
						if (user.uID == data.uID) user.money -= data.bid;
						if (user.uID == lastUser) user.money += data.bid;
						return user;
					});

					if (data.uID == that.$global.getUser().uID) that.$global.showToast('success', `${that.$t('gspAssignedItemToYou')}`);
					else {
						that.$global.showToast(
							'info',
							`${that.players.filter((player) => player.uID == data.uID)[0].name} ${that.$t('gspGotItemAssigned')}`
						);
					}
				});

				socket.on('set_current_bidder', function (data) {
					that.wlpState.users.map((user) => {
						if (user.uID == data.uID) user.disableBid = false;
						else user.disableBid = true;
						return user;
					});

					if (that.$global.getUser().uID == data.uID) that.$global.showToast('success', `${that.$t('gspSetCurrentBidderToYou')}`);
				});

				socket.on('set_money', function (data) {
					that.wlpState.users.map((user) => {
						if (user.uID == data.uID) user.money = data.money;
						return user;
					});
				});

				socket.on('highest_bid', function (data) {
					let bid = that.wlpState.bids.filter((bid) => bid.uID == data.uID)[0];
					that.wlpState.users.map((user) => {
						user.disableBid = true;
						if (user.uID == data.uID) user.money = bid.bid ? user.money - bid.bid : user.money - 1;
						return user;
					});

					that.wlpState.items.push({ qID: data.qID, bid: bid.bid ? bid.bid : 1, uID: data.uID });

					if (data.isAssignment) {
						if (data.uID == that.$global.getUser().uID) that.$global.showToast('success', `${that.$t('gspItemAssignedToYou')}`);
						else
							that.$global.showToast(
								'info',
								`${that.players.filter((player) => player.uID == data.uID)[0].name} ${that.$t('gspGotTheItemAssigned')}`
							);
					} else {
						if (data.uID == that.$global.getUser().uID) that.$global.showToast('success', `${that.$t('gspYouHaveHighestBet')}`);
						else
							that.$global.showToast(
								'info',
								`${that.players.filter((player) => player.uID == data.uID)[0].name} ${that.$t('gspHasHighestBet')}`
							);
					}
				});

				socket.on('reveal_item', function (data) {
					that.wlpState.revealState.push({
						type: data.type,
						qID: data.qID,
						uID: data.uID,
						position: data.position,
						diff: data.diff ? Number(data.diff) : null,
					});
				});

				socket.on('points_win', function (data) {
					if (!that.$global.isHost()) {
						let show = that.configuredShows.filter((show) => show.show == that.lobby.session.state)[0];
						let user = data.wlpLeaderboard.filter((entry) => entry.uID == that.$global.getUser().uID)[0];
						let points = Math.round(show.pointsWin * user.pointsDistribution);
						if (user) {
							// prettier-ignore
							if (user.place <= 3) that.$global.showToast('success',`${that.$t('gspYouGot')} ${user.place}. ${that.$t('gspAndGot')} ${points} ${that.$t('gspPoints')}`);
							// prettier-ignore
							else that.$global.showToast('info',`${that.$t('gspYouGot')} ${user.place}. ${that.$t('gspAndGot')} ${points} ${that.$t('gspPoints')}`);
						}
					} else {
						window.setTimeout(() => {
							that.nextQuestion();
						}, 3000);
					}
				});

				socket.on('set_points', function (data) {
					if (that.$global.getUser().uID == data.uID) {
						if (data.mode == 'addPoints')
							that.$global.showToast('success', `${that.$t('gspPointsAdded')}: ${data.points} ${that.$t('gspPointsAbbrv')}`);
						else if (data.mode == 'removePoints')
							that.$global.showToast('error', `${that.$t('gspPointsRemoved')}: ${data.points} ${that.$t('gspPointsAbbrv')}`);
					}
				});

				socket.on('correct_answer', function (data) {
					that.showSolutionImage = true;
					let player = that.players.filter((player) => player.uID == data.uID)[0];
					if (that.$global.getUser().uID == data.uID) that.$global.showToast('success', that.$t('gspYourAnswerWasCorrect'));
					else if (!that.$global.isHost()) that.$global.showToast('info', `${player.name} ${that.$t('gspAnsweredCorrectly')}`);
				});

				socket.on('show_timer', function (data) {
					let translations = {
						title: that.$t(data.msg),
						message: '',
					};

					let timerDuration = 3000;
					if (data.msg == 'gspNextQuestion' && that.$global.isHost()) timerDuration = 1000;

					if (data.msg == 'gspNextShow') {
						that.transitionScreenConfig = {
							show: that.sessionState.nextState,
							timer: timerDuration,
						};
						that.$nextTick(() => {
							window.setTimeout(() => {
								that.transitionScreenConfig = null;
								if (that.$global.isHost()) {
									let user = that.$global.getUser();
									if (that.sessionState.nextState == 'wlp') {
										let show = that.quizNight.configs.filter((show) => show.show == 'wlp')[0];
										let question = show.questions.filter((show) => show.position == 0)[0];
										let randomStarter = Math.floor(Math.random() * that.players.length);
										let firstBidder = that.players[randomStarter];

										// prettier-ignore
										that.$global.addHistoryEntry(that.$route.params.sID, user.uID, show.gID, question.qID, 'startBidder', firstBidder.order, null, firstBidder.uID, function (err) {
											if (err) that.$global.showToast('error', that.$t('gspSetStartBidderError'));
											that.$socket.emitEvent('start_show', { sID: that.$route.params.sID });
										});
									} else if (that.sessionState.nextState == 'kq') {
										let show = that.quizNight.configs.filter((show) => show.show == 'kq')[0];
										let starter = that.players[Math.floor(Math.random() * that.players.length)];
										// prettier-ignore
										that.$global.addHistoryEntry(that.$route.params.sID, user.uID, show.gID, null, 'setStarter', null, null, starter.uID, function (err) {
											if (err) that.$global.showToast('error', that.$t('gspSetStarterError'));
											that.$socket.emitEvent('start_show', { sID: that.$route.params.sID });
										});
									} else that.$socket.emitEvent('start_show', { sID: that.$route.params.sID });
								}
							}, timerDuration);
						});
						// let nextShowLogo = null
						// if(that.sessionState.nextState == 'smf') nextShow = "SMFLogo.png"
						// else if(that.sessionState.nextState == 'wlp') nextShow = "SMFLogo.png"
						// else if(that.sessionState.nextState == 'xfz') nextShow = "SMFLogo.png"
						// else if(that.sessionState.nextState == 'dpin') nextShow = "DPINLogo.png"
						// let ne = that.configuredShows.filter((show) => show.show == that.sessionState.nextState)[0];
						// 				let question = show.questions.filter((show) => show.position == 0)[0];
					} else {
						that.$global.showTimer(timerDuration, translations, function (result) {
							if (['gspSwapToLobby', 'gspSwapToResults'].includes(data.msg)) {
								if (result.dismiss !== 'timer') {
									window.setTimeout(() => {
										that.socket.emit('force_reload', { sID: that.$route.params.sID }, function (success) {});
									}, timerDuration);
								} else that.socket.emit('force_reload', { sID: that.$route.params.sID }, function (success) {});
							} else if (data.msg == 'gspNextQuestion') {
								that.ownAnswer = {};
								that.givenAnswers = {};
								that.buzzerUser = null;
								that.pressedBuzzer = null;
								that.buzzerAnimation = null;
								that.showSolutionImage = false;
								if (that.wlpState) {
									that.wlpState.bids.map((bid) => {
										bid.bid = 0;
										bid.qID = data.qID;
										return bid;
									});

									// Disable all users and check lock
									that.wlpState.users.map((user) => {
										user.disableBid = true;
										if (user.money !== 0) user.lockBid = false;
										else user.lockBid = true;

										return user;
									});

									let startBidders = that.lobby.history.filter((his) => his.action == 'startBidder');
									let nextBidder = startBidders[startBidders.length - 1];
									let userIdx = that.wlpState.users.findIndex((user) => user.uID == nextBidder.result);

									if (!that.wlpState.users[userIdx].lockBid) that.wlpState.users[userIdx].disableBid = false;
									else {
										let order = Number(nextBidder.info);
										if (order == 0) {
											for (let i = 1; i < that.wlpState.users.length; i++) {
												if (!that.wlpState.users[i].lockBid) {
													that.wlpState.users[i].disableBid = false;
													break;
												}
											}
										} else {
											let enabledBid = false;
											for (let i = order + 1; i < that.wlpState.users.length; i++) {
												if (!that.wlpState.users[i].lockBid) {
													that.wlpState.users[i].disableBid = false;
													enabledBid = true;
													break;
												}
											}
											if (!enabledBid) {
												for (let i = 0; i < order; i++) {
													if (!that.wlpState.users[i].lockBid) {
														that.wlpState.users[i].disableBid = false;
														break;
													}
												}
											}
										}
									}

									// that.wlpState.users.map((user, idx) => {
									// 	if (user.money !== 0) {
									// 		if (idx == enableIdx) user.disableBid = false;
									// 		else user.disableBid = true;
									// 		user.lockBid = false;
									// 	} else {
									// 		if (idx == enableIdx) enableIdx += 1;
									// 		user.disableBid = true;
									// 		user.lockBid = true;
									// 	}
									// 	return user;
									// });
								}
								if (result.dismiss !== 'timer') {
									window.setTimeout(() => {
										that.questionToggle = !that.questionToggle;
										window.dispatchEvent(new Event('resize'));
									}, timerDuration);
								} else that.questionToggle = !that.questionToggle;
							} else if (data.msg == 'gspRevealScreen') {
								if (that.wlpState) {
									that.wlpState.revealState = [];
								}
							} else if (data.msg == 'gspNextShow') {
								if (that.$global.isHost()) {
									if (that.sessionState.nextState == 'wlp') {
										let show = that.quizNight.configs.filter((show) => show.show == 'wlp')[0];
										let question = show.questions.filter((show) => show.position == 0)[0];
										let firstBidder = that.players.filter((player) => player.order == 0)[0];

										// prettier-ignore
										that.$global.addHistoryEntry(that.$route.params.sID, that.$global.getUser().uID, show.gID, question.qID, 'startBidder', firstBidder.order, null, firstBidder.uID, function (err) {
										if (err) that.$global.showToast('error', that.$t('gspSetStartBidderError'));
									});
									}

									that.$socket.emitEvent('start_show', { sID: that.$route.params.sID });
								}
							}
						});
					}
				});

				socket.on('assign_topic', function (data) {
					that.zgwState = data;
					that.zgwState.phase = 'lockTopicTerms';
					that.$nextTick(() => window.dispatchEvent(new Event('resize')));
				});

				socket.on('update_topic_terms', function (data) {
					if (that.$global.isHost()) {
						that.zgwState.proposedTopicTerms = data.terms;
						sessionStorage.setItem('proposedTopicTerms', JSON.stringify(data.terms));
						that.$nextTick(() => {
							window.dispatchEvent(new Event('resize'));
						});
					}
					that.$nextTick(() => window.dispatchEvent(new Event('resize')));
				});

				socket.on('lock_topic_terms', function (data) {
					that.zgwState.selectedGuesser = null;
					that.timerUpdate = {
						newTime: null,
						newState: null,
					};
					that.zgwState.proposedTopicTerms = data.proposedTopicTerms;
					that.zgwState.acceptedTopicTerms = [];
					that.zgwState.phase = 'assignTopicGuessing';
					sessionStorage.removeItem('proposedTopicTerms');
					that.$nextTick(() => window.dispatchEvent(new Event('resize')));
				});

				socket.on('assign_topic_guessing', function (data) {
					that.zgwState.selectedGuesser = data.selectedGuesser;
					if (data.selectedGuesser.uID == that.$global.getUser().uID) that.$global.showToast('info', that.$t('gspYouAreTheGuesser'));
					else if (!that.$global.isHost()) that.$global.showToast('info', `${data.selectedGuesser.name} ${that.$t('gspIsTheNextGuesser')}`);
					that.$nextTick(() => window.dispatchEvent(new Event('resize')));
				});

				socket.on('update_accepted_terms', function (data) {
					if (data.selectedCreator.uID == that.$global.getUser().uID) that.zgwState.acceptedTopicTerms = data.acceptedTopicTerms;
					that.$nextTick(() => window.dispatchEvent(new Event('resize')));
				});

				socket.on('topic_summary', function (data) {
					sessionStorage.removeItem('timeLeft');
					sessionStorage.removeItem('acceptedTopicTerms');
					that.zgwState.selectedGuesser = null;
					that.zgwState.acceptedTopicTerms = [];
					that.zgwState.phase = 'topicSummary';
					that.$nextTick(() => window.dispatchEvent(new Event('resize')));
				});

				socket.on('reveal_topic_term', function (data) {
					that.zgwState.revealedTopicTerms = data.revealedTopicTerms;
					that.$nextTick(() => window.dispatchEvent(new Event('resize')));
				});

				socket.on('assign_new_creator', function (data) {
					that.zgwState = data;
					that.$nextTick(() => window.dispatchEvent(new Event('resize')));
				});

				socket.on('start_timer', function (data) {
					that.zgwState.phase = 'guessingTime';
					that.timerUpdate.newState = 'STARTED';
					that.timerUpdate.newTime = null;
					if (data.selectedGuesser.uID == that.$global.getUser().uID)
						that.$global.showToast('info', `${that.$t('gspYouTopicIs')}: ${data.selectedTopic.question}`);
					else if (!that.$global.isHost()) that.$global.showToast('info', that.$t('gspTimerStarted'));
					that.$nextTick(() => window.dispatchEvent(new Event('resize')));
				});

				socket.on('reset_timer', function (data) {
					that.zgwState.phase = 'assignTopicGuessing';
					that.timerUpdate.newState = 'INITIAL';
					that.timerUpdate.newTime = null;
					sessionStorage.removeItem('timeLeft');
					that.$nextTick(() => window.dispatchEvent(new Event('resize')));
				});

				socket.on('resume_timer', function (data) {
					that.timerUpdate.newState = 'STARTED';
					that.timerUpdate.newTime = data.timeLeft;
					that.$nextTick(() => window.dispatchEvent(new Event('resize')));
				});

				socket.on('stop_timer', function (data) {
					that.timerUpdate.newState = 'STOPED';
					that.timerUpdate.newTime = data.timeLeft;
					that.$nextTick(() => window.dispatchEvent(new Event('resize')));
				});

				socket.on('update_timer', function (data) {
					that.timerUpdate.newState = data.state;
					that.timerUpdate.newTime = data.timeLeft;
					that.$nextTick(() => window.dispatchEvent(new Event('resize')));
				});

				socket.on('select_category', function (data) {
					that.kqState.selectedQuestion = data;
					that.kqState.usedQuestions.push(data);
					that.kqState.state = 'showQuestion';

					let show = that.quizNight.configs.filter((show) => show.show == 'kq')[0];
					let question = show.questions.filter((quest) => quest.qID == data.qID)[0];
					if (question && (that.$global.getUser().uID != data.uID || data.assign)) {
						let name = that.players.filter((player) => player.uID == data.uID)[0].name;

						// prettier-ignore
						that.$global.showToast('info',`${name} ${that.$t('gspSelectedCategory')} ${question.category} (${show.pointsPerLevel[data.level].points} ${that.$t('gspPointsAbbrv')})`);
					}

					that.$nextTick(() => {
						window.dispatchEvent(new Event('resize'));
					});
				});

				socket.on('remove_category', function (data) {
					if (that.kqState && that.kqState.selectedQuestion) {
						if (that.compareSelectedQuestion(that.kqState.selectedQuestion, data)) that.kqState.selectedQuestion = null;
						that.kqState.usedQuestions = that.kqState.usedQuestions.filter((quest) => !that.compareSelectedQuestion(quest, data));
					}
					that.kqState.state = null;

					if (data.restoreJoker) {
						that.kqState.players = that.kqState.players.map((it) => {
							if (it.uID == data.uID) it.joker = data.joker;
							return it;
						});
					}

					let show = that.quizNight.configs.filter((show) => show.show == 'kq')[0];
					let question = show.questions.filter((quest) => quest.qID == data.qID)[0];
					if (question) {
						// prettier-ignore
						that.$global.showToast('info',`${that.$t('gspTheCategory')} ${question.category} (${show.pointsPerLevel[data.level].points} ${that.$t('gspPointsAbbrv')}) ${data.restoreJoker ? that.$t('gspWasRemovedAndJokersRestored') : that.$t('gspWasRemoved')}`);
					}

					that.$nextTick(() => {
						window.dispatchEvent(new Event('resize'));
					});
				});

				socket.on('use_joker', function (data) {
					that.kqState.players = that.kqState.players.map((it) => {
						if (it.uID == data.uID) it.joker = data.joker;
						return it;
					});
					that.kqState.state = 'showHint';

					// prettier-ignore
					if(data.uID == that.$global.getUser().uID) that.$global.showToast('success', `${that.$t('gspUsedJokerSuccessfully')} (${data.joker} ${that.$t('gspLeft')})`);
					else {
						let player = that.players.filter((it)=> it.uID == data.uID)[0]
						if(player) that.$global.showToast('info',`${player.name } ${that.$t('gspUsedAJokerSuccessfully')} (${data.joker} ${that.$t('gspLeft')})`);
					}

					that.$nextTick(() => {
						window.dispatchEvent(new Event('resize'));
					});
				});

				socket.on('restore_joker', function (data) {
					that.kqState.players = that.kqState.players.map((it) => {
						if (it.uID == data.uID) it.joker = data.joker;
						return it;
					});
					that.kqState.state = 'showQuestion';

					that.$global.showToast(
						that.$global.getUser().uID == data.uID ? 'success' : 'info',
						`${that.$t('gspJokerWasRestored')} (${data.joker} ${that.$t('gspLeft')})`
					);

					that.$nextTick(() => {
						window.dispatchEvent(new Event('resize'));
					});
				});

				socket.on('correct_answer_kq', function (data) {
					let show = that.quizNight.configs.filter((show) => show.show == 'kq')[0];

					that.kqState.usedQuestions = that.kqState.usedQuestions.map((it) => {
						if (it.uID == data.uID && it.qID == data.qID && it.level == data.level) it.answered = 'correct';
						return it;
					});

					that.kqState.state = null;
					that.kqState.selectedQuestion = null;

					let player = that.players.filter((player) => player.uID == data.uID)[0];
					// prettier-ignore
					if (data.uID != that.$global.getUser().uID)
						that.$global.showToast('info',`${player.name} ${that.$t('gspAnsweredCorrect')}`);
					else that.$global.showToast('success', `${that.$t('gspAnswerWasCorrect')} (${show.pointsPerLevel[data.level].points} ${that.$t('gspPointsAbbrv')})`);

					let maxQuestionsPerPlayer = that.kqState.multipleAnswers
						? Math.floor((show.questions.length * show.pointsPerLevel.length) / that.players.length)
						: show.questions.length;

					let availablePlayers = [];

					that.players.forEach((player) => {
						if (that.kqState.usedQuestions.filter((it) => it.uID == player.uID).length < maxQuestionsPerPlayer)
							availablePlayers.push(player);
					});

					if (availablePlayers.length == 0) {
						that.kqState.state = 'noMoreQuestions';
						that.kqState.currentPlayer = null;
						that.$global.showToast('info', that.$t('gspShowFinished'));
					} else {
						let nextPlayer = that.getNextKQPlayer(that.kqState.usedQuestions, maxQuestionsPerPlayer, data.uID);
						that.kqState.currentPlayer = nextPlayer.uID;
						if (nextPlayer.uID != that.$global.getUser().uID)
							that.$global.showToast('info', `${nextPlayer.name} ${that.$t('gspIsTheNextPlayer')}`);
						else that.$global.showToast('info', that.$t('gspYouAreTheNextPlayer'));
					}

					that.$nextTick(() => {
						window.dispatchEvent(new Event('resize'));
					});
				});

				socket.on('wrong_answer_kq', function (data) {
					let show = that.quizNight.configs.filter((show) => show.show == 'kq')[0];

					that.kqState.usedQuestions = that.kqState.usedQuestions.map((it) => {
						if (it.uID == data.uID && it.qID == data.qID && it.level == data.level) it.answered = 'wrong';
						return it;
					});

					that.kqState.state = null;
					that.kqState.selectedQuestion = null;

					let player = that.players.filter((player) => player.uID == data.uID)[0];
					// prettier-ignore
					if (data.uID != that.$global.getUser().uID)
						that.$global.showToast('info',`${player.name} ${that.$t('gspAnsweredWrong')}`);
					else that.$global.showToast('error', that.$t('gspAnswerWasWrong'));

					let maxQuestionsPerPlayer = that.kqState.multipleAnswers
						? Math.floor((show.questions.length * show.pointsPerLevel.length) / that.players.length)
						: show.questions.length;

					let availablePlayers = [];

					that.players.forEach((player) => {
						if (that.kqState.usedQuestions.filter((it) => it.uID == player.uID).length < maxQuestionsPerPlayer)
							availablePlayers.push(player);
					});

					if (availablePlayers.length == 0) {
						that.kqState.state = 'noMoreQuestions';
						that.kqState.currentPlayer = null;
						that.$global.showToast('info', that.$t('gspShowFinished'));
					} else {
						let nextPlayer = that.getNextKQPlayer(that.kqState.usedQuestions, maxQuestionsPerPlayer, data.uID);
						that.kqState.currentPlayer = nextPlayer.uID;
						if (nextPlayer.uID != that.$global.getUser().uID)
							that.$global.showToast('info', `${nextPlayer.name} ${that.$t('gspIsTheNextPlayer')}`);
						else that.$global.showToast('info', that.$t('gspYouAreTheNextPlayer'));
					}

					that.$nextTick(() => {
						window.dispatchEvent(new Event('resize'));
					});
				});

				socket.on('show_category', function (data) {
					that.kqState.selectedQuestion = {
						uID: null,
						qID: data.cell[1].qID,
						level: data.cell[0].level,
					};
					that.kqState.state = 'revealQuestion';
				});
			});
		},
		getNextKQPlayer(usedQuestions, maxQuestionsPerPlayer, uID) {
			let currentPlayerIdx = this.players.findIndex((player) => player.uID == uID);
			let nextPlayer = null;
			if (currentPlayerIdx == this.players.length - 1) nextPlayer = this.players[0];
			else nextPlayer = this.players[currentPlayerIdx + 1];

			let playedQuestions = usedQuestions.filter((it) => it.uID == nextPlayer.uID).length;

			if (playedQuestions < maxQuestionsPerPlayer) return nextPlayer;
			else return this.getNextKQPlayer(usedQuestions, maxQuestionsPerPlayer, nextPlayer.uID);
		},
		compareSelectedQuestion(first, second) {
			return first.uID == second.uID && first.level == second.level && first.qID == second.qID;
		},
		reconstructState() {
			let show = this.configuredShows.filter((show) => show.show == this.lobby.session.state)[0];
			if (show) {
				if (['dpin', 'smf', 'wlp', 'xfz'].includes(show.show)) {
					let lastQuestions = this.lobby.history.filter((his) => his.action == 'setQuestion');
					let quest = show.questions.filter((quest) => quest.qID == lastQuestions[lastQuestions.length - 1].result)[0];
					if (show.show == 'wlp') this.reconstructWLP(show, quest);
					else if (['dpin', 'smf'].includes(show.show)) this.reconstructFreetextQuestion(show, quest);
					else if (['xfz', 'smf'].includes(show.show) && ['Freetext', 'SingleChoice'].includes(quest.type))
						this.reconstructFreetextQuestion(show, quest);
					else if (['xfz', 'smf'].includes(show.show) && ['Buzzer', 'BuzzerSingleChoice'].includes(quest.type))
						this.reconstructBuzzerQuestion(show, quest);

					if (show.show == 'smf') this.reconstructSMF(show, quest);
				} else if (show.show == 'zgw') this.reconstructZGW(show);
				else if (show.show == 'kq') this.reconstructKQ(show);
			}
		},
		reconstructFreetextQuestion(show, quest) {
			if (!this.$global.isHost()) {
				let uID = this.$global.getUser().uID;
				let his = this.lobby.history.filter((his) => his.qID == quest.qID && his.uID == uID && his.action == 'setAnswer')[0];
				if (his) {
					this.ownAnswer = {
						lockAnswer: true,
						textAnswer: his.result,
						uID: uID,
					};
				} else {
					this.ownAnswer = {
						lockAnswer: false,
						textAnswer: '',
						uID: uID,
					};
				}
			} else {
				let his = this.lobby.history.filter((his) => his.qID == quest.qID && his.action == 'setAnswer');
				this.players.forEach((player) => {
					let entry = his.filter((entry) => entry.uID == player.uID)[0];
					if (entry) {
						this.$set(this.givenAnswers, entry.uID, {
							lockAnswer: true,
							textAnswer: entry.result,
							uID: entry.uID,
						});
					} else {
						this.$set(this.givenAnswers, player.uID, {
							lockAnswer: false,
							textAnswer: '',
							uID: player.uID,
						});
					}
				});
			}
		},
		reconstructBuzzerQuestion(show, quest) {
			if (!this.$global.isHost()) this.socket.emit('get_buzzer_state', { host: this.host.uID, sID: this.$route.params.sID });
			else this.socket.emit('unlock_buzzer', this.$route.params.sID);
		},
		reconstructWLP(show, quest) {
			let his = this.lobby.history.filter((his) => his.gID == show.gID);
			let users = [];
			let bids = [];
			let items = [];

			this.players.forEach((player) => {
				// Set bids of players
				let playerBids = his.filter((entry) => entry.action == 'setBid' && entry.qID == quest.qID && entry.uID == player.uID);
				if (playerBids.length > 0) {
					let highestBid = playerBids.reduce((max, bid) => (Number(max.result) > Number(bid.result) ? max : bid));
					bids.push({ uID: player.uID, bid: Number(highestBid.result), qID: quest.qID });
				} else bids.push({ uID: player.uID, bid: 0, qID: quest.qID });

				// Set player money and items
				let playerMoney = show.money;
				let playerMoneyActions = his.filter((entry) => ['highestBid', 'setMoney'].includes(entry.action));
				playerMoneyActions.forEach((mAction) => {
					if (mAction.action == 'highestBid' && mAction.result == player.uID) {
						items.push({ qID: mAction.qID, bid: Number(mAction.info), uID: player.uID });
						playerMoney -= Number(mAction.info);
					} else if (mAction.action == 'setMoney' && mAction.info == player.uID) playerMoney = Number(mAction.result);
				});

				// Lock or unlock player
				let playerLocked = his.filter(
					(entry) =>
						((entry.action == 'lockBid' && entry.uID == player.uID) || (entry.action == 'unlockBid' && entry.result == player.uID)) &&
						entry.qID == quest.qID
				);

				if (playerLocked.length == 0) playerLocked = false;
				else {
					let lastEntry = playerLocked[playerLocked.length - 1];
					if (lastEntry.action == 'lockBid') playerLocked = true;
					else playerLocked = false;
				}

				users.push({ uID: player.uID, money: playerMoney, disableBid: true, lockBid: playerLocked, order: player.order });
			});

			// Reconstruct the reveal state after all items are sold
			let revealState = [];
			let reveals = his.filter((h) => ['showReveal', 'revealItem', 'revealMoney'].includes(h.action));
			if (reveals.length !== 0) {
				let position = 0;
				reveals.forEach((rev) => {
					if (rev.action != 'showReveal') {
						revealState.push({
							type: rev.action == 'revealItem' ? 'ITEM' : 'MONEY',
							qID: rev.qID,
							uID: rev.info,
							position: position,
							diff: Number(rev.result),
						});
						position += 1;
					}
				});

				bids.map((bid) => {
					bid.bid = 0;
					return bid;
				});
			} else revealState = null;

			// Enable one player to bid
			let lastStates = his.filter(
				(entry) => ['startBidder', 'setBid', 'lockBid', 'highestBid', 'setCurrentBidder'].includes(entry.action) && entry.qID == quest.qID
			);
			let lastState = lastStates[lastStates.length - 1];

			let lastUserUID = null;
			let highestBidder = null;
			if (['setBid', 'lockBid'].includes(lastState.action)) {
				lastUserUID = lastState.uID;

				if (lastState.action == 'setBid') {
					let bid = bids.filter((bid) => bid.uID == lastState.uID && bid.qID == quest.qID)[0];
					if (bid) highestBidder = { uID: lastState.uID, bid: Number(bid.bid) };
				} else {
					let lastBids = lastStates.filter((entry) => entry.action == 'setBid');
					if (lastBids.length > 0) {
						let highestBid = lastBids.reduce((max, entry) => (entry.num > max.num ? entry : max));
						if (highestBid) highestBidder = { uID: highestBid.uID, bid: Number(highestBid.result) };
					}
				}
			}

			if (lastUserUID) {
				let availablePlayers = this.getAvailableWLPBidders(users, lastUserUID, highestBidder, null, true);
				if (availablePlayers.length > 0) this.enableNextWLPBidder(users, lastUserUID, availablePlayers);
			} else if (['startBidder', 'setCurrentBidder'].includes(lastState.action)) {
				let nextBidderIdx = users.findIndex((user) => user.uID == lastState.result);
				users[nextBidderIdx].disableBid = false;
			} else if (lastState.action == 'highestBid') {
				bids.map((bid) => {
					if (bid.uID == lastState.result) bid.bid = Number(lastState.info);
					return bid;
				});
			}

			this.wlpState = {
				users: users,
				bids: bids,
				items: items,
				revealState: revealState,
			};

			this.$nextTick(() => {
				window.dispatchEvent(new Event('resize'));
			});
		},
		reconstructKQ(show) {
			let players = [];
			let currentPlayer = null;
			let selQ = null;
			let usedQuestions = [];
			let state = null;

			let his = this.lobby.history.filter((his) => his.gID == show.gID);
			let jokerEvents = his.filter((h) => ['useJoker', 'restoreJoker'].includes(h.action));

			this.players.forEach((player) => {
				let amountJoker =
					show.amountJoker -
					jokerEvents.filter((je) => je.action == 'useJoker' && je.uID == player.uID).length +
					jokerEvents.filter((je) => je.action == 'restoreJoker' && je.result == player.uID).length;

				players.push({
					uID: player.uID,
					joker: amountJoker,
				});
			});

			let questionEvents = his.filter((h) =>
				['selectCategory', 'removeCategory', 'assignCategory', 'correctAnswerKQ', 'wrongAnswerKQ'].includes(h.action)
			);
			questionEvents.forEach((qe) => {
				if (qe.action == 'selectCategory') {
					selQ = {
						uID: qe.uID,
						qID: qe.result,
						level: qe.info,
					};

					usedQuestions.push({ ...selQ, answered: null });
				} else if (qe.action == 'assignCategory') {
					selQ = {
						uID: qe.info,
						qID: qe.result,
						level: qe.info2,
					};
					usedQuestions.push({ ...selQ, answered: null });
				} else if (qe.action == 'removeCategory') {
					usedQuestions = usedQuestions.filter((quest) => !(quest.uID == qe.info2 && quest.qID == qe.result && quest.level == qe.info));
					if (selQ.uID == qe.info2 && selQ.qID == qe.result && selQ.level == qe.info) selQ = null;
				} else if (['correctAnswerKQ', 'wrongAnswerKQ'].includes(qe.action)) {
					selQ = null;
					usedQuestions = usedQuestions.map((it) => {
						if (it.uID == qe.info && it.qID == qe.info2 && it.level == qe.result)
							it.answered = qe.action == 'correctAnswerKQ' ? 'correct' : 'wrong';
						return it;
					});
				}
			});

			// Loop in reverse order to find the last entry that mentions a user. This user will be the current active player
			for (let i = his.length - 1; i >= 0; i--) {
				if (['setStarter', 'restoreJoker'].includes(his[i].action)) currentPlayer = his[i].result;
				else if (['selectCategory', 'useJoker'].includes(his[i].action)) currentPlayer = his[i].uID;
				else if (his[i].action == 'assignCategory') currentPlayer = his[i].info;
				else if (his[i].action == 'removeCategory') currentPlayer = his[i].info2;
				else if (['correctAnswerKQ', 'wrongAnswerKQ'].includes(his[i].action)) {
					let maxQuestionsPerPlayer = show.multipleAnswersPerCat
						? Math.floor((show.questions.length * show.pointsPerLevel.length) / this.players.length)
						: show.questions.length;

					let availablePlayers = [];

					this.players.forEach((it) => {
						if (usedQuestions.filter((quest) => quest.uID == it.uID).length < maxQuestionsPerPlayer) availablePlayers.push(it);
					});

					if (availablePlayers.length == 0) {
						state = 'noMoreQuestions';
						currentPlayer = null;
						this.$global.showToast('info', this.$t('gspShowFinished'));
					} else {
						// let currentPlayerIdx = this.players.findIndex((it) => it.uID == his[i].info);
						// let nextPlayer = null;
						// if (currentPlayerIdx == this.players.length - 1) nextPlayer = this.players[0];
						// else nextPlayer = this.players[currentPlayerIdx + 1];
						let nextPlayer = this.getNextKQPlayer(usedQuestions, maxQuestionsPerPlayer, his[i].info);
						currentPlayer = nextPlayer.uID;

						if (nextPlayer.uID != this.$global.getUser().uID)
							this.$global.showToast('info', `${nextPlayer.name} ${this.$t('gspIsTheNextPlayer')}`);
						else this.$global.showToast('info', this.$t('gspYouAreTheNextPlayer'));
					}
				}

				break;
			}

			let starter = his.filter((h) => h.action == 'setStarter')[0];
			if (his.length == 2 && starter) {
				this.$global.showToast(
					'info',
					this.$global.getUser().uID == starter.result
						? this.$t('gspYouCanSelectCategory')
						: `${this.players.filter((player) => player.uID == starter.result)[0].name} ${this.$t('gspCanSelectCategory')}`
				);
			}

			this.kqState = {
				state: state ? state : selQ ? (his[his.length - 1].action == 'useJoker' ? 'showHint' : 'showQuestion') : null,
				multipleAnswers: show.multipleAnswersPerCat,
				players: players,
				currentPlayer: currentPlayer,
				selectedQuestion: selQ,
				usedQuestions: usedQuestions,
			};
		},
		reconstructSMF(show, quest) {
			let his = this.lobby.history.filter((his) => his.gID == show.gID && ['setQuestion', 'correctAnswer'].includes(his.action));
			if (his[his.length - 1].action == 'correctAnswer') this.showSolutionImage = true;
		},
		reconstructZGW(show) {
			let his = this.lobby.history.filter(
				(his) =>
					his.gID == show.gID &&
					['assignTopic', 'lockTopicTerms', 'assignTopicGuessing', 'guessingTime', 'setAnswers', 'topicSummary'].includes(his.action)
			);
			let lastEntry = his[his.length - 1];
			if (!lastEntry) {
				this.zgwState.phase = 'assignTopic';
			} else if (lastEntry.action == 'assignTopic') {
				this.zgwState.selectedCreator = this.players.filter((player) => player.uID == lastEntry.info)[0];
				this.zgwState.selectedTopic = show.questions.filter((quest) => quest.qID == lastEntry.result)[0];
				let proposedTopicTerms = sessionStorage.getItem('proposedTopicTerms');
				if (proposedTopicTerms) this.zgwState.proposedTopicTerms = JSON.parse(proposedTopicTerms);
				this.zgwState.phase = 'lockTopicTerms';
			} else {
				sessionStorage.removeItem('proposedTopicTerms');
				let lastAssign = null;
				for (let i = his.length - 1; i >= 0; i--) {
					if (his[i].action == 'assignTopic') {
						lastAssign = his[i];
						break;
					}
				}
				if (lastAssign) {
					this.zgwState.selectedCreator = this.players.filter((player) => player.uID == lastAssign.info)[0];
					this.zgwState.selectedTopic = show.questions.filter((quest) => quest.qID == lastAssign.result)[0];
					if (lastEntry.action == 'lockTopicTerms') {
						this.zgwState.proposedTopicTerms = lastEntry.result.split(';TERM;');
						this.zgwState.phase = 'assignTopicGuessing';
					} else {
						let lastTopicSet = null;
						for (let i = his.length - 1; i >= 0; i--) {
							if (his[i].action == 'lockTopicTerms') {
								lastTopicSet = his[i];
								break;
							}
						}

						if (lastTopicSet) {
							this.zgwState.proposedTopicTerms = lastTopicSet.result.split(';TERM;');
							if (lastEntry.action == 'setAnswers') {
								this.zgwState.phase = 'assignTopicGuessing';
								if (this.$global.isHost()) {
									let acceptedTopicTerms = sessionStorage.getItem('acceptedTopicTerms');
									if (acceptedTopicTerms) this.zgwState.acceptedTopicTerms = JSON.parse(acceptedTopicTerms);
								}
							} else {
								this.zgwState.selectedGuesser = this.players.filter((player) => player.uID == lastEntry.info)[0];
								if (lastEntry.action == 'assignTopicGuessing') {
									this.zgwState.phase = 'assignTopicGuessing';
								} else if (lastEntry.action == 'guessingTime') {
									this.zgwState.phase = 'guessingTime';
									if (this.$global.isHost()) {
										let timeLeft = sessionStorage.getItem('timeLeft');
										let show = this.configuredShows.filter((show) => show.show == this.lobby.session.state)[0];
										let acceptedTopicTerms = sessionStorage.getItem('acceptedTopicTerms');
										if (acceptedTopicTerms) {
											this.zgwState.acceptedTopicTerms = JSON.parse(acceptedTopicTerms);
											this.updateTimer({ timeLeft: timeLeft ? Number(timeLeft) : show.timer, state: 'INITIAL' });
										} else this.updateTimer({ timeLeft: timeLeft ? Number(timeLeft) : show.timer, state: 'STOPED' });
									}
								} else if (lastEntry.action == 'topicSummary') {
									sessionStorage.removeItem('timeLeft');
									sessionStorage.removeItem('acceptedTopicTerms');
									this.zgwState.phase = 'topicSummary';
									let revealedTerms = this.lobby.history.filter(
										(h) => h.gID == show.gID && h.action == 'revealTopicTerm' && h.qID == this.zgwState.selectedTopic.qID
									);
									let i = 0;
									if (!this.$global.isHost()) {
										let interval = window.setInterval(() => {
											if (revealedTerms[i]) {
												this.zgwState.revealedTopicTerms.push(revealedTerms[i].result);
											} else clearInterval(interval);
											i += 1;
										}, 250);
									} else this.zgwState.revealedTopicTerms = revealedTerms.map((term) => term.result);
								}
							}
						} else that.$global.showToast('error', that.$t('gspZGWReconstructError'));
					}
				} else that.$global.showToast('error', that.$t('gspZGWReconstructError'));
			}
			this.$nextTick(() => {
				window.dispatchEvent(new Event('resize'));
			});
		},
		giveTextAnswer(data) {
			this.ownAnswer = data;
			this.socket.emit('text_answer', data, this.host.uID);

			if (data.lockAnswer) {
				let that = this;
				let sID = this.$route.params.sID;
				let show = this.configuredShows.filter((show) => show.show == this.lobby.session.state)[0];
				let lastQuestions = this.lobby.history.filter((his) => his.action == 'setQuestion');
				let quest = show.questions.filter((quest) => quest.qID == lastQuestions[lastQuestions.length - 1].result)[0];

				this.$global.addHistoryEntry(sID, data.uID, show.gID, quest.qID, 'setAnswer', '', null, data.textAnswer, function (err) {
					if (err) that.$global.showToast('error', that.$t('gspLockAnswerError'));
				});
			}
		},
		buzzerPressed() {
			this.socket.emit('buzzer_pressed', { uID: this.$global.getUser().uID, host: this.host.uID });
		},

		unlockAnswer(uID) {
			let that = this;
			let lastQuestions = this.lobby.history.filter((his) => his.action == 'setQuestion');
			let show = this.configuredShows.filter((show) => show.show == this.lobby.session.state)[0];
			let quest = show.questions.filter((quest) => quest.qID == lastQuestions[lastQuestions.length - 1].result)[0];
			let his = this.lobby.history.filter((his) => his.qID == quest.qID && his.uID == uID && his.action == 'setAnswer')[0];
			if (his) {
				this.$global.deleteHistoryEntry(his.hID, function (err) {
					if (err) that.$global.showToast('error', that.$t('gspLockAnswerError'));
					else {
						that.socket.emit('unlock_answer', uID);
						that.givenAnswers[uID].lockAnswer = false;
					}
				});
			}
		},
		unlockBuzzer() {
			this.pressedBuzzer = null;
			this.buzzerUser = null;
			this.unlockSound.volume = 0.5;
			this.unlockSound.play();
			this.socket.emit('unlock_buzzer', this.$route.params.sID);
		},
		rightBuzzer(uID) {
			let that = this;
			let sID = this.$route.params.sID;
			let show = this.configuredShows.filter((show) => show.show == this.lobby.session.state)[0];
			let lastQuestions = this.lobby.history.filter((his) => his.action == 'setQuestion');
			let quest = show.questions.filter((quest) => quest.qID == lastQuestions[lastQuestions.length - 1].result)[0];
			// prettier-ignore
			this.$global.addHistoryEntry(sID, this.host.uID, show.gID, quest.qID, 'rightBuzzer', uID, null, show.pointsPos, function (err) {
				if (err) that.$global.showToast('error', that.$t('gspRightBuzzerError'));
				else {
					that.updateLeaderboard({ uID: uID, points: show.pointsPos, sID: that.$route.params.sID }, function () {
						that.socket.emit('right_buzzer', { uID: uID, points: show.pointsPos,  sID: that.$route.params.sID });
						that.rightSound.volume = 0.5;
						that.rightSound.play();
					});
				}
			});
		},
		wrongBuzzer(uID) {
			let that = this;
			let sID = this.$route.params.sID;
			let show = this.configuredShows.filter((show) => show.show == this.lobby.session.state)[0];
			let lastQuestions = this.lobby.history.filter((his) => his.action == 'setQuestion');
			let quest = show.questions.filter((quest) => quest.qID == lastQuestions[lastQuestions.length - 1].result)[0];
			// prettier-ignore
			this.$global.addHistoryEntry(sID, this.host.uID, show.gID, quest.qID, 'wrongBuzzer', uID, null, show.pointsNeg, function (err) {
				if (err) that.$global.showToast('error', that.$t('gspWrongBuzzerError'));
				else {
					let players = that.players.filter((player) => player.uID != uID);
					let successCallbacks = 0
					players.forEach((player) => {
						that.updateLeaderboard({ uID: player.uID, points: show.pointsNeg, sID: that.$route.params.sID }, function () {
							successCallbacks += 1
						});
					});
					let intervals = 0
					let interval = window.setInterval(()=>{
						// Clear interval if all callbacks arrived or after 10s
						if(successCallbacks == players.length || intervals >= 100){
							clearInterval(interval)
							that.socket.emit('wrong_buzzer', { uID: uID, points: show.pointsNeg, sID: that.$route.params.sID });
						}
						intervals += 1
					},100)
				}
			});
		},
		updateLeaderboard(update, cb) {
			let that = this;
			this.$global.patchData(
				'session',
				'/updateLeaderboard',
				{ uID: this.$global.getUser().uID, update: update },
				{
					headers: { pageauthheader: this.$global.getAccessCode() },
					auth: this.$global.getCredentials(),
				},
				(err, data) => {
					if (err) that.$global.showToast('error', that.$t(err.response ? err.response.data.msg : 'gspLeaderboardUpdateError'));
					else if (cb) cb();
				}
			);
		},
		toggleSolution() {
			this.socket.emit('toggle_solution', { sID: this.$route.params.sID, toggle: !this.showSolutionImage });
		},
		sendBid(bid) {
			let that = this;
			let sID = this.$route.params.sID;
			let show = this.configuredShows.filter((show) => show.show == this.lobby.session.state)[0];
			// prettier-ignore
			this.$global.addHistoryEntry(sID, this.$global.getUser().uID, show.gID, bid.qID, 'setBid', null, null, bid.bid, function (err) {
				if (err) that.$global.showToast('error', that.$t('gspSetBidError'));
				else {
					that.socket.emit('set_bid', { sID: sID, ...bid, host: that.host.uID });
				}
			});
		},
		lockBid(lock) {
			let that = this;
			let sID = this.$route.params.sID;
			let show = this.configuredShows.filter((show) => show.show == this.lobby.session.state)[0];
			// prettier-ignore
			this.$global.addHistoryEntry(sID, this.$global.getUser().uID, show.gID, lock.qID, 'lockBid', null, null, null, function (err) {
				if (err) that.$global.showToast('error', that.$t('gspLockBidError'));
				else {
					that.socket.emit('lock_bid', { sID: sID, ...lock, host: that.host.uID });
				}
			});
		},
		unlockBid(unlock) {
			let that = this;
			let sID = this.$route.params.sID;
			let show = this.configuredShows.filter((show) => show.show == this.lobby.session.state)[0];
			// prettier-ignore
			this.$global.addHistoryEntry(sID, this.$global.getUser().uID, show.gID, unlock.qID, 'unlockBid', null, null, unlock.uID, function (err) {
				if (err) that.$global.showToast('error', that.$t('gspUnlockBidError'));
				else {
					that.socket.emit('unlock_bid', { sID: sID, ...unlock, host: that.host.uID });
				}
			});
		},
		setCurrentBidder(data) {
			let that = this;
			let sID = this.$route.params.sID;
			let show = this.configuredShows.filter((show) => show.show == this.lobby.session.state)[0];

			try {
				let user = this.wlpState.users.filter((user) => user.uID == data.uID)[0];
				let highestBidder = this.wlpState.bids.reduce((max, bid) => (bid.bid > max.bid ? bid : max));
				let item = this.wlpState.items.filter((item) => item.qID == data.qID)[0];

				if (user.lockBid) throw 'gspUserLockedError';
				else if (!user.disableBid) throw 'gspUserCanAlreadyBidError';
				else if (highestBidder.uID == data.uID) throw 'gspUserAlreadyHighestBidderError';
				else if (user.money < highestBidder.bid) throw 'gspNotEnoughMoneyError';
				else if (item) throw 'gspItemAlreadySoldError';
				else {
					// prettier-ignore
					that.$global.addHistoryEntry(sID, that.$global.getUser().uID, show.gID, data.qID, 'setCurrentBidder', null, null, data.uID, function (err) {
						if (err) that.$global.showToast('error', that.$t('gspCurrentBidderError'));
						else that.socket.emit('set_current_bidder', { sID: sID, ...data });
					});
				}
			} catch (error) {
				that.$global.showToast('warn', `${that.$t(error)}`);
			}
		},
		assignItem(data) {
			let that = this;
			let sID = this.$route.params.sID;
			let show = this.configuredShows.filter((show) => show.show == this.lobby.session.state)[0];

			// prettier-ignore
			that.$global.deleteHistoryEntry(data.hID, function (err) {
				if (err) that.$global.showToast('error', that.$t('gspDeleteBidError'));
				else {
					that.$global.addHistoryEntry(sID, that.$global.getUser().uID, show.gID, data.qID, 'highestBid', data.bid, null, data.uID, function (err) {
						if (err) that.$global.showToast('error', that.$t('gspHighestBidError'));
						else {
							that.socket.emit('assign_item', { sID: sID, ...data, host: that.host.uID });
						}
					});
				}
			});
		},
		assignCurrentItem(data) {
			let that = this;
			let sID = this.$route.params.sID;
			let show = this.configuredShows.filter((show) => show.show == this.lobby.session.state)[0];
			that.$global.addHistoryEntry(sID, that.host.uID, show.gID, data.qID, 'highestBid', data.bid, null, data.uID, function (err) {
				if (err) that.$global.showToast('error', that.$t('gspHighestBidError'));
				else {
					that.socket.emit('highest_bid', { sID: sID, isAssignment: true, ...data, host: that.host.uID });
				}
			});
		},
		setMoney(data) {
			let that = this;
			let sID = this.$route.params.sID;
			let show = this.configuredShows.filter((show) => show.show == this.lobby.session.state)[0];
			// prettier-ignore
			this.$global.addHistoryEntry(sID, this.$global.getUser().uID, show.gID, data.qID, 'setMoney', data.uID, null, data.money, function (err) {
				if (err) that.$global.showToast('error', that.$t('gspSetMoneyError'));
				else {
					that.socket.emit('set_money', { sID: sID, ...data, host: that.host.uID });
				}
			});
		},
		setNextBidder(uID, highestBidder, qID) {
			let that = this;
			let sID = this.$route.params.sID;
			let show = this.configuredShows.filter((show) => show.show == this.lobby.session.state)[0];

			let lastBidderIdx = this.wlpState.users.findIndex((user) => user.uID == uID);
			this.wlpState.users[lastBidderIdx].disableBid = true;

			let availablePlayers = this.getAvailableWLPBidders(this.wlpState.users, uID, highestBidder, qID, false);

			if (availablePlayers.length > 0) this.enableNextWLPBidder(this.wlpState.users, uID, availablePlayers);
			// No available players which means that the current highest bidder gets the item
			else {
				window.setTimeout(() => {
					// prettier-ignore
					this.$global.addHistoryEntry(sID, this.$global.getUser().uID, show.gID, qID, 'highestBid', highestBidder.bid, null, highestBidder.uID, function (err) {
						if (err) that.$global.showToast('error', that.$t('gspHighestBidError'));
						else {
							that.socket.emit('highest_bid', { sID: sID, uID: highestBidder.uID,	qID: qID, host: that.host.uID });
						}
					});
				}, 100);
			}
		},
		getAvailableWLPBidders(users, lastUserUID, highestBidder, qID, duringReconstruct) {
			let availablePlayers = [];
			if (highestBidder) {
				availablePlayers = users
					.filter((player, idx) => {
						// Get all available players to bid and sort them by their order. Available players need to match the following criteria:
						// - The available player isn't the player that either locked or set a bid
						// - The available player isn't the highest bidder already
						// - The available player has more money than the highest bid
						// - The available player isn't already locked
						if (player.uID != lastUserUID && player.uID != highestBidder.uID && player.money > highestBidder.bid && !player.lockBid)
							return true;
						// If a player matches all criteria from above except the following then the player should be locked as well because hes broke
						// - The available player doesn't have more money than the highest bid
						else if (
							player.uID != lastUserUID &&
							player.uID != highestBidder.uID &&
							player.money <= highestBidder.bid &&
							!player.lockBid
						) {
							users[idx].lockBid = true;

							if (!duringReconstruct && this.$global.isHost()) {
								let that = this;
								let sID = this.$route.params.sID;
								let show = this.configuredShows.filter((show) => show.show == this.lobby.session.state)[0];

								this.$global.addHistoryEntry(sID, player.uID, show.gID, qID, 'lockBid', null, null, null, function (err) {
									if (err) that.$global.showToast('error', that.$t('gspLockBidError'));
								});
							}

							return false;
						} else return false;
					})
					.sort((a, b) => a.order - b.order);
			} else {
				availablePlayers = users
					.filter((player, idx) => {
						// Get all available players to bid and sort them by their order. Available players need to match the following criteria:
						// - The available player isn't the last bidder
						// - The available player isn't already locked
						if (player.uID != lastUserUID && !player.lockBid) return true;
						else return false;
					})
					.sort((a, b) => a.order - b.order);
			}

			return availablePlayers;
		},
		enableNextWLPBidder(users, lastUserUID, availablePlayers) {
			let lastBidderIdx = users.findIndex((user) => user.uID == lastUserUID);
			let lastBidderOrder = Number(users[lastBidderIdx].order);
			let nextBidderOrder = 0;

			// If the last order was 0 then the next one needs to be higher which would be the first of the available players
			if (lastBidderOrder == 0) nextBidderOrder = availablePlayers[0].order;
			else {
				let availablePlayerOrders = availablePlayers.map((player) => player.order);
				let higherOrders = availablePlayerOrders.filter((order) => order > lastBidderOrder);

				// If a higher order exists then it would be the first of the higher orders
				if (higherOrders.length > 0) nextBidderOrder = higherOrders[0];
				else {
					let lowerOrders = availablePlayerOrders.filter((order) => order < lastBidderOrder);
					// If no higher orders exist then it would be the first of the lower orders
					nextBidderOrder = lowerOrders[0];
				}
			}

			// Enable the next bidder
			let nextBidderIdx = users.findIndex((user) => user.order == nextBidderOrder);
			users[nextBidderIdx].disableBid = false;
		},
		setPoints(data) {
			let that = this;
			let sID = this.$route.params.sID;
			let show = this.configuredShows.filter((show) => show.show == this.lobby.session.state)[0];
			data.selectedPlayers.forEach((uID) => {
				// prettier-ignore
				this.$global.addHistoryEntry(sID, this.host.uID, show.gID, null, data.mode, uID, null, data.points, function (err) {
					if (err) that.$global.showToast('error', that.$t('gspSetPointsError'));
					else {
						that.updateLeaderboard({ ...data,uID: uID, sID: that.$route.params.sID }, function () {
							that.socket.emit('set_points', { ...data,uID: uID, sID: that.$route.params.sID });
						});
					}
				});
			});
		},
		revealItem(data) {
			let that = this;
			let sID = this.$route.params.sID;
			let show = this.configuredShows.filter((show) => show.show == this.lobby.session.state)[0];
			if (data.type == 'ITEM') {
				// prettier-ignore
				this.$global.addHistoryEntry(sID, this.$global.getUser().uID, show.gID, data.qID, 'revealItem', data.uID, null, data.diff, function (err) {
					if (err) that.$global.showToast('error', that.$t('gspRevealItemError'));
					else {
						that.socket.emit('reveal_item', { ...data, sID: that.$route.params.sID });
					}
				});
			} else if (data.type == 'SKIP') {
				// prettier-ignore
				this.$global.addHistoryEntry(sID, this.$global.getUser().uID, show.gID, data.qID, 'revealSkip', null, null, null, function (err) {
					if (err) that.$global.showToast('error', that.$t('gspRevealItemError'));
					else {
						that.socket.emit('reveal_item', { ...data, sID: that.$route.params.sID });
					}
				});
			} else if (data.type == 'MONEY') {
				// prettier-ignore
				this.$global.addHistoryEntry(sID, this.$global.getUser().uID, show.gID, data.qID, 'revealMoney', data.uID, null, data.diff, function (err) {
					if (err) that.$global.showToast('error', that.$t('gspRevealItemError'));
					else {
						that.socket.emit('reveal_item', { ...data, sID: that.$route.params.sID });
					}
				});
			}
		},
		revealAllItems(data) {
			let i = 0;
			let interval = window.setInterval(() => {
				if (i == data.length) clearInterval(interval);
				else {
					let d = data[i];
					this.revealItem(d);
					i += 1;
				}
			}, 1500);
		},
		getWLPPointsDistribution() {
			let squaredUsers = this.wlpState.users.length * this.wlpState.users.length;
			let currentPointDistribution = 1;
			let pointsDistributions = [];

			while (squaredUsers > 0) {
				squaredUsers -= currentPointDistribution;
				pointsDistributions.push(currentPointDistribution);
				currentPointDistribution += 2;
			}

			return pointsDistributions.reverse();
		},
		getWLPUserOrder() {
			let userOrder = [];

			let show = this.configuredShows.filter((show) => show.show == this.lobby.session.state)[0];
			let users = {};
			this.wlpState.users.forEach((user) => {
				let userReveals = this.wlpState.revealState.filter((reveal) => reveal.uID == user.uID);
				let userMoney = show.money;
				userReveals.forEach((reveal) => (userMoney += reveal.diff));

				if (users[userMoney]) users[userMoney].push(user.uID);
				else users[userMoney] = [user.uID];
			});
			let groups = Object.keys(users).sort((a, b) => Number(b) - Number(a));
			groups.forEach((group) => {
				userOrder.push(users[group]);
			});

			return userOrder;
		},
		computeWLPLeaderboard() {
			let pointsDistributions = this.getWLPPointsDistribution();
			let userOrder = this.getWLPUserOrder();
			let wlpLeaderboard = [];

			userOrder.forEach((place) => {
				place.forEach((uID) => {
					let p = 1;
					for (let i = 0; i < userOrder.length; i++) {
						if (userOrder[i].find((it) => it == uID)) break;
						p += userOrder[i].length;
					}
					wlpLeaderboard.push({
						uID: uID,
						place: p,
						pointsDistribution: pointsDistributions[p - 1] / pointsDistributions[0],
					});
				});
			});

			return wlpLeaderboard;
		},
		awardWLPPoints() {
			let wlpLeaderboard = this.computeWLPLeaderboard();
			let that = this;
			let sID = this.$route.params.sID;
			let successCallbacks = 0;
			let host = this.$global.getUser().uID;
			let show = this.configuredShows.filter((show) => show.show == this.lobby.session.state)[0];

			wlpLeaderboard.forEach((entry) => {
				let points = Math.round(show.pointsWin * entry.pointsDistribution);
				// prettier-ignore
				console.log(`${entry.uID} is place ${entry.place} with share ${entry.pointsDistribution} (${points} points)`)

				this.$global.addHistoryEntry(sID, host, show.gID, null, 'pointsWin', entry.uID, entry.place, points, function (err) {
					if (err) that.$global.showToast('error', that.$t('gspPointsWinError'));
					else {
						that.updateLeaderboard({ uID: entry.uID, points: points, sID: that.$route.params.sID }, function () {
							successCallbacks += 1;
						});
					}
				});
			});

			let intervals = 0;
			let interval = window.setInterval(() => {
				// Clear interval if all callbacks arrived or after 10s
				if (successCallbacks == this.wlpState.users.length || intervals >= 100) {
					clearInterval(interval);
					that.socket.emit('points_win', { wlpLeaderboard: wlpLeaderboard, sID: that.$route.params.sID });
				}
				intervals += 1;
			}, 100);
		},
		correctAnswer(data) {
			let that = this;
			let sID = this.$route.params.sID;
			let show = this.configuredShows.filter((show) => show.show == this.lobby.session.state)[0];
			let lastQuestions = this.lobby.history.filter((his) => his.action == 'setQuestion');
			let quest = show.questions.filter((quest) => quest.qID == lastQuestions[lastQuestions.length - 1].result)[0];
			// prettier-ignore
			this.$global.addHistoryEntry(sID, this.host.uID, show.gID, quest.qID, 'correctAnswer', data.uID, null, data.points, function (err) {
				if (err) that.$global.showToast('error', that.$t('gspCorrectAnswerError'));
				else {
					that.updateLeaderboard({ ...data, sID: that.$route.params.sID }, function () {
						that.socket.emit('correct_answer', { ...data, sID: that.$route.params.sID });
					});
				}
			});
		},
		updateZGWState(update) {
			let propertiesToUpdate = Object.keys(update);
			propertiesToUpdate.forEach((key) => {
				this.zgwState[key] = update[key];
			});
		},
		assignTopic() {
			let that = this;
			let sID = this.$route.params.sID;
			let show = this.configuredShows.filter((show) => show.show == this.lobby.session.state)[0];
			// prettier-ignore
			this.$global.addHistoryEntry(sID, this.$global.getUser().uID, show.gID, this.zgwState.selectedTopic.qID, 'assignTopic', this.zgwState.selectedCreator.uID, null, this.zgwState.selectedTopic.qID, function (err) {
				if (err) that.$global.showToast('error', that.$t('gspAssignTopicError'));
				else that.socket.emit('assign_topic', { ...that.zgwState, sID: that.$route.params.sID });

			});
		},
		assignTopicGuessing(selectedGuesser) {
			this.zgwState.selectedGuesser = selectedGuesser;
			let that = this;
			let sID = this.$route.params.sID;
			let show = this.configuredShows.filter((show) => show.show == this.lobby.session.state)[0];
			// prettier-ignore
			this.$global.addHistoryEntry(sID, this.$global.getUser().uID, show.gID, this.zgwState.selectedTopic.qID, 'assignTopicGuessing', selectedGuesser.uID, null, this.zgwState.selectedTopic.qID, function (err) {
				if (err) that.$global.showToast('error', that.$t('gspAssignTopicGuessingError'));
				else that.socket.emit('assign_topic_guessing', { ...that.zgwState, sID: that.$route.params.sID });

			});
		},
		addTopicTerm(topic) {
			if (this.zgwState.phase == 'lockTopicTerms') {
				this.zgwState.proposedTopicTerms.push(topic);
				this.socket.emit('update_topic_terms', { terms: this.zgwState.proposedTopicTerms, sID: this.$route.params.sID });
				sessionStorage.setItem('proposedTopicTerms', JSON.stringify(this.zgwState.proposedTopicTerms));
				this.$nextTick(() => {
					window.dispatchEvent(new Event('resize'));
				});
			}
		},
		removeTopicTerm(topic) {
			if (this.zgwState.phase == 'lockTopicTerms') {
				this.zgwState.proposedTopicTerms = this.zgwState.proposedTopicTerms.filter((t) => t != topic);
				this.socket.emit('update_topic_terms', { terms: this.zgwState.proposedTopicTerms, sID: this.$route.params.sID });
				sessionStorage.setItem('proposedTopicTerms', JSON.stringify(this.zgwState.proposedTopicTerms));
				this.$nextTick(() => {
					window.dispatchEvent(new Event('resize'));
				});
			}
		},
		lockTerms() {
			let that = this;
			let sID = this.$route.params.sID;
			let show = this.configuredShows.filter((show) => show.show == this.lobby.session.state)[0];
			// prettier-ignore
			this.$global.addHistoryEntry(sID, this.$global.getUser().uID, show.gID, this.zgwState.selectedTopic.qID, 'lockTopicTerms', '', null, this.zgwState.proposedTopicTerms.join(";TERM;"), function (err) {
				if (err) that.$global.showToast('error', that.$t('gspSetTopicTermsError'));
				else that.socket.emit('lock_topic_terms', { ...that.zgwState, sID: that.$route.params.sID });

			});
		},
		updateAcceptedTerm(term) {
			if (this.zgwState.acceptedTopicTerms.includes(term))
				this.zgwState.acceptedTopicTerms = this.zgwState.acceptedTopicTerms.filter((t) => t != term);
			else this.zgwState.acceptedTopicTerms.push(term);
			sessionStorage.setItem('acceptedTopicTerms', JSON.stringify(this.zgwState.acceptedTopicTerms));
			this.socket.emit('update_accepted_terms', { ...this.zgwState, sID: this.$route.params.sID });
		},
		assignNewPlayer() {
			let that = this;
			let sID = this.$route.params.sID;
			let show = this.configuredShows.filter((show) => show.show == this.lobby.session.state)[0];

			if (this.zgwState.phase == 'assignTopicGuessing') {
				let his = this.lobby.history.filter(
					(his) => his.gID == show.gID && his.action == 'assignTopicGuessing' && his.info == this.zgwState.selectedGuesser.uID
				)[0];

				if (his) {
					this.zgwState.selectedGuesser = null;
					this.zgwState.phase = 'assignTopicGuessing';
					this.$global.deleteHistoryEntry(his.hID, function (err) {
						if (err) that.$global.showToast('error', that.$t('gspAssignNewPlayerError'));
					});
				} else that.$global.showToast('error', that.$t('gspAssignNewPlayerError'));
			} else if (this.zgwState.phase == 'guessingTime') {
				// prettier-ignore
				this.$global.addHistoryEntry(sID, this.$global.getUser().uID, show.gID, this.zgwState.selectedTopic.qID, 'setAnswers', this.zgwState.selectedGuesser.uID, null, this.zgwState.acceptedTopicTerms.join(";TERM;"), function (err) {
					if (err) that.$global.showToast('error', that.$t('gspSetAnswersError'));
					else {
						sessionStorage.removeItem('acceptedTopicTerms');
						that.zgwState.acceptedTopicTerms = [];
						that.timerUpdate = {
							newTime: null,
							newState: null,
						};

						let availablePlayers = [];
						let playersThatDidGuessTopic = that.lobby.history
							.filter((his) => his.action == 'setAnswers' && his.qID == that.zgwState.selectedTopic.qID)
							.map((his) => his.info);
						that.players.forEach((player) => {
							if (
								!playersThatDidGuessTopic.includes(player.uID) &&
								player.uID !== that.zgwState.selectedCreator.uID &&
								player.uID !== that.zgwState.selectedGuesser.uID) {
									availablePlayers.push(player);
							}
						});
						// If last player for topic show topicSummary
						that.zgwState.selectedGuesser = null
						if(availablePlayers.length == 0){
							// prettier-ignore
							that.$global.addHistoryEntry(sID, that.$global.getUser().uID, show.gID, that.zgwState.selectedTopic.qID, 'topicSummary', '', null, '', function (err) {
								if (err) that.$global.showToast('error', that.$t('gspTopicSummaryError'));
								else that.socket.emit('topic_summary', { ...that.zgwState, sID: that.$route.params.sID });

							});
						}
						// If not last player show assignTopicGuessing
						else that.socket.emit('lock_topic_terms', { ...that.zgwState, sID: that.$route.params.sID });

					}
				});
			}
		},
		revealTopicTerm(cb) {
			if (this.allowReveal) {
				this.allowReveal = false;
				let that = this;
				let sID = this.$route.params.sID;
				let show = this.configuredShows.filter((show) => show.show == this.lobby.session.state)[0];
				let nextTopicTerm = this.zgwState.proposedTopicTerms[this.zgwState.revealedTopicTerms.length];
				this.zgwState.revealedTopicTerms.push(nextTopicTerm);
				// prettier-ignore
				this.$global.addHistoryEntry(sID, this.$global.getUser().uID, show.gID, this.zgwState.selectedTopic.qID, 'revealTopicTerm', null, null, nextTopicTerm, function (err) {
					if (err) {
						that.$global.showToast('error', that.$t('gspRevealTopicTermError'));
						that.allowReveal = true;
						if (cb) cb();
					} else {
						let updatedPlayers = 0;
						that.players.forEach((player) => {
							let playerTerms = that.lobby.history.filter(
								(his) => his.action == 'setAnswers' && his.info == player.uID && his.qID == that.zgwState.selectedTopic.qID
							)[0];

							if (playerTerms) {
								playerTerms = playerTerms.result.split(';TERM;');
								if (playerTerms.includes(nextTopicTerm)) {
									// prettier-ignore
									that.$global.addHistoryEntry(sID, that.$global.getUser().uID, show.gID, that.zgwState.selectedTopic.qID, 'correctAnswer', player.uID, null, show.pointsPos, function (err) {
										if (err) {
											that.$global.showToast('error', that.$t('gspCorrectAnswerError'));
											updatedPlayers += 1
										}
										else {
											that.updateLeaderboard({ uID: player.uID, points: show.pointsPos, sID: that.$route.params.sID }, function () {
												updatedPlayers += 1
											});
										}
									});
								} else updatedPlayers += 1;
							} else updatedPlayers += 1;
						});

						let interval = window.setInterval(() => {
							if (updatedPlayers == that.players.length) {
								clearInterval(interval);
								that.socket.emit('reveal_topic_term', { ...that.zgwState, sID: that.$route.params.sID });
								that.allowReveal = true;
								if (cb) cb();
							}
						}, 100);
					}
				});
			}
		},
		revealAllTopicTerms() {
			if (this.allowReveal) {
				let that = this;
				let i = 0;
				let notRevealedTopicsTerms = this.zgwState.proposedTopicTerms.filter((term) => !this.zgwState.revealedTopicTerms.includes(term));
				let loopTopicTerms = function (terms) {
					that.revealTopicTerm(function () {
						window.setTimeout(() => {
							i++;
							if (i < terms.length) loopTopicTerms(terms);
							else that.allowReveal = true;
						}, 1000);
					});
				};

				loopTopicTerms(notRevealedTopicsTerms);
			}
		},
		assignNewCreator() {
			let creatorGotPoints = this.lobby.history.filter((his) => his.action == 'creatorPoints' && his.qID == this.zgwState.selectedTopic.qID)[0];
			if (!creatorGotPoints) {
				let that = this;
				this.addCreatorPoints(function () {
					that.zgwState = {
						phase: 'assignTopic',
						selectedCreator: null,
						selectedTopic: null,
						selectedGuesser: null,
						proposedTopicTerms: [],
						acceptedTopicTerms: [],
						revealedTopicTerms: [],
					};
					that.socket.emit('assign_new_creator', { ...that.zgwState, sID: that.$route.params.sID });
				});
			} else {
				this.zgwState = {
					phase: 'assignTopic',
					selectedCreator: null,
					selectedTopic: null,
					selectedGuesser: null,
					proposedTopicTerms: [],
					acceptedTopicTerms: [],
					revealedTopicTerms: [],
				};
				this.socket.emit('assign_new_creator', { ...this.zgwState, sID: this.$route.params.sID });
			}
		},
		addCreatorPoints(cb) {
			let that = this;
			let sID = this.$route.params.sID;
			let show = this.configuredShows.filter((show) => show.show == this.lobby.session.state)[0];
			let correctAnswers = this.lobby.history.filter((his) => his.action == 'correctAnswer' && his.qID == this.zgwState.selectedTopic.qID);
			let userPoints = {};
			correctAnswers.forEach((answer) => {
				if (userPoints[answer.info]) userPoints[answer.info] += Number(answer.result);
				else userPoints[answer.info] = Number(answer.result);
			});
			let creatorPoints = Math.max.apply(Math, Object.values(userPoints));
			// prettier-ignore
			this.$global.addHistoryEntry(sID, this.$global.getUser().uID, show.gID, this.zgwState.selectedTopic.qID, 'creatorPoints', this.zgwState.selectedCreator.uID, null, creatorPoints, function (err) {
				if (err)
					that.$global.showToast('error', that.$t('gspCreatorPointsError'));
				else {
					that.updateLeaderboard({ uID: that.zgwState.selectedCreator.uID, points: creatorPoints, sID: that.$route.params.sID }, function () {
						if(cb) cb()
					});
				}
			});
		},
		startTimer() {
			let that = this;
			let sID = this.$route.params.sID;
			let show = this.configuredShows.filter((show) => show.show == this.lobby.session.state)[0];
			// prettier-ignore
			this.$global.addHistoryEntry(sID, this.$global.getUser().uID, show.gID, this.zgwState.selectedTopic.qID, 'guessingTime', this.zgwState.selectedGuesser.uID, null, '', function (err) {
				if (err) that.$global.showToast('error', that.$t('gspStartTimerError'));
				else that.socket.emit('start_timer', { ...that.zgwState, sID: that.$route.params.sID });

			});
		},
		resetTimer() {
			let that = this;
			let his = this.lobby.history.filter(
				(his) => his.qID == this.zgwState.selectedTopic.qID && his.info == this.zgwState.selectedGuesser.uID && his.action == 'guessingTime'
			)[0];
			if (his) {
				this.$global.deleteHistoryEntry(his.hID, function (err) {
					if (err) that.$global.showToast('error', that.$t('gspResetTimerError'));
					else that.socket.emit('reset_timer', { sID: that.$route.params.sID });
				});
			} else this.socket.emit('reset_timer', { sID: this.$route.params.sID });
		},
		resumeTimer(data) {
			this.socket.emit('resume_timer', { sID: this.$route.params.sID, ...data });
		},
		stopTimer(data) {
			this.socket.emit('stop_timer', { sID: this.$route.params.sID, ...data });
		},
		updateTimer(data) {
			this.socket.emit('update_timer', { sID: this.$route.params.sID, ...data });
		},
		resetBuzzerAnimation() {
			window.setTimeout(() => {
				this.buzzerAnimation = null;
			}, 2000);
		},
		selectCategory(cell) {
			this.kqState.selectedQuestion = {
				uID: this.$global.getUser().uID,
				qID: cell[1].qID,
				level: cell[0].level,
			};

			let sID = this.$route.params.sID;
			let uID = this.$global.getUser().uID;
			let show = this.configuredShows.filter((show) => show.show == this.lobby.session.state)[0];
			let that = this;
			// prettier-ignore
			this.$global.addHistoryEntry(sID, uID, show.gID, null, 'selectCategory', cell[0].level.toString(), cell[1].category, cell[1].qID, function (err) {
				if (err) {
					that.$global.showToast('error', that.$t('gspCouldntSelectCategory'));
					that.kqState.selectedQuestion = null;
				} else that.socket.emit('select_category', { sID: sID, ...that.kqState.selectedQuestion });
			});
		},
		removeCategory(cell) {
			let sID = this.$route.params.sID;
			let uID = this.$global.getUser().uID;
			let show = this.configuredShows.filter((show) => show.show == this.lobby.session.state)[0];
			let usedJoker = this.kqState.state == 'showHint';

			let that = this;
			// prettier-ignore
			this.$global.addHistoryEntry(sID, uID, show.gID, null, 'removeCategory', cell[0].level.toString(), that.kqState.selectedQuestion.uID, cell[1].qID, function (err) {
				if (err) that.$global.showToast('error', that.$t('gspCouldntRemoveQuestion'));
				else {
					if(usedJoker) {
						let jokersLeft = that.kqState.players.filter((player) => player.uID == that.kqState.selectedQuestion.uID)[0];
						jokersLeft = jokersLeft ? jokersLeft.joker : 0;
						let restoredJokers = jokersLeft < show.amountJoker ? jokersLeft + 1 : show.amountJoker;

						that.$global.addHistoryEntry(sID, uID, show.gID, null, 'restoreJoker', that.kqState.selectedQuestion.qID, that.kqState.selectedQuestion.level.toString(), that.kqState.selectedQuestion.uID, function (err) {
							if (err) that.$global.showToast('error', that.$t('gspCouldntRestoreJoker'));
							else that.socket.emit('remove_category', { sID: sID, restoreJoker: true, joker: restoredJokers, ...that.kqState.selectedQuestion });
						});
					} else that.socket.emit('remove_category', { sID: sID, ...that.kqState.selectedQuestion });
				}
			});
		},
		setAssignCategory(cell) {
			this.kqState.selectedQuestion = {
				uID: null,
				qID: cell[1].qID,
				level: cell[0].level,
			};
			this.kqState.state = 'assignCategory';
		},
		showCategory(cell) {
			this.socket.emit('show_category', { sID: this.$route.params.sID, cell: cell });
		},
		assignCategory(uID) {
			this.kqState.selectedQuestion.uID = uID;

			let sID = this.$route.params.sID;
			let show = this.configuredShows.filter((show) => show.show == this.lobby.session.state)[0];
			let that = this;
			// prettier-ignore
			this.$global.addHistoryEntry(sID, this.$global.getUser().uID, show.gID, null, 'assignCategory', uID, this.kqState.selectedQuestion.level.toString(), this.kqState.selectedQuestion.qID, function (err) {
				if (err) that.$global.showToast('error', that.$t('gspCouldntAssignCategory'));
				else that.socket.emit('select_category', { sID: sID, assign: true, ...that.kqState.selectedQuestion });
			});
		},
		useJoker() {
			let uID = this.$global.getUser().uID;
			let jokersLeft = this.kqState.players.filter((player) => player.uID == uID)[0];
			jokersLeft = jokersLeft ? jokersLeft.joker : 0;
			let that = this;

			if (jokersLeft <= 0) this.$global.showToast('error', this.$t('gspNoJokerLeft'));
			else {
				let sID = this.$route.params.sID;
				let show = this.configuredShows.filter((show) => show.show == this.lobby.session.state)[0];
				// prettier-ignore
				this.$global.addHistoryEntry(sID, uID, show.gID, null, 'useJoker', this.kqState.selectedQuestion.level.toString(), null, this.kqState.selectedQuestion.qID, function (err) {
					if (err) that.$global.showToast('error', that.$t('gspCouldntUseJoker'));
					else that.socket.emit('use_joker', { sID: sID, uID: uID, joker: jokersLeft - 1 });
				});
			}
		},
		restoreJoker() {
			let sID = this.$route.params.sID;
			let uID = this.$global.getUser().uID;
			let show = this.configuredShows.filter((show) => show.show == this.lobby.session.state)[0];
			let jokersLeft = this.kqState.players.filter((player) => player.uID == this.kqState.selectedQuestion.uID)[0];
			jokersLeft = jokersLeft ? jokersLeft.joker : 0;
			let restoredJokers = jokersLeft < show.amountJoker ? jokersLeft + 1 : show.amountJoker;

			let that = this;
			// prettier-ignore
			that.$global.addHistoryEntry(sID, uID, show.gID, null, 'restoreJoker', this.kqState.selectedQuestion.qID, this.kqState.selectedQuestion.level.toString(), that.kqState.selectedQuestion.uID, function (err) {
				if (err) that.$global.showToast('error', that.$t('gspCouldntRestoreJoker'));
				else that.socket.emit('restore_joker', { sID: sID, joker: restoredJokers, ...that.kqState.selectedQuestion });
			});
		},
		revertClick() {
			this.kqState.selectedQuestion = null;
			this.kqState.state = null;
		},
		correctAnswerKQ() {
			let sID = this.$route.params.sID;
			let uID = this.kqState.selectedQuestion.uID;
			let show = this.configuredShows.filter((show) => show.show == this.lobby.session.state)[0];
			let points = show.pointsPerLevel[this.kqState.selectedQuestion.level].points;
			let that = this;

			// prettier-ignore
			this.$global.addHistoryEntry(sID, this.$global.getUser().uID, show.gID, null, 'correctAnswerKQ', uID,  this.kqState.selectedQuestion.qID, this.kqState.selectedQuestion.level.toString(), function (err) {
				if (err) that.$global.showToast('error', that.$t('gspCouldntSetCorrectAnswer'));
				else {
					that.updateLeaderboard({sID: sID, uID: uID, points: points }, function () {
						that.socket.emit('correct_answer_kq', { sID: sID, ...that.kqState.selectedQuestion })
					});
				}
			});
		},
		wrongAnswerKQ() {
			let sID = this.$route.params.sID;
			let uID = this.kqState.selectedQuestion.uID;
			let show = this.configuredShows.filter((show) => show.show == this.lobby.session.state)[0];
			let that = this;

			// prettier-ignore
			this.$global.addHistoryEntry(sID, this.$global.getUser().uID, show.gID, null, 'wrongAnswerKQ', uID,  this.kqState.selectedQuestion.qID, this.kqState.selectedQuestion.level.toString(), function (err) {
				if (err) that.$global.showToast('error', that.$t('gspCouldntSendWrongAnswer'));
				else that.socket.emit('wrong_answer_kq', { sID: sID, ...that.kqState.selectedQuestion })
			});
		},
		nextQuestion() {
			let uID = this.$global.getUser().uID;
			let sID = this.$route.params.sID;
			let lastQuestions = this.lobby.history.filter((his) => his.action == 'setQuestion');
			let show = this.configuredShows.filter((show) => show.show == this.lobby.session.state)[0];
			let lastQuest = show.questions.filter((quest) => quest.qID == lastQuestions[lastQuestions.length - 1].qID)[0];
			let nextQuest = show.questions.filter((quest) => quest.position == lastQuest.position + 1)[0];
			let that = this;
			if (show.show == 'wlp' && lastQuest) {
				// If item was not bought then set skip entry
				if (this.lobby.history.filter((his) => his.qID == lastQuest.qID && his.action == 'highestBid').length == 0) {
					// prettier-ignore
					this.$global.addHistoryEntry(sID, uID, show.gID, lastQuest.qID, 'skipItem', null, null, null, function (err) {
							if (err) that.$global.showToast('error', that.$t('loAddHistoryEntryError'))
						})
				}
			}
			if (nextQuest) {
				window.setTimeout(() => {
					// prettier-ignore
					that.$global.addHistoryEntry(sID, uID, show.gID, nextQuest.qID, 'setQuestion', '', null, nextQuest.qID, function (err) {
						if (err) that.$global.showToast('error', that.$t('loAddHistoryEntryError'));
						else {
							if (show.show == 'wlp'){
								let lastStarters = that.lobby.history.filter((his) => his.action == 'startBidder');
								let lastPlayer = lastStarters[lastStarters.length - 1];
								let nextBidder = that.players.filter((player)=> player.order == Number(lastPlayer.info)+1)[0];
								if(!nextBidder) nextBidder = that.players.filter((player)=> player.order == 0)[0];

								// prettier-ignore
								that.$global.addHistoryEntry(sID, uID, show.gID, nextQuest.qID, 'startBidder', nextBidder.order, null, nextBidder.uID, function (err) {
									if (err) that.$global.showToast('error', that.$t('loAddHistoryEntryError'))
									that.socket.emit('show_timer', {sID: sID, qID: nextQuest.qID, msg: 'gspNextQuestion'})

								})
							} else that.socket.emit('show_timer', {sID: sID, qID: nextQuest.qID, msg: 'gspNextQuestion'})
						}
					})
				}, 1000);
			} else if (show.show == 'wlp') {
				if (this.wlpState.revealState) {
					window.setTimeout(() => {
						that.nextShow();
					}, 1000);
				} else {
					that.$global.addHistoryEntry(sID, uID, show.gID, null, 'showReveal', null, null, null, function (err) {
						if (err) that.$global.showToast('error', that.$t('loAddHistoryEntryError'));
						else that.socket.emit('show_timer', { sID: sID, msg: 'gspRevealScreen' });
					});
				}
			} else this.nextShow();
		},
		nextShow() {
			let that = this;
			let sID = this.$route.params.sID;
			let uID = this.$global.getUser().uID;
			let show = this.configuredShows.filter((show) => show.show == this.lobby.session.state)[0];
			if (show.show == 'zgw') {
				let creatorGotPoints = this.lobby.history.filter(
					(his) => his.action == 'creatorPoints' && his.qID == this.zgwState.selectedTopic.qID
				)[0];
				if (!creatorGotPoints) this.addCreatorPoints();
			}
			if (show.position + 1 !== this.configuredShows.length) {
				// prettier-ignore
				this.$global.setSessionState(sID, uID, null, this.sessionState.nextState, function (err) {
					if (err) that.$global.showToast('error', that.$t('loSessionStateError'));
					else that.socket.emit('show_timer', {sID: sID, msg: 'gspSwapToLobby'})
				});
			} else {
				// prettier-ignore
				this.$global.setSessionState(sID, uID, null, 'finished', function (err) {
					if (err) that.$global.showToast('error', that.$t('loSessionStateError'));
					else that.socket.emit('show_timer', {sID: sID, msg: 'gspSwapToResults'})
				});
			}
		},
	},
};
</script>

<style scoped>
.gsp-wrap-content {
	width: 100vw;
	height: 100vh;
	position: relative;
	overflow-x: hidden;
}

.gsp-wrap-user-controls,
.gsp-wrap-host-controls {
	width: 100%;
	height: fit-content;
	position: absolute;
	bottom: 0px;
	left: 0px;
}
</style>
