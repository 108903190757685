import io from 'socket.io-client';
import { global } from './global.js';
import { i18n } from './translations/i18n';

let noConnectionRoutes = [
	'PageAccess',
	'PasswordReset',
	'NotFound',
	'Registration',
	'LegalNotice',
	// 'Home',
	'Settings',
	'Profile',
	'QuizNightConfiguration',
	'QuizNightConfigurationOverview',
	'QuestionCreation',
	'LegalNotice',
];
let connectionInfo = null;
let sock = null;
let socketInitiated = false;
let socketReady = false;
let route = null;
let router = null;
let ref = null;

export const socket = {
	setVars: function ($route, $router, reference) {
		route = $route;
		router = $router;
		ref = reference;
	},
	getConnectionInfo: function () {
		let that = this;
		global.getData('access', '/connectionInfo', null, null, function (err, data) {
			if (err) global.showToast('error', err.response.data.msg);
			else {
				connectionInfo = data;
				window.setTimeout(() => {
					if (!noConnectionRoutes.includes(route.name)) that.initiateSocketConnection();
				}, 1000);
			}
		});
	},
	initiateSocketConnection: function () {
		if (!sock && global.getUser()) {
			sock = io.connect(connectionInfo, {
				reconnection: true,
				transports: ['websocket'],
				auth: {
					pageauthheader: global.getAccessCode(),
					basicAuth: global.getCredentials(),
					uID: global.getUser().uID,
				},
			});

			sock.on('connect', function () {
				socketInitiated = true;
			});

			sock.on('get_current_route', function (data) {
				sock.emit('current_route', { name: ref.$route.name, reason: data.reason, data: data.data });
			});

			sock.on('socket_ready', function () {
				socketReady = true;
			});

			sock.on('connect_error', async function (err) {
				global.showToast('error', i18n.tc('soLostConnectionToServer'));
				if (err.message == 'erUnauthorizedBasicAuth') {
					global.logoutUser();
					if (router.history.current.name != 'Home') {
						await router.push({ name: 'Home' });
						location.reload();
					} else location.reload();
				} else if (err.message == 'erUnauthorizedAccessCode') {
					global.handleUnauthorizedAccess();
					if (router.history.current.name != 'Home') {
						await router.push({ name: 'Home' });
						location.reload();
					} else location.reload();
				} else if (err.message == 'erUnknownError') global.showToast('error', i18n.tc(err.message));
			});

			sock.on('disconnect', function () {});

			sock.on('disconnect_error', function (msg) {
				global.showToast('error', i18n.tc(msg));
				sock.disconnect();
				if (!['PageAccess'].includes(router.history.current.name) && socketInitiated) {
					socketInitiated = false;
					socketReady = false;
					sock = null;
					router.push({ name: 'Home' });
				}
			});
		}
	},
	emitEvent: function (event, data, cb) {
		sock.emit(event, data);
		if (cb) cb();
	},
	getSocket: function (cb) {
		if (sock) cb(sock);
		else {
			window.setTimeout(() => {
				this.getSocket(cb);
			}, 100);
		}
	},
	isSocketReady: function () {
		return socketReady;
	},
	closeSocketConnection: function () {
		if (sock) {
			sock.disconnect();
			socketInitiated = false;
			socketReady = false;
			sock = null;
		}
	},
	handleRouteChange: function (name) {
		if (!noConnectionRoutes.includes(name)) this.initiateSocketConnection();
		else this.closeSocketConnection();
	},
};
