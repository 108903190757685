<template>
	<div class="cc-wrap-content">
		<div class="cc-wrap-top">
			<h2>{{ title }}</h2>
			<fai v-if="!collapsed" icon="fas fa-chevron-up" @click="collapseView" />
			<fai v-else icon="fas fa-chevron-down" @click="expandView" />
		</div>
		<div :id="getSlotName()" class="cc-wrap-bottom">
			<slot v-show="!collapsed"></slot>
		</div>
	</div>
</template>

<script>
/**
 * @group Layout
 * CollapsableComponent
 */
export default {
	name: 'CollapsableComponent',
	props: {
		title: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			collapsed: false,
		};
	},
	methods: {
		getSlotName() {
			return this.$slots.default[0].componentOptions.Ctor.options.name;
		},
		collapseView() {
			let element = document.getElementById(this.getSlotName());
			element.style.height = element.offsetHeight + 'px';
			element.classList.add('cc-collapse');

			let that = this;
			this.$nextTick(() => {
				window.setTimeout(() => {
					element.style.height = '0px';
					that.collapsed = true;
				}, 10);
			});
		},
		expandView() {
			let element = document.getElementById(this.getSlotName());
			element.style.height = '0px';
			element.classList.add('cc-expand');
			this.collapsed = false;

			this.$nextTick(() => {
				let children = element.children;
				let totalHeight = 10;

				for (let i = 0; i < children.length; i++) {
					totalHeight += children[i].offsetHeight;
				}
				window.setTimeout(() => {
					element.style.height = totalHeight + 'px';
					window.setTimeout(() => {
						element.style.height = 'fit-content';
					}, 500);
				}, 10);
			});
		},
	},
};
</script>

<style scoped>
.cc-wrap-content {
	width: 100%;
	margin-bottom: 40px;
	padding: 5px;
	box-sizing: border-box;
	background-color: var(--main-color-5-cc);
	border: 2px solid var(--main-color-border-dark);
}

.cc-wrap-top {
	width: 100%;
	height: 55px;
	position: relative;
}

.cc-wrap-top h2 {
	width: fit-content;
	font-size: 40px;
	margin: 0px auto;
	padding: 5px 0px 5px 0px;
	text-shadow: 3px 3px var(--main-color-6);
	-webkit-text-stroke: 2px var(--main-color-text-dark);
	text-align: center;
}

.cc-wrap-top svg {
	position: absolute;
	top: 12px;
	right: 10px;
	color: var(--main-color-6);
	font-size: 30px;
}

.cc-wrap-top svg:hover {
	cursor: pointer;
	color: var(--main-color-6-cc);
}

.cc-wrap-bottom {
	margin-top: 10px;
	overflow: hidden;
	transition: height 0.5s;
}

.cc-wrap-bottom.cc-collapse {
	height: 0px;
}

.cc-wrap-bottom.cc-expand {
	height: fit-content;
}
</style>
