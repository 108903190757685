<template>
	<div class="qnco-wrap-content">
		<h1 v-if="editMode">{{ $t('qncoQuizNightCreation') }}</h1>
		<h1 v-if="viewMode">{{ name }}</h1>
		<div v-if="editMode" class="qnco-wrap-input">
			<label>{{ $t('gncoQuizNightName') }}</label>
			<input type="text" :placeholder="$t('gncoQuizNightName')" v-model="name" :disabled="isPublic || isPrivate" />
		</div>
		<ShowSelection v-if="editMode" :shows="unusedShows" :selectedShow="selectedShow" @selectShow="selectShow" :key="resetToggle" />
		<div class="qnco-wrap-config">
			<GameShowConfig
				v-if="selectedShow"
				:key="resetToggle"
				:show="selectedShow"
				:editedConfig="editedConfig"
				@addConfigElement="addConfigElement"
				@revertChanges="revertChanges"
			/>
		</div>
		<div class="qnco-wrap-config-list">
			<GameShowConfigList
				:key="resetToggle"
				:registeredShows="shows"
				:configuredShows="configuredShows"
				:state="{ viewMode: viewMode, isPrivate: isPrivate, isPublic: isPublic }"
				:editedConfig="editedConfig"
				@moveConfigUp="moveConfigUp"
				@moveConfigDown="moveConfigDown"
				@editConfig="editConfig"
				@deleteConfig="deleteConfig"
			/>
		</div>
		<div v-if="editMode || viewMode" class="qnco-wrap-bottom-cards">
			<div
				v-if="!getsEdited && !isPrivate && !isPublic && (user == $global.getUser().uID || !user)"
				class="qnco-card qnco-save-card"
				@click="saveQuizNight(null)"
			>
				<div>
					<fai icon="fas fa-save" />
					<p>{{ $t('qncoSaveQuizNight') }}</p>
				</div>
			</div>
			<div
				v-else-if="!isPrivate && !isPublic && (user == $global.getUser().uID || !user)"
				class="qnco-card qnco-edit-card"
				@click="editQuizNight()"
			>
				<div>
					<fai icon="fas fa-save" />
					<p>{{ $t('qncoEditQuizNight') }}</p>
				</div>
			</div>
			<div
				v-if="getsEdited && !isPrivate && !isPublic && (user == $global.getUser().uID || !user)"
				class="qnco-card qnco-delete-card"
				@click="deleteQuizNight"
			>
				<div>
					<fai icon="fas fa-trash" />
					<p>{{ $t('qncoDeleteQuizNight') }}</p>
				</div>
			</div>
			<div
				v-if="!isPrivate && !isPublic && (user == $global.getUser().uID || !user)"
				class="qnco-card qnco-publish-private-card"
				@click="publishQuizNight('private')"
			>
				<div>
					<fai icon="fas fa-upload" />
					<p>{{ $t('qncoPublishQuizNightPrivate') }}</p>
				</div>
			</div>
			<div
				v-if="!isPublic && (user == $global.getUser().uID || !user)"
				class="qnco-card qnco-publish-public-card"
				@click="publishQuizNight('public')"
			>
				<div>
					<fai icon="fas fa-upload" />
					<p>{{ $t('qncoPublishQuizNightPublic') }}</p>
				</div>
			</div>
			<div v-if="(isPrivate && (user == $global.getUser().uID || !user)) || isPublic" class="qnco-card qnco-clone-card" @click="cloneQuizNight">
				<div>
					<fai icon="fas fa-clone" />
					<p>{{ $t('qncoCloneQuizNight') }}</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import GameShowConfig from '../components/quizNightCreation/GameShowConfig.vue';
import GameShowConfigList from '../components/quizNightCreation/GameShowConfigList.vue';
import ShowSelection from '../components/quizNightCreation/ShowSelection.vue';
export default {
	name: 'QuizNightConfigurationOverview',
	components: {
		GameShowConfig,
		GameShowConfigList,
		ShowSelection,
	},
	data() {
		return {
			resetToggle: false,
			shows: [],
			configuredShows: [],
			selectedShow: null,
			editedConfig: null,
			getsEdited: false,
			name: '',
			isPrivate: false,
			isPublic: false,
			editedQuizNightID: null,
			user: null,
			viewMode: false,
			editMode: false,
		};
	},
	computed: {
		unusedShows() {
			return this.shows.filter((it) => !it.isUsed);
		},
	},
	created() {
		this.$global.checkAccess(this.$router, true);
		this.getRegisteredShows(() => {
			if (this.$route.query.qID) this.loadExistingConfig(this.$route.query.qID);
			else this.editMode = true;
		});
	},
	methods: {
		loadExistingConfig(qID) {
			let that = this;
			this.$global.getData(
				'quiz',
				'/getQuizNight',
				{
					headers: { pageauthheader: this.$global.getAccessCode() },
					auth: this.$global.getCredentials(),
					params: { qID: qID },
				},
				null,
				function (err, result) {
					if (err) that.$global.showToast('error', that.$t(err.response ? err.response.data.msg : 'qncoQuizNightError'));
					else {
						that.$nextTick(() => {
							result.configs.map((conf) => {
								conf.show = that.shows.filter((show) => show.rID == conf.rID)[0].abbreviation;
								if (conf.pointsPerLevel) conf.pointsPerLevel = JSON.parse(conf.pointsPerLevel);
								that.shows.map((show) => {
									if (show.abbreviation == conf.show) show.isUsed = true;
									return show;
								});
								return conf;
							});
							if (!result.isPrivate && !result.isPublic) {
								that.getsEdited = true;
								that.editMode = true;
							} else that.viewMode = true;
							that.name = result.name;
							that.configuredShows = result.configs;
							that.isPrivate = result.isPrivate;
							that.isPublic = result.isPublic;
							that.editedQuizNightID = result.qID;
							that.user = result.uID;
						});
					}
				}
			);
		},
		getRegisteredShows(cb) {
			let that = this;
			this.$global.getData(
				'quiz',
				'/getRegisteredShows',
				{
					headers: { pageauthheader: this.$global.getAccessCode() },
					auth: this.$global.getCredentials(),
				},
				null,
				function (err, result) {
					if (err) that.$global.showToast('error', that.$t(err.response ? err.response.data.msg : 'qncoShowsError'));
					else {
						that.shows = result.map((it) => {
							it.isUsed = false;
							return it;
						});
						if (cb) cb();
					}
				}
			);
		},
		selectShow(show) {
			if (this.editedConfig) this.revertChanges();
			else this.resetToggle = !this.resetToggle;

			this.$nextTick(() => {
				this.selectedShow = show;
			});
		},
		addConfigElement(config) {
			if (this.editedConfig) {
				let newConf = Object.assign(JSON.parse(JSON.stringify(this.editedConfig)), config);
				let idx = this.configuredShows.findIndex((conf) => conf.show == newConf.show);
				this.$set(this.configuredShows, idx, newConf);
			} else {
				this.configuredShows.push({ ...config, position: this.configuredShows.length });
			}

			this.shows = this.shows.map((show) => {
				if (show.abbreviation == config.show) show.isUsed = true;
				return show;
			});

			this.selectedShow = null;
			this.editedConfig = null;
		},
		revertChanges() {
			this.shows = this.shows.map((show) => {
				if (show.abbreviation == this.editedConfig.show) show.isUsed = true;
				return show;
			});
			this.selectedShow = null;
			this.editedConfig = null;
			this.resetToggle = !this.resetToggle;
		},
		moveConfigUp(config) {
			if (config.position !== 0) {
				this.configuredShows = this.$global.arrayMove(this.configuredShows, config.position, config.position - 1);
				this.renumberConfigs();
			}
		},
		moveConfigDown(config) {
			if (config.position !== this.configuredShows.length - 1) {
				this.configuredShows = this.$global.arrayMove(this.configuredShows, config.position, config.position + 1);
				this.renumberConfigs();
			}
		},
		renumberConfigs() {
			this.configuredShows.map((config, idx) => {
				config.position = idx;
			});
		},
		editConfig(config) {
			if (!this.editedConfig) this.resetToggle = !this.resetToggle;
			else if (this.editedConfig.cID !== config.cID) this.resetToggle = !this.resetToggle;

			this.$nextTick(() => {
				// this.configuredShows = this.configuredShows.filter((show) => show.show != config.show);
				this.shows.map((show) => {
					if (show.abbreviation == config.show) show.isUsed = false;
					return show;
				});
				this.selectedShow = config.show;
				this.$nextTick(() => {
					this.editedConfig = config;
				});
			});
		},
		deleteConfig(config) {
			let that = this;
			this.$global.showPopup(
				this.$t('qncoDeleteConfig'),
				this.$t('qncoDeleteConfigText'),
				'warning',
				{ text: this.$t('qncoCancel'), color: 'var(--main-color-4)' },
				{ text: this.$t('qncoDelete'), color: 'var(--main-color-error)' },
				null,
				function (result) {
					if (result.isConfirmed) {
						that.configuredShows = that.configuredShows.filter((show) => show.show != config.show);
						that.shows.map((show) => {
							if (show.abbreviation == config.show) show.isUsed = false;
							return show;
						});
						that.renumberConfigs();
					}
				}
			);
		},
		saveQuizNight(publish) {
			let that = this;
			if (this.name.length < 5 || this.name.length > 25) {
				this.$global.showToast('error', this.$t('qncoNameLength'));
			} else if (this.configuredShows.length < 1) {
				this.$global.showToast('error', this.$t('qncoNoShows'));
			} else {
				this.$global.postData(
					'quiz',
					'/addQuizNight',
					{
						name: this.name,
						configs: this.configuredShows,
						uID: this.$global.getUser().uID,
						isPrivate: publish == 'private' ? true : false,
						isPublic: publish == 'public' ? true : false,
					},
					{
						headers: { pageauthheader: this.$global.getAccessCode() },
						auth: this.$global.getCredentials(),
					},
					(err, data) => {
						if (err) {
							if (!publish) that.$global.showToast('error', that.$t(err.response ? err.response.data.msg : 'qncoAddError'));
							else that.$global.showToast('error', that.$t(err.response ? err.response.data.msg : 'qncoPublishError'));
						} else {
							that.resetToggle = !that.resetToggle;
							if (!publish) that.$global.showToast('success', that.$t('qncoConfigsAdded'));
							else that.$global.showToast('success', that.$t('qncoConfigsAddedPublished'));
							that.$router.push({ name: 'QuizNightConfiguration' });
						}
					}
				);
			}
		},
		editQuizNight(publish) {
			let that = this;
			this.$global.patchData(
				'quiz',
				'/editQuizNight',
				{
					qID: this.editedQuizNightID,
					name: this.name,
					configs: this.configuredShows,
					uID: this.$global.getUser().uID,
					isPrivate: publish == 'private' ? true : false,
					isPublic: publish == 'public' ? true : false,
				},
				{
					headers: { pageauthheader: this.$global.getAccessCode() },
					auth: this.$global.getCredentials(),
				},
				(err, data) => {
					if (err) {
						if (!publish) that.$global.showToast('error', that.$t(err.response ? err.response.data.msg : 'qncoEditError'));
						else that.$global.showToast('error', that.$t(err.response ? err.response.data.msg : 'qncoPublishError'));
					} else {
						that.resetToggle = !that.resetToggle;
						if (!publish) that.$global.showToast('success', that.$t('qncoConfigsEdited'));
						else that.$global.showToast('success', that.$t('qncoConfigsEditedPublished'));
						that.$router.push({ name: 'QuizNightConfiguration' });
					}
				}
			);
		},
		publishQuizNight(type) {
			let that = this;
			this.$global.showPopup(
				type == 'private' ? this.$t('qncoPublishQuizNightPrivate') : this.$t('qncoPublishQuizNightPublic'),
				type == 'private' ? this.$t('qncoPublishQuizNightPrivateText') : this.$t('qncoPublishQuizNightPublicText'),
				'warning',
				{ text: this.$t('qncoCancel'), color: 'var(--main-color-4)' },
				{ text: this.$t('qncoPublish'), color: 'var(--main-color-success)' },
				null,
				function (result) {
					if (result.isConfirmed) {
						if (that.getsEdited) that.editQuizNight(type);
						else if (that.editedQuizNightID) {
							that.$global.patchData(
								'quiz',
								'/publishQuizNight',
								{
									qID: that.editedQuizNightID,
									uID: that.$global.getUser().uID,
									isPrivate: type == 'private' ? true : false,
									isPublic: type == 'public' ? true : false,
								},
								{
									headers: { pageauthheader: that.$global.getAccessCode() },
									auth: that.$global.getCredentials(),
								},
								(err, data) => {
									console.log(err);
									if (err)
										that.$global.showToast(
											'error',
											that.$t(that.$global.hasErrorResponseMsg(err) ? err.response.data.msg : 'qncoPublishError')
										);
									else {
										that.resetToggle = !that.resetToggle;
										that.$global.showToast('success', that.$t('qncoConfigsEditedPublished'));
										that.$router.push({ name: 'QuizNightConfiguration' });
									}
								}
							);
						} else that.saveQuizNight(type);
					}
				}
			);
		},
		cloneQuizNight() {
			this.getsEdited = false;
			this.editedQuizNightID = null;
			this.editMode = true;
			this.viewMode = false;
			this.isPublic = false;
			this.isPrivate = false;
			this.user = this.$global.getUser().uID;
		},
		deleteQuizNight() {
			let that = this;
			this.$global.showPopup(
				this.$t('qncoDeleteQuizNight'),
				this.$t('qncoDeleteQuizNightText'),
				'warning',
				{ text: this.$t('qncoCancel'), color: 'var(--main-color-4)' },
				{ text: this.$t('qncoDelete'), color: 'var(--main-color-error)' },
				null,
				function (result) {
					if (result.isConfirmed) {
						that.$global.deleteData(
							'quiz',
							'/deleteQuizNight',
							{
								headers: { pageauthheader: that.$global.getAccessCode() },
								auth: that.$global.getCredentials(),
								params: { uID: that.$global.getUser().uID, qID: that.editedQuizNightID },
							},
							null,
							function (err, result) {
								if (err) that.$global.showToast('error', that.$t(err.response ? err.response.data.msg : 'qncoDeleteError'));
								else {
									that.$global.showToast('success', that.$t('qncoDeleteSuccess'));
									that.$router.push({ name: 'QuizNightConfiguration' });
								}
							}
						);
					}
				}
			);
		},
	},
};
</script>

<style scoped>
.qnco-wrap-content {
	width: 100%;
	height: 100%;
	padding: 0px 3vw;
	box-sizing: border-box;
	overflow: auto;
}

.qnco-wrap-input {
	width: 90%;
	margin: 20px auto;
	text-align: center;
}

.qnco-wrap-input label {
	font-size: 20px;
	display: block;
}

.qnco-wrap-input input {
	width: 100%;
	max-width: 500px;
	height: 30px;
	margin: 10px auto;
	padding: 5px !important;
	display: block;
	font-size: 20px;
	text-align: center;
}

.qnco-show-select {
	width: 400px;
	height: 40px;
	margin: 5px auto;
	padding: 5px 10px;
	display: block;
	font-size: 20px;
}

.qnco-wrap-config {
	margin: 10px 0px;
	overflow: auto;
}

.qnco-wrap-bottom-cards {
	width: 100%;
	padding: 0px 5%;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	flex-flow: row;
	align-items: center;
}

.qnco-card {
	flex: 1 1 50%;
	height: 10vh;
	min-height: 100px;
	margin: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: transform 0.5s;
	border: 2px solid var(--main-color-border-dark);
}

.qnco-card:hover {
	cursor: pointer;
	transform: scale(1.1);
	z-index: 5;
}

.qnco-card div {
	text-align: center;
}

.qnco-card svg {
	margin: 5px;
	font-size: 45px;
	color: var(--main-color-text-light);
	filter: drop-shadow(2px 2px 2px var(--main-color-border-dark));
}

.qnco-card p {
	font-size: 20px;
	text-shadow: 1px 1px var(--main-color-text-dark);
}

.qnco-save-card {
	background-color: var(--main-color-success-cc);
}
.qnco-edit-card {
	background-color: var(--main-color-success-cc);
}
.qnco-delete-card {
	background-color: var(--main-color-error-cc);
}
.qnco-publish-private-card {
	background-color: var(--main-color-info-cc);
}
.qnco-publish-public-card {
	background-color: var(--main-color-6-cc);
}
.qnco-clone-card {
	background-color: var(--main-color-info-cc);
}
</style>
