<template>
	<div class="fb-wrap-content">
		<div id="dropdown" class="fb-wrap-available-shows" v-show="showShows">
			<div v-if="isBugFilter" class="fb-available-show">
				<input type="checkbox" id="global" @click="clickedShow({ abbreviation: 'global' })" :checked="isChecked('global')" />
				<img :src="require(`@/assets/images/logos/global.png`)" class="fb-small-show-logo" @click="clickedShow({ abbreviation: 'global' })" />
				<label for="global">{{ $t('fbGlobal') }}</label>
			</div>
			<div v-for="show in availableShows" :key="show.abbreviation" class="fb-available-show">
				<input type="checkbox" :id="show.abbreviation" @click="clickedShow(show)" :checked="isChecked(show.abbreviation)" />
				<img
					:src="require(`@/assets/images/logos/${show.abbreviation.toUpperCase()}Logo.png`)"
					:alt="$t(`${show.name}`)"
					:title="$t(`${show.name}`)"
					class="fb-small-show-logo"
					@click="clickedShow(show)"
				/>
				<label :for="show.abbreviation">{{ $t(show.name) }}</label>
			</div>
		</div>
		<div class="fb-no-overflow">
			<div class="fb-wrap-left">
				<div v-if="isSessionFilter" class="fb-wrap-option" :title="$t('sfShowPasswordProtected')">
					<fai icon="fas fa-lock"></fai>
					<label class="fb-switch">
						<input type="checkbox" v-model="sessionFilter.showPasswordProtected" />
						<span class="fb-slider"></span>
					</label>
				</div>
				<div v-if="isSessionFilter" class="fb-wrap-option" :title="$t('sfShowClosed')">
					<fai icon="fas fa-door-closed"></fai>
					<label class="fb-switch">
						<input type="checkbox" v-model="sessionFilter.showFull" />
						<span class="fb-slider"></span>
					</label>
				</div>
				<div v-if="isQuizNightFilter" class="fb-wrap-option" :title="$t('sfShowOwnQuizNights')">
					<fai icon="fas fa-user-lock"></fai>
					<label class="fb-switch">
						<input type="checkbox" v-model="quizNightFilter.showOwnQuizNights" @change="dependencyCheck('own')" />
						<span class="fb-slider"></span>
					</label>
				</div>
				<div v-if="isQuizNightFilter" class="fb-wrap-option" :title="$t('sfShowOtherQuizNights')">
					<fai icon="fas fa-users"></fai>
					<label class="fb-switch">
						<input type="checkbox" v-model="quizNightFilter.showOtherQuizNights" @change="dependencyCheck('other')" />
						<span class="fb-slider"></span>
					</label>
				</div>
				<div v-if="isQuestionFilter" class="fb-wrap-option" :title="$t('sfShowEditableQuestions')">
					<fai icon="fas fa-edit"></fai>
					<label class="fb-switch">
						<input type="checkbox" v-model="questionFilter.showEditableQuestions" @change="dependencyCheck('editable')" />
						<span class="fb-slider"></span>
					</label>
				</div>
				<div v-if="isQuestionFilter" class="fb-wrap-option" :title="$t('sfShowPrivateQuestions')">
					<fai icon="fas fa-lock"></fai>
					<label class="fb-switch">
						<input type="checkbox" v-model="questionFilter.showPrivateQuestions" @change="dependencyCheck('own')" />
						<span class="fb-slider"></span>
					</label>
				</div>
				<div v-if="isQuestionFilter" class="fb-wrap-option" :title="$t('sfShowPublicQuestions')">
					<fai icon="fas fa-globe-europe"></fai>
					<label class="fb-switch">
						<input type="checkbox" v-model="questionFilter.showPublicQuestions" @change="dependencyCheck('other')" />
						<span class="fb-slider"></span>
					</label>
				</div>
				<div v-if="isSessionFilter || isQuizNightFilter" class="fb-wrap-option" :title="$t('sfMaxDuration')">
					<fai icon="fas fa-clock"></fai>
					<input
						v-if="isSessionFilter"
						type="number"
						v-model="sessionFilter.minDuration"
						@input="setMinDuration"
						:min="0"
						:max="sessionFilter.maxDuration"
					/>
					<input
						v-else-if="isQuizNightFilter"
						type="number"
						v-model="quizNightFilter.minDuration"
						@input="setMinDuration"
						:min="0"
						:max="quizNightFilter.maxDuration"
					/>
					<p>-</p>
					<input
						v-if="isSessionFilter"
						type="number"
						v-model="sessionFilter.maxDuration"
						@input="setMaxDuration"
						:min="sessionFilter.minDuration"
						:max="maxDuration"
					/>
					<input
						v-else-if="isQuizNightFilter"
						type="number"
						v-model="quizNightFilter.maxDuration"
						@input="setMaxDuration"
						:min="quizNightFilter.minDuration"
						:max="maxDuration"
					/>
					<p>{{ $t('sfMinutes') }}</p>
				</div>
				<div v-if="isUserFilter" class="fb-wrap-option" :title="$t('sfShowVerifiedUsers')" @change="dependencyCheck('verified')">
					<fai icon="fas fa-check-circle"></fai>
					<label class="fb-switch">
						<input type="checkbox" v-model="userFilter.showVerifiedUsers" />
						<span class="fb-slider"></span>
					</label>
				</div>
				<div v-if="isUserFilter" class="fb-wrap-option" :title="$t('sfShowUnverifiedUsers')" @change="dependencyCheck('unverified')">
					<fai icon="fas fa-times-circle"></fai>
					<label class="fb-switch">
						<input type="checkbox" v-model="userFilter.showUnverifiedUsers" />
						<span class="fb-slider"></span>
					</label>
				</div>
				<div v-if="isUserFilter" class="fb-wrap-option" :title="$t('sfShowDeletedUsers')" @change="dependencyCheck('deleted')">
					<fai icon="fas fa-user-xmark"></fai>
					<label class="fb-switch">
						<input type="checkbox" v-model="userFilter.showDeletedUsers" />
						<span class="fb-slider"></span>
					</label>
				</div>
				<div v-if="isBugFilter" class="fb-wrap-option" :title="$t('sfShowUnresolvedBugs')" @change="dependencyCheck('unresolved')">
					<fai icon="fas fa-exclamation-circle"></fai>
					<label class="fb-switch">
						<input type="checkbox" v-model="bugFilter.showUnresolved" />
						<span class="fb-slider"></span>
					</label>
				</div>
				<div v-if="isBugFilter" class="fb-wrap-option" :title="$t('sfShowResolvedBugs')" @change="dependencyCheck('resolved')">
					<fai icon="fas fa-check-circle"></fai>
					<label class="fb-switch">
						<input type="checkbox" v-model="bugFilter.showResolved" />
						<span class="fb-slider"></span>
					</label>
				</div>
				<div v-if="isBugFilter" class="fb-wrap-option" :title="$t('sfShowBugs')" @change="dependencyCheck('bugs')">
					<fai icon="fas fa-bug"></fai>
					<label class="fb-switch">
						<input type="checkbox" v-model="bugFilter.showBugs" />
						<span class="fb-slider"></span>
					</label>
				</div>
				<div v-if="isBugFilter" class="fb-wrap-option" :title="$t('sfShowFeedback')" @change="dependencyCheck('feedback')">
					<fai icon="fas fa-comment"></fai>
					<label class="fb-switch">
						<input type="checkbox" v-model="bugFilter.showFeedback" />
						<span class="fb-slider"></span>
					</label>
				</div>
				<div class="fb-break-flow"></div>
				<div v-if="isSessionFilter || isQuizNightFilter || isBugFilter" class="fb-wrap-option" :title="$t('sfShows')">
					<fai icon="fas fa-th-list"></fai>
					<div @click="addClickListener" id="clickReference" class="fb-shows-dropdown">
						<p class="fb-dropdown-select">
							{{ $t('sfSelectShow') }}
							<span v-if="isSessionFilter">({{ sessionFilter.shows.length }})</span>
							<span v-else-if="isQuizNightFilter">({{ quizNightFilter.shows.length }})</span>
							<span v-else-if="isBugFilter">({{ bugFilter.shows.length }})</span>
							<fai icon="fas fa-chevron-down"></fai>
						</p>
					</div>
				</div>
				<div class="fb-wrap-searchbar">
					<fai icon="fas fa-search"></fai>
					<input v-if="isSessionFilter" type="text" :placeholder="$t('sfSearchSessions')" v-model="sessionFilter.searchString" />
					<input v-else-if="isQuizNightFilter" type="text" :placeholder="$t('sfSearchQuizNights')" v-model="quizNightFilter.searchString" />
					<input v-else-if="isQuestionFilter" type="text" :placeholder="$t('sfSearchQuestions')" v-model="questionFilter.searchString" />
					<input v-else-if="isUserFilter" type="text" :placeholder="$t('sfSearchUser')" v-model="userFilter.searchString" />
					<input v-else-if="isBugFilter" type="text" :placeholder="$t('sfSearchBugs')" v-model="bugFilter.searchString" />
				</div>
			</div>
			<div class="fb-wrap-right">
				<div class="fb-right-top">
					<select v-if="isSessionFilter" class="fb-sort-by" v-model="sessionFilter.sortBy">
						<option default selected value="default">{{ $t('sdSortByDefault') }}</option>
						<option value="players-desc">{{ $t('sdSortByAmountPlayersDesc') }}</option>
						<option value="players-asc">{{ $t('sdSortByAmountPlayersAsc') }}</option>
						<option value="name-desc">{{ $t('sdSortByNameDesc') }}</option>
						<option value="name-asc">{{ $t('sdSortByNameAsc') }}</option>
						<option value="duration-desc">{{ $t('sdSortByDurationDesc') }}</option>
						<option value="duration-asc">{{ $t('sdSortByDurationAsc') }}</option>
						<option value="created-desc">{{ $t('sdSortByCreatedDesc') }}</option>
						<option value="created-asc">{{ $t('sdSortByCreatedAsc') }}</option>
					</select>
					<select v-else-if="isQuizNightFilter" class="fb-sort-by" v-model="quizNightFilter.sortBy">
						<option default selected value="default">{{ $t('sdSortByDefault') }}</option>
						<option value="stars-desc">{{ $t('qncStarsDown') }}</option>
						<option value="stars-asc">{{ $t('qncStarsUp') }}</option>
						<option value="played-desc">{{ $t('qncPlayedDown') }}</option>
						<option value="played-asc">{{ $t('qncPlayedUp') }}</option>
						<option value="name-desc">{{ $t('qncNameDown') }}</option>
						<option value="name-asc">{{ $t('qncNameUp') }}</option>
						<option value="created-desc">{{ $t('qncDateDown') }}</option>
						<option value="created-asc">{{ $t('qncDateUp') }}</option>
						<option value="duration-desc">{{ $t('sdSortByDurationDesc') }}</option>
						<option value="duration-asc">{{ $t('sdSortByDurationAsc') }}</option>
						<option value="creator-desc">{{ $t('qncCreatorDown') }}</option>
						<option value="creator-asc">{{ $t('qncCreatorUp') }}</option>
					</select>
					<select v-else-if="isQuestionFilter" class="fb-sort-by" v-model="questionFilter.sortBy">
						<option default selected value="default">{{ $t('sdSortByDefault') }}</option>
						<option value="name-desc">{{ $t('qncQuestionDown') }}</option>
						<option value="name-asc">{{ $t('qncQuestionUp') }}</option>
						<option value="editable-desc">{{ $t('qncEditableDown') }}</option>
						<option value="editable-asc">{{ $t('qncEditableUp') }}</option>
						<option value="created-desc">{{ $t('qncDateDown') }}</option>
						<option value="created-asc">{{ $t('qncDateUp') }}</option>
					</select>
					<select v-else-if="isUserFilter" class="fb-sort-by" v-model="userFilter.sortBy">
						<option default selected value="default">{{ $t('sdSortByDefault') }}</option>
						<option value="name-desc">{{ $t('utNameDown') }}</option>
						<option value="name-asc">{{ $t('utNameUp') }}</option>
						<option value="email-desc">{{ $t('utEmailDown') }}</option>
						<option value="email-asc">{{ $t('utEmailUp') }}</option>
						<option value="role-desc">{{ $t('utRoleDown') }}</option>
						<option value="role-asc">{{ $t('utRoleUp') }}</option>
						<option value="active-desc">{{ $t('utActiveDown') }}</option>
						<option value="active-asc">{{ $t('utActiveUp') }}</option>
						<option value="verified-desc">{{ $t('utVerifiedDown') }}</option>
						<option value="verified-asc">{{ $t('utVerifiedUp') }}</option>
						<option value="deleted-desc">{{ $t('utDeletedDown') }}</option>
						<option value="deleted-asc">{{ $t('utDeletedUp') }}</option>
						<option value="created-desc">{{ $t('utCreatedDesc') }}</option>
						<option value="created-asc">{{ $t('utCreatedAsc') }}</option>
					</select>
					<select v-else-if="isBugFilter" class="fb-sort-by" v-model="bugFilter.sortBy">
						<option default selected value="default">{{ $t('sdSortByDefault') }}</option>
						<option value="category-desc">{{ $t('utCategoryDown') }}</option>
						<option value="category-asc">{{ $t('utCategoryUp') }}</option>
						<option value="created-desc">{{ $t('utCreatedDown') }}</option>
						<option value="created-asc">{{ $t('utCreatedUp') }}</option>
						<option value="resolved-desc">{{ $t('utResolvedDown') }}</option>
						<option value="resolved-asc">{{ $t('utResolvedUp') }}</option>
					</select>
					<fai
						icon="fas fa-sync-alt"
						:class="[loading ? 'fb-loading-spinner' : '', 'fb-refresh']"
						@click="refresh"
						:title="
							isSessionFilter
								? $t('sfRefreshSessions')
								: isQuizNightFilter
								? $t('sfRefreshQuizNights')
								: isQuestionFilter
								? $t('sfRefreshQuestions')
								: isUserFilter
								? $t('sfRefreshUsers')
								: isBugFilter
								? $t('sfRefreshBugs')
								: ''
						"
					></fai>
				</div>
				<div class="fb-right-bottom">
					<p v-if="isSessionFilter">{{ $t('sfLobbysPerPage') }}:</p>
					<p v-else-if="isQuizNightFilter">{{ $t('sfQuizNightsPerPage') }}:</p>
					<p v-else-if="isQuestionFilter">{{ $t('sfQuestionsPerPage') }}:</p>
					<p v-else-if="isUserFilter">{{ $t('sfUsersPerPage') }}:</p>
					<p v-else-if="isBugFilter">{{ $t('sfBugsPerPage') }}:</p>
					<select v-if="isSessionFilter" class="fb-page-size" v-model.number="sessionFilter.pageSize" @change="setPageSize">
						<option default selected :value="10">10</option>
						<option :value="25">25</option>
						<option :value="50">50</option>
					</select>
					<select v-else-if="isQuizNightFilter" class="fb-page-size" v-model.number="quizNightFilter.pageSize" @change="setPageSize">
						<option default selected :value="10">10</option>
						<option :value="25">25</option>
						<option :value="50">50</option>
					</select>
					<select v-else-if="isQuestionFilter" class="fb-page-size" v-model.number="questionFilter.pageSize" @change="setPageSize">
						<option default selected :value="10">10</option>
						<option :value="25">25</option>
						<option :value="50">50</option>
					</select>
					<select v-else-if="isUserFilter" class="fb-page-size" v-model.number="userFilter.pageSize" @change="setPageSize">
						<option default selected :value="10">10</option>
						<option :value="25">25</option>
						<option :value="50">50</option>
					</select>
					<select v-else-if="isBugFilter" class="fb-page-size" v-model.number="bugFilter.pageSize" @change="setPageSize">
						<option default selected :value="10">10</option>
						<option :value="25">25</option>
						<option :value="50">50</option>
					</select>
					<p>({{ amountElements }})</p>

					<div class="fb-pages">
						<p v-for="page in pages" :key="page" class="fb-page">
							<span v-if="['pageLowerDivider', 'pageUpperDivider'].includes(page)" class="fb-no-hover"> . . . </span>
							<span
								v-else
								:class="[page == getCurrentPage() ? 'fb-highlight-page' : '', loading ? 'fb-no-hover' : '']"
								@click="loading ? null : setPage(page)"
								>{{ page }}</span
							>
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
/**
 * @group Filter
 * Filter bar
 */
export default {
	name: 'FilterBar',
	props: {
		type: {
			type: String,
			required: true,
		},
		isLoading: {
			type: Boolean,
			required: true,
		},
		amountElements: {
			type: Number,
			required: true,
		},
	},
	watch: {
		isLoading: {
			handler: function (newVal, oldVal) {
				if (this.isLoadingStart != null) {
					let msDiff = this.isLoadingStart.getTime() - new Date().getTime();
					if (msDiff > this.minimumSpinDuration) this.loading = false;
					else {
						let that = this;
						window.setTimeout(() => {
							that.loading = false;
						}, this.minimumSpinDuration - msDiff);
					}
					if (this.isSessionFilter)
						this.pages = this.$global.getPages(this.amountElements, this.sessionFilter.pageSize, this.sessionFilter.currentPage);
					else if (this.isQuizNightFilter)
						this.pages = this.$global.getPages(this.amountElements, this.quizNightFilter.pageSize, this.quizNightFilter.currentPage);
					else if (this.isQuestionFilter)
						this.pages = this.$global.getPages(this.amountElements, this.questionFilter.pageSize, this.questionFilter.currentPage);
					else if (this.isUserFilter)
						this.pages = this.$global.getPages(this.amountElements, this.userFilter.pageSize, this.userFilter.currentPage);
					else if (this.isBugFilter)
						this.pages = this.$global.getPages(this.amountElements, this.bugFilter.pageSize, this.bugFilter.currentPage);
				}
			},
		},
		sessionFilter: {
			handler: function (newVal, oldVal) {
				if (newVal != null) {
					let clone = JSON.parse(JSON.stringify(newVal));
					clone.searchString = '';
					if (localStorage.getItem('cookiesAllowed')) localStorage.setItem('sessionFilter', JSON.stringify(clone));
					else sessionStorage.setItem('sessionFilter', JSON.stringify(clone));
				}
			},
			deep: true,
		},
		quizNightFilter: {
			handler: function (newVal, oldVal) {
				if (newVal != null) {
					let clone = JSON.parse(JSON.stringify(newVal));
					clone.searchString = '';
					if (localStorage.getItem('cookiesAllowed')) localStorage.setItem('quizNightFilter', JSON.stringify(clone));
					else sessionStorage.setItem('quizNightFilter', JSON.stringify(clone));
				}
			},
			deep: true,
		},
		questionFilter: {
			handler: function (newVal, oldVal) {
				if (newVal != null) {
					let clone = JSON.parse(JSON.stringify(newVal));
					clone.searchString = '';
					if (localStorage.getItem('cookiesAllowed')) localStorage.setItem('questionFilter', JSON.stringify(clone));
					else sessionStorage.setItem('questionFilter', JSON.stringify(clone));
				}
			},
			deep: true,
		},
		userFilter: {
			handler: function (newVal, oldVal) {
				if (newVal != null) {
					let clone = JSON.parse(JSON.stringify(newVal));
					clone.searchString = '';
					if (localStorage.getItem('cookiesAllowed')) localStorage.setItem('userFilter', JSON.stringify(clone));
					else sessionStorage.setItem('userFilter', JSON.stringify(clone));
				}
			},
			deep: true,
		},
		bugFilter: {
			handler: function (newVal, oldVal) {
				if (newVal != null) {
					let clone = JSON.parse(JSON.stringify(newVal));
					clone.searchString = '';
					if (localStorage.getItem('cookiesAllowed')) localStorage.setItem('bugFilter', JSON.stringify(clone));
					else sessionStorage.setItem('bugFilter', JSON.stringify(clone));
				}
			},
			deep: true,
		},
		amountElements: {
			handler: function (newVal, oldVal) {
				if (this.isSessionFilter)
					this.pages = this.$global.getPages(this.amountElements, this.sessionFilter.pageSize, this.sessionFilter.currentPage);
				else if (this.isQuizNightFilter)
					this.pages = this.$global.getPages(this.amountElements, this.quizNightFilter.pageSize, this.quizNightFilter.currentPage);
				else if (this.isQuestionFilter)
					this.pages = this.$global.getPages(this.amountElements, this.questionFilter.pageSize, this.questionFilter.currentPage);
				else if (this.isUserFilter)
					this.pages = this.$global.getPages(this.amountElements, this.userFilter.pageSize, this.userFilter.currentPage);
				else if (this.isBugFilter)
					this.pages = this.$global.getPages(this.amountElements, this.bugFilter.pageSize, this.bugFilter.currentPage);
			},
		},
	},
	data() {
		return {
			isSessionFilter: false,
			isQuizNightFilter: false,
			isQuestionFilter: false,
			isUserFilter: false,
			isBugFilter: false,
			availableShows: [],
			showShows: false,
			minimumSpinDuration: 1000,
			maxDuration: 9999,
			isLoadingStart: null,
			loading: false,
			pages: [],
			sessionFilter: {
				sortBy: 'default',
				showFull: true,
				showPasswordProtected: true,
				shows: [],
				minDuration: 0,
				maxDuration: 0,
				pageSize: 10,
				currentPage: 1,
				searchString: '',
			},
			quizNightFilter: {
				sortBy: 'default',
				showOwnQuizNights: true,
				showOtherQuizNights: true,
				shows: [],
				minDuration: 0,
				maxDuration: 0,
				pageSize: 10,
				currentPage: 1,
				searchString: '',
			},
			questionFilter: {
				sortBy: 'default',
				showEditableQuestions: true,
				showPrivateQuestions: true,
				showPublicQuestions: true,
				pageSize: 10,
				currentPage: 1,
				searchString: '',
			},
			userFilter: {
				sortBy: 'default',
				showVerifiedUsers: true,
				showUnverifiedUsers: true,
				showDeletedUsers: true,
				pageSize: 10,
				currentPage: 1,
				searchString: '',
			},
			bugFilter: {
				sortBy: 'default',
				showUnresolved: true,
				showResolved: true,
				showBugs: true,
				showFeedback: true,
				shows: [],
				pageSize: 10,
				currentPage: 1,
				searchString: '',
			},
		};
	},
	created() {
		this.isSessionFilter = this.type == 'session';
		this.isQuizNightFilter = this.type == 'quizNight';
		this.isQuestionFilter = this.type == 'question';
		this.isUserFilter = this.type == 'user';
		this.isBugFilter = this.type == 'bug';
		this.getAvailableShows();
	},
	methods: {
		isChecked(name) {
			if (this.isSessionFilter) return this.sessionFilter.shows.filter((s) => s == name).length > 0;
			else if (this.isQuizNightFilter) return this.quizNightFilter.shows.filter((s) => s == name).length > 0;
			else if (this.bugFilter) return this.bugFilter.shows.filter((s) => s == name).length > 0;
		},
		getAvailableShows() {
			let that = this;
			this.$global.getData(
				'quiz',
				'/getRegisteredShows',
				{
					headers: { pageauthheader: this.$global.getAccessCode() },
				},
				null,
				function (err, result) {
					if (err) that.$global.showToast('error', that.$t(err.response ? err.response.data.msg : 'loShowsError'));
					else {
						that.availableShows = result;

						if (that.isSessionFilter) that.handleSessionFilter(result);
						else if (that.isQuizNightFilter) that.handleQuizNightFilter(result);
						else if (that.isQuestionFilter) that.handleQuestionFilter();
						else if (that.isUserFilter) that.handleUserFilter();
						else if (that.isBugFilter) that.handleBugFilter(result);
					}
				}
			);
		},
		handleSessionFilter(shows) {
			let sessionFilter = localStorage.getItem('sessionFilter')
				? localStorage.getItem('sessionFilter')
				: sessionStorage.getItem('sessionFilter');

			if (sessionFilter) this.sessionFilter = JSON.parse(sessionFilter);
			else this.sessionFilter.shows = shows.map((s) => s.abbreviation);

			if (localStorage.getItem('cookiesAllowed')) localStorage.setItem('sessionFilter', JSON.stringify(this.sessionFilter));
			else sessionStorage.setItem('sessionFilter', JSON.stringify(this.sessionFilter));

			this.$emit('refresh', this.sessionFilter);
		},
		handleQuizNightFilter(shows) {
			let quizNightFilter = localStorage.getItem('quizNightFilter')
				? localStorage.getItem('quizNightFilter')
				: sessionStorage.getItem('quizNightFilter');

			if (quizNightFilter) this.quizNightFilter = JSON.parse(quizNightFilter);
			else this.quizNightFilter.shows = shows.map((s) => s.abbreviation);

			if (localStorage.getItem('cookiesAllowed')) localStorage.setItem('quizNightFilter', JSON.stringify(this.quizNightFilter));
			else sessionStorage.setItem('quizNightFilter', JSON.stringify(this.quizNightFilter));

			this.$emit('refresh', this.quizNightFilter);
		},
		handleQuestionFilter() {
			let questionFilter = localStorage.getItem('questionFilter')
				? localStorage.getItem('questionFilter')
				: sessionStorage.getItem('questionFilter');

			if (questionFilter) this.questionFilter = JSON.parse(questionFilter);

			if (localStorage.getItem('cookiesAllowed')) localStorage.setItem('questionFilter', JSON.stringify(this.questionFilter));
			else sessionStorage.setItem('questionFilter', JSON.stringify(this.questionFilter));

			this.$emit('refresh', this.questionFilter);
		},
		handleUserFilter() {
			let userFilter = localStorage.getItem('userFilter') ? localStorage.getItem('userFilter') : sessionStorage.getItem('userFilter');

			if (userFilter) this.userFilter = JSON.parse(userFilter);

			if (localStorage.getItem('cookiesAllowed')) localStorage.setItem('userFilter', JSON.stringify(this.userFilter));
			else sessionStorage.setItem('userFilter', JSON.stringify(this.userFilter));

			this.$emit('refresh', this.userFilter);
		},
		handleBugFilter(shows) {
			let bugFilter = localStorage.getItem('bugFilter') ? localStorage.getItem('bugFilter') : sessionStorage.getItem('bugFilter');

			if (bugFilter) this.bugFilter = JSON.parse(bugFilter);
			else {
				this.bugFilter.shows = shows.map((s) => s.abbreviation);
				this.bugFilter.shows.push('global');
			}

			if (localStorage.getItem('cookiesAllowed')) localStorage.setItem('bugFilter', JSON.stringify(this.bugFilter));
			else sessionStorage.setItem('bugFilter', JSON.stringify(this.bugFilter));

			this.$emit('refresh', this.bugFilter);
		},
		addClickListener() {
			if (this.showShows) this.removeClickListener();
			else {
				window.addEventListener('resize', this.resizeListener);
				window.addEventListener('customScroll', this.resizeListener);
				window.dispatchEvent(new Event('resize'));
				this.showShows = true;
				let that = this;
				window.setTimeout(() => {
					window.addEventListener('click', that.clickListener);
				}, 10);
			}
		},
		removeClickListener() {
			this.showShows = false;
			window.removeEventListener('resize', this.resizeListener);
			window.removeEventListener('customScroll', this.resizeListener);
			window.removeEventListener('click', this.clickListener);
		},
		resizeListener() {
			let dropdown = document.getElementById('dropdown');
			let target = document.getElementById('clickReference');
			let rect = target.getBoundingClientRect();

			dropdown.style.left = rect.left + 'px';
			dropdown.style.top = rect.top + 30 + 'px';
		},
		clickListener(e) {
			const withinBoundaries = e.composedPath().includes(document.getElementById('dropdown'));
			if (!withinBoundaries) this.removeClickListener();
		},
		clickedShow(show) {
			if (this.isSessionFilter) {
				if (!this.sessionFilter.shows.includes(show.abbreviation)) this.sessionFilter.shows.push(show.abbreviation);
				else this.sessionFilter.shows = this.sessionFilter.shows.filter((s) => s != show.abbreviation);
			} else if (this.isQuizNightFilter) {
				if (!this.quizNightFilter.shows.includes(show.abbreviation)) this.quizNightFilter.shows.push(show.abbreviation);
				else this.quizNightFilter.shows = this.quizNightFilter.shows.filter((s) => s != show.abbreviation);
			} else if (this.isBugFilter) {
				if (!this.bugFilter.shows.includes(show.abbreviation)) this.bugFilter.shows.push(show.abbreviation);
				else this.bugFilter.shows = this.bugFilter.shows.filter((s) => s != show.abbreviation);
			}
		},
		dependencyCheck(from) {
			if (this.isQuizNightFilter) {
				if (from == 'own' && !this.quizNightFilter.showOwnQuizNights) this.quizNightFilter.showOtherQuizNights = true;
				else if (from == 'other' && !this.quizNightFilter.showOtherQuizNights) this.quizNightFilter.showOwnQuizNights = true;
			} else if (this.isQuestionFilter) {
				let optionActivated =
					this.questionFilter.showEditableQuestions || this.questionFilter.showPrivateQuestions || this.questionFilter.showPublicQuestions;
				this.$nextTick(() => {
					if (from == 'editable' && !optionActivated) {
						this.questionFilter.showPrivateQuestions = true;
						this.questionFilter.showPublicQuestions = true;
					} else if (from == 'own' && !optionActivated) {
						this.questionFilter.showEditableQuestions = true;
						this.questionFilter.showPublicQuestions = true;
					} else if (from == 'other' && !optionActivated) {
						this.questionFilter.showEditableQuestions = true;
						this.questionFilter.showPrivateQuestions = true;
					}
				});
			} else if (this.isUserFilter) {
				let optionActivated = this.userFilter.showVerifiedUsers || this.userFilter.showUnverifiedUsers || this.userFilter.showDeletedUsers;

				this.$nextTick(() => {
					if (from == 'verified') this.userFilter.showUnverifiedUsers = true;
					else if (from == 'unverified') this.userFilter.showVerifiedUsers = true;
					else if (from == 'deleted' && !optionActivated) {
						this.userFilter.showVerifiedUsers = true;
						this.userFilter.showUnverifiedUsers = true;
					}
				});
			} else if (this.isBugFilter) {
				this.$nextTick(() => {
					if (from == 'unresolved') this.bugFilter.showResolved = true;
					else if (from == 'resolved') this.bugFilter.showUnresolved = true;
					else if (from == 'bugs') this.bugFilter.showFeedback = true;
					else if (from == 'feedback') this.bugFilter.showBugs = true;
				});
			}
		},
		setMinDuration(e) {
			if (this.isSessionFilter) {
				if (e.target.value.length == 0) this.sessionFilter.minDuration = 0;
				else {
					let duration = Number(e.target.value);
					if (duration < 0) this.sessionFilter.minDuration = 0;
					else if (duration > this.sessionFilter.maxDuration) this.sessionFilter.minDuration = this.sessionFilter.maxDuration;
					else this.sessionFilter.minDuration = Number(e.target.value);
				}
			} else if (this.isQuizNightFilter) {
				if (e.target.value.length == 0) this.quizNightFilter.minDuration = 0;
				else {
					let duration = Number(e.target.value);
					if (duration < 0) this.quizNightFilter.minDuration = 0;
					else if (duration > this.quizNightFilter.maxDuration) this.quizNightFilter.minDuration = this.quizNightFilter.maxDuration;
					else this.quizNightFilter.minDuration = Number(e.target.value);
				}
			}
		},
		setMaxDuration(e) {
			if (this.isSessionFilter) {
				if (e.target.value.length == 0) this.sessionFilter.maxDuration = 0;
				else {
					let duration = Number(e.target.value);
					if (duration > this.durationMax) this.sessionFilter.maxDuration = this.durationMax;
					else if (duration < this.sessionFilter.minDuration) this.sessionFilter.maxDuration = this.sessionFilter.minDuration;
					else this.sessionFilter.maxDuration = Number(e.target.value);
				}
			} else if (this.isQuizNightFilter) {
				if (e.target.value.length == 0) this.quizNightFilter.maxDuration = 0;
				else {
					let duration = Number(e.target.value);
					if (duration > this.durationMax) this.quizNightFilter.maxDuration = this.durationMax;
					else if (duration < this.quizNightFilter.minDuration) this.quizNightFilter.maxDuration = this.quizNightFilter.minDuration;
					else this.quizNightFilter.maxDuration = Number(e.target.value);
				}
			}
		},
		getCurrentPage() {
			if (this.isSessionFilter) return this.sessionFilter.currentPage;
			else if (this.isQuizNightFilter) return this.quizNightFilter.currentPage;
			else if (this.isQuestionFilter) return this.questionFilter.currentPage;
			else if (this.isUserFilter) return this.userFilter.currentPage;
			else if (this.isBugFilter) return this.bugFilter.currentPage;
			else 0;
		},
		setPageSize() {
			if (this.isSessionFilter) {
				this.sessionFilter.currentPage = 1;
				this.pages = this.$global.getPages(this.amountElements, this.sessionFilter.pageSize, 1);
			} else if (this.isQuizNightFilter) {
				this.quizNightFilter.currentPage = 1;
				this.pages = this.$global.getPages(this.amountElements, this.quizNightFilter.pageSize, 1);
			} else if (this.isQuestionFilter) {
				this.questionFilter.currentPage = 1;
				this.pages = this.$global.getPages(this.amountElements, this.questionFilter.pageSize, 1);
			} else if (this.isUserFilter) {
				this.userFilter.currentPage = 1;
				this.pages = this.$global.getPages(this.amountElements, this.userFilter.pageSize, 1);
			} else if (this.isBugFilter) {
				this.bugFilter.currentPage = 1;
				this.pages = this.$global.getPages(this.amountElements, this.bugFilter.pageSize, 1);
			}
		},
		setPage(page) {
			if (this.isSessionFilter && page) this.sessionFilter.currentPage = page;
			else if (this.isQuizNightFilter && page) this.quizNightFilter.currentPage = page;
			else if (this.isQuestionFilter && page) this.questionFilter.currentPage = page;
			else if (this.isUserFilter && page) this.userFilter.currentPage = page;
			else if (this.isBugFilter && page) this.bugFilter.currentPage = page;

			this.refresh();
		},
		refresh() {
			this.loading = true;
			this.isLoadingStart = new Date();

			if (this.isSessionFilter) this.$emit('refresh', this.sessionFilter);
			else if (this.isQuizNightFilter) this.$emit('refresh', this.quizNightFilter);
			else if (this.isQuestionFilter) this.$emit('refresh', this.questionFilter);
			else if (this.isUserFilter) this.$emit('refresh', this.userFilter);
			else if (this.isBugFilter) this.$emit('refresh', this.bugFilter);
		},
	},
};
</script>

<style scoped>
.fb-wrap-content {
	width: 100%;
	height: 100%;
}

.fb-no-overflow {
	width: 100%;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	align-items: flex-start;
}

.fb-wrap-left {
	width: calc(100% - 240px);
	flex-flow: wrap;
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
}

.fb-wrap-option {
	padding: 2px 10px 10px 10px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.fb-wrap-option svg {
	padding-right: 10px;
	font-size: 25px;
	color: var(--main-color-text-light);
	filter: drop-shadow(2px 2px 2px var(--main-color-border-dark));
}

.fb-wrap-option > input {
	width: 50px;
	margin: 0px 5px;
	padding: 5px !important;
	box-sizing: content-box;
}

.fb-break-flow {
	height: 0px;
	width: 100%;
}

.fb-shows-dropdown {
	width: 190px;
	flex: 1 1 100%;
	margin-right: 10px;
	padding: 5px 0px 5px 5px !important;
	position: relative;
	border-bottom: 1px solid var(--main-color-border-light);
	font-size: 13px;
	text-align: start;
	cursor: pointer;
	user-select: none;
}

.fb-dropdown-select svg {
	font-size: 10px;
	float: right;
}

.fb-click-outside {
	width: 99999999px;
	height: 99999999px;
	position: absolute;
	top: -9999px;
	left: -9999px;
	background-color: #ffffff90;
	z-index: 10;
}

.fb-wrap-available-shows {
	width: fit-content;
	height: fit-content;
	padding: 2px;
	position: absolute;
	/* top: calc(100% + 5px);
	left: 0px; */
	z-index: 15;
	box-sizing: border-box;
	border: 1px solid var(--main-color-border-dark);
	border-radius: 10px;
	background-color: var(--main-color-1);
	box-shadow: 0px 0px 5px 1px var(--main-color-border-dark);
}

.fb-available-show {
	width: fit-content;
	/* min-width: 158px; */
	padding: 2px 5px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.fb-available-show input {
	cursor: pointer;
	box-shadow: none;
}

.fb-available-show label {
	padding-left: 5px;
	font-size: 17px;
	cursor: pointer;
	color: var(--main-color-text-dark);
}

.fb-small-show-logo {
	width: 30px;
	height: 30px;
	margin: 0px 5px;
	display: inline-block;
	border-radius: 10px;
	cursor: pointer;
}

.fb-wrap-searchbar {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding-left: 10px;
}

.fb-wrap-searchbar svg {
	padding-right: 10px;
	font-size: 25px;
	color: var(--main-color-text-light);
	filter: drop-shadow(2px 2px 2px var(--main-color-border-dark));
}

.fb-wrap-searchbar input {
	width: 170px;
	margin: 0px 5px;
	padding-left: 5px !important;
	text-align: center;
}

.fb-wrap-right {
	width: 260px;
	flex: 1 1 270px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-flow: wrap;
}

.fb-wrap-right div {
	width: 100%;
	flex: 1 1 240px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.fb-wrap-right select {
	margin-right: 10px;
	padding: 5px 0px !important;
	border: none;
	border-bottom: 1px solid var(--main-color-text-light);
	border-radius: 0px;
	color: var(--main-color-text-light);
	background-color: transparent;
}

.fb-right-top select {
	width: fit-content;
	padding-right: 5px !important;
	/* flex: 1 1 200px; */
}

.fb-right-bottom {
	padding: 5px 30px 0px 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow: wrap;
	font-size: 14px;
}

.fb-right-bottom select {
	width: 40px;
	margin-left: 5px;
}

.fb-wrap-right select:focus {
	box-shadow: none;
}

.fb-wrap-right select option {
	color: var(--main-color-text-dark);
	background-color: transparent;
}

.fb-wrap-right svg {
	font-size: 30px;
	color: var(--main-color-text-light);
	filter: drop-shadow(2px 2px 2px var(--main-color-border-dark));
}

.fb-wrap-right svg:hover {
	cursor: pointer;
	color: var(--main-color-6);
}

.fb-loading-spinner {
	-webkit-animation: spin 1s linear infinite;
	-moz-animation: spin 1s linear infinite;
	animation: spin 1s linear infinite;
}

.fb-switch {
	position: relative;
	display: inline-block;
	width: 40px;
	height: 26px;
}

.fb-switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

.fb-slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: var(--main-color-5);
	-webkit-transition: 0.4s;
	transition: 0.4s;
	border-radius: 26px;
}

.fb-slider:before {
	position: absolute;
	content: '';
	height: 20px;
	width: 20px;
	left: 3px;
	bottom: 3px;
	background-color: var(--main-color-text-light);
	-webkit-transition: 0.4s;
	transition: 0.4s;
	border-radius: 50%;
}

input:checked + .fb-slider {
	background-color: var(--main-color-success);
}

input:focus + .fb-slider {
	box-shadow: 0 0 1px var(--main-color-success);
}

input:checked + .fb-slider:before {
	-webkit-transform: translateX(13px);
	-ms-transform: translateX(13px);
	transform: translateX(13px);
}

.fb-pages {
	width: 100%;
	min-width: 240px;
	height: fit-content;
	display: block;
	margin: 10px 0px;
	overflow-x: auto;
}

.fb-page {
	display: inline-block;
	margin: 0px 5px !important;
}

.fb-no-hover {
	text-decoration: none !important;
	cursor: default !important;
}

.fb-page:hover > span {
	text-decoration: underline;
	cursor: pointer;
}

.fb-highlight-page {
	color: var(--main-color-6);
	pointer-events: none;
}

@-moz-keyframes spin {
	0% {
		-moz-transform: rotate(0deg);
	}
	100% {
		-moz-transform: rotate(360deg);
	}
}
@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}
@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
</style>
