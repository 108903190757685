<template>
	<div class="hc-wrap-content">
		<div v-if="showConfirmation" class="hc-wrap-confirmation-window">
			<ConfirmationWindow
				v-if="showConfirmation == 'addPoints'"
				:title="`${$t('hcAddPoints')}?`"
				:text="$t('hcAddPointsText')"
				:positiveBtnText="$t('hcYes')"
				:negativeBtnText="$t('hcNo')"
				@positiveEvent="addPoints($event, true)"
				@negativeEvent="addPoints($event, false)"
			/>
			<ConfirmationWindow
				v-else-if="showConfirmation == 'removePoints'"
				:title="`${$t('hcRemovePoints')}?`"
				:text="$t('hcRemovePointsText')"
				:positiveBtnText="$t('hcYes')"
				:negativeBtnText="$t('hcNo')"
				@positiveEvent="removePoints($event, true)"
				@negativeEvent="removePoints($event, false)"
			/>
			<ConfirmationWindow
				v-else-if="showConfirmation == 'nextQuestion'"
				:title="`${$t('hcNextQuestion')}?`"
				:text="`${$t('hcNextQuestionText')}`"
				:positiveBtnText="$t('hcYes')"
				:negativeBtnText="$t('hcNo')"
				@positiveEvent="nextQuestion($event, true)"
				@negativeEvent="nextQuestion($event, false)"
			/>
			<ConfirmationWindow
				v-else-if="showConfirmation == 'nextShow'"
				:title="`${$t('hcNextShow')}?`"
				:text="`${$t('hcNextShowText')}`"
				:positiveBtnText="$t('hcYes')"
				:negativeBtnText="$t('hcNo')"
				@positiveEvent="nextShow($event, true)"
				@negativeEvent="nextShow($event, false)"
			/>
			<ConfirmationWindow
				v-else-if="showConfirmation.split('_')[0] == 'correctAnswer'"
				:title="`${$t('hcCorrectAnswer')}?`"
				:text="`${$t('hcCorrectAnswerText')}`"
				:positiveBtnText="$t('hcYes')"
				:negativeBtnText="$t('hcNo')"
				@positiveEvent="correctAnswer($event, showConfirmation.split('_')[1], true)"
				@negativeEvent="correctAnswer($event, showConfirmation.split('_')[1], false)"
			/>
			<ConfirmationWindow
				v-else-if="showConfirmation.split('_')[0] == 'unlockAnswer'"
				:title="`${$t('hcUnlockAnswer')}?`"
				:text="`${$t('hcUnlockAnswerText')}`"
				:positiveBtnText="$t('hcYes')"
				:negativeBtnText="$t('hcNo')"
				@positiveEvent="unlockAnswer($event, showConfirmation.split('_')[1], true)"
				@negativeEvent="unlockAnswer($event, showConfirmation.split('_')[1], false)"
			/>
			<ConfirmationWindow
				v-else-if="showConfirmation == `toggleSolution`"
				:title="`${$t('hcToggleSolution')}?`"
				:text="`${$t('hcToggleSolutionText')}`"
				:positiveBtnText="$t('hcYes')"
				:negativeBtnText="$t('hcNo')"
				@positiveEvent="toggleSolution($event, true)"
				@negativeEvent="toggleSolution($event, false)"
			/>
			<ConfirmationWindow
				v-else-if="showConfirmation == `unlockBuzzer`"
				:title="`${$t('hcUnlockBuzzer')}?`"
				:text="`${$t('hcUnlockBuzzerText')}`"
				:positiveBtnText="$t('hcYes')"
				:negativeBtnText="$t('hcNo')"
				@positiveEvent="unlockBuzzer($event, true)"
				@negativeEvent="unlockBuzzer($event, false)"
			/>
			<ConfirmationWindow
				v-else-if="showConfirmation == `rightBuzzer`"
				:title="`${$t('hcRightBuzzer')}?`"
				:text="`${$t('hcRightBuzzerText')}`"
				:positiveBtnText="$t('hcYes')"
				:negativeBtnText="$t('hcNo')"
				@positiveEvent="rightBuzzer($event, pressedBuzzer.uID, true)"
				@negativeEvent="rightBuzzer($event, pressedBuzzer.uID, false)"
			/>
			<ConfirmationWindow
				v-else-if="showConfirmation == `wrongBuzzer`"
				:title="`${$t('hcWrongBuzzer')}?`"
				:text="`${$t('hcWrongBuzzerText')}`"
				:positiveBtnText="$t('hcYes')"
				:negativeBtnText="$t('hcNo')"
				@positiveEvent="wrongBuzzer($event, pressedBuzzer.uID, true)"
				@negativeEvent="wrongBuzzer($event, pressedBuzzer.uID, false)"
			/>
			<ConfirmationWindow
				v-else-if="showConfirmation.split('_')[0] == 'setCurrentBidder'"
				:title="`${$t('hcSetCurrentBidder')}?`"
				:text="`${$t('hcSetCurrentBidderText')}`"
				:positiveBtnText="$t('hcYes')"
				:negativeBtnText="$t('hcNo')"
				@positiveEvent="setCurrentBidder($event, showConfirmation.split('_')[1], true)"
				@negativeEvent="setCurrentBidder($event, showConfirmation.split('_')[1], false)"
			/>
			<ConfirmationWindow
				v-else-if="showConfirmation.split('_')[0] == 'unlockBid'"
				:title="`${$t('hcUnlockBid')}?`"
				:text="`${$t('hcUnlockBidText')}`"
				:positiveBtnText="$t('hcYes')"
				:negativeBtnText="$t('hcNo')"
				@positiveEvent="unlockBid($event, showConfirmation.split('_')[1], true)"
				@negativeEvent="unlockBid($event, showConfirmation.split('_')[1], false)"
			/>
			<div v-else-if="showConfirmation.split('_')[0] == 'setMoney'" class="hc-special-confirmation-window">
				<fai icon="fas fa-times-circle" class="hc-close-dialog" :title="$t('hcCloseWindow')" @click="showConfirmation = null"></fai>
				<p>{{ $t('hcGiveUserMoney') }}</p>
				<em>{{ $t('hcGiveUserMoneyText') }}</em>
				<p>{{ showConfirmation.split('_')[2] }} <fai icon="fas fa-coins" class="hc-coins"></fai></p>
				<div class="hc-wrap-coin-assign-buttons">
					<input type="number" min="0" v-model.number="extraMoney" />
					<button class="app-success-btn" :title="$t('hcAddMoney')" @click="setMoneyOfPlayer(showConfirmation.split('_')[1], true)">
						<fai icon="fas fa-plus"></fai>
					</button>
					<button class="app-error-btn" :title="$t('hcRemoveMoney')" @click="setMoneyOfPlayer(showConfirmation.split('_')[1], false)">
						<fai icon="fas fa-minus"></fai>
					</button>
				</div>
			</div>
			<ConfirmationWindow
				v-else-if="showConfirmation.split('_')[0] == 'assignCurrentItem'"
				:title="`${$t('hcAssignItem')}?`"
				:text="`${$t('hcAssignItemText')}`"
				:positiveBtnText="$t('hcYes')"
				:negativeBtnText="$t('hcNo')"
				@positiveEvent="assignCurrentItem($event, showConfirmation.split('_')[1], true)"
				@negativeEvent="assignCurrentItem($event, showConfirmation.split('_')[1], false)"
			/>
			<div v-else-if="showConfirmation.split('_')[0] == 'assignItem'" class="hc-special-confirmation-window">
				<fai
					icon="fas fa-times-circle"
					class="hc-close-dialog"
					:title="$t('hcCloseWindow')"
					@click="
						$event.stopPropagation();
						showConfirmation = null;
					"
				></fai>
				<template v-if="players.length > 1">
					<p>{{ $t('hcAssignItem') }}?</p>
					<em>{{ $t('hcAssignItemText') }}</em>
					<div
						v-for="player in players"
						:key="player.uID"
						class="hc-player-assignment"
						:title="$t('hcAssignItem')"
						@click="assignItem($event, player.uID, showConfirmation.split('_')[1])"
					>
						<img
							v-if="showConfirmation.split('_')[2] !== player.uID"
							:src="require(`@/assets/images/profilePictures/${player.profilePic}`)"
						/>
						<p v-if="showConfirmation.split('_')[2] !== player.uID">{{ player.name }}</p>
					</div>
				</template>
				<template v-else>
					<p>{{ $t('hcNoPlayersToAssign') }}</p>
				</template>
			</div>
			<ConfirmationWindow
				v-else-if="showConfirmation == 'revealItem'"
				:title="`${$t('hcRevealNextItem')}?`"
				:text="`${$t('hcRevealNextItemText')}`"
				:positiveBtnText="$t('hcYes')"
				:negativeBtnText="$t('hcNo')"
				@positiveEvent="revealItem($event, true)"
				@negativeEvent="revealItem($event, false)"
			/>
			<ConfirmationWindow
				v-else-if="showConfirmation == 'revealAllItems'"
				:title="`${$t('hcRevealAllItems')}?`"
				:text="`${$t('hcRevealAllItemsText')}`"
				:positiveBtnText="$t('hcYes')"
				:negativeBtnText="$t('hcNo')"
				@positiveEvent="revealAllItems($event, true)"
				@negativeEvent="revealAllItems($event, false)"
			/>
			<ConfirmationWindow
				v-else-if="showConfirmation == 'awardPointsWin'"
				:title="`${$t('hcAwardPointsAndFinishShow')}?`"
				:text="`${$t('hcAwardPointsAndFinishShowText')}`"
				:positiveBtnText="$t('hcYes')"
				:negativeBtnText="$t('hcNo')"
				@positiveEvent="awardPointsWin($event, true)"
				@negativeEvent="awardPointsWin($event, false)"
			/>
			<ConfirmationWindow
				v-else-if="showConfirmation == 'assignTopic'"
				:title="`${$t('hcAssignTopic')}?`"
				:text="`${$t('hcAssignTopicText')}`"
				:positiveBtnText="$t('hcYes')"
				:negativeBtnText="$t('hcNo')"
				@positiveEvent="assignTopic($event, true)"
				@negativeEvent="assignTopic($event, false)"
			/>
			<ConfirmationWindow
				v-else-if="showConfirmation.split('_')[0] == 'assignTopicGuessing'"
				:title="`${$t('hcAssignTopicGuessing')}?`"
				:text="`${$t('hcAssignTopicGuessingText')}`"
				:positiveBtnText="$t('hcYes')"
				:negativeBtnText="$t('hcNo')"
				@positiveEvent="assignTopicGuessing($event, true, showConfirmation.split('_')[1])"
				@negativeEvent="assignTopicGuessing($event, false, null)"
			/>
			<ConfirmationWindow
				v-else-if="showConfirmation == 'assignNewPlayer'"
				:title="`${$t('hcAssignNewPlayer')}?`"
				:text="$t('hcAssignNewPlayerText')"
				:positiveBtnText="$t('hcYes')"
				:negativeBtnText="$t('hcNo')"
				@positiveEvent="assignNewPlayer($event, true)"
				@negativeEvent="assignNewPlayer($event, false)"
			/>
			<ConfirmationWindow
				v-else-if="showConfirmation == 'startTimer'"
				:title="`${$t('hcStartTimer')}?`"
				:text="`${$t('hcStartTimerText')}`"
				:positiveBtnText="$t('hcYes')"
				:negativeBtnText="$t('hcNo')"
				@positiveEvent="startTimer($event, true)"
				@negativeEvent="startTimer($event, false)"
			/>
			<ConfirmationWindow
				v-else-if="showConfirmation == 'stopTimer'"
				:title="`${$t('hcStopTimer')}?`"
				:text="`${$t('hcStopTimerText')}`"
				:positiveBtnText="$t('hcYes')"
				:negativeBtnText="$t('hcNo')"
				@positiveEvent="stopTimer($event, true)"
				@negativeEvent="stopTimer($event, false)"
			/>
			<ConfirmationWindow
				v-else-if="showConfirmation == 'resetTimer'"
				:title="`${$t('hcResetTimer')}?`"
				:text="$t('hcResetText')"
				:positiveBtnText="$t('hcYes')"
				:negativeBtnText="$t('hcNo')"
				@positiveEvent="resetTimer($event, true)"
				@negativeEvent="resetTimer($event, false)"
			/>
			<ConfirmationWindow
				v-else-if="showConfirmation == 'resumeTimer'"
				:title="`${$t('hcResumeTimer')}?`"
				:text="`${$t('hcResumeTimerText')}`"
				:positiveBtnText="$t('hcYes')"
				:negativeBtnText="$t('hcNo')"
				@positiveEvent="resumeTimer($event, true)"
				@negativeEvent="resumeTimer($event, false)"
			/>
			<ConfirmationWindow
				v-else-if="showConfirmation == 'revealTopicTerm'"
				:title="`${$t('hcRevealNextTopicTerm')}?`"
				:text="`${$t('hcRevealNextTopicTermText')}`"
				:positiveBtnText="$t('hcYes')"
				:negativeBtnText="$t('hcNo')"
				@positiveEvent="revealTopicTerm($event, true)"
				@negativeEvent="revealTopicTerm($event, false)"
			/>
			<ConfirmationWindow
				v-else-if="showConfirmation == 'revealAllTopicTerms'"
				:title="`${$t('hcRevealAllTopicTerms')}?`"
				:text="`${$t('hcRevealAllTopicTermsText')}`"
				:positiveBtnText="$t('hcYes')"
				:negativeBtnText="$t('hcNo')"
				@positiveEvent="revealAllTopicTerms($event, true)"
				@negativeEvent="revealAllTopicTerms($event, false)"
			/>
			<ConfirmationWindow
				v-else-if="showConfirmation == 'assignNewCreator'"
				:title="`${$t('hcAssignNewCreator')}?`"
				:text="`${$t('hcAssignNewCreatorText')}`"
				:positiveBtnText="$t('hcYes')"
				:negativeBtnText="$t('hcNo')"
				@positiveEvent="assignNewCreator($event, true)"
				@negativeEvent="assignNewCreator($event, false)"
			/>
			<ConfirmationWindow
				v-else-if="showConfirmation.split('_')[0] == 'assignCategory'"
				:title="`${$t('hcAssignCategory')}?`"
				:text="`${$t('hcAssignCategoryText')}`"
				:positiveBtnText="$t('hcYes')"
				:negativeBtnText="$t('hcNo')"
				@positiveEvent="assignCategory($event, true, showConfirmation.split('_')[1])"
				@negativeEvent="assignCategory($event, false, null)"
			/>
			<ConfirmationWindow
				v-else-if="showConfirmation == 'restoreJoker'"
				:title="`${$t('hcRestoreJoker')}?`"
				:text="`${$t('hcRestoreJokerText')}`"
				:positiveBtnText="$t('hcYes')"
				:negativeBtnText="$t('hcNo')"
				@positiveEvent="restoreJoker($event, true)"
				@negativeEvent="restoreJoker($event, false)"
			/>
			<ConfirmationWindow
				v-else-if="showConfirmation == 'correctAnswerKQ'"
				:title="`${$t('hcCorrectAnswerKQ')}?`"
				:text="`${$t('hcCorrectAnswerKQText')}`"
				:positiveBtnText="$t('hcYes')"
				:negativeBtnText="$t('hcNo')"
				@positiveEvent="correctAnswerKQ($event, true)"
				@negativeEvent="correctAnswerKQ($event, false)"
			/>
			<ConfirmationWindow
				v-else-if="showConfirmation == 'wrongAnswerKQ'"
				:title="`${$t('hcWrongAnswerKQ')}?`"
				:text="`${$t('hcWrongAnswerKQText')}`"
				:positiveBtnText="$t('hcYes')"
				:negativeBtnText="$t('hcNo')"
				@positiveEvent="wrongAnswerKQ($event, true)"
				@negativeEvent="wrongAnswerKQ($event, false)"
			/>
			<ConfirmationWindow
				v-else-if="showConfirmation == 'endShow'"
				:title="`${$t('hcEndShow')}?`"
				:text="`${$t('hcEndShowText')}`"
				:positiveBtnText="$t('hcYes')"
				:negativeBtnText="$t('hcNo')"
				@positiveEvent="endShow($event, true)"
				@negativeEvent="endShow($event, false)"
			/>
		</div>
		<div class="hc-top-bar" :title="$t('hcToggleHostBar')" @click="toggleHostTools">
			<fai icon="fas fa-chevron-down" :class="[hostToolsExpanded ? 'hc-normal' : 'hc-rotated']" />
		</div>
		<div v-show="hostToolsExpanded" class="hc-show-manager">
			<div class="hc-show-manager-part">
				<h2>{{ $t('hcCustomPoints') }}</h2>
				<div class="hc-show-manager-layer">
					<input type="number" v-model.number="custom.points" min="0" @change="checkCustomPoints" />
					<p>{{ $t('hcPointsAbbrv') }}</p>
				</div>
				<div class="hc-show-manager-layer">
					<div class="hc-wrap-players">
						<div
							v-for="player in players"
							:key="player.uID"
							class="hc-player"
							:title="player.name"
							@click="selectPlayer(player.uID)"
							@mouseenter="hoveredPlayer = player.uID"
							@mouseleave="hoveredPlayer = null"
						>
							<img
								:src="require(`@/assets/images/profilePictures/${player.profilePic}`)"
								:class="[custom.selectedPlayers.includes(player.uID) ? 'hc-selected-player' : '']"
							/>
						</div>
					</div>
				</div>
				<div class="hc-show-manager-layer">
					<button class="app-success-btn" :title="$t('hcAddPoints')" @click="showConfirmation = 'addPoints'">
						<fai icon="fas fa-user-plus" />
					</button>
					<button class="app-error-btn" :title="$t('hcRemovePoints')" @click="showConfirmation = 'removePoints'">
						<fai icon="fas fa-user-minus" />
					</button>
				</div>
			</div>
			<div v-if="show.show != 'zgw'" class="hc-show-manager-part">
				<h2>{{ $t('hcQuestions') }}</h2>
				<div class="hc-wrap-question-flow">
					<!-- <button class="app-default-btn" :title="$t('hcLastQuestion')"  @click="showConfirmation = 'lastQuestion'">
						
						<fai icon="fas fa-chevron-circle-left" />
					</button> -->
					<button v-if="show.show == 'kq'" class="app-default-btn" :title="$t('hcEndShow')" @click="showConfirmation = 'endShow'">
						<fai icon="fas fa-ban" />
					</button>
					<button v-else class="app-default-btn" :title="$t('hcNextQuestion')" @click="showConfirmation = 'nextQuestion'">
						<fai icon="fas fa-chevron-circle-right" />
					</button>
				</div>
			</div>
		</div>
		<div v-show="hostToolsExpanded && getFreetextConditions()" class="hc-wrap-freetext-answers">
			<div class="hc-wrap-freetext-players">
				<div v-for="player in players" :key="player.uID" class="hc-wrap-player">
					<div :class="[hoveredPlayer == player.uID ? 'hc-hovered-player' : '', 'hc-player-name']">
						<p>
							{{ player.name }}
							<fai v-if="givenAnswers[player.uID] ? givenAnswers[player.uID].lockAnswer : false" icon="fas fa-lock" />
						</p>
					</div>
					<div :class="['hc-player-answer']">
						{{ parseTextAnswer(givenAnswers[player.uID]) }}
					</div>
					<button
						class="app-success-btn hc-correct-answer"
						:title="$t('hcCorrectAnswer')"
						@click="showConfirmation = `correctAnswer_${player.uID}`"
					>
						<fai icon="fas fa-check" />
					</button>
					<button class="app-default-btn" :title="$t('hcUnlockAnswer')" @click="showConfirmation = `unlockAnswer_${player.uID}`">
						<fai icon="fas fa-lock-open" />
					</button>
				</div>
			</div>

			<div v-show="show.show == 'smf'" class="hc-wrap-reveal-btn">
				<button
					v-if="currentQuestion && (currentQuestion.hasTwoImages || currentQuestion.imageIsAnswer)"
					class="app-default-btn"
					:title="$t('hcToggleSolution')"
					@click="showConfirmation = `toggleSolution`"
				>
					<fai icon="fas fa-eye" />
				</button>
			</div>
		</div>
		<div v-show="hostToolsExpanded && getBuzzerConditions()" class="hc-wrap-buzzer-answers">
			<div v-if="pressedBuzzer" class="hc-buzzer-user">
				<img :src="require(`@/assets/images/profilePictures/${pressedBuzzer.profilePic}`)" />
				<p>{{ pressedBuzzer.name }}</p>
			</div>
			<div v-if="!pressedBuzzer" class="hc-no-buzzer">
				{{ $t('hcNoBuzzerPressed') }}
			</div>
			<div class="hc-buzzer-controls">
				<button class="app-default-btn" :title="$t('hcUnlockBuzzer')" @click="showConfirmation = `unlockBuzzer`">
					<fai icon="fas fa-lock-open" />
				</button>

				<button v-if="pressedBuzzer" class="app-success-btn" :title="$t('hcRightBuzzer')" @click="showConfirmation = `rightBuzzer`">
					<fai icon="fas fa-check" />
				</button>
				<button v-if="pressedBuzzer" class="app-error-btn" :title="$t('hcWrongBuzzer')" @click="showConfirmation = `wrongBuzzer`">
					<fai icon="fas fa-times" />
				</button>
				<button
					v-if="currentQuestion && (currentQuestion.hasTwoImages || currentQuestion.imageIsAnswer)"
					class="app-default-btn"
					:title="$t('hcToggleSolution')"
					@click="showConfirmation = `toggleSolution`"
				>
					<fai icon="fas fa-eye" />
				</button>
			</div>
		</div>
		<div v-show="hostToolsExpanded && show.show == 'wlp' && wlpState" class="hc-wrap-wlp">
			<div v-if="wlpState" class="hc-wrap-users">
				<div
					v-for="user in getUsers()"
					:key="user.uID"
					class="hc-wrap-user"
					:title="!user.disableBid && !wlpState.revealState ? $t('ucCurrentBidder') : null"
				>
					<div
						:class="[
							hoveredPlayer == user.uID ? 'hc-hovered-player' : '',
							!user.disableBid && !wlpState.revealState ? 'hc-active-bidder' : '',
							'hc-user-info',
						]"
					>
						<img :src="require(`@/assets/images/profilePictures/${user.profilePic}`)" />
						<p>
							{{ user.name }}
							<fai v-if="user.lockBid && !wlpState.revealState" icon="fas fa-ban" :title="$t('ucUserLocked')" class="hc-lock-bid"></fai>
							<fai
								v-if="user.highestBid && !wlpState.revealState"
								icon="fas fa-chevron-circle-up"
								:title="$t('ucHighestBidder')"
								class="hc-highest-bid"
							></fai>
						</p>
					</div>
					<div class="hc-user-money">
						<div v-if="!wlpState.revealState" class="hc-money-of-user">
							<p :title="$t('hcSetMoney')" @click="showConfirmation = `setMoney_${user.uID}_${user.money}`">
								{{ $t('hcBid') }} {{ user.bid }} <fai icon="fas fa-coins"></fai>
							</p>
							<p :title="$t('hcSetMoney')" @click="showConfirmation = `setMoney_${user.uID}_${user.money}`">/</p>
							<p :title="$t('hcSetMoney')" @click="showConfirmation = `setMoney_${user.uID}_${user.money}`">
								{{ user.money }} <fai icon="fas fa-coins"></fai>
							</p>
						</div>
					</div>
					<div v-if="!wlpState.revealState" class="hc-user-items">
						<p>{{ $t('hcBoughtItems') }}</p>
						<div v-if="user.boughtItems.length > 0" class="hc-wrap-items">
							<div
								v-for="item in user.boughtItems"
								:key="item.qID"
								class="hc-item"
								:title="$t('hcAssignItem')"
								@click="!wlpState.revealState ? (showConfirmation = `assignItem_${item.qID}_${item.uID}`) : null"
							>
								<img :src="item.image" />
								<p>{{ item.bid }} <fai icon="fas fa-coins"></fai></p>
							</div>
						</div>
						<p v-else class="hc-no-items">{{ $t('hcNoItems') }}</p>
					</div>
					<div v-if="!wlpState.revealState" class="hc-wrap-wlp-controls">
						<button class="app-warn-btn" :title="$t('hcSetCurrentBidder')" @click="showConfirmation = `setCurrentBidder_${user.uID}`">
							<fai icon="fas fa-hand-point-up" />
						</button>
						<button class="app-default-btn" :title="$t('hcUnlockBid')" @click="showConfirmation = `unlockBid_${user.uID}`">
							<fai icon="fas fa-lock-open" />
						</button>
						<button
							class="app-success-btn"
							:title="$t('hcAssignCurrentItem')"
							@click="showConfirmation = `assignCurrentItem_${user.uID}`"
						>
							<fai icon="fas fa-check" />
						</button>
					</div>
					<div v-if="wlpState.revealState" class="hc-wrap-wlp-reveal">
						<p>{{ show.money }} <fai icon="fas fa-coins"></fai></p>
						<p v-for="state in getRevealedItems(user.uID)" :key="state.position" :class="getItemPriceDiff(state.diff)">
							{{ state.diff > 0 ? `+${state.diff}` : state.diff }} <fai icon="fas fa-coins"></fai>
						</p>
						<p :class="[getItemPriceDiff(getUserMoneyDiff(user.uID) - show.money), 'hc-diff']">
							{{ getUserMoneyDiff(user.uID) }} <fai icon="fas fa-coins"></fai>
						</p>
					</div>
				</div>
			</div>
			<div v-if="wlpState" class="hc-reveal-item">
				<div v-if="wlpState.revealState">
					<div v-if="wlpState.revealState.length < getQuestionsAndMoneyEvents().length">
						<button
							v-if="!revealAllClicked"
							class="app-default-btn"
							:title="$t('hcRevealNextItem')"
							@click="showConfirmation = 'revealItem'"
						>
							<fai icon="fas fa-eye" />
						</button>
						<button
							v-if="!revealAllClicked"
							class="app-success-btn"
							:title="$t('hcRevealAllItems')"
							@click="showConfirmation = 'revealAllItems'"
						>
							<fai icon="fas fa-eye" />
						</button>
					</div>
					<div v-else>
						<button class="app-success-btn" :title="$t('hcAwardPointsWin')" @click="showConfirmation = 'awardPointsWin'">
							<fai icon="fas fa-trophy" />
						</button>
					</div>
				</div>
			</div>
		</div>
		<div v-show="hostToolsExpanded && show.show == 'zgw'" class="hc-wrap-zgw">
			<div v-if="zgwState.phase == 'assignTopic'">
				<p>{{ $t('hcAvailablePlayers') }}</p>
				<div class="hc-wrap-players">
					<div class="hc-wrap-zgw-players">
						<div
							v-for="player in getAvailableZGWCreatingPlayers()"
							:key="player.uID"
							:title="$t('hcSelectPlayerForTopic')"
							@click="$emit('updateZGWState', { selectedCreator: player })"
							:class="[
								'hc-zgw-player',
								zgwState.selectedCreator ? (zgwState.selectedCreator.uID == player.uID ? 'hc-zgw-player-selected' : '') : '',
							]"
						>
							<img :src="require(`@/assets/images/profilePictures/${player.profilePic}`)" />
							<p>{{ player.name }}</p>
						</div>
					</div>
				</div>
				<p>{{ $t('hcAvailableTopics') }}</p>
				<div class="hc-wrap-zgw-topics">
					<div
						v-for="topic in getAvailableZGWTopics()"
						:key="topic.qID"
						:title="$t('hcSelectTopicForPlayer')"
						@click="$emit('updateZGWState', { selectedTopic: topic })"
						:class="[
							'hc-zgw-topic',
							zgwState.selectedTopic ? (zgwState.selectedTopic.qID == topic.qID ? 'hc-zgw-topic-selected' : '') : '',
						]"
					>
						<p>{{ topic.question }}</p>
					</div>
				</div>
				<div class="hc-zgw-assign-topic" v-if="zgwState.selectedCreator && zgwState.selectedTopic">
					<button class="app-success-btn" :title="$t('hcAssignTopic')" @click="showConfirmation = 'assignTopic'">
						<fai icon="fas fa-user" />
						<fai icon="fas fa-plus" />
						W
					</button>
				</div>
			</div>
			<div v-if="['lockTopicTerms', 'assignTopicGuessing', 'guessingTime'].includes(zgwState.phase)">
				<div class="hc-wrap-zgw-topic">
					<p>{{ $t('hcSelectedTopic') }}: {{ zgwState.selectedTopic.question }}</p>
				</div>
				<div class="hc-zgw-topic-creator">
					<img :src="require(`@/assets/images/profilePictures/${zgwState.selectedCreator.profilePic}`)" />
					<p>{{ zgwState.selectedCreator.name }} ({{ $t('hcCreator') }})</p>
				</div>
				<div v-if="zgwState.selectedGuesser" class="hc-zgw-topic-guesser">
					<img :src="require(`@/assets/images/profilePictures/${zgwState.selectedGuesser.profilePic}`)" />
					<p>{{ zgwState.selectedGuesser.name }} ({{ $t('hcGuesser') }})</p>
				</div>
				<div v-if="zgwState.phase == 'guessingTime'" class="hc-zgw-host-tip">
					<em>{{ $t('hcCheckTopicTerms') }}</em>
				</div>
				<div class="hc-wrap-created-terms">
					<div
						v-for="term in zgwState.proposedTopicTerms"
						:key="term"
						@click="zgwState.phase == 'guessingTime' ? updateAcceptedTerm(term) : null"
						:class="[
							'hc-zgw-topic-terms',
							zgwState.phase == 'guessingTime' ? 'hc-hoverable' : '',
							zgwState.acceptedTopicTerms.includes(term) ? 'hc-zgw-term-selected' : '',
						]"
					>
						{{ term }}
					</div>
				</div>
			</div>
			<div v-if="zgwState.phase == 'assignTopicGuessing' && !zgwState.selectedGuesser" class="hc-zgw-guesser-selection">
				<p>{{ $t('hcPlayersThatHaventGuessedYet') }}</p>
				<div class="hc-wrap-players">
					<div
						v-for="player in getAvailableZGWGuessingPlayers()"
						:key="player.uID"
						:title="$t('hcSelectPlayerForTopic')"
						@click="showConfirmation = `assignTopicGuessing_${player.uID}`"
						:class="[
							'hc-zgw-player',
							zgwState.selectedGuesser ? (zgwState.selectedGuesser.uID == player.uID ? 'hc-zgw-player-selected' : '') : '',
						]"
					>
						<img :src="require(`@/assets/images/profilePictures/${player.profilePic}`)" />
						<p>{{ player.name }}</p>
					</div>
				</div>
			</div>
			<div v-if="['assignTopicGuessing', 'guessingTime'].includes(zgwState.phase) && zgwState.selectedGuesser">
				<div class="hc-wrap-zgw-timer">
					<Timer
						:state="timer.state"
						:time="timer.time"
						:timerUpdate="timerUpdate"
						@currentTime="updateCurrentTime"
						@timerFinished="timer.state = 'INITIAL'"
					/>
				</div>
				<div class="hc-wrap-timer-buttons">
					<button
						:class="[zgwState.phase == 'assignTopicGuessing' ? 'app-success-btn' : 'app-disabled-btn']"
						:title="$t('hcStartTimer')"
						@click="showConfirmation = 'startTimer'"
					>
						<fai icon="fas fa-stopwatch" />
					</button>
					<button
						:class="[zgwState.phase == 'guessingTime' ? 'app-error-btn' : 'app-disabled-btn']"
						:title="$t('hcResetTimer')"
						@click="showConfirmation = 'resetTimer'"
					>
						<fai icon="fas fa-undo" />
					</button>
					<button
						v-if="
							zgwState.phase == 'assignTopicGuessing' ||
							(zgwState.phase == 'guessingTime' && this.timer.state != 'STOPED' && this.timer.state != 'INITIAL')
						"
						:class="[zgwState.phase == 'guessingTime' ? 'app-warn-btn' : 'app-disabled-btn']"
						:title="$t('hcStopTimer')"
						@click="showConfirmation = 'stopTimer'"
					>
						<fai icon="fas fa-pause" />
					</button>
					<button
						v-if="zgwState.phase == 'guessingTime' && (this.timer.state == 'STOPED' || this.timer.state == 'INITIAL')"
						:class="['app-success-btn']"
						:title="$t('hcResumeTimer')"
						@click="showConfirmation = 'resumeTimer'"
					>
						<fai icon="fas fa-play" />
					</button>
					<div class="hc-wrap-new-assign-btn">
						<button
							:class="[this.timer.state == 'INITIAL' && zgwState.selectedGuesser ? 'app-default-btn' : 'app-disabled-btn']"
							:title="$t('hcAssignNewPlayer')"
							@click="showConfirmation = 'assignNewPlayer'"
						>
							<fai icon="fas fa-user-lock" />
						</button>
					</div>
				</div>
			</div>
			<div v-if="zgwState.phase == 'topicSummary'" class="hc-reveal-item">
				<div v-if="zgwState.revealedTopicTerms.length !== zgwState.proposedTopicTerms.length">
					<button class="app-default-btn" :title="$t('hcRevealNextTopicTerm')" @click="showConfirmation = 'revealTopicTerm'">
						<fai icon="fas fa-eye" />
					</button>
					<button class="app-success-btn" :title="$t('hcRevealAllTopicTerms')" @click="showConfirmation = 'revealAllTopicTerms'">
						<fai icon="fas fa-eye" />
					</button>
				</div>
				<div v-else>
					<div v-if="getAvailableZGWCreatingPlayers().length == 0">
						<p>{{ $t('hcAllTermsRevealedAndNoPlayersLeft') }}</p>
						<button class="app-success-btn" :title="$t('hcNextShow')" @click="showConfirmation = 'nextShow'">
							<fai icon="fas fa-chevron-circle-right" />
						</button>
					</div>
					<div v-else>
						<p>{{ $t('hcAllTermsRevealedAndAssignNewCreator') }}</p>
						<button class="app-default-btn" :title="$t('hcAssignNewCreator')" @click="showConfirmation = 'assignNewCreator'">
							<fai icon="fas fa-user" />
						</button>
					</div>
				</div>
			</div>
		</div>
		<div v-show="hostToolsExpanded && show.show == 'kq'" class="hc-wrap-kq">
			<div v-if="kqState.selectedQuestion && currentQuestion" class="hc-wrap-question">
				<p class="hc-kq-category">
					{{ currentQuestion.category }} ({{ show.pointsPerLevel[kqState.selectedQuestion.level].points }} {{ $t('hcPointsAbbrv') }})
				</p>
			</div>
			<div v-if="kqState.state == 'assignCategory'">
				<p>{{ $t('hcAvailablePlayers') }}</p>
				<div class="hc-wrap-players">
					<div class="hc-wrap-kq-players">
						<div
							v-for="player in getAvailableKQPlayersForCategory()"
							:key="player.uID"
							:title="$t('hcSelectPlayerForTopic')"
							@click="showConfirmation = `assignCategory_${player.uID}`"
							class="hc-kq-player"
						>
							<img :src="require(`@/assets/images/profilePictures/${player.profilePic}`)" />
							<p>{{ player.name }}</p>
						</div>
						<div v-if="getAvailableKQPlayersForCategory().length == 0" class="hc-no-players-available">
							<p>{{ $t('hcNoPlayersAvailable') }}</p>
						</div>
					</div>
				</div>
			</div>
			<div
				v-if="
					(kqState.selectedQuestion && ['assignCategory', 'showQuestion', 'showHint'].includes(kqState.state)) ||
					getAvailableKQPlayers().length == 0
				"
				class="hc-wrap-kq-buttons"
			>
				<div v-if="kqState.state == 'showHint'" class="hc-wrap-restore">
					<button class="app-default-btn" :title="$t('hcRestoreJoker')" @click="showConfirmation = 'restoreJoker'">
						<span class="hc-joker"> &#x1F0CF; </span>
					</button>
				</div>
				<div
					v-if="getAvailableKQPlayers().length > 0 || (!selectedQuestionIsAnswered() && kqState.state != 'revealQuestion')"
					class="hc-wrap-points-buttons"
				>
					<button
						v-if="kqState.state !== 'assignCategory'"
						class="app-success-btn"
						:title="$t('hcCorrectAnswerKQ')"
						@click="showConfirmation = 'correctAnswerKQ'"
					>
						<fai icon="fas fa-check" />
					</button>
					<button
						v-if="kqState.state !== 'assignCategory'"
						class="app-error-btn"
						:title="$t('hcWrongAnswerKQ')"
						@click="showConfirmation = 'wrongAnswerKQ'"
					>
						<fai icon="fas fa-times" />
					</button>
					<button v-if="kqState.state == 'assignCategory'" class="app-default-btn" :title="$t('hcRevertClick')" @click="revertClick">
						<fai icon="fas fa-undo" />
					</button>
				</div>
				<div v-else class="hc-wrap-kq-button">
					<p>{{ $t('hcKQFinished') }}</p>
					<button class="app-default-btn" :title="$t('hcEndShow')" @click="showConfirmation = 'endShow'">
						<fai icon="fas fa-chevron-circle-right" />
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ConfirmationWindow from './ConfirmationWindow.vue';
import Timer from './gameshowElements/Timer.vue';
export default {
	name: 'HostControls',
	components: {
		Timer,
		ConfirmationWindow,
	},
	props: {
		show: {
			type: Object,
			required: true,
		},
		players: {
			type: Array,
			required: true,
		},
		givenAnswers: {
			type: Object,
			required: true,
		},
		history: {
			type: Array,
			required: true,
		},
		pressedBuzzer: {
			type: Object,
			required: false,
		},
		wlpState: {
			type: Object,
			required: false,
		},
		zgwState: {
			type: Object,
			required: false,
		},
		kqState: {
			type: Object,
			required: false,
		},
		timerUpdate: {
			type: Object,
			required: true,
		},
	},
	watch: {
		wlpState: {
			handler: function (newVal) {
				this.$nextTick(() => window.dispatchEvent(new Event('resize')));
			},
			deep: true,
		},
		zgwState: {
			handler: function (newVal) {
				this.$nextTick(() => window.dispatchEvent(new Event('resize'))); // if (newVal.phase == 'guessingTime') this.timer.state = 'STARTED';
			},
			deep: true,
		},
		kqState: {
			handler: function (newVal) {
				this.getCurrentQuestion();
				if (newVal.state == 'assignCategory' && !this.hostToolsExpanded) {
					this.$nextTick(() => {
						this.toggleHostTools();
					});
				} else this.$nextTick(() => window.dispatchEvent(new Event('resize')));
			},
			deep: true,
		},
		timerUpdate: {
			handler: function (newVal) {
				if (newVal.newState) this.timer.state = newVal.newState;
				if (newVal.newState == 'INITIAL') {
					this.timer.time = this.show.timer;
					this.timer.timeLeft = this.show.timer;
					sessionStorage.setItem('timeLeft', this.show.timer);
				}
			},
			deep: true,
		},
	},
	data() {
		return {
			hostToolsExpanded: false,
			custom: { points: this.show.pointsPos ? this.show.pointsPos : 0, selectedPlayers: [] },
			showConfirmation: false,
			hoveredPlayer: null,
			currentQuestion: null,
			extraMoney: 0,
			revealAllClicked: false,
			timer: {
				state: 'INITIAL',
				time: this.show.timer,
				timeLeft: this.show.timer,
			},
			timerUpdateInterval: null,
		};
	},
	created() {
		this.getCurrentQuestion();
	},
	mounted() {
		this.toggleHostTools();
	},
	beforeDestroy() {
		if (this.timerUpdateInterval) clearInterval(this.timerUpdateInterval);
		this.timerUpdateInterval = null;
	},
	methods: {
		getCurrentQuestion() {
			if (this.show.show == 'zgw') this.currentQuestion = this.show.questions.filter((quest) => quest.position == 0)[0];
			else if (this.show.show == 'kq') {
				if (this.kqState && this.kqState.selectedQuestion)
					this.currentQuestion = this.show.questions.filter((quest) => quest.qID == this.kqState.selectedQuestion.qID)[0];
				else this.currentQuestion = null;
			} else {
				let lastQuestions = this.history.filter((his) => his.action == 'setQuestion');
				if (lastQuestions.length > 0) {
					this.currentQuestion = this.show.questions.filter((quest) => quest.qID == lastQuestions[lastQuestions.length - 1].result)[0];
				} else {
					this.currentQuestion = this.show.questions.filter((quest) => quest.position == 0)[0];
				}
				this.$nextTick(() => window.dispatchEvent(new Event('resize')));
			}
		},
		toggleHostTools() {
			let target = document.querySelector('.hc-wrap-content');

			if (this.hostToolsExpanded) {
				target.style.height = '0px';
				target.style.paddingTop = '14px';
			} else {
				target.style.height = 'fit-content';
				target.style.paddingTop = '30px';
			}
			this.hostToolsExpanded = !this.hostToolsExpanded;
			this.$nextTick(() => window.dispatchEvent(new Event('resize')));
		},
		getFreetextConditions() {
			// Shows needed
			let showsNeeded = ['dpin', 'xfz', 'smf'].includes(this.show.show);

			if (['xfz', 'smf'].includes(this.show.show)) {
				// Correct question type
				let questionType = this.currentQuestion && ['Freetext', 'SingleChoice'].includes(this.currentQuestion.type);

				return showsNeeded && questionType;
			} else return showsNeeded;
		},
		getBuzzerConditions() {
			// Shows needed
			let showsNeeded = ['xfz', 'smf'].includes(this.show.show);

			// Correct question type
			let questionType = this.currentQuestion && ['Buzzer', 'BuzzerSingleChoice'].includes(this.currentQuestion.type);

			return showsNeeded && questionType;
		},
		parseTextAnswer(answer) {
			let ans = this.$t('hcNoAnswer');
			if (answer) {
				if (answer.textAnswer && answer.textAnswer !== '') {
					if (this.currentQuestion.type == 'SingleChoice') {
						let a = this.currentQuestion.answerPos.filter((a) => String(a.pos) == answer.textAnswer)[0];
						if (a) ans = `${String.fromCharCode(97 + Number(answer.textAnswer))})`;
					} else ans = answer.textAnswer;
				}
			}
			return ans;
		},
		correctAnswer(e, uID, give) {
			e.stopPropagation();
			if (give) this.$emit('correctAnswer', { points: this.show.pointsPos, uID: uID });
			this.showConfirmation = null;
		},
		unlockAnswer(e, uID, unlock) {
			e.stopPropagation();
			if (unlock) this.$emit('unlockAnswer', uID);
			this.showConfirmation = null;
		},
		lastQuestion(e, last) {
			e.stopPropagation();
			if (last) this.$emit('lastQuestion');
			this.showConfirmation = null;
		},
		nextQuestion(e, next) {
			e.stopPropagation();
			if (next) this.$emit('nextQuestion');
			this.showConfirmation = null;
		},
		nextShow(e, next) {
			e.stopPropagation();
			if (next) this.$emit('nextShow');
			this.showConfirmation = null;
		},
		selectPlayer(uID) {
			if (this.custom.selectedPlayers.includes(uID)) this.custom.selectedPlayers = this.custom.selectedPlayers.filter((user) => user !== uID);
			else this.custom.selectedPlayers.push(uID);
		},
		checkCustomPoints() {
			if (typeof this.custom.points == 'string' || !this.custom.points || this.custom.points < 0) this.custom.points = 0;
			else if (this.custom.points > 9999) this.custom.points = 9999;
		},
		addPoints(e, add) {
			e.stopPropagation();
			if (!this.custom.points == 0 && this.custom.selectedPlayers.length > 0 && add) {
				let custom = JSON.parse(JSON.stringify(this.custom));
				custom.mode = 'addPoints';
				this.$emit('setPoints', custom);
				this.custom.selectedPlayers = [];
			}
			this.showConfirmation = null;
		},
		removePoints(e, remove) {
			e.stopPropagation(remove);
			if (!this.custom.points == 0 && this.custom.selectedPlayers.length > 0 && remove) {
				let custom = JSON.parse(JSON.stringify(this.custom));
				custom.points = -Math.abs(custom.points);
				custom.mode = 'removePoints';
				this.$emit('setPoints', custom);
				this.custom.selectedPlayers = [];
			}
			this.showConfirmation = null;
		},
		unlockBuzzer(e, unlock) {
			e.stopPropagation();
			if (unlock) this.$emit('unlockBuzzer');

			this.showConfirmation = null;
		},
		rightBuzzer(e, uID, right) {
			e.stopPropagation();
			if (right) this.$emit('rightBuzzer', uID);

			this.showConfirmation = null;
		},
		wrongBuzzer(e, uID, wrong) {
			e.stopPropagation();
			if (wrong) this.$emit('wrongBuzzer', uID);

			this.showConfirmation = null;
		},
		toggleSolution(e, reveal) {
			e.stopPropagation();
			if (reveal) this.$emit('toggleSolution');

			this.showConfirmation = null;
		},
		getUsers() {
			let users = [];
			this.players.forEach((player) => {
				let boughtItems = this.getBoughtItems(player.uID);
				let bid = this.wlpState.bids.filter((bid) => bid.uID == player.uID)[0];
				bid = bid ? bid.bid : 0;

				let user = this.wlpState.users.filter((user) => user.uID == player.uID)[0];
				let highestBid = true;

				if (bid == 0) highestBid = false;
				else {
					this.wlpState.bids.forEach((b) => {
						if (bid < b.bid) highestBid = false;
					});
				}

				users.push({
					...player,
					boughtItems: boughtItems,
					bid: bid,
					lockBid: user.lockBid,
					disableBid: user.disableBid,
					money: user.money,
					highestBid: highestBid,
				});
			});
			return users;
		},
		getBoughtItems(uID) {
			let items = this.wlpState.items.filter((item) => item.uID == uID);
			let fullItems = [];
			this.show.questions.forEach((quest) => {
				let item = items.filter((i) => i.qID == quest.qID)[0];
				if (item) {
					fullItems.push({
						...quest,
						...item,
					});
				}
			});
			return fullItems;
		},
		assignItem(e, uID, qID) {
			e.stopPropagation();
			let his = this.history.filter((his) => his.qID == qID && 'highestBid' == his.action)[0];
			let target = this.wlpState.users.filter((user) => user.uID == uID)[0];
			let lastUser = this.wlpState.users.filter((user) => user.uID == his.result)[0];
			let bid = this.wlpState.bids.filter((bid) => bid.uID == uID)[0];
			if (target.money < Number(his.info) + bid.bid) this.$global.showToast('warn', this.$t('hcPlayerHasNotEnoughMoney'));
			else this.$emit('assignItem', { uID: uID, lastUser: lastUser, qID: qID, hID: his.hID, bid: Number(his.info) });

			this.showConfirmation = null;
		},
		assignCurrentItem(e, uID, assign) {
			e.stopPropagation();
			if (assign) {
				let qID = this.currentQuestion.qID;
				let playerBid = this.wlpState.bids.filter((bid) => bid.uID == uID && bid.qID == qID)[0];
				let bid = playerBid ? (playerBid.bid ? playerBid.bid : 1) : 1;

				let itemAlreadyPossesed = this.wlpState.items.filter((item) => item.qID == qID)[0];
				if (itemAlreadyPossesed) this.$global.showToast('warn', this.$t('hcItemAlreadyAssigned'));
				else this.$emit('assignCurrentItem', { uID: uID, qID: qID, bid: bid });
			}
			this.showConfirmation = null;
		},
		setMoneyOfPlayer(uID, add) {
			let mon = Number(this.extraMoney);
			if (mon !== 0) {
				if (mon < 0) mon *= -1;
				let money = Number(this.wlpState.users.filter((user) => user.uID == uID)[0].money);
				money = add ? money + mon : money - mon;
				if (money < 0) money = 0;
				this.$emit('setMoney', { uID: uID, money: money, qID: this.currentQuestion.qID });
				this.extraMoney = 0;
			}
			this.showConfirmation = null;
		},
		setCurrentBidder(e, uID, set) {
			e.stopPropagation();
			if (set) this.$emit('setCurrentBidder', { uID: uID, qID: this.currentQuestion.qID });

			this.showConfirmation = null;
		},
		unlockBid(e, user, unlock) {
			e.stopPropagation();
			if (unlock) {
				if (this.wlpState.users.filter((u) => u.uID == user && !u.lockBid).length > 0)
					this.$global.showToast('warn', this.$t('hcUserIsUnlocked'));
				else this.$emit('unlockBid', { uID: user, qID: this.currentQuestion.qID });
			}

			this.showConfirmation = null;
		},
		getQuestionsAndMoneyEvents() {
			let moneyAndItemEvents = this.history.filter(
				(his) => his.gID == this.show.gID && ['setMoney', 'highestBid', 'skipItem'].includes(his.action)
			);
			let revealItems = [];

			moneyAndItemEvents.forEach((it) => {
				if (it.action == 'highestBid') {
					let event = {
						type: 'ITEM',
						...this.show.questions.filter((quest) => quest.qID == it.qID)[0],
					};
					event.uID = it.result;
					revealItems.push(event);
				} else if (it.action == 'skipItem') {
					revealItems.push({
						type: 'SKIP',
						...this.show.questions.filter((quest) => quest.qID == it.qID)[0],
					});
				} else if (it.action == 'setMoney') {
					revealItems.push({
						type: 'MONEY',
						uID: it.info,
						qID: it.qID,
						money: it.result,
						position: 0,
						timestamp: it.timepoint,
					});
				}
			});
			revealItems.map((it, idx) => {
				it.position = idx;
				return it;
			});

			return revealItems;
		},
		revealItem(e, reveal) {
			e.stopPropagation();
			if (reveal && !this.revealAllClicked) {
				let events = this.getQuestionsAndMoneyEvents();
				let event = events[this.wlpState.revealState.length];

				if (event.type == 'MONEY') {
					let previousEvents = events.slice(0, this.wlpState.revealState.length).filter((it) => it.uID == event.uID);
					let currentMoney = this.show.money;

					previousEvents.forEach((it) => {
						if (it.type == 'ITEM') {
							let item = this.wlpState.items.filter((item) => item.qID == it.qID)[0];
							currentMoney -= item.bid;
						} else if (it.type == 'MONEY') currentMoney = Number(it.money);
					});

					let profit = Number(event.money) - currentMoney;

					this.$emit('revealItem', {
						type: 'MONEY',
						qID: event.qID,
						uID: event.uID,
						position: event.position,
						diff: profit,
					});
				} else if (event.type == 'SKIP') {
					this.$emit('revealItem', {
						type: 'SKIP',
						qID: event.qID,
						uID: null,
						position: event.position,
						diff: null,
					});
				} else {
					let item = this.wlpState.items.filter((item) => item.qID == event.qID)[0];
					let answer = Number(this.show.questions.filter((quest) => quest.qID == event.qID)[0].answer);

					this.$emit('revealItem', {
						type: 'ITEM',
						qID: event.qID,
						uID: item ? item.uID : null,
						position: event.position,
						diff: item ? answer - item.bid : 0,
					});
				}
			}
			this.showConfirmation = null;
		},
		revealAllItems(e, reveal) {
			e.stopPropagation();
			if (reveal && !this.revealAllClicked) {
				this.revealAllClicked = true;
				let unrevealedEvents = [];
				let events = this.getQuestionsAndMoneyEvents();

				events.forEach((event) => {
					if (this.wlpState.revealState.filter((rev) => rev.position == event.position).length == 0) unrevealedEvents.push(event);
				});

				let revealEvents = [];
				unrevealedEvents.forEach((event) => {
					if (event.type == 'MONEY') {
						let previousEvents = events.slice(0, this.wlpState.revealState.length).filter((it) => it.uID == event.uID);
						let currentMoney = this.show.money;

						previousEvents.forEach((it) => {
							if (it.type == 'ITEM') {
								let item = this.wlpState.items.filter((item) => item.qID == it.qID)[0];
								currentMoney -= item.bid;
							} else if (it.type == 'MONEY') currentMoney = Number(it.money);
						});

						let profit = Number(event.money) - currentMoney;

						revealEvents.push({
							type: 'MONEY',
							qID: event.qID,
							uID: event.uID,
							position: event.position,
							diff: profit,
						});
					} else if (event.type == 'SKIP') {
						revealEvents.push({
							type: 'SKIP',
							qID: event.qID,
							uID: null,
							position: event.position,
							diff: null,
						});
					} else {
						let item = this.wlpState.items.filter((item) => item.qID == event.qID)[0];
						let answer = Number(this.show.questions.filter((quest) => quest.qID == event.qID)[0].answer);

						revealEvents.push({
							type: 'ITEM',
							qID: event.qID,
							uID: item ? item.uID : null,
							position: event.position,
							diff: item ? answer - item.bid : 0,
						});
					}
				});

				if (revealEvents.length == 0) this.$global.showToast('info', this.$t('hcAllItemsRevealed'));
				else this.$emit('revealAllItems', revealEvents);
			}
			this.showConfirmation = null;
		},
		awardPointsWin(e, award) {
			e.stopPropagation();
			if (award && this.wlpState.revealState.length == this.getQuestionsAndMoneyEvents().length) this.$emit('awardWLPPoints');
			this.showConfirmation = null;
		},
		getRevealedItems(uID) {
			let diffs = [];
			this.wlpState.revealState.forEach((it) => {
				if (it.uID == uID) diffs.push(it);
			});

			return diffs;
		},
		getItemPriceDiff(diff) {
			if (diff < 0) return 'hc-red';
			else if (diff > 0) return 'hc-green';
			else return 'hc-white';
		},
		getUserMoneyDiff(uID) {
			let diff = this.show.money;
			let reveals = this.wlpState.revealState.filter((state) => state.uID == uID);
			reveals.forEach((state) => {
				diff += state.diff;
			});

			return diff;
		},
		getAvailableZGWCreatingPlayers() {
			let availablePlayers = [];
			let playersThatDidChooseTopic = this.history.filter((his) => his.action == 'assignTopic').map((his) => his.info);
			this.players.forEach((player) => {
				if (!playersThatDidChooseTopic.includes(player.uID)) availablePlayers.push(player);
			});
			return availablePlayers;
		},
		getAvailableZGWGuessingPlayers() {
			let availablePlayers = [];
			let playersThatDidGuessTopic = this.history
				.filter((his) => his.action == 'setAnswers' && his.qID == this.zgwState.selectedTopic.qID)
				.map((his) => his.info);
			this.players.forEach((player) => {
				if (!playersThatDidGuessTopic.includes(player.uID) && player.uID !== this.zgwState.selectedCreator.uID) availablePlayers.push(player);
			});
			return availablePlayers;
		},
		getAvailableZGWTopics() {
			let availableTopics = [];
			let choosenTopics = this.history.filter((his) => his.action == 'assignTopic').map((his) => his.result);
			this.show.questions.forEach((quest) => {
				if (!choosenTopics.includes(quest.qID)) availableTopics.push(quest);
			});
			return availableTopics;
		},
		assignTopic(e, assign) {
			e.stopPropagation();
			if (assign) this.$emit('assignTopic');
			this.showConfirmation = null;
		},
		assignTopicGuessing(e, assign, uID) {
			e.stopPropagation();
			if (assign) this.$emit('assignTopicGuessing', this.players.filter((player) => player.uID == uID)[0]);
			this.showConfirmation = null;
		},
		assignNewPlayer(e, assign) {
			e.stopPropagation();
			if (assign) this.$emit('assignNewPlayer');
			this.showConfirmation = null;
		},
		updateAcceptedTerm(term) {
			this.$emit('updateAcceptedTerm', term);
		},
		revealTopicTerm(e, reveal) {
			e.stopPropagation();
			if (reveal) this.$emit('revealTopicTerm');
			this.showConfirmation = null;
		},
		revealAllTopicTerms(e, reveal) {
			e.stopPropagation();
			if (reveal) this.$emit('revealAllTopicTerms');
			this.showConfirmation = null;
		},
		assignNewCreator(e, assign) {
			e.stopPropagation();
			if (assign) this.$emit('assignNewCreator');
			this.showConfirmation = null;
		},
		startTimer(e, start) {
			e.stopPropagation();
			if (start) {
				if (!this.timerUpdateInterval) this.timerUpdateInterval = this.getTimerUpdateInterval();
				this.$emit('startTimer');
			}
			this.showConfirmation = null;
		},
		stopTimer(e, stop) {
			e.stopPropagation();
			if (stop) {
				// if (this.timerUpdateInterval) clearInterval(this.timerUpdateInterval);
				// this.timerUpdateInterval = null;
				this.timer.state = 'STOPED';
				this.$emit('stopTimer', { timeLeft: this.timer.timeLeft });
			}
			this.showConfirmation = null;
		},
		resetTimer(e, reset) {
			e.stopPropagation();
			if (reset) {
				sessionStorage.removeItem('timeLeft');
				if (this.timerUpdateInterval) clearInterval(this.timerUpdateInterval);
				this.timerUpdateInterval = null;
				this.timer.state = 'INITIAL';
				this.$emit('resetTimer');
			}
			this.showConfirmation = null;
		},
		resumeTimer(e, resume) {
			e.stopPropagation();
			if (resume) {
				if (!this.timerUpdateInterval) this.timerUpdateInterval = this.getTimerUpdateInterval();
				this.$emit('resumeTimer', { timeLeft: this.timer.timeLeft });
			}
			this.showConfirmation = null;
		},
		updateCurrentTime(timeLeft) {
			this.timer.timeLeft = timeLeft;
			sessionStorage.setItem('timeLeft', timeLeft);
		},
		getTimerUpdateInterval() {
			return window.setInterval(() => {
				if (this.timer.timeLeft <= 0) {
					clearInterval(this.timerUpdateInterval);
					this.timerUpdateInterval = null;
					this.timer.timeLeft = 0;
					this.timer.state = 'INITIAL';
				}
				this.$emit('updateTimer', { timeLeft: this.timer.timeLeft, state: this.timer.state });
			}, 1000);
		},
		getAvailableKQPlayers() {
			try {
				let maxQuestionsPerPlayer = this.kqState.multipleAnswers
					? Math.floor((this.show.questions.length * this.show.pointsPerLevel.length) / this.players.length)
					: this.show.questions.length;
				let availablePlayers = [];
				this.players.forEach((player) => {
					if (this.kqState.usedQuestions.filter((it) => it.uID == player.uID).length < maxQuestionsPerPlayer) availablePlayers.push(player);
				});

				return availablePlayers;
			} catch (error) {
				return [];
			}
		},
		getAvailableKQPlayersForCategory() {
			let playersThatStillCanGuess = this.getAvailableKQPlayers();
			if (this.kqState.multipleAnswers || playersThatStillCanGuess.length == 0) return playersThatStillCanGuess;
			else {
				let availablePlayers = [];
				playersThatStillCanGuess.forEach((player) => {
					if (this.kqState.usedQuestions.filter((it) => it.qID == this.kqState.selectedQuestion.qID && it.uID == player.uID).length == 0)
						availablePlayers.push(player);
				});
				return availablePlayers;
			}
		},
		selectedQuestionIsAnswered() {
			if (this.kqState && this.kqState.selectedQuestion) {
				let selQ = this.kqState.selectedQuestion;
				let usedQ = this.kqState.usedQuestions.filter((it) => it.uID == selQ.uID && it.qID == selQ.qID && it.level == selQ.level)[0];
				if (usedQ && usedQ.answered) return true;
				else return false;
			} else return true;
		},
		assignCategory(e, assign, uID) {
			e.stopPropagation();
			if (assign) this.$emit('assignCategory', uID);
			this.showConfirmation = null;
		},
		restoreJoker(e, restore) {
			e.stopPropagation();
			if (restore) this.$emit('restoreJoker');
			this.showConfirmation = null;
		},
		revertClick(e) {
			e.stopPropagation();
			this.$emit('revertClick');
			this.showConfirmation = null;
		},
		correctAnswerKQ(e, correct) {
			e.stopPropagation();
			if (correct) this.$emit('correctAnswerKQ');
			this.showConfirmation = null;
		},
		wrongAnswerKQ(e, wrong) {
			e.stopPropagation();
			if (wrong) this.$emit('wrongAnswerKQ');
			this.showConfirmation = null;
		},
		endShow(e, end) {
			e.stopPropagation();
			if (end) this.$emit('nextShow');
			this.showConfirmation = null;
		},
	},
};
</script>

<style scoped>
.hc-wrap-content {
	width: 100%;
	height: 0px;
	max-height: 80vh;
	padding: 10px;
	padding-top: 30px;
	box-sizing: border-box;
	position: relative;
	background-color: var(--main-color-dark-transparent-cc);
	-webkit-transition: height 1s ease-out;
	-moz-transition: height 1s ease-out;
	transition: height 1s ease-out;
	z-index: 11;
}

.hc-top-bar {
	width: 100%;
	height: 20px;
	position: absolute;
	top: 0px;
	left: 0px;
	border-top: 1px solid var(--main-color-border-dark);
	border-bottom: 1px solid var(--main-color-border-dark);
	text-align: center;
	background-color: var(--main-color-light-transparent-80);
	color: var(--main-color-text-light);
}

.hc-top-bar svg {
	font-size: 20px;
	transition: 1s;
}

.hc-normal {
	transform: rotate(0deg);
}

.hc-rotated {
	transform: rotate(180deg);
}

.hc-top-bar:hover {
	cursor: pointer;
	background-color: var(--main-color-light-transparent-cc);
	color: var(--main-color-text-dark);
}

.hc-wrap-confirmation-window {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0px;
	left: 0px;
	z-index: 12;
	padding-left: 260px;
	display: flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	overflow: auto;
	background-color: var(--main-color-dark-transparent-cc);
}

.hc-special-confirmation-window {
	width: calc(100% - 260px);
	box-sizing: border-box;
	text-align: center;
}

.hc-special-confirmation-window > svg {
	font-size: 30px;
}

.hc-coins {
	color: var(--main-color-gold);
}

.hc-wrap-coin-assign-buttons {
	display: flex;
	justify-content: center;
	align-items: center;
}

.hc-wrap-coin-assign-buttons > input,
.hc-special-confirmation-window > input {
	width: 100px;
	font-size: 25px;
}

.hc-wrap-coin-assign-buttons > p,
.hc-special-confirmation-window > p {
	font-size: 30px;
	margin: 5px auto 10px;
}

.hc-wrap-coin-assign-buttons > button,
.hc-special-confirmation-window > button {
	min-width: 100px;
	font-size: 20px;
}

.hc-special-confirmation-window > em {
	max-width: 1000px;
	margin: 10px auto;
	padding: 0px 10px;
	display: block;
	color: var(--main-color-text-light);
}

.hc-wrap-content button {
	width: fit-content;
	height: fit-content;
	margin: 0px 10px;
	font-size: 17px;
}

.hc-show-manager {
	width: 242px;
	height: fit-content;
	display: inline-block;
	padding-right: 10px;
	padding-bottom: 10px;
	vertical-align: top;
	text-align: start;
	border-right: 2px solid var(--main-color-border-light);
	border-bottom: 2px solid var(--main-color-border-light);
}

.hc-show-manager-part:nth-child(2) {
	margin: 15px 0px 0px 0px;
}

.hc-wrap-question-flow {
	width: 100%;
	text-align: center;
	margin-bottom: 5px;
}

.hc-wrap-question-flow button {
	position: relative;
	z-index: 10;
}

.hc-show-manager-layer {
	margin: 5px auto;
	text-align: center;
}

.hc-show-manager-layer:last-child {
	text-align: center;
}

.hc-show-manager h2 {
	width: fit-content;
	margin: 0px auto 5px auto;
	display: block;
	font-size: 17px;
	color: var(--main-color-text-light);
}

.hc-show-manager input {
	width: 60px;
	height: fit-content;
	display: inline-block;
	padding: 5px 10px !important;
	margin-left: 2px;
}

.hc-show-manager p {
	max-width: 30px;
	width: 30px;
	display: inline-block;
	margin-left: 5px;
	font-size: 17px;
	color: var(--main-color-text-light);
}

.hc-wrap-players {
	width: 100%;
	height: fit-content;
	display: flex;
	flex-flow: wrap;
	justify-content: center;
	align-items: center;
}

.hc-player {
	/* flex: 1 1 30px; */
	margin: 5px;
	text-align: center;
}

.hc-player img {
	width: 30px;
	height: 30px;
	box-shadow: 0px 0px 4px 4px var(--main-color-border-dark);
	border-radius: 50%;
}

.hc-player:hover img {
	cursor: pointer;
	box-shadow: 0px 0px 4px 4px var(--main-color-6);
	border-radius: 50%;
}

.hc-selected-player {
	box-shadow: 0px 0px 4px 4px var(--main-color-6) !important;
}

.hc-hovered-player p {
	background-color: var(--main-color-1);
	color: var(--main-color-text-dark) !important;
	box-shadow: 0px 0px 4px 4px var(--main-color-border-light);
	border-bottom: 2px solid var(--main-color-border-light) !important;
	border-radius: 20px;
}

.hc-show-manager select {
	width: 100%;
}

.hc-wrap-freetext-answers {
	width: calc(100% - 274px);
	vertical-align: top;
	display: inline-block;
}

.hc-wrap-freetext-players {
	width: 100%;
	height: inherit;
	display: inline-flex;
	margin-left: 20px;
	vertical-align: top;
	flex-flow: wrap;
	z-index: 10;
}

.hc-wrap-reveal-btn {
	width: 100%;
	height: inherit;
	margin: 10px 0px 0px 20px;
	display: inline-block;
	vertical-align: top;
	text-align: center;
	position: relative;
	z-index: 11;
}

.hc-wrap-buzzer-answers {
	width: calc(100% - 274px);
	height: inherit;
	display: inline-flex;
	margin-left: 20px;
	vertical-align: top;
	flex-flow: wrap;
}

.hc-wrap-player {
	text-align: center;
	display: block;
	flex: 1 1 150px;
	margin-bottom: 20px;
}

.hc-player-name p {
	width: fit-content;
	height: 25px;
	margin: 0px auto 10px auto;
	padding: 0px 10px;
	font-size: 17px;
	font-weight: bold;
	color: var(--main-color-text-light);
	border-bottom: 2px solid var(--main-color-border-light);
	white-space: nowrap;
}

.hc-player-name p svg {
	color: var(--main-color-6);
	filter: drop-shadow(3px 3px 2px var(--main-color-text-dark));
	/* stroke-width: 40px;
	stroke: var(--main-color-border-dark); */
}

.hc-player-answer {
	height: calc(100% - 65px);
	padding: 0px 5px;
	box-sizing: border-box;
	color: var(--main-color-text-light);
}

.hc-answer-locked p {
	border-bottom: 2px solid var(--main-color-6);
}

.hc-wrap-player button {
	height: 30px;
	margin-top: 5px;
	vertical-align: bottom;
}

.hc-correct-answer {
	position: relative;
	z-index: 10;
}

.hc-buzzer-user {
	width: 100%;
	height: fit-content;
	margin-top: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.hc-buzzer-user img {
	width: 50px;
	height: 50px;
	margin-right: 10px;
}

.hc-buzzer-user p {
	font-size: 20px;
}

.hc-buzzer-controls {
	width: 100%;
	text-align: center;
	margin: 20px 0px;
}

.hc-buzzer-controls button {
	width: 50px;
}

.hc-no-buzzer {
	width: 100%;
	margin: 10px;
	box-sizing: border-box;
	color: var(--main-color-text-light);
	font-size: 25px;
	text-align: center;
}

.hc-wrap-zgw {
	width: calc(100% - 274px);
	height: inherit;
	margin-left: 20px;
	display: inline-block;
	vertical-align: top;
}

.hc-wrap-zgw button {
	min-width: 100px;
	font-size: 20px;
}

.hc-wrap-zgw > p {
	width: 100%;
	margin: 5px 0px;
	font-size: 20px;
	text-align: center;
}

.hc-wrap-zgw-players {
	width: 100%;
	height: fit-content;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow: wrap;
}

.hc-zgw-player {
	flex: 1 1 100px;
	min-width: 100px;
	max-width: fit-content;
	height: 75px;
	margin: 5px;
	text-align: center;
	cursor: pointer;
}

.hc-zgw-player img {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	border: 2px solid transparent;
}

.hc-zgw-player:hover > img {
	border: 2px solid var(--main-color-6);
}

.hc-zgw-player-selected > img {
	border: 2px solid var(--main-color-6);
}

.hc-wrap-zgw-topics {
	width: 100%;
	height: fit-content;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow: wrap;
}

.hc-zgw-topic {
	flex: 1 1 100px;
	max-width: fit-content;
	height: fit-content;
	margin: 5px 10px;
	padding: 15px;
	text-align: center;
	cursor: pointer;
	border: 1px solid var(--main-color-border-dark);
	border-radius: 10px;
	background-color: var(--main-color-3);
}

.hc-zgw-topic:hover {
	background-color: var(--main-color-6);
}

.hc-zgw-topic-selected {
	background-color: var(--main-color-6);
}

.hc-zgw-assign-topic {
	width: 100%;
	height: fit-content;
	margin-top: 10px;
	text-align: center;
}

.hc-zgw-assign-topic button > svg {
	margin: 0px 3px;
}

.hc-zgw-topic-creator,
.hc-zgw-topic-guesser {
	margin-right: 15px;
	display: inline-flex;
	justify-content: flex-start;
	align-items: center;
}

.hc-zgw-topic-creator img,
.hc-zgw-topic-guesser img {
	width: 50px;
	height: 50px;
	margin-right: 10px;
	display: inline-block;
	border-radius: 50%;
	border: 2px solid var(--main-color-border-light);
}

.hc-zgw-host-tip {
	width: 100%;
	height: fit-content;
	margin: 5px 0px;
	padding: 0px 20px;
	box-sizing: border-box;
	text-align: center;
	color: var(--main-color-text-light);
}

.hc-wrap-zgw-topic {
	width: 100%;
	height: fit-content;
	margin-bottom: 10px;
	text-align: center;
	font-size: 20px;
}

.hc-wrap-created-terms {
	width: 100%;
	height: fit-content;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow: wrap;
}

.hc-zgw-topic-terms {
	flex: 1 1 100px;
	margin: 10px 5px;
	padding: 10px;
	position: relative;
	border: 2px solid var(--main-color-border-dark);
	border-radius: 5px;
	background-color: var(--main-color-3);
	color: var(--main-color-text-light);
}

.hc-zgw-guesser-selection {
	width: 100%;
	height: fit-content;
	text-align: center;
}

.hc-zgw-guesser-selection > p {
	font-size: 18px;
	margin: 10px 0px;
}

.hc-wrap-timer-buttons {
	width: 100%;
	height: fit-content;
	margin-top: 10px;
	text-align: end;
}

.hc-wrap-timer-buttons button {
	width: 100px;
	font-size: 20px;
}

.hc-wrap-new-assign-btn {
	margin: 10px 0px;
}

.hc-zgw-term-selected {
	background-color: var(--main-color-6);
}

.hc-hoverable {
	cursor: pointer;
}

.hc-wrap-wlp {
	width: calc(100% - 274px);
	height: inherit;
	margin-left: 20px;
	display: inline-block;
	vertical-align: top;
}

.hc-wrap-users {
	width: 100%;
	height: fit-content;
	padding: 0px 30px;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	box-sizing: border-box;
	flex-flow: wrap;
}

.hc-wrap-user {
	width: fit-content;
	height: fit-content;
	margin-bottom: 20px;
	flex: 1 1 200px;
}

.hc-user-info {
	width: 100%;
	height: fit-content;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.hc-user-info img {
	width: 30px;
	height: 30px;
	display: inline-block;
	border: 2px solid var(--main-color-border-dark);
	border-radius: 50%;
	box-sizing: border-box;
}

.hc-user-info p {
	width: fit-content;
	display: inline-block;
	margin-left: 10px;
	font-size: 20px;
}

.hc-active-bidder p {
	color: var(--main-color-6);
	text-decoration: underline;
}

.hc-lock-bid {
	color: var(--main-color-error) !important;
}

.hc-highest-bid {
	color: var(--main-color-success) !important;
}

.hc-user-money {
	width: 100%;
	height: fit-content;
	margin-top: 10px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	position: relative;
}

.hc-money-of-user:hover p {
	color: var(--main-color-6) !important;
}

.hc-user-money p {
	width: fit-content;
	display: inline-block;
	margin-right: 10px;
	font-size: 20px;
	cursor: pointer;
}

.hc-user-money p svg {
	color: var(--main-color-gold);
}

.hc-user-money-config {
	width: 160px;
	padding: 5px 10px;
	position: absolute;
	bottom: 40px;
	left: 0px;
	border: 2px solid var(--main-color-border-dark);
	border-radius: 20px;
	background-color: var(--main-color-dark-transparent-cc);
	filter: none;
}

.hc-close-dialog {
	position: absolute;
	top: 5px;
	right: 5px;
	font-size: 20px;
	-webkit-text-stroke: 1px var(--main-color-border-dark);
	color: var(--main-color-error);
}

.hc-close-dialog:hover {
	color: var(--secondary-color-error);
	cursor: pointer;
}

.hc-user-money-config input {
	width: 100%;
	box-sizing: border-box;
}

.hc-user-money-config p {
	width: fit-content !important;
	max-width: fit-content !important;
	margin: 0px auto;
	filter: none !important;
	font-size: 17px;
	color: var(--main-color-text-light);
}

.hc-user-money-config button {
	margin: 5px 5px 0px 5px;
	font-size: 12px;
	display: inline-block;
}

.hc-user-items p {
	width: fit-content;
	margin: 10px 0px;
	font-size: 20px;
	text-decoration: underline;
}

.hc-no-items {
	font-size: 17px !important;
	text-decoration: none !important;
}

.hc-wrap-items {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-flow: wrap;
}

.hc-item {
	width: fit-content;
	max-width: 75px;
	height: fit-content;
	margin: 0px 5px 10px 5px;
	position: relative;
	text-align: center;
}

.hc-item img {
	max-width: 75px;
	max-height: 50px;
	margin-bottom: 5px;
	cursor: pointer;
}

.hc-item img:hover {
	box-shadow: 0px 0px 4px 4px var(--main-color-border-light);
}

.hc-item p {
	width: fit-content;
	max-width: 75px;
	margin: 0px auto;
	font-size: 17px;
	text-decoration: none;
}

.hc-player-assignment {
	margin: 0px 10px;
	padding: 5px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	border-radius: 20px;
}

.hc-player-assignment:hover {
	cursor: pointer;
	background-color: var(--main-color-6);
}

.hc-player-assignment img {
	width: 30px;
	height: 30px;
	margin-right: 20px;
	display: inline-block;
	border: 1px solid var(--main-color-border-dark);
	border-radius: 50%;
}

.hc-player-assignment p {
	width: fit-content;
	margin: 0px 0px 0px 10px;
	display: inline-block;
	text-align: start;
}

.hc-item p svg {
	color: var(--main-color-gold);
}

.hc-reveal-item {
	width: 100%;
	height: fit-content;
	margin: 0px 0px 5px 0px;
	text-align: center;
}

.hc-reveal-item button {
	margin: 10px 5px;
}

.hc-wrap-wlp-reveal {
	max-height: 200px;
	overflow: auto;
}

.hc-red {
	color: var(--main-color-error);
}

.hc-white {
	color: var(--main-color-text-light);
}

.hc-green {
	color: var(--main-color-success);
}

.hc-diff {
	min-width: fit-content;
	max-width: 100px;
	margin-top: 10px;
	padding-top: 5px;
	border-top: 2px solid var(--main-color-border-light);
	text-align: end;
}

.hc-wrap-kq {
	width: calc(100% - 274px);
	height: inherit;
	margin-left: 20px;
	display: inline-block;
	vertical-align: top;
}

.hc-wrap-kq p {
	width: 100%;
	margin: 5px 0px;
	font-size: 17px;
	text-align: center;
}

.hc-wrap-question {
	width: 100%;
	text-align: center;
}

.hc-wrap-question p {
	margin-bottom: 10px;
	font-size: 20px;
}

.hc-wrap-kq-buttons {
	width: 100%;
	padding: 5px;
	box-sizing: border-box;
}

.hc-wrap-kq-buttons button {
	width: 50%;
	max-width: 100px;
	min-width: 50px;
}

.hc-wrap-restore {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow: wrap;
}

.hc-wrap-points-buttons {
	width: 100%;
	margin: 20px 0px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.hc-wrap-kq-button {
	width: 100%;
	margin: 20px 0px;
	text-align: center;
}

.hc-wrap-kq-button p {
	padding-bottom: 15px;
}

.hc-wrap-kq-players {
	width: 100%;
	height: fit-content;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow: wrap;
}

.hc-kq-player {
	flex: 1 1 100px;
	min-width: 100px;
	max-width: fit-content;
	height: 75px;
	margin: 5px;
	text-align: center;
	cursor: pointer;
}

.hc-kq-player img {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	border: 2px solid transparent;
}

.hc-kq-player:hover > img {
	border: 2px solid var(--main-color-6);
}

.hc-kq-player-selected > img {
	border: 2px solid var(--main-color-6);
}
</style>
