import Vue from 'vue';
import VueRouter from 'vue-router';
import PageAccess from '../views/PageAccess.vue';
import PasswordReset from '../views/PasswordReset.vue';
import Home from '../views/Home.vue';
import Settings from '../views/Settings.vue';
import Profile from '../views/Profile.vue';
import QuizNightConfiguration from '../views/QuizNightConfiguration.vue';
import QuizNightConfigurationOverview from '../views/QuizNightConfigurationOverview.vue';
import QuestionCreation from '../views/QuestionCreation.vue';
import LobbyCreation from '../views/LobbyCreation.vue';
import Lobby from '../views/Lobby.vue';
import LegalNotice from '../views/LegalNotice.vue';
import Help from '../views/Help.vue';
import Admin from '../views/Admin.vue';
import NotFound from '../views/NotFound.vue';
Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'PageAccess',
		component: PageAccess,
	},
	{
		path: '/password-reset',
		name: 'PasswordReset',
		component: PasswordReset,
	},
	{
		path: '/home',
		name: 'Home',
		component: Home,
	},
	{
		path: '/settings',
		name: 'Settings',
		component: Settings,
	},
	{
		path: '/profile',
		name: 'Profile',
		component: Profile,
	},
	{
		path: '/quiz-night-configuration',
		name: 'QuizNightConfiguration',
		component: QuizNightConfiguration,
	},
	{
		path: '/quiz-night-configuration-overview',
		name: 'QuizNightConfigurationOverview',
		component: QuizNightConfigurationOverview,
	},
	{
		path: '/question-creation',
		name: 'QuestionCreation',
		component: QuestionCreation,
	},
	{
		path: '/lobby-creation',
		name: 'LobbyCreation',
		component: LobbyCreation,
	},
	{
		path: '/lobby/:sID',
		name: 'Lobby',
		component: Lobby,
	},
	{
		path: '/help',
		name: 'Help',
		component: Help,
	},
	{
		path: '/legal-notice',
		name: 'LegalNotice',
		component: LegalNotice,
	},
	{
		path: '/admin',
		name: 'Admin',
		component: Admin,
	},
	{
		path: '*',
		name: 'NotFound',
		component: NotFound,
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});

export default router;
