<template>
	<div class="lclc-wrap-content">
		<div class="lclc-wrap-input">
			<label>{{ $t('lclcCategory') }}</label>
			<input type="text" :placeholder="$t('lclcCategory')" v-model="category" @change="setCategory" class="lclc-category" />
		</div>
		<div :id="columnID" class="lclc-column-elements">
			<div v-for="element in elements" :key="element" class="lclc-column-element">
				<div class="lclc-wrap-icons">
					<fai icon="fas fa-pen" class="lclc-edit" @click="editElement(element)"></fai>
					<fai icon="fas fa-trash" class="lclc-delete" @click="deleteElement(element)"></fai>
				</div>
				<p>{{ element }}</p>
			</div>
			<p v-if="elements.length == 0" class="lclc-no-elements">{{ $t('lclcNoElements') }}</p>
		</div>
		<div class="lclc-wrap-input">
			<label>{{ $t('lclcElement') }}</label>
			<input type="text" :placeholder="$t('lclcElement')" v-model="newElement" @keyup.enter="addElement" />
			<button class="app-default-btn" @click="addElement">{{ $t('lclcAddElement') }}</button>
		</div>
		<div class="lclc-wrap-input">
			<label>{{ $t('lclcLink') }}</label>
			<input type="text" :placeholder="$t('lclcLink')" v-model="link" @change="setLink" />
			<button class="app-error-btn" @click="deleteColumn">{{ $t('lclcDeleteColumn') }}</button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ListCheckLayoutColumn',
	props: {
		columnID: {
			type: Number,
			required: true,
		},
		answerPos: {
			type: Array,
			required: true,
		},
		initialLink: {
			type: String,
			required: false,
		},
	},
	watch: {
		answerPos: {
			handler: function (newVal) {
				if (newVal.length > 0 && this.initialUpdate) {
					this.initialUpdate = false;
					this.elements = [...newVal.map((ans) => ans.answer)];
					this.category = newVal[0].category;
					// this.$emit('setAnswers', this.columnID, this.elements);
					// this.$emit('setCategory', this.columnID, this.category);
					this.$emit('adjustHeight');
				}
			},
			deep: true,
		},
		initialLink: {
			handler: function (newVal) {
				this.$nextTick(() => {
					this.link = newVal;
				});
			},
		},
	},
	data() {
		return {
			initialUpdate: true,
			category: '',
			lastCategory: '',
			newElement: '',
			elements: [],
			link: this.initialLink,
		};
	},
	methods: {
		setCategory() {
			this.$emit('setCategory', this.columnID, this.category, this.lastCategory);
			this.lastCategory = this.category;
		},
		setLink() {
			this.$emit('setLink', this.columnID, this.link);
		},
		addElement() {
			if (this.newElement == '') this.$global.showToast('error', this.$t('lclcNoElement'));
			else if (this.elements.filter((elem) => elem == this.newElement).length > 0)
				this.$global.showToast('error', this.$t('lclcSameElement'));
			else {
				this.elements.push(this.newElement);
				this.newElement = '';
				this.$emit('setAnswers', this.columnID, this.elements);
				this.$emit('adjustHeight');
			}
		},
		editElement(element) {
			this.newElement = element;
			this.deleteElement(element);
		},
		deleteElement(element) {
			this.elements = this.elements.filter((elem) => elem !== element);
			this.$emit('setAnswers', this.columnID, this.elements);
			this.$emit('adjustHeight');
		},
		deleteColumn() {
			let that = this;
			this.$global.showPopup(
				this.$t('lclcDeleteColumn'),
				this.$t('lclcDeleteColumnText'),
				'warning',
				{ text: this.$t('lclcCancel'), color: 'var(--main-color-4)' },
				{ text: this.$t('lclcDelete'), color: 'var(--main-color-error)' },
				null,
				function (result) {
					if (result.isConfirmed) {
						that.$emit('deleteColumn', that.columnID);
					}
				}
			);
		},
	},
};
</script>

<style scoped>
.lclc-wrap-content {
	height: 100%;
	width: 150px;
	min-width: 150px;
	margin: 10px 20px 10px 0px;
	display: inline-block;
	vertical-align: top;
	flex-grow: 1;
}

.lclc-wrap-input {
	width: 100%;
	height: fit-content;
	text-align: start;
	margin: auto;
	position: relative;
	display: inline-block;
	vertical-align: top;
}

.lclc-wrap-input label {
	font-size: 20px;
	margin: 10px auto;
}

.lclc-wrap-input input {
	width: 100%;
	height: 44px;
	padding: 10px !important;
	box-sizing: border-box;
	display: block;
	margin: 10px 0px;
	font-size: 20px;
}

button {
	font-size: 17px;
	min-width: 100%;
	height: 30px;
	margin: 10px 0px 20px 0px;
	display: block;
}

.lclc-column-elements {
	margin: 0px 0px 20px 0px;
	padding: 3px;
	box-sizing: border-box;
	max-height: 300px;
	overflow: auto;
	text-align: center;
}

.lclc-column-element {
	width: 100%;
	min-height: 40px;
	padding: 5px;
	margin: 5px 0px;
	border: 1px solid var(--main-color-border-dark);
	background-color: var(--main-color-4);
	box-shadow: 2px 2px 4px 4px var(--main-color-border-dark);
	box-sizing: border-box;
	text-align: start;
}

.lclc-no-elements {
	font-size: 20px;
}

.lclc-wrap-icons {
	float: right;
}

.lclc-wrap-icons svg {
	margin: 0px 2px;
	cursor: pointer;
	-webkit-filter: drop-shadow(3px 3px 0px var(--main-color-text-dark));
	filter: drop-shadow(3px 3px 0px var(--main-color-text-dark));
}

.lclc-edit {
	font-size: 20px;
	color: var(--main-color-warn);
}

.lclc-delete {
	font-size: 20px;
	color: var(--main-color-error);
}

.lclc-edit:hover {
	color: var(--secondary-color-warn);
}

.lclc-delete:hover {
	color: var(--secondary-color-error);
}
</style>
