<template>
	<div class="sr-wrap-content">
		<p>{{ $t('srRateThisShow') }}</p>
		<fieldset class="rate" @click="setRating">
			<input type="radio" id="rating10" name="rating" value="10" /><label for="rating10" :title="$t('srStarRating10')"></label>
			<input type="radio" id="rating9" name="rating" value="9" /><label class="half" for="rating9" :title="$t('srStarRating9')"></label>
			<input type="radio" id="rating8" name="rating" value="8" /><label for="rating8" :title="$t('srStarRating8')"></label>
			<input type="radio" id="rating7" name="rating" value="7" /><label class="half" for="rating7" :title="$t('srStarRating7')"></label>
			<input type="radio" id="rating6" name="rating" value="6" /><label for="rating6" :title="$t('srStarRating6')"></label>
			<input type="radio" id="rating5" name="rating" value="5" /><label class="half" for="rating5" :title="$t('srStarRating5')"></label>
			<input type="radio" id="rating4" name="rating" value="4" /><label for="rating4" :title="$t('srStarRating4')"></label>
			<input type="radio" id="rating3" name="rating" value="3" /><label class="half" for="rating3" :title="$t('srStarRating3')"></label>
			<input type="radio" id="rating2" name="rating" value="2" /><label for="rating2" :title="$t('srStarRating2')"></label>
			<input type="radio" id="rating1" name="rating" value="1" /><label class="half" for="rating1" :title="$t('srStarRating1')"></label>
		</fieldset>
	</div>
</template>

<script>
export default {
	name: 'StarRating',
	props: {
		qID: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			rating: 0,
		};
	},
	created() {
		this.getUserRating();
	},
	methods: {
		getUserRating() {
			let that = this;
			this.$global.getData(
				'quiz',
				'/getStarRating',
				{
					headers: { pageauthheader: this.$global.getAccessCode() },
					auth: this.$global.getCredentials(),
					params: { qID: this.qID, uID: this.$global.getUser().uID },
				},
				null,
				function (err, result) {
					if (err) that.$global.showToast('error', that.$t(err.response ? err.response.data.msg : 'srGetStarRatingError'));
					else {
						that.rating = Number(result);
						if (that.rating) document.getElementById(`rating${that.rating * 2}`).click();
					}
				}
			);
		},
		setRating(e) {
			if (e.target.value) {
				this.rating = Number(e.target.value) / 2;
				let that = this;
				this.$global.postData(
					'quiz',
					'/setStarRating',
					{
						qID: this.qID,
						uID: this.$global.getUser().uID,
						rating: this.rating,
					},
					{
						headers: { pageauthheader: this.$global.getAccessCode() },
						auth: this.$global.getCredentials(),
					},
					function (err, result) {
						if (err) that.$global.showToast('error', that.$t(err.response ? err.response.data.msg : 'srSetStarRatingError'));
					}
				);
			}
		},
	},
};
</script>

<style scoped>
@import url(//netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css);
.sr-wrap-content {
	margin-top: 30px;
	text-align: center;
	position: relative;
}

.sr-wrap-content h2 {
	font-size: 40px;
	margin: 0px auto;
	padding: 20px 0px;
	text-shadow: 3px 3px var(--main-color-6);
	-webkit-text-stroke: 2px var(--main-color-text-dark);
	text-align: center;
}

.rate {
	display: inline-block;
	border: 0;
	filter: drop-shadow(2px 2px 2px var(--main-color-text-dark));
}

.rate > input {
	display: none;
}

.rate > label {
	float: right;
}

.rate > label:before {
	display: inline-block;
	font-size: 2rem;
	padding: 0.3rem 0.2rem;
	margin: 0;
	cursor: pointer;
	font-family: FontAwesome;
	content: '\f005 ';
}

.rate .half:before {
	content: '\f089 ';
	position: absolute;
	padding-right: 0;
}

input:checked ~ label,
label:hover,
label:hover ~ label {
	color: var(--main-color-6);
}

input:checked + label:hover,
input:checked ~ label:hover,
input:checked ~ label:hover ~ label,
label:hover ~ input:checked ~ label {
	color: var(--secondary-color-6);
}
</style>
