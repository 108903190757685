<template>
	<div class="le-wrap-content">
		<div class="le-wrap-header">
			<div class="le-place le-header">{{ $t('lePlace') }}</div>
			<div class="le-name le-header">{{ $t('leName') }}</div>
			<div class="le-points le-header">{{ $t('lePoints') }}</div>
		</div>
		<div v-for="(place, idx) in places" :key="idx" class="le-wrap-place">
			<div v-for="player in place" :key="player.uID" class="le-wrap-entry">
				<div
					:class="[
						getPlace(player.uID) == 1
							? 'le-gold'
							: getPlace(player.uID) == 2
							? 'le-silver'
							: getPlace(player.uID) == 3
							? 'le-bronze'
							: '',
						'le-place',
						'le-cell',
					]"
				>
					{{ getPlace(player.uID) }}.
				</div>
				<div class="le-name le-cell">
					<img :src="require(`@/assets/images/profilePictures/${player.profilePic}`)" />

					<p>{{ player.name }}</p>
				</div>
				<div class="le-points le-cell">{{ getPoints(player.uID) }} {{ $t('lePointsAbbrv') }}</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Leaderboard',
	props: {
		leaderboard: {
			type: Array,
			required: true,
		},
		players: {
			type: Array,
			required: true,
		},
	},
	watch: {
		leaderboard: {
			handler: function (newVal) {
				this.places = [];
				this.$nextTick(() => {
					this.getPlaces();
				});
			},
		},
	},
	data() {
		return {
			places: [],
		};
	},
	created() {
		this.getPlaces();
	},
	methods: {
		getPlaces() {
			let entrys = {};
			this.leaderboard.forEach((entry) => {
				let player = this.players.filter((p) => p.uID == entry.uID)[0];
				if (entrys[entry.points]) entrys[entry.points].push(player);
				else entrys[entry.points] = [player];
			});
			let groups = Object.keys(entrys).sort((a, b) => Number(b) - Number(a));
			groups.forEach((group) => {
				this.places.push(entrys[group]);
			});
		},
		getPlace(uID) {
			let p = 1;
			for (let i = 0; i < this.places.length; i++) {
				if (this.places[i].find((it) => it.uID == uID)) break;
				p += this.places[i].length;
			}
			return p;
		},
		getPoints(uID) {
			return this.leaderboard.filter((entry) => entry.uID == uID)[0].points;
		},
	},
};
</script>

<style scoped>
.le-wrap-content {
	width: 90%;
	min-width: 485px;
	height: fit-content;
	margin: 10px 5%;
	padding: 10px 20px;
	border: 2px solid var(--main-color-border-dark);
	border-radius: 20px;
	box-sizing: border-box;
	box-shadow: 0px 0px 4px 2px var(--main-color-border-dark);
	background-color: var(--main-color-dark-transparent-cc);
	color: var(--main-color-text-light);
	font-size: 20px;
}

.le-wrap-place {
	width: 100%;
}

.le-gold {
	color: var(--main-color-gold);
	font-weight: bold;
}

.le-silver {
	color: var(--main-color-silver);
	font-weight: bold;
}

.le-bronze {
	color: var(--main-color-bronze);
	font-weight: bold;
}

.le-wrap-header,
.le-wrap-entry {
	width: 100%;
	display: flex;
	flex-flow: wrap;
}

.le-header {
	font-weight: bold;
	padding: 10px 5px;
	display: inline-block;
	border-bottom: 2px solid var(--main-color-border-light);
	font-size: 20px !important;
}

.le-cell {
	padding: 10px 5px;
	display: inline-flex;
}

.le-place {
	flex: 1 1 50px;
	font-size: 35px;
}

.le-name {
	flex: 1 1 calc(100% - 230px);
	align-items: center;
	justify-content: start;
}

.le-name img {
	width: 40px;
	height: 40px;
	display: inline-block;
	margin-right: 10px;
	border-radius: 50%;
	border: 2px solid var(--main-color-border-dark);
}

.le-name p {
	margin: 0px;
}

.le-points {
	flex: 1 1 100px;
	align-items: center;
	justify-content: start;
}
</style>
