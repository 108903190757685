<template>
	<div v-if="content">
		<p class="he-wrap-content">
			<span v-html="content"></span>
			<fai v-if="showIcon" :icon="showIcon" />
		</p>
	</div>
</template>

<script>
import i18n from '@/translations/i18n';
export default {
	name: 'HistoryEntry',
	props: {
		entry: {
			type: Object,
			required: true,
		},
		players: {
			type: Array,
			required: true,
		},
		shows: {
			type: Array,
			required: true,
		},
		configuredShows: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			content: '',
			showIcon: null,
		};
	},
	created() {
		this.createHistoryEntry();
	},
	methods: {
		createHistoryEntry() {
			if (this.entry.action == 'dayChange') {
				this.content = `<p style="width: 100%; padding: 5px 0px; text-align: center;">${this.entry.timepoint.toLocaleDateString()}<br/>${'-'.repeat(
					50
				)}</p>`;
			} else {
				this.content = this.addBold(this.getTimepoint());
				if (this.entry.action == 'joinSession') {
					this.content += `${this.addNeutralHighlight(this.entry.info)} ${this.$t('hiUserJoined')}`;
				} else if (this.entry.action == 'leaveSession') {
					this.content += `${this.addNeutralHighlight(this.entry.info)} ${this.$t('hiUserLeft')}`;
				} else if (this.entry.action == 'kickUser') {
					this.content += `${this.addErrorHighlight(this.entry.info ? this.entry.info : this.$t('hiUnknownUser'))} ${this.$t(
						'hiKickedUser'
					)}`;
				} else if (this.entry.action == 'setState') {
					if (this.entry.result == 'lobby') this.content += `${this.$t('hiSwappedTo')} ${this.addNeutralHighlight(this.$t('hiWaitingRoom'))}`; /* prettier-ignore */
				else if (this.entry.result == 'finished') this.content += `${this.addNeutralHighlight(this.$t('hiQuizNightFinished'))}`; /* prettier-ignore */
				else this.content += `${this.$t('hiTheGame')} ${this.addNeutralHighlight(this.getGameShow())} ${this.$t('hiHasStarted')}`; /* prettier-ignore */
				} else if (this.entry.action == 'setQuestion') {
					let show = this.configuredShows.filter((show) => show.gID == this.entry.gID)[0];
					if (show) {
						let questionNr = `${this.$t('hiQuestionNr')} ${
							show.questions.filter((quest) => quest.qID == this.entry.result)[0].position + 1
						}/${show.questions.length}`;
						this.content += `${this.$t('hiUpNext')}: ${this.addNeutralHighlight(questionNr)}`;
					} else this.content += `${this.addErrorHighlight(this.$t('hcNoHistoryEntry'))}`;
				} else if (this.entry.action == 'setAnswer') {
					let player = this.players.filter((player) => player.uID == this.entry.uID)[0];
					if (player) this.content += `${this.addNeutralHighlight(player.name)} ${this.$t('hiHasAnswered')}`;
					else this.content += `${this.addErrorHighlight(this.$t('hcNoHistoryEntry'))}`;
				} else if (this.entry.action == 'correctAnswer') {
					let player = this.players.filter((player) => player.uID == this.entry.info)[0];
					if (player) {
						let points = `${this.entry.result} ${this.$t('hiPointsAbbrv')}`;
						this.content += `${this.addNeutralHighlight(player.name)} ${this.$t('hiCorrectAnswer')} ${this.addSuccessHighlight(points)}`;
					} else this.content += `${this.addErrorHighlight(this.$t('hcNoHistoryEntry'))}`;
				} else if (this.entry.action == 'addPoints') {
					let player = this.players.filter((player) => player.uID == this.entry.info)[0];
					if (player) {
						let points = `${this.entry.result} ${this.$t('hiPointsAbbrv')}`;
						this.content += `${this.addNeutralHighlight(player.name)} ${this.$t('hiGot')} ${this.addSuccessHighlight(points)} ${this.$t(
							'hiAdded'
						)}`;
					} else this.content += `${this.addErrorHighlight(this.$t('hcNoHistoryEntry'))}`;
				} else if (this.entry.action == 'removePoints') {
					let player = this.players.filter((player) => player.uID == this.entry.info)[0];
					if (player) {
						let points = `${this.entry.result} ${this.$t('hiPointsAbbrv')}`;
						this.content += `${this.addNeutralHighlight(player.name)} ${this.$t('hiGot')} ${this.addErrorHighlight(points)} ${this.$t(
							'hiRemoved'
						)}`;
					} else this.content += `${this.addErrorHighlight(this.$t('hcNoHistoryEntry'))}`;
				} else if (this.entry.action == 'rightBuzzer') {
					let player = this.players.filter((player) => player.uID == this.entry.info)[0];
					if (player) {
						let points = `${this.entry.result} ${this.$t('hiPointsAbbrv')}`;
						this.content += `${this.addNeutralHighlight(player.name)} ${this.$t('hiGot')} ${this.addSuccessHighlight(points)} ${this.$t(
							'hiForRightBuzzer'
						)}`;
					} else this.content += `${this.addErrorHighlight(this.$t('hcNoHistoryEntry'))}`;
				} else if (this.entry.action == 'wrongBuzzer') {
					let player = this.players.filter((player) => player.uID == this.entry.info)[0];
					if (player) {
						let points = `${this.entry.result} ${this.$t('hiPointsAbbrv')}`;
						this.content += `${this.$t('hiAllPlayersGot')} ${this.addErrorHighlight(points)} ${this.$t(
							'hiBecause'
						)} ${this.addNeutralHighlight(player.name)} ${this.$t('hiBuzzeredWrong')}`;
					} else this.content += `${this.addErrorHighlight(this.$t('hcNoHistoryEntry'))}`;
				} else if (this.entry.action == 'startBidder') {
					let player = this.players.filter((player) => player.uID == this.entry.result)[0];
					if (player) {
						this.content += `${this.addNeutralHighlight(player.name)} ${this.$t('hiIsTheFirstBidder')}`;
					} else this.content += `${this.addErrorHighlight(this.$t('hcNoHistoryEntry'))}`;
				} else if (this.entry.action == 'setBid') {
					let player = this.players.filter((player) => player.uID == this.entry.uID)[0];
					if (player) {
						this.content += `${this.addNeutralHighlight(player.name)} ${this.$t('hiDidBid')} ${this.addNeutralHighlight(
							this.entry.result
						)}`;
						this.showIcon = 'fas fa-coins';
					} else this.content += `${this.addErrorHighlight(this.$t('hcNoHistoryEntry'))}`;
				} else if (this.entry.action == 'lockBid') {
					let player = this.players.filter((player) => player.uID == this.entry.uID)[0];
					if (player) {
						this.content += `${this.addNeutralHighlight(player.name)} ${this.$t('hiStoppedBidding')}`;
						this.showIcon = 'fas fa-ban';
					} else this.content += `${this.addErrorHighlight(this.$t('hcNoHistoryEntry'))}`;
				} else if (this.entry.action == 'unlockBid') {
					let player = this.players.filter((player) => player.uID == this.entry.result)[0];
					if (player) {
						this.content += `${player.name} ${this.$t('hiCanBidAgain')}`;
						this.showIcon = 'fas fa-lock-open';
					} else this.content += `${this.addErrorHighlight(this.$t('hcNoHistoryEntry'))}`;
				}
				// Deprecated, but needed for backwards compatibility
				else if (this.entry.action == 'assignItem') {
					let player = this.players.filter((player) => player.uID == this.entry.result)[0];
					let show = this.configuredShows.filter((show) => show.gID == this.entry.gID)[0];
					if (player) {
						let questionNr = show.questions.filter((quest) => quest.qID == this.entry.qID)[0].position + 1;
						let price = this.entry.info ? `${this.$t('hiFor')} ${this.entry.info}` : '';
						if (this.entry.info) this.showIcon = 'fas fa-coins';
						this.content += `${this.$t('hiItemNumber')} ${questionNr} ${this.$t('hiWasAssignedTo')} ${this.addNeutralHighlight(
							player.name
						)} ${price}`;
					} else this.content += `${this.addErrorHighlight(this.$t('hcNoHistoryEntry'))}`;
				} else if (this.entry.action == 'setCurrentBidder') {
					let player = this.players.filter((player) => player.uID == this.entry.result)[0];

					if (player) this.content += `${this.addNeutralHighlight(`${player.name}`)} ${this.$t('hiWasSetAsBidder')}`;
					else this.content += `${this.addErrorHighlight(this.$t('hcNoHistoryEntry'))}`;
				} else if (this.entry.action == 'setMoney') {
					let player = this.players.filter((player) => player.uID == this.entry.info)[0];

					if (player) {
						this.content += `${this.addNeutralHighlight(`${player.name}'s`)} ${this.$t('hiMoneyWasSetTo')} ${this.entry.result}`;
						this.showIcon = 'fas fa-coins';
					} else this.content += `${this.addErrorHighlight(this.$t('hcNoHistoryEntry'))}`;
				} else if (this.entry.action == 'highestBid') {
					let player = this.players.filter((player) => player.uID == this.entry.result)[0];
					if (player) {
						this.content += `${this.addNeutralHighlight(player.name)} ${this.$t('hiGotTheItemWithBid')} ${this.addSuccessHighlight(
							this.entry.info
						)}`;
						this.showIcon = 'fas fa-coins';
					} else this.content += `${this.addErrorHighlight(this.$t('hcNoHistoryEntry'))}`;
				} else if (this.entry.action == 'skipItem') {
					let show = this.configuredShows.filter((show) => show.gID == this.entry.gID)[0];
					let questionNr = show.questions.filter((quest) => quest.qID == this.entry.qID)[0].position + 1;
					this.content += `${this.addNeutralHighlight(`${this.$t('hiItemNumber')} ${questionNr}`)} ${this.$t('hiWasSkipped')}`;
				} else if (this.entry.action == 'showReveal') {
					this.content += `${this.$t('hiSwappedTo2')} ${this.addNeutralHighlight(this.$t('hiWLPRevealScreen'))}`;
				} else if (this.entry.action == 'revealItem') {
					let player = this.players.filter((player) => player.uID == this.entry.info)[0];
					let show = this.configuredShows.filter((show) => show.gID == this.entry.gID)[0];
					let questionNr = show.questions.filter((quest) => quest.qID == this.entry.qID)[0].position + 1;
					if (player) {
						let diff = Number(this.entry.result);
						let moneyMsg = '';
						if (diff < 0) moneyMsg = `${this.addErrorHighlight(this.entry.result)}`;
						else if (diff == 0) moneyMsg = `${this.addNeutralHighlight(this.entry.result)}`;
						else moneyMsg = `${this.addSuccessHighlight(this.entry.result)}`;
						if (i18n.locale == 'de') {
							this.content += `${this.addNeutralHighlight(player.name)} ${this.$t('hiHas')} ${moneyMsg} ${this.$t(
								'hiForItem'
							)} ${this.$t('hiItemNumber')} ${questionNr} ${diff < 0 ? this.$t('hiLost') : this.$t('hiEarned')}`;
						} else if (i18n.locale == 'en') {
							this.content += `${this.addNeutralHighlight(player.name)} ${
								diff < 0 ? this.$t('hiLost') : this.$t('hiEarned')
							} ${moneyMsg} ${this.$t('hiForItem')} ${this.$t('hiItemNumber')} ${questionNr}`;
						}
					} else this.content += `${this.addNeutralHighlight(`${this.$t('hiItemNumber')} ${questionNr}`)} ${this.$t('hiNoBidderForItem')}`;
				} else if (this.entry.action == 'revealMoney') {
					let player = this.players.filter((player) => player.uID == this.entry.info)[0];
					let show = this.configuredShows.filter((show) => show.gID == this.entry.gID)[0];
					let questionNr = show.questions.filter((quest) => quest.qID == this.entry.qID)[0].position + 1;
					if (player) {
						let diff = Number(this.entry.result);
						let moneyMsg = '';
						if (diff < 0) moneyMsg = `${this.addErrorHighlight(this.entry.result)}`;
						else moneyMsg = `${this.addSuccessHighlight(this.entry.result)}`;

						this.content += `${this.addNeutralHighlight(player.name)} ${diff < 0 ? this.$t('hiLooses') : this.$t('hiGets')} ${moneyMsg}`;
						this.showIcon = 'fas fa-coins';
					} else this.content += `${this.$t('hiNoBidderForItem')} ${questionNr}`;
				} else if (this.entry.action == 'revealSkip') {
					let show = this.configuredShows.filter((show) => show.gID == this.entry.gID)[0];
					let questionNr = show.questions.filter((quest) => quest.qID == this.entry.qID)[0].position + 1;
					this.content += `${this.addNeutralHighlight(`${this.$t('hiItemNumber')} ${questionNr}`)} ${this.$t('hiNoBidderForItem')}`;
				} else if (this.entry.action == 'pointsWin') {
					let player = this.players.filter((player) => player.uID == this.entry.info)[0];
					if (player) {
						this.content += `${this.addNeutralHighlight(player.name)} ${this.$t('hiIs')} ${this.addNeutralHighlight(
							`${this.entry.info2 ? `${this.entry.info2}.` : '?'} ${this.$t('hiPlace')}`
						)} ${this.$t('hiAndWon')} ${this.addSuccessHighlight(`${this.entry.result} ${this.$t('hiPointsAbbrv')}`)}`;
					} else this.content += `${this.addErrorHighlight(this.$t('hcNoHistoryEntry'))}`;
				} else if (this.entry.action == 'assignTopic') {
					let player = this.players.filter((player) => player.uID == this.entry.info)[0];
					if (player) this.content += `${this.addNeutralHighlight(player.name)} ${this.$t('hiGotAssignedToATopic')}`;
					else this.content += `${this.addErrorHighlight(this.$t('hcNoHistoryEntry'))}`;
				} else if (this.entry.action == 'lockTopicTerms') {
					let player = this.players.filter((player) => player.uID == this.entry.uID)[0];
					if (player) this.content += `${this.addNeutralHighlight(player.name)} ${this.$t('hiHasChoosenHisTerms')}`;
					else this.content += `${this.addErrorHighlight(this.$t('hcNoHistoryEntry'))}`;
				} else if (this.entry.action == 'assignTopicGuessing') {
					let player = this.players.filter((player) => player.uID == this.entry.info)[0];
					if (player) this.content += `${this.addNeutralHighlight(player.name)} ${this.$t('hiWasChoosenForATopic')}`;
					else this.content += `${this.addErrorHighlight(this.$t('hcNoHistoryEntry'))}`;
				} else if (this.entry.action == 'guessingTime') {
					let player = this.players.filter((player) => player.uID == this.entry.info)[0];
					if (player)
						this.content += `${this.$t('hiTheGuessingTimeForPlayer')} ${this.addNeutralHighlight(player.name)} ${this.$t(
							'hiHasStarted'
						)}`;
					else this.content += `${this.addErrorHighlight(this.$t('hcNoHistoryEntry'))}`;
				} else if (this.entry.action == 'setAnswers') {
					let player = this.players.filter((player) => player.uID == this.entry.info)[0];
					if (player) this.content += `${this.addNeutralHighlight(player.name)} ${this.$t('hiHasGuessedTheTopicTerms')}`;
					else this.content += `${this.addErrorHighlight(this.$t('hcNoHistoryEntry'))}`;
				} else if (this.entry.action == 'topicSummary') {
					let show = this.configuredShows.filter((show) => show.gID == this.entry.gID)[0];
					if (show) {
						let question = show.questions.filter((quest) => quest.qID == this.entry.qID)[0];
						if (question) {
							this.content += `${this.$t('hiZGWTopicSummaryStartedForTheTopic')} ${this.addNeutralHighlight(question.question)}`;
						} else this.content += `${this.addErrorHighlight(this.$t('hcNoHistoryEntry'))}`;
					} else this.content += `${this.addErrorHighlight(this.$t('hcNoHistoryEntry'))}`;
				} else if (this.entry.action == 'creatorPoints') {
					let player = this.players.filter((player) => player.uID == this.entry.info)[0];
					if (player) {
						let points = `${this.entry.result} ${this.$t('hiPointsAbbrv')}`;
						this.content += `${this.addNeutralHighlight(player.name)} ${this.$t(
							'hiWasAwardedPointsForCreation'
						)} ${this.addSuccessHighlight(points)} ${this.$t('hiPointsAwarded')}`;
					} else this.content += `${this.addErrorHighlight(this.$t('hcNoHistoryEntry'))}`;
				} else if (this.entry.action == 'revealTopicTerm') {
					this.content += `${this.$t('hiTheCurrentTermIs')} ${this.addNeutralHighlight(this.entry.result)} `;
				} else if (this.entry.action == 'setStarter') {
					let player = this.players.filter((player) => player.uID == this.entry.result)[0];
					if (player) this.content += `${this.addNeutralHighlight(player.name)} ${this.$t('hiWasSelectedAsStartPlayer')}`;
					else this.content += `${this.addErrorHighlight(this.$t('hcNoHistoryEntry'))}`;
				} else if (this.entry.action == 'selectCategory') {
					let player = this.players.filter((player) => player.uID == this.entry.uID)[0];
					let show = this.configuredShows.filter((show) => show.gID == this.entry.gID)[0];
					if (player && show) {
						let points = `${show.pointsPerLevel[this.entry.info].points} ${this.$t('hiPointsAbbrv')}`;
						let category = `${this.entry.info2} (${points})`;
						this.content += `${this.addNeutralHighlight(player.name)} ${this.$t('hiSelectedTheCategory')} ${this.addNeutralHighlight(
							category
						)}`;
					} else this.content += `${this.addErrorHighlight(this.$t('hcNoHistoryEntry'))}`;
				} else if (this.entry.action == 'removeCategory') {
					this.content += `${this.$t('hiCategoryWasRemoved')}`;
				} else if (this.entry.action == 'assignCategory') {
					let player = this.players.filter((player) => player.uID == this.entry.info)[0];
					let show = this.configuredShows.filter((show) => show.gID == this.entry.gID)[0];
					if (player && show) {
						let points = `${show.pointsPerLevel[this.entry.info2].points} ${this.$t('hiPointsAbbrv')}`;
						let question = show.questions.filter((quest) => quest.qID == this.entry.result)[0];
						let category = `${question.category} (${points})`;
						this.content += `${this.$t('hiTheCategory')} ${this.addNeutralHighlight(category)} ${this.$t(
							'hiWasAssignedToThePlayer'
						)} ${this.addNeutralHighlight(player.name)} ${this.$t('hiAssigned')}`;
					} else this.content += `${this.addErrorHighlight(this.$t('hcNoHistoryEntry'))}`;
				} else if (this.entry.action == 'useJoker') {
					let player = this.players.filter((player) => player.uID == this.entry.uID)[0];
					if (player) this.content += `${this.$t('hiThePlayer')} ${this.addNeutralHighlight(player.name)} ${this.$t('hiUsedAJoker')}`;
					else this.content += `${this.addErrorHighlight(this.$t('hcNoHistoryEntry'))}`;
				} else if (this.entry.action == 'restoreJoker') {
					let player = this.players.filter((player) => player.uID == this.entry.result)[0];
					if (player)
						this.content += `${this.$t('hiThePlayerGot')} ${this.addNeutralHighlight(player.name)} ${this.$t('hiGotAJokerRestored')}`;
					else this.content += `${this.addErrorHighlight(this.$t('hcNoHistoryEntry'))}`;
				} else if (this.entry.action == 'correctAnswerKQ') {
					let player = this.players.filter((player) => player.uID == this.entry.info)[0];
					let show = this.configuredShows.filter((show) => show.gID == this.entry.gID)[0];
					if (player && show) {
						let points = `${show.pointsPerLevel[this.entry.result].points} ${this.$t('hiPointsAbbrv')}`;
						let question = show.questions.filter((quest) => quest.qID == this.entry.info2)[0];
						this.content += `${this.$t('hiThePlayer')} ${this.addNeutralHighlight(player.name)} ${this.$t(
							'hiGets'
						)} ${this.addSuccessHighlight(points)} ${this.$t('hiForAnsweringAQuestionInTheCategory')} ${this.addNeutralHighlight(
							question.category
						)}`;
					} else this.content += `${this.addErrorHighlight(this.$t('hcNoHistoryEntry'))}`;
				} else if (this.entry.action == 'wrongAnswerKQ') {
					let player = this.players.filter((player) => player.uID == this.entry.info)[0];
					let show = this.configuredShows.filter((show) => show.gID == this.entry.gID)[0];
					if (player && show) {
						let points = `${show.pointsPerLevel[this.entry.result].points} ${this.$t('hiPointsAbbrv')}`;
						let question = show.questions.filter((quest) => quest.qID == this.entry.info2)[0];
						let category = `${question.category} (${points})`;
						this.content += `${this.addNeutralHighlight(player.name)} ${this.$t(
							'hiHasWronglyAnsweredTheQuestion'
						)} ${this.addErrorHighlight(category)} ${this.$t('hiAnsweredWrong')}`;
					} else this.content += `${this.addErrorHighlight(this.$t('hcNoHistoryEntry'))}`;
				} else this.content += `${this.addErrorHighlight(this.$t('hcNoHistoryEntry'))}`;
			}
		},
		addNeutralHighlight(content) {
			return `<span class='he-highlighted'>${content}</span>`;
		},
		addSuccessHighlight(content) {
			return `<span class='he-highlighted he-success'>${content}</span>`;
		},
		addErrorHighlight(content) {
			return `<span class='he-highlighted he-error'>${content}</span>`;
		},
		addBold(content) {
			return `<strong class='he-bold'>${content}</strong>`;
		},
		getTimepoint() {
			let date = new Date(this.entry.timepoint);
			let hours = String(date.getHours());
			let minutes = String(date.getMinutes());
			return `${hours.length == 2 ? hours : '0' + hours}:${minutes.length == 2 ? minutes : '0' + minutes} `;
		},
		getGameShow() {
			let show = this.shows.filter((s) => s.abbreviation == this.entry.result)[0];
			return this.$t(show.name);
		},
	},
};
</script>

<style scoped>
.he-wrap-content {
	width: 100%;
	padding: 1px 5px;
	line-height: 24px;
	margin: 0px;
	display: inline-block;
}

.he-wrap-content ::v-deep .he-highlighted {
	padding: 2px 4px;
	border-radius: 5px;
	font-weight: bold;
	background-color: var(--main-color-2);
	color: var(--main-color-text-dark);
	white-space: nowrap;
}

.he-wrap-content ::v-deep .he-bold {
	font-weight: bold;
}

.he-wrap-content ::v-deep .he-success {
	background-color: var(--main-color-success) !important;
}

.he-wrap-content ::v-deep .he-error {
	background-color: var(--main-color-error) !important;
}

.he-wrap-content svg {
	margin: 0px 10px;
}

.fa-coins {
	color: var(--main-color-gold);
}

.fa-ban {
	color: var(--main-color-error);
}

.fa-lock-open {
	color: var(--main-color-info);
}
</style>
