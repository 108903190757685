<template>
	<div class="apl-wrap-content">
		<h2>{{ $t('aplAnswerList') }}</h2>
		<div class="apl-wrap-input">
			<label>{{ $t('aplAnswer') }}</label>
			<input type="text" v-model="answer" :placeholder="$t('aplAnswer')" @keyup.enter="addAnswer" />
		</div>
		<div v-if="['oh', 'wbm'].includes(show)" class="apl-wrap-input">
			<label>{{ $t('aplAnswerInfo') }}</label>
			<input type="text" v-model="answerInfo" :placeholder="$t('aplAnswerInfoExample')" @keyup.enter="addAnswer" />
		</div>
		<div v-if="['smf', 'xfz'].includes(show)" class="apl-wrap-input">
			<label>{{ $t('aplIsAnswer') }}</label>
			<input type="checkbox" v-model="isAnswer" />
		</div>
		<button class="app-default-btn" @click="addAnswer">{{ $t('aplAddAnswer') }}</button>
		<div class="apl-wrap-answer-pos">
			<div v-if="['oh'].includes(show)" class="apl-upper-title">
				<div class="apl-wrap-input">
					<label>{{ $t('aplUpperTitle') }}</label>
					<input type="text" v-model="upperTitle" :placeholder="$t('aplUpperExample')" @input="updateAnswerPos" />
				</div>
			</div>
			<div class="apl-scroll-layout">
				<div class="apl-wrap-answers">
					<div v-for="answer in answers" :key="answer.pos" class="apl-answer">
						<p>
							<span v-if="['oh', 'wbm'].includes(show)">{{ answer.pos + 1 }}. </span>{{ answer.answer }}
							<span v-if="answer.info">({{ answer.info }})</span>
						</p>

						<div class="apl-wrap-controls">
							<fai
								v-if="['smf', 'xfz'].includes(show)"
								icon="fas fa-check-circle"
								:class="[answer.isAnswer ? 'apl-is-answer' : 'apl-is-not-answer']"
								:title="[answer.isAnswer ? $t('aplIsAnswer') : $t('aplIsNotAnswer')]"
								@click="setAnswer(answer)"
							></fai>
							<fai icon="fas fa-pen" class="apl-edit" @click="editAnswer(answer)"></fai>
							<fai
								icon="fas fa-angle-up"
								class="apl-up"
								:class="[answer.pos == 0 ? 'apl-disable' : '']"
								:disabled="answer.pos == 0"
								@click="moveAnswerUp(answer)"
							></fai>
							<fai
								icon="fas fa-angle-down"
								class="apl-down"
								:class="[answer.pos == answers.length - 1 ? 'apl-disable' : '']"
								:disabled="answer.pos == answers.length - 1"
								@click="moveAnswerDown(answer)"
							></fai>
							<fai icon="fas fa-trash" class="apl-remove" @click="removeAnswer(answer)"></fai>
						</div>
					</div>
					<div v-if="answers.length == 0" class="apl-no-answers">{{ $t('aplNoAnswers') }}</div>
				</div>
				<div v-if="['oh'].includes(show)" class="apl-lower-title">
					<div class="apl-wrap-input">
						<label>{{ $t('aplLowerTitle') }}</label>
						<input type="text" v-model="lowerTitle" :placeholder="$t('aplLowerExample')" @input="updateAnswerPos" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'AnswerPosList',
	props: {
		initialAnswers: {
			type: Array,
			required: true,
		},
		initialUpperTitle: {
			type: String,
			required: true,
		},
		initialLowerTitle: {
			type: String,
			required: true,
		},
		show: {
			type: String,
			required: true,
		},
	},
	watch: {
		initialAnswers: {
			handler: function (newVal) {
				this.answers = [
					...newVal.map((ans, idx) => {
						return {
							pos: ans.pos ? ans.pos : idx,
							answer: ans.answer ? ans.answer : '',
							info: ans.info ? ans.info : '',
							isAnswer: ans.isAnswer ? ans.isAnswer : false,
						};
					}),
				];
			},
			deep: true,
		},
		initialUpperTitle: {
			handler: function (newVal) {
				this.upperTitle = newVal;
			},
			deep: true,
		},
		initialLowerTitle: {
			handler: function (newVal) {
				this.lowerTitle = newVal;
			},
			deep: true,
		},
	},
	data() {
		return {
			answer: '',
			answerInfo: '',
			isAnswer: false,
			upperTitle: '',
			lowerTitle: '',
			answers: [],
		};
	},
	methods: {
		addAnswer() {
			if (this.answer == '') this.$global.showToast('warn', this.$t('aplNoAnswer'));
			else if (this.isAnswer && this.answers.filter((ans) => ans.isAnswer).length > 0)
				this.$global.showToast('warn', this.$t('aplNoMultipleAnswers'));
			else {
				this.answers.push({ pos: this.answers.length, answer: this.answer, info: this.answerInfo, isAnswer: this.isAnswer });
				this.answer = '';
				this.answerInfo = '';
				this.isAnswer = false;
				this.updateAnswerPos();
				this.$nextTick(() => {
					let element = document.getElementsByClassName('apl-wrap-answers')[0];
					element.scrollTo({ top: element.scrollHeight, behavior: 'smooth' });
				});
			}
		},
		setAnswer(answer) {
			this.answers.map((ans) => {
				if (ans.pos !== answer.pos) ans.isAnswer = false;
				else ans.isAnswer = true;
			});
			this.updateAnswerPos();
		},
		editAnswer(answer) {
			this.answer = answer.answer;
			this.answerInfo = answer.info;
			this.isAnswer = answer.isAnswer;
			this.answers = this.answers.filter((ans) => ans.pos !== answer.pos);
			this.renumberAnswers();
		},
		moveAnswerUp(answer) {
			if (answer.pos !== 0) this.arrayMove(answer.pos, answer.pos - 1);
			this.renumberAnswers();
		},
		moveAnswerDown(answer) {
			if (answer.pos !== this.answers.length - 1) this.arrayMove(answer.pos, answer.pos + 1);
			this.renumberAnswers();
		},
		arrayMove(oldIndex, newIndex) {
			if (newIndex >= this.answers.length) {
				let k = newIndex - this.answers.length + 1;
				while (k--) this.answers.push(undefined);
			}
			this.answers.splice(newIndex, 0, this.answers.splice(oldIndex, 1)[0]);
		},
		removeAnswer(answer) {
			this.answers = this.answers.filter((ans) => ans.pos !== answer.pos);
			this.renumberAnswers();
		},
		renumberAnswers() {
			this.answers.map((ans, idx) => {
				ans.pos = idx;
			});
			this.updateAnswerPos();
		},
		updateAnswerPos() {
			this.$emit('updateAnswerPos', { upperTitle: this.upperTitle, lowerTitle: this.lowerTitle, answers: this.answers });
		},
		resetAnswerPos() {
			this.answer = '';
			this.answerInfo = '';
			this.isAnswer = false;
			this.upperTitle = '';
			this.lowerTitle = '';
			this.answers = [];
		},
	},
};
</script>

<style scoped>
.apl-wrap-content {
	width: 100%;
	box-sizing: border-box;
	margin: 10px 0px 30px 0px;
}

h2 {
	width: fit-content;
	font-size: 40px;
	margin: 0px auto;
	padding: 5px 0px 20px 0px;
	text-shadow: 3px 3px var(--main-color-6);
	-webkit-text-stroke: 2px var(--main-color-text-dark);
	text-align: center;
}

.apl-wrap-input {
	width: 50%;
	height: fit-content;
	text-align: start;
	margin: auto;
	position: relative;
	display: inline-block;
	vertical-align: top;
}

.apl-wrap-input label {
	font-size: 20px;
	margin: 10px auto;
}

.apl-wrap-input input[type='text'] {
	width: 90%;
	height: 30px;
	padding: 5px !important;
	display: block;
	margin: 10px 0px;
	font-size: 20px;
}

.apl-wrap-input input[type='checkbox'] {
	width: 30px;
	height: 30px;
	padding: 5px !important;
	display: block;
	margin: 10px 0px;
	font-size: 20px;
	cursor: pointer;
}

button {
	font-size: 20px;
	width: 300px;
	height: 40px;
	margin: 10px auto;
	display: block;
}

.apl-wrap-answer-pos {
	position: relative;
	padding: 60px 0px;
	box-sizing: border-box;
}

.apl-scroll-layout {
	width: 95%;
	margin: auto;
	overflow: hidden;
	border: 2px solid var(--main-color-border-light);
	border-radius: 20px;
	box-shadow: 2px 2px 4px 4px var(--main-color-border-dark);
}

.apl-wrap-answers {
	width: 100%;
	min-height: 100px;
	max-height: 300px;
	padding: 10px;
	background-color: var(--main-color-4);
	overflow: auto;
	box-sizing: border-box;
}

.apl-answer {
	width: calc(100% - 20px);
	height: fit-content;
	min-height: 50px;
	margin: 10px;
	padding: 5px 10px;
	padding-right: 150px;
	box-sizing: border-box;
	display: block;
	background-color: var(--main-color-3);
	box-shadow: 2px 2px 4px 4px var(--main-color-border-dark);
	color: var(--main-color-text-light);
	font-size: 20px;
	position: relative;
}

.apl-answer p {
	width: 100%;
	display: inline-block;
	margin: 0px 5px 0px 0px;
}

.apl-wrap-controls {
	position: absolute;
	right: 0px;
	top: 0px;
	width: 150px;
	height: 60px;
}

.apl-wrap-controls svg {
	position: absolute;
	font-size: 30px;
	-webkit-filter: drop-shadow(3px 3px 0px var(--main-color-text-dark));
	filter: drop-shadow(3px 3px 0px var(--main-color-text-dark));
}

.apl-wrap-controls svg:hover {
	cursor: pointer;
}

.apl-is-answer {
	top: 10px;
	right: 110px;
	color: var(--main-color-success);
}

.apl-is-not-answer {
	top: 10px;
	right: 110px;
	color: var(--main-color-disabled);
}

.apl-remove {
	top: 10px;
	right: 5px;
	color: var(--main-color-error);
}

.apl-edit {
	top: 12px;
	right: 40px;
	color: var(--main-color-warn);
	font-size: 25px !important;
}

.apl-up {
	top: 0px;
	right: 75px;
}

.apl-down {
	top: 20px;
	right: 75px;
}

.apl-is-answer:hover,
.apl-is-not-answer:hover {
	color: var(--secondary-color-success);
}

.apl-remove:hover {
	color: var(--secondary-color-error);
}

.apl-up:hover,
.apl-down:hover {
	color: var(--main-color-6);
}

.apl-edit:hover {
	color: var(--secondary-color-warn);
}

.apl-disable {
	color: var(--main-color-disabled) !important;
}

.apl-disable:hover {
	cursor: default !important;
}

.apl-no-answers {
	width: 100%;
	height: inherit;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	font-size: 20px;
	color: var(--main-color-text-light);
}

.apl-upper-title {
	position: absolute;
	top: 0px;
	left: 0px;
}
.apl-lower-title {
	position: absolute;
	bottom: 0px;
	left: 0px;
}

.apl-upper-title .apl-wrap-input,
.apl-lower-title .apl-wrap-input {
	width: 100%;
}

.apl-upper-title .apl-wrap-input label,
.apl-lower-title .apl-wrap-input label {
	display: inline-block;
}

.apl-upper-title .apl-wrap-input input,
.apl-lower-title .apl-wrap-input input {
	width: fit-content;
	display: inline-block;
	margin: 0px 0px 0px 5px;
}
</style>
