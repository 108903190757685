<template>
	<div class="he-wrap-content">
		<div v-if="header" class="he-wrap-small-header">
			<fai icon="fas fa-bars" class="he-menu" @click="showHeader = !showHeader" />
			<div v-show="showHeader" class="he-extended-header">
				<p v-for="element in header" :key="element.name" class="he-menu-name" @click="navigateTo(element.name)">
					<fai :icon="getIconForElement(element.name)" class="he-menu-icon" />
					<span>
						{{ $t(element.translation) }}
					</span>
				</p>
				<div class="he-wrap-languages">
					<img
						id="de"
						class="he-selected-language"
						:src="require(`@/assets/images/german.svg`)"
						:alt="$t('heGerman')"
						@click="setLanguage('de')"
					/>
					<img id="en" :src="require(`@/assets/images/english.svg`)" :alt="$t('heEnglish')" @click="setLanguage('en')" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import i18n from '@/translations/i18n';
export default {
	name: 'Header',
	watch: {
		$route: {
			handler: function (to, from) {
				this.showHeader = false;
				this.loadHeader(to.name);
			},
		},
	},
	data() {
		return {
			header: null,
			showHeader: null,
		};
	},
	created() {
		this.loadHeader(this.$route.name);
	},
	methods: {
		loadHeader(name) {
			let isLoggedIn = this.$global.getCredentials() != null;
			let isAdmin = this.$global.isAdmin();

			switch (name) {
				case 'NotFound':
				case 'PasswordReset':
					this.header = [
						{ name: 'Home', translation: 'heHome' },
						{ name: 'LegalNotice', translation: 'heLegalNotice' },
					];

					break;
				case 'Home':
					if (isLoggedIn) {
						this.header = [
							{ name: 'QuizNightConfiguration', translation: 'heQuizNightConfiguration' },
							{ name: 'LobbyCreation', translation: 'heLobbyCreation' },
							{ name: 'Profile', translation: 'heProfile' },
							{ name: 'Settings', translation: 'heSettings' },
						];
						if (isAdmin) this.header.push({ name: 'Admin', translation: 'heAdmin' });
						this.header.push({ name: 'Logout', translation: 'heLogout' });
					} else this.header = [];

					this.header.push({ name: 'Help', translation: 'heHelp' });
					this.header.push({ name: 'LegalNotice', translation: 'heLegalNotice' });
					break;
				case 'Settings':
					this.header = [
						{ name: 'Home', translation: 'heHome' },
						{ name: 'QuizNightConfiguration', translation: 'heQuizNightConfiguration' },
						{ name: 'LobbyCreation', translation: 'heLobbyCreation' },
						{ name: 'Profile', translation: 'heProfile' },
						{ name: 'Help', translation: 'heHelp' },
					];

					if (isAdmin) this.header.push({ name: 'Admin', translation: 'heAdmin' });

					this.header.push({ name: 'Logout', translation: 'heLogout' });
					this.header.push({ name: 'LegalNotice', translation: 'heLegalNotice' });
					break;
				case 'Profile':
					this.header = [
						{ name: 'Home', translation: 'heHome' },
						{ name: 'QuizNightConfiguration', translation: 'heQuizNightConfiguration' },
						{ name: 'LobbyCreation', translation: 'heLobbyCreation' },
						{ name: 'Settings', translation: 'heSettings' },
						{ name: 'Help', translation: 'heHelp' },
					];
					if (isAdmin) this.header.push({ name: 'Admin', translation: 'heAdmin' });

					this.header.push({ name: 'Logout', translation: 'heLogout' });
					this.header.push({ name: 'LegalNotice', translation: 'heLegalNotice' });
					break;
				case 'LobbyCreation':
					this.header = [
						{ name: 'Home', translation: 'heHome' },
						{ name: 'QuizNightConfiguration', translation: 'heQuizNightConfiguration' },
						{ name: 'Profile', translation: 'heProfile' },
						{ name: 'Settings', translation: 'heSettings' },
						{ name: 'Help', translation: 'heHelp' },
					];
					if (isAdmin) this.header.push({ name: 'Admin', translation: 'heAdmin' });

					this.header.push({ name: 'Logout', translation: 'heLogout' });
					this.header.push({ name: 'LegalNotice', translation: 'heLegalNotice' });
					break;
				case 'Lobby':
					this.header = [
						{ name: 'Home', translation: 'heHome' },
						{ name: 'LobbyCreation', translation: 'heLobbyCreation' },
						{ name: 'Profile', translation: 'heProfile' },
						{ name: 'Settings', translation: 'heSettings' },
						{ name: 'Help', translation: 'heHelp' },
					];
					if (isAdmin) this.header.push({ name: 'Admin', translation: 'heAdmin' });

					this.header.push({ name: 'Logout', translation: 'heLogout' });
					this.header.push({ name: 'LegalNotice', translation: 'heLegalNotice' });
					break;
				case 'QuizNightConfiguration':
					this.header = [
						{ name: 'Home', translation: 'heHome' },
						{ name: 'LobbyCreation', translation: 'heLobbyCreation' },
						{ name: 'Profile', translation: 'heProfile' },
						{ name: 'Settings', translation: 'heSettings' },
						{ name: 'Help', translation: 'heHelp' },
					];
					if (isAdmin) this.header.push({ name: 'Admin', translation: 'heAdmin' });

					this.header.push({ name: 'Logout', translation: 'heLogout' });
					this.header.push({ name: 'LegalNotice', translation: 'heLegalNotice' });
					break;
				case 'QuestionCreation':
					this.header = [
						{ name: 'Home', translation: 'heHome' },
						{ name: 'QuizNightConfiguration', translation: 'heQuizNightConfiguration' },
						{ name: 'LobbyCreation', translation: 'heLobbyCreation' },
						{ name: 'Profile', translation: 'heProfile' },
						{ name: 'Settings', translation: 'heSettings' },
						{ name: 'Help', translation: 'heHelp' },
					];
					if (isAdmin) this.header.push({ name: 'Admin', translation: 'heAdmin' });

					this.header.push({ name: 'Logout', translation: 'heLogout' });
					this.header.push({ name: 'LegalNotice', translation: 'heLegalNotice' });
					break;
				case 'QuizNightConfigurationOverview':
					this.header = [
						{ name: 'Home', translation: 'heHome' },
						{ name: 'QuizNightConfiguration', translation: 'heQuizNightConfiguration' },
						{ name: 'LobbyCreation', translation: 'heLobbyCreation' },
						{ name: 'Help', translation: 'heHelp' },
					];
					if (isAdmin) this.header.push({ name: 'Admin', translation: 'heAdmin' });

					this.header.push({ name: 'Logout', translation: 'heLogout' });
					this.header.push({ name: 'LegalNotice', translation: 'heLegalNotice' });
					break;
				case 'Help':
					if (isLoggedIn) {
						this.header = [
							{ name: 'Home', translation: 'heHome' },
							{ name: 'QuizNightConfiguration', translation: 'heQuizNightConfiguration' },
							{ name: 'LobbyCreation', translation: 'heLobbyCreation' },
							{ name: 'Profile', translation: 'heProfile' },
							{ name: 'Settings', translation: 'heSettings' },
						];
						if (isAdmin) this.header.push({ name: 'Admin', translation: 'heAdmin' });

						this.header.push({ name: 'Logout', translation: 'heLogout' });
						this.header.push({ name: 'LegalNotice', translation: 'heLegalNotice' });
					} else {
						this.header = [
							{ name: 'Home', translation: 'heHome' },
							{ name: 'LegalNotice', translation: 'heLegalNotice' },
						];
					}
					break;
				case 'Admin':
					this.header = [
						{ name: 'Home', translation: 'heHome' },
						{ name: 'QuizNightConfiguration', translation: 'heQuizNightConfiguration' },
						{ name: 'LobbyCreation', translation: 'heLobbyCreation' },
						{ name: 'Profile', translation: 'heProfile' },
						{ name: 'Settings', translation: 'heSettings' },
					];

					if (isAdmin) this.header.push({ name: 'Admin', translation: 'heAdmin' });

					this.header.push({ name: 'Logout', translation: 'heLogout' });
					this.header.push({ name: 'Help', translation: 'heHelp' });
					this.header.push({ name: 'LegalNotice', translation: 'heLegalNotice' });
					break;
				case 'PageAccess':
					this.header = [{ name: 'LegalNotice', translation: 'heLegalNotice' }];
					break;
				case 'LegalNotice':
					if (sessionStorage.getItem('accessCode') || localStorage.getItem('accessCode')) {
						this.header = [{ name: 'Home', translation: 'heHome' }];
						if (isAdmin) this.header.push({ name: 'Admin', translation: 'heAdmin' });
					} else this.header = [{ name: 'PageAccess', translation: 'hePageAccess' }];
					break;
			}
		},
		getIconForElement(element) {
			if (element == 'PageAccess') return 'fas fa-home';
			else if (element == 'Home') return 'fas fa-home';
			else if (element == 'Registration') return 'fas fa-file-signature';
			else if (element == 'QuizNightConfiguration') return 'fas fa-cogs';
			else if (element == 'LobbyCreation') return 'fas fa-couch';
			else if (element == 'Help') return 'fas fa-question-circle';
			else if (element == 'Settings') return 'fas fa-user-cog';
			else if (element == 'Profile') return 'fas fa-user';
			else if (element == 'Logout') return 'fas fa-sign-out-alt';
			else if (element == 'Admin') return 'fas fa-user-tie';
			else if (element == 'LegalNotice') return 'fas fa-balance-scale';
			else return '';
		},
		async navigateTo(dest) {
			if (dest == 'Logout') {
				if (this.$route.name != 'Lobby') {
					this.$global.logoutUser();
					if (this.$route.name != 'Home') {
						await this.$router.push({ name: 'Home' });
						location.reload();
					} else location.reload();
				} else {
					if (sessionStorage.getItem('isPodium')) {
						sessionStorage.removeItem('isPodium');
						this.$global.logoutUser();

						await this.$router.push({ name: 'Home' });
						location.reload();
					} else {
						sessionStorage.setItem('wasLogout', true);
						this.$router.push({ name: 'Home' });
					}
				}
			} else {
				this.showHeader = false;
				let that = this;
				this.checkAlert(this.$route.name, function (confirmed) {
					if (confirmed) {
						that.$router.push({
							name: dest,
						});
					}
				});
			}
		},
		checkAlert(name, cb) {
			let that = this;
			let title = '';
			let text = '';
			let cancel = '';
			let confirm = '';
			// if (name == 'Lobby') {
			// 	title = 'loLeaveLobby';
			// 	text = 'loLeaveLobbyText';
			// 	cancel = 'loCancel';
			// 	confirm = 'loLeave';
			// }

			if (title == '') cb(true);
			else {
				this.$global.showPopup(
					this.$t(title),
					this.$t(text),
					'warning',
					{ text: this.$t(cancel), color: 'var(--main-color-4)' },
					{ text: this.$t(confirm), color: 'var(--main-color-error)' },
					null,
					function (result) {
						if (result.isConfirmed) {
							if (name == 'Lobby') {
								let lobby = that.$global.getSession();
								that.$socket.emitEvent('leave_session', {
									sID: lobby.session.sID,
									uID: that.$global.getUser().uID,
									state: lobby.session.state,
									host: lobby.session.uID,
								});
							}
						}
						cb(result.isConfirmed);
					}
				);
			}
		},
		setLanguage(lang) {
			if (lang == 'en') {
				this.$global.showToast('info', 'English translations are not available yet');
			} else {
				document.getElementById(lang).classList.add('he-selected-language');
				if (lang == 'de') document.getElementById('en').classList.remove('he-selected-language');
				else document.getElementById('de').classList.remove('he-selected-language');
				i18n.locale = lang;
				window.dispatchEvent(new Event('languageChange'));
			}
		},
	},
};
</script>

<style scoped>
.he-wrap-content {
	position: absolute;
	top: 0px;
	left: 0px;
	z-index: 20;
}

.he-wrap-small-header {
	width: fit-content;
	height: fit-content;
	padding: 10px;
	background-color: var(--main-color-2);
	border-bottom-right-radius: 10px;
}

.he-menu {
	color: var(--main-color-text-dark);
	font-size: 25px !important;
}

.he-menu:hover {
	cursor: pointer;
}

.he-menu-name {
	position: relative;
}

.he-menu-name span {
	padding-left: 30px;
}

.he-menu-icon {
	position: absolute;
	left: 0px;
	top: 0px;
}

.he-close {
	position: absolute;
	top: 10px;
	right: 10px;
	font-size: 25px;
}

.he-close:hover {
	color: var(--main-color-error);
	cursor: pointer;
}

.he-extended-header {
	padding-top: 10px;
}

.he-extended-header a,
.he-extended-header p {
	display: block;
	margin: 2px 10px;
	font-size: 20px;
	color: var(--main-color-text-dark);
	text-decoration: none;
}

.he-extended-header a:hover,
.he-extended-header p:hover {
	cursor: pointer;
	text-decoration: underline;
}

.he-wrap-languages {
	width: 100px;
	height: 50px;
	margin: auto;
}

.he-wrap-languages img {
	width: 40px;
	height: 24px;
	margin: 10px 5px;
	display: inline-block;
	cursor: pointer;
	opacity: 0.5;
	filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=50)';
	background-color: var(--main-color-border-light);
	vertical-align: top;
}

.he-wrap-languages img:hover {
	border: 2px solid black;
	margin: 8px 3px;
	opacity: 1;
	filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
}

.he-selected-language {
	border: 2px solid black;
	margin: 8px 3px !important;
	opacity: 1 !important;
	filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
	pointer-events: none;
}
</style>
