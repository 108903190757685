<template>
	<div class="lo-wrap-content">
		<div class="lo-wrap-component" v-show="!registration">
			<div class="lo-top-bar">
				<h1 v-html="$t('loPageTitle')"></h1>
				<fai icon="fas fa-times-circle" @click="$emit('closeLogin')"></fai>
			</div>
			<div class="lo-wrap-login">
				<div class="lo-wrap-input">
					<label>{{ $t('loEmailUsername') }}</label>
					<input
						id="autofocus"
						type="text"
						autocomplete="on"
						:placeholder="$t('loEmailUsername')"
						v-model.trim="credentials.username"
						@keyup.enter="login"
						autofocus
					/>
				</div>
				<div class="lo-wrap-input">
					<label>{{ $t('loPassword') }}</label>
					<input
						:type="[showPassword ? 'text' : 'password']"
						autocomplete="on"
						:placeholder="$t('loPassword')"
						v-model="credentials.password"
						@keyup.enter="login"
					/>
					<fai
						:icon="['fas', showPassword ? 'fa-eye-slash' : 'fa-eye']"
						:class="[showPassword ? 'lo-smaller-eye' : '']"
						@mouseenter="showPassword = true"
						@mouseleave="showPassword = false"
					></fai>
				</div>
				<button class="app-default-btn" @click="login">{{ $t('loLogin') }}</button>
				<div class="lo-wrap-links">
					<p @click="showPasswordReset = true">{{ $t('loForgotPassword') }}</p>
					<p @click="toRegistration">{{ $t('loRegister') }}</p>
				</div>
			</div>
			<div v-if="showPasswordReset" class="lo-wrap-password-reset">
				<div class="lo-password-reset">
					<h2>{{ $t('loForgotPasswordTitle') }}</h2>
					<div class="lo-wrap-input">
						<label>{{ $t('loEmail') }}</label>
						<input type="email" autocomplete="on" :placeholder="$t('loEmail')" v-model.trim="resetEmail" @keyup.enter="resetPassword" />
					</div>
					<button class="app-success-btn" @click="resetPassword">{{ $t('loResetPassword') }}</button>
					<fai icon="fas fa-times-circle" @click="showPasswordReset = false"></fai>
				</div>
			</div>
		</div>
		<div v-show="registration">
			<Registration @goToLogin="toLogin" @closeRegistration="$emit('closeLogin')" />
		</div>
	</div>
</template>

<script>
import Registration from '../../components/login/Registration.vue';
export default {
	components: { Registration },
	name: 'Login',
	props: {
		showLogin: {
			type: Boolean,
			required: true,
		},
		showRegistration: {
			type: Boolean,
			required: true,
		},
	},
	watch: {
		showLogin: {
			handler: function (newVal, oldVal) {
				if (newVal) this.registration = false;
			},
		},
		showRegistration: {
			handler: function (newVal, oldVal) {
				if (newVal) this.registration = true;
			},
		},
	},
	data() {
		return {
			showPassword: false,
			showPasswordReset: false,
			credentials: {
				username: '',
				password: '',
			},
			resetEmail: '',
			registration: false,
		};
	},
	created() {
		this.$global.checkAccess(this.$router, false);
		if (this.showLogin) this.registration = false;
		else this.registration = true;
	},
	methods: {
		login() {
			if (this.credentials.username.length === 0) this.$global.showToast('error', this.$t('loErrorEmptyUser'));
			else if (this.credentials.password.length === 0) this.$global.showToast('error', this.$t('loErrorEmptyPassword'));
			else {
				let that = this;
				this.$global.postData(
					'access',
					'/checkCredentials',
					{ credentials: this.credentials },
					{ headers: { pageauthheader: this.$global.getAccessCode() } },
					(err, data) => {
						if (err) this.$global.showToast('error', that.$t(err.response ? err.response.data.msg : 'loErrorLogin'));
						else {
							that.$global.getCredentials();
							sessionStorage.setItem('credentials', JSON.stringify(data.credentials));
							localStorage.setItem('credentials', JSON.stringify(data.credentials));
							sessionStorage.setItem('user', JSON.stringify(data.user));
							localStorage.setItem('user', JSON.stringify(data.user));

							that.$socket.initiateSocketConnection();
							location.reload();
						}
					}
				);
			}
		},
		resetPassword() {
			this.$global.postData(
				'access',
				'/initiatePasswordReset',
				{ email: this.resetEmail },
				{ headers: { pageauthheader: this.$global.getAccessCode() } },
				(err, data) => {
					if (err) this.$global.showToast('error', this.$t(err.response ? err.response.data.msg : 'loMailError'));
					else {
						this.$global.showToast('success', this.$t('loMailSend'));
						this.resetEmail = '';
						this.showPasswordReset = false;
					}
				}
			);
		},
		toRegistration() {
			this.registration = true;
		},
		toLogin() {
			this.registration = false;
		},
	},
};
</script>

<style scoped>
.lo-wrap-content {
	width: 100%;
	height: 100%;
	text-align: center;
	position: relative;
	margin: auto;
	overflow-y: auto;
	overflow-x: hidden;
}

.lo-wrap-component {
	width: 100%;
	height: 100%;
	padding: 20px;
	box-sizing: border-box;
}

.lo-wrap-content h1 {
	max-width: 80vw;
	font-size: 40px;
	margin: 0px auto;
	padding: 0px 0px 20px 0px;
	text-shadow: 3px 3px var(--main-color-6);
	-webkit-text-stroke: 2px var(--main-color-text-dark);
}

.lo-wrap-input {
	width: fit-content;
	height: fit-content;
	margin: auto;
	text-align: start;
	position: relative;
}

.lo-wrap-input label {
	margin: 10px auto;
	font-size: 20px;
}

.lo-wrap-input input {
	min-width: 400px;
	width: 100%;
	height: 40px;
	margin: 10px auto;
	padding: 5px;
	display: block;
	box-sizing: border-box;
	font-size: 17px;
}

.lo-wrap-input svg {
	font-size: 25px;
	color: var(--main-color-text-dark);
	position: absolute;
	bottom: 10px;
	right: 10px;
}

.lo-smaller-eye {
	right: 8px !important;
}

button {
	width: 300px;
	height: 40px;
	margin-top: 10px;
	font-size: 20px;
}

.lo-wrap-links {
	margin: 20px auto 0px auto;
}

.lo-wrap-links p {
	width: fit-content;
	font-size: 17px;
	font-style: italic;
	margin: 10px auto;
}

.lo-wrap-links p:hover {
	cursor: pointer;
	text-decoration: underline;
}

.lo-wrap-password-reset {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0px;
	left: 0px;
	padding: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	background-color: var(--main-color-5);
	overflow: auto;
}

.lo-wrap-password-reset h2 {
	margin: 10px auto;
	font-size: 25px;
}

.lo-top-bar svg,
.lo-wrap-password-reset svg {
	font-size: 30px;
	position: absolute;
	top: 15px;
	right: 15px;
	-webkit-filter: drop-shadow(3px 3px 0px var(--main-color-text-dark));
	filter: drop-shadow(3px 3px 0px var(--main-color-text-dark));
	color: var(--main-color-error);
}

.lo-top-bar svg,
.lo-wrap-password-reset svg:hover {
	cursor: pointer;
	color: var(--secondary-color-error);
}
</style>
