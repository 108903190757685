<template>
	<div class="cql-wrap-content">
		<div class="cql-wrap-left">
			<div class="cql-wrap-input">
				<label>{{ $t('cqlQuestion') }}</label>
				<input type="text" :placeholder="$t('cqlQuestion')" v-model="question" @keyup.enter="addQuestion" />
			</div>
			<div class="cql-wrap-input">
				<label>{{ $t('cqlHint') }}</label>
				<input type="text" :placeholder="$t('cqlHint')" v-model="hint" @keyup.enter="addQuestion" />
			</div>
			<div class="cql-wrap-input">
				<label>{{ $t('cqlAnswer') }}</label>
				<input type="text" :placeholder="$t('cqlAnswer')" v-model="answer" @keyup.enter="addQuestion" />
			</div>
			<div class="cql-wrap-input">
				<label>{{ $t('cqlLink') }}</label>
				<input type="text" :placeholder="$t('cqlLink')" v-model="link" @keyup.enter="addQuestion" />
			</div>
			<button class="app-default-btn" @click="addQuestion">{{ $t('cqlAddQuestion') }}</button>
		</div>
		<div class="cql-wrap-right">
			<p v-if="questions.length !== 0">{{ $t('cqlEasyQuestion') }}</p>
			<div class="cql-wrap-questions">
				<div v-for="question in questions" :key="question.question" class="cql-wrap-question">
					<div class="cql-wrap-icons">
						<div class="cql-wrap-up-down">
							<fai
								icon="fas fa-angle-up"
								class="cql-up"
								:class="[question.pos == 0 ? 'cql-disable' : '']"
								:disabled="question.pos == 0"
								@click="moveQuestionUp(question)"
							></fai>
							<fai
								icon="fas fa-angle-down"
								class="cql-down"
								:class="[question.pos == questions.length - 1 ? 'cql-disable' : '']"
								:disabled="question.pos == questions.length - 1"
								@click="moveQuestionDown(question)"
							></fai>
						</div>
						<fai icon="fas fa-pen" class="cql-edit" @click="editQuestion(question)"></fai>
						<fai icon="fas fa-trash" class="cql-delete" @click="deleteQuestion(question)"></fai>
					</div>
					<p>
						<strong>{{ $t('cqlQuestion') }}: </strong>{{ question.question }}
					</p>
					<p>
						<strong>{{ $t('cqlHint') }}: </strong>{{ question.hint }}
					</p>
					<p>
						<strong>{{ $t('cqlAnswer') }}: </strong>{{ question.answer }}
					</p>
				</div>
			</div>
			<p v-if="questions.length !== 0">{{ $t('cqlHardQuestion') }}</p>
		</div>
	</div>
</template>

<script>
export default {
	name: 'CategoryQuizLayout',
	props: {
		initialAnswers: {
			type: Array,
			required: true,
		},
	},
	watch: {
		initialAnswers: {
			handler: function (newVal) {
				this.questions = [
					...newVal.map((quest, idx) => {
						return {
							pos: quest.pos,
							question: quest.question,
							hint: quest.hint,
							answer: quest.answer,
							link: quest.link,
						};
					}),
				];
			},
			deep: true,
		},
	},
	data() {
		return {
			question: '',
			hint: '',
			answer: '',
			link: '',
			questions: [],
		};
	},
	methods: {
		addQuestion() {
			if (this.question.length < 1) this.$global.showToast('warn', this.$t('cqlNoQuestion'));
			else if (this.hint.length < 1) this.$global.showToast('warn', this.$t('cqlNoHint'));
			else if (this.answer.length < 1) this.$global.showToast('warn', this.$t('cqlNoAnswer'));
			else if (this.questions.length >= 6) this.$global.showToast('warn', this.$t('cqlMaxQuestionsPerCategoryReached'));
			else if (this.questions.filter((quest) => quest.question == this.question).length > 0)
				this.$global.showToast('warn', this.$t('cqlDuplicateQuestion'));
			else {
				this.questions.push({
					pos: this.questions.length,
					question: this.question,
					hint: this.hint,
					answer: this.answer,
					link: this.link,
				});
				this.renumberQuestions();
				this.question = '';
				this.hint = '';
				this.answer = '';
				this.link = '';
				this.$nextTick(() => {
					let element = document.getElementsByClassName('cql-wrap-questions')[0];
					element.scrollTo({ top: element.scrollHeight, behavior: 'smooth' });
				});
			}
		},
		moveQuestionUp(question) {
			if (question.pos !== 0) this.arrayMove(question.pos, question.pos - 1);
			this.renumberQuestions();
		},
		moveQuestionDown(question) {
			if (question.pos !== this.questions.length - 1) this.arrayMove(question.pos, question.pos + 1);
			this.renumberQuestions();
		},
		arrayMove(oldIndex, newIndex) {
			if (newIndex >= this.questions.length) {
				let k = newIndex - this.questions.length + 1;
				while (k--) this.questions.push(undefined);
			}
			this.questions.splice(newIndex, 0, this.questions.splice(oldIndex, 1)[0]);
		},

		editQuestion(question) {
			this.question = question.question;
			this.hint = question.hint;
			this.answer = question.answer;
			this.link = question.link;
			this.deleteQuestion(question);
		},
		deleteQuestion(question) {
			this.questions = this.questions.filter((quest) => quest.question !== question.question);
			this.renumberQuestions();
		},
		renumberQuestions() {
			this.questions.map((ans, idx) => {
				ans.pos = idx;
			});
			this.$emit('setQuestions', this.questions);
		},
	},
};
</script>

<style scoped>
.cql-wrap-content {
	display: flex;
	flex-flow: row wrap;
}

.cql-wrap-left,
.cql-wrap-right {
	width: 50%;
	margin-top: 20px;
	display: inline-block;
	vertical-align: top;
	flex: 1 0 400px;
	flex-flow: column;
}

.cql-wrap-input {
	width: 100%;
	height: fit-content;
	text-align: start;
	margin: auto;
	position: relative;
	display: inline-block;
	vertical-align: top;
}

.cql-wrap-input label {
	font-size: 20px;
	margin: 10px auto;
}

.cql-wrap-input input {
	width: 90%;
	height: 30px;
	padding: 5px !important;
	display: block;
	margin: 10px 0px;
	font-size: 20px;
}

button {
	font-size: 20px;
	width: 300px;
	height: 40px;
	margin: 10px auto;
	display: block;
}

.cql-wrap-right {
	max-height: 400px;
	background-color: var(--main-color-3);
	border: 1px solid var(--main-color-border-light);
	border-radius: 10px;
	box-sizing: border-box;
}

.cql-wrap-right p {
	margin: 7px 0px 7px 0px;
	font-size: 20px;
	text-align: center;
}

.cql-wrap-questions {
	width: 100%;
	height: calc(100% - 80px);
	padding: 10px;
	box-sizing: border-box;
	overflow: auto;
}

.cql-wrap-questions p {
	margin: 0px;
	font-size: 17px;
	text-align: start;
}

.cql-wrap-question {
	min-height: 40px;
	padding: 5px;
	margin: 5px 0px;
	border: 1px solid var(--main-color-border-dark);
	background-color: var(--main-color-4);
	box-shadow: 2px 2px 4px 4px var(--main-color-border-dark);
	box-sizing: border-box;
	text-align: start;
}

.cql-wrap-icons {
	float: right;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 3px;
}

.cql-wrap-icons svg {
	margin: 0px 3px;
	cursor: pointer;
	-webkit-filter: drop-shadow(3px 3px 0px var(--main-color-text-dark));
	filter: drop-shadow(3px 3px 0px var(--main-color-text-dark));
}

.cql-edit {
	font-size: 20px;
	color: var(--main-color-warn);
}

.cql-delete {
	font-size: 20px;
	color: var(--main-color-error);
}

.cql-edit:hover {
	color: var(--secondary-color-warn);
}

.cql-delete:hover {
	color: var(--secondary-color-error);
}

.cql-wrap-up-down {
	width: 30px;
	height: 20px;
	position: relative;
	display: inline-block;
}

.cql-up {
	position: absolute;
	top: -10px;
	right: 0px;
	font-size: 25px;
	color: var(--main-color-text-light);
}

.cql-up:hover {
	color: var(--main-color-6);
}

.cql-down {
	position: absolute;
	bottom: -10px;
	right: 0px;
	font-size: 25px;
	color: var(--main-color-text-light);
}

.cql-down:hover {
	color: var(--main-color-6);
}

.cql-disable {
	color: var(--main-color-disabled) !important;
}

.cql-disable:hover {
	cursor: default !important;
}
</style>
