<template>
	<div class="sb-wrap-content">
		<div v-if="!showHistory" class="sb-wrap-icons-right">
			<div class="sb-wrap-icon" @click="setViewState($event, 'showLeaderboard')">
				<fai icon="fas fa-trophy" />
			</div>
			<div class="sb-wrap-icon" @click="setViewState($event, 'showGameShowConfig')">
				<fai icon="fas fa-question" />
			</div>
			<div :class="[showHistory ? 'sb-selected' : '', 'sb-wrap-icon', 'sb-history']" @click="toggleHistory">
				<fai icon="fas fa-list-alt" />
			</div>
			<div v-if="$global.isHost()" class="sb-wrap-icon" @click="setViewState($event, 'showHostTools')">
				<fai icon="fas fa-tools" />
			</div>
			<div class="sb-wrap-icon" @click="setViewState($event, 'showBugReport')">
				<fai icon="fas fa-bug" />
			</div>
		</div>
		<div v-else-if="showHistory" class="sb-wrap-icons-top">
			<div class="sb-wrap-icon" @click="setViewState($event, 'showLeaderboard')">
				<fai icon="fas fa-trophy" />
			</div>
			<div class="sb-wrap-icon" @click="setViewState($event, 'showGameShowConfig')">
				<fai icon="fas fa-question" />
			</div>
			<div :class="[showHistory ? 'sb-selected' : '', 'sb-wrap-icon', 'sb-history']" @click="showHistory = !showHistory">
				<fai icon="fas fa-list-alt" />
			</div>
			<div v-if="$global.isHost()" class="sb-wrap-icon" @click="setViewState($event, 'showHostTools')">
				<fai icon="fas fa-tools" />
			</div>
			<div class="sb-wrap-icon" @click="setViewState($event, 'showBugReport')">
				<fai icon="fas fa-bug" />
			</div>
		</div>
		<div v-if="showHistory" class="sb-wrap-history">
			<History :history="history" :players="users" :shows="shows" :configuredShows="configuredShows" />
		</div>
		<div v-show="showLeaderboard || showGameShowConfig || showHostTools || showBugReport" class="sb-wrap-info">
			<h2 v-if="showLeaderboard">{{ $t('sbLeaderboard') }}</h2>
			<h2 v-if="showGameShowConfig">{{ $t('sbGameShowConfig') }}</h2>
			<h2 v-if="showHostTools">{{ $t('sbHostTools') }}</h2>
			<h2 v-if="showBugReport">{{ $t('sbBugReportForm') }}</h2>
			<fai icon="fas fa-times-circle" class="sb-close-dialog" @click="setViewState($event, 'close')"></fai>
			<Leaderboard v-if="showLeaderboard" :leaderboard="leaderboard" :players="users" />
			<div v-if="showLeaderboard && hasEnoughData" class="sb-wrap-points-graph">
				<h2>{{ $t('poGameCourse') }}</h2>
				<PointsGraph :shows="configuredShows" :history="history" :players="users.filter((it) => it.uID != host.uID)" />
			</div>
			<GameShowConfigList
				v-if="showGameShowConfig && sessionState"
				:registeredShows="shows"
				:configuredShows="getShowConfig()"
				:state="{ lobby: true, showDescription: true, isPrivate: true, isPublic: true }"
			/>
			<BugReportForm v-if="showBugReport && shows" :shows="shows" :selectedShow="getShow()" @closeBugReport="setViewState(null, 'close')" />
			<div v-if="showHostTools">
				<ConnectionCheck
					:users="users"
					@checkUserConnections="$emit('checkUserConnections', $event)"
					@forceReload="$emit('forceReload', $event)"
					@forceRejoin="$emit('forceRejoin', $event)"
					@kickPlayer="$emit('kickPlayer', $event)"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import History from './History.vue';
import Leaderboard from './Leaderboard.vue';
import GameShowConfigList from '../quizNightCreation/GameShowConfigList.vue';
import BugReportForm from '../help/BugReportForm.vue';
import ConnectionCheck from './ConnectionCheck.vue';
import PointsGraph from './PointsGraph.vue';
export default {
	name: 'SideBar',
	components: {
		History,
		Leaderboard,
		GameShowConfigList,
		BugReportForm,
		ConnectionCheck,
		PointsGraph,
	},
	props: {
		history: {
			type: Array,
			required: true,
		},
		users: {
			type: Array,
			required: true,
		},
		configuredShows: {
			type: Array,
			required: true,
		},
		shows: {
			type: Array,
			required: true,
		},
		leaderboard: {
			type: Array,
			required: true,
		},
		sessionState: {
			type: Object,
			required: false,
		},
		host: {
			type: Object,
			required: true,
		},
	},
	watch: {
		showHistory: {
			handler: function () {
				this.$nextTick(() => {
					window.dispatchEvent(new Event('resize'));
				});
			},
		},
		sessionState: {
			handler: function (newVal) {
				if (['created', 'lobby', 'finished'].includes(newVal.currentState)) this.showHistory = false;
				else this.showHistory = true;
			},
		},
	},
	data() {
		return {
			showHistory: false,
			showLeaderboard: false,
			showGameShowConfig: false,
			showBugReport: false,
			showHostTools: false,
		};
	},
	computed: {
		hasEnoughData: function () {
			return this.history.find((his) => his.action == 'setState' && his.result != 'lobby');
		},
	},
	created() {
		if (['created', 'lobby', 'finished'].includes(this.sessionState.currentState)) this.showHistory = false;
		else this.showHistory = true;
	},
	mounted() {
		window.addEventListener('resize', this.resizeListener);
		window.dispatchEvent(new Event('resize'));
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.resizeListener);
	},
	methods: {
		resizeListener() {
			let sb = document.querySelector('.sb-wrap-info');
			let sbh = document.querySelector('.sb-wrap-history');
			let uc = document.querySelector('.uc-wrap-content');
			let hc = document.querySelector('.hc-wrap-content');
			let wr = document.querySelector('.wr-wrap-content');
			let po = document.querySelector('.po-wrap-content');
			let gsp = document.querySelector('.gsp-wrap-content');
			let gs = document.querySelector('.gs-wrap-content');
			let cql = document.querySelector('.gs-wrap-kq');
			let modifier = 0;
			if (wr) {
				let wrOverflowing = wr.clientHeight < wr.scrollHeight;
				if (wrOverflowing) modifier += 16;
			}

			if (gsp) {
				let gspOverflowing = gsp.clientHeight < gsp.scrollHeight;
				if (gspOverflowing) modifier += 16;
			}

			if (gs) {
				let gsOverflowing = gs.clientHeight < gs.scrollHeight;

				if (gsOverflowing && !this.showHistory) modifier += 16;
			}

			if (po) {
				let poOverflowing = po.clientHeight < po.scrollHeight;

				if (poOverflowing) modifier += 16;
			}

			let tools = uc ? uc : hc;
			if (tools && sbh) sbh.style.height = `${window.innerHeight - 94 - tools.clientHeight}px`;
			if (this.showHistory && gs) gs.style.width = `${window.innerWidth - 312}px`;
			else if (!this.showHistory && gs) gs.style.width = `${window.innerWidth}px`;

			if (this.sessionState.currentState == 'wlp' && this.history.filter((his) => his.action == 'showReveal').length > 0) {
				let reveal = document.querySelector('.gs-wrap-wlp-reveal');
				if (reveal) {
					let uc = document.querySelector('.uc-wrap-content');
					if (uc) reveal.style.height = `${window.innerHeight - uc.clientHeight - 70}px`;
					let hc = document.querySelector('.hc-wrap-content');
					if (hc) reveal.style.height = `${window.innerHeight - hc.clientHeight - 70}px`;
				}
			}

			if (this.sessionState.currentState == 'kq') {
				let padding = document.querySelector('.gs-wrap-top').clientHeight;
				let uc = document.querySelector('.uc-wrap-content');
				if (uc) cql.style.height = `${window.innerHeight - uc.clientHeight - padding}px`;
				let hc = document.querySelector('.hc-wrap-content');
				if (hc) cql.style.height = `${window.innerHeight - hc.clientHeight - padding}px`;
			}

			document.querySelector('.sb-wrap-content').style.marginRight = `${modifier}px`;
			if (this.showHistory) {
				if (window.innerWidth >= 920) {
					sb.style.width = '800px';
					sb.style.left = '-548px';
				} else {
					sb.style.width = `${window.innerWidth - 120}px`;
					sb.style.left = `calc(-${window.innerWidth - 370}px)`;
				}
			} else {
				if (window.innerWidth >= 920) {
					sb.style.width = '800px';
					sb.style.left = '-800px';
				} else {
					sb.style.width = `${window.innerWidth - 120}px`;
					sb.style.left = `calc(-${window.innerWidth - 120}px - 2px)`;
				}
			}
		},
		getShowConfig() {
			let show = '';
			if (['created', 'lobby'].includes(this.sessionState.currentState)) show = this.sessionState.nextState;
			else show = this.sessionState.currentState;
			return this.configuredShows.filter((conf) => conf.show == show);
		},
		getShow() {
			let show = this.shows.filter((s) => s.abbreviation == this.sessionState.currentState)[0];
			if (!show) show = 'global';
			else show = show.abbreviation;
			return show;
		},
		toggleHistory() {
			this.showHistory = !this.showHistory;
			this.$nextTick(() => {
				window.dispatchEvent(new Event('resize'));
			});
		},
		setViewState(e, state) {
			document.querySelectorAll('.sb-wrap-icon').forEach((icon) => {
				icon.classList.remove('sb-selected');
			});
			let target = e ? (e.target.tagName == 'DIV' ? e.target : e.target.parentElement) : null;
			while (target && target.parentElement) {
				if (target.tagName && target.tagName == 'DIV') break;
				target = target.parentElement;
			}
			if (this.showHistory) document.querySelector('.sb-history').classList.add('sb-selected');

			if (state == 'showGameShowConfig') {
				this.showGameShowConfig = !this.showGameShowConfig;
				this.showLeaderboard = false;
				this.showHostTools = false;
				this.showBugReport = false;
				if (this.showGameShowConfig) target.classList.add('sb-selected');
			} else if (state == 'showLeaderboard') {
				this.showGameShowConfig = false;
				this.showLeaderboard = !this.showLeaderboard;
				this.showHostTools = false;
				this.showBugReport = false;
				if (this.showLeaderboard) target.classList.add('sb-selected');
			} else if (state == 'showHostTools') {
				this.showGameShowConfig = false;
				this.showLeaderboard = false;
				this.showHostTools = !this.showHostTools;
				this.showBugReport = false;
				if (this.showHostTools) target.classList.add('sb-selected');
			} else if (state == 'showBugReport') {
				this.showBugReport = !this.showBugReport;
				this.showGameShowConfig = false;
				this.showLeaderboard = false;
				this.showHostTools = false;
				if (this.showBugReport) target.classList.add('sb-selected');
			} else if (state == 'close') {
				this.showGameShowConfig = false;
				this.showLeaderboard = false;
				this.showHostTools = false;
				this.showBugReport = false;
			}
		},
	},
};
</script>

<style scoped>
.sb-wrap-content {
	position: absolute;
	top: 0px;
	right: 0px;
	z-index: 15;
}

.sb-wrap-icons-right {
	float: right;
}

.sb-wrap-icons-top .sb-wrap-icon {
	float: right;
	border-radius: 2px 2px 10px 10px;
	margin: 5px;
}

.sb-wrap-icon {
	display: flex;
	width: 30px;
	height: 30px;
	margin: 5px 5px 10px 5px;
	padding: 10px;
	background-color: var(--main-color-2);
	border-radius: 10px 2px 2px 10px;
	align-items: center;
	justify-content: center;
	font-size: 27px;
}

.sb-wrap-icon:hover {
	cursor: pointer;
	background-color: var(--main-color-6);
	color: var(--main-color-1);
}

.sb-selected {
	background-color: var(--main-color-6);
	color: var(--main-color-text-light);
}

.sb-wrap-info {
	height: fit-content;
	position: absolute;
	top: 60px;
	border-radius: 20px;
	padding: 3vh 2vw 2vh 2vw;
	box-sizing: border-box;
	box-shadow: -10vw -10vh 0px 10000px var(--main-color-light-transparent-80);
	background-color: var(--main-color-5);
	border: 2px solid var(--main-color-border-dark);
	overflow: auto;
	z-index: 2;
}

.sb-wrap-info h2 {
	font-size: 40px;
	max-width: 80%;
	margin: 0px auto 2vh auto;
	text-align: center;
}

.sb-close-dialog {
	font-size: 3em;
	position: absolute;
	top: 1vh;
	right: 1vw;
	color: var(--main-color-error);
	-webkit-filter: drop-shadow(4px 4px 2px var(--main-color-text-dark));
	filter: drop-shadow(4px 4px 2px var(--main-color-text-dark));
	/* -webkit-text-stroke: 1px var(--main-color-text-dark); */
}

.sb-close-dialog:hover {
	cursor: pointer;
	color: var(--secondary-color-error);
}

.sb-wrap-history {
	width: 300px;
	height: calc(100vh - 94px);
	margin-top: 70px;
	padding: 10px 0px 10px 10px;
	border-radius: 20px 0px 0px 20px;
	background-color: var(--main-color-2);
	border: 2px solid var(--main-color-border-dark);
	border-right: none;
}

.sb-host-btn {
	width: 230px;
	height: fit-content;
	display: block;
	margin: 20px auto 5px auto;
	font-size: 17px;
}

.sb-wrap-points-graph {
	width: 90%;
	margin: 20px auto;
	padding: 10px;
}

.sb-wrap-info h2 {
	font-size: 40px;
	margin: 0px auto;
	padding: 0px 0px;
	text-shadow: 3px 3px var(--main-color-6);
	-webkit-text-stroke: 2px var(--main-color-text-dark);
	text-align: center;
}
</style>
