<template>
	<div class="lc-wrap-content">
		<h1>{{ $t('lcLobbyCreation') }}</h1>
		<div class="lc-wrap-top">
			<div class="lc-wrap-input">
				<label>{{ $t('lcLobbyName') }}</label>
				<input type="text" :placeholder="$t('lcLobbyName')" v-model="lobbyName" />
			</div>
			<div class="lc-wrap-input">
				<label>{{ $t('lcPassword') }}</label>
				<input type="text" :placeholder="$t('lcPassword')" v-model="password" />
			</div>
			<div class="lc-wrap-input-small">
				<label>{{ $t('lcAmountPlayers') }}</label>
				<input type="number" :placeholder="$t('lcAmountPlayers')" v-model.number="amountPlayers" min="2" max="6" @change="checkInput" />
			</div>
		</div>
		<div class="lc-wrap-quiz-nights-browser">
			<div class="lc-wrap-quiz-night-top-bar">
				<h2>{{ $t('hoQuizNightBrowser') }}</h2>
				<div class="lc-wrap-quiz-night-filter">
					<FilterBar :type="'quizNight'" :isLoading="isLoading" :amountElements="quizNightAmount" @refresh="refreshBrowser" />
				</div>
			</div>
			<div class="lc-blur-ref">
				<div v-show="!firstItemVisible" class="lc-blur-before"></div>
				<div class="lc-wrap-quiz-nights">
					<QuizNightPreview
						v-for="(night, idx) in quizNights"
						:id="`quizNight-${idx}`"
						:key="idx"
						:night="night"
						@configureQuizNight="selectQuizNight(night)"
					/>
					<div v-if="quizNights.length == 0 && !isLoading" class="lc-wrap-no-quiz-nights">
						<div class="lc-wrap-refresh">
							<fai icon="fas fa-sad-tear" />
							<p class="lc-no-quiz-nights">{{ $t('hoNoQuizNightsFound') }}</p>
						</div>
					</div>
				</div>
				<div v-show="!lastItemVisible" class="lc-blur-after"></div>
			</div>
		</div>
		<div class="lc-wrap-bottom">
			<h2 v-if="selectedQuizNight">{{ selectedQuizNight.name }}</h2>
			<h3 v-else>{{ $t('lcNoQuizNightSelected') }}</h3>
			<div class="lc-wrap-list">
				<GameShowConfigList
					v-if="selectedQuizNight"
					:registeredShows="shows"
					:configuredShows="selectedQuizNight.configs"
					:state="{
						lobby: true,
						showDescription: false,
						isPrivate: selectedQuizNight.isPrivate,
						isPublic: selectedQuizNight.isPublic,
					}"
				/>
			</div>
		</div>
		<div class="lc-wrap-bottom-cards">
			<div class="lc-card lc-create-card" @click="createLobby">
				<div>
					<fai icon="fas fa-couch" />
					<p>{{ $t('lcCreateLobby') }}</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import GameShowConfigList from '../components/quizNightCreation/GameShowConfigList.vue';
import QuizNightPreview from '../components/configuration/QuizNightPreview.vue';
import FilterBar from '../components/lobby/filter/FilterBar';
export default {
	name: 'LobbyCreation',
	components: {
		FilterBar,
		QuizNightPreview,
		GameShowConfigList,
	},
	data() {
		return {
			lobbyName: '',
			password: '',
			amountPlayers: 2,
			shows: [],
			selectedQuizNight: null,
			isLoading: false,
			user: null,
			refreshClicked: false,
			quizNightFilter: null,
			quizNights: [],
			quizNightAmount: 0,
			firstItemVisible: true,
			lastItemVisible: true,
		};
	},
	created() {
		this.$global.checkAccess(this.$router, true);
		this.user = this.$global.getUser();
		this.lobbyName = `${this.user.name}'s ${this.$t('lcQuizNight')}`;
		this.getRegisteredShows();
	},
	mounted() {
		document.querySelector('.lc-wrap-content').addEventListener('scroll', this.parentScrollListener);
		document.querySelector('.lc-wrap-quiz-nights').addEventListener('scroll', this.scrollListener);
	},
	beforeDestroy() {
		document.querySelector('.lc-wrap-content').removeEventListener('scroll', this.parentScrollListener);
		document.querySelector('.lc-wrap-quiz-nights').removeEventListener('scroll', this.scrollListener);
	},
	methods: {
		getRegisteredShows() {
			let that = this;
			this.$global.getData(
				'quiz',
				'/getRegisteredShows',
				{
					headers: { pageauthheader: this.$global.getAccessCode() },
					auth: this.$global.getCredentials(),
				},
				null,
				function (err, result) {
					if (err) that.$global.showToast('error', that.$t(err.response ? err.response.data.msg : 'lcShowsError'));
					else {
						that.shows = result;
					}
				}
			);
		},
		parentScrollListener(e) {
			window.dispatchEvent(new Event('customScroll'));
		},
		scrollListener(e) {
			this.firstItemVisible = this.itemVisible(true);
			this.lastItemVisible = this.itemVisible(false);
		},
		itemVisible(first) {
			const item = document.getElementById(`quizNight-${first ? 0 : this.quizNights.length - 1}`);
			const container = document.querySelector('.lc-wrap-quiz-nights');
			if (item && container) {
				const containerRect = container.getBoundingClientRect();
				const itemRect = item.getBoundingClientRect();

				// Check if the item's top and bottom are within the container's visible area
				const isTopVisible = itemRect.top >= containerRect.top && itemRect.top <= containerRect.bottom;
				const isBottomVisible = itemRect.bottom >= containerRect.top && itemRect.bottom <= containerRect.bottom;

				return isTopVisible || isBottomVisible;
			} else if (this.quizNights.length == 0) return true;
			else return false;
		},
		queryQuizNights() {
			let that = this;
			if (this.quizNightFilter.shows.length == 0) {
				this.$global.showToast('warn', this.$t('hoNoShowsSelected'));
				this.refreshClicked = false;
			} else {
				this.isLoading = true;
				this.$global.getData(
					'quiz',
					'/getQuizNights',
					{
						headers: { pageauthheader: this.$global.getAccessCode() },
						auth: this.$global.getCredentials(),
						params: {
							uID: this.user.uID,
							quizNightFilter: this.quizNightFilter != null ? JSON.stringify({ ...this.quizNightFilter, filterPlayable: true }) : null,
						},
					},
					null,
					function (err, result) {
						if (err) that.$global.showToast('error', that.$t(err.response ? err.response.data.msg : 'qncQueryError'));
						else {
							// result.quizNights = result.quizNights
							// 	.map((night) => {
							// 		if (night.isPrivate || night.isPublic) return night;
							// 	})
							// 	.filter((night) => night);
							that.quizNights = result.quizNights;
							that.quizNightAmount = result.quizNightAmount;
						}
						that.isLoading = false;
						that.refreshClicked = false;
					}
				);
			}
		},
		refreshBrowser(quizNightFilter) {
			if (!this.refreshClicked) {
				this.refreshClicked = true;
				this.quizNightFilter = quizNightFilter;
				this.quizNights = [];
				this.queryQuizNights();
			}
		},
		selectQuizNight(night) {
			this.selectedQuizNight = null;
			this.loadExistingConfig(night.qID);
		},
		configureQuizNight() {
			this.$router.push({ name: 'QuizNightConfigurationOverview' });
		},
		loadExistingConfig(qID) {
			let that = this;
			this.$global.getData(
				'quiz',
				'/getQuizNight',
				{
					headers: { pageauthheader: this.$global.getAccessCode() },
					auth: this.$global.getCredentials(),
					params: { qID: qID },
				},
				null,
				function (err, result) {
					if (err) that.$global.showToast('error', that.$t(err.response ? err.response.data.msg : 'lcQuizNightError'));
					else {
						that.$nextTick(() => {
							result.configs.map((conf) => {
								conf.show = that.shows.filter((show) => show.rID == conf.rID)[0].abbreviation;
								if (conf.pointsPerLevel) conf.pointsPerLevel = JSON.parse(conf.pointsPerLevel);
								return conf;
							});
							that.selectedQuizNight = result;

							that.$nextTick(() => {
								let wrapper = document.querySelector('.lc-wrap-content');
								wrapper.scroll({ top: wrapper.scrollHeight, behavior: 'smooth' });
							});
						});
					}
				}
			);
		},
		checkInput() {
			if (this.amountPlayers < 2) this.amountPlayers = 2;
			else if (this.amountPlayers > 6) this.amountPlayers = 6;
		},
		zgwCheck() {
			let configuredShow = this.selectedQuizNight.configs.filter((conf) => conf.show == 'zgw')[0];
			if (!configuredShow) return true;
			else if (configuredShow.questions.length < this.amountPlayers) {
				this.$global.showToast('warn', this.$t('lcZGWPlayerError'));
				return false;
			} else return true;
		},
		kqCheck() {
			let configuredShow = this.selectedQuizNight.configs.filter((conf) => conf.show == 'kq')[0];
			if (!configuredShow) return true;
			else {
				let maxQuestions = configuredShow.multipleAnswersPerCat
					? configuredShow.questions.length * configuredShow.pointsPerLevel.length
					: configuredShow.questions.length;

				if (maxQuestions < this.amountPlayers) {
					this.$global.showToast('warn', `${this.$t('lcKQPlayerError')} (${maxQuestions})`);
					return false;
				} else return true;
			}
		},
		createLobby() {
			if (this.lobbyName.length < 5 || this.lobbyName.length > 50) this.$global.showToast('warn', this.$t('lcNameLength'));
			else if (!this.selectedQuizNight) this.$global.showToast('warn', this.$t('lcNoQuizNight'));
			else if (this.zgwCheck() && this.kqCheck()) {
				let that = this;
				this.$global.postData(
					'session',
					'/createSession',
					{
						qID: this.selectedQuizNight.qID,
						uID: this.user.uID,
						name: this.lobbyName,
						password: this.password.length == 0 ? null : this.password,
						amountPlayers: this.amountPlayers,
					},
					{
						headers: { pageauthheader: this.$global.getAccessCode() },
						auth: this.$global.getCredentials(),
					},
					function (err, result) {
						if (err) that.$global.showToast('error', that.$t(err.response ? err.response.data.msg : 'lcLobbyError'));
						else {
							that.$socket.emitEvent('created_session', result);
							that.$router.push({ name: 'Lobby', params: { sID: result } });
						}
					}
				);
			}
		},
	},
};
</script>

<style>
.lc-wrap-content {
	width: 100%;
	height: 100%;
	padding: 20px 40px;
	box-sizing: border-box;
	overflow-x: hidden;
	overflow-y: auto;
}

.lc-wrap-top {
	width: 100%;
	height: fit-content;
	margin: 20px auto;
	padding: 0px 2vw;
}

.lc-wrap-input,
.lc-wrap-input-small {
	width: calc((100% - 250px) / 2);
	height: fit-content;
	text-align: start;
	margin: auto;
	position: relative;
	display: inline-block;
}

.lc-wrap-input-small {
	width: 200px;
}

.lc-wrap-input label,
.lc-wrap-input-small label {
	font-size: 20px;
	margin: 20px auto;
}

.lc-wrap-input input,
.lc-wrap-input-small input {
	width: 80%;
	height: 30px;
	padding: 5px;
	display: block;
	margin: 20px 0px;
	font-size: 20px;
}

.lc-wrap-quiz-nights-browser {
	width: calc(100% - 10px);
	height: 80vh;
	min-height: 300px;
	display: flex;
	flex-flow: row;
	justify-content: center;
	align-items: center;
	flex: 1 1 100%;
	margin: 5px;
	padding: 10px;
	flex-flow: column;
	box-sizing: border-box;
	border: 2px solid var(--main-color-border-dark);
	background-color: var(--main-color-3-cc);
}

.lc-wrap-quiz-night-top-bar {
	width: 100%;
	height: 160px;
	text-align: center;
}

.lc-wrap-quiz-night-top-bar h2 {
	height: 40px;
	margin: 0px auto;
	padding: 0px 0px 10px 0px;
	font-size: 35px;
	text-shadow: 2px 2px var(--main-color-6);
	-webkit-text-stroke: 2px var(--main-color-text-dark);
}

.lc-wrap-quiz-night-top-bar h2 svg {
	margin: 5px;
	font-size: 45px;
	color: var(--main-color-text-light);
	filter: drop-shadow(2px 2px 2px var(--main-color-border-dark));
}

.lc-wrap-quiz-night-filter {
	width: 100%;
	height: 100px;
	margin-right: 15px;
	box-sizing: border-box;
	padding: 0px 5px 0px 0px;
	overflow-y: auto;
	overflow-x: hidden;
	border-bottom: 1px solid var(--main-color-border-light);
}

.lc-blur-ref {
	width: 100%;
	height: calc(100% - 150px);
	position: relative;
}

.lc-wrap-quiz-nights {
	width: 100%;
	height: 100%;
	padding: 00px 10px;
	box-sizing: border-box;
	overflow: auto;
}

.lc-blur-before,
.lc-blur-after {
	position: absolute;
	left: 8px;
	width: calc(100% - 30px);
	margin: auto;
	height: 60px;
	backdrop-filter: blur(1px);
	z-index: 10;
	pointer-events: none;
}

.lc-blur-before {
	top: 0px;
	background: linear-gradient(to bottom, #00000033, #00000000);
}

.lc-blur-after {
	bottom: 0;
	background: linear-gradient(to top, #00000033, #00000000);
}

.lc-wrap-no-quiz-nights {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.lc-wrap-no-quiz-nights p {
	text-shadow: 3px 3px var(--main-color-border-dark);
}

.lc-wrap-refresh {
	text-align: center;
	font-size: 30px;
}

.lc-wrap-refresh svg {
	margin: 10px;
	font-size: 60px;
	filter: drop-shadow(2px 2px 2px var(--main-color-border-dark));
	color: var(--main-color-6);
}

.lc-wrap-bottom {
	width: 100%;
	margin: 30px 0px;
	text-align: center;
}

.lc-wrap-bottom h2 {
	font-size: 35px;
	text-shadow: 2px 2px var(--main-color-6);
	-webkit-text-stroke: 2px var(--main-color-text-dark);
}

.lc-wrap-bottom-cards {
	width: 100%;
	padding: 0px 5%;
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	flex-flow: row;
	align-items: center;
}

.lc-card {
	flex: 1 1 50%;
	height: 10vh;
	min-height: 100px;
	margin: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: transform 0.5s;
	border: 2px solid var(--main-color-border-dark);
}

.lc-card:hover {
	cursor: pointer;
	transform: scale(1.1);
	z-index: 5;
}

.lc-card div {
	text-align: center;
}

.lc-card svg {
	margin: 5px;
	font-size: 45px;
	color: var(--main-color-text-light);
	filter: drop-shadow(2px 2px 2px var(--main-color-border-dark));
}

.lc-card p {
	font-size: 20px;
	text-shadow: 1px 1px var(--main-color-text-dark);
}

.lc-create-card {
	background-color: var(--main-color-success-cc);
}
</style>
