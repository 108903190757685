<template>
	<div class="qc-wrap-inner-content">
		<div v-if="['oh', 'wbm', 'dpin', 'zgw', 'smf', 'sq', 'wlp', 'xfz', 'lz'].includes(show)" class="qc-wrap-input">
			<label>{{ $t('qcQuestion') }}</label>
			<input type="text" v-model="question" :placeholder="$t('qcQuestion')" />
		</div>
		<div v-if="['smf', 'xfz'].includes(show)" class="qc-wrap-input">
			<label>{{ $t('qcQuestionType') }}</label>
			<select class="qc-type-select" v-model="type" @change="imageIsAnswer = false">
				<option value="" default selected hidden>{{ $t('qcSelectQuestionType') }}</option>
				<option value="Freetext">{{ $t('qcFreetext') }}</option>
				<option value="SingleChoice">{{ $t('qcSingleChoice') }}</option>
				<option value="Buzzer">{{ $t('qcBuzzer') }}</option>
				<option value="BuzzerSingleChoice">{{ $t('qcBuzzerSingleChoice') }}</option>
			</select>
		</div>
		<div v-if="['kq'].includes(show)" class="qc-wrap-input qc-center">
			<label>{{ $t('qcCategory') }}</label>
			<input type="text" :placeholder="$t('qcCategory')" v-model="category" />
		</div>
		<br v-if="['dpin', 'lz', 'smf', 'wlp', 'xfz'].includes(show)" />
		<div v-if="['dpin', 'wlp'].includes(show) || (show == 'smf' && type != '')" class="qc-wrap-image">
			<div class="qc-wrap-input">
				<label>{{ $t('qcFirstImageLink') }}</label>
				<input type="text" :placeholder="$t('qcFirstImageLink')" v-model="firstImageLink" />
			</div>
			<div class="qc-wrap-image-upload">
				<ImageUpload
					:imgID="'first'"
					:src="firstImage"
					:link="firstImageLink"
					@imageIsValid="firstImageValid = $event"
					@imageParsed="firstImage = $event"
				/>
			</div>
		</div>
		<div v-if="['smf'].includes(show) && hasTwoImages && type != ''" class="qc-wrap-image">
			<div class="qc-wrap-input">
				<label>{{ $t('qcSecondImageLink') }}</label>
				<input type="text" :placeholder="$t('qcSecondImageLink')" v-model="secondImageLink" />
			</div>
			<div class="qc-wrap-image-upload">
				<ImageUpload
					:imgID="'second'"
					:src="secondImage"
					:link="secondImageLink"
					@imageIsValid="secondImageValid = $event"
					@imageParsed="secondImage = $event"
				/>
			</div>
		</div>
		<div v-if="['smf'].includes(show) && !hasTwoImages && !['', 'SingleChoice', 'BuzzerSingleChoice'].includes(type)" class="qc-wrap-input">
			<label>{{ $t('qcImageIsAnswer') }}</label>
			<input type="checkbox" v-model="imageIsAnswer" />
		</div>
		<div v-if="['smf'].includes(show) && !imageIsAnswer && type != ''" class="qc-wrap-input">
			<label>{{ $t('qcHasTwoImages') }}</label>
			<input type="checkbox" v-model="hasTwoImages" />
		</div>
		<div
			v-if="['oh', 'wbm'].includes(show) || (['smf', 'xfz'].includes(show) && ['SingleChoice', 'BuzzerSingleChoice'].includes(type))"
			class="qc-wrap-answer-pos-list"
		>
			<AnswerPosList
				:key="resetToggle"
				:initialAnswers="initialAnswers"
				:initialUpperTitle="initialUpperTitle"
				:initialLowerTitle="initialLowerTitle"
				:show="show"
				@updateAnswerPos="updateAnswerPos"
			/>
		</div>
		<div
			v-if="
				['dpin', 'sq', 'wlp'].includes(show) ||
				(['smf', 'xfz'].includes(show) && !['', 'SingleChoice', 'BuzzerSingleChoice'].includes(type) && !imageIsAnswer && !hasTwoImages)
			"
			class="qc-wrap-input"
		>
			<label>{{ $t('qcAnswer') }}</label>
			<input
				:type="show == 'wlp' ? 'number' : 'text'"
				min="0"
				v-model="answer"
				@change="show == 'wlp' ? checkInput($event) : null"
				:placeholder="$t('qcAnswer')"
			/>
		</div>
		<div v-if="['kq'].includes(show)" class="qc-wrap-category-quiz">
			<CategoryQuizLayout :key="resetToggle" :initialAnswers="initialAnswers" @setQuestions="answerPos = $event" />
		</div>
		<div v-if="['lz'].includes(show)" class="qc-wrap-category-quiz">
			<ListCheckLayout
				:key="resetToggle"
				:initialAnswers="initialAnswers"
				:initialCategories="initialCategories"
				@setLists="lists = $event"
				@setBaits="baits = $event"
			/>
		</div>
		<div v-if="['oh', 'wbm', 'dpin', 'smf', 'sq', 'wlp', 'xfz'].includes(show)" class="qc-wrap-input">
			<label>{{ $t('qcLink') }}</label>
			<input type="text" v-model="link" :placeholder="$t('qcLink')" />
		</div>
		<div class="qc-wrap-textarea">
			<label>{{ $t('qcNotes') }}</label>
			<textarea v-model="notes" :placeholder="$t('qcNotesInfo')" />
		</div>
		<div class="qc-wrap-bottom">
			<div class="qc-card qc-add-question-card" @click="addQuestion">
				<div>
					<fai icon="fas fa-question" />
					<p>{{ getsEdited ? $t('qcUpdateQuestion') : $t('qcAddQuestion') }}</p>
				</div>
			</div>
			<div class="qc-card qc-discard-card" @click="discardChanges">
				<div>
					<fai icon="fas fa-object-group" />
					<p>{{ $t('qcDiscardChanges') }}</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import AnswerPosList from './AnswerPosList.vue';
import ImageUpload from './ImageUpload.vue';
import CategoryQuizLayout from './CategoryQuizLayout.vue';
import ListCheckLayout from './ListCheckLayout.vue';
export default {
	name: 'QuestionConfig',
	components: {
		AnswerPosList,
		ImageUpload,
		CategoryQuizLayout,
		ListCheckLayout,
	},
	props: {
		show: {
			type: String,
			required: true,
		},
		editedQuestion: {
			type: Object,
			required: false,
		},
		getsEdited: {
			type: Boolean,
			required: true,
		},
	},
	watch: {
		show: {
			handler: function () {
				this.resetCreation();
			},
		},
		editedQuestion: {
			handler: function (newVal) {
				if (newVal) {
					this.resetCreation();
					this.$nextTick(() => {
						this.setQuestion();
					});
				}
			},
		},
	},
	data() {
		return {
			initialAnswers: [],
			initialCategories: [],
			initialUpperTitle: '',
			initialLowerTitle: '',
			resetToggle: false,
			question: '',
			firstImage: '',
			firstImageLink: '',
			firstImageValid: false,
			hasTwoImages: false,
			secondImage: '',
			secondImageLink: '',
			secondImageValid: false,
			imageIsAnswer: false,
			type: '',
			answerPos: [],
			lists: [],
			baits: [],
			upperTitle: '',
			lowerTitle: '',
			answer: '',
			link: '',
			notes: '',
			category: '',
		};
	},
	methods: {
		checkInput(e) {
			let answer = this.answer.replace(/\./g, '').replace(/e/g, '');
			let money = Number(answer);
			if (money < 0) this.answer = '0';
			else this.answer = answer;
		},
		updateAnswerPos(answerPos) {
			this.upperTitle = answerPos.upperTitle;
			this.lowerTitle = answerPos.lowerTitle;
			this.answerPos = answerPos.answers;
		},
		addQuestion() {
			if (this.show == 'oh') this.addOHQuestion();
			else if (this.show == 'wbm') this.addWBMQuestion();
			else if (this.show == 'dpin') this.addDPINQuestion();
			else if (this.show == 'zgw') this.addZGWQuestion();
			else if (this.show == 'smf') this.addSMFQuestion();
			else if (this.show == 'sq') this.addSQQuestion();
			else if (this.show == 'wlp') this.addWLPQuestion();
			else if (this.show == 'kq') this.addKQQuestion();
			else if (this.show == 'xfz') this.addXFZQuestion();
			else if (this.show == 'lz') this.addLZQuestion();
		},
		addOHQuestion() {
			if (this.question.length < 1) this.$global.showToast('warn', this.$t('qcNoQuestion'));
			else if (this.answerPos.length != 10) this.$global.showToast('warn', this.$t('qcOHQuestionAmount'));
			else
				this.sendQuestions([
					{
						question: this.question,
						answerPos: this.answerPos.map((ans) => {
							delete ans.isAnswer;
							return ans;
						}),
						upperTitle: this.upperTitle.length == 0 ? null : this.upperTitle,
						lowerTitle: this.lowerTitle.length == 0 ? null : this.lowerTitle,
						link: this.link ? (this.link.length == 0 ? null : this.link) : null,
						notes: this.notes ? (this.notes.length == 0 ? null : this.notes) : null,
					},
				]);
		},
		addWBMQuestion() {
			if (this.question.length < 1) this.$global.showToast('warn', this.$t('qcNoQuestion'));
			else if (this.answerPos.length < 2) this.$global.showToast('warn', this.$t('qcWBMQuestionAmount'));
			else
				this.sendQuestions([
					{
						question: this.question,
						answerPos: this.answerPos.map((ans) => {
							delete ans.isAnswer;
							return ans;
						}),
						link: this.link ? (this.link.length == 0 ? null : this.link) : null,
						notes: this.notes ? (this.notes.length == 0 ? null : this.notes) : null,
					},
				]);
		},
		addDPINQuestion() {
			if (this.question.length < 1) this.$global.showToast('warn', this.$t('qcNoQuestion'));
			else if (!this.firstImageValid) this.$global.showToast('warn', this.$t('qcImageNotValid'));
			else if (this.answer.length < 1) this.$global.showToast('warn', this.$t('qcNoAnswer'));
			else
				this.sendQuestions([
					{
						question: this.question,
						image: this.firstImage,
						imageOld: this.editedQuestion ? this.editedQuestion.path : null,
						answer: this.answer,
						link: this.link ? (this.link.length == 0 ? null : this.link) : null,
						notes: this.notes ? (this.notes.length == 0 ? null : this.notes) : null,
					},
				]);
		},
		addZGWQuestion() {
			if (this.question.length < 1) this.$global.showToast('warn', this.$t('qcNoQuestion'));
			else {
				this.sendQuestions([
					{
						question: this.question,
						notes: this.notes ? (this.notes.length == 0 ? null : this.notes) : null,
					},
				]);
			}
		},
		addSMFQuestion() {
			if (this.type == '') this.$global.showToast('warn', this.$t('qcNoQuestionType'));
			else if (this.question.length < 1) this.$global.showToast('warn', this.$t('qcNoQuestion'));
			else if (!this.firstImageValid) this.$global.showToast('warn', this.$t('qcImageNotValid'));
			else if (['Freetext', 'Buzzer'].includes(this.type)) {
				if (!this.imageIsAnswer && !this.hasTwoImages && this.answer.length < 1) this.$global.showToast('warn', this.$t('qcNoAnswer'));
				else if (!this.imageIsAnswer && this.hasTwoImages && !this.secondImageValid)
					this.$global.showToast('warn', this.$t('qcImageNotValid'));
				else {
					if (!this.hasTwoImages) this.secondImage = null;
					this.sendQuestions([
						{
							question: this.question,
							image: this.firstImage,
							imageOld: this.editedQuestion ? this.editedQuestion.path : null,
							image2: this.secondImageValid ? this.secondImage : null,
							image2Old: this.editedQuestion ? this.editedQuestion.path2 : null,
							answerPos: !this.imageIsAnswer && !this.hasTwoImages ? [{ answer: this.answer }] : [],
							type: this.type,
							imageIsAnswer: this.imageIsAnswer,
							hasTwoImages: this.hasTwoImages,
							link: this.link ? (this.link.length == 0 ? null : this.link) : null,
							notes: this.notes ? (this.notes.length == 0 ? null : this.notes) : null,
						},
					]);
				}
			} else if (['SingleChoice', 'BuzzerSingleChoice'].includes(this.type)) {
				if (this.hasTwoImages && !this.secondImageValid) this.$global.showToast('warn', this.$t('qcImageNotValid'));
				else if (this.answerPos.length < 2) this.$global.showToast('warn', this.$t('qcSMFQuestionAmount'));
				else if (this.answerPos.filter((ans) => ans.isAnswer).length !== 1) this.$global.showToast('warn', this.$t('qcExactlyOneAnswer'));
				else {
					this.sendQuestions([
						{
							question: this.question,
							image: this.firstImage,
							imageOld: this.editedQuestion ? this.editedQuestion.path : null,
							image2: this.secondImageValid ? this.secondImage : null,
							image2Old: this.editedQuestion ? this.editedQuestion.path2 : null,
							answerPos: this.answerPos.map((ans) => {
								delete ans.info;
								return ans;
							}),
							type: this.type,
							imageIsAnswer: false,
							hasTwoImages: this.hasTwoImages,
							link: this.link ? (this.link.length == 0 ? null : this.link) : null,
							notes: this.notes ? (this.notes.length == 0 ? null : this.notes) : null,
						},
					]);
				}
			}
		},
		addSQQuestion() {
			if (this.question.length < 1) this.$global.showToast('warn', this.$t('qcNoQuestion'));
			else if (this.answer.length < 1) this.$global.showToast('warn', this.$t('qcNoAnswer'));
			else {
				this.sendQuestions([
					{
						question: this.question,
						answer: this.answer,
						link: this.link ? (this.link.length == 0 ? null : this.link) : null,
						notes: this.notes ? (this.notes.length == 0 ? null : this.notes) : null,
					},
				]);
			}
		},
		addWLPQuestion() {
			if (this.question.length < 1) this.$global.showToast('warn', this.$t('qcNoQuestion'));
			else if (!this.firstImageValid) this.$global.showToast('warn', this.$t('qcImageNotValid'));
			else if (this.answer.length < 1) this.$global.showToast('warn', this.$t('qcNoAnswer'));
			else if (this.answer == '0') this.$global.showToast('warn', this.$t('qcNoAnswer'));
			else {
				this.sendQuestions([
					{
						question: this.question,
						image: this.firstImage,
						imageOld: this.editedQuestion ? this.editedQuestion.path : null,
						answer: this.answer,
						link: this.link ? (this.link.length == 0 ? null : this.link) : null,
						notes: this.notes ? (this.notes.length == 0 ? null : this.notes) : null,
					},
				]);
			}
		},
		addKQQuestion() {
			if (this.category.length < 1) this.$global.showToast('warn', this.$t('qcNoCategory'));
			else if (this.answerPos.length < 3) this.$global.showToast('warn', this.$t('qcKQQuestionAmountMin'));
			else if (this.answerPos.length > 6) this.$global.showToast('warn', this.$t('qcKQQuestionAmountMax'));
			else {
				this.sendQuestions([
					{
						category: this.category,
						questions: this.answerPos,
						notes: this.notes ? (this.notes.length == 0 ? null : this.notes) : null,
					},
				]);
			}
		},
		addXFZQuestion() {
			if (this.type == '') this.$global.showToast('warn', this.$t('qcNoQuestionType'));
			else if (this.question.length < 1) this.$global.showToast('warn', this.$t('qcNoQuestion'));
			else if (['Freetext', 'Buzzer'].includes(this.type)) {
				if (this.answer.length < 1) this.$global.showToast('warn', this.$t('qcNoAnswer'));
				else {
					this.sendQuestions([
						{
							question: this.question,
							answerPos: [{ answer: this.answer }],
							type: this.type,
							link: this.link ? (this.link ? (this.link.length == 0 ? null : this.link) : null) : null,
							notes: this.notes ? (this.notes.length == 0 ? null : this.notes) : null,
						},
					]);
				}
			} else if (['SingleChoice', 'BuzzerSingleChoice'].includes(this.type)) {
				if (this.answerPos.length < 2) this.$global.showToast('warn', this.$t('qcXFZQuestionAmount'));
				else if (this.answerPos.filter((ans) => ans.isAnswer).length !== 1) this.$global.showToast('warn', this.$t('qcExactlyOneAnswer'));
				else {
					this.sendQuestions([
						{
							question: this.question,
							answerPos: this.answerPos.map((ans) => {
								delete ans.info;
								return ans;
							}),
							type: this.type,
							link: this.link ? (this.link.length == 0 ? null : this.link) : null,
							notes: this.notes ? (this.notes.length == 0 ? null : this.notes) : null,
						},
					]);
				}
			}
		},
		addLZQuestion() {
			if (this.question.length < 1) this.$global.showToast('warn', this.$t('qcNoQuestion'));
			else if (this.lists.length < 2) this.$global.showToast('warn', this.$t('qcLZMinCategories'));
			else {
				let categoriesWithoutTitle = this.lists.filter((cat) => cat.category == '');
				let categoriesWithoutAnswers = this.lists.filter((cat) => cat.answerPos.length == 0);
				let sumOfAllAnswers = this.baits.length;
				this.lists.forEach((cat) => (sumOfAllAnswers += cat.answerPos.length));

				if (categoriesWithoutTitle.length > 0) this.$global.showToast('warn', this.$t('qcLZNoTitle'));
				else if (categoriesWithoutAnswers.length > 0) this.$global.showToast('warn', this.$t('qcLZNoAnswers'));
				else if (this.baits.length < 1) this.$global.showToast('warn', this.$t('qcLZMinBait'));
				else if (sumOfAllAnswers < this.lists.length * 2) this.$global.showToast('warn', this.$t('qcLZMinAnswers'));
				else {
					let answerPos = [];
					this.baits.forEach((bait) => answerPos.push(bait));
					this.lists.forEach((cat) => cat.answerPos.forEach((ans) => answerPos.push(ans)));
					this.sendQuestions([
						{
							question: this.question,
							answerPos: answerPos,
							categories: [
								...this.lists.map((cat) => {
									return {
										category: cat.category,
										link: cat.link,
									};
								}),
							],
							notes: this.notes ? (this.notes.length == 0 ? null : this.notes) : null,
						},
					]);
				}
			}
		},
		sendQuestions(questions) {
			if (this.getsEdited) this.patchQuestion(questions);
			else this.postQuestions(questions);
		},
		postQuestions(questions) {
			let that = this;
			this.$global.postData(
				'quiz',
				'/addQuestions',
				{
					questions: questions,
					show: this.show,
					uID: this.$global.getUser().uID,
				},
				{
					headers: { pageauthheader: this.$global.getAccessCode() },
					auth: this.$global.getCredentials(),
				},
				(err, data) => {
					if (err) that.$global.showToast('error', that.$t(err.response ? err.response.data.msg : 'qcAddError'));
					else {
						that.resetCreation();
						that.$emit('questionsAdded');
						that.$global.showToast('success', that.$t('qcQuestionsAdded'));
					}
				}
			);
		},
		patchQuestion(question) {
			let that = this;
			this.$global.patchData(
				'quiz',
				'/updateQuestion',
				{
					question: question[0],
					show: this.show,
					qID: this.editedQuestion.qID,
					uID: this.$global.getUser().uID,
				},
				{
					headers: { pageauthheader: this.$global.getAccessCode() },
					auth: this.$global.getCredentials(),
				},
				(err, data) => {
					if (err) that.$global.showToast('error', that.$t(err.response ? err.response.data.msg : 'qcUpdateError'));
					else {
						that.resetCreation();
						that.$emit('questionsAdded');
						that.$global.showToast('success', that.$t('qcQuestionUpdated'));
					}
				}
			);
		},
		discardChanges() {
			let that = this;
			this.$global.showPopup(
				this.$t('qcDiscardChanges'),
				this.$t('qcDiscardChangesText'),
				'warning',
				{ text: this.$t('qcCancel'), color: 'var(--main-color-4)' },
				{ text: this.$t('qcDiscard'), color: 'var(--main-color-error)' },
				null,
				function (result) {
					if (result.isConfirmed) {
						that.resetCreation();
						that.$emit('discardChanges');
					}
				}
			);
		},
		resetCreation() {
			this.resetToggle = !this.resetToggle;
			this.question = '';
			this.answerPos = [];
			this.answer = '';
			this.upperTitle = '';
			this.lowerTitle = '';
			this.link = '';
			this.notes = '';
			this.firstImage = '';
			this.firstImageLink = '';
			this.firstImageValid = false;
			this.secondImage = '';
			this.secondImageLink = '';
			this.secondImageValid = false;
			this.hasTwoImages = false;
			this.imageIsAnswer = false;
			this.type = '';
			this.lists = [];
			this.baits = [];
			this.category = '';
			this.initialAnswers = [];
			this.initialCategories = [];
			this.initialUpperTitle = '';
			this.initialLowerTitle = '';
		},
		setQuestion() {
			// Type
			if (['smf', 'xfz'].includes(this.show)) {
				this.type = this.editedQuestion.type;
			}

			this.$nextTick(() => {
				// Question
				if (['oh', 'wbm', 'dpin', 'zgw', 'smf', 'sq', 'wlp', 'xfz', 'lz'].includes(this.show)) {
					this.question = this.editedQuestion.question;
				}

				// Category
				if (['kq'].includes(this.show)) {
					this.category = this.editedQuestion.category;
				}

				// Answer pos
				if (['kq'].includes(this.show)) {
					this.initialAnswers = this.editedQuestion.questions;
					this.answerPos = this.editedQuestion.questions;
				}

				// Answer pos
				if (
					['oh', 'wbm'].includes(this.show) ||
					(this.show == 'smf' && ['SingleChoice', 'BuzzerSingleChoice'].includes(this.editedQuestion.type)) ||
					(this.show == 'xfz' && ['SingleChoice', 'BuzzerSingleChoice'].includes(this.editedQuestion.type))
				) {
					this.answerPos = this.editedQuestion.answerPos;
					this.initialAnswers = this.editedQuestion.answerPos;
				}

				// Categories, answerPos and baits
				if (this.show == 'lz') {
					this.initialAnswers = this.editedQuestion.answerPos;
					this.initialCategories = this.editedQuestion.categories;
					this.answerPos = this.editedQuestion.answerPos.filter((ans) => ans.category !== 'BAIT');
					this.baits = this.editedQuestion.answerPos.filter((ans) => ans.category === 'BAIT');
				}

				// Answer
				if (['dpin', 'sq', 'wlp'].includes(this.show)) {
					this.answer = this.editedQuestion.answer;
				}

				// Answer
				// prettier-ignore
				if(
					(this.show == 'smf' && ['Freetext', 'Buzzer'].includes(this.editedQuestion.type) && !this.editedQuestion.imageIsAnswer && !this.editedQuestion.hasTwoImages) ||
					(this.show == 'xfz' && ['Freetext', 'Buzzer'].includes(this.editedQuestion.type))){
						this.answer = this.editedQuestion.answerPos[0].answer;
				}

				if (this.show == 'oh') {
					this.upperTitle = this.editedQuestion.upperTitle;
					this.initialUpperTitle = this.editedQuestion.upperTitle;
					this.lowerTitle = this.editedQuestion.lowerTitle;
					this.initialLowerTitle = this.editedQuestion.lowerTitle;
				}

				// Link
				if (['oh', 'wbm', 'dpin', 'smf', 'sq', 'wlp', 'xfz'].includes(this.show)) {
					this.link = this.editedQuestion.link;
				}

				// First image
				if (['dpin', 'smf', 'wlp'].includes(this.show)) {
					this.firstImage = this.editedQuestion.image;
					this.firstImageLink = '';
					this.firstImageValid = true;
				}

				// Image is answer
				if (['smf'].includes(this.show) && this.editedQuestion.imageIsAnswer) {
					this.imageIsAnswer = true;
				}

				// Second image and has two images
				if (['smf'].includes(this.show) && this.editedQuestion.hasTwoImages) {
					this.secondImage = this.editedQuestion.image2;
					this.secondImageLink = '';
					this.secondImageValid = true;
					this.hasTwoImages = true;
				}

				this.notes = this.editedQuestion.notes;
			});
		},
	},
};
</script>

<style scoped>
.qc-wrap-inner-content {
	width: 100%;
	height: 100%;
	padding: 40px;
	box-sizing: border-box;
	overflow-x: hidden;
	overflow-y: auto;
}

.qc-wrap-input {
	width: 50%;
	height: fit-content;
	text-align: start;
	margin: auto;
	position: relative;
	display: inline-block;
	vertical-align: top;
}

.qc-wrap-input label {
	font-size: 20px;
	margin: 10px auto;
}

.qc-wrap-input input[type='text'],
.qc-wrap-input input[type='number'],
.qc-wrap-input select {
	width: 90%;
	height: 30px;
	padding: 5px !important;
	display: block;
	margin: 10px 0px;
	font-size: 20px;
}

.qc-center {
	display: block;
	margin: auto;
}

.qc-wrap-input select {
	box-sizing: content-box;
	padding: 5px !important;
}

.qc-wrap-input input[type='checkbox'] {
	width: 30px;
	height: 30px;
	padding: 5px !important;
	display: block;
	margin: 10px 0px;
	font-size: 20px;
	cursor: pointer;
}

button {
	font-size: 20px;
	width: 300px;
	height: 40px;
	margin: 10px auto;
	display: block;
}

.qc-wrap-image {
	width: 50%;
	height: 400px;
	margin-bottom: 10px;
	display: inline-block;
}

.qc-wrap-image .qc-wrap-input {
	width: 100%;
	height: 90px;
	margin-bottom: 10px;
}

.qc-wrap-image-upload {
	width: 90%;
	height: 300px;
}

.qc-wrap-textarea {
	width: 100%;
	height: fit-content;
	text-align: start;
	margin: auto;
	position: relative;
	display: inline-block;
	vertical-align: top;
}

.qc-wrap-textarea label {
	font-size: 20px;
	margin: 10px auto;
}

.qc-wrap-textarea textarea {
	width: 100%;
	min-height: 100px;
	max-height: 300px;
	display: block;
	margin: 10px 0px;
	font-size: 20px;
	box-sizing: border-box;
	resize: vertical;
}

.qc-wrap-bottom {
	width: 100%;
	display: flex;
	flex-flow: row;
	justify-content: center;
	align-items: center;
}

.qc-card {
	flex: 1 1 50%;
	height: 10vh;
	min-height: 100px;
	margin: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: transform 0.5s;
	border: 2px solid var(--main-color-border-dark);
}

.qc-card:hover {
	cursor: pointer;
	transform: scale(1.1);
	z-index: 5;
}

.qc-card div {
	text-align: center;
}

.qc-card svg {
	margin: 5px;
	font-size: 45px;
	color: var(--main-color-text-light);
	filter: drop-shadow(2px 2px 2px var(--main-color-border-dark));
}

.qc-card p {
	font-size: 20px;
	text-shadow: 1px 1px var(--main-color-text-dark);
}

.qc-add-question-card {
	background-color: var(--main-color-success-cc);
}

.qc-discard-card {
	background-color: var(--main-color-error-cc);
}
</style>
