<template>
	<div :class="[!userCanJoin() ? 'sp-hide-join' : rejoin ? 'sp-rejoin' : '', 'sp-wrap-content']">
		<div class="sp-wrap-icons">
			<p>
				<fai
					v-if="user && session.uID == user.uID"
					icon="fas fa-trash"
					class="sp-delete-session"
					:title="$t('spDeleteSession')"
					@click="$emit('deleteSession', session.sID)"
				/>
				<fai
					v-if="user && session.activePlayers.includes(user.uID)"
					icon="fas fa-door-open"
					class="sp-leave-session"
					:title="$t('spLeaveSession')"
					@click="$emit('leaveSession', session.sID, $global.getUser().uID)"
				/>
			</p>
			<p>
				<fai v-if="session.password" icon="fas fa-lock" :title="$t('spPassword')" />
				<fai v-else icon="fas fa-lock-open" :title="$t('spNoPassword')" />
			</p>
			<p :title="$t('spUserInSession')">{{ session.activePlayers.length }}/{{ session.amountPlayers }}<fai icon="fas fa-user" /></p>
		</div>
		<p class="sp-name">{{ session.name }}</p>
		<div class="sp-information">
			<p>
				{{ session.quizNight }}
				<fai icon="fas fa-external-link-alt" @click="toQuizNight" />
				({{ $t('spEstimated') }} {{ session.estimatedDuration }} {{ $t('spMin') }})
			</p>
			<div class="sp-wrap-shows-duration">
				<img
					v-for="show in session.shows"
					:key="show.abbreviation"
					:src="require(`@/assets/images/logos/${show.abbreviation.toUpperCase()}Logo.png`)"
					:alt="$t(`${show.name}`)"
					:title="$t(`${show.name}`)"
					class="sp-small-show-logo"
				/>
			</div>
		</div>
		<div class="sp-created">
			<p>{{ $global.parseDate(session.created, true, false) }}</p>
		</div>
		<div
			class="sp-join-session"
			:title="!userCanJoin() ? $t('spClosedSession') : $t('spJoinSession')"
			@click="user != null ? $emit('joinSession', session) : $emit('showLogin')"
		>
			<fai icon="fas fa-sign-in-alt" />
		</div>
	</div>
</template>

<script>
export default {
	name: 'SessionPreview',
	props: {
		session: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			rejoin: false,
			user: null,
		};
	},
	created() {
		this.user = this.$global.getUser();
	},
	methods: {
		userCanJoin() {
			if (this.user != null) {
				if (this.session.activePlayers.includes(this.user.uID) || this.session.uID == this.user.uID) {
					this.rejoin = true;
					return true;
				} else if (this.session.activePlayers.length == this.session.amountPlayers) {
					this.rejoin = false;
					return false;
				} else if (this.session.state == 'created') {
					this.rejoin = false;
					return true;
				} else {
					this.rejoin = false;
					return false;
				}
			} else if (this.session.activePlayers.length == this.session.amountPlayers) {
				this.rejoin = false;
				return false;
			} else if (this.session.state == 'created') {
				this.rejoin = false;
				return true;
			} else {
				this.rejoin = false;
				return false;
			}
		},
		getShowName(show) {
			if (show.name == 'xfzName') return `${this.$t(show.name).replace('X', show.amountQuestions)} "${show.topic}"`;
			else return this.$t(show.name);
		},
		toQuizNight() {
			if (this.user != null) this.$router.push({ name: 'QuizNightConfigurationOverview', query: { qID: this.session.qID } });
			else this.$emit('showLogin');
		},
	},
};
</script>

<style scoped>
.sp-wrap-content {
	width: 100%;
	height: fit-content;
	margin: 2px 0px 18px 0px;
	padding: 5px;
	padding-right: 50px;
	box-sizing: border-box;
	position: relative;
	background-color: var(--main-color-5);
	box-shadow: 2px 2px 3px 3px var(--main-color-border-dark);
}

.sp-hide-join {
	background-color: var(--main-color-2);
}

.sp-hide-join p,
.sp-hide-join svg {
	color: var(--main-color-text-dark);
}

.sp-hide-join .sp-join-session {
	background: var(--main-color-5);
}

.sp-hide-join .sp-join-session:hover {
	cursor: default;
}

.sp-hide-join .sp-join-session:active {
	pointer-events: none;
}

.sp-hide-join .sp-join-session svg {
	color: var(--main-color-2);
}

.sp-hide-join .sp-delete-session {
	color: var(--main-color-error);
}

.sp-rejoin .sp-join-session {
	background: linear-gradient(to left, var(--secondary-color-success) 50%, var(--main-color-success) 50%) right;
	background-size: 200% 100%;
	transition: 0.2s ease-in;
}

.sp-wrap-icons {
	float: right;
}

.sp-wrap-icons p {
	display: inline-block;
	font-size: 17px;
}

.sp-wrap-icons p svg {
	margin: 0px 5px;
	filter: drop-shadow(2px 2px 2px var(--main-color-border-dark));
}

.sp-name {
	font-size: 20px;
	text-decoration: underline;
	text-decoration-color: var(--main-color-6);
	margin-bottom: 5px;
}

.sp-join-session {
	width: 40px;
	height: 100%;
	position: absolute;
	top: 0px;
	right: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: linear-gradient(to left, var(--main-color-3) 50%, var(--main-color-6) 50%) right;
	background-size: 200% 100%;
	transition: 0.2s ease-in;
}

.sp-join-session:hover {
	background-position: left;
	cursor: pointer;
}

.sp-join-session svg {
	font-size: 30px;
	color: var(--main-color-text-light);
	filter: drop-shadow(2px 2px 2px var(--main-color-border-dark));
}

.sp-information {
	font-size: 13px;
	margin-top: 10px;
}

.sp-information svg:hover {
	cursor: pointer;
	color: var(--main-color-6);
}

.sp-wrap-shows-duration {
	width: 100%;
	height: fit-content;
	margin-top: 10px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.sp-small-show-logo {
	width: 30px;
	height: 30px;
	margin-right: 10px;
	display: inline-block;
	border-radius: 10px;
}

.sp-created {
	width: 100%;
	margin-top: 5px;
	font-size: 13px;
	text-align: end;
	color: var(--main-color-text-light);
}

.sp-delete-session,
.sp-leave-session {
	color: var(--main-color-error);
}

.sp-delete-session:hover,
.sp-leave-session:hover {
	color: var(--secondary-color-error);
	cursor: pointer;
}
</style>
