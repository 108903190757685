<template>
	<div class="se-wrap-content">
		<h1>{{ $t('seSettings') }}</h1>
		<div class="se-wrap-left">
			<div class="se-wrap-input">
				<label>{{ $t('seUsername') }}</label>
				<input type="text" autocomplete="on" :placeholder="$t('seUsername')" v-model.trim="updatedUser.name" />
			</div>
			<div class="se-wrap-input">
				<label>{{ $t('seCurrentEmail') }}: </label>
				<p>{{ this.user.email }}</p>
			</div>
			<button class="app-default-btn" @click="handleClick('emailChange', true)">{{ $t('seChangeEmail') }}</button>
			<button class="app-default-btn" @click="handleClick('passwordChange', true)">{{ $t('seChangePassword') }}</button>
			<button class="app-warn-btn" @click="handleClick('clearCookies', true)">{{ $t('seClearCookies') }}</button>
			<button class="app-error-btn" @click="handleClick('deleteAccount', true)">{{ $t('seDeleteAccount') }}</button>
			<!-- <div class="se-wrap-switch">
				<div class="se-wrap-theme">
					<p>{{ $t('seDarkTheme') }}</p>
					<label class="se-switch">
						<input type="checkbox" v-model="darkTheme" @change="$emit('setTheme', darkTheme ? 'dark' : 'light')" />
						<span class="se-slider"></span>
					</label>
				</div>
			</div> -->
		</div>
		<transition name="zoom">
			<div v-if="showEmailChange" class="se-wrap-change-div">
				<div class="se-change-div">
					<h2>{{ $t('seChangeEmailTitle') }}</h2>
					<div class="se-wrap-input">
						<label>{{ $t('seEmail') }}</label>
						<input type="email" autocomplete="on" :placeholder="$t('seEmail')" v-model="newEmail" />
					</div>
					<button class="app-success-btn" @click="changeEmail">{{ $t('seChangeEmail') }}</button>
					<fai icon="fas fa-times-circle" class="se-close-dialog" @click="handleClick('emailChange', false)"></fai>
				</div>
			</div>
		</transition>
		<transition name="zoom">
			<div v-if="showPasswordChange" class="se-wrap-change-div">
				<div class="se-change-div">
					<h2>{{ $t('seChangePasswordTitle') }}</h2>
					<div class="se-wrap-input">
						<label>{{ $t('seNewPassword') }}</label>
						<input
							:type="[showPassword ? 'text' : 'password']"
							autocomplete="on"
							:placeholder="$t('seNewPassword')"
							v-model="newPassword"
						/>
						<fai v-if="!showPassword" icon="fas fa-eye" class="se-smaller-eye" @mouseenter="showPassword = true"></fai>
						<fai v-if="showPassword" icon="fas fa-eye-slash" @mouseleave="showPassword = false"></fai>
					</div>
					<div class="se-wrap-input">
						<label>{{ $t('seNewPasswordRepeat') }}</label>
						<input
							:type="[showPasswordRepeat ? 'text' : 'password']"
							autocomplete="on"
							:placeholder="$t('seNewPasswordRepeat')"
							v-model="newPasswordRepeat"
						/>
						<fai v-if="!showPasswordRepeat" icon="fas fa-eye" class="se-smaller-eye" @mouseenter="showPasswordRepeat = true"></fai>
						<fai v-if="showPasswordRepeat" icon="fas fa-eye-slash" @mouseleave="showPasswordRepeat = false"></fai>
					</div>
					<button class="app-success-btn" @click="changePassword">{{ $t('seChangePassword') }}</button>
					<fai icon="fas fa-times-circle" class="se-close-dialog" @click="handleClick('passwordChange', false)"></fai>
				</div>
			</div>
		</transition>
		<div class="se-wrap-right">
			<ProfilePictureSelection :profilePic="updatedUser.profilePic" @selectedImage="updatedUser.profilePic = $event.src" />
		</div>
		<button class="app-success-btn se-save-btn" :disabled="!changes" @click="saveChanges">{{ $t('seSaveChanges') }}</button>
	</div>
</template>

<script>
import ProfilePictureSelection from '../components/login/ProfilePictureSelection.vue';
export default {
	name: 'Settings',
	components: {
		ProfilePictureSelection,
	},
	watch: {
		updatedUser: {
			handler: function (newVal) {
				if (JSON.stringify(newVal) !== JSON.stringify(this.user)) this.changes = true;
				else this.changes = false;
			},
			deep: true,
		},
	},
	data() {
		return {
			darkTheme: true,
			showPassword: false,
			user: null,
			updatedUser: null,
			changes: false,
			showEmailChange: false,
			showPasswordChange: false,
			newEmail: null,
			showPassword: false,
			showPasswordRepeat: false,
			newPassword: null,
			newPasswordRepeat: null,
		};
	},
	created() {
		this.$global.checkAccess(this.$router, true);
		this.user = this.$global.getUser();
		this.updatedUser = this.$global.getUser();
		this.theme = localStorage.getItem('theme')
			? localStorage.getItem('theme')
			: sessionStorage.getItem('theme')
			? sessionStorage.getItem('theme')
			: 'dark';
		if (this.theme !== 'dark') this.darkTheme = false;
	},
	methods: {
		handleClick(type, show) {
			if (show) {
				let elem = document.querySelector('.se-wrap-content');
				elem.scrollTo(0, 0);
				elem.style.overflow = 'hidden';
			} else {
				let elem = document.querySelector('.se-wrap-content');
				elem.style.overflow = 'auto';
			}
			switch (type) {
				case 'emailChange':
					this.showEmailChange = show;
					break;
				case 'passwordChange':
					this.showPasswordChange = show;
					break;
				case 'clearCookies':
					if (show) this.clearCookies();
					break;
				case 'deleteAccount':
					if (show) this.deleteAccount();
					break;
			}
		},
		saveChanges() {
			if (this.updatedUser.name.length < 2 || this.updatedUser.name.length > 25)
				this.$global.showToast('error', this.$t('seUsernameLengthError'));
			else {
				this.$global.patchData(
					'user',
					'/updateUser',
					{
						user: this.updatedUser,
						nameUpdated: this.updatedUser.name !== this.user.name ? true : false,
					},
					{
						headers: { pageauthheader: this.$global.getAccessCode() },
						auth: this.$global.getCredentials(),
					},
					(err, data) => {
						if (err) this.$global.showToast('error', this.$t(err.response ? err.response.data.msg : 'seUpdateError'));
						else {
							this.user = JSON.parse(JSON.stringify(this.updatedUser));
							sessionStorage.setItem('user', JSON.stringify(this.user));
							// let credentials = this.$global.getCredentials();
							// credentials.username = this.user.name;
							// sessionStorage.setItem('credentials', JSON.stringify(credentials));
							this.changes = false;
							this.$global.showToast('success', this.$t('seUserUpdated'));
						}
					}
				);
			}
		},
		changeEmail() {
			this.newEmail = this.newEmail.toLowerCase();
			if (!this.$global.validEmail(this.newEmail)) this.$global.showToast('error', this.$t('seValidEmailError'));
			else {
				this.$global.patchData(
					'user',
					'/initiateEmailChange',
					{ uID: this.user.uID, email: this.newEmail },
					{
						headers: { pageauthheader: this.$global.getAccessCode() },
						auth: this.$global.getCredentials(),
					},
					(err, data) => {
						if (err) this.$global.showToast('error', this.$t(err.response ? err.response.data.msg : 'seMailError'));
						else {
							this.$global.showToast('success', this.$t('seEmailChangeInitiated'));
							this.showEmailChange = false;
							this.newEmail = null;
						}
					}
				);
			}
		},
		changePassword() {
			if (!this.newPassword || !this.newPasswordRepeat) this.$global.showToast('error', this.$t('seFillAllFields'));
			else if (this.newPassword.length < 9) this.$global.showToast('error', this.$t('sePasswordLength'));
			else if (this.newPassword !== this.newPasswordRepeat) this.$global.showToast('error', this.$t('seNoMatchingPasswords'));
			else {
				this.$global.patchData(
					'user',
					'/changePassword',
					{ uID: this.user.uID, password: this.newPassword },
					{
						headers: { pageauthheader: this.$global.getAccessCode() },
						auth: this.$global.getCredentials(),
					},
					(err, data) => {
						if (err) this.$global.showToast('error', this.$t(err.response ? err.response.data.msg : 'seUpdateError'));
						else {
							this.showPasswordChange = false;
							this.newPassword = null;
							this.newPasswordRepeat = null;
							let credentials = this.$global.getCredentials();
							credentials.password = data;

							sessionStorage.setItem('credentials', JSON.stringify(credentials));
							localStorage.setItem('credentials', JSON.stringify(credentials));

							this.$global.showToast('success', this.$t('sePasswordChanged'));
						}
					}
				);
			}
		},
		clearCookies() {
			let that = this;
			this.$global.showPopup(
				this.$t('seDeleteCookies'),
				this.$t('seDeleteCookiesText'),
				'warning',
				{ text: this.$t('seCancel'), color: 'var(--main-color-4)' },
				{ text: this.$t('seDelete'), color: 'var(--main-color-error)' },
				null,
				function (result) {
					if (result.isConfirmed) {
						if (localStorage.length > 0) {
							// Theme
							sessionStorage.setItem(
								'theme',
								localStorage.getItem('theme')
									? localStorage.getItem('theme')
									: sessionStorage.getItem('theme')
									? sessionStorage.getItem('theme')
									: 'dark'
							);
							// AccessCode
							sessionStorage.setItem('accessCode', that.$global.getAccessCode());
							localStorage.clear();
						}
					}
					that.handleClick('clearCookies', false);
				}
			);
		},
		deleteAccount() {
			let that = this;
			this.$global.showPopup(
				this.$t('seDeleteAccount'),
				this.$t('seDeleteAccountText'),
				'warning',
				{ text: this.$t('seCancel'), color: 'var(--main-color-4)' },
				{ text: this.$t('seDelete'), color: 'var(--main-color-error)' },
				null,
				function (result) {
					if (result.isConfirmed) {
						that.$global.deleteData(
							'user',
							'/deleteAccount',
							{
								headers: {
									pageauthheader: that.$global.getAccessCode(),
								},
								auth: that.$global.getCredentials(),
								params: { user: that.user },
							},
							null,
							async (err, data) => {
								if (err) that.$global.showToast('error', that.$t(err.response ? err.response.data.msg : 'seDeleteAccountError'));
								else {
									that.$global.showToast('success', that.$t('seDeletedAccount'));
									that.$global.logoutUser();

									await that.$router.push({ name: 'Home' });
									location.reload();
								}
							}
						);
					}
					that.handleClick('deleteAccount', false);
				}
			);
		},
	},
};
</script>

<style scoped>
.se-wrap-content {
	max-width: 100vw;
	height: 100%;
	text-align: center;
	position: relative;
	margin: auto;
	overflow-y: auto;
	overflow-x: hidden;
}

.se-wrap-left {
	width: calc(100vw - 767px);
}

.se-wrap-right {
	width: 750px;
}

.se-wrap-right,
.se-wrap-left {
	height: fit-content;
	vertical-align: top;
	padding: 1vh 2vw;
	box-sizing: border-box;
	display: inline-block;
	text-align: start;
}

.se-wrap-input {
	width: 100%;
	height: fit-content;
	text-align: start;
	margin: auto;
	position: relative;
}

.se-wrap-input label {
	font-size: 25px;
	margin: 20px auto;
}

.se-wrap-input input {
	width: 100%;
	/* max-width: 600px; */
	height: 50px;
	padding: 5px;
	display: block;
	box-sizing: border-box;
	margin: 20px 0px;
	font-size: 30px;
}

.se-wrap-input p {
	font-size: 25px;
	margin: 0px;
	vertical-align: top;
	display: inline-block;
	line-height: 35px;
}

.se-wrap-input svg {
	font-size: 30px;
	color: var(--main-color-text-dark);
	position: absolute;
	bottom: 12px;
	right: 3px;
}

.se-smaller-eye {
	right: 5px !important;
}

button {
	width: 100%;
	height: 50px;
	font-size: 30px;
	margin: 20px 0px;
}

.se-wrap-switch {
	text-align: start;
}

.se-wrap-theme {
	height: fit-content;
	position: relative;
	display: inline-flex;
	justify-content: center;
	align-items: center;
}

.se-wrap-theme p {
	font-size: 30px;
	margin: 20px;
}

.se-switch {
	position: relative;
	width: 90px;
	height: 50px;
}

.se-switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

.se-slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: var(--main-color-2);
	-webkit-transition: 0.4s;
	transition: 0.4s;
	border-radius: 50px;
	border: 3px solid var(--main-color-border-dark);
	box-sizing: border-box;
}

.se-slider:before {
	position: absolute;
	content: '';
	height: 38px;
	width: 38px;
	left: 3px;
	bottom: 3px;
	background-color: var(--main-color-1);
	-webkit-transition: 0.4s;
	transition: 0.4s;
	border-radius: 50%;
	border: 3px solid var(--main-color-border-dark);
	box-sizing: border-box;
}

input:checked + .se-slider {
	background-color: var(--main-color-6);
}

input:focus + .se-slider {
	box-shadow: 0 0 1px var(--main-color-6);
}

input:checked + .se-slider:before {
	-webkit-transform: translateX(40px);
	-ms-transform: translateX(40px);
	transform: translateX(40px);
}

.se-wrap-change-div {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0px;
	left: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 2;
	background-color: var(--main-color-dark-transparent-80);
}

.se-wrap-change-div .se-change-div {
	height: fit-content;
	max-height: 90vh;
	width: fit-content;
	width: 800px;
	max-width: 90vw;
	padding: 20px;
	border-radius: 20px;
	box-sizing: border-box;
	position: relative;
	overflow: auto;
	z-index: 2;
	border: 2px solid var(--main-color-border-light);
	background-color: var(--main-color-dark-transparent-cc);
}

.se-change-div button {
	width: 300px;
	height: 40px;
	padding: 5px;
	font-size: 25px;
}

.se-change-div h2 {
	font-size: 35px;
	max-width: 80%;
	margin: 0px auto 2vh auto;
}

.zoom-enter-active,
.zoom-leave-active {
	transition: transform 0.3s ease;
	background-color: var(--main-color-dark-transparent);
}

.zoom-enter,
.zoom-leave-to {
	transform: scale(0);
}

.se-close-dialog {
	font-size: 3em;
	position: absolute;
	top: 1vh;
	right: 1vw;
	color: var(--main-color-error);
	filter: drop-shadow(2px 2px 2px var(--main-color-text-dark));
}

.se-close-dialog:hover {
	cursor: pointer;
	color: var(--secondary-color-error);
}

.se-change-div .se-wrap-input {
	width: 90%;
	height: fit-content;
	text-align: start;
	margin: auto;
	position: relative;
}

.se-change-div .se-wrap-input input {
	font-size: 20px;
}

.se-save-btn {
	max-width: 600px;
	margin: 20px auto;
	display: block;
}

@media screen and (min-width: 1450px) {
	.se-wrap-left {
		width: 700px;
	}

	.se-wrap-right {
		width: calc(100vw - 717px);
		max-width: 900px;
	}
}

@media screen and (max-width: 1150px) {
	.se-wrap-content h1 {
		font-size: 60px;
	}

	.se-wrap-left,
	.se-wrap-right {
		width: 90vw;
		max-width: 800px;
		margin: auto;
	}

	.se-wrap-input input {
		width: 90%;
	}

	.se-change-div .se-wrap-input input {
		width: 100%;
	}

	button {
		width: 90%;
	}
}
</style>
