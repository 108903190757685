<template>
	<div :id="`ue-${user.uID}`" class="ue-wrap-content">
		<div
			:class="[sessionRoom.inactive.includes(user.uID) ? 'ue-is-inactive' : '', 'ue-wrap-user']"
			:title="isHost ? $t('ueHost') : $t('uePlayer')"
		>
			<img
				:src="require(`@/assets/images/profilePictures/${user.profilePic}`)"
				:class="[isHost ? 'ue-is-host' : user.ready ? 'ue-is-ready' : '', 'ue-profile']"
			/>
			<div class="ue-hover-wrapper">
				<div v-if="!hideDisconnect" class="ue-disconnected">
					<fai icon="fas fa-unlink" />
				</div>
				<div v-if="$global.isHost() && $global.getUser().uID !== user.uID" class="ue-hover-div" @click="kickUser">
					<fai icon="fas fa-times" />
				</div>
			</div>
			<div class="ue-user-name">
				{{ user.name }}
			</div>
			<button
				v-if="user.uID == $global.getUser().uID && !isHost && !user.ready && !hideDisconnect"
				class="app-success-btn ue-btn"
				@click="$emit('playerReady', user.uID)"
			>
				{{ $t('ueReady') }}
			</button>
			<button
				v-else-if="user.uID == $global.getUser().uID && !isHost && user.ready && !hideDisconnect"
				class="app-warn-btn ue-btn"
				@click="$emit('playerNotReady', user.uID)"
			>
				{{ $t('ueNotReady') }}
			</button>
			<button
				v-else-if="user.uID == $global.getUser().uID && isHost && !sessionState && !hideDisconnect"
				:class="[!sessionReady ? 'app-warn-btn' : 'app-success-btn', 'ue-btn']"
				@click="[!sessionReady ? confirmStart('session') : startSession()]"
			>
				{{ $t('ueStart') }}
			</button>
			<button
				v-else-if="user.uID == $global.getUser().uID && isHost && sessionState && !hideDisconnect"
				:class="[!sessionReady ? 'app-warn-btn' : 'app-success-btn', 'ue-btn']"
				@click="[!sessionReady ? confirmStart('show') : startShow()]"
			>
				{{ $t('ueStart') }}
			</button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'UserElement',
	props: {
		user: {
			type: Object,
			required: true,
		},
		isHost: {
			type: Boolean,
			required: false,
		},
		hideDisconnect: {
			type: Boolean,
			required: false,
		},
		sessionReady: {
			type: Boolean,
			required: false,
		},
		sessionRoom: {
			type: Object,
			required: false,
		},
		sessionState: {
			type: Object,
			required: false,
		},
		configuredShows: {
			type: Array,
			required: false,
		},
	},
	data() {
		return {
			preventClick: false,
		};
	},
	// mounted() {
	// 	window.addEventListener('resize', this.resizeListener);
	// },
	// beforeDestroy() {
	// 	window.removeEventListener('resize', this.resizeListener);
	// },
	methods: {
		// resizeListener() {
		// 	let element = document.getElementById(`ue-${this.user.uID}`);
		// },
		kickUser() {
			let that = this;
			this.$global.showPopup(
				this.$t('ueKickPlayer'),
				this.$t('ueKickPlayerText'),
				'warning',
				{ text: this.$t('ueCancel'), color: 'var(--main-color-4)' },
				{ text: this.$t('ueKick'), color: 'var(--main-color-error)' },
				null,
				function (result) {
					if (result.isConfirmed) {
						that.$emit('removeUser', that.user.uID);
					}
				}
			);
		},
		confirmStart(type) {
			let that = this;
			let title = type == 'session' ? 'ueStartSessionUnready' : 'ueStartShowUnready';
			let text = type == 'session' ? 'ueStartSessionUnreadyText' : 'ueStartShowUnreadyText';
			this.$global.showPopup(
				this.$t(title),
				this.$t(text),
				'warning',
				{ text: this.$t('ueCancel'), color: 'var(--main-color-4)' },
				{ text: this.$t('ueStart'), color: 'var(--main-color-success)' },
				null,
				function (result) {
					if (result.isConfirmed) {
						if (type == 'session') that.startSession();
						else if (type == 'show') that.startShow();
					}
				}
			);
		},
		startSession() {
			if (!this.preventClick) {
				this.preventClick = true;
				let that = this;
				this.$global.setSessionState(this.$route.params.sID, this.$global.getUser().uID, null, 'lobby', function (err) {
					if (err) that.$global.showToast('error', that.$t(err.response ? err.response.data.msg : 'loSessionStartError'));
					else that.$emit('startSession');
				});
			}
		},
		startShow() {
			if (!this.preventClick) {
				this.preventClick = true;

				let that = this;
				let sID = this.$route.params.sID;
				let uID = this.$global.getUser().uID;
				let gID = this.configuredShows.filter((show) => show.show == this.sessionState.nextState)[0].gID;

				this.$global.setSessionState(sID, uID, gID, this.sessionState.nextState, function (err) {
					if (err) that.$global.showToast('error', that.$t(err.response ? err.response.data.msg : 'loShowStartError'));
					else {
						window.setTimeout(() => {
							that.$emit('startShow', that.sessionState.nextState);
						}, 1000);
					}
				});
			}
		},
	},
};
</script>

<style scoped>
.ue-wrap-content {
	height: fit-content;
	flex: 1 1 170px;
	display: flex;
	justify-content: center;
	text-align: center;
	padding-bottom: 10px;
}

.ue-wrap-user {
	width: 120px;
	height: fit-content;
	position: relative;
}

.ue-hover-div:hover {
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.ue-profile:hover + .ue-hover-wrapper > .ue-hover-div {
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.ue-hover-div,
.ue-disconnected {
	width: 120px;
	height: 120px;
	display: none;
	background-color: var(--main-color-light-transparent-80);
	position: absolute;
	top: 4px;
	left: 4px;
	border-radius: 50%;
}

.ue-disconnected {
	z-index: 2;
}

.ue-hover-div {
	z-index: 2;
}

.ue-disconnected svg,
.ue-hover-div svg {
	font-size: 50px;
	color: var(--main-color-error);
}

.ue-wrap-content img {
	width: 120px;
	height: 120px;
	border: 4px solid var(--main-color-border-dark);
	border-radius: 50%;
}

.ue-user-name {
	height: fit-content;
	margin-top: 5px;
	font-size: 20px;
	color: var(--main-color-text-light);
	display: flex;
	justify-content: center;
}

.ue-is-inactive .ue-disconnected {
	display: flex;
	justify-content: center;
	align-items: center;
}

.ue-is-host {
	border: 4px solid var(--main-color-6) !important;
}

.ue-is-ready {
	border: 4px solid var(--main-color-success) !important;
}

.ue-btn {
	width: 100%;
	height: fit-content;
	margin-top: 10px;
	font-size: 17px;
}
</style>
