<template>
	<div class="gscl-wrap-content">
		<h2 v-if="!state.lobby && !state.viewMode">{{ $t('gsclConfiguredShows') }}</h2>
		<div class="gscl-wrap-configs">
			<GameShowConfigPreview
				v-for="config in configs"
				:key="config.show"
				:config="config"
				:editedConfig="editedConfig"
				:shows="registeredShows"
				:state="state"
				@moveConfigUp="$emit('moveConfigUp', $event)"
				@moveConfigDown="$emit('moveConfigDown', $event)"
				@editConfig="$emit('editConfig', $event)"
				@deleteConfig="$emit('deleteConfig', $event)"
				@showQuestionPreview="showQuestionPreview"
			/>
			<p v-if="configs.length == 0 && !state.lobby">{{ $t('gscpNoConfigs') }}</p>
			<p v-else-if="configs.length == 0 && state.lobby">{{ $t('gscpNoMoreGameshows') }}</p>
		</div>
	</div>
</template>

<script>
import GameShowConfigPreview from './GameShowConfigPreview.vue';
export default {
	name: 'GameShowConfigList',
	components: {
		GameShowConfigPreview,
	},
	props: {
		registeredShows: {
			type: Array,
			required: true,
		},
		configuredShows: {
			type: Array,
			required: true,
		},
		state: {
			type: Object,
			required: true,
		},
		editedConfig: {
			type: Object,
			required: false,
		},
	},
	watch: {
		configuredShows: {
			handler: function (newVal) {
				this.configs = JSON.parse(JSON.stringify(newVal));
			},
			deep: true,
		},
	},
	emits: ['moveConfigUp', 'moveConfigDown', 'editConfig', 'deleteConfig'],
	data() {
		return {
			configs: [],
		};
	},
	created() {
		this.configs = JSON.parse(JSON.stringify(this.configuredShows));
	},
	methods: {
		showQuestionPreview() {
			console.log(this.configs);
		},
	},
};
</script>

<style scoped>
.gscl-wrap-content {
	width: 100%;
	height: fit-content;
	margin: 20px 0px;
}

h2 {
	width: fit-content;
	font-size: 40px;
	margin: 0px auto;
	padding: 5px 0px 20px 0px;
	text-shadow: 3px 3px var(--main-color-6);
	-webkit-text-stroke: 2px var(--main-color-text-dark);
	text-align: center;
}

.gscl-wrap-configs {
	display: flex;
	flex-flow: wrap;
}

.gscl-wrap-configs p {
	width: 100%;
	font-size: 20px;
	text-align: center;
}
</style>
