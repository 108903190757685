<template>
	<div class="ss-wrap-content">
		<div
			v-for="show in shows"
			:key="show.abbreviation"
			:id="`${ssID}show${show.abbreviation}`"
			:class="[noSelect ? 'ss-no-select' : '', 'ss-wrap-show']"
			@click="selectShow(show.abbreviation)"
		>
			<img
				:src="require(`@/assets/images/logos/${show.abbreviation.toUpperCase()}Logo.png`)"
				:alt="$t(`${show.name}`)"
				:title="$t(`${show.name}`)"
				class="ss-show-logo"
			/>
			<p>{{ $t(show.name) }}</p>
		</div>
	</div>
</template>

<script>
/**
 * @group ShowSelection
 * Show selection
 */
import * as uuid from 'uuid';
export default {
	name: 'ShowSelection',
	props: {
		shows: {
			type: Array,
			required: true,
		},
		selectedShow: {
			type: String,
			required: false,
		},
		noSelect: {
			type: Boolean,
			required: false,
		},
	},
	watch: {
		shows: {
			handler: function (newVal, oldVal) {
				if (this.selectedShow) this.colorShow(this.selectedShow);
			},
		},
		selectedShow: {
			handler: function (newVal, oldVal) {
				if (newVal) this.colorShow(newVal);
			},
		},
	},
	data() {
		return {
			ssID: uuid.v4(),
		};
	},
	mounted() {
		if (this.selectedShow) this.colorShow(this.selectedShow);
	},
	methods: {
		selectShow(show) {
			this.colorShow(show);
			this.$emit('selectShow', show);
		},
		colorShow(show) {
			this.$nextTick(() => {
				let elements = document.querySelectorAll('.ss-wrap-show');
				elements.forEach((element) => {
					if (element.id == `${this.ssID}show${show}`) element.classList.add('ss-wrap-selected-show');
					else if (element.id.includes(this.ssID)) element.classList.remove('ss-wrap-selected-show');
				});
			});
		},
	},
};
</script>

<style scoped>
.ss-wrap-content {
	width: 100%;
	max-height: 260px;
	margin: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow: wrap;
	overflow: auto;
}

.ss-no-select {
	cursor: default;
	user-select: none;
}

.ss-wrap-show {
	width: 130px;
	height: 110px;
	padding: 5px;
	margin: 5px;
	box-sizing: content-box;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow: wrap;
	border-radius: 20px;
	transition: transform 0.5s;
	background-color: var(--main-color-light-transparent-cc);
	user-select: none;
}

.ss-wrap-show p {
	font-size: 14px;
	color: var(--main-color-text-dark);
}

.ss-wrap-show:hover {
	cursor: pointer;
	transform: scale(1.08);
	z-index: 5;
	background-color: var(--main-color-6-cc);
	/* background-color: var(--main-color-dark); */
}

.ss-wrap-show:hover p {
	color: var(--main-color-text-dark);
}

.ss-wrap-selected-show {
	background-color: var(--main-color-6-cc);
	pointer-events: none;
}

.ss-wrap-selected-show:hover {
	cursor: default;
	background-color: var(--main-color-6-cc) !important;
	transform: none;
}

.ss-wrap-selected-show p {
	font-weight: bold;
	color: var(--main-color-text-dark);
}

.ss-show-logo {
	width: 80px;
	height: 80px;
	margin-bottom: 5px;
	display: inline-block;
	border-radius: 10px;
}
</style>
