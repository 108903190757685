<template>
	<div class="lcl-wrap-content">
		<h2>{{ $t('lclCategories') }}</h2>
		<div class="lcl-wrap-top">
			<div class="lcl-wrap-columns">
				<ListCheckLayoutColumn
					v-for="column in columns"
					:key="column.id"
					:columnID="column.id"
					:answerPos="column.answerPos"
					:initialLink="column.link"
					@adjustHeight="adjustHeight"
					@setCategory="setCategory"
					@setAnswers="setAnswers"
					@setLink="setLink"
					@deleteColumn="deleteColumn"
				/>
			</div>
			<fai v-if="columns.length < this.maxColumns" icon="fas fa-plus-circle" @click="addColumn"></fai>
		</div>
		<h2>{{ $t('lclBaits') }}</h2>
		<div class="lcl-wrap-bottom">
			<div class="lcl-wrap-baits">
				<div v-for="bait in baits" :key="bait.answer" class="lcl-wrap-bait">
					<div class="lcl-wrap-icons">
						<fai icon="fas fa-pen" class="lcl-edit" @click="editBait(bait)"></fai>
						<fai icon="fas fa-trash" class="lcl-delete" @click="deleteBait(bait)"></fai>
					</div>
					<p>{{ bait.answer }}</p>
				</div>
				<p v-if="baits.length == 0" class="lcl-no-baits">{{ $t('lclNoBaits') }}</p>
			</div>
			<div class="lcl-wrap-input">
				<label>{{ $t('lclBait') }}</label>
				<input type="text" :placeholder="$t('lclBait')" v-model="newBait" @keyup.enter="addBait" />
				<button class="app-default-btn" @click="addBait">{{ $t('lclAddBait') }}</button>
			</div>
		</div>
	</div>
</template>

<script>
import ListCheckLayoutColumn from './ListCheckLayoutColumn.vue';
export default {
	name: 'ListCheckLayout',
	components: {
		ListCheckLayoutColumn,
	},
	props: {
		initialAnswers: {
			type: Array,
			required: true,
		},
		initialCategories: {
			type: Array,
			required: true,
		},
	},
	watch: {
		initialAnswers: {
			handler: function (newVal) {
				if (this.initialUpdate) {
					this.initialUpdate = false;
					// Wait for categories to be set
					this.$nextTick(() => {
						this.columns.forEach((col) => {
							newVal.forEach((ans) => {
								if (ans.category == col.category) col.answerPos.push(ans);
							});
						});
						this.baits = newVal.filter((ans) => ans.category == 'BAIT');
					});
				}
			},
			deep: true,
		},
		initialCategories: {
			handler: function (newVal) {
				this.columns = [
					...newVal.map((cat, idx) => {
						return {
							id: idx,
							category: cat.category,
							answerPos: [],
							link: cat.link ? cat.link : '',
						};
					}),
				];
			},
			deep: true,
		},
		columns: {
			handler: function (newVal) {
				this.$emit('setLists', newVal);
			},
			deep: true,
		},
	},
	data() {
		return {
			initialUpdate: true,
			maxColumns: 6,
			columns: [
				{ id: 0, category: '', answerPos: [], link: '' },
				{ id: 1, category: '', answerPos: [], link: '' },
			],
			newBait: '',
			baits: [],
		};
	},
	created() {
		window.addEventListener('resize', this.adjustHeight);
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.adjustHeight);
	},
	methods: {
		addColumn() {
			this.columns.push({ id: this.columns.length, category: '', answerPos: [], link: '' });
			if (this.columns.length == this.maxColumns) document.querySelector('.lcl-wrap-columns').style.maxWidth = '100%';
			else document.querySelector('.lcl-wrap-columns').style.maxWidth = 'calc(100% - 70px)';

			this.adjustHeight();
		},
		setCategory(id, category, lastValue) {
			if (this.columns.filter((col) => col.category == category).length > 0) {
				this.$global.showToast('warn', this.$t('lclDuplicatedCategories'));
				document.getElementById(id).parentElement.querySelector('.lclc-category').value = lastValue;
			} else {
				this.columns.map((col) => {
					if (col.id == id) {
						col.category = category;
						col.answerPos.map((ans) => {
							ans.category = category;
						});
					}
					return col;
				});
			}
		},
		setAnswers(id, answers) {
			this.columns.map((col) => {
				if (col.id == id)
					col.answerPos = answers.map((ans) => {
						return {
							category: col.category,
							answer: ans,
						};
					});
				return col;
			});
		},
		setLink(id, link) {
			this.columns.map((col) => {
				if (col.id == id) col.link = link;
				return col;
			});
		},
		deleteColumn(id) {
			this.columns = this.columns.filter((col) => col.id !== id);
			this.adjustHeight();
		},
		adjustHeight() {
			[...document.querySelectorAll('.lclc-column-elements')].forEach((elem) => {
				elem.style.height = 'fit-content';
			});
			this.$nextTick(() => {
				let elements = [...document.querySelectorAll('.lclc-column-elements')];
				let maxHeight = Math.max(...elements.map((col) => col.offsetHeight));
				$('.lclc-column-elements').height(maxHeight);
			});
		},
		addBait() {
			if (this.newBait == '') this.$global.showToast('error', this.$t('lclEmptyBait'));
			else if (this.baits.filter((bait) => bait.answer == this.newBait).length > 0) this.$global.showToast('warn', this.$t('lclDuplicateBait'));
			else {
				this.baits.push({ category: 'BAIT', answer: this.newBait });
				this.newBait = '';
				this.$emit('setBaits', this.baits);
			}
		},
		editBait(bait) {
			this.newBait = bait.answer;
			this.deleteBait(bait);
		},
		deleteBait(bait) {
			this.baits = this.baits.filter((b) => bait.answer !== b.answer);
			this.$emit('setBaits', this.baits);
		},
	},
};
</script>

<style scoped>
.lcl-wrap-content {
	height: 100%;
	width: 100%;
}

h2 {
	width: fit-content;
	font-size: 40px;
	margin: 0px auto;
	padding: 5px 0px 5px 0px;
	text-shadow: 3px 3px var(--main-color-6);
	-webkit-text-stroke: 2px var(--main-color-text-dark);
	text-align: center;
}

.lcl-wrap-top {
	height: 100%;
	width: 100%;
	height: fit-content;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.lcl-wrap-top svg {
	font-size: 60px;
	color: var(--main-color-text-light);
	cursor: pointer;
}

.lcl-wrap-top svg:hover {
	color: var(--main-color-6);
}

.lcl-wrap-columns {
	height: 100%;
	width: 100%;
	max-width: calc(100% - 75px);
	padding-left: 5px;
	vertical-align: top;
	display: flex;
	flex-flow: wrap;
}

.lcl-wrap-bottom {
	width: 100%;
	display: block;
	text-align: center;
}

.lcl-wrap-input {
	width: 90%;
	height: fit-content;
	text-align: start;
	margin: auto;
	position: relative;
	display: inline-block;
	vertical-align: top;
}

.lcl-wrap-input label {
	font-size: 20px;
	margin: 10px auto;
}

.lcl-wrap-input input {
	width: 100%;
	height: 44px;
	padding: 10px !important;
	box-sizing: border-box;
	display: block;
	margin: 10px 0px;
	font-size: 20px;
}

button {
	font-size: 17px;
	min-width: 100%;
	height: 30px;
	margin: 10px 0px 20px 0px;
	display: block;
}

.lcl-wrap-baits {
	width: 90%;
	padding: 10px 20px;
	box-sizing: border-box;
	display: inline-block;
	margin: auto;
	text-align: center;
}

.lcl-wrap-baits {
	margin: 0px 0px 20px 0px;
	padding: 3px;
	box-sizing: border-box;
	max-height: 300px;
	overflow: auto;
	text-align: center;
}

.lcl-no-baits {
	font-size: 20px;
	margin-top: 10px;
}

.lcl-wrap-bait {
	width: 100%;
	min-height: 40px;
	padding: 5px;
	margin: 5px 0px;
	border: 1px solid var(--main-color-border-dark);
	background-color: var(--main-color-4);
	box-shadow: 2px 2px 4px 4px var(--main-color-border-dark);
	box-sizing: border-box;
	text-align: start;
}

.lcl-wrap-icons {
	float: right;
}

.lcl-wrap-icons svg {
	margin: 0px 2px;
	cursor: pointer;
	-webkit-filter: drop-shadow(3px 3px 0px var(--main-color-text-dark));
	filter: drop-shadow(3px 3px 0px var(--main-color-text-dark));
}

.lcl-edit {
	font-size: 20px;
	color: var(--main-color-warn);
}

.lcl-delete {
	font-size: 25px;
	color: var(--main-color-error);
}

.lcl-edit:hover {
	color: var(--secondary-color-warn);
}

.lcl-delete:hover {
	color: var(--secondary-color-error);
}
</style>
