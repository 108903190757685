<template>
	<div class="ss-wrap-content">
		<p>SystemStatistics</p>
	</div>
</template>

<script>
/**
 * @group Admin
 * SystemStatistics
 */
export default {
	name: 'SystemStatistics',
};
</script>

<style scoped>
.ss-wrap-content {
}
</style>
