<template>
	<div class="lo-wrap-content">
		<SideBar
			v-if="sessionState.currentState && lobby && quizNight && host && sessionState.currentState !== 'finished'"
			:history="lobby.history"
			:users="[...players, host]"
			:configuredShows="quizNight.configs"
			:shows="shows"
			:leaderboard="lobby.leaderboard"
			:sessionState="sessionState"
			:host="host"
			@checkUserConnections="checkUserConnections"
			@forceReload="forceReload"
			@forceRejoin="forceRejoin"
			@kickPlayer="removeUserFromSession"
		/>
		<div v-if="showInactiveUser" class="lo-inactive-users">
			<div class="lo-center-content">
				<h1>{{ $t('loInactiveUsers') }}</h1>
				<div v-if="sessionRoom && players.length > 0" class="lo-wrap-users">
					<UserElement
						v-for="player in sessionRoom.inactive"
						:key="player"
						:hideDisconnect="true"
						:user="[...players, host].filter((play) => play.uID == player)[0]"
						:sessionRoom="sessionRoom"
					/>
				</div>
				<p v-if="$global.isHost()" class="lo-actions-text">{{ $t('loPossibleActionsInactiveHost') }}</p>
				<p v-else class="lo-actions-text">{{ $t('loPossibleActionsInactivePlayer') }}</p>
				<div v-if="$global.isHost() && players.length > 0 && host && socket" class="lo-wrap-connection-check">
					<ConnectionCheck
						:users="[...players, host]"
						@checkUserConnections="checkUserConnections"
						@forceReload="forceReload"
						@forceRejoin="forceRejoin"
						@kickPlayer="removeUserFromSession"
					/>
				</div>
			</div>
		</div>
		<div v-if="sessionState.currentState == 'created'" class="lo-wrap-waiting-room">
			<h1 v-if="lobby">{{ lobby.session.name }}</h1>
			<div class="lo-wrap-users">
				<UserElement
					v-if="host"
					:user="host"
					:isHost="true"
					:sessionReady="sessionReady"
					:sessionRoom="sessionRoom"
					@startSession="startSession"
				/>
				<UserElement
					v-for="player in players"
					:key="player.uID"
					:user="player"
					:sessionRoom="sessionRoom"
					@removeUser="removeUserFromSession"
					@playerReady="playerReady"
					@playerNotReady="playerNotReady"
				/>
			</div>
			<p v-if="lobby" class="lo-amount-players">{{ players.length }} / {{ lobby.session.amountPlayers }} {{ $t('loPlayers') }}</p>
			<div class="lo-wrap-list">
				<GameShowConfigList
					v-if="quizNight && shows"
					:registeredShows="shows"
					:configuredShows="quizNight.configs"
					:state="{ lobby: true, showDescription: true, isPrivate: quizNight.isPrivate, isPublic: quizNight.isPublic }"
				/>
			</div>
		</div>
		<div v-else-if="sessionState" class="lo-wrap-gameshow-panel">
			<GameShowPanel
				v-if="quizNight && shows && lobby && players && host"
				:lobby="lobby"
				:players="players"
				:configuredShows="quizNight.configs"
				:shows="shows"
				:sessionRoom="sessionRoom"
				:host="host"
				:sessionReady="sessionReady"
				:sessionState="sessionState"
				:quizNight="quizNight"
				@removeUserFromSession="removeUserFromSession"
				@startShow="startShow"
			/>
		</div>
		<div v-else class="lo-loading-session">
			<h1>{{ $t('loLoadingSession') }}</h1>
		</div>
	</div>
</template>

<script>
import SideBar from '../components/lobby/SideBar.vue';
import UserElement from '../components/lobby/UserElement.vue';
import GameShowConfigList from '../components/quizNightCreation/GameShowConfigList.vue';
import GameShowPanel from '../components/lobby/GameShowPanel.vue';
import ConnectionCheck from '../components/lobby/ConnectionCheck.vue';
export default {
	name: 'Lobby',
	components: {
		SideBar,
		UserElement,
		GameShowConfigList,
		GameShowPanel,
		ConnectionCheck,
	},
	data() {
		return {
			socket: null,
			forceRouterPush: false,
			sessionState: {},
			lobby: null,
			quizNight: null,
			shows: null,
			users: [],
			host: null,
			players: [],
			sessionRoom: {
				sID: this.$route.params.sID,
				uIDs: [],
				inactive: [],
				blocked: [],
			},
			sessionReady: false,
			timeout: null,
			playerGetsKicked: false,
			forcedReload: false,
			showInactiveUser: false,
		};
	},
	created() {
		this.$global.checkAccess(this.$router, true);
		this.getRegisteredShows();
	},
	mounted() {
		this.getSession(this.$route.params.sID);
		this.initiateSocket();
	},
	beforeRouteLeave(to, from, next) {
		if (!this.playerGetsKicked && !this.forceRouterPush && this.sessionState.currentState !== 'finished') {
			let that = this;
			this.$global.showPopup(
				this.$t('loLeaveLobby'),
				this.$t('loLeaveLobbyText'),
				'warning',
				{ text: this.$t('loCancle'), color: 'var(--main-color-4)' },
				{ text: this.$t('loLeave'), color: 'var(--main-color-error)' },
				null,
				function (result) {
					let wasLogout = sessionStorage.getItem('wasLogout');
					sessionStorage.removeItem('wasLogout');
					if (result.isConfirmed) {
						let sID = that.$route.params.sID;
						let uID = that.$global.getUser().uID;
						let name = that.$global.getUser().name;
						that.$socket.emitEvent('leave_session', {
							sID: sID,
							uID: uID,
							state: that.lobby.session.state,
							host: that.lobby.session.uID,
						});
						// prettier-ignore
						that.$global.addHistoryEntry(sID, uID, null, null, 'leaveSession', name, null, "", function (err) {
							if (err) console.log(err);
							
							if(wasLogout) that.$global.logoutUser();
							next();
						});
					} else next(false);
				}
			);
		} else next();
	},
	beforeDestroy() {
		this.cleanupSocket();
		// this.socket.destroy();
		window.removeEventListener('beforeunload', this.beforeUnloadListener);
		window.removeEventListener('unload', this.clearTimeout);
	},
	methods: {
		// Needs to listen to unloads to prevent disconnects from the socket io rooms
		// The beforeunload event automatically disconnects the socket and doesn't reconnect after.
		// If a user decides to stay the page gets reloaded so that the user can reconnect
		// If the reload of the page is forced the callback is ignored
		beforeUnloadListener(e) {
			if (!this.forcedReload) {
				let that = this;
				// e.preventDefault();
				this.$nextTick(() => {
					that.timeout = setTimeout(function () {
						window.removeEventListener('beforeunload', that.beforeUnloadListener);
						// that.$nextTick(()=>{

						// })
						location.reload();
					}, 1000);
				}, 1);
				return (e.returnValue = 'Are you sure you want to exit?');
			}
		},
		clearTimeout() {
			clearTimeout(this.timeout);
			this.timeout = null;
		},
		getSession(sID) {
			let that = this;
			if (this.$global.getUser()) {
				this.$global.getData(
					'session',
					'/getSession',
					{
						headers: { pageauthheader: this.$global.getAccessCode() },
						auth: this.$global.getCredentials(),
						params: { sID: sID, uID: this.$global.getUser().uID },
					},
					null,
					function (err, result) {
						if (err) {
							that.forceRouterPush = true;
							that.$router.push({ name: 'Home' });
							that.$global.showToast('error', that.$t(err.response ? err.response.data.msg : 'loSessionError'));
						} else {
							if (result.session.state !== 'finished') {
								window.addEventListener('beforeunload', that.beforeUnloadListener);
								window.addEventListener('unload', that.clearTimeout);
							}
							that.lobby = result;
							that.$global.setSession(result);
							that.getSessionState();
							that.getUsersOfSession();
							that.getSessionRoom();
							that.getQuizNight(result.session.qID);
						}
					}
				);
			} else {
				this.forceRouterPush = true;
				this.$router.push({ name: 'Home' });
			}
		},
		getSessionState() {
			let that = this;
			this.$global.getData(
				'session',
				'/getSessionState',
				{
					headers: { pageauthheader: this.$global.getAccessCode() },
					auth: this.$global.getCredentials(),
					params: { sID: this.$route.params.sID },
				},
				null,
				function (err, result) {
					if (err) that.$global.showToast('error', that.$t(err.response ? err.response.data.msg : 'loSessionStateError'));
					else {
						that.sessionState = result;
						that.lobby.session.state = result.currentState;

						that.$nextTick(() => window.dispatchEvent(new Event('resize')));
					}
				}
			);
		},
		getRegisteredShows() {
			let that = this;
			this.$global.getData(
				'quiz',
				'/getRegisteredShows',
				{
					headers: { pageauthheader: this.$global.getAccessCode() },
					auth: this.$global.getCredentials(),
				},
				null,
				function (err, result) {
					if (err) that.$global.showToast('error', that.$t(err.response ? err.response.data.msg : 'loShowsError'));
					else {
						that.shows = result;
					}
				}
			);
		},
		getUsersOfSession() {
			let that = this;
			this.$global.getData(
				'session',
				'/getUsersOfSession',
				{
					headers: { pageauthheader: this.$global.getAccessCode() },
					auth: this.$global.getCredentials(),
					params: { sID: this.$route.params.sID },
				},
				null,
				function (err, result) {
					if (err) {
						that.$global.showToast('error', that.$t(err.response ? err.response.data.msg : 'loUserError'));
					} else {
						that.sessionReady = false;
						that.users = result;
						let uIDs = [...result.map((user) => user.uID)];
						that.users.forEach((user) => {
							if (that.lobby.leaderboard.filter((u) => u.uID == user.uID).length > 0) {
								let playerIdx = that.players.findIndex((p) => p.uID == user.uID);
								if (playerIdx == -1) that.players.push({ ...user, ready: false });
								else that.players[playerIdx].order = user.order;
							} else if (user.uID == that.lobby.session.uID) that.host = user;
						});
						that.players = that.players.filter((p) => uIDs.includes(p.uID));
						that.players.map((player) => {
							player.ready = false;
							return player;
						});

						that.players.sort((a, b) => {
							if (a.name < b.name) {
								return -1;
							}
							if (a.name > b.name) {
								return 1;
							}
							return 0;
						});

						try {
							console.log('------------------------');
							console.log(
								that.players
									.map((player) => {
										return `${player.order}. ${player.name}`;
									})
									.join('\n')
							);
						} catch (error) {
							console.log(error);
						}
					}
				}
			);
		},
		getSessionRoom() {
			let that = this;
			this.$global.getData(
				'session',
				'/getSessionRoom',
				{
					headers: { pageauthheader: this.$global.getAccessCode() },
					auth: this.$global.getCredentials(),
					params: { sID: this.$route.params.sID },
				},
				null,
				function (err, result) {
					if (err) {
						that.$global.showToast('error', that.$t(err.response ? err.response.data.msg : 'loSessionRoomError'));
					} else {
						that.sessionRoom = result;
						if (that.sessionRoom.inactive.length == 0) that.showInactiveUser = false;
						else if (that.sessionRoom.inactive.length > 0 && !['created', 'lobby', 'finished'].includes(that.lobby.session.state))
							that.showInactiveUser = true;
					}
				}
			);
		},
		getQuizNight(qID) {
			let that = this;
			this.$global.getData(
				'quiz',
				'/getQuizNight',
				{
					headers: { pageauthheader: this.$global.getAccessCode() },
					auth: this.$global.getCredentials(),
					params: { qID: qID },
				},
				null,
				function (err, result) {
					if (err) that.$global.showToast('error', that.$t(err.response ? err.response.data.msg : 'loQuizNightError'));
					else {
						that.$nextTick(() => {
							result.configs.map((conf) => {
								conf.show = that.shows.filter((show) => show.rID == conf.rID)[0].abbreviation;
								if (conf.pointsPerLevel) conf.pointsPerLevel = JSON.parse(conf.pointsPerLevel);
								return conf;
							});
							that.quizNight = result;
						});
					}
				}
			);
		},
		getSessionHistory(cb) {
			let that = this;
			this.$global.getData(
				'session',
				'/getSessionHistory',
				{
					headers: { pageauthheader: this.$global.getAccessCode() },
					auth: this.$global.getCredentials(),
					params: { sID: this.$route.params.sID },
				},
				null,
				function (err, result) {
					if (err) that.$global.showToast('error', that.$t(err.response ? err.response.data.msg : 'loGetHistoryError'));
					else if (that.lobby) that.lobby.history = result;
					if (cb) cb();
				}
			);
		},
		getSessionLeaderbaord() {
			let that = this;
			this.$global.getData(
				'session',
				'/getSessionLeaderboard',
				{
					headers: { pageauthheader: this.$global.getAccessCode() },
					auth: this.$global.getCredentials(),
					params: { sID: this.$route.params.sID },
				},
				null,
				function (err, result) {
					if (err) that.$global.showToast('error', that.$t(err.response ? err.response.data.msg : 'loGetLeaderboardError'));
					else that.lobby.leaderboard = result;
				}
			);
		},
		removeUserFromSession(uID) {
			this.$socket.emitEvent('kick_player', { sID: this.$route.params.sID, uID: uID });
			this.$socket.emitEvent('leave_session', {
				sID: this.$route.params.sID,
				uID: uID,
				state: this.lobby.session.state,
				host: this.lobby.session.uID,
				kicked: true,
			});
			let player = this.players.filter((player) => player.uID == uID)[0];
			// prettier-ignore
			this.$global.addHistoryEntry(this.$route.params.sID, this.$global.getUser().uID, null, null, 'kickUser', player ? player.name : null, null, "", function (err) {
				if (err) console.log(err);
			});
		},
		initiateSocket() {
			let that = this;
			this.$socket.getSocket(function (socket) {
				that.socket = socket;
				that.socket.on('user_joined', function (uID) {
					that.getSession(that.$route.params.sID);
				});

				that.socket.on('user_left', function (uID) {
					let player = that.players.filter((player) => player.uID == uID)[0];
					if (player) that.$global.showToast('info', `${player.name} ${that.$t('loLeftTheLobby')}`);
					else that.$global.showToast('info', that.$t('loUnknownPlayerLeftTheLobby'));
					that.getSession(that.$route.params.sID);
				});

				that.socket.on('user_inactive', function (uID) {
					that.getSession(that.$route.params.sID);
					if (!['created', 'lobby', 'finished'].includes(that.lobby.session.state)) that.showInactiveUser = true;
				});

				that.socket.on('kick_player', function (data) {
					let lobby = that.$global.getSession();
					let player = that.players.filter((user) => user.uID == data.uID);
					if (data.uID == that.$global.getUser().uID && data.sID == lobby.session.sID) {
						that.$global.showToast('warn', that.$t('loKickedFromLobby'));
						that.playerGetsKicked = true;
						that.$router.push({ name: 'Home' });
					} else {
						that.$global.showToast('info', `${that.$t('loKickedPlayer')}: ${player.length > 0 ? player[0].name : 'loUnknownPlayer'}`);
						that.getSession(that.$route.params.sID);
					}
				});

				that.socket.on('player_ready', function (data) {
					let readyCount = 0;
					that.players.map((player) => {
						if (player.uID == data.uID) player.ready = true;
						if (player.ready) readyCount += 1;
						return player;
					});
					if (readyCount == that.players.length && that.players.length >= 2 && that.players.length <= 6) that.sessionReady = true;
				});

				that.socket.on('player_not_ready', function (data) {
					that.sessionReady = false;
					that.players.map((player) => {
						if (player.uID == data.uID) player.ready = false;
						return player;
					});
				});

				that.socket.on('started_session', function () {
					that.getSessionHistory();
					that.lobby.session.state = 'lobby';
					that.sessionReady = false;
					that.players.map((player) => {
						player.ready = false;
						return player;
					});
					that.getSessionState();
					// that.cleanupSocket();
				});

				that.socket.on('started_show', function () {
					that.getSessionHistory(() => {
						that.getSessionState();
					});
				});

				that.socket.on('force_reload', function () {
					that.forcedReload = true;
					that.$nextTick(() => {
						location.reload();
					});
				});

				that.socket.on('forced_rejoin_result', function (data) {
					if (that.$global.isHost()) that.$global.showToast(data.level, that.$t(data.msg));
				});

				that.socket.on('refresh_history', function () {
					that.getSessionHistory();
				});

				that.socket.on('refresh_leaderboard', function () {
					that.getSessionLeaderbaord();
				});

				that.socket.on('closed_session', function () {
					that.$global.showToast('warn', that.$t('erSessionClosed'));
					that.forceRouterPush = true;
					that.$router.push({ name: 'Home' });
				});
			});
		},
		playerReady(uID) {
			this.$socket.emitEvent('player_ready', { sID: this.$route.params.sID, uID: uID });
		},
		playerNotReady(uID) {
			this.$socket.emitEvent('player_not_ready', { sID: this.$route.params.sID, uID: uID });
		},
		checkUserConnections(cb) {
			let users = [...this.players, this.host].map((p) => p.uID);
			this.socket.emit('check_user_connections', { sID: this.$route.params.sID, users: users }, function (data) {
				cb(data);
			});
		},
		startSession() {
			this.$socket.emitEvent('start_session', { sID: this.$route.params.sID, uID: this.$global.getUser().uID });
		},
		startShow(show) {
			let sID = this.$route.params.sID;
			if (['smf', 'xfz', 'dpin', 'wlp'].includes(show)) {
				let uID = this.$global.getUser().uID;
				let currentShow = this.quizNight.configs.filter((s) => s.show == show)[0];
				let gID = currentShow.gID;
				let question = currentShow.questions.filter((quest) => quest.position == 0)[0];
				if (!question)
					question = currentShow.questions.filter(
						(quest) => quest.position == Math.min(...currentShow.questions.map((q) => q.position))
					)[0];
				let that = this;
				this.$global.addHistoryEntry(sID, uID, gID, question.qID, 'setQuestion', '', null, question.qID, function (err) {
					if (err) that.$global.showToast('error', that.$t('loAddHistoryEntryError'));
					else that.socket.emit('show_timer', { sID: sID, msg: 'gspNextShow' });
				});
			} else this.socket.emit('show_timer', { sID: sID, msg: 'gspNextShow' });
		},
		forceReload(cb) {
			let that = this;
			this.socket.emit('force_reload', { sID: this.$route.params.sID }, function (success) {
				cb(success);
				that.forcedReload = true;
				that.$nextTick(() => {
					location.reload();
				});
			});
		},
		forceRejoin(data) {
			this.socket.emit('force_rejoin', { sID: this.$route.params.sID, uID: data.uID });
			window.setTimeout(() => {
				if (data.cb) data.cb();
			}, 200);
		},
		cleanupSocket() {
			if (this.socket) {
				this.socket.off('user_joined');
				this.socket.off('user_left');
				this.socket.off('user_inactive');
				this.socket.off('kick_player');
				this.socket.off('player_ready');
				this.socket.off('player_not_ready');
				this.socket.off('force_reload');
				this.socket.off('forced_rejoin_result');
				this.socket.off('refresh_history');
				this.socket.off('refresh_leaderboard');
				this.socket.off('started_session');
				this.socket.off('closed_session');
			}
		},
	},
};
</script>

<style scoped>
.lo-wrap-content {
	width: 100%;
	height: 100%;
	overflow-y: auto;
	overflow-x: hidden;
	position: relative;
}

.lo-wrap-users {
	width: 100%;
	min-width: fit-content;
	height: fit-content;
	display: flex;
	flex-flow: wrap;
}

.lo-amount-players {
	width: fit-content;
	margin: 25px auto 10px auto;
}

.lo-wrap-list {
	width: 90vw;
	height: fit-content;
	padding: 0px 0px 0px 20px;
	margin: auto;
}

.lo-inactive-users {
	width: 100vw;
	height: 100vh;
	position: absolute;
	top: 0px;
	left: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--main-color-dark-transparent-cc);
	color: var(--main-color-text-light);
	z-index: 15;
	overflow: auto;
}

.lo-center-content {
	width: 90vw;
	text-align: center;
}

.lo-actions-text {
	margin: 40px auto 10px auto;
	text-align: center;
}

.lo-wrap-connection-check {
	min-width: 550px;
	max-width: 700px;
	width: 60vw;
	margin: auto;
}
</style>
