import { render, staticRenderFns } from "./UserControls.vue?vue&type=template&id=4afa4dfa&scoped=true"
import script from "./UserControls.vue?vue&type=script&lang=js"
export * from "./UserControls.vue?vue&type=script&lang=js"
import style0 from "./UserControls.vue?vue&type=style&index=0&id=4afa4dfa&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4afa4dfa",
  null
  
)

export default component.exports