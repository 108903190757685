<template>
	<div class="qnc-wrap-content">
		<div class="qnc-wrap-top">
			<div class="qnc-wrap-quiz-night-top-bar">
				<h2>{{ $t('hoQuizNightBrowser') }}</h2>
				<div class="qnc-wrap-quiz-night-filter">
					<FilterBar :type="'quizNight'" :isLoading="isLoading" :amountElements="quizNightAmount" @refresh="refreshBrowser" />
				</div>
			</div>
			<div class="qnc-blur-ref">
				<div v-show="!firstItemVisible" class="qnc-blur-before"></div>
				<div class="qnc-wrap-quiz-nights">
					<QuizNightPreview
						v-for="(night, idx) in quizNights"
						:id="`quizNight-${idx}`"
						:key="idx"
						:night="night"
						@configureQuizNight="$router.push({ name: 'QuizNightConfigurationOverview', query: { qID: night.qID } })"
					/>
					<div v-if="quizNights.length == 0 && !isLoading" class="qnc-wrap-no-quiz-nights">
						<div class="qnc-wrap-refresh">
							<fai icon="fas fa-sad-tear" />
							<p class="qnc-no-quiz-nights">{{ $t('hoNoQuizNightsFound') }}</p>
						</div>
					</div>
				</div>
				<div v-show="!lastItemVisible" class="qnc-blur-after"></div>
			</div>
		</div>
		<div class="qnc-wrap-bottom">
			<div class="qnc-card qnc-question-card" @click="$router.push({ name: 'QuestionCreation' })">
				<div>
					<fai icon="fas fa-question" />
					<p>{{ $t('qncCreateQuestion') }}</p>
				</div>
			</div>
			<div class="qnc-card qnc-quiz-night-card" @click="$router.push({ name: 'QuizNightConfigurationOverview' })">
				<div>
					<fai icon="fas fa-object-group" />
					<p>{{ $t('qncConfigureQuizNight') }}</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import QuizNightPreview from '../components/configuration/QuizNightPreview.vue';
import FilterBar from '../components/lobby/filter/FilterBar';
export default {
	name: 'QuizNightConfiguration',
	components: {
		FilterBar,
		QuizNightPreview,
	},
	data() {
		return {
			isLoading: false,
			user: null,
			refreshClicked: false,
			quizNightFilter: null,
			quizNights: [],
			quizNightAmount: 0,
			firstItemVisible: true,
			lastItemVisible: true,
		};
	},
	created() {
		this.$global.checkAccess(this.$router, true);
		this.user = this.$global.getUser();
	},
	mounted() {
		document.querySelector('.qnc-wrap-quiz-nights').addEventListener('scroll', this.scrollListener);
	},
	beforeDestroy() {
		document.querySelector('.qnc-wrap-quiz-nights').removeEventListener('scroll', this.scrollListener);
	},
	methods: {
		scrollListener(e) {
			this.firstItemVisible = this.itemVisible(true);
			this.lastItemVisible = this.itemVisible(false);
		},
		itemVisible(first) {
			const item = document.getElementById(`quizNight-${first ? 0 : this.quizNights.length - 1}`);
			const container = document.querySelector('.qnc-wrap-quiz-nights');
			if (item && container) {
				const containerRect = container.getBoundingClientRect();
				const itemRect = item.getBoundingClientRect();

				// Check if the item's top and bottom are within the container's visible area
				const isTopVisible = itemRect.top >= containerRect.top && itemRect.top <= containerRect.bottom;
				const isBottomVisible = itemRect.bottom >= containerRect.top && itemRect.bottom <= containerRect.bottom;

				return isTopVisible || isBottomVisible;
			} else if (this.quizNights.length == 0) return true;
			else return false;
		},
		queryQuizNights() {
			let that = this;
			if (this.quizNightFilter.shows.length == 0) {
				this.$global.showToast('warn', this.$t('hoNoShowsSelected'));
				this.refreshClicked = false;
			} else {
				this.isLoading = true;
				this.$global.getData(
					'quiz',
					'/getQuizNights',
					{
						headers: { pageauthheader: this.$global.getAccessCode() },
						auth: this.$global.getCredentials(),
						params: { uID: this.user.uID, quizNightFilter: this.quizNightFilter != null ? JSON.stringify(this.quizNightFilter) : null },
					},
					null,
					function (err, result) {
						if (err) that.$global.showToast('error', that.$t(err.response ? err.response.data.msg : 'qncQueryError'));
						else {
							if (that.filterPlayable) {
								result.quizNights = result.quizNights
									.map((night) => {
										if (night.isPrivate || night.isPublic) return night;
									})
									.filter((night) => night);
							}
							that.quizNights = result.quizNights;
							that.quizNightAmount = result.quizNightAmount;
						}
						that.isLoading = false;
						that.refreshClicked = false;
					}
				);
			}
		},
		refreshBrowser(quizNightFilter) {
			if (!this.refreshClicked) {
				this.refreshClicked = true;
				this.quizNightFilter = quizNightFilter;
				this.quizNights = [];
				this.queryQuizNights();
			}
		},
	},
};
</script>

<style scoped>
.qnc-wrap-content {
	width: 100%;
	height: 100%;
	padding: 40px;
	box-sizing: border-box;
	overflow-x: hidden;
	overflow-y: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow: column;
}

.qnc-wrap-top,
.qnc-wrap-bottom {
	width: 100%;
	display: flex;
	flex-flow: row;
	justify-content: center;
	align-items: center;
}

.qnc-wrap-top {
	width: calc(100% - 10px);
	height: 80vh;
	min-height: 300px;
	flex: 1 1 100%;
	margin: 5px;
	padding: 10px;
	flex-flow: column;
	box-sizing: border-box;
	border: 2px solid var(--main-color-border-dark);
	background-color: var(--main-color-3-cc);
}

.qnc-wrap-quiz-night-top-bar {
	width: 100%;
	height: 160px;
	text-align: center;
}

.qnc-wrap-quiz-night-top-bar h2 {
	height: 40px;
	margin: 0px auto;
	padding: 0px 0px 10px 0px;
	font-size: 35px;
	text-shadow: 2px 2px var(--main-color-6);
	-webkit-text-stroke: 2px var(--main-color-text-dark);
}

.qnc-wrap-quiz-night-top-bar h2 svg {
	margin: 5px;
	font-size: 45px;
	color: var(--main-color-text-light);
	filter: drop-shadow(2px 2px 2px var(--main-color-border-dark));
}

.qnc-wrap-quiz-night-filter {
	width: 100%;
	height: 100px;
	margin-right: 15px;
	box-sizing: border-box;
	padding: 0px 5px 0px 0px;
	overflow-y: auto;
	overflow-x: hidden;
	border-bottom: 1px solid var(--main-color-border-light);
}

.qnc-blur-ref {
	width: 100%;
	height: calc(100% - 150px);
	position: relative;
}

.qnc-wrap-quiz-nights {
	width: 100%;
	height: 100%;
	padding: 00px 10px;
	box-sizing: border-box;
	overflow: auto;
}

.qnc-blur-before,
.qnc-blur-after {
	position: absolute;
	left: 8px;
	width: calc(100% - 30px);
	margin: auto;
	height: 60px;
	backdrop-filter: blur(1px);
	z-index: 10;
	pointer-events: none;
}

.qnc-blur-before {
	top: 0px;
	background: linear-gradient(to bottom, #00000033, #00000000);
}

.qnc-blur-after {
	bottom: 0;
	background: linear-gradient(to top, #00000033, #00000000);
}

.qnc-wrap-no-quiz-nights {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.qnc-wrap-no-quiz-nights p {
	text-shadow: 3px 3px var(--main-color-border-dark);
}

.qnc-wrap-refresh {
	text-align: center;
	font-size: 30px;
}

.qnc-wrap-refresh svg {
	margin: 10px;
	font-size: 60px;
	filter: drop-shadow(2px 2px 2px var(--main-color-border-dark));
	color: var(--main-color-6);
}

.qnc-card {
	flex: 1 1 50%;
	height: 10vh;
	min-height: 100px;
	margin: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: transform 0.5s;
	border: 2px solid var(--main-color-border-dark);
}

.qnc-card:hover {
	cursor: pointer;
	transform: scale(1.1);
	z-index: 5;
}

.qnc-card div {
	text-align: center;
}

.qnc-card svg {
	margin: 5px;
	font-size: 45px;
	color: var(--main-color-text-light);
	filter: drop-shadow(2px 2px 2px var(--main-color-border-dark));
}

.qnc-card p {
	font-size: 20px;
	text-shadow: 1px 1px var(--main-color-text-dark);
}

.qnc-question-card {
	background-color: var(--main-color-6-cc);
}

.qnc-quiz-night-card {
	background-color: var(--main-color-success-cc);
}
</style>
