<template>
	<div class="bfv-wrap-content">
		<div class="bfv-wrap-bug-report">
			<BugReportForm v-if="shows" :shows="shows" />
		</div>
		<div class="bfv-wrap-bug-view">
			<div v-if="displayedAsset" class="bfv-displayed-asset" @click="closeAsset">
				<div class="bfv-asset-top-bar">
					<p @click="downloadAsset"><fai icon="fas fa-file-arrow-down" class="bfv-download" /> {{ $t('bfvDownloadAsset') }}</p>
				</div>
				<div class="bfv-asset">
					<img :src="displayedAsset.src" alt="" />
				</div>
			</div>
			<div class="bfv-wrap-bug-top-bar">
				<div class="bfv-wrap-bug-filter">
					<FilterBar :type="'bug'" :isLoading="isLoading" :amountElements="bugAmount" @refresh="refreshBrowser" />
				</div>
			</div>
			<div class="bfv-blur-ref">
				<div v-show="!firstItemVisible" class="bfv-blur-before"></div>
				<div class="bfv-wrap-bugs">
					<BugPreview
						v-for="(bug, idx) in bugs"
						:id="`bug-${idx}`"
						:key="idx"
						:bug="bug"
						:shows="shows"
						@showAsset="showAsset"
						@refreshBugs="getBugs"
					/>
					<div v-if="bugs.length == 0 && !isLoading" class="bfv-wrap-no-bugs">
						<div class="bfv-wrap-refresh">
							<fai icon="fas fa-face-laugh-beam" />
							<p class="bfv-no-bugs">{{ $t('bfvNoBugsFound') }}</p>
						</div>
					</div>
				</div>
				<div v-show="!lastItemVisible" class="bfv-blur-after"></div>
			</div>
		</div>
	</div>
</template>

<script>
/**
 * @group Admin
 * BugFeedbackView
 */
import BugReportForm from '../help/BugReportForm.vue';
import FilterBar from '../lobby/filter/FilterBar.vue';
import BugPreview from './BugPreview.vue';
export default {
	name: 'BugFeedbackView',
	components: {
		BugReportForm,
		BugPreview,
		FilterBar,
	},
	data() {
		return {
			shows: [],
			bugs: [],
			bugAmount: 0,
			isLoading: false,
			refreshClicked: false,
			bugFilter: null,
			firstItemVisible: true,
			lastItemVisible: true,
			displayedAsset: null,
		};
	},
	mounted() {
		document.querySelector('.bfv-wrap-bugs').addEventListener('scroll', this.scrollListener);
		this.getRegisteredShows();
	},
	beforeDestroy() {
		document.querySelector('.bfv-wrap-bugs').removeEventListener('scroll', this.scrollListener);
	},
	methods: {
		scrollListener(e) {
			this.firstItemVisible = this.itemVisible(true);
			this.lastItemVisible = this.itemVisible(false);
		},
		itemVisible(first) {
			const item = document.getElementById(`bug-${first ? 0 : this.bugs.length - 1}`);
			const container = document.querySelector('.bfv-wrap-bugs');
			if (item && container) {
				const containerRect = container.getBoundingClientRect();
				const itemRect = item.getBoundingClientRect();

				// Check if the item's top and bottom are within the container's visible area
				const isTopVisible = itemRect.top >= containerRect.top && itemRect.top <= containerRect.bottom;
				const isBottomVisible = itemRect.bottom >= containerRect.top && itemRect.bottom <= containerRect.bottom;

				return isTopVisible || isBottomVisible;
			} else if (this.bugs.length == 0) return true;
			else return false;
		},
		getRegisteredShows() {
			let that = this;
			this.$global.getData(
				'quiz',
				'/getRegisteredShows',
				{ headers: { pageauthheader: this.$global.getAccessCode() } },
				null,
				function (err, result) {
					if (err) that.$global.showToast('error', that.$t(err.response ? err.response.data.msg : 'bfvShowsError'));
					else that.shows = result;
				}
			);
		},
		getBugs() {
			let that = this;
			this.$global.getData(
				'admin',
				`/bugs`,
				{
					headers: {
						pageauthheader: this.$global.getAccessCode(),
						adminauthheader: this.$global.getAdminCode(),
						adminid: this.$global.getUser().uID,
					},
					auth: this.$global.getCredentials(),
					params: { bugFilter: this.bugFilter != null ? JSON.stringify(this.bugFilter) : null },
				},
				null,
				function (err, result) {
					if (err) {
						that.$global.showToast('error', that.$t('bfvQueryBugError'));
						if (err.response.status == 401) that.$router.push({ name: 'Home' });
					} else {
						that.bugs = result.bugs;
						that.bugAmount = result.bugAmount;
						that.$nextTick(() => {
							that.firstItemVisible = that.itemVisible(true);
							that.lastItemVisible = that.itemVisible(false);
						});
					}
					that.isLoading = false;
					that.refreshClicked = false;
				}
			);
		},
		refreshBrowser(bugFilter) {
			if (!this.refreshClicked) {
				this.refreshClicked = true;
				this.isLoading = true;
				this.bugFilter = bugFilter;
				this.bugs = [];
				this.getBugs();
			}
		},
		showAsset(asset) {
			this.displayedAsset = asset;
		},
		closeAsset(e) {
			e.stopPropagation();
			this.displayedAsset = null;
		},
		downloadAsset(e) {
			e.stopPropagation();
			// Split the data URL at the comma to separate the MIME type from the data
			let parts = this.displayedAsset.src.split(',');
			let mime = parts[0].match(/:(.*?);/)[1];
			let base64Data = parts[1];

			const mimeToExtension = {
				'image/jpeg': 'jpg',
				'image/jpg': 'jpg',
				'image/png': 'png',
				'image/gif': 'gif',
				'image/svg+xml': 'svg',
				'image/webp': 'webp',
				'image/tiff': 'tiff',
				'image/bmp': 'bmp',
				'image/ico': 'ico',
			};

			// Decode the base64 data
			let byteCharacters = atob(base64Data);
			let byteNumbers = new Array(byteCharacters.length);
			for (let i = 0; i < byteCharacters.length; i++) {
				byteNumbers[i] = byteCharacters.charCodeAt(i);
			}
			let byteArray = new Uint8Array(byteNumbers);

			// Create a blob from the byte array
			let blob = new Blob([byteArray], { type: mime });
			let url = URL.createObjectURL(blob);

			// Create an anchor element
			let link = document.createElement('a');
			link.download = `Bug_${new Date().toLocaleString()}.${mimeToExtension[mime]}`;
			link.href = url;
			link.click();

			// Release the reference to the blob URL
			URL.revokeObjectURL(url);
		},
	},
};
</script>

<style scoped>
.bfv-wrap-bug-view {
	width: calc(100% - 10px);
	height: 80vh;
	min-height: 300px;
	max-height: 80vh;
	flex: 1 1 100%;
	margin: 5px;
	padding: 10px;
	box-sizing: border-box;
	border: 2px solid var(--main-color-border-dark);
	/* position: relative; */
	background-color: var(--main-color-3-cc);
}

.bfv-wrap-bug-report {
	width: 90%;
	margin: 0px auto 20px auto;
}

.bfv-displayed-asset {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0px;
	left: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow: wrap;
	background-color: var(--main-color-4-cc);
	z-index: 15;
}

.bfv-asset-top-bar {
	width: 100%;
	height: 50px;
	margin-bottom: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.bfv-asset-top-bar p {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.bfv-asset-top-bar p:hover {
	cursor: pointer;
}

.bfv-asset-top-bar svg {
	font-size: 30px;
	margin: 0px 10px 0px 20px;
	filter: drop-shadow(2px 2px 2px var(--main-color-border-dark));
}

.bfv-download {
	color: var(--main-color-info);
}

.bfv-delete {
	color: var(--main-color-error);
}

.bfv-asset {
	width: 100%;
	height: calc(100% - 60px);
	display: flex;
	justify-content: center;
	align-items: center;
}

.bfv-displayed-asset img {
	max-width: 90%;
	max-height: 90%;
}

.bfv-wrap-bug-top-bar {
	width: 100%;
	height: 120px;
	text-align: center;
}

.bfv-wrap-bug-top-bar h2 {
	height: 40px;
	margin: 0px auto;
	padding: 0px 0px 10px 0px;
	font-size: 35px;
	text-shadow: 2px 2px var(--main-color-6);
	-webkit-text-stroke: 2px var(--main-color-text-dark);
}

.bfv-wrap-bug-top-bar h2 svg {
	margin: 5px;
	font-size: 45px;
	color: var(--main-color-text-light);
	filter: drop-shadow(2px 2px 2px var(--main-color-border-dark));
}

.bfv-wrap-bug-filter {
	width: 100%;
	height: 110px;
	margin-right: 15px;
	box-sizing: border-box;
	padding: 0px 5px 0px 0px;
	overflow-y: auto;
	overflow-x: hidden;
	border-bottom: 1px solid var(--main-color-border-light);
}

.bfv-blur-ref {
	width: 100%;
	height: calc(100% - 110px);
	position: relative;
}

.bfv-wrap-bugs {
	width: 100%;
	height: 100%;
	padding: 0px 10px;
	box-sizing: border-box;
	overflow: auto;
}

.bfv-blur-before,
.bfv-blur-after {
	position: absolute;
	left: 8px;
	width: calc(100% - 30px);
	margin: auto;
	height: 60px;
	backdrop-filter: blur(1px);
	z-index: 10;
	pointer-events: none;
}

.bfv-blur-before {
	top: 0px;
	background: linear-gradient(to bottom, #00000033, #00000000);
}

.bfv-blur-after {
	bottom: 0;
	background: linear-gradient(to top, #00000033, #00000000);
}

.bfv-wrap-no-bugs {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.bfv-wrap-refresh {
	text-align: center;
	font-size: 30px;
}

.bfv-wrap-refresh svg {
	margin: 10px;
	font-size: 60px;
	filter: drop-shadow(2px 2px 2px var(--main-color-border-dark));
	color: var(--main-color-6);
}
</style>
