<template>
	<div class="pa-wrap-content">
		<h1>{{ $t('paPageTitle') }}</h1>
		<div class="pa-wrap-input">
			<label>{{ $t('paInputTitle') }}</label>
			<input type="text" autocomplete="on" :placeholder="$t('paInputTitle')" v-model="accessCode" @keyup.enter="checkAccess" />
			<button class="app-default-btn" @click="checkAccess">{{ $t('paGetAccess') }}</button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'PageAccess',
	data() {
		return {
			accessCode: '',
		};
	},
	created() {
		sessionStorage.removeItem('credentials');
		localStorage.removeItem('credentials');
		sessionStorage.removeItem('user');
		localStorage.removeItem('user');

		if (this.$route.query.token) {
			this.accessCode = this.$route.query.token;
			this.checkAccess();
		} else this.$global.checkAccess(this.$router, false);

		if (['paVerified', 'paEmailChanged'].includes(this.$route.query.msg)) this.$global.showToast('success', this.$t(this.$route.query.msg));
		else if (this.$route.query.msg !== '' && this.$route.query.msg) this.$global.showToast('error', this.$t(this.$route.query.msg));
	},
	methods: {
		checkAccess() {
			if (this.accessCode.length === 0) this.$global.showToast('error', this.$t('paErrorEmptyCode'));
			else {
				this.$global.postData('access', '/checkAccess', { accessCode: this.accessCode }, null, (err, data) => {
					if (err) this.$global.showToast('error', this.$t('paErrorAccessDenied'));
					else {
						if (localStorage.getItem('cookiesAllowed')) localStorage.setItem('accessCode', this.accessCode);
						else sessionStorage.setItem('accessCode', this.accessCode);

						this.$router.push({ name: 'Home' });
					}
				});
			}
		},
	},
};
</script>

<style scoped>
.pa-wrap-content {
	text-align: center;
	margin-top: 5vh;
	overflow: auto;
}

.pa-wrap-content h1 {
	font-size: 100px;
	margin: 0px auto 5vh auto;
	text-shadow: 5px 5px var(--main-color-6);
	-webkit-text-stroke: 2px var(--main-color-text-dark);
}

.pa-wrap-input {
	width: fit-content;
	height: fit-content;
	text-align: start;
	margin: auto;
}

.pa-wrap-input label {
	font-size: 50px;
	margin: 20px auto;
}

.pa-wrap-input input {
	width: 700px;
	height: 50px;
	padding: 5px;
	display: block;
	margin: 20px auto;
	font-size: 30px;
}

.pa-wrap-input button {
	font-size: 30px;
	width: 400px;
	height: 50px;
}

@media screen and (max-width: 800px) {
	.pa-wrap-content h1 {
		font-size: 70px;
	}

	.pa-wrap-input {
		width: 90%;
	}

	.pa-wrap-input label {
		font-size: 30px;
	}

	.pa-wrap-input input {
		width: 700px;
		max-width: 90%;
		height: 40px;
		font-size: 25px;
		margin: 20px 0px;
	}

	.pa-wrap-input button {
		font-size: 25px;
		width: 300px;
		height: 40px;
	}
}

/* big landscape tablets, laptops, and desktops */
@media screen and (max-width: 1281px) {
	.pa-wrap-content {
		/* background-color: blue; */
	}
}

/* tablet, landscape iPad, lo-res laptops ands desktops */
@media screen and (max-width: 1025px) {
	.pa-wrap-content {
		/* background-color: orange; */
	}
}

/* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
@media screen and (max-width: 961px) {
	.pa-wrap-content {
		/* background-color: yellow; */
	}
}

/* smartphones, Android phones, landscape iPhone */
@media screen and (max-width: 641px) {
	.pa-wrap-content {
		/* background-color: green; */
	}
}

/* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
@media screen and (max-width: 481px) {
	.pa-wrap-content {
		/* background-color: red; */
	}
}
</style>
