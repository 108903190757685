<template>
	<div class="ho-wrap-content">
		<div class="ho-wrap-cards">
			<div v-if="$global.getUser()" class="ho-wrap-top-cards">
				<div class="ho-card ho-profile-card" @click="$router.push({ name: 'Profile' })">
					<div>
						<fai icon="fas fa-user" />
						<p>{{ $t('hoProfile') }}</p>
					</div>
				</div>
				<div class="ho-card ho-settings-card" @click="$router.push({ name: 'Settings' })">
					<div>
						<fai icon="fas fa-user-cog" />
						<p>{{ $t('hoSettings') }}</p>
					</div>
				</div>
				<div class="ho-card ho-logout-card" @click="logoutUser">
					<div>
						<fai icon="fas fa-sign-out-alt" />
						<p>{{ $t('hoLogout') }}</p>
					</div>
				</div>
			</div>
			<div class="ho-lobby-browser">
				<div class="ho-wrap-session-top-bar">
					<h2>{{ $t('hoLobbyBrowser') }}</h2>
					<div class="ho-wrap-session-filter">
						<FilterBar :type="'session'" :isLoading="isLoading" :amountElements="sessionAmount" @refresh="refreshBrowser" />
					</div>
				</div>
				<div class="ho-blur-ref">
					<div v-show="!firstItemVisible" class="ho-blur-before"></div>
					<div class="ho-wrap-sessions">
						<SessionPreview
							v-for="(session, idx) in sessions"
							:id="`session-${idx}`"
							:key="idx"
							:session="session"
							@showLogin="showLogin = true"
							@joinSession="checkPassword"
							@leaveSession="leaveSession"
							@deleteSession="deleteSession"
						/>
						<div v-if="sessions.length == 0 && !isLoading" class="ho-wrap-no-sessions">
							<div class="ho-wrap-refresh">
								<fai icon="fas fa-sad-tear" />
								<p class="ho-no-sessions">{{ $t('hoNoSessionFound') }}</p>
							</div>
						</div>
					</div>
					<div v-show="!lastItemVisible" class="ho-blur-after"></div>
				</div>
			</div>
			<div class="ho-wrap-bottom-cards">
				<div v-if="!$global.getUser()" class="ho-card ho-registration-card" @click="showRegistration = true">
					<div>
						<fai icon="fas fa-user-plus" />
						<p>{{ $t('hoRegistration') }}</p>
					</div>
				</div>
				<div v-if="!$global.getUser()" class="ho-card ho-login-card" @click="showLogin = true">
					<div>
						<fai icon="fas fa-sign-in-alt" />
						<p>{{ $t('hoLogin') }}</p>
					</div>
				</div>
				<div v-if="$global.getUser()" class="ho-card ho-quiz-night-card" @click="$router.push({ name: 'QuizNightConfiguration' })">
					<div>
						<fai icon="fas fa-cogs" />
						<p>{{ $t('hoQuizNight') }}</p>
					</div>
				</div>
				<div v-if="$global.getUser()" class="ho-card ho-lobby-creation-card" @click="$router.push({ name: 'LobbyCreation' })">
					<div>
						<fai icon="fas fa-couch" />
						<p>{{ $t('hoLobbyCreation') }}</p>
					</div>
				</div>
				<div v-if="$global.getUser()" class="ho-card ho-help-card" @click="$router.push({ name: 'Help' })">
					<div>
						<fai icon="fas fa-question-circle" />
						<p>{{ $t('hoHelp') }}</p>
					</div>
				</div>
			</div>
		</div>
		<transition name="zoom">
			<div v-if="showLogin || showRegistration" class="ho-center-login">
				<div class="ho-wrap-login">
					<div class="ho-login">
						<Login
							:showLogin="showLogin"
							:showRegistration="showRegistration"
							@closeLogin="
								showLogin = false;
								showRegistration = false;
							"
						/>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
import Login from '../components/login/Login';
import SessionPreview from '../components/lobby/SessionPreview';
import FilterBar from '../components/lobby/filter/FilterBar';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
	name: 'Home',
	components: {
		Login,
		SessionPreview,
		FilterBar,
		Loading,
	},
	data() {
		return {
			showLogin: false,
			showRegistration: false,
			sessions: [],
			sessionAmount: 0,
			isLoading: false,
			refreshClicked: false,
			sessionFilter: null,
			firstItemVisible: true,
			lastItemVisible: true,
		};
	},
	created() {
		let credentials = this.$global.getCredentials();
		if (credentials) this.$global.checkAccess(this.$router, true);
		else this.$global.checkAccess(this.$router, false);
	},
	mounted() {
		document.querySelector('.ho-wrap-sessions').addEventListener('scroll', this.scrollListener);
	},
	beforeDestroy() {
		document.querySelector('.ho-wrap-sessions').removeEventListener('scroll', this.scrollListener);
	},
	methods: {
		scrollListener(e) {
			this.firstItemVisible = this.itemVisible(true);
			this.lastItemVisible = this.itemVisible(false);
		},
		itemVisible(first) {
			const item = document.getElementById(`session-${first ? 0 : this.sessions.length - 1}`);
			const container = document.querySelector('.ho-wrap-sessions');
			if (item && container) {
				const containerRect = container.getBoundingClientRect();
				const itemRect = item.getBoundingClientRect();

				// Check if the item's top and bottom are within the container's visible area
				const isTopVisible = itemRect.top >= containerRect.top && itemRect.top <= containerRect.bottom;
				const isBottomVisible = itemRect.bottom >= containerRect.top && itemRect.bottom <= containerRect.bottom;

				return isTopVisible || isBottomVisible;
			} else if (this.sessions.length == 0) return true;
			else return false;
		},
		loadSessions() {
			let that = this;
			if (this.sessionFilter.shows.length == 0) {
				this.$global.showToast('warn', this.$t('hoNoShowsSelected'));
				this.refreshClicked = false;
			} else {
				this.isLoading = true;
				let user = this.$global.getUser();
				this.$global.getData(
					'session',
					`/getSessions`,
					{
						headers: { pageauthheader: this.$global.getAccessCode() },
						params: {
							sessionFilter: this.sessionFilter != null ? JSON.stringify(this.sessionFilter) : null,
							uID: user ? user.uID : null,
						},
					},
					null,
					function (err, result) {
						if (err) that.$global.showToast('error', that.$t(err.response ? err.response.data.msg : 'hoQueryError'));
						else {
							that.sessions = result.sessions;
							that.sessionAmount = result.sessionAmount;
							that.$nextTick(() => {
								that.firstItemVisible = that.itemVisible(true);
								that.lastItemVisible = that.itemVisible(false);
							});
						}
						that.isLoading = false;
						that.refreshClicked = false;
					}
				);
			}
		},
		checkPassword(session) {
			if (session.password) {
				let that = this;
				this.$global.showPopup(
					this.$t('hoPassword'),
					this.$t('hoPasswordText'),
					'info',
					{ text: this.$t('hoCancel'), color: 'var(--main-color-4)' },
					{ text: this.$t('hoEnter'), color: 'var(--main-color-success)' },
					{ type: 'password', placeholder: this.$t('hoPassword') },
					function (result) {
						if (result.isConfirmed) {
							if (result.value == session.password) that.joinSession(session);
							else that.$global.showToast('error', that.$t('hoWrongPassword'));
						}
					}
				);
			} else this.joinSession(session);
		},
		joinSession(session) {
			let that = this;
			this.$global.postData(
				'session',
				'/joinSession',
				{
					uID: this.$global.getUser().uID,
					sID: session.sID,
				},
				{
					headers: { pageauthheader: this.$global.getAccessCode() },
					auth: this.$global.getCredentials(),
				},
				(err, data) => {
					if (err) {
						that.$global.showToast('error', that.$t(err.response ? err.response.data.msg : 'hoJoinError'));
						that.loadSessions();
					} else {
						that.$socket.emitEvent('join_session', { sID: session.sID, uID: that.$global.getUser().uID });
						// prettier-ignore
						that.$global.addHistoryEntry(session.sID, that.$global.getUser().uID, null, null, 'joinSession', that.$global.getUser().name, null, "", function (err) {
							if (err) console.log(err);
						});
						that.$router.push({ name: 'Lobby', params: { sID: session.sID, state: session.state } });
					}
				}
			);
		},
		deleteSession(sID) {
			let that = this;
			this.$global.showPopup(
				this.$t('hoDeleteSession'),
				this.$t('hoDeleteSessionText'),
				'warning',
				{ text: this.$t('hoCancel'), color: 'var(--main-color-4)' },
				{ text: this.$t('hoDelete'), color: 'var(--main-color-error)' },
				null,
				function (result) {
					if (result.isConfirmed) {
						that.$global.deleteData(
							'session',
							'/deleteSession',
							{
								headers: { pageauthheader: that.$global.getAccessCode() },
								auth: that.$global.getCredentials(),
								params: { uID: that.$global.getUser().uID, sID: sID },
							},
							null,
							function (err, result) {
								if (err) that.$global.showToast('error', that.$t(err.response ? err.response.data.msg : 'hoDeleteError'));
								else {
									that.$global.showToast('success', that.$t('hoDeleteSuccess'));
									that.$socket.emitEvent('closed_session', sID);
									that.sessions = that.sessions.filter((s) => s.sID !== sID);
								}
							}
						);
					}
				}
			);
		},
		leaveSession(sID, uID) {
			let that = this;
			this.$global.showPopup(
				this.$t('hoLeaveSession'),
				this.$t('hoLeaveSessionText'),
				'warning',
				{ text: this.$t('hoCancel'), color: 'var(--main-color-4)' },
				{ text: this.$t('hoLeave'), color: 'var(--main-color-error)' },
				null,
				function (result) {
					if (result.isConfirmed) {
						that.$socket.emitEvent('leave_session', {
							sID: sID,
							uID: uID,
							state: null,
							host: null,
							byChoice: true,
						});
						that.refreshBrowser(that.sessionFilter);
					}
				}
			);
		},
		refreshBrowser(sessionFilter) {
			if (!this.refreshClicked) {
				this.refreshClicked = true;
				this.sessionFilter = sessionFilter;
				this.sessions = [];
				this.loadSessions();
			}
		},
		logoutUser() {
			this.$global.logoutUser();
			location.reload();
		},
	},
};
</script>

<style scoped>
.ho-wrap-content {
	width: 100%;
	height: 100%;
	position: relative;
}

.ho-wrap-cards {
	width: 100%;
	height: 100%;
	padding: 40px;
	box-sizing: border-box;
	overflow-x: hidden;
	overflow-y: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow: column;
}

.ho-wrap-top-cards,
.ho-wrap-bottom-cards {
	width: 100%;
	display: flex;
	justify-content: center;
	flex-flow: row;
	align-items: center;
}

.ho-lobby-browser {
	width: calc(100% - 10px);
	height: 80vh;
	min-height: 300px;
	flex: 1 1 100%;
	margin: 5px;
	padding: 10px;
	box-sizing: border-box;
	border: 2px solid var(--main-color-border-dark);
	background-color: var(--main-color-3-cc);
}

.ho-wrap-session-top-bar {
	width: 100%;
	height: 160px;
	text-align: center;
}

.ho-wrap-session-top-bar h2 {
	height: 40px;
	margin: 0px auto;
	padding: 0px 0px 10px 0px;
	font-size: 35px;
	text-shadow: 2px 2px var(--main-color-6);
	-webkit-text-stroke: 2px var(--main-color-text-dark);
}

.ho-wrap-session-top-bar h2 svg {
	margin: 5px;
	font-size: 45px;
	color: var(--main-color-text-light);
	filter: drop-shadow(2px 2px 2px var(--main-color-border-dark));
}

.ho-wrap-session-filter {
	width: 100%;
	height: 100px;
	margin-right: 15px;
	box-sizing: border-box;
	padding: 0px 5px 0px 0px;
	overflow-y: auto;
	overflow-x: hidden;
	border-bottom: 1px solid var(--main-color-border-light);
}

.ho-blur-ref {
	width: 100%;
	height: calc(100% - 150px);
	position: relative;
}

.ho-wrap-sessions {
	width: 100%;
	height: 100%;
	padding: 00px 10px;
	box-sizing: border-box;
	overflow: auto;
}

.ho-blur-before,
.ho-blur-after {
	position: absolute;
	left: 8px;
	width: calc(100% - 30px);
	margin: auto;
	height: 60px;
	backdrop-filter: blur(1px);
	z-index: 10;
	pointer-events: none;
}

.ho-blur-before {
	top: 0px;
	background: linear-gradient(to bottom, #00000033, #00000000);
}

.ho-blur-after {
	bottom: 0;
	background: linear-gradient(to top, #00000033, #00000000);
}

.ho-card {
	flex: 1 1 50%;
	height: 10vh;
	min-height: 100px;
	margin: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: transform 0.5s;
	border: 2px solid var(--main-color-border-dark);
}

.ho-card:hover {
	cursor: pointer;
	transform: scale(1.1);
	z-index: 5;
}

.ho-card div {
	text-align: center;
}

.ho-card svg {
	margin: 5px;
	font-size: 45px;
	color: var(--main-color-text-light);
	filter: drop-shadow(2px 2px 2px var(--main-color-border-dark));
}

.ho-card p {
	font-size: 20px;
	text-shadow: 1px 1px var(--main-color-text-dark);
}

.ho-profile-card {
	background-color: var(--main-color-purple-cc);
}
.ho-settings-card {
	background-color: var(--main-color-6-cc);
}
.ho-logout-card {
	background-color: var(--main-color-error-cc);
}
.ho-help-card {
	background-color: #202225cc;
}
.ho-registration-card {
	background-color: var(--main-color-info-cc);
}
.ho-login-card {
	background-color: var(--main-color-success-cc);
}
.ho-quiz-night-card {
	background-color: var(--main-color-info-cc);
}
.ho-lobby-creation-card {
	background-color: var(--main-color-success-cc);
}

.ho-center-login {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0px;
	left: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 2;
	background-color: var(--main-color-dark-transparent-80);
}

.ho-wrap-login {
	width: fit-content;
	max-width: 90vw;
	height: fit-content;
	max-height: 90vh;
	border: 2px solid var(--main-color-border-light);
	border-radius: 20px;
	background-color: var(--main-color-4);
	overflow: hidden;
}

.ho-login {
	width: 100%;
	max-width: 90vw;
	height: 100%;
	max-height: 90vh;
	overflow: auto;
}

.zoom-enter-active,
.zoom-leave-active {
	transition: transform 0.3s ease;
	background-color: var(--main-color-dark-transparent);
}

.zoom-enter,
.zoom-leave-to {
	transform: scale(0);
}

.ho-wrap-no-sessions {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ho-wrap-refresh {
	text-align: center;
	font-size: 30px;
}

.ho-wrap-refresh svg {
	margin: 10px;
	font-size: 60px;
	filter: drop-shadow(2px 2px 2px var(--main-color-border-dark));
	color: var(--main-color-6);
}
</style>
