<template>
	<div class="lhh-wrap-content">
		<p>
			{{ $t('lhhLobbyCreationIntro') }}
			<span v-if="$global.getUser()">
				(<router-link :to="{ name: 'LobbyCreation' }">{{ $t('lhhLobbyCreation') }}</router-link
				>)
			</span>
		</p>
		<p v-html="$t('lhhLobbyCreationText')"></p>
		<img :src="require(`@/assets/images/help/lobby_creation.png`)" :alt="$t('lhhLobbyCreationText')" class="lhh-single-img" />
		<p v-html="$t('lhhLobbyWaitingRoomCreated')"></p>
		<img :src="require(`@/assets/images/help/lobby_waiting_room_created.png`)" :alt="$t('lhhLobbyWaitingRoomCreated')" class="lhh-single-img" />
		<p v-html="$t('lhhLobbyWaitingRoom')"></p>
		<img :src="require(`@/assets/images/help/lobby_waiting_room.png`)" :alt="$t('lhhLobbyWaitingRoom')" class="lhh-single-img" />
		<p v-html="$t('lhhLobbyHostingLeaderboard')"></p>
		<img :src="require(`@/assets/images/help/lobby_hosting_leaderboard.png`)" :alt="$t('lhhLobbyHostingLeaderboard')" class="lhh-single-img" />
		<p v-html="$t('lhhLobbyHostingCurrentShow')"></p>
		<img :src="require(`@/assets/images/help/lobby_hosting_current_show.png`)" :alt="$t('lhhLobbyHostingCurrentShow')" class="lhh-single-img" />
		<p v-html="$t('lhhLobbyHostingHistory')"></p>
		<img :src="require(`@/assets/images/help/lobby_hosting_history.png`)" :alt="$t('lhhLobbyHostingHistory')" class="lhh-single-img" />
		<p v-html="$t('lhhLobbyHostingHostTools')"></p>
		<img :src="require(`@/assets/images/help/lobby_hosting_host_tools.png`)" :alt="$t('lhhLobbyHostingHostTools')" class="lhh-single-img" />
		<p v-html="$t('lhhLobbyHostingBugReport')"></p>
		<img :src="require(`@/assets/images/help/lobby_hosting_bug_report.png`)" :alt="$t('lhhLobbyHostingBugReport')" class="lhh-single-img" />
		<p v-html="$t('lhhLobbyShowControl')"></p>
		<img :src="require(`@/assets/images/help/lobby_hosting_host_control.png`)" :alt="$t('lhhLobbyShowControl')" class="lhh-single-img" />
		<p v-html="$t('lhhLobbyAFK')"></p>
		<img :src="require(`@/assets/images/help/lobby_hosting_afk.png`)" :alt="$t('lhhLobbyAFK')" class="lhh-single-img" />
		<p v-html="$t('lhhSelectionOfShows')"></p>
		<div class="lhh-wrap-shows">
			<ShowSelection :shows="shows" :selectedShow="shows[0].abbreviation" :noSelect="true" @selectShow="selectShow" />
		</div>
		<div class="lhh-wrap-slideshow">
			<div v-if="selectedSlide" class="lhh-selected-show">
				<h3>{{ $t(selectedSlide.alt) }}</h3>
				<div v-for="(image, imageIdx) in selectedSlide.images" :key="imageIdx" class="lhh-slide">
					<img :src="require(`@/assets/images/help/${image.src}`)" :alt="$t(selectedSlide.alt)" />
					<p v-html="$t(image.desc)"></p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
/**
 * @group Help
 * LobbyHostingHelp
 */
import ShowSelection from '../quizNightCreation/ShowSelection.vue';
export default {
	name: 'LobbyHostingHelp',
	components: {
		ShowSelection,
	},
	props: {
		shows: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			selectedSlide: {},
			slides: [
				{ show: 'DPIN', alt: 'lhhDPINAlt', images: [{ src: 'lobby_hosting_dpin.png', desc: 'lhhDPINDesc' }] },
				{ show: 'XFZ', alt: 'lhhXFZAlt', images: [{ src: 'lobby_hosting_xfz.png', desc: 'lhhXFZDesc' }] },
				{
					show: 'WLP',
					alt: 'lhhWLPAlt',
					images: [
						{ src: 'lobby_hosting_wlp.png', desc: 'lhhWLPDesc1' },
						{ src: 'lobby_hosting_wlp2.png', desc: 'lhhWLPDesc2' },
					],
				},
				{
					show: 'SMF',
					alt: 'lhhSMFAlt',
					images: [
						{ src: 'lobby_hosting_smf.png', desc: 'lhhSMFDesc1' },
						{ src: 'lobby_hosting_smf2.png', desc: 'lhhSMFDesc2' },
					],
				},
				{ show: 'KQ', alt: 'lhhKQAlt', images: [{ src: 'lobby_hosting_kq.png', desc: 'lhhKQDesc' }] },
				{
					show: 'ZGW',
					alt: 'lhhZGWAlt',
					images: [
						{ src: 'lobby_hosting_zgw.png', desc: 'lhhZGWDesc1' },
						{ src: 'lobby_hosting_zgw2.png', desc: 'lhhZGWDesc2' },
						{ src: 'lobby_hosting_zgw3.png', desc: 'lhhZGWDesc3' },
						{ src: 'lobby_hosting_zgw4.png', desc: 'lhhZGWDesc4' },
					],
				},
			],
		};
	},
	mounted() {
		this.selectedSlide = this.slides.filter((slide) => slide.show == this.shows[0].abbreviation.toUpperCase())[0];
	},
	methods: {
		selectShow(show) {
			let slide = this.slides.filter((slide) => slide.show == show.toUpperCase())[0];
			if (slide) this.selectedSlide = slide;
		},
	},
};
</script>

<style scoped>
.lhh-wrap-content p {
	width: 100%;
	margin: 2px 0px;
	font-size: 25px;
	text-align: start;
	color: var(--main-color-text-light);
	filter: drop-shadow(5px 5px 5px var(--main-color-text-dark));
}

.lhh-single-img {
	max-width: 90vw;
	max-height: 650px;
	margin: 20px auto;
	box-sizing: border-box;
	border: 2px solid var(--main-color-border-dark);
	box-shadow: 0px 0px 20px 2px var(--main-color-border-dark);
}

.lhh-wrap-content a {
	text-decoration: underline;
	text-decoration-color: var(--main-color-text-light);
	color: var(--main-color-text-light);
}

.lhh-wrap-content a:hover {
	color: var(--main-color-6);
	text-decoration-color: var(--main-color-6);
}

.lhh-selected-show {
	width: 100%;
	margin: 10px 0px 20px 0px;
	text-align: start;
}

.lhh-selected-show h3 {
	font-size: 35px;
	padding: 20px 0px;
	text-shadow: 2px 2px var(--main-color-6);
	-webkit-text-stroke: 2px var(--main-color-text-dark);
	text-align: center;
}

.lhh-selected-show p {
	font-size: 25px;
	text-align: center;
}

.lhh-wrap-shows {
	margin: 10px 0px;
}

.lhh-slideshow {
	margin-bottom: 0px;
	padding: 10px;
	box-sizing: border-box;
	border-radius: 50px;
}

.lhh-slide {
	height: fit-content;
	/* max-height: 700px; */
	margin: 10px 0px 40px 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	flex-flow: wrap;
	opacity: 1 !important;
}

.lhh-slide img {
	max-height: 650px;
	max-width: calc(90vw - 40px);
	margin: 20px;
	box-sizing: border-box;
	display: block;
	border: 2px solid var(--main-color-border-dark);
	box-shadow: 0px 0px 20px 2px var(--main-color-border-dark);
}

.lhh-slide p {
	text-align: start;
}

.lhh-slide-thumbnail {
	height: 110px;
	padding: 5px;
	transition: opacity 0.3s;
	cursor: pointer;
	border: none;
}

.lhh-slide-thumbnail img {
	max-height: 100%;
	max-width: 100%;
	margin: 0px;
	border: 1px solid var(--main-color-border-dark);
	box-shadow: none;
}

.lhh-slide-thumbnail:hover {
	opacity: 0.75;
}

.lhh-thumbnails {
	margin: 0 -5px;
}

.lhh-selected-img img {
	box-sizing: border-box;
	border: 2px solid var(--main-color-6);
}
</style>
