<template>
	<div class="qnch-wrap-content">
		<p>
			{{ $t('qnchQuizNightCreationIntro1') }}

			<span v-if="$global.getUser()">
				(<router-link :to="{ name: 'QuizNightConfigurationOverview' }">{{ $t('qnchQuizNightCreation') }}</router-link
				>)
			</span>
		</p>
		<p>{{ $t('qnchSelectionOfShows') }}</p>
		<div class="qnch-wrap-shows">
			<ShowSelection :shows="shows" :selectedShow="shows[0].abbreviation" :noSelect="true" @selectShow="selectShow" />
		</div>
		<p>{{ $t('qnchQuizNightCreationText') }}</p>
		<div class="qnch-wrap-slideshow">
			<div v-if="selectedSlide" class="qnch-selected-show">
				<h3>{{ $t(selectedSlide.alt) }}</h3>
				<p v-html="$t(selectedSlide.desc)"></p>
			</div>
			<div class="qnch-slideshow">
				<div class="qnch-slide" v-for="(slide, idx) in slides" :key="idx" :class="`slide--${idx}`">
					<img v-if="selectedSlide.src == slide.src" :src="require(`@/assets/images/help/${slide.src}`)" :alt="$t(slide.alt)" />
				</div>
			</div>
		</div>
		<p>{{ $t('qnchQuizNightManagement') }}</p>
		<img :src="require(`@/assets/images/help/quiz_night_creation_management.png`)" :alt="$t('qnchQuizNightManagement')" class="qnch-single-img" />
	</div>
</template>

<script>
import ShowSelection from '../quizNightCreation/ShowSelection.vue';
export default {
	name: 'QuizNightCreationHelp',
	components: {
		ShowSelection,
	},
	props: {
		shows: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			selectedSlide: {},
			slides: [
				{ show: 'DPIN', src: 'dpin_quiz_night_creation.png', alt: 'qnchDPINAlt', desc: 'qnchDPINDesc' },
				{ show: 'XFZ', src: 'xfz_quiz_night_creation.png', alt: 'qnchXFZAlt', desc: 'qnchXFZDesc' },
				{ show: 'WLP', src: 'wlp_quiz_night_creation.png', alt: 'qnchWLPAlt', desc: 'qnchWLPDesc' },
				{ show: 'SMF', src: 'smf_quiz_night_creation.png', alt: 'qnchSMFAlt', desc: 'qnchSMFDesc' },
				{ show: 'KQ', src: 'kq_quiz_night_creation.png', alt: 'qnchKQAlt', desc: 'qnchKQDesc' },
				{ show: 'ZGW', src: 'zgw_quiz_night_creation.png', alt: 'qnchZGWAlt', desc: 'qnchZGWDesc' },
			],
		};
	},
	mounted() {
		this.selectedSlide = this.slides.filter((slide) => slide.show == this.shows[0].abbreviation.toUpperCase())[0];
	},
	methods: {
		selectSlide(slide, idx) {
			this.selectedSlide = slide;
		},
		selectShow(show) {
			let slide = this.slides.filter((slide) => slide.show == show.toUpperCase())[0];
			if (slide) this.selectedSlide = slide;
		},
	},
};
</script>

<style scoped>
.qnch-wrap-content p {
	width: 100%;
	margin: 2px 0px;
	font-size: 25px;
	text-align: start;
	color: var(--main-color-text-light);
}

.qnch-single-img {
	max-width: 90vw;
	max-height: 650px;
	margin: 20px auto;
	box-sizing: border-box;
	border: 2px solid var(--main-color-border-dark);
	box-shadow: 0px 0px 20px 2px var(--main-color-border-dark);
}

.qnch-wrap-content a {
	text-decoration: underline;
	text-decoration-color: var(--main-color-text-light);
	color: var(--main-color-text-light);
}

.qnch-wrap-content a:hover {
	color: var(--main-color-6);
	text-decoration-color: var(--main-color-6);
}

.qnch-wrap-slideshow {
	margin: 20px auto 40px auto;
}

.qnch-selected-show {
	width: 100%;
	margin: 10px 0px 20px 0px;
	text-align: start;
}

.qnch-selected-show h3 {
	font-size: 35px;
	padding: 20px 0px;
	text-shadow: 2px 2px var(--main-color-6);
	-webkit-text-stroke: 2px var(--main-color-text-dark);
	text-align: center;
}

.qnch-selected-show p {
	font-size: 25px;
}

.qnch-wrap-shows {
	margin: 10px 0px;
}

.qnch-slideshow {
	margin-bottom: 0px;
	padding: 10px;
	box-sizing: border-box;
	border-radius: 50px;
}

.qnch-slide {
	height: fit-content;
	max-height: 700px;
	display: flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	opacity: 1 !important;
}

.qnch-slide img {
	max-height: 650px;
	max-width: calc(90vw - 40px);
	margin: 20px;
	box-sizing: border-box;
	border: 2px solid var(--main-color-border-dark);
	box-shadow: 0px 0px 20px 2px var(--main-color-border-dark);
}

.qnch-slide-thumbnail {
	height: 110px;
	padding: 5px;
	transition: opacity 0.3s;
	cursor: pointer;
	border: none;
}

.qnch-slide-thumbnail img {
	max-height: 100%;
	max-width: 100%;
	margin: 0px;
	border: 1px solid var(--main-color-border-dark);
	box-shadow: none;
}

.qnch-slide-thumbnail:hover {
	opacity: 0.75;
}

.qnch-thumbnails {
	margin: 0 -5px;
}

.qnch-selected-img img {
	box-sizing: border-box;
	border: 2px solid var(--main-color-6);
}
</style>
