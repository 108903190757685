<template>
	<div class="ln-wrap-content">
		<a name="Impressum"></a>
		<h1>{{ $t('lnLegalNotice') }}</h1>
		<div class="ln-wrap-legal-notice">
			<p>{{ $t('lnDetails') }}</p>
			<p>
				<span>Philip Storz</span> <br />
				<span>Zum Schelmenwasen 14, 72768 Reutlingen</span> <br />
				<strong>{{ $t('lnContact') }}:</strong> <br />
				{{ $t('lnEmail') }}: <a href="mailto:philipstorz@web.de" target="_blank">philipstorz@web.de</a> <br />
			</p>

			<h2>{{ $t('lnDisclaimer') }}</h2>

			<h3>{{ $t('lnContent') }}</h3>
			<p>{{ $t('lnContentText') }}</p>

			<h3>{{ $t('lnLinks') }}</h3>
			<p>{{ $t('lnLinksText') }}</p>

			<h3>{{ $t('lnCopyright') }}</h3>
			<p>{{ $t('lnCopyrightText') }}</p>
		</div>
		<a name="Datenschutz"></a>
		<h1>{{ $t('lnDataPrivacy') }}</h1>
		<div class="ln-wrap-legal-notice">
			<p>{{ $t('lnDataPrivacyText') }}</p>
			<strong>
				<p>{{ $t('lnDataPrivacyContactTitle') }}</p>
			</strong>
			<p>{{ $t('lnDataPrivacyContactText') }}</p>
			<p>Philip Storz</p>
			<p>{{ $t('lnEmail') }}: <a href="mailto:philipstorz@web.de" target="_blank">philipstorz@web.de</a> <br /></p>

			<h2>{{ $t('lnDataProcessingTitle') }}</h2>
			<p>{{ $t('lnDataProcessingText') }}</p>

			<h3>{{ $t('lnDataProcessingProcessedDataTitle') }}</h3>
			<p>{{ $t('lnDataProcessingProcessedDataText') }}</p>

			<h3>{{ $t('lnDataProcessingPurposeTitle') }}</h3>
			<p>{{ $t('lnDataProcessingPurposeText') }}</p>

			<h3>{{ $t('lnDataProcessingUserTitle') }}</h3>
			<p>{{ $t('lnDataProcessingUserText1') }}</p>
			<p>{{ $t('lnDataProcessingUserText2') }}</p>

			<h2>{{ $t('lnLegalBasisProcessingTitle') }}</h2>
			<p>{{ $t('lnLegalBasisProcessingText') }}</p>
			<ol>
				<li>{{ $t('lnLegalBasisProcessingList1') }}</li>
				<li>{{ $t('lnLegalBasisProcessingList2') }}</li>
				<li>{{ $t('lnLegalBasisProcessingList3') }}</li>
				<li>{{ $t('lnLegalBasisProcessingList4') }}</li>
				<li>{{ $t('lnLegalBasisProcessingList5') }}</li>
			</ol>

			<h2>{{ $t('lnTransmissionOfDataTitle') }}</h2>
			<p>{{ $t('lnTransmissionOfDataText1') }}</p>
			<p>{{ $t('lnTransmissionOfDataText2') }}</p>

			<h2>{{ $t('lnTransmissionThirdCountriesTitle') }}</h2>
			<p>{{ $t('lnTransmissionThirdCountriesText1') }}</p>
			<p>{{ $t('lnTransmissionThirdCountriesText2') }}</p>

			<h2>{{ $t('lnDeletionOfDataTitle') }}</h2>
			<p>{{ $t('lnDeletionOfDataText') }}</p>

			<h2>{{ $t('lnAutomatedDecisionMakingTitle') }}</h2>
			<p>{{ $t('lnAutomatedDecisionMakingText') }}</p>

			<h2>{{ $t('lnWebsiteAndLogFilesTitle') }}</h2>
			<ol>
				<li>{{ $t('lnWebsiteAndLogFilesList1') }}</li>
				<div>
					<ul>
						<li>{{ $t('lnWebsiteAndLogFilesList1_1') }}</li>
						<li>{{ $t('lnWebsiteAndLogFilesList1_2') }}</li>
						<li>{{ $t('lnWebsiteAndLogFilesList1_3') }}</li>
						<li>{{ $t('lnWebsiteAndLogFilesList1_4') }}</li>
						<li>{{ $t('lnWebsiteAndLogFilesList1_5') }}</li>
						<li>{{ $t('lnWebsiteAndLogFilesList1_6') }}</li>
						<li>{{ $t('lnWebsiteAndLogFilesList1_7') }}</li>
						<li>{{ $t('lnWebsiteAndLogFilesList1_8') }}</li>
						<li>{{ $t('lnWebsiteAndLogFilesList1_9') }}</li>
						<li>{{ $t('lnWebsiteAndLogFilesList1_10') }}</li>
						<li>{{ $t('lnWebsiteAndLogFilesList1_11') }}</li>
					</ul>
					<p>{{ $t('lnWebsiteAndLogFilesText1') }}</p>
				</div>
				<li>{{ $t('lnWebsiteAndLogFilesList2') }}</li>
				<li>{{ $t('lnWebsiteAndLogFilesList3') }}</li>
				<li>{{ $t('lnWebsiteAndLogFilesList4') }}</li>
			</ol>

			<h2>{{ $t('lnContactingTitle') }}</h2>
			<ol>
				<li>{{ $t('lnContactingList1') }}</li>
				<li>{{ $t('lnContactingList2') }}</li>
				<li>{{ $t('lnContactingList3') }}</li>
				<li>{{ $t('lnContactingList4') }}</li>
				<li>{{ $t('lnContactingList5') }}</li>
			</ol>

			<h2>{{ $t('lnSocialMediaTitle') }}</h2>
			<p>{{ $t('lnSocialMediaText') }}</p>

			<h2>{{ $t('lnRightsOfConcernedUserTitle') }}</h2>

			<h3>{{ $t('lnRightsOfConcernedUserSection1Title') }}</h3>
			<p>{{ $t('lnRightsOfConcernedUserSection1Text1') }}</p>
			<p>{{ $t('lnRightsOfConcernedUserSection1Text2') }}</p>
			<p>{{ $t('lnRightsOfConcernedUserSection1Text3') }}</p>
			<p>Philip Storz</p>
			<p>{{ $t('lnEmail') }}: <a href="mailto:philipstorz@web.de" target="_blank">philipstorz@web.de</a> <br /></p>

			<h3>{{ $t('lnRightsOfConcernedUserSection2Title') }}</h3>
			<p>{{ $t('lnRightsOfConcernedUserSection2Text') }}</p>

			<h3>{{ $t('lnRightsOfConcernedUserSection3Title') }}</h3>
			<p>{{ $t('lnRightsOfConcernedUserSection3Text') }}</p>

			<h3>{{ $t('lnRightsOfConcernedUserSection4Title') }}</h3>
			<p>{{ $t('lnRightsOfConcernedUserSection4Text') }}</p>

			<h3>{{ $t('lnRightsOfConcernedUserSection5Title') }}</h3>
			<p>{{ $t('lnRightsOfConcernedUserSection5Text') }}</p>
			<ul>
				<li>{{ $t('lnRightsOfConcernedUserSection5List1') }}</li>
				<li>{{ $t('lnRightsOfConcernedUserSection5List2') }}</li>
			</ul>

			<h3>{{ $t('lnRightsOfConcernedUserSection6Title') }}</h3>
			<p>{{ $t('lnRightsOfConcernedUserSection6Text') }}</p>

			<h3>{{ $t('lnRightsOfConcernedUserSection7Title') }}</h3>
			<p>{{ $t('lnRightsOfConcernedUserSection7Text') }}</p>

			<h2>{{ $t('lnDataSecurityTitle') }}</h2>
			<p>{{ $t('lnDataSecurityText') }}</p>
		</div>
	</div>
</template>

<script>
export default {
	name: 'LegalNotice',
};
</script>

<style scoped>
.ln-wrap-content {
	width: 100%;
	height: 100%;
	padding: 0px 5% 30px 5%;
	box-sizing: border-box;
	overflow: auto;
}

.ln-wrap-legal-notice {
	padding: 20px;
	box-sizing: border-box;
	border: 2px solid var(--main-color-border-dark);
	border-radius: 20px;
	background-color: var(--main-color-4-cc);
}

.ln-wrap-legal-notice h2 {
	font-size: 25px;
	margin: 30px 0px 10px 0px;
	text-align: start;
	filter: drop-shadow(2px 2px 2px var(--main-color-border-dark));
}

.ln-wrap-legal-notice h3 {
	font-size: 20px;
	margin: 20px 0px 5px 0px;
	text-align: start;
	filter: drop-shadow(1px 1px 1px var(--main-color-border-dark));
}

.ln-wrap-legal-notice p {
	font-size: 18px;
	margin: 10px 0px;
	text-align: start;
}

.ln-wrap-legal-notice a {
	font-size: 18px;
	text-decoration: underline;
	color: var(--main-color-6);
}

.ln-wrap-legal-notice ol,
.ln-wrap-legal-notice ul {
	margin: 5px 0px 5px 20px;
	font-size: 18px;
	color: var(--main-color-text-light);
}

.ln-wrap-legal-notice li {
	margin: 0px 0px 10px 0px;
}
</style>
