<template>
	<div class="uc-wrap-content">
		<div class="uc-top-bar" @click="toggleUserTools">
			<fai icon="fas fa-chevron-down" :class="[userToolsExpanded ? 'uc-normal' : 'uc-rotated']" />
		</div>
		<div v-if="showConfirmation" class="uc-confirmation-window">
			<div v-if="showConfirmation == 'lockAnswer'">
				<p>{{ $t('ucLockAnswer') }}?</p>
				<em>{{ $t('ucLockAnswerText') }}</em>
				<button id="ucYesBtn" class="app-success-btn" @click="lockAnswer($event, true)">
					{{ $t('ucYes') }}
				</button>
				<button class="app-error-btn" @click="lockAnswer($event, false)">{{ $t('ucNo') }}</button>
			</div>
			<div v-if="showConfirmation == 'lockChoice'">
				<p>{{ $t('ucLockChoice') }}</p>
				<em>{{ $t('ucLockChoiceText') }}</em>
				<button class="app-success-btn" @click="lockChoice($event, selectedChoice, true)">{{ $t('ucYes') }}</button>
				<button class="app-error-btn" @click="lockChoice($event, selectedChoice, false)">{{ $t('ucNo') }}</button>
			</div>
			<div v-if="showConfirmation == 'bid'">
				<p>{{ $t('ucSetBid') }}</p>
				<em>{{ $t('ucSetBidText') }}</em>
				<button class="app-success-btn" @click="sendBid($event, true)">
					{{ $t('ucYes') }}
				</button>
				<button class="app-error-btn" @click="sendBid($event, false)">
					{{ $t('ucNo') }}
				</button>
			</div>
			<div v-if="showConfirmation == 'lockBid'">
				<p>{{ $t('ucLockBid') }}</p>
				<em>{{ $t('ucLockBidText') }}</em>
				<button class="app-success-btn" @click="lockBid($event, true)">
					{{ $t('ucYes') }}
				</button>
				<button class="app-error-btn" @click="lockBid($event, false)">
					{{ $t('ucNo') }}
				</button>
			</div>
			<div v-if="showConfirmation == 'lockTerms'">
				<p>{{ $t('ucLockTopicTerms') }}</p>
				<em>{{ $t('ucLockTopicTermsText') }}</em>
				<button class="app-success-btn" @click="lockTerms($event, true)">
					{{ $t('ucYes') }}
				</button>
				<button class="app-error-btn" @click="lockTerms($event, false)">
					{{ $t('ucNo') }}
				</button>
			</div>
			<div v-if="showConfirmation == 'useJoker'">
				<p>{{ $t('ucUseJoker') }}</p>
				<em>{{ $t('ucUseJokerText') }}</em>
				<button class="app-success-btn" @click="useJoker($event, true)">
					{{ $t('ucYes') }}
				</button>
				<button class="app-error-btn" @click="useJoker($event, false)">
					{{ $t('ucNo') }}
				</button>
			</div>
		</div>
		<div v-show="userToolsExpanded" class="uc-user-tools">
			<div v-if="getFreetextConditions()" class="uc-text-answer">
				<input
					type="text"
					:disabled="answer.lockAnswer"
					v-model="answer.textAnswer"
					:placeholder="$t('ucEnterAnswer')"
					@input="$emit('giveTextAnswer', { uID: $global.getUser().uID, ...answer })"
					@keyup.enter="setShowConfirmation('lockAnswer')"
				/>
				<button class="app-success-btn" @click="setShowConfirmation('lockAnswer')" :disabled="answer.lockAnswer" :title="$t('ucLockAnswer')">
					<fai icon="fas fa-lock" />
				</button>
			</div>
			<div v-else-if="getBuzzerConditions()" class="uc-buzzer-answer">
				<Buzzer :buzzerUser="buzzerUser" :buzzerAnimation="buzzerAnimation" @buzzerPressed="$emit('buzzerPressed')" />
			</div>
			<div v-else-if="getSingleChoiceConditions()" class="uc-single-choice">
				<div>
					<button
						v-for="answer in currentQuestion.answerPos"
						:key="answer.pos"
						@click="selectedChoice = answer.pos"
						:class="[selectedChoice == answer.pos ? 'app-success-btn' : 'app-default-btn']"
					>
						{{ String.fromCharCode(97 + answer.pos) }})
					</button>
				</div>
				<button class="app-success-btn" @click="showConfirmation = 'lockChoice'" :disabled="answer.lockAnswer" :title="$t('ucLockChoice')">
					<fai icon="fas fa-lock" />
				</button>
			</div>
			<div v-else-if="!wlpState && !getZGWConditions() && !getKQConditions()" class="uc-no-tools">
				<p>{{ $t('ucNoToolsAvailable') }}</p>
			</div>
			<div v-if="show.show == 'wlp' && wlpState">
				<div v-if="!wlpState.revealState" class="uc-wrap-bid">
					<div class="uc-own-bid">
						<input :disabled="disableBid()" type="number" v-model.number="bid" :min="getMinBid()" :max="getMaxBid()" @change="checkBid" />
						<p>
							{{ wlpState.users.filter((user) => user.uID == $global.getUser().uID)[0].money }}
							<fai icon="fas fa-coins"></fai>
						</p>
						<button :class="[disableBid() ? 'app-disabled-btn' : 'app-success-btn', 'uc-bid-btn']" @click="showConfirmation = 'bid'">
							<fai icon="fas fa-coins"></fai>
						</button>
						<button :class="[disableBid() ? 'app-disabled-btn' : 'app-warn-btn', 'uc-bid-btn']" @click="showConfirmation = 'lockBid'">
							<fai icon="fas fa-ban"></fai>
						</button>
					</div>
				</div>
				<div class="uc-wrap-users">
					<div
						v-for="user in getUsers()"
						:key="user.uID"
						class="uc-wrap-user"
						:title="!user.disableBid && !wlpState.revealState ? $t('ucCurrentBidder') : null"
					>
						<div :class="[!user.disableBid && !wlpState.revealState ? 'uc-active-bidder' : '', 'uc-user-info']">
							<img :src="require(`@/assets/images/profilePictures/${user.profilePic}`)" />
							<p>
								{{ user.name }}
								<fai
									v-if="user.lockBid && !wlpState.revealState"
									icon="fas fa-ban"
									:title="$t('ucUserLocked')"
									class="uc-lock-bid"
								></fai>
								<fai
									v-if="user.highestBid && !wlpState.revealState"
									icon="fas fa-chevron-circle-up"
									:title="$t('ucHighestBidder')"
									class="uc-highest-bid"
								></fai>
							</p>
						</div>
						<div v-if="!wlpState.revealState" class="uc-user-money">
							<p>{{ $t('ucBid') }} {{ user.bid }} <fai icon="fas fa-coins"></fai></p>
							<p>/</p>
							<p>{{ user.money }} <fai icon="fas fa-coins"></fai></p>
						</div>
						<div v-if="!wlpState.revealState" class="uc-user-items">
							<p>{{ $t('ucBoughtItems') }}</p>
							<div v-if="user.boughtItems.length > 0" class="uc-wrap-items">
								<div v-for="item in user.boughtItems" :key="item.qID" class="uc-item">
									<img :src="item.image" />
									<p>{{ item.bid }} <fai icon="fas fa-coins"></fai></p>
								</div>
							</div>
							<p v-else class="uc-no-items">{{ $t('ucNoItems') }}</p>
						</div>
						<div v-if="wlpState.revealState" class="uc-wrap-wlp-reveal">
							<p>{{ show.money }} <fai icon="fas fa-coins"></fai></p>
							<p v-for="state in getRevealedItems(user.uID)" :key="state.position" :class="getItemPriceDiff(state.diff)">
								{{ state.diff > 0 ? `+${state.diff}` : state.diff }} <fai icon="fas fa-coins"></fai>
							</p>
							<p :class="[getItemPriceDiff(getUserMoneyDiff(user.uID) - show.money), 'uc-diff']">
								{{ getUserMoneyDiff(user.uID) }} <fai icon="fas fa-coins"></fai>
							</p>
						</div>
					</div>
				</div>
			</div>
			<div v-if="show.show == 'zgw'">
				<div v-if="zgwState.selectedCreator && zgwState.selectedCreator.uID == $global.getUser().uID" class="uc-wrap-zgw">
					<p>{{ $t('ucYourTopicIs') }}: {{ zgwState.selectedTopic.question }}</p>
					<p>{{ $t('ucYourTerms') }} ({{ zgwState.proposedTopicTerms.length }} / 10)</p>
					<div class="uc-wrap-term-creation">
						<div
							v-for="term in zgwState.proposedTopicTerms"
							:key="term"
							:class="['uc-zgw-topic-terms', zgwState.acceptedTopicTerms.includes(term) ? 'uc-zgw-accepted-term' : '']"
						>
							{{ term }}
							<div
								:class="[zgwState.phase == 'lockTopicTerms' ? 'uc-zgw-wrap-term-delete' : 'uc-zgw-no-delete']"
								@click="zgwState.phase == 'lockTopicTerms' ? $emit('removeTopicTerm', term) : null"
							>
								<fai icon="fas fa-times" :title="$t('ucRemoveTopic')" />
							</div>
						</div>
					</div>
					<div v-if="zgwState.phase == 'lockTopicTerms'">
						<p>{{ $t('ucEnterTopicTerm') }}</p>
						<div class="uc-topic-answer">
							<input type="text" :placeholder="$t('ucEnterTopicTerm')" v-model="currentTopic" @keyup.enter="addTopicTerm" />
							<button class="app-default-btn" @click="addTopicTerm" :title="$t('ucSetTopicTerm')">
								<fai icon="fas fa-check" />
							</button>
							<button class="app-success-btn" @click="showConfirmation = 'lockTerms'" :title="$t('ucLockTopicTerms')">
								<fai icon="fas fa-lock" />
							</button>
						</div>
					</div>
				</div>
				<div v-if="zgwState.phase == 'guessingTime'" class="uc-wrap-timer">
					<p v-if="zgwState.selectedGuesser.uID == $global.getUser().uID">
						{{ $t('ucYourTopicIs') }}: {{ zgwState.selectedTopic.question }}
					</p>
					<div class="uc-wrap-zgw-timer">
						<Timer
							:state="timer.state"
							:time="timer.time"
							:timerUpdate="timerUpdate"
							@currentTime="timer.timeLeft = $event"
							@timerFinished="timer.state = 'INITIAL'"
						/>
					</div>
				</div>
			</div>
			<div v-if="getKQConditions()" class="uc-wrap-kq-tools">
				<div v-if="getJokersLeft() > 0" class="uc-wrap-joker">
					<p>{{ $t('ucJokerLeft') }}: <span v-for="i in getJokersLeft()" :key="i" class="uc-joker"> &#x1F0CF; </span></p>
				</div>
				<div class="uc-wrap-button">
					<button
						:class="[getJokersLeft() == 0 ? 'app-disabled-btn' : 'app-success-btn']"
						@click="getJokersLeft() == 0 ? null : setShowConfirmation('useJoker')"
						:title="$t('ucUseJoker')"
					>
						{{ getJokersLeft() == 0 ? $t('ucNoJokerLeft') : $t('ucUseJoker') }}
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Buzzer from './Buzzer.vue';
import Timer from './gameshowElements/Timer.vue';
export default {
	name: 'UserControls',
	components: {
		Buzzer,
		Timer,
	},
	props: {
		show: {
			type: Object,
			required: true,
		},
		players: {
			type: Array,
			required: true,
		},
		ownAnswer: {
			type: Object,
			required: true,
		},
		history: {
			type: Array,
			required: true,
		},
		buzzerUser: {
			type: Object,
			required: false,
		},
		buzzerAnimation: {
			type: String,
			required: false,
		},
		wlpState: {
			type: Object,
			required: false,
		},
		zgwState: {
			type: Object,
			required: false,
		},
		kqState: {
			type: Object,
			required: false,
		},
		timerUpdate: {
			type: Object,
			required: false,
		},
	},
	watch: {
		history: {
			handler: function (newVal) {
				if (this.show.show == 'kq') {
					if (
						!this.userToolsExpanded &&
						this.getKQConditions() &&
						['showQuestion'].includes(this.kqState.state) &&
						this.getJokersLeft() > 0
					) {
						this.$nextTick(() => {
							this.toggleUserTools();
						});
					} else if (this.userToolsExpanded && (['showHint'].includes(this.kqState.state) || !this.kqState.selectedQuestion)) {
						this.$nextTick(() => {
							this.toggleUserTools();
						});
					}
				}
			},
			deep: true,
		},
		ownAnswer: {
			handler: function (newVal) {
				this.answer = newVal;
				if (this.currentQuestion.type == 'SingleChoice') this.selectedChoice = Number(newVal.textAnswer);
			},
			deep: true,
		},
		wlpState: {
			handler: function (newVal) {
				if (newVal) {
					this.setBid();
					this.$nextTick(() => window.dispatchEvent(new Event('resize')));
				}
			},
			deep: true,
		},
		zgwState: {
			handler: function (newVal) {
				if (
					['lockTopicTerms', 'assignTopicGuessing'].includes(newVal.phase) &&
					newVal.selectedCreator &&
					newVal.selectedCreator.uID == this.$global.getUser().uID &&
					!this.userToolsExpanded
				) {
					this.$nextTick(() => {
						this.toggleUserTools();
					});
				} else if (newVal.phase == 'guessingTime' && newVal.selectedGuesser && !this.userToolsExpanded) {
					this.$nextTick(() => {
						// this.timer.state = 'STARTED';
						this.toggleUserTools();
					});
				} else if (!['lockTopicTerms', 'assignTopicGuessing', 'guessingTime'].includes(newVal.phase) && this.userToolsExpanded) {
					this.$nextTick(() => {
						this.toggleUserTools();
					});
				}
			},
			deep: true,
		},
		kqState: {
			handler: function (newVal, oldVal) {
				if (!this.userToolsExpanded && this.getKQConditions() && ['showQuestion'].includes(newVal.state) && this.getJokersLeft() > 0) {
					this.$nextTick(() => {
						this.toggleUserTools();
					});
				} else if (this.userToolsExpanded && (['showHint'].includes(newVal.state) || !newVal.selectedQuestion)) {
					this.$nextTick(() => {
						this.toggleUserTools();
					});
				}
			},
			deep: true,
		},
		timerUpdate: {
			handler: function (newVal) {
				if (newVal.newState == 'INITIAL') {
					this.timer = {
						state: 'INITIAL',
						time: this.show.timer,
						timeLeft: this.show.timer,
					};
				} else if (newVal.newState == 'STARTED') {
					this.$nextTick(() => {
						this.timer.state = newVal.newState;
					});
				}
			},
			deep: true,
		},
	},
	data() {
		return {
			userToolsExpanded: false,
			showConfirmation: false,
			answer: {
				textAnswer: '',
				lockAnswer: false,
			},
			currentQuestion: null,
			selectedChoice: null,
			bid: 1,
			currentTopic: '',
			timer: {
				state: 'INITIAL',
				time: this.show.timer,
				timeLeft: this.show.timer,
			},
		};
	},
	created() {
		this.getCurrentQuestion();
	},
	mounted() {
		if (['dpin', 'xfz', 'smf', 'wlp'].includes(this.show.show)) this.toggleUserTools();
		else if (this.getKQConditions()) this.toggleUserTools();
		else {
			let target = document.querySelector('.uc-wrap-content');
			target.style.paddingTop = '13px';
		}
	},
	methods: {
		getCurrentQuestion() {
			let lastQuestions = this.history.filter((his) => his.action == 'setQuestion');
			if (lastQuestions.length > 0) {
				this.currentQuestion = this.show.questions.filter((quest) => quest.qID == lastQuestions[lastQuestions.length - 1].result)[0];
			} else {
				this.currentQuestion = this.show.questions.filter((quest) => quest.position == 0)[0];
			}
		},
		toggleUserTools() {
			let target = document.querySelector('.uc-wrap-content');
			if (this.userToolsExpanded) {
				target.style.height = '0px';
				target.style.paddingTop = '14px';
				target.style.paddingBottom = '20px';
			} else {
				target.style.height = 'fit-content';
				target.style.paddingTop = '30px';
			}
			this.userToolsExpanded = !this.userToolsExpanded;
			this.$nextTick(() => window.dispatchEvent(new Event('resize')));
		},
		getFreetextConditions() {
			// Shows needed
			let showsNeeded = ['dpin', 'xfz', 'smf'].includes(this.show.show);

			if (['xfz', 'smf'].includes(this.show.show)) {
				// Correct question type
				let questionType = ['Freetext'].includes(this.currentQuestion.type);

				return showsNeeded && questionType;
			} else return showsNeeded;
		},
		getBuzzerConditions() {
			// Shows needed
			let showsNeeded = ['xfz', 'smf'].includes(this.show.show);

			// Correct question type
			let questionType = false;
			if (showsNeeded) questionType = ['Buzzer', 'BuzzerSingleChoice'].includes(this.currentQuestion.type);

			return showsNeeded && questionType;
		},
		getSingleChoiceConditions() {
			// Shows needed
			let showsNeeded = ['xfz', 'smf'].includes(this.show.show);

			// Correct question type
			let questionType = false;
			if (showsNeeded) questionType = ['SingleChoice'].includes(this.currentQuestion.type);

			return showsNeeded && questionType;
		},
		getZGWConditions() {
			if (this.zgwState) {
				let showNeeded = this.show.show == 'zgw';
				let userCanSelectTerms =
					this.zgwState.phase == 'lockTopicTerms' &&
					this.zgwState.selectedCreator &&
					this.zgwState.selectedCreator.uID == this.$global.getUser().uID;

				let userSelectedTerms =
					this.zgwState.phase == 'assignTopicGuessing' &&
					this.zgwState.selectedCreator &&
					this.zgwState.selectedCreator.uID == this.$global.getUser().uID;

				let timerActive = this.zgwState.phase == 'guessingTime' && this.zgwState.selectedGuesser;

				return showNeeded && (userCanSelectTerms || userSelectedTerms || timerActive);
			} else return false;
		},
		setShowConfirmation(confirmation) {
			this.showConfirmation = confirmation;
			let target = document.querySelector('.uc-wrap-content');
			target.style.height = 'fit-content';
			if (confirmation) {
				target.style.paddingTop = '50px';
				target.style.paddingBottom = '30px';
			} else {
				target.style.paddingTop = '30px';
				target.style.paddingBottom = '10px';
			}
			this.$nextTick(() => window.dispatchEvent(new Event('resize')));
		},
		lockAnswer(e, lock) {
			e.stopPropagation();
			if (lock) {
				this.answer.lockAnswer = true;
				this.$emit('giveTextAnswer', { uID: this.$global.getUser().uID, ...this.answer });
			}
			this.setShowConfirmation(false);
		},
		lockChoice(e, choice, lock) {
			e.stopPropagation();
			if (lock && choice != null && choice != undefined) {
				this.answer.lockAnswer = true;
				this.answer.textAnswer = String(choice);
				this.$emit('giveTextAnswer', { uID: this.$global.getUser().uID, ...this.answer });
			}
			this.showConfirmation = false;
		},
		setBid() {
			this.bid = this.getMinBid();
		},
		checkBid() {
			if (this.bid < this.getMinBid()) this.bid = this.getMinBid();
			else if (this.bid > this.getMaxBid()) this.bid = this.getMaxBid();
		},
		getMinBid() {
			let highestBid = 0;
			this.wlpState.bids.forEach((bid) => {
				if (bid.bid > highestBid) highestBid = bid.bid;
			});
			return highestBid + 1;
		},
		getMaxBid() {
			let user = this.wlpState.users.filter((user) => user.uID == this.$global.getUser().uID)[0];
			return user.money;
		},
		getUsers() {
			let users = [];
			this.players.forEach((player) => {
				let boughtItems = this.getBoughtItems(player.uID);
				let bid = this.wlpState.bids.filter((bid) => bid.uID == player.uID)[0];
				bid = bid ? bid.bid : 0;
				let user = this.wlpState.users.filter((user) => user.uID == player.uID)[0];
				let highestBid = true;
				if (bid == 0) highestBid = false;
				else {
					this.wlpState.bids.forEach((b) => {
						if (bid < b.bid) highestBid = false;
					});
				}

				users.push({
					...player,
					boughtItems: boughtItems,
					bid: bid,
					lockBid: user.lockBid,
					disableBid: user.disableBid,
					money: user.money,
					highestBid: highestBid,
				});
			});
			return users;
		},
		getBoughtItems(uID) {
			let items = this.wlpState.items.filter((user) => user.uID == uID);
			let fullItems = [];
			this.show.questions.forEach((quest) => {
				let item = items.filter((i) => i.qID == quest.qID)[0];
				if (item) {
					fullItems.push({
						...quest,
						...item,
					});
				}
			});
			return fullItems;
		},
		disableBid() {
			let user = this.wlpState.users.filter((user) => user.uID == this.$global.getUser().uID)[0];
			return user.disableBid || user.lockBid;
		},
		sendBid(e, send) {
			e.stopPropagation();
			if (send) {
				this.$emit('sendBid', { uID: this.$global.getUser().uID, bid: this.bid, qID: this.currentQuestion.qID });
			}
			this.showConfirmation = false;
		},
		lockBid(e, lock) {
			e.stopPropagation();
			if (lock) {
				let ownBid = this.wlpState.bids.filter((bid) => bid.uID == this.$global.getUser().uID)[0];
				let highestBid = true;
				if (ownBid.bid == 0) highestBid = false;
				else {
					this.wlpState.bids.forEach((b) => {
						if (ownBid.bid < b.bid) highestBid = false;
					});
				}
				if (!highestBid) this.$emit('lockBid', { uID: this.$global.getUser().uID, qID: this.currentQuestion.qID });
				else this.$global.showToast('info', this.$t('ucHighestBid'));
			}
			this.showConfirmation = false;
		},
		getRevealedItems(uID) {
			// let diffs = [];
			// let items = this.wlpState.items.filter((item) => item.uID == uID);
			// items.forEach((item) => {
			// 	let itemRevealed = this.wlpState.revealState.filter((state) => state.qID == item.qID)[0];
			// 	if (itemRevealed) {
			// 		diffs.push(itemRevealed);
			// 	}
			// });
			let diffs = [];
			this.wlpState.revealState.forEach((it) => {
				if (it.uID == uID) diffs.push(it);
			});

			return diffs;
		},
		getItemPriceDiff(diff) {
			if (diff < 0) return 'uc-red';
			else if (diff > 0) return 'uc-green';
			else return 'uc-white';
		},
		getUserMoneyDiff(uID) {
			let diff = this.show.money;
			let reveals = this.wlpState.revealState.filter((state) => state.uID == uID);
			reveals.forEach((state) => {
				diff += state.diff;
			});

			return diff;
		},
		addTopicTerm() {
			let topic = this.currentTopic.trim();

			if (this.zgwState.proposedTopicTerms.length >= 10) this.$global.showToast('warn', this.$t('ucAlreadyMaxTerms'));
			else if (topic.length < 2 || topic.length > 50) this.$global.showToast('warn', this.$t('ucTermLengthWarning'));
			else if (this.zgwState.proposedTopicTerms.includes(topic)) this.$global.showToast('warn', this.$t('ucTermAlreadyUsed'));
			else {
				this.$emit('addTopicTerm', topic);
				this.currentTopic = '';
			}
		},
		lockTerms(e, lock) {
			e.stopPropagation();
			if (lock) {
				if (this.zgwState.proposedTopicTerms.length !== 10) this.$global.showToast('warn', this.$t('ucNotEnoughTerms'));
				else this.$emit('lockTerms');
			}
			this.showConfirmation = false;
		},
		useJoker(e, use) {
			e.stopPropagation();
			if (use) this.$emit('useJoker');
			this.setShowConfirmation(false);
		},
		getKQConditions() {
			if (
				this.show.show == 'kq' &&
				this.kqState &&
				this.kqState.selectedQuestion &&
				this.kqState.selectedQuestion.uID == this.$global.getUser().uID
			) {
				let usedJoker = this.history.filter(
					(his) =>
						his.action == 'useJoker' &&
						his.uID == this.kqState.selectedQuestion.uID &&
						his.result == this.kqState.selectedQuestion.qID &&
						his.info == this.kqState.selectedQuestion.level
				).length;
				let jokerWasRestored = this.history.filter(
					(his) =>
						his.action == 'restoreJoker' &&
						his.result == this.kqState.selectedQuestion.uID &&
						his.info == this.kqState.selectedQuestion.qID &&
						his.info2 == this.kqState.selectedQuestion.level
				).length;
				let showTools = usedJoker == 0 || (usedJoker > 0 && jokerWasRestored == usedJoker);
				return showTools;
			} else return false;
		},
		getJokersLeft() {
			let player = this.kqState.players.filter((player) => player.uID == this.$global.getUser().uID)[0];
			if (player) return player.joker;
			else return 0;
		},
	},
};
</script>

<style scoped>
.uc-wrap-content {
	width: 100%;
	height: 0px;
	max-height: 80vh;
	padding: 10px 20px;
	padding-top: 30px;
	box-sizing: border-box;
	position: relative;
	background-color: var(--main-color-dark-transparent-cc);
	-webkit-transition: height 1s ease-out;
	-moz-transition: height 1s ease-out;
	transition: height 1s ease-out;
	z-index: 11;
}

.uc-top-bar {
	width: 100%;
	height: 20px;
	position: absolute;
	top: 0px;
	left: 0px;
	border-top: 1px solid var(--main-color-border-dark);
	border-bottom: 1px solid var(--main-color-border-dark);
	text-align: center;
	background-color: var(--main-color-light-transparent-80);
	color: var(--main-color-text-light);
}

.uc-top-bar svg {
	font-size: 20px;
	transition: 1s;
}

.uc-normal {
	transform: rotate(0deg);
}

.uc-rotated {
	transform: rotate(180deg);
}

.uc-top-bar:hover {
	cursor: pointer;
	background-color: var(--main-color-light-transparent-cc);
	color: var(--main-color-text-dark);
}

.uc-confirmation-window {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0px;
	left: 0px;
	z-index: 11;
	display: flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	overflow: auto;
	background-color: var(--main-color-dark-transparent-cc);
}

.uc-confirmation-window div {
	box-sizing: border-box;
	text-align: center;
}

.uc-confirmation-window div svg {
	font-size: 20px;
}

.uc-confirmation-window div p {
	font-size: 20px;
	margin: 5px auto 10px;
}

.uc-confirmation-window div button {
	width: fit-content;
	min-width: 100px;
	height: fit-content;
	margin: 0px 10px;
	font-size: 20px;
}

.uc-confirmation-window div em {
	max-width: 1000px;
	margin: 10px auto;
	padding: 0px 10px;
	display: block;
	color: var(--main-color-text-light);
}

.uc-text-answer {
	width: 100%;
	height: fit-content;
	padding: 5px 0px;
	box-sizing: border-box;
}

.uc-text-answer h2 {
	width: fit-content;
	font-size: 20px;
	margin: 0px auto 10px auto;
	padding: 0px;
}

.uc-text-answer button {
	width: 120px;
	height: 40px;
	font-size: 17px;
	margin: 0px;
	/* padding: 5px; */
	position: relative;
}

.uc-text-answer input {
	width: calc(100% - 170px);
	height: 30px;
	margin-right: 10px;
}

.uc-buzzer-answer {
	width: 100%;
	height: fit-content;
	padding: 5px 0px;
	box-sizing: border-box;
}

.uc-buzzer-answer h2 {
	width: fit-content;
	font-size: 20px;
	margin: 0px auto 10px auto;
	padding: 0px;
}

.uc-single-choice {
	width: 100%;
	height: fit-content;
	padding: 5px 0px;
	box-sizing: border-box;
	text-align: center;
}

.uc-single-choice h2 {
	width: fit-content;
	font-size: 20px;
	margin: 0px auto 10px auto;
	padding: 0px;
}

.uc-single-choice button {
	width: 60px;
	font-size: 22px;
	margin: 5px;
}

.uc-no-tools {
	width: 100%;
	height: 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 20px;
}

.uc-wrap-bid {
	width: 100%;
	height: fit-content;
}

.uc-own-bid,
.uc-other-bids {
	width: 100%;
	height: fit-content;
	padding: 5px 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
}

.uc-own-bid input {
	width: calc(100% - 350px);
	height: 35px;
	font-size: 20px;
}

.uc-own-bid p {
	width: 120px;
	margin-left: 10px;
	font-size: 25px;
	color: var(--main-color-text-light);
}

.uc-own-bid p svg {
	color: var(--main-color-gold);
}

.uc-own-bid .uc-bid-btn {
	width: 100px;
	margin-left: 10px;
}

.uc-bid-btn svg {
	font-size: 20px;
}

.uc-wrap-users {
	width: 100%;
	height: fit-content;
	padding: 0px 30px;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	box-sizing: border-box;
	flex-flow: wrap;
}

.uc-wrap-user {
	width: fit-content;
	height: fit-content;
	margin: 20px 5px;
	flex: 1 1 200px;
}

.uc-user-info {
	width: 100%;
	height: fit-content;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.uc-user-info img {
	width: 30px;
	height: 30px;
	display: inline-block;
	border: 2px solid var(--main-color-border-dark);
	border-radius: 50%;
	box-sizing: border-box;
}

.uc-user-info p {
	width: fit-content;
	display: inline-block;
	margin-left: 10px;
	font-size: 20px;
}

.uc-active-bidder p {
	color: var(--main-color-6);
	text-decoration: underline;
}

.uc-lock-bid {
	color: var(--main-color-error) !important;
}

.uc-highest-bid {
	color: var(--main-color-success) !important;
}

.uc-user-money {
	width: 100%;
	height: fit-content;
	margin-top: 10px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.uc-user-money p {
	width: fit-content;
	display: inline-block;
	margin-right: 10px;
	font-size: 20px;
}

.uc-user-money p svg {
	color: var(--main-color-gold);
}

.uc-user-items p {
	width: fit-content;
	margin: 10px 0px;
	font-size: 20px;
	text-decoration: underline;
}

.uc-no-items {
	font-size: 17px !important;
	text-decoration: none !important;
}

.uc-wrap-items {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	flex-flow: wrap;
}

.uc-item {
	width: fit-content;
	max-width: 75px;
	height: fit-content;
	margin: 0px 5px 10px 5px;
	text-align: center;
}

.uc-item img {
	max-width: 75px;
	max-height: 50px;
	margin-bottom: 5px;
}

.uc-item p {
	width: fit-content;
	max-width: 75px;
	margin: 0px auto;
	font-size: 17px;
	text-decoration: none;
}

.uc-item p svg {
	color: var(--main-color-gold);
}

.uc-wrap-wlp-reveal {
	max-height: 200px;
	overflow: auto;
}

.uc-red {
	color: var(--main-color-error);
}

.uc-white {
	color: var(--main-color-text-light);
}

.uc-green {
	color: var(--main-color-success);
}

.uc-diff {
	min-width: fit-content;
	max-width: 100px;
	margin-top: 10px;
	padding-top: 5px;
	border-top: 2px solid var(--main-color-border-light);
	text-align: end;
}

.uc-wrap-zgw {
	width: 100%;
	height: fit-content;
}

.uc-wrap-zgw > p:first-child {
	text-align: center;
	font-size: 20px;
	margin-bottom: 10px;
}

.uc-wrap-zgw > p:nth-child(2) {
	text-align: center;
	font-size: 17px;
	margin-bottom: 0px;
}

.uc-wrap-zgw > p:nth-child(3) {
	width: 100%;
	font-size: 17px;
	margin-bottom: 0px;
	text-align: start;
}

.uc-wrap-term-creation {
	width: 100%;
	height: fit-content;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow: wrap;
}

.uc-zgw-topic-terms {
	flex: 1 1 100px;
	margin: 10px 5px;
	padding: 10px;
	position: relative;
	border: 2px solid var(--main-color-border-dark);
	border-radius: 5px;
	background-color: var(--main-color-3);
	color: var(--main-color-text-light);
}

.uc-zgw-topic-terms:hover .uc-zgw-wrap-term-delete {
	display: flex;
	cursor: pointer;
}

.uc-zgw-wrap-term-delete {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0px;
	left: 0px;
	display: none;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	border: 2px solid var(--main-color-error);
	border-radius: 5px;
	background-color: var(--main-color-error-80);
}

.uc-zgw-wrap-term-delete svg {
	font-size: 30px;
	-webkit-filter: drop-shadow(3px 0px 5px 5px rgba(0, 0, 0, 1));
	filter: drop-shadow(3px 0px 5px 5px rgba(0, 0, 0, 1));
	color: var(--main-color-text-light);
}

.uc-zgw-no-delete {
	display: none;
}

.uc-zgw-accepted-term {
	background-color: var(--main-color-6);
}

.uc-topic-answer {
	width: 100%;
	height: fit-content;
	padding: 5px 0px;
	box-sizing: border-box;
}

.uc-topic-answer button {
	width: 75px;
	height: 40px;
	font-size: 17px;
	margin: 0px 5px;
	/* padding: 5px; */
	position: relative;
}

.uc-topic-answer input {
	width: calc(100% - 220px);
	height: 30px;
	margin-right: 10px;
}

.uc-wrap-timer {
	text-align: center;
}

.uc-wrap-timer > p {
	font-size: 20px;
	margin-bottom: 10px;
}

.uc-wrap-kq-tools {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.uc-wrap-joker {
	min-width: fit-content;
	margin-right: 20px;
	font-size: 30px;
}

.uc-wrap-button {
	flex: 1 1 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.uc-wrap-button button {
	min-width: 150px;
	height: 40px;
	margin: 0px 10px;
	font-size: 17px;
}
</style>
