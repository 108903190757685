<template>
	<div class="cw-wrap-content">
		<p>{{ title }}</p>
		<em v-if="text">{{ text }}</em>
		<button class="app-success-btn" :title="positiveBtnText" @click="$emit('positiveEvent', $event)">
			{{ positiveBtnText }}
		</button>
		<button class="app-error-btn" :title="negativeBtnText" @click="$emit('negativeEvent', $event)">
			{{ negativeBtnText }}
		</button>
	</div>
</template>

<script>
/**
 * @group Lobby
 * Shows a confirmation window for given data
 */
export default {
	name: 'ConfirmationWindow',
	props: {
		title: {
			type: String,
			required: true,
		},
		text: {
			type: String,
			required: false,
		},
		positiveBtnText: {
			type: String,
			required: true,
		},
		negativeBtnText: {
			type: String,
			required: true,
		},
	},
};
</script>

<style scoped>
.cw-wrap-content {
	width: 100%;
	box-sizing: border-box;
	text-align: center;
}

.cw-wrap-content svg {
	font-size: 30px;
}

.cw-wrap-content input {
	width: 100px;
	font-size: 30px;
}

.cw-wrap-content p {
	font-size: 30px;
	margin: 5px auto 10px;
}

.cw-wrap-content button {
	width: fit-content;
	min-width: 100px;
	margin: 0px 10px;
	font-size: 20px;
}

.cw-wrap-content em {
	max-width: 1000px;
	margin: 10px auto;
	padding: 0px 10px;
	display: block;
	color: var(--main-color-text-light);
}
</style>
