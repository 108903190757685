<template>
	<div class="pg-wrap-content">
		<canvas id="pointsGraph"></canvas>
	</div>
</template>

<script>
import { Chart, registerables } from 'chart.js';
import { i18n } from '../../translations/i18n';

// Register necessary controllers and elements
Chart.register(...registerables);

class CustomLine extends Chart {
	constructor(context, config, showStarts) {
		super(context, {
			type: 'line',
			data: config.data,
			options: config.options,
			showStarts: showStarts,
		});
	}
	draw() {
		super.draw(arguments);
		const { ctx, data } = this;

		let config = this.config._config;
		let areDatesSameDay = config.data.labels[0].length == 8;

		config.showStarts.forEach((start, idx) => {
			let labelIdx = data.labels.indexOf(
				areDatesSameDay ? new Date(start.timepoint).toLocaleTimeString() : new Date(start.timepoint).toLocaleString()
			);
			const x = this.scales.x.getPixelForValue(data.labels[labelIdx]);
			const y = this.scales.y.getPixelForValue(data.datasets[0].data[labelIdx]);

			this.scales.y.height;

			ctx.save();
			ctx.beginPath();
			ctx.strokeStyle = '#ffffff';
			ctx.lineWidth = 1;
			ctx.moveTo(x, idx % 2 == 0 ? 50 : 80);
			ctx.lineTo(x, this.scales.y.height + 30);
			ctx.stroke();

			ctx.font = '17px Arial';
			ctx.fillStyle = '#ffffff';
			ctx.fillText(i18n.tc(`${start.result}Name`), x - 2, idx % 2 == 0 ? 40 : 70);

			ctx.restore();
		});
	}
}

export default {
	name: 'PointsGraph',
	props: {
		shows: {
			type: Array,
			required: true,
		},
		history: {
			type: Array,
			required: true,
		},
		players: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			chartHandle: null,
			showStarts: [],
			config: {
				type: 'customLine',
				data: {
					labels: [],
					datasets: [],
				},
				options: {
					plugins: {
						legend: {
							display: true,
							labels: {
								color: '#ffffff',
							},
						},
					},
					scales: {
						x: {
							color: '#ffffff',
							grid: {
								color: '#ffffff10',
							},
							ticks: {
								color: '#ffffff',
							},
						},
						y: {
							color: '#ffffff',
							grid: {
								color: '#ffffff10',
							},
							ticks: {
								color: '#ffffff',
							},
						},
					},
				},
			},
			playerColors: new Map([
				[0, ['--main-color-info', '--main-color-info-cc']],
				[1, ['--main-color-success', '--main-color-success-cc']],
				[2, ['--main-color-error', '--main-color-error-cc']],
				[3, ['--main-color-warn', '--main-color-warn-cc']],
				[4, ['--main-color-purple', '--main-color-purple-cc']],
				[5, ['--main-color-border-dark', '--main-color-dark-transparent-cc']],
			]),
		};
	},
	mounted() {
		this.configureGraph();
	},
	methods: {
		getComputedStyleValue(propertyName) {
			return getComputedStyle(document.documentElement).getPropertyValue(propertyName);
		},
		configureGraph() {
			if (this.chartHandle) {
				this.chartHandle.destroy();
				this.chartHandle = null;
			}

			let players = [];
			let labels = [];

			this.players.forEach((player, idx) => {
				players.push({
					uID: player.uID,
					label: player.name,
					backgroundColor: this.getComputedStyleValue(this.playerColors.get(idx)[0]),
					borderColor: this.getComputedStyleValue(this.playerColors.get(idx)[1]),
					data: [],
				});
			});

			let allEntries = this.history.filter(
				(his) =>
					[
						'correctAnswer',
						'correctAnswerKQ',
						'rightBuzzer',
						'wrongBuzzer',
						'addPoints',
						'removePoints',
						'pointsWin',
						'creatorPoints',
					].includes(his.action) ||
					(his.action == 'setState' && !['lobby', 'finished'].includes(his.result))
			);
			this.showStarts = this.history.filter((his) => his.action == 'setState' && !['lobby', 'finished'].includes(his.result));
			allEntries.forEach((entry) => {
				players.forEach((player) => {
					let currentPoints = player.data.length > 0 ? player.data[player.data.length - 1] : 0;
					if (entry.action == 'wrongBuzzer') {
						if (entry.info !== player.uID) player.data.push(currentPoints + Number(entry.result));
						else player.data.push(currentPoints);
					} else if (entry.action == 'correctAnswerKQ') {
						if (entry.info === player.uID) {
							let show = this.shows.filter((it) => it.gID == entry.gID)[0];
							let points = show.pointsPerLevel[entry.result].points;
							player.data.push(currentPoints + Number(points));
						} else player.data.push(currentPoints);
					} else if (entry.action != 'setState') {
						if (entry.info === player.uID) player.data.push(currentPoints + Number(entry.result));
						else player.data.push(currentPoints);
					} else player.data.push(currentPoints);
				});
				labels.push(new Date(entry.timepoint));
			});

			if (this.areDatesSameDay(labels)) labels = labels.map((label) => label.toLocaleTimeString());
			else labels = labels.map((label) => label.toLocaleString());

			this.config.data.datasets = players;
			this.config.data.labels = labels;

			CustomLine.id = 'customLine';
			Chart.register(CustomLine);

			this.chartHandle = new CustomLine(document.getElementById('pointsGraph'), this.config, this.showStarts);
		},
		areDatesSameDay(dateArray) {
			if (dateArray.length === 0) return false;

			const firstDate = new Date(dateArray[0]);
			const firstYear = firstDate.getFullYear();
			const firstMonth = firstDate.getMonth();
			const firstDay = firstDate.getDate();

			for (let i = 1; i < dateArray.length; i++) {
				const currentDate = new Date(dateArray[i]);
				if (currentDate.getFullYear() !== firstYear || currentDate.getMonth() !== firstMonth || currentDate.getDate() !== firstDay)
					return false;
			}
			return true;
		},
	},
};
</script>

<style scoped>
.pg-wrap-content {
	width: calc(100%-40px);
	height: fit-content;
	margin: 20px;
	box-sizing: border-box;
	background-color: var(--main-color-dark-transparent-80);
	box-shadow: 0px 0px 10px 10px var(--main-color-dark-transparent-80);
}
</style>
