<template>
	<div :class="['bp-wrap-content', bug.resolved ? 'bp-bug-resolved' : 'bp-bug-unresolved', 'bp-hover-effect']">
		<div class="bp-hover-view">
			<p @click="confirmAction(bug.resolved ? 'unresolve' : 'resolve')" class="bp-resolve-bug">
				<fai :icon="['fas', !bug.resolved ? 'fa-square-check' : 'fa-undo']" :class="!bug.resolved ? 'bp-resolved' : 'bp-unresolved'" />
				{{ $t(bug.resolved ? 'bpRemoveResolved' : 'bpResolveBugs') }}
			</p>
			<p @click="confirmAction('delete')" class="bp-delete-bug"><fai icon="fas fa-trash" class="bp-trash" /> {{ $t('bpDeleteBugs') }}</p>
		</div>
		<div class="bp-bug-content">
			<div class="bp-wrap-top">
				<div class="bp-bug-type">
					<fai v-if="bug.category == 'func'" icon="fas fa-bug" class="bp-functional-bug" :title="$t('bpFunctionalBug')" />
					<fai v-else-if="bug.category == 'vis'" icon="fas fa-eye" class="bp-visual-bug" :title="$t('bpVisualBug')" />
					<fai v-else-if="bug.category == 'other'" icon="fas fa-tag" class="bp-other-bug" :title="$t('bpOtherBug')" />
					<fai v-else-if="bug.category == 'feedback'" icon="fas fa-comment" class="bp-feedback" :title="$t('bpFeedback')" />
				</div>
				<div class="bp-bug-id">
					<p>{{ bug.bID }}</p>
				</div>
			</div>
			<div class="bp-wrap-bottom">
				<div class="bp-bug-description">
					<p>{{ bug.description }}</p>
				</div>
				<div class="bp-bug-relation">
					<p>
						<fai icon="fas fa-user" class="bp-related-user" /> {{ bug.user.name }} ({{ bug.user.role }})
						<fai icon="fas fa-couch" class="bp-related-session" />
						<span v-if="bug.session">
							{{ bug.session.name }}
							(<span class="bp-quiz-night" @click="toQuizNight"
								>{{ bug.session.quizNight }} <fai icon="fas fa-external-link-alt" /></span
							>)
						</span>
						<span v-else>{{ $t('bpOutsideOfSession') }}</span>
						<img
							v-if="bug.show != 'global'"
							:src="require(`@/assets/images/logos/${bug.show.toUpperCase()}Logo.png`)"
							:alt="$t(`${bug.show}Name`)"
							:title="$t(`${bug.show}Name`)"
							class="bp-show-icon"
						/>
						<img v-else :src="require(`@/assets/images/logos/global.png`)" class="bp-show-icon" />

						<span v-if="bug.show != 'global'"> {{ $t(`${bug.show}Name`) }}</span>
						<span v-else>{{ $t(`bpGlobal`) }}</span>
					</p>
				</div>
				<div :class="['bp-bug-assets', bug.assets.length > 0 ? 'bp-bug-assets-available' : '']">
					<p v-if="bug.assets.length == 0"><fai icon="fas fa-images" class="bp-assets" />{{ $t('bpNoAssetsAvailable') }}</p>
					<div v-else class="bp-wrap-assets">
						<div v-for="asset in bug.assets" :key="asset.id" class="bp-asset" @click="$emit('showAsset', asset)">
							<img :src="asset.src" />
						</div>
					</div>
				</div>
				<div class="bp-bug-creation">
					<p>
						<span>{{ $global.parseDate(bug.creation, true, false) }}</span> /
						<span v-if="bug.resolved">{{ $global.parseDate(bug.resolvedTimestamp, true, false) }}</span>
						<span v-else>-</span>
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
/**
 * @group Admin
 * BugPreview
 */
export default {
	name: 'BugPreview',
	props: {
		bug: {
			type: Object,
			required: true,
		},
		shows: {
			type: Array,
			required: true,
		},
	},
	methods: {
		confirmAction(action) {
			let title = '';
			let text = '';
			let cancel = '';
			let confirm = '';

			if (action == 'resolve') {
				title = 'bpResolveBug';
				text = 'bpResolveBugText';
				cancel = 'bpResolveBugCancel';
				confirm = 'bpResolveBugConfirm';
			} else if (action == 'unresolve') {
				title = 'bpUnresolveBug';
				text = 'bpUnresolveBugText';
				cancel = 'bpUnresolveBugCancel';
				confirm = 'bpUnresolveBugConfirm';
			} else if (action == 'delete') {
				title = 'bpDeleteBug';
				text = 'bpDeleteBugText';
				cancel = 'bpDeleteBugCancel';
				confirm = 'bpDeleteBugConfirm';
			}

			let that = this;
			this.$global.showPopup(
				this.$t(title),
				this.$t(text),
				'warning',
				{ text: this.$t(cancel), color: 'var(--main-color-info)' },
				{ text: this.$t(confirm), color: 'var(--main-color-warn)' },
				null,
				function (result) {
					if (result.isConfirmed) {
						if (['resolve', 'unresolve'].includes(action)) that.changeResolvedStatus();
						else if (action == 'delete') that.deleteBug();
					}
				}
			);
		},
		changeResolvedStatus() {
			let that = this;
			this.bug.resolvedTimestamp = new Date();
			this.$global.patchData(
				'admin',
				'/changeResolvedStatus',
				{ bug: this.bug },
				{
					headers: {
						pageauthheader: this.$global.getAccessCode(),
						adminauthheader: this.$global.getAdminCode(),
						adminid: this.$global.getUser().uID,
					},
					auth: this.$global.getCredentials(),
				},
				(err, data) => {
					if (err)
						that.$global.showToast(
							'error',
							that.$t(err.response && err.response.data.msg ? err.response.data.msg : 'bpChangeResolvedStatusError')
						);
					else {
						that.$global.showToast('success', that.$t('bpChangedResolvedStatus'));
						that.bug.resolved = !that.bug.resolved;
					}
				}
			);
		},
		deleteBug() {
			let that = this;
			this.$global.deleteData(
				'admin',
				'/deleteBug',
				{
					headers: {
						pageauthheader: this.$global.getAccessCode(),
						adminauthheader: this.$global.getAdminCode(),
						adminid: this.$global.getUser().uID,
					},
					auth: this.$global.getCredentials(),
					params: { bug: this.bug },
				},
				null,
				(err, data) => {
					if (err)
						that.$global.showToast('error', that.$t(err.response && err.response.data.msg ? err.response.data.msg : 'bpDeleteBugError'));
					else {
						that.$global.showToast('success', that.$t('bpDeletedBug'));
						that.$emit('refreshBugs');
					}
				}
			);
		},
		toQuizNight() {
			this.$router.push({ name: 'QuizNightConfigurationOverview', query: { qID: this.bug.session.qID } });
		},
	},
};
</script>

<style scoped>
.bp-wrap-content {
	width: 100%;
	max-height: 370px;
	margin: 2px 0px 8px 0px;
	padding: 5px;
	box-sizing: border-box;
	position: relative;
	border: 2px solid var(--main-color-border-dark);
	transition: height 0.2s linear;
	overflow: hidden;
}

.bp-hover-effect:hover {
	max-height: 420px;
}

.bp-hover-view {
	width: 100%;
	height: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow: wrap;
	transition: height 0.2s linear;
	z-index: 2;
	overflow: hidden;
}

.bp-hover-view p {
	margin: 0px 15px;
	font-size: 20px;
	cursor: pointer;
}
.bp-hover-view p svg {
	filter: drop-shadow(2px 2px 2px var(--main-color-border-dark));
}

.bp-hover-view p:hover svg {
	opacity: 0.5;
}

.bp-hover-effect:hover .bp-hover-view {
	height: 50px;
	overflow: auto;
}

.bp-resolved {
	color: var(--main-color-success);
}

.bp-unresolved {
	color: var(--main-color-warn);
}

.bp-trash {
	color: var(--main-color-error);
}

.bp-bug-unresolved {
	background-color: var(--main-color-5);
}

.bp-bug-resolved {
	background-color: var(--main-color-1-cc);
}

.bp-bug-resolved p {
	color: var(--main-color-text-dark);
}

.bp-bug-content {
	width: 100%;
	height: fit-content;
}

.bp-wrap-top {
	width: 100%;
	height: 35px;
	display: flex;
}

.bp-bug-unresolved .bp-wrap-top {
	border-bottom: 1px solid var(--main-color-border-light);
}

.bp-bug-resolved .bp-wrap-top {
	border-bottom: 1px solid var(--main-color-border-dark);
}

.bp-bug-type {
	width: 50px;
	height: 100%;
	margin-right: 10px;
	display: inline-block;
}

.bp-bug-type svg {
	font-size: 30px;
	filter: drop-shadow(2px 2px 2px var(--main-color-border-dark));
}

.bp-functional-bug {
	color: var(--main-color-error);
}

.bp-visual-bug {
	color: var(--main-color-warn);
}

.bp-other-bug {
	color: var(--main-color-info);
}

.bp-feedback {
	color: var(--main-color-success);
}

.bp-bug-id {
	width: calc(100% - 60px);
	height: 100%;
	display: inline-flex;
	justify-content: flex-end;
	align-items: flex-start;
}

.bp-wrap-bottom {
	width: 100%;
	height: fit-content;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow: wrap;
}

.bp-wrap-bottom > div {
	flex: 1 1 100%;
	margin: 10px 0px;
	padding: 0px 10px;
	box-sizing: border-box;
}

.bp-bug-description {
	max-height: 100px;
	overflow: auto;
	text-align: start;
}

.bp-bug-relation {
	text-align: center;
}

.bp-bug-relation p {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow: wrap;
	font-size: 17px;
}

.bp-bug-relation svg {
	margin: 0px 5px 0px 20px;
	font-size: 20px;
	filter: drop-shadow(2px 2px 2px var(--main-color-border-dark));
}

.bp-show-icon {
	max-width: 30px;
	max-height: 30px;
	margin: 0px 5px 0px 20px;
	display: inline-block;
	border-radius: 5px;
}

.bp-quiz-night svg {
	font-size: 17px;
	margin: 0px 0px 0px 5px;
}

.bp-quiz-night:hover {
	cursor: pointer;
	text-decoration: underline;
	color: var(--main-color-6);
}

.bp-related-user {
	color: var(--main-color-info);
}
.bp-related-session {
	color: var(--main-color-purple);
}

.bp-bug-assets {
	box-sizing: content-box;
	overflow: auto;
	text-align: center;
}

.bp-bug-assets-available {
	height: 110px;
}

.bp-assets {
	margin: 0px 5px 0px 20px;
	font-size: 20px;
	filter: drop-shadow(2px 2px 2px var(--main-color-border-dark));
}

.bp-wrap-assets {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-flow: wrap;
}

.bp-asset {
	display: flex;
	justify-content: center;
	align-items: center;
}

.bp-asset img {
	max-width: 90px;
	max-height: 90px;
	margin: 0px 10px;
	border: 1px solid var(--main-color-border-dark);
}

.bp-asset:hover img {
	border: 2px solid var(--main-color-border-light);
	cursor: pointer;
	transform: scale(1.1);
}

.bp-bug-creation {
	width: 100%;
	text-align: end;
}
</style>
