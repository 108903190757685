<template>
	<div class="pr-wrap-content">
		<h1>{{ $t('prPasswordReset') }}</h1>
		<div class="pr-wrap-input">
			<label>{{ $t('prPassword') }}</label>
			<input :type="[showPassword ? 'text' : 'password']" autocomplete="on" :placeholder="$t('prPassword')" v-model="password" />
			<fai v-if="!showPassword" icon="fas fa-eye" class="pr-smaller-eye" @mouseenter="showPassword = true"></fai>
			<fai v-if="showPassword" icon="fas fa-eye-slash" @mouseleave="showPassword = false"></fai>
		</div>
		<div class="pr-wrap-input">
			<label>{{ $t('prPasswordControl') }}</label>
			<input
				:type="[showPasswordControl ? 'text' : 'password']"
				autocomplete="on"
				:placeholder="$t('prPasswordControl')"
				v-model="passwordControl"
			/>
			<fai v-if="!showPasswordControl" icon="fas fa-eye" class="pr-smaller-eye" @mouseenter="showPasswordControl = true"></fai>
			<fai v-if="showPasswordControl" icon="fas fa-eye-slash" @mouseleave="showPasswordControl = false"></fai>
		</div>
		<button class="app-default-btn" @click="resetPassword">{{ $t('prResetPassword') }}</button>
	</div>
</template>

<script>
export default {
	name: 'PasswordReset',
	data() {
		return {
			accessCode: null,
			showPassword: false,
			showPasswordControl: false,
			resetCode: '',
			password: '',
			passwordControl: '',
		};
	},
	created() {
		if (!this.$route.query.resetCode) this.$router.push('/NotFound');
		else this.resetCode = this.$route.query.resetCode;

		if (this.$route.query.token) this.accessCode = this.$route.query.token;
		else this.$global.checkAccess(this.$router, false);
	},
	methods: {
		resetPassword() {
			if (this.password !== this.passwordControl) this.$global.showToast('error', this.$t('prNoMatchingPassword'));
			else if (this.password.length < 9) this.$global.showToast('error', this.$t('prPasswordLength'));
			else {
				this.$global.postData(
					'access',
					'/resetPassword',
					{ password: this.password, resetCode: this.resetCode },
					{ headers: { pageauthheader: this.accessCode } },
					(err, data) => {
						if (err) this.$global.showToast('error', this.$t(err.response ? err.response.data.msg : 'prResetError'));
						else {
							this.$global.showToast('success', this.$t('prPasswordResetSuccess'));

							if (localStorage.getItem('cookiesAllowed')) localStorage.setItem('accessCode', this.accessCode);
							else sessionStorage.setItem('accessCode', this.accessCode);

							this.$router.push({ name: 'Home' });
						}
					}
				);
			}
		},
	},
};
</script>

<style scoped>
.pr-wrap-content {
	max-width: 100vw;
	height: 100%;
	text-align: center;
	position: relative;
	margin: auto;
}

.pr-wrap-content h1 {
	max-width: 80vw;
	font-size: 80px;
	margin: 0px auto;
	padding: 5vh 0px 20px 0px;
	text-shadow: 5px 5px var(--main-color-6);
	-webkit-text-stroke: 2px var(--main-color-text-dark);
}

.pr-wrap-input {
	width: fit-content;
	max-width: 80%;
	height: fit-content;
	text-align: start;
	margin: auto;
	position: relative;
}

.pr-wrap-input label {
	font-size: 30px;
	margin: 20px auto;
}

.pr-wrap-input input {
	width: 700px;
	max-width: calc(100% - 25px);
	height: 50px;
	padding: 5px;
	display: block;
	margin: 20px auto;
	font-size: 30px;
}

.pr-wrap-input svg {
	font-size: 35px;
	color: var(--main-color-text-dark);
	position: absolute;
	bottom: 15px;
	right: 10px;
}

.pr-smaller-eye {
	right: 13px !important;
}

button {
	width: 400px;
	height: 50px;
	font-size: 30px;
	margin-top: 20px;
}

@media screen and (max-width: 600px) {
	.pr-wrap-content {
		max-width: 100vw;
	}

	.pr-wrap-content h1 {
		max-width: 90vw;
		font-size: 60px;
	}

	.pr-wrap-input {
		max-width: 90%;
	}

	.pr-wrap-input label {
		font-size: 25px;
	}

	.pr-wrap-input input {
		max-width: calc(100% - 25px);
		height: 40px;
		font-size: 25px;
	}

	.pr-wrap-input svg {
		font-size: 30px;
		bottom: 10px;
	}

	button {
		font-size: 20px;
		width: 300px;
		height: 40px;
	}

	.pr-wrap-password-reset {
		width: 90vw;
		height: 30vh;
		min-height: 400px;
		top: 25vh;
		left: 5vw;
	}

	.pr-wrap-password-reset h2 {
		font-size: 20px;
		max-width: 90%;
	}

	.pr-wrap-password-reset svg {
		font-size: 2em;
	}
}
</style>
